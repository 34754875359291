import React, { useEffect, useState } from "react";
import { Navbar } from "reactstrap";
import * as commonConstant from "../../common/constants";

//Lib
import Logo from "../Logo";

//components
import UserNavDropdown from "./userNavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { Link } from "react-router-dom";
import MessagesService from "../../services/MessagesService";
import CountBadge from "../CountBadge";
import HeadNav from "./HeadNav";
import Drawer from "../Drawer";
import SideBar from "../../views/message/components/SideBar";
import { useHistory } from "react-router-dom";
import Links from "../Link";

const UserNavigation = (props) => {
  let {
    showNav,
    changeNavBarColor,
    allowAccess,
    headerColor,
    headerTextColor,
    companyName,
    companyLogo,
    logoRedirectUrl,
    leftNavigationBackgroundColor,
    leftNavigationTextColor,
    leftNavigationTextHoverColor,
  } = props;
  const history = useHistory();

  const [message, setMessage] = useState([]);
  const [nav, setNav] = useState([]);
  const [isModalopen, setIsModalopen] = useState(false);

  useEffect(() => {
    getMassageDetail();
  }, [isModalopen]);

  let handleMessageClick = (row) => {
    if(message && message?.data[0]?.id){
      history.push(`/message/user/${message?.data[0]?.id}`, { isDrawerClick: true, customParam: row });
    }else{
      history.push(`/message/user/add`, { isDrawerClick: true, customParam: row });
    }
    setIsModalopen(false);
    setMessage([])
  };

  let getMassageDetail = async () => {
    let response = await MessagesService.search();
    let data = response && response?.data && response?.data;
    setMessage(data);
  };

  let openToggle = () => {
    setIsModalopen(!isModalopen);
  };

  let closeToggle = () => {
    setIsModalopen(false);
  };

  let handleClick = (row) => {
    history.push(`/message/user/${row?.id}`, { isDrawerClick: true, customParam: row });
    setIsModalopen(false);
    setMessage([])
  };

  let handleGroupChange = (row) => {
    history.push(`/message/group/${row?.channel_id}`, {
      isDrawerGroupClick: true,
      customGroupParam: row,
    });
    setIsModalopen(false);
    setMessage([])
  };

  const messageFooter = (
    <Links
      to="#"
      onClick={() => handleMessageClick()}
      className="text-blue-500 hover:text-blue-700 link-opacity-75 text-decoration-none"
      text="View All"
    />
  );

  return (
    <>
      <Navbar
        className={`p-0 navbar navbar-expand-lg ${showNav} ${changeNavBarColor}`}
        style={{
          backgroundColor: headerColor
            ? headerColor
            : commonConstant.DEFAULT_HEADER_COLOR,
          color: headerTextColor,
        }}
      >
        <Drawer
          DrawerBody={
            <SideBar
              handleClick={handleClick}
              handleGroupChange={handleGroupChange}
              showChennalSection
              showDirectMessageSection
              showChannelAddButton={false}
              showDirectMessageAddButton={false}
            />
          }
          initialValues={false}
          handleOpenModal={openToggle}
          handleCloseModal={closeToggle}
          handleDrawerClose={closeToggle}
          isModalOpen={isModalopen}
          enableReinitialize={{}}
          className="filter-width"
          modelTitle="Messages"
          DrawerFooter={messageFooter}
        />
        <div className="container-fluid d-flex p-0 m-0">
          <div className="text-nowrap navbar-collapse">
            <div className="logo-min-width logo-container">
              <Logo
                src={companyLogo}
                link={logoRedirectUrl}
                altText={companyName}
                label={companyName}
                headerTextColor={headerTextColor}
                className="portal-logo"
              />
            </div>
            <HeadNav
              leftNavigationBackgroundColor={leftNavigationBackgroundColor}
              leftNavigationTextColor={leftNavigationTextColor}
              leftNavigationTextHoverColor={leftNavigationTextHoverColor}
              headerTextColor={headerTextColor}
              headerColor={headerColor}
            />
          </div>
          {/* Mail and Admin Dropdown */}
          <div className="d-flex mt-4 ms-3 me-3 mb-3 mt-sm-3">
            <FontAwesomeIcon
              className={message?.totalMessageCount ? "" : "me-3"}
              icon={faEnvelope}
              style={{
                fontSize: "36px",
                color: "var(--page-userNavigation-FontAwesomeIcon-color)",
              }}
              onClick={() => openToggle()}
            />
            <CountBadge
              className="d-inline-block ps-2 pe-2 status rounded-pill mb-4 me-3"
              count={message?.totalMessageCount}
              badgeColor="red"
            />

            {/* /.utilities */}
            <UserNavDropdown
              headerTextColor={headerTextColor}
              enable={allowAccess ? allowAccess : true}
            />
          </div>
          {/* /.nav-wrapper */}
        </div>
      </Navbar>
    </>
  );
};

export default UserNavigation;
