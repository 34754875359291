import React from 'react';
import { Tooltip } from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';

const ToolTipComponent = ({ text }) => {
  return (
    <div>
      <FaInfoCircle
        data-tooltip-id="info-tooltip"
        data-tooltip-content={text}
        className='cursor-pointer'
        style={{ color: '#555' }}
      />
      <Tooltip id="info-tooltip" place="top" type="dark" effect="solid" />
    </div>
  );
};

export default ToolTipComponent;
