import { Field } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import LazyLoad from "react-lazyload";
import classNames from "classnames";
import Lightbox from "react-image-lightbox";

// Helpers
import mediaConstant from "../../helpers/Media";

// components
import CancelButton from "../../components/CancelButton";
import Form from "../../components/Form";
import Hint from "../../components/Hint";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Text from "../../components/Text";
import ToggleSwitch from "../../components/ToggleSwitch";
import DeleteModal from "../../components/DeleteModal";
import BreadCrumb from "../../components/Breadcrumb";
import ActivityList from "../../components/ActivityList";
import Action from "../../components/Action";
import Select from "../../components/Select";

// Services
import MediaService from "../../services/MediaService";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import { TagTypeName } from "../../helpers/Tag";

// Lib
import Cookie from "../../lib/Helper";
import { COOKIE_SESSION_TOKEN } from "../../lib/Cookie";

// API
import { apiClient } from "../../apiClient";
import { endpoints } from "../../api/endPoints";

export const Tab = {
  GENERAL: "General",
  PRODUCTS: "Products",
  HISTORY: "History",
};

const MediaDetail = (props) => {
  const { history } = props;
  const [file, setFile] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [mediaDetail, setMediaDetail] = useState("");
  const [toggleValue, setToggleValue] = useState(false);
  const [fileName, setFileName] = useState("");
  const [mediaUrl, setMediaUrl] = useState(mediaDetail && mediaDetail.file_url);
  const [mediaFileTypeError, setMediaFileTypeError] = useState(false);
  const [isFileExist, setIsFileExist] = useState(false);
  const [activeTab, setActiveTab] = useState(Tab.GENERAL);
  const [isOpen, setIsOpen] = useState(false);
  const [tagList, setTagList] = useState();
  const [tag, setTag] = useState();

  const userImageRef = useRef();

  // Current Id
  let id = props.match.params.id;

  useEffect(() => {
    getMediaDetails();
    getMediaTagDetails();
  }, []);

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/admin/companyProfile" },
    { label: "Media", link: "/media" },
    { label: "MediaDetails", link: "" },
  ];

  // Media Visibility Initial values
  const mediaVisibilityInitialValues = {
    media_visibility: mediaDetail.visibility === mediaConstant.VISIBILITY_PUBLIC ? true : false,
  };

  //Get Media Details
  const getMediaDetails = async () => {
    await MediaService.get(id, async (err, data) => {
      const response = await fetch(data?.url, {
        headers: {
          Authorization: Cookie.get(COOKIE_SESSION_TOKEN),
        }
      });
      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }
      let visibility = data.visibility == mediaConstant.VISIBILITY_PUBLIC ? true : false
      setToggleValue(visibility)
      const blob = await response.blob();
      setMediaDetail({
        ...data,
        fetchUrl: URL.createObjectURL(blob)
      });
    });
  };

  const getMediaTagDetails = async () => {
    try {
      const response = await apiClient.get(`${endpoints().tagApi}/list`);
      const tagDetails = response.data.data;

      const tagList = tagDetails
        .filter((tag) => tag.type === TagTypeName.MEDIA)
        .map((tag) => ({
          label: tag.name,
          value: tag.id,
          id: tag.id,
          status: tag.status,
        }));

      setTagList(tagList);
    } catch (err) {
      console.log(err);
    }
  };

  // Handle media visibility
  const handleEnableSalesRouting = (value) => {
    const data = new FormData();
    if (value !== undefined) {
      data.append("visibility", value === true ? mediaConstant.VISIBILITY_PUBLIC : mediaConstant.VISIBILITY_PRIVATE);
    }
    updateImage(data);
  };

  const handleTagChange = (value) => {
    let values = value && value?.values && value?.values?.tag_id?.id;
    setTag(values);
  }

  // Update Media
  const updateImage = async (data) => {
    await MediaService.update(id, data, null, (res) => {
      if (res) {
        getMediaDetails()
      }
    });
  };

  // Delete Media
  const deleteImage = async (id) => {
    await MediaService.delete(id);
  };

  //Set Media
  const media = (e) => {
    const data = e.target.files ? e.target.files[0] : "";
    setFile(data);
    const fileUrl = URL.createObjectURL(data);
    setMediaUrl(fileUrl);
    setFileName(data.name);
    setIsFileExist(false);
  };

  //Media Remove
  const onMediaRemove = () => {
    setMediaFileTypeError(false);
    setIsFileExist(true);
    mediaRef.current.value = "";
    setMediaUrl("");
    setFile("");
    setFileName("");
    mediaRemove();
  };

  // Validate Filetype
  const validateFile = (e, File) => {
    const targetFile = e.target;
    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          File === mediaConstant.MEDIA_URL && setMediaFileTypeError(false);
          break;
        default:
          File === mediaConstant.MEDIA_URL && setMediaFileTypeError(true);
          break;
      }
    }
  };

  // Media image upload
  const onMediaChange = (e) => {
    validateFile(e, mediaConstant.MEDIA_URL);
    if (!mediaFileTypeError) {
      media(e);
    }
  };

  //Update File
  const update = (values) => {
    const data = new FormData();
    if (file) {
      data.append("media_file", file ? file : "");
      data.append("media_name", fileName ? fileName : "");
      data.append("media_url", mediaUrl ? mediaUrl : "");
    }
    if (values.image_name) {
      data.append("image_name", values.image_name ? values.image_name : "");
    }
    if (values.name) {
      data.append("name", values.name ? values.name : "");
    }
    if (values.tag_id) {
      data.append("tag_id", tag ? tag : values?.tag_id && values.tag_id?.value ? values.tag_id?.value : "");
    }
    if (values.file_path) {
      data.append("file_path", values.file_path ? values.file_path : "");
    }
    if (values.image_description) {
      data.append(
        "image_description",
        values.image_description ? values.image_description : ""
      );
    }
    if (values.image_status) {
      data.append(
        "image_status",
        values.image_status ? values.image_status.label : ""
      );
    }
    if (!isFileExist) {
      updateImage(data);
      setFile("");
      setMediaUrl("");
      setFileName("");
    }
  };

  // Media Url
  const mediaLogoWithBaseUrl = mediaUrl
    ? mediaUrl
    : mediaDetail && mediaDetail.url;
  const initialValues = {
    image_name: mediaDetail ? mediaDetail.name : "",
  };

  // Handle Delete
  const handleDeleteMedia = (id) => {
    deleteImage(mediaDetail.id);
    history.push("/media");
  };

  const openLightBox = () => {
    setIsOpen(true);
  }

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e == "delete") {
      setDeleteModal(true);
    }

  };

  const handleClick = (e) => {
    e.preventDefault();
    openLightBox()
  };

  let visibility = mediaDetail.visibility === mediaConstant.VISIBILITY_PUBLIC ? true : false
  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={mediaDetail?.fetchUrl}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Status"
        id={mediaDetail.id}
        label={mediaDetail.name}
        deleteFunction={handleDeleteMedia}
      />
      {/* Breadd Crumb Section */}
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        <PageTitle label="Media Details" />
        <Action
          dropdownLinks={actionsMenuList}
          handleChange={handleActionChange}
        />
      </div>

      <Nav tabs className="admin-tabs">
        {/* Detail Tab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.GENERAL,
            })}
            onClick={() => {
              toggle(Tab.GENERAL);
            }}
          >
            {Tab.GENERAL}
          </NavLink>
        </NavItem>

        {/* History Tab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.HISTORY,
            })}
            onClick={() => {
              toggle(Tab.HISTORY);
            }}
          >
            {Tab.HISTORY}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tab.GENERAL}>

          <Form
            enableReinitialize={true}
            initialValues={{
              ...mediaVisibilityInitialValues,
              file_path: mediaDetail?.file_path,
              name: mediaDetail?.name,
              tag_id: tag
                ? tagList && tagList.find((data) => data.id == tag)
                : mediaDetail && mediaDetail?.tag_id
                  ? tagList && tagList.find((tag) => tag.id == mediaDetail?.tag_id)
                  : ""
            }}
            onSubmit={(e) => {
              update(e);
            }}
          >
            <div className="card bg-white mb-3">
              <div className="card-body">
                <div className="row">
                  {/* Left Section (File Upload, Name, File Path, etc.) */}
                  <div className="col-lg-6 col-sm-12">
                    <div className="row">
                      <div className="field-wrapper d-flex mt-1 ms-1 mb-3 col-lg-12 col-sm-12">
                        <div>
                          <div>
                            <Label className="ms-1">File</Label>
                          </div>
                          <div className="upload-field d-inline-block">
                            <input
                              name="file"
                              className="form-control d-none"
                              type="file"
                              id="portalLogo"
                              placeholder="Banner Image"
                              accept="image/png,image/gif,image/jpeg"
                              onChange={(e) => {
                                onMediaChange(e);
                              }}
                              ref={userImageRef}
                            />
                            <span className="profilePicOverlay d-block ">
                              <label
                                htmlFor="portalLogo"
                                className="profile-img-sm mb-0"
                              >
                                {mediaLogoWithBaseUrl ? (
                                  <span className="text-decoration-none cursor-pointer text-primary">
                                    Select Other Image
                                  </span>
                                ) : (
                                  <span className="text-decoration-none cursor-pointer text-primary">
                                    Select Image
                                  </span>
                                )}
                              </label>
                            </span>
                          </div>
                          <div className="field-wrapper">
                            <small className={`text-danger mt-3 ${"d-none"}`}>
                              Please select an image file
                            </small>
                            <Hint
                              id="bannerRequirements"
                              hintText="We recommend using 400 x 400 image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <Text
                          name="name"
                          label="Name"
                          placeholder="Enter Name"
                          required={true}
                          error=""
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <Select
                          name="tag_id"
                          label="Tag"
                          placeholder="Select Tag"
                          onInputChange={handleTagChange}
                          options={tagList}
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <Text
                          name="file_path"
                          label="File Path"
                          placeholder="Enter File Path"
                          required={true}
                          error=""
                        />
                      </div>
                      <Field
                        name={mediaConstant.MEDIA_VISIBILITY}
                        render={({ field, form }) => {
                          return (
                            <ToggleSwitch
                              name={mediaConstant.MEDIA_VISIBILITY}
                              label="Public"
                              value={field.value}
                              handleChange={() => {
                                handleEnableSalesRouting(!field.value);
                                setToggleValue(!field.value);
                                form.setFieldValue(
                                  mediaConstant.MEDIA_VISIBILITY,
                                  !field.value
                                );
                              }}
                              outlined
                            />
                          );
                        }}
                      />
                    </div>
                  </div>

                  {/* Right Section (Images Stacked One Below the Other) */}
                  <div className="col-lg-6 col-sm-12 mb-3">
                    <div className="d-flex flex-column align-items-center">
                      {/* First Image */}
                      <div className="ms-3 mb-3">
                        <LazyLoad height={200}>
                          <a
                            href={mediaDetail?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleClick}
                          >
                            <img
                              src={mediaDetail?.fetchUrl}
                              alt={mediaDetail?.file_name}
                              onClick={() => {
                                openLightBox();
                              }}
                              style={{
                                height: "200px",
                                marginTop: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            />
                          </a>
                        </LazyLoad>
                      </div>
                      {/* Second Image */}
                      <div className="ms-3 mb-3">
                        {visibility &&
                          <LazyLoad height={200}>
                            <a
                              href={mediaDetail?.publicImage}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={handleClick}
                            >
                              <img
                                src={mediaDetail?.publicImage}
                                alt={mediaDetail?.name}
                                onClick={() => {
                                  openLightBox();
                                }}
                                style={{
                                  height: "200px",
                                  marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              />
                            </a>
                          </LazyLoad>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <SaveButton />
                <CancelButton onClick={() => history.push("/media")} />
              </div>
            </div>
          </Form>

        </TabPane>
        {activeTab == Tab.HISTORY && (
          <TabPane tabId={Tab.HISTORY} className="w-100">
            <ActivityList
              id={id}
              objectId={id}
              object_name={ObjectName.MEDIA}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default MediaDetail;
