import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Chart } from "chart.js";
import Spinner from "../../../components/Spinner";
import Currency from "../../../lib/Currency";
import { PaymentType } from "../../../helpers/saleReport";
import { getColorsRandom, getRandomColor } from "../../../lib/Helper";

import "../../../scss/_custom.scss";

// Register chart components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ActiveSales = (props) => {
  const [total, setTotal] = useState(0);
  const [storeList, setStoreList] = useState([]);
  const [average, setAverage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [colorList, setColorList] = useState([]);
  const [totalAmount, setTotalAmount] = useState([]);
  const graphData = props.graphData;
  const defaultGraph = props.defaultGraph;
  const [averageAmounts, setAverageAmounts] = useState([]);
  useEffect(() => {
    if (graphData) {
      getStoresList();
    }
  }, [graphData]);

  useEffect(() => {
    getRandomColors();
  }, [storeList]);

  const getRandomColors = () => {
    const color = defaultGraph ? getRandomColor(storeList.length) : getColorsRandom();
    setColorList(color);
  };

  const getStoresList = async () => {
    setIsLoading(true); // Set loading before async operation
    let storeName = [];
    let storeAmount = [];
    let averageAmounts = [];
    
    if (graphData && graphData.data) {
      const storesLists = graphData.data;

      if (storesLists.length === 0) {
        setStoreList([]);
        setTotalAmount([]);
      } else {
        for (const store of storesLists) {
          storeName.push(defaultGraph ? store.name : store.date);
          if(store?.averageOrderAmount){
            averageAmounts.push(store.averageOrderAmount)
          }
          storeAmount.push(getAmountBasedOnPaymentType(store));
        }
        setStoreList(storeName);
        setTotalAmount(storeAmount);
        setTotal(graphData.totalAmount);
        setAverage(graphData.average);
        setAverageAmounts(averageAmounts);
      }
    }
    
    setIsLoading(false); // Stop loading after data processing
  };

  const getAmountBasedOnPaymentType = (store) => {
    switch (props.params.paymentType) {
      case PaymentType.CASH_VALUE:
        return defaultGraph ? store.total_amount_cash : store.amount_cash;
      case PaymentType.UPI_VALUE:
        return defaultGraph ? store.total_amount_upi : store.amount_upi;
      default:
        return defaultGraph ? store.totalAmount : store.amount;
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="App">
        <h3 className="text-center">
          Total: {Currency.GetFormatted(total)} (Average: {Currency.GetFormatted(average)})
        </h3>
      </div>
      <div className="App">
        <Bar
          data={{
            labels: storeList,
            datasets: [
              {
                label: "",
                data: totalAmount,
                backgroundColor: colorList,
                borderColor: defaultGraph ? ["aqua", "green", "red", "yellow"] : colorList,
                borderWidth: 0.5,
                datalabels: {
                  formatter: (value) => Currency.GetFormatted(value),
                  color: "blue",
                  anchor: "end",
                  align: "top",
                  offset: -5,
                  font: {
                    size: 14,
                  }
                },
              }
            ],
          }}
          height={400}
          plugins={[ChartDataLabels]}
          options={{
            animation: false,
            locale: "en-IN",
            maintainAspectRatio: false,
            scales: {
              y: {
                ticks: {
                  callback: (value) => Currency.GetFormatted(value),
                  beginAtZero: true,
                },
              },
            },
            plugins: {
              legend: {
                display: true,
                position: 'top',
                align: 'center',
                labels: {
                  padding: 20,
                  boxWidth: 0,
                },
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const label = context.dataset.label || '';
                    const value = Currency.GetFormatted(context.raw);
                    const averageValue = averageAmounts[context.dataIndex] ? Currency.GetFormatted(averageAmounts[context.dataIndex]) : ''; 
                    if(averageValue){
                      return [`${label}: ${value}`,`Average: ${averageValue}`]; // Display average in tooltip
                    }
                  },
                 
                },
              },
            },
          }}
        />
      </div>
    </>
  );
};
export default ActiveSales;
