import {
  faCheckSquare,
  faProjectDiagram,
  faTableCells,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import { faTableCellsLarge } from "@fortawesome/free-solid-svg-icons";
import Cookie from "../helpers/Cookie";
import Urls from "../lib/Url";
import Permission from "../helpers/Permission";
import Url from "../helpers/Url";
import { hasPermission } from "../services/UserRolePermissionService";
import Cookies from "../lib/Helper";

export function getProjectsNavList(settings, permissionList) {
  let hasTicketViewPermission = hasPermission(Permission.TICKET_VIEW);
  let hasSprintViewPermission = hasPermission(Permission.SPRINT_VIEW);

  let arrayList = [];

  arrayList = arrayList.concat({
    name: "Dashboard",
    url: Url.DASHBOARD_TICKET_LIST,
    detailsPageurl: "/dashboard/ticket",
    icon: faTableCellsLarge,
  });

  if (hasTicketViewPermission) {
    arrayList = arrayList.concat({
      name: "Tickets",
      url: `/ticket?projectId=${Urls.GetParam("projectId")}`,
      addPageurl: "/ticket",
      detailsPageurl: "/ticket/",
      icon: faTasks,
    });
  }

  if (hasSprintViewPermission) {
    arrayList = arrayList.concat({
      name: "Sprints",
      url: "/sprint",
      detailsPageurl: "/Sprint/",
      editPageurl: "/sprint",
      icon: faCheckSquare,
    });
  }

  if (hasTicketViewPermission) {
    arrayList = arrayList.concat({
      name: "Ticket Search",
      url: "/ticketSearch",
      detailsPageurl: "/ticketSearch",
      icon: faTasks,
    });
  }

  if (Cookies.get(Cookie.PROJECT_ID) && Cookies.get(Cookie.PROJECT_ID)) {
    arrayList = arrayList.concat({
      name: "Test Case",
      url: "/ticketTestCase",
      detailsPageurl: "/ticketTestCase/",
      icon: faTableCells,
    });
  }

  if (
    Cookies.get(Cookie.PROJECT_ID) &&
    Cookies.get(Cookie.PROJECT_ID) !== "null"
  ) {
    arrayList = arrayList.concat({
      name: "Settings",
      url: `/project/${Cookies.get(Cookie.PROJECT_ID)}?projectId=${Cookies.get(
        Cookie.PROJECT_ID
      )}`,
      detailsPageurl: "/project/",
      icon: faTasks,
    });
  }
  return arrayList;
}
