import React, { useState, useEffect } from "react";
import ColorSelect from "../../components/colorSelect/colorSelect";

// Components
import { Setting } from "../../helpers/Setting";
import "../../scss/_custom.scss";

const LeftNavigation = (props) => {
  const {
    leftNavBackgroundImageRef,
    onNavBackgroundImageChange,
    onNavBackgroundImageRemove,
    leftNavToggles,
    LeftNavigationBackgroundImageWithBaseUrl,
    toggleColor,
    settingsLeft,
  } = props;

  let {
    portal_left_navigation_text_color,
    portal_left_navigation_text_hover_over_color,
    portal_left_navigation_background_color
  } = settingsLeft;

  const [navigationColor, setNavigationColor] = useState(portal_left_navigation_text_color);
  const [navigationHoverColor, setNavigationHoverColor] = useState(portal_left_navigation_text_hover_over_color);
  const [navigationBackgroundColor, setNavigationBackgroundColor] = useState(portal_left_navigation_background_color);
  const [isExpanded, setIsExpanded] = useState(true);

  const leftNavColor = isExpanded ? "#ECF5FF" : "#FFFFFF";

  const onNavigationColourChange = (e) => {
    setNavigationColor(e.target.id, Setting.SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_COLOR);
    props.leftNavigationColor(e.target.id);
  };

  const onNavigationHoverColorChange = (e) => {
    setNavigationHoverColor(e.target.id, Setting.SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_COLOR);
    props.leftNavigationHoverColor(e.target.id);
  };

  const onNavigationBackgroundColorChange = (e) => {
    setNavigationBackgroundColor(e.target.id, Setting.SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_COLOR);
    props.leftNavigationBackgroundColor(e.target.id);
  };

  useEffect(() => {
    setNavigationColor(settingsLeft.portal_left_navigation_text_color);
    setNavigationHoverColor(settingsLeft.portal_left_navigation_text_hover_over_color);
    setNavigationBackgroundColor(settingsLeft.portal_left_navigation_background_color);
  }, [
    settingsLeft.portal_left_navigation_text_color,
    settingsLeft.portal_left_navigation_text_hover_over_color,
    settingsLeft.portal_left_navigation_background_color,
  ]);

  return (
    <>
      <div className="accordion mt-3" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingLeftNavigation">
            <button
              className={`accordion-button ${isExpanded ? "" : "collapsed"}`}
              style={{ backgroundColor: leftNavColor }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseLeftNavigation"
              aria-expanded={isExpanded ? "true" : "false"}
              aria-controls="collapseLeftNavigation"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <h5 className="text-primary">Left Navigation</h5>
            </button>
          </h2>
          <div
            id="collapseLeftNavigation"
            className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
            aria-labelledby="headingLeftNavigation"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-3">
              {/* Left Navigation Text Color */}
              <h5>Text Color</h5>
              <div className="field-wrapper">
                <ColorSelect
                  name="portal_left_navigation_text_color"
                  defaultValue={portal_left_navigation_text_color}
                  setColor={props?.leftNavigationColor}
                />
              </div>

              {/* Left Navigation Text Hover Over Color */}
              <h5>Text Hover Over Color</h5>
              <div className="field-wrapper">
                <ColorSelect
                  name="portal_left_navigation_text_hover_over_color"
                  defaultValue={portal_left_navigation_text_hover_over_color}
                  setColor={props?.leftNavigationHoverColor}
                />
              </div>

              {/* Left Navigation Background Color */}
              <h5>Background Color</h5>
              <div className="field-wrapper">
                <ColorSelect
                  name="portal_left_navigation_background_color"
                  defaultValue={portal_left_navigation_background_color}
                  setColor={props?.leftNavigationBackgroundColor}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftNavigation;
