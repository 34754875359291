import React from "react";
import { useState } from "react";
import RatingTypeDetail from "../../../components/RatingTypeList";

const RatingTypeDetails = (props) => {
  let ratingType = props.match.params.id;
  const { history } = props;
  const [isOpen, setIsOpen] = useState(false);

  const _toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <>
        <RatingTypeDetail
          history={history}
          ratingType={ratingType}
          __toggle={_toggle}
          isModel={isOpen}
          hideQueryParams={{type:true}}
        />
      </>
    </div>
  );
};
export default RatingTypeDetails;
