import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageSearch from "../../components/PageSearch";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import ObjectName from "../../helpers/ObjectName";
import NoRecordsFound from "../../components/NoRecordsFound";

const StatusDetails = (props) => {
  const { history } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStatusList, setFilteredStatusList] = useState([]);

  const handleSearch = () => {
    const statusList = ObjectName.Options;
    const filteredList = statusList.filter((data) => {
      return data.label.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredStatusList(filteredList);
  };

  const onSearchKeyUp = (event) => {
    if (event.charCode === 13) {
      handleSearch({ search: searchQuery });
    }
  };

  useEffect(() => {
    handleSearch(); // Initial filtering based on search query
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <PageTitle label="Statuses" />
      </div>
      <PageSearch
        onSearchClick={handleSearch}
        onChange={(event) => setSearchQuery((event.target.value).trim())}
        onKeyPress={(e) => onSearchKeyUp(e)}
        placeholder="Search"
        classnames="page-heading cover d-flex p-3"
      />
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr style={{ backgroundColor: 'black' }}>
            <th style={{ border: '1px solid black', padding: '10px', color: 'white', textAlign: "center" }}>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredStatusList && filteredStatusList.length > 0 ? (
            filteredStatusList.map(row => (
              <tr key={row.label} style={{ border: '1px solid black' }}>
                <td style={{ border: '1px solid lightgray', padding: '10px', color: 'white' }}>
                  <Link
                    to={`/setting/Statues/StatusList/${row.label}?object_name=${row.label}`}
                    className="link-opacity-75 text-decoration-none lh-lg"
                  >
                    {row.label}
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan="6">
                <NoRecordsFound middleHeight={"70vh"} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default StatusDetails;
