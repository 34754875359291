// API Client
import { apiClient } from "../apiClient";
import { endpoints } from "../api/endPoints";
import Toast from "../components/Toast";
import { isBadRequest, SUCCESS_RESPONSE } from "../lib/Http";
import { HttpStatus } from "../helpers/HttpStatus";

class customerService {
  static create = (data, callback) => {
    apiClient
      .post(endpoints().customersAPI, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback && callback(successMessage);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status >= HttpStatus.BAD_REQUEST) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          Toast.error(errorMessage);
          // toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  static update = async (id, data, callback) => {
    apiClient
      .put(`${endpoints().customersAPI}/${id}`, data)
      .then((res) => {
        if (res.status == SUCCESS_RESPONSE) {
          Toast.success(res?.data?.message);
          return callback(res.data.message);
        }
      })
      .catch((err) => {
        if (isBadRequest(err)) {
          let errorMessage;
          const errorRequest = err.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
        }
      });
    return data;
  };

  static delete = async (id, history, callback) => {
    apiClient
      // delete callRegister
      .delete(`${endpoints().customersAPI}/delete/${id}`)
      .then((res) => {
        if (res.status == SUCCESS_RESPONSE) {
          Toast.success(res?.data?.message);
          history.push("/customers");
          return callback && callback(res?.data?.message);
        }
      })
      .catch((err) => {
        if (isBadRequest(err)) {
          let errorMessage;
          const errorRequest = err.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
        }
      });
  };

  static async get(id, callback) {
    try {
      if (id) {
        await apiClient
          .get(`${endpoints().customersAPI}/${id}`)
          .then((response) => {
            return callback(response, null);
          })
          .catch((error) => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(error.response.data.message);
              console.error(errorMessage);
            }
          });
      }
    } catch (err) {
      console.log(null, err);
    }
  }
  static async list(callback) {
    try {
        await apiClient
          .get(`${endpoints().customersAPI}/list`)
          .then((response) => {
            return callback(response?.data, null);
          })
          .catch((error) => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(error.response.data.message);
              console.error(errorMessage);
            }
          });
    } catch (err) {
      console.log(null, err);
    }
  }
}

export default customerService;
