import React from "react";
import Currency, { Percentage } from "../../lib/Currency";

function ReportTable(props) {
  const { detail, detailValue, page } = props;
  const currentPage = page;
  const totalCounts = detailValue?.totalCount;
  const startPage = (currentPage - 1) * detailValue?.pageSize + 1;
  const firstPage =
    startPage > detailValue?.totalCount ? detailValue?.totalCount : startPage;
  const endPage = currentPage * detailValue?.pageSize;
  const lastPage =
    endPage > detailValue?.totalCount ? detailValue?.totalCount : endPage;

  return (
    <div className="overflow-x-auto">
      <table
        className="table bg-gray table-bordered overflow-x-auto"
      >
        <thead className="table-dark">
          <tr className="text-center">
            <th
              className="align-middle"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              GST
            </th>
            <th
              className="align-middle"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              TAXABLE Amount
            </th>
            <th
              className="align-middle"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              NET Amount
            </th>
            <th
              className="align-middle"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              SGST Amount
            </th>
            <th
              className="align-middle"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              CGST Amount
            </th>
            <th
              className="align-middle"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              GST Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {detail.map((item, index) => (
            <tr className="border-bottom" key={index}>
              <td
                className="align-middle text-center"
                style={{ minWidth: "200px", maxWidth: "200px" }}
              >
                <div>{item && Percentage(item.gstPercentage)}</div>
              </td>
              <td
                className="align-middle text-center"
                style={{ minWidth: "200px", maxWidth: "200px" }}
              >
                <div>{item && Currency.roundOff(item.taxableAmount)}</div>
              </td>
              <td
                className="align-middle text-center"
                style={{ minWidth: "200px", maxWidth: "200px" }}
              >
                <div>{item && Currency.roundOff(item.netAmount)}</div>
              </td>
              <td
                className="align-middle text-center"
                style={{ minWidth: "200px", maxWidth: "200px" }}
              >
                <div>{item && Currency.roundOff(item.sgstAmount)}</div>
              </td>
              <td
                className="align-middle text-center"
                style={{ minWidth: "200px", maxWidth: "200px" }}
              >
                <div>{item && Currency.roundOff(item.cgstAmount)}</div>
              </td>
              <td
                className="align-middle text-center"
                style={{ minWidth: "200px", maxWidth: "200px" }}
              >
                <div>{item && Currency.roundOff(item.totalAmount)}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <table className="table overflow-x-auto">
        <tbody>
          <tr>
            <td
              className="  text-center"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              {totalCounts > 0 && (
                <div>
                  Showing {firstPage} to {lastPage} of {totalCounts} entries
                </div>
              )}
            </td>
            <td
              className="  text-center"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              <div>
                <strong>
                  {" "}
                  Total Taxable Amount :{" "}
                  {Currency.roundOff(detailValue?.totalTaxableAmount)}
                </strong>
              </div>
            </td>
            <td
              className="  text-center"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              <div>
                <strong>
                  {" "}
                  Total Net Amount :{" "}
                  {Currency.roundOff(detailValue?.totalNetAmount)}
                </strong>
              </div>
            </td>
            <td
              className="  text-center"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              <div>
                <strong>
                  {" "}
                  Total Sgst : {Currency.roundOff(detailValue?.totalSgstAmount)}
                </strong>
              </div>
            </td>
            <td
              className="  text-center"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              <div>
                {" "}
                <strong>
                  Total Cgst : {Currency.roundOff(detailValue?.totalCgstAmount)}
                </strong>{" "}
              </div>
            </td>
            <td
              className=" text-center"
              style={{ minWidth: "200px", maxWidth: "200px" }}
            >
              <div>
                <strong>
                  Total Gst : {Currency.roundOff(detailValue?.totalAmount)}
                </strong>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ReportTable;
