import { apiClient } from "../apiClient";
import ObjectName from "../helpers/ObjectName";
import { MEDIA_PATH_SETTING } from "./Constants";

class Url {
  static GetParam(e) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get(e) || "";
  }
  // Remove Empty query params

  static UpdateUrl = (params, props) => {

    try {
      const currentPage = window.location.pathname;
      let queryString = "";

      queryString = "?";

      const searchParams = new URLSearchParams(params);
      const url = `${currentPage}${queryString}${searchParams.toString()}`;
      if (props.history) {
        props.history.push(url), {
          data: params,
        };
      }
    } catch (err) {
      console.log(err);
    }
  }
  static removeEmptyParams(query) {
    return query.replace(/\w+=&/g, "").replace(/&\w+=$/, "");
  }

  static async get(url, queryString) {
    let apiUrl;

    if (queryString && queryString.length > 0) {
      apiUrl = `${url}?${queryString.join("&")}`;
    } else {
      apiUrl = `${url}`;
    }
    const response = await apiClient.get(apiUrl);
    return response;
  }

  static GetAllParams() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paramsObj = {};

    if (!params.entries().next().done) {
      for (const [key, value] of params.entries()) {
        paramsObj[key] = value;
      }
    }

    return paramsObj;
  }
  static getUrlByObjectName = (objectName, objectId) => {
    const objectNameToUrlMapping = {
      [ObjectName.ACTIVITY_TYPE]: (id) => `/setting/ActivityTypes/${id}`,
      [ObjectName.ORDER]: (id) => `/order/${id}`,
      [ObjectName.PRODUCT]: (id) => `/product/${id}`,
      [ObjectName.USER]: (id) => `/user/${id}`,
      [ObjectName.FINE]: (id) => `/fine/${id}`,
      [ObjectName.SHIFT]: (id) => `/setting/Shifts/${id}`,
      [ObjectName.PAYMENT]: (id) => `/payment/detail/${id}`,
      [ObjectName.LOCATION]: (id) => `/location/${id}`,
      [ObjectName.ACCOUNT_ENTRY]: (id) => `/accountsEntry/details/${id}`,
      [ObjectName.ATTENDANCE]: (id) => `/attendance/${id}`,
      [ObjectName.BILL]: (id) => `/bill/detail/${id}`,
      [ObjectName.PAYMENT_ACCOUNT]: (id) => `/paymentAccount/detail/${id}`,
      [ObjectName.CATEGORY]: (id) => `/category/${id}`,
      [ObjectName.CUSTOMER]: (id) => `/customers/${id}`,
      [ObjectName.ORDER_PRODUCT]: (id) => `/order/${id}`,
      [ObjectName.PROJECT]: (id) => `/project/${id}`,
      [ObjectName.PURCHASE]: (id) => `/purchase/${id}`,
      [ObjectName.PURCHASE_ORDER]: (id) => `/purchaseOrder/detail/${id}`,
      [ObjectName.SALE_SETTLEMENT]: (id) => `/SalesSettlement/${id}`,
      [ObjectName.SALARY]: (id) => `/salary/detail/${id}`,
      [ObjectName.SCHEDULER_JOB]: (id) => `/schedulerJobs/detail/${id}`,
      [ObjectName.SPRINT]: (id) => `/Sprint/${id}`,
      [ObjectName.STOCK_ENTRY]: (id) => `/stockEntry/${id}`,
      [ObjectName.TICKET_TASK]: (id) => `/ticket/details/${id}`,
      [ObjectName.TRANSFER]: (id) => `/transfer/${id}`,
      [ObjectName.TRANSFER_TYPE]: (id) => `/setting/Transfer/type/${id}`,
      [ObjectName.ACTIVITY]: (id) => `/activity/detail/${id}`,
      [ObjectName.MEDIA]: (id) => `/media/detail/${id}`,
      [ObjectName.COMPANY]: (id) => `/supportPortal/company/detail/${id}`,
      [ObjectName.PAGE]: (id) => `/pages/pagelist/detail/${id}`,
      [ObjectName.VENDOR]: (id) => `/vendor/${id}`,
    };
  
    const urlFn = objectNameToUrlMapping[objectName];
    return urlFn ? urlFn(objectId) : null;
  };
}
export default Url;

// * @returns {String}
// */
export function getUrlPath(n) {
  let currentPath = window.location.pathname;
  if (n) currentPath = currentPath.split("/")[n];
  return currentPath;
}
/**
 * Get Redirect Url

 * @param name
 */
export function getRedirectUrl() {
  const urlParams = window.location.search;
  const redirectUrlArray = urlParams.split("redirect=");
  return urlParams === null
    ? ""
    : redirectUrlArray && redirectUrlArray[1]
      ? decodeURIComponent(redirectUrlArray[1])
      : "";
}

/**
 * Get params by name
 */
export function getParamsByName(e) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get(e);
}
/**
 * Remove params by name
 */
export function removeParamsByName(name) {
  const search = window.location.search;
  const params = new URLSearchParams(search.slice(1));
  return params.delete(name);
}
/**
 * Get Formatted Price
 *
 * @param price
 * @returns {string}
 */
export const getBaseUrlParam = () => {
  const baseUrl = window.location.origin;
  return baseUrl ? `baseUrl=${baseUrl}` : "";
};

//   /String to URL
export const getStringToUrl = (str) => {
  if (!str) {
    return null;
  }
  try {
    str = str
      .toLowerCase()
      .replace("/", "")
      .replace(/\s+/g, " ")
      .replace(/ /g, "-")
      .replace(/_/g, "-")
      .replace(/[^\w-]+/g, "");
    return str;
  } catch (e) {
    return "";
  }
};

/**
 * Get Url Parameter

 * @param name
 */
export function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : name === "email"
      ? decodeURIComponent(results[1])
      : decodeURIComponent(results[1].replace(/\+/g, " "));
}

//Update Url Value and Value Pass For Url
export const UpdateUrl = (params, props) => {
  const currentPage = window.location.pathname;
  let queryString = "";

  queryString = "?";

  const searchParams = new URLSearchParams(params);
  const url = `${currentPage}${queryString}${searchParams.toString()}`;
  if (props.history) {
    props.history.push(`${Url.removeEmptyParams(url)}`, {
      data: params,
    });
  }
};

export const RawURLDecode = (str) => {
  if (!str) {
    return null;
  }

  try {
    return decodeURIComponent(str.replace(/%(?![\da-f]{2})/gi, () => "%25"));
  } catch (e) {
    return null;
  }
};
