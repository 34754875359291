export const Salary = {
  STATUS_APPROVED_TEXT: "Approved",
  STATUS_PAID_TEXT: "Paid",
  STATUS_COMPLETED_TEXT: "Completed",
  STATUS_DRAFT_TEXT: "Draft",

  STATUS_APPROVED_VALUE: 1,
  STATUS_PAID_VALUE: 2,
  STATUS_COMPLETED_VALUE: 3,
  STATUS_DRAFT_VALUE: 4,
  LEAVE:"Leave",
  ABSENT:"Absent",
  ADDITIONAL_DAYS:"Additional Days",
  ADDITIONAL_DAY_SALARY:"Additional Day Salary",
  ADDITIONAL_HOURS:"Additional Hours",
  WORKED_DAYS:"Worked Days",
  WORKING_DAYS:"Working Days",
  ADDITIONAL_HOURS_SALARY:"Additional Hours Salary",
  FINE:"Fine",
  LOP:"Lop",
};

export const MonthOption = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];


export const getYears = () => {
  const yearOption = []; // Initialize an empty array
  for (let i = 2010; i <= 2030; i++) {
    yearOption.push({
      label: i,
      value: i,
    });
  }
  return yearOption; // Return the array of year options
};
