import React, { useRef, useState, useEffect } from 'react';
import '../../../scss/_custom.scss';

// Components
import CustomUserCard from '../../../components/CustomUserCard';
import ArrayList from "../../../lib/ArrayList";
import DateTime from '../../../lib/DateTime';
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';

const ShareModalContent = ({ locationList, filteredShifts, date, detail, userList, viewShotRef, isVisible }) => (
    <div 
        ref={viewShotRef} 
        className={`share-modal-content d-flex align-items-center ${isVisible ? 'visible' : 'hidden'}`} 
        style={{ visibility: isVisible ? 'visible' : 'hidden', position: 'fixed', top: '0', left: '0', zIndex: -1 ,minWidth:"2000px"}}
    >
        <div className="share-table">
            <div className="share-table-header-row">
                <span className="share-label share-header-cell" style={{ fontSize: 24, backgroundColor: "yellow", borderBottomWidth: 1 }}>
                    {`Store Allocation - ${DateTime.getDate(date)}`}
                </span>
            </div>
            <div className="share-table-header">
                <span className="share-label share-header-cell" style={{ fontSize: 24, backgroundColor: "yellow" }}>Store</span>
                {ArrayList.isArray(filteredShifts) && filteredShifts.length > 0 && filteredShifts.map((shift, index) => (
                    <span key={index} className="share-label share-header-cell" style={{ fontSize: 24, backgroundColor: "yellow", borderRightWidth: index === filteredShifts.length - 1 ? 0.2 : 1, borderRightColor: "black" }}>
                        {shift?.name}
                    </span>
                ))}
            </div>
            {ArrayList.isArray(locationList) && locationList.length > 0 && locationList.map((location, locationIndex) => (
                <div key={locationIndex} className="share-table-row" style={{ borderBottomWidth: locationIndex === locationList.length - 1 ? 0 : 1, borderRightColor: "black", lineHeight: 2.8 }}>
                    <span className="share-label share-body-cell" style={{ fontSize: 24, fontWeight: "bold", textAlign: "left", backgroundColor: "#f4f4f6" }}>
                        {location?.label}
                    </span>
                    {ArrayList.isArray(filteredShifts) && filteredShifts.length > 0 && filteredShifts.map((shift, shiftIndex) => (
                        <span key={shiftIndex} className="share-label share-body-cell" style={{ fontSize: 24, fontWeight: "bold", borderRightWidth: shiftIndex === filteredShifts.length - 1 ? 0 : 1, borderRightColor: "black" }}>
                            {renderUserDetail(location, shift, detail, userList)}
                        </span>
                    ))}
                </div>
            ))}
        </div>
    </div>
);

// Utility function to render user details
const renderUserDetail = (location, shift, detail, userList) => {
    if (detail) {
        const key = `${location?.id}-${shift?.id}`;
        const userIds = detail[key] || [];

        let userValue = Array.isArray(userList) && userIds
            ? userList.filter((data) => userIds.includes(data?.id))
            : [];
        
        return userValue.length > 0 && (
            userValue.map((user, index) => (
                <span key={index}>
                    <CustomUserCard 
                        firstName={user.first_name} 
                        lastName={user.last_name} 
                        imageUrl={user.media_url} 
                        className={(user?.primary_shift_id !== shift?.id || user?.primary_location_id !== location?.id) ? "text-danger" : ""} 
                    />
                </span>
            ))
        );
    }
};

const ShareModal = ({ locationList, filteredShifts, date, detail, userList, isClicked, setIsClicked }) => {
    const viewShotRef = useRef(null);
    const [isExporting, setIsExporting] = useState(false);

    // Trigger handleExport when isClicked is true
    useEffect(() => {
        if (isClicked) {
            handleExport();
        }
    }, [isClicked]);
    
const handleExport = async () => {
    setIsExporting(true);
    setTimeout(async () => {
        if (viewShotRef.current) {
            const isMobile = window.innerWidth < 768;
            const exportScale = isMobile ? 1.5 : 2; // Lower scale for mobile

            try {
                const dataUrl = await domtoimage.toPng(viewShotRef.current, { 
                    quality: 1, 
                    bgcolor: "#FFFFFF", 
                    scale: exportScale 
                });
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = `Store Allocation-${DateTime.getDate(date)}.png`;
                link.click();
                setIsClicked(false);
            } catch (error) {
                console.error('Error exporting image with dom-to-image:', error);
                try {
                    const canvas = await html2canvas(viewShotRef.current, { scale: exportScale });
                    const dataUrl = canvas.toDataURL('image/png');
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = `Store Allocation-${DateTime.getDate(date)}.png`;
                    link.click();
                    setIsClicked(false);
                } catch (error) {
                    console.error('Error exporting image with html2canvas:', error);
                }
            } finally {
                setIsExporting(false);
            }
        }
    }, 100);
};


    const groupDetailByLocationAndShift = () => {
        return detail.reduce((acc, item) => {
            const key = `${item.location_id}-${item.shift_id}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item.user_id);
            return acc;
        }, {});
    };

    const groupedDetail = groupDetailByLocationAndShift(detail);
console.log('isExporting>>>------------------------> ', isExporting);
    return (
        <div>
            <ShareModalContent
                viewShotRef={viewShotRef}
                locationList={locationList}
                filteredShifts={filteredShifts}
                date={date}
                detail={groupedDetail}
                userList={userList}
                isVisible={isExporting}
            />
        </div>
    );
};

export default ShareModal;
