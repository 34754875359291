import React from "react";
import { PulseLoader } from 'react-spinners';

const Spinner = (props) => {


  return (
    <div className="h-100 w-100 sweet-loading">
      <PulseLoader
        loading={true}
        size={25}
        color="var(--three-bounce-color)"
        cssOverride={{ backgroundColor: "transparent" }}
      />
    </div>
  );
}

export default Spinner;
