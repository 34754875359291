import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Filter from "../../../components/Filter";
import JobsSelect from "../../../components/JobsSelect";
import Select from "../../../components/Select";
import Currency from "../../../components/Currency";
import Text from "../../../components/Text";
import Number from "../../../components/Number";

// Lib
import Url from "../../../lib/Url";
import NumberHelper from "../../../lib/Number";
import ArrayList from "../../../lib/ArrayList";

// Actions
import { fetchList } from "../../../actions/table";

// API
import { endpoints } from "../../../api/endPoints";

const CandidateFilter = (props) => {
  let { history, genderOptions, maritialStatusOptions } = props;

  const [pageSize, setPageSize] = useState(Url.GetParam("pageSize"));
  const [searchItem, setSearch] = useState(Url.GetParam("search"));
  const [spinValue, setSpin] = useState(false);
  const [allParams, setAllParams] = useState({ ...Url.GetAllParams() });
  const [jobList, setJobList] = useState([]);

  let dispatch = useDispatch();

  const UpdateUrl = (params) => {
    const currentPage = window.location.pathname;
    let queryString = "";
    const queryStringArray = Object.entries(params);

    if (queryStringArray.length > 0) {
      queryString = "?";
      queryStringArray.forEach(async (queryParam) => {
        if (queryParam[1] !== "")
          queryString = `${queryString}&${queryParam[0]}=${queryParam[1]}`;
      });
    }
    if (history) {
      history.push(`${currentPage}${queryString}`);
    }
  };

  let filterForm = (
    <>
      <div className="row d-block px-0 py-0">
        <div className="col">
          <JobsSelect
            label={" "}
            name="position"
            placeholder="Select Position"
            setJobList={setJobList}
          />
        </div>
        <div className="col">
          <Select
            name="gender"
            placeholder="Select Gender"
            options={genderOptions}
          />
        </div>
        <div className="col">
          <Select
            name="maritalStatus"
            placeholder="Select Marital Status"
            options={maritialStatusOptions}
          />
        </div>
        <div className="col">
          <Number name="phone" placeholder="Enter Phone Number" maxLength={10} />
        </div>
        <div className="col">
          <Text name="name" placeholder="Enter Name" />
        </div>
        <div className="col">
          <Text name="email" placeholder="Enter Email" />
        </div>
        <div className="col">
          <Text name="age" placeholder="Enter Age" />
        </div>
        <div className="col">
          <Text name="qualification" placeholder="Enter Qualification" />
        </div>
        <div className="col">
          <Text name="department" placeholder="Enter Department" />
        </div>
        <div className="col">
          <Number name="yearOfPassing" placeholder="Enter Year Of Passing" />
        </div>
        <div className="col">
          <Text name="stayingIn" placeholder="Enter Staying In" />
        </div>
        <div className="col">
          <Currency name="expectedSalary" placeholder="Enter Expected Salary" />
        </div>
        <div className="col">
          <Text name="currentCity" placeholder="Enter Current City" />
        </div>
        <div className="col">
          <Text name="currentState" placeholder="Enter Current State" />
        </div>
        <div className="col">
          <Text name="permanentCity" placeholder="Enter Permanent City" />
        </div>
        <div className="col">
          <Text name="permanentState" placeholder="Enter Permanent State" />
        </div>
        <div className="col">
          <Text name="messagge" placeholder="Enter Message" />
        </div>
      </div>
    </>
  );

  let getInitialValue = (key) => {
    if (NumberHelper.isNotNull(allParams[key])) {
      return allParams[key];
    } else {
      return "";
    }
  };

  let filterInitialValues = {
    position: ArrayList.isArray(jobList)
      ? jobList.find((value) => value?.value == getInitialValue("position"))
      : "",
    gender: ArrayList.isArray(genderOptions)
      ? genderOptions.find((value) => value?.value == getInitialValue("gender"))
      : "",
    maritalStatus: ArrayList.isArray(maritialStatusOptions)
      ? maritialStatusOptions.find(
        (value) => value?.value == getInitialValue("maritalStatus")
      )
      : "",
    name: getInitialValue("name"),
    email: getInitialValue("email"),
    age: getInitialValue("age"),
    qualification: getInitialValue("qualification"),
    department: getInitialValue("department"),
    yearOfPassing: getInitialValue("yearOfPassing"),
    stayingIn: getInitialValue("stayingIn"),
    expectedSalary: getInitialValue("expectedSalary"),
    currentCity: getInitialValue("currentCity"),
    currentState: getInitialValue("currentState"),
    permanentCity: getInitialValue("permanentCity"),
    permanentState: getInitialValue("permanentState"),
    messagge: getInitialValue("messagge"),
    phone: getInitialValue("phone"),
  };

  const handleSearchTermChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setAllParams((preValue) => ({
      ...preValue,
      search: searchValue,
    }));
  };

  const onSearchKeyUp = async (event) => {
    if (event.charCode === 13) {
      let params = {
        ...allParams,
        search: searchItem,
      };

      dispatch(
        fetchList(
          "Candidate",
          `${endpoints().candidate}/list`,
          Url.GetParam("page") ? Url.GetParam("page") : 1,
          Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
          params
        )
      );
      UpdateUrl(params);
    }
  };

  const handlePageSize = async (value) => {
    let params = {
      ...allParams,
      pageSize: value,
    };
    setPageSize(value);
    dispatch(
      fetchList(
        "Candidate",
        `${endpoints().candidate}/list`,
        Url.GetParam("page") ? Url.GetParam("page") : 1,
        value,
        params
      )
    );
    UpdateUrl(params);
    setAllParams((preValue) => ({
      ...preValue,
      pageSize: value,
    }));
  };

  const refreshButtonOnClick = async () => {
    const filteredValues = Object.fromEntries(
      Object.entries(allParams)
        .filter(([key, _]) => !isNaN(key))
        .map(([_, value]) => {
          const [prop, val] = value?.split("=");
          return [prop, val];
        })
    );
    setSpin(true);
    dispatch(
      fetchList(
        "Candidate",
        `${endpoints().candidate}/list`,
        Url.GetParam("page") ? Url.GetParam("page") : 1,
        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
        { ...filteredValues, ...allParams }
      )
    );
    setSpin(false);
  };

  const onSearchKeyDown = async (event) => {
    if (event.key === "Enter") {
      let params = {
        ...allParams,
        search: searchItem,
      };

      dispatch(
        fetchList(
          "Candidate",
          `${endpoints().candidate}/list`,
          Url.GetParam("page") ? Url.GetParam("page") : 1,
          Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
          params
        )
      );
      UpdateUrl(params);
    }
  };

  const handleFilter = (values) => {
    values.gender = values?.gender ? values?.gender?.value : "";
    values.maritalStatus = values?.maritalStatus
      ? values?.maritalStatus?.value
      : "";
    values.position = values?.position ? values?.position?.value : "";
    let filterParams = ArrayList.toQueryString(values);
    const filteredValues = Object.fromEntries(
      Object.entries(filterParams)
        .filter(([key, _]) => !isNaN(key))
        .map(([_, value]) => {
          const [prop, val] = value?.split("=");
          return [prop, val];
        })
    );
    dispatch(
      fetchList(
        "Candidate",
        `${endpoints().candidate}/list`,
        Url.GetParam("page") ? Url.GetParam("page") : 1,
        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
        filteredValues
      )
    );
    UpdateUrl(filteredValues);
    setAllParams(filteredValues);
  };

  const handleDeleteFilter = async (removedFilter) => {
    let params = {
      ...allParams,
      ...removedFilter,
    };
    dispatch(
      fetchList(
        "Candidate",
        `${endpoints().candidate}/list`,
        Url.GetParam("page") ? Url.GetParam("page") : 1,
        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
        params
      )
    );
    UpdateUrl(params);
    setAllParams(params);
  };

  return (
    <div>
      <Filter
        showHeader
        newTableHeading
        pageSearchOnChange={(e) => {
          handleSearchTermChange(e);
        }}
        sortByDropdown
        pageSearchValue={searchItem}
        searchPlaceholder="Search"
        getPageSizeByOptions={(e) => handlePageSize(e)}
        selectedPageSize={pageSize && pageSize}
        refreshButtonOnClick={refreshButtonOnClick}
        refreshValue={spinValue}
        showPageSize
        handleDeleteFilter={handleDeleteFilter}
        initialValues={filterInitialValues}
        handleFilter={handleFilter}
        onKeyPress={onSearchKeyUp}
        onKeyDown={onSearchKeyDown}
        customChild={filterForm}
        handleColumnChange={props.handleColumnChange}
        FieldLabel={props.FieldLabel}
        arrayList={props.arrayList}
        DropdownWithCheckbox
      />
    </div>
  );
};

export default CandidateFilter;
