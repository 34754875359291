/* Add PORTAL_USER */
export const REQUEST_CREATE_TICKET = "REQUEST_CREATE_TICKET";
export const RECEIVE_CREATE_TICKET = "RECEIVE_CREATE_TICKET";
export const TICKET_CREATE_ERROR = "TICKET_CREATE_ERROR";
export const REQUEST_ADD_TICKET_MEDIA = "REQUEST_ADD_TICKET_MEDIA";
export const RECEIVE_ADD_TICKET_MEDIA = "RECEIVE_ADD_TICKET_MEDIA";
export const TICKET_MEDIA_ADD_ERROR = "TICKET_MEDIA_ADD_ERROR";
export const RECEIVE_DELETE_MEDIA = "RECEIVE_DELETE_MEDIA";
export const REQUEST_DELETE_MEDIA = "REQUEST_DELETE_MEDIA";
export const MEDIA_DELETE_ERROR = "MEDIA_DELETE_ERROR";
// Update Ticket
export const REQUEST_UPDATE_TICKET = "REQUEST_UPDATE_TICKET";
export const RECEIVE_UPDATE_TICKET = "RECEIVE_UPDATE_TICKET";
export const TICKET_UPDATE_ERROR = "COMPANYTICKET";

//  Delete Ticket
/* Delete Store */
export const REQUEST_DELETE_TICKET = "REQUEST_DELETE_TICKET";
export const RECEIVE_DELETE_TICKET = "RECEIVE_DELETE_TICKET";
export const TICKET_DELETE_ERROR = "TICKET_DELETE_ERROR";