import React from 'react';
import Select from './Select';

const ProductSearch = (props) => {
    const {
        className,
        storeProductList,
        onSearch,
        onInputChange,
        label,
        name,
    } = props;

    // Sort the product list
    const getProductList = storeProductList.sort((a, b) =>
        a.value > b.value ? 1 : -1
    );

    // Handle empty list
    const options =
        getProductList.length > 0
            ? getProductList
            : [
                {
                    value: "",
                    label: (
                        <div
                            style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                padding: "15px",
                                color:"red"
                            }}
                        >
                            No record found
                        </div>
                    ),
                    isDisabled: true,
                },
            ];

    return (
        <div className={className}>
            <Select
                fullWidth={true}
                label={label ? label : "Product"}
                name={name ? name : "storeProduct"}
                onSearch={onSearch}
                required
                options={options}
                showVirtualizedMenu={true}
                onInputChange={onInputChange}
            />
        </div>
    )
}

export default ProductSearch;