import React, { useState, useEffect, useRef } from "react";
import Avatar from "../../../components/Avatar";
import UserSelect from "../../../components/UserSelect";
import Form from "../../../components/Form";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import moment from "moment";
import { FaPaperclip, FaTimes } from "react-icons/fa";
import MessageBubble from "./MessageBubble";
import { hasPermission } from "../../../services/UserRolePermissionService";
import Permission from "../../../helpers/Permission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPaperPlane, faXmark } from "@fortawesome/free-solid-svg-icons";
import Image from "../../../components/Image";
import MessagesService from "../../../services/MessagesService";
import ArrayList from "../../../lib/ArrayList";
import Toast from "../../../components/Toast";
import Media from "../../../lib/Media";
import { connectSocket, getSocketInstance } from "../../../services/SocketService";
import { isVideoURL } from "../../../components/static/imageCarousel";
import VideoPlayer from "../../../components/VideoPlayer";
import { Modal } from "reactstrap";

const DirectMessage = (props) => {
  let {
    refresh,
    logUser,
    isOpen,
    selectedUserIds,
    setSelectedUserIds,
    closeModel,
    getMessageList,
    setAttachment,
    attachment,
    setFileName,
    fileName,
    params,
    activeUser,
    autoRefresh
  } = props;
  let messengerValue = params?.path == "user"
  const [userList, setUserList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState({});
  const [isForWardModalOpen, setIsForWardModalOpen] = useState(false);
  const [forwardMessageDetail, setForwardMessageDetail] = useState(false);
  const [imageModal, setImageModal] = useState(false);

  useEffect(() => {
      if (autoRefresh) {
  
        const interval = setInterval(() => {
          if(messengerValue){
            fetchData();
            }
        }, 60000);
    
        return () => clearInterval(interval); // Cleanup on unmount or when autoRefresh changes
      }
    }, [autoRefresh,messengerValue]);
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("messageAttachment"));
    if (storedData) {
      if (storedData && storedData.userId == activeUser?.id) {
        setAttachment && setAttachment(storedData.previewURL);
        setFileName && setFileName(storedData.name);
      } else {
          setAttachment && setAttachment("");
          setFileName && setFileName("");
      }
    }else{
       setAttachment && setAttachment("");
        setFileName && setFileName("");
    }
    fetchData();
  }, [params?.id]);
  let socket = getSocketInstance();
  useEffect(() => {
    if (logUser && logUser?.id) {
      if (!socket || !socket.connected) {
        socket = connectSocket(logUser?.id);
      }
      if (!socket) return;

      const handleReceiveMessage = (data) => {
        if (data) {
          fetchData()
        }
      };
      socket.on("receiveMessage", handleReceiveMessage);
      return () => {
        socket.off("receiveMessage", handleReceiveMessage);
      };
    }
  }, [logUser]);
  let deletePermission = hasPermission(Permission.MESSAGE_DELETE);
  let manageOthersPermission = hasPermission(Permission.MESSAGE_MANAGE_OTHERS);
  const textAreaRef = useRef(null);

  const refreshValue = async (e) => {
    fetchData();
  };
useEffect(() => {
    const handleDragOver = (event) => {
      event.preventDefault(); // Prevent default behavior (like opening the file in a new tab)
    };

    const handleDrop = (event) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
    if (file) {
      if (!file.type.startsWith("image/") && !file.type.startsWith("video/")) {
        Toast.error("Please upload an image or video file only!");
        return;
    }
      setFileName(file?.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result;
        setAttachment && setAttachment(base64Image);
        if(file.type.startsWith("image/")){

        if(activeUser?.id ){
          localStorage.setItem(
            "messageAttachment",
            JSON.stringify({
              userId: activeUser?.id,
              previewURL: base64Image,
              name:file.name
            })
          );
        }else{
            localStorage.setItem(
              "messageAttachment",
              JSON.stringify({
                userId: logUser?.id,
                previewURL: base64Image,
                name:file.name
              })
            );
        }
      }
    }
      };
    };

    // Add event listeners
    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);

    // Cleanup on unmount
    return () => {
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
    };
  }, []);
  useEffect(() => {
      setSelectedIds(selectedUserIds)
      const storedData = JSON.parse(localStorage.getItem("messageAttachment"));
    
    if (storedData) {
      if (storedData && storedData?.userId == logUser?.id) {
        setAttachment && setAttachment(storedData?.previewURL);
        setFileName && setFileName(storedData?.name);
      } else {
          setAttachment && setAttachment("");
          setFileName && setFileName("");
      }
    }
    setMessages([])
  }, [selectedUserIds,!messengerValue]);
  
  
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [currentMessage[params?.id]]);

  // Function to scroll instantly to the bottom
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  // Scroll on mount & when messages update
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const messagesContainerRef = useRef(null);
  
  // handle attachment
  const handleAttachmentChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.startsWith("image/") && !file.type.startsWith("video/")) {
      Toast.error("Please upload an image or video file only!");
      return;
  }
    if (file) {
      setFileName(file?.name)
      const reader = new FileReader();
      reader.readAsDataURL(file); // ✅ Convert to Base64
      reader.onload = () => {
        const base64Image = reader.result; // ✅ Get Base64 string
          setAttachment(base64Image); // ✅ Show image preview directly
       
        if(file.type.startsWith("image/")){
        if(activeUser?.id ){
          localStorage.setItem(
            "messageAttachment",
            JSON.stringify({
              userId: activeUser?.id,
              previewURL: base64Image,
              name:file.name
            })
          );
        }else{
            localStorage.setItem(
              "messageAttachment",
              JSON.stringify({
                userId: logUser?.id,
                previewURL: base64Image,
                name:file.name
              })
            );
        }
      };
    }
    }
  };
  // Get message data
  const fetchData = async () => {
    if(params?.id && messengerValue){
    let response = await MessagesService.getMessages(
     params?.id
    );

    const receiverMessages = response?.data?.receiverMessages.map(
      (message, index) => ({
        message: message.message,
        isSender: false,
        timestamp: new Date(message.timestamp).getTime(), // Convert timestamp to milliseconds
        id: message?.id,
        media_url: message?.media_url,
      })
    );

    const senderMessages = response?.data?.senderMessages.map(
      (message, index) => ({
        message: message.message,
        isSender: true,
        timestamp: new Date(message.timestamp).getTime(), // Convert timestamp to milliseconds
        read_at: message?.read_at,
        id: message?.id,
        media_url: message?.media_url,
      })
    );
    const allMessages = [
      ...(receiverMessages || []),
      ...(senderMessages || []),
    ];

    allMessages.sort((a, b) => a.timestamp - b.timestamp);
    setMessages(allMessages);
  }
  };

  // Send Message
  const handleSendMessage = async () => {
    const dataObj = new FormData();
    let message = {
      message: currentMessage[params?.id],
      isSender: logUser?.id == activeUser?.id ? false : true,
      timestamp: new Date().getTime(), // Convert timestamp to milliseconds
      id: null,
      media_url: attachment,
    };
    setMessages((prev) => [...prev, message]);

    let data = {
      message: currentMessage[params?.id],
      reciever_user_id: ArrayList.isArray(selectedUserIds)
        ? selectedUserIds
        : ArrayList.isArray(selectedIds)
        ? selectedIds
        : activeUser?.id,
      attachment: attachment ? attachment : "",
    };

    if (attachment) {
      const file = Media.ConvertBase64ToFile(attachment, fileName);
      dataObj.append("media_file", file ? file : "");
      dataObj.append("media_name", fileName ? fileName : "");
    }

    dataObj.append("message", currentMessage[params?.id] ? currentMessage[params?.id] : "");
    if (!currentMessage[params?.id] && !attachment) {
      return null;
    }

    dataObj.append(
      "reciever_user_id",
      ArrayList.isArray(selectedIds) ? selectedIds : activeUser?.id
    );
    if (ArrayList.isEmpty(data?.reciever_user_id)) {
      return Toast.error("Select Ids");
    }
    let response = await MessagesService.Create(dataObj);
    setAttachment(null)
    setSelectedIds([]);
    socket.emit("sendMessage", {
      senderId: logUser?.id,
      receiverId: data.reciever_user_id,
      message: currentMessage[params?.id],
      media_url: attachment || null,
      isSender: true,
      timestamp: new Date(),
    });
    if (response) {

      setCurrentMessage({});
      setAttachment(null);
      fetchData();
      localStorage.removeItem("messageAttachment");
      setSelectedUserIds && setSelectedUserIds([]);
      getMessageList && getMessageList();
      closeModel && closeModel();
    }
  };

  const handleChange = (values) => {
    let valueData =
      values && values.length > 0 ? values.map((value) => value.id) : "";
    setSelectedIds(valueData);
  };
  let userValueData = [];
  if (userList && userList.length > 0) {
    if (selectedIds && selectedIds.length > 0) {
      for (let i = 0; i < selectedIds.length; i++) {
        let value = userList.find((value) => value.id == selectedIds[i]);
        userValueData.push(value);
      }
    }
  }

  // drawer initial values
  const forwardInitialValues = {
    user: "",
  };

  const forWordModalForm = (
    <UserSelect
      name="user"
      handleUserChange={handleChange}
      userList={setUserList}
      isMulti
      required
      showLocationName={true}
      showShiftName={true}
    />
  );

  const forwardFooter = (
    <div className="overflow-hidden">
      <div className="col-sm-10 text-center">
        <Button type="submit" label="Forward" showButtonLabelName />
      </div>
    </div>
  );

  const openToggle = () => {
    setIsForWardModalOpen(true);
  };

  const closeToggle = () => {
    setIsForWardModalOpen(false);
  };

  const handleForward = async (values) => {
    await MessagesService.forward(
      { messageId: forwardMessageDetail?.id, reciever_user_ids: values?.user },
      (err, res) => {
        if (res) {
          fetchData();
          setIsForWardModalOpen(false);
          window.refreshMessages && window.refreshMessages();
        }
      }
    );
  };

  // group message by date
  const groupMessagesByDate = (messages) => {
    const grouped = messages.reduce((acc, msg) => {
      const dateKey = moment(msg.timestamp).format("DD-MMM-YY");
      if (!acc[dateKey]) acc[dateKey] = [];
      acc[dateKey].push(msg);
      return acc;
    }, {});

    return Object.keys(grouped)
      .sort((a, b) => new Date(b) - new Date(a)) // 🔹 Sort dates in descending order (newest first)
      .reduce((acc, key) => {
        acc[key] = grouped[key].sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp) // 🔹 Messages still sorted ascending within each date
        );
        return acc;
      }, {});
  };

  // remove media
  const removeAttachment = () => {
    setAttachment(null);
    localStorage.removeItem("messageAttachment");
  };

  // Use it
  const groupedMessages = groupMessagesByDate(messages);
  const handleMessageChange = (value) => {
    try{
    setCurrentMessage((prev) => ({
      ...prev,
      [params?.id]: value, // Update message for the specific id
    }));
  }catch(err){
    console.log(err);
  }
  };
  let locationPart = activeUser?.current_location_name ? `${activeUser?.current_location_name}` : "";
  let shiftPart = activeUser?.current_shift_name ? `${activeUser?.current_shift_name}` : "";
  let locationShiftPart =
    locationPart && shiftPart
      ? `(${locationPart}, ${shiftPart})`
      : locationPart
      ? `(${locationPart})`
      : shiftPart
      ? `(${shiftPart})`
      : "";
  // return
  return (
    <div
      className={`bg-white d-flex flex-column ${isOpen ? "" : `max-h-[86%]`}`}
    >
            {imageModal && isVideoURL(attachment) && (
        <Modal
          isOpen={imageModal}
          onRequestClose={() => {
            setImageModal(false);
          }}
          contentLabel="Video Modal"
        >
          <div className="modal-content p-0">
            <div className="d-flex justify-content-end">
              <FontAwesomeIcon
                icon={faXmark}
                className="cursor-pointer align-right fw-bold h3 mt-0 pt-0"
                onClick={() => {
                  setImageModal(false);
                }}
              />
            </div>
            <VideoPlayer
              className="react-player"
              url={attachment}
              controls={true}
              style={{ backgroundImage: attachment }}
              volume={0}
              playInlineVideo={true}
              setImageModal={setImageModal}
            />
          </div>
        </Modal>
      ) }
      {/* Messages Container */}
      <div
        ref={messagesContainerRef}
        className="overflow-auto flex-grow-1"
        style={{ scrollbarWidth: "none" }}
      >
        {!isOpen && messengerValue &&(
          <div
            className="d-flex align-items-center justify-content-between p-2 px-3 shadow-sm"
            style={{
              backgroundColor: "#075E54", // WhatsApp green
              color: "white",
              width: "100%",
              position: "sticky",
              top: 0,
              zIndex: 1,
              height: "60px", // Fixed WhatsApp-style height
            }}
          >
            {/* Left: Profile Avatar & Name */}
            <div className="d-flex align-items-center">
              <Avatar
                id="avatar"
                url={activeUser?.media}
                firstName={activeUser?.first_name}
                lastName={activeUser?.last_name}
              />
              <span className="mx-1 fw-bold">
                {activeUser?.first_name} {activeUser?.last_name}
              </span>
              {activeUser?.showLocationShiftPermission && locationShiftPart && (
            <span className="m-0" style={{ fontSize: "15px"}}>
              {locationShiftPart}
            </span>
          )}
              
            </div>
            <button onClick={refreshValue} className="btn">
              <i
                className={`fa ${
                  refresh ? "fa-sync fa-spin" : "fa-refresh"
                } text-white`}
              />
            </button>
          </div>
        )}

        <Drawer
          modelTitle={"Users"}
          DrawerBody={forWordModalForm}
          initialValues={forwardInitialValues}
          DrawerFooter={forwardFooter}
          handleOpenModal={openToggle}
          handleCloseModal={closeToggle}
          handleDrawerClose={closeToggle}
          isModalOpen={isForWardModalOpen}
          onSubmit={(values) => {
            handleForward(values);
          }}
          enableReinitialize
        />

        {isOpen && (
          <div>
            <Form
              initialValues={{ user: userValueData || "" }}
              enableReinitialize
            >
              <div className="card card-body shadow">
                <div className="d-flex justify-content-between pt-1">
                  <div className="col-10 mx-1">
                    <UserSelect
                      name="user"
                      handleUserChange={handleChange}
                      userList={setUserList}
                      isMulti
                      showLocationName={true}
                      showShiftName={true}
                      isnore
                    />{" "}
                  </div>
                  <div className="col-4">
                    <Button
                      label="Bulk Select"
                      onClick={() => {
                        props?.setBulkSelect(!props?.bulkSelect);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form>
            {props?.bulkSelect && (
              <Drawer
                modelTitle={"User"}
                DrawerBody={addActivityForm}
                initialValues={{ user: "" }}
                DrawerFooter={activityFooter}
                handleOpenModal={toggle}
                handleCloseModal={toggle}
                handleDrawerClose={toggle}
                isModalOpen={props?.bulkSelect}
                onSubmit={(values) => {
                  toggle(values);
                }}
                enableReinitialize
              />
            )}
          </div>
        )}

        <div
          className="text-wrap overflow-auto mt-1 pb-3 pb-0 flex-grow-1 h-screen"
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            scrollbarColor: "transparent transparent",
          }}
        >
          {Object.entries(groupedMessages).map(([date, msgs], dateIndex) => (
            <div key={dateIndex}>
              {/* Date Separator */}
              <div className="text-center">
                <span
                  className="px-3 py-1 bg-secondary text-white rounded"
                  style={{ fontSize: "12px" }}
                >
                  {date}
                </span>
              </div>

              {/* Messages */}
              {msgs.map((msg, index) => (
                <div
                  key={index}
                  className={`d-flex p-2 align-items-center ${
                    msg.isSender
                      ? "justify-content-end"
                      : "justify-content-start"
                  }`}
                >
                  {(msg?.message || msg?.media_url) && !msg.isSender && (
                    <Avatar
                      id="avatar"
                      size="xs"
                      url={activeUser?.media || activeUser?.userImage}
                      firstName={activeUser?.first_name}
                      lastName={activeUser?.last_name}
                    />
                  )}
                  {(msg?.message || msg?.media_url) && (
                    <MessageBubble
                      msg={msg}
                      showDeleteButton={deletePermission}
                      manageOthersPermission={manageOthersPermission}
                      fetchData={fetchData}
                      setIsForWardModalOpen={setIsForWardModalOpen}
                      setForwardMessageDetail={setForwardMessageDetail}
                    />
                  )}
                  {msg.isSender && (
                    <Avatar
                      size="xs"
                      id="avatar"
                      url={logUser?.avatarUrl}
                      firstName={logUser?.name}
                      lastName={logUser?.lastName}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col p-1 sticky bottom-0 w-full">
      <div className="flex flex-col p-1 sticky bottom-0 w-full">
        {/* Chat Input Section */}
        <div className="d-flex align-items-center">
           <div>
                      <input
                        type="file"
                        id="attachment"
                        style={{ display: "none" }}
                        accept="image/*,video/*"
                        onChange={(event) => {
                          handleAttachmentChange(event); // Handle file selection
                        }}
                      />
                      <FontAwesomeIcon
                        size="2x"
                        className="icon-pointer text-success"
                        icon={faCirclePlus}
                        onClick={() => document.getElementById("attachment").click()}
                      />        {/* Preview Section */}
                 
          
                    </div>
          <div className="flex items-center p-2 mx-1 bg-white rounded-full border border-gray-300 shadow-sm w-100">

             {attachment && attachment.trim() !== "" && (
          <div
            className="d-flex align-items-start justify-content-end pr-2"
            style={{
              position: "relative",
              width: "50",
              zIndex: 1,
            }}
          >
            {/* Attachment Image */}
            {isVideoURL(fileName) ? (
  <VideoPlayer
    className="react-player"
    url={attachment}
    controls={true}
    style={{ backgroundImage: attachment }}
    volume={0}
    height="50px"
    playInlineVideo={true}
    setImageModal={setImageModal}
  />
) :(
            <Image
              src={attachment}
              alt="No Image"
              className=""
              width="50px"
              height="50px"
              alignRight
              borderRadius="15px"
            />
)}
            {/* Close (X) Button */}
            <FaTimes              
            style={{
                position: "absolute",
                right: "6px",
                cursor: "pointer",
                color: "white",
                backgroundColor: "red",
                borderRadius: "30%",
                fontSize: "12px",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
              }}
              onClick={removeAttachment}
            />
          </div>
        )}
          <textarea
            ref={textAreaRef}
            className="flex-grow-1 border-0"
            placeholder="Type your message here..."
            value={currentMessage[params?.id] || ""}
            onChange={(e) => handleMessageChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
            rows={1}
            style={{
              resize: "none",
              overflow: "hidden",
              width: "100%",
              outline: "none",
              background: "transparent",
            }}
          />
          <label className="cursor-pointer pr-1">
          <FaPaperclip
        size={24}
        color="gray"
        style={{ cursor: "pointer", marginLeft: "10px" }}
        onClick={() => document.getElementById("attachment").click()}
      />
          </label>
          <button
            className="relative cursor-pointer"
            onClick={handleSendMessage}
          >
            {/* Paper Plane Icon */}
            <FontAwesomeIcon icon={faPaperPlane} color="green" size="2x" />

            {/* Attachment Count - Positioned on Top Right */}
            {attachment && (
              <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold rounded-full px-1.5 py-0.5">
                1
              </span>
            )}
          </button>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default DirectMessage;
