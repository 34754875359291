import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import AttendanceTypeService from "../../services/AttendanceTypeService";
import { statusOptions, Type } from "../../helpers/AttendanceType";
import ArrayList from "../../lib/ArrayList";
import DateTime from "../../lib/DateTime";
import Numbers from "../../lib/Number";
import AttendanceTypeForm from "./components/AttendanceTypeForm";
import UserRoleService from "../../services/UserRoleService";
import HorizontalSpace from "../../components/HorizontalSpace";
import Action from "../../components/Action";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";

const DetailPage = (props) => {
  const [detail, setDetail] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const [statusOption, setStatusOption] = useState([]);
  const [isEditable, setIsEditable] = useState(true);

  const dispatch = useDispatch();
  let hourOption = DateTime.getHours(12);
  let daysArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let showEditButton = hasPermission(Permission.ATTENDANCE_TYPE_DELETE);

  useEffect(() => {
    getDetails();
    getUserRoleList();
  }, []);

  const Toggle = () => {
    setIsOpen(!isOpen);
  };

  const categoryList = [
    {
      value: Type.WORKING_DAY,
      label: Type.WORKING_DAY_TEXT,
    },
    {
      value: Type.LEAVE,
      label: Type.LEAVE_TEXT,
    },
  ];

  const getDetails = async () => {
    let data = await AttendanceTypeService.get(props.match.params.id);
    setDetail(data);
  };

  const getUserRoleList = async () => {
    const roleData = await UserRoleService.list();
    setUserRole(roleData);
  };

  const checkBoxInitialValues = (values) => {
    let obj = {};
    let checkBoxs = [
      "allow_late_checkin",
      "is_additional_shift",
      "show_in_salary_slip",
    ];
    for (let i = 0; i < checkBoxs.length; i++) {
      const key = checkBoxs[i];
      if (Numbers.isNotNull(values) && Numbers.isNotNull(values[key])) {
        obj[key] = values[key];
      } else {
        obj[key] = "";
      }
    }
    return obj;
  };

  const daysCheckBoxInitialValues = (values) => {
    let obj = {};
    let splitKey = values?.allowed_days?.split(",") || [];
    for (let i = 0; i < daysArray.length; i++) {
      const key = daysArray[i];
      if (
        Numbers.isNotNull(values) &&
        Numbers.isNotNull(values?.allowed_days) &&
        splitKey.includes(key)
      ) {
        obj[key] = true;
      } else {
        obj[key] = false;
      }
    }
    return obj;
  };

  let allowedRoleValue = [];

  let splitRoleIds = (detail && detail?.allowed_roles?.split(",")) || [];
  ArrayList.isArray(splitRoleIds) &&
    splitRoleIds.forEach((values) => {
      let isRecord =
        (ArrayList.isArray(userRole) &&
          userRole.find((data) => data?.value == values)) ||
        null;
      if (isRecord) {
        allowedRoleValue.push(isRecord);
      }
    });

  let initialValue = {
    name: detail ? detail?.name : "",
    days_count: detail ? detail?.days_count : "",
    status: statusOptions.find((data) => data?.value == detail?.status),
    cutoff_time:
      hourOption &&
      hourOption.find(
        (data) => data.value === Numbers.Get(detail?.cutoff_time) || ""
      ),
    maximum_allowed: detail ? detail?.maximum_allowed : "",
    ...checkBoxInitialValues(detail),
    ...daysCheckBoxInitialValues(detail),
    allowed_roles: ArrayList.isArray(allowedRoleValue) ? allowedRoleValue : "",
    category: categoryList.find((value) => value?.value == detail?.category),
    default_status: ArrayList.isArray(statusOption)
      ? statusOption.find((data) => data?.value == detail?.default_status)
      : "",
    date: detail ? detail?.date : "",
  };

  const handleSubmit = async (values) => {
    let allowedRoleIds = ArrayList.isArray(values?.allowed_roles)
      ? values?.allowed_roles?.map((data) => data?.value)
      : [];
    let data = new FormData();
    data.append("name", values && values?.name ? values?.name : "");
    data.append(
      "status",
      values && values?.status ? values?.status?.value : ""
    );
    data.append(
      "days_count",
      values && values?.days_count ? values?.days_count : ""
    );
    data.append(
      "maximum_allowed",
      values && values?.maximum_allowed ? values?.maximum_allowed : ""
    );
    data.append(
      "cutoff_time",
      values && values?.cutoff_time ? values?.cutoff_time?.value : ""
    );
    data.append(
      "default_status",
      values && values?.default_status ? values?.default_status?.value : ""
    );
    data.append("date", values && values?.date ? values?.date : "");
    let checkBoxs = [
      "allow_late_checkin",
      "is_additional_shift",
      "show_in_salary_slip",
    ];

    for (let i = 0; i < checkBoxs.length; i++) {
      const key = checkBoxs[i];
      data.append(key, values[key] ? values[key] : false);
    }

    let days = [];
    let keys = Object.keys(values);
    daysArray.forEach((key) => {
      if (keys?.includes(key) && values[key]) {
        days.push(key);
      }
    });

    data.append("days", ArrayList.isArray(days) ? days.join(",") : "");
    data.append("allowed_role_ids", allowedRoleIds);
    data.append(
      "category",
      values && values?.category ? values?.category?.value : ""
    );
    dispatch(
      await AttendanceTypeService.update(props.match.params.id, data, () => {
        setIsEditable(true);
      })
    );
  };

  const deleteFunction = async () => {
    dispatch(
      await AttendanceTypeService.delete(props.match.params.id, (res) => {
        if (res) {
          Toggle();
          props.history.push("/setting/Attendance");
        }
      })
    );
  };

  const breadcrumbList = [
    { label: "Home", link: "/admin/companyProfile" },
    { label: "Attendance Types", link: "/setting/Attendance" },
    { label: "Attendance Types Detail", link: "" },
  ];

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  if (showEditButton && isEditable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "edit") {
      setIsEditable(false);
    }
    if (e == "delete") {
      Toggle();
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={isOpen}
        toggle={Toggle}
        title="Delete AttendanceType"
        label={detail?.name}
        deleteFunction={deleteFunction}
      />
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between">
        <div>
          <PageTitle label="Detail Page" />
        </div>
        <div className="d-flex">
          <HorizontalSpace paddingbottom="10" />
          <div className="pb-3">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>
      <div className="card card-body">
        <Form
          initialValues={initialValue}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          <AttendanceTypeForm
            daysArray={daysArray}
            showSaveButton
            roleList={userRole}
            categoryList={categoryList}
            statusOption={setStatusOption}
            history={props.history}
            isEditable={isEditable}
          />
        </Form>
      </div>
    </>
  );
};

export default DetailPage;
