import { endpoints } from "../../api/endPoints";
import BreadCrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import DateTime from "../../lib/DateTime";

const SalesSettlementMissingReport = (props) => {

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report",
    },
    {
      label: "Sales Settlement Missing Report",
      link: "",
    },
  ];

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <PageTitle label="Sales Settlement Missing Report" className="mb-3" />
      <ReduxTable
        id={"salesSettlementMissingReport"}
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().salesSettlementMissingReport}`}
        params={{}}
        sortByDropdown
        history={props?.history}
        paramsToUrl={true}
        showCustomDateFilter
      >
        <ReduxColumn
          field="date"
          sortBy="date"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => <span>{DateTime.getDate(row.date)}</span>}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="shift"
          sortBy="shift"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Shift
        </ReduxColumn>
        <ReduxColumn
          field="locationName"
          sortBy="locationName"
          className="text-center"
          width="140px"
          minWidth="140px"
          maxWidth="140px"
        >
          Location
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default SalesSettlementMissingReport;
