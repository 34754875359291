import React from "react";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import BreadCrumb from "../../components/Breadcrumb";

// API
import { endpoints } from "../../api/endPoints";

// Lib
import Currency from "../../lib/Currency";

const AccountEntryReportList = (props) => {
  const { history } = props;

  const sortOptions = [
    {
      value: "name:ASC",
      label: "Name",
    },
  ];

  // Breadcrumb list
  const breadcrumbList = [
    { label: "Home", link: "/accountDashboard" },
    { label: "Report", link: "/accountReports" },
    { label: "AccountEntryReport", link: "" },
  ];

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />

      <ReduxTable
        id="accountEntryReport"
        sortByOptions={sortOptions}
        showHeader
        newTableHeading
        showCustomDateFilter
        showTagFilter
        tagPlaceholder="Select Category"
        tagFilterType={{
          type: "Account Entry Category",
        }}
        apiURL={`${endpoints().accountEntryReportAPI}/search`}
        message="You can start by clicking Add New"
        paramsToUrl={true}
        customTypeOption={[
          {
            value:"Account Entry Category",
            label:"Account Entry Category",
          }
        ]}
        history={history}
        searchPlaceholder="Search"
        hideQueryParams={{type:true}}
      >
        <ReduxColumn
          field="name"
          width="200px"
          maxWidth="170px"
          minWidth="170px"
        >
          Category
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          width="200px"
          maxWidth="170px"
          minWidth="170px"
          className="text-end"
          renderField={(row) => <span>{Currency.Format(row.amount)}</span>}
        >
          Amount
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

export default AccountEntryReportList;
