import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";

// Components
import AddButton from "../../components/AddButton";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import StatusText from "../../components/StatusText";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import AppSettingForm from "./components/AppSettingForm";

// Lib
import Url from "../../lib/Url";

// Services
import AppService from "../../services/AppService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Helpers
import App from "../../helpers/App";
import { portalTemplate } from "../../helpers/Support";
import Permission from "../../helpers/Permission";

const AppsSetting = (props) => {
    let { history } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [row, setRow] = useState(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [hostUrlValue, setHostUrlValue] = useState();
    const [templateValue, setTemplateValue] = useState(null);
    const [statusValue, setStatusValue] = useState(null);
    const [nameChangeValue, setNameChangeValue] = useState(null);
    const [nameSpaceChangeValue, setNameSpaceChangeValue] = useState(null);

    let dispatch = useDispatch();

    let hasDeletePermission = hasPermission(Permission.APP_DELETE);
    let showAddButton = hasPermission(Permission.APP_ADD);

    const _toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleAddButtonClick = () => {
        _toggle();
        setRow("");
    };

    const openToggle = () => {
        setIsOpen(true)
    }

    const closeToggle = () => {
        setIsOpen(false);
        setRow(null);
        setTemplateValue("");
        setStatusValue("");
        setNameChangeValue("");
        setNameSpaceChangeValue("");
        setHostUrlValue("")
    }

    const closeDeleteToggle = () => {
        setIsDeleteOpen(false)
        setRow(null)
    }

    const handleHostUrlChange = (e) => {
        let value = e?.target?.value;
        setHostUrlValue(value);
    };

    const handleTemplateChange = (e) => {
        let value = e?.values?.template;
        setTemplateValue(value);
    };

    const handleStatusChange = (e) => {
        let value = e;
        setStatusValue(value);
    };

    const handleNameChange = (e) => {
        let value = e?.target?.value;
        setNameChangeValue(value);
    };

    const handleNameSpaceChange = (e) => {
        let value = e?.target?.value;
        setNameSpaceChangeValue(value);
    };

    const customStatusOption = [
        {
            value: "",
            label: "All",
        },
        {
            label: App.STATUS_ACTIVE_TEXT,
            value: App.STATUS_ACTIVE
        },
        {
            label: App.STATUS_INACTIVE_TEXT,
            value: App.STATUS_INACTIVE
        }
    ]

    let modelBody = (
        <>
            <AppSettingForm
                handleTemplateChange={handleTemplateChange}
                handleHostUrlChange={handleHostUrlChange}
                handleStatusChange={handleStatusChange}
                handleNameChange={handleNameChange}
                handleNameSpaceChange={handleNameSpaceChange}
            />
        </>
    );

    let modelFooter = (
        <>
            <SaveButton label={row ? "Save" : "Add"} />
        </>
    )

    let initialValues = {
        name: nameChangeValue ? nameChangeValue : row?.name ? row?.name : "",
        nameSpace: nameSpaceChangeValue ? nameSpaceChangeValue : row?.nameSpace ? row?.nameSpace : "",
        status: statusValue
            ? customStatusOption &&
            customStatusOption.find((data) => data?.value == statusValue?.value)
            : customStatusOption.find((data) => data?.value == row?.statusId),
        template: templateValue
            ? portalTemplate &&
            portalTemplate.find((data) => data?.value == templateValue?.value)
            : portalTemplate.find((data) => data?.value == row?.template),
        host_url: hostUrlValue ? hostUrlValue : row?.host_url ? row?.host_url : "",
    };

    let handleSubmit = async (values) => {
        let data = new FormData()
        data.append("name", values && values?.name ? values?.name : "")
        data.append("nameSpace", values && values?.nameSpace ? values?.nameSpace : "")
        data.append("status", values && values?.status ? values?.status?.value : "");
        data.append("template", values && values?.template ? values?.template?.value : "");
        data.append("host_url", values && values?.host_url ? values?.host_url : "");
        if (row?.id) {
            data.append("id", row?.id);
            dispatch(await AppService.update(data, (res) => {
                dispatch(
                    fetchList(
                        "apps",
                        `${endpoints().appAPI}/search`,
                        Url.GetParam("page") ? Url.GetParam("page") : 1,
                        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                        {
                            ...Url.GetAllParams()
                        }
                    )
                );
                closeToggle()
            }))
        } else {
            dispatch(await AppService.create(data, (res) => {
                dispatch(
                    fetchList(
                        "apps",
                        `${endpoints().appAPI}/search`,
                        Url.GetParam("page") ? Url.GetParam("page") : 1,
                        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                        {
                            ...Url.GetAllParams()
                        }
                    )
                );
                closeToggle()
            }))
        }
    }

    const handleDelete = async (value) => {
        dispatch(await AppService.delete({ data: { id: row?.id } }, (res) => {
            dispatch(
                fetchList(
                    "apps",
                    `${endpoints().appAPI}/search`,
                    Url.GetParam("page") ? Url.GetParam("page") : 1,
                    Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                    {
                        ...Url.GetAllParams()
                    }
                )
            );
            closeToggle()
        }))
    }

    const sortByOption = [
        {
            value: "id:DESC",
            label: "Most Recent",
        },
        {
            value: "name:ASC",
            label: "Name",
        },
    ];


    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <PageTitle label="Apps" />
                {showAddButton && (
                    <AddButton
                        className="ms-2 me-1"
                        label="Add"
                        onClick={handleAddButtonClick}
                    />
                )}
            </div>
            <DeleteModal
                isOpen={isDeleteOpen}
                toggle={closeDeleteToggle}
                title="Delete Image"
                label={row?.name}
                deleteFunction={handleDelete}
            />
            <Drawer
                isModalOpen={isOpen}
                handleOpenModal={openToggle}
                handleCloseModal={closeToggle}
                handleDrawerClose={closeToggle}
                modelTitle={row ? "Edit Apps" : "Add Apps"}
                DrawerBody={modelBody}
                DrawerFooter={modelFooter}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
                hideDefaultButtons
            />
            <ReduxTable
                id="apps"
                searchPlaceholder="Search"
                apiURL={`${endpoints().appAPI}/search`}
                newTableHeading
                paramsToUrl={true}
                history={props.history}
                sortByOptions={sortByOption}
                params={{
                    status: Url.GetParam("status"),
                }} showStatusFilter
                customStatusOption={customStatusOption}
            >
                <ReduxColumn
                    field="name"
                    sortBy="name"
                    type="link"
                    className="no-underline"
                    renderField={(row) => (
                        <Link
                            to={`/admin/settings/app/${row.id}`}
                            className="link-opacity-75"
                        >
                            {row.name}
                        </Link>
                    )}
                >
                    Name
                </ReduxColumn>
                <ReduxColumn
                    field="nameSpace"
                    sortBy="name_space"
                    className="text-center"
                >
                    Name Space
                </ReduxColumn>
                <ReduxColumn
                    field="host_url"
                    sortBy="host_url"
                    className="text-center"
                >
                    Host Url
                </ReduxColumn>
                <ReduxColumn
                    field="template"
                    sortBy="template"
                    className="column-template"
                    renderField={(row) => {
                        const template = portalTemplate.find(value => value.value === row.template);
                        return template ? template.label : "";
                    }}
                >
                    Template
                </ReduxColumn>
                <ReduxColumn
                    field="status"
                    sortBy="status"
                    className="column-status"
                    renderField={(row) => (
                        <StatusText
                            backgroundColor={row.statusColor}
                            status={row.status}
                        />
                    )}
                >
                    Status
                </ReduxColumn>
                <ReduxColumn
                    field="Action"
                    disableOnClick
                    width="120px"
                    renderField={(row) => (
                        <div className="text-center action-group-dropdown">
                            <MoreDropdown>
                                <DropdownItem
                                    onClick={() => {
                                        setRow(row)
                                        openToggle()
                                    }}
                                >
                                    Quick View
                                </DropdownItem>
                                {hasDeletePermission && (
                                    <DropdownItem
                                        className="text-danger"
                                        onClick={() => {
                                            setRow(row)
                                            setIsDeleteOpen(true)
                                        }}
                                    >
                                        Delete
                                    </DropdownItem>
                                )}
                            </MoreDropdown>
                        </div>
                    )}
                >
                    Action
                </ReduxColumn>
            </ReduxTable>
        </>
    );
};

export default AppsSetting;
