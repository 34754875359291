export const CandidateStatus = {
    STATUS_NEW: "NEW",
};

export const FieldLabel = {
    AGE: "Age",
    CURRENT_CITY: "Current City",
    CURRENT_STATE: "Current State",
    DEPARTMENT: "Department",
    EMAIL: "Email",
    EXPECTED_SALARY: "Expected Salary",
    GENDER: "Gender",
    MARITAL_STATUS: "Marital Status",
    MESSAGE: "Message",
    PERMANENT_CITY: "Permanent City",
    PERMANENT_STATE: "Permanent State",
    PHONE: "Phone",
    POSITION: "Position",
    QUALIFICATION: "Qualification",
    STAYING_WITH: "Staying With",
    YEAR_OF_PASSING: "Year Of Passing",
  };