import React, { useState } from "react";

// Components
import PageTitle from "../../components/PageTitle";
import CallRegisterList from "./components/callRegisterList";
import AddButton from "../../components/AddButton";

// Services
import { hasPermission } from "../../services/UserRolePermissionService";

// Helpers
import Permission from "../../helpers/Permission";

const callRegisterListPage = (props) => {
  let { history } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasAddPermission = hasPermission(Permission.CALL_REGISTER_ADD);

  const openToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      <div className="d-flex justify-between mb-3">
        <PageTitle
          label={"Call Register"}
        />
        {hasAddPermission && (
          <AddButton
            label="Add"
            onClick={(e) => {
              openToggle();
            }}
          />
        )}
      </div>

      <CallRegisterList
        history={history}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default callRegisterListPage;
