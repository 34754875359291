import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Text from "../../../components/Text";
import Form from "../../../components/Form.js";
import SaveButton from "../../../components/SaveButton.js";
import Select from "../../../components/Select.js";
import CancelButton from "../../../components/CancelButton.js";
import SelectStore from "../../../components/SelectStore.js";
import SingleCheckbox from "../../../components/SingleCheckbox.js";
import HourComponent from "../../../components/HourComponent.js";

// Helpers
import { OrderType } from "../../../helpers/OrderTypeGroup.js";


// Services
import orderTypeService from "../../../services/OrderTypeService.js";

// Lib
import String from "../../../lib/String.js";
import Url from "../../../lib/Url.js";
import Number from "../../../lib/Number.js";
import DateTime from "../../../lib/DateTime.js";

// Actions
import { fetchList } from "../../../actions/table.js";

// API
import { endpoints } from "../../../api/endPoints.js";

const OrderTypeForm = (props) => {
  const { details, closeToggle, getDetails, enableDeliveryDate, enableDeliveryTime, enableDeliveryExecutive, editable } = props;
  const dispatch = useDispatch();
  const [orderTypeName, setOrderTypeName] = useState();
  const [customerSelection, setCustomerSelection] = useState("");
  const [storeList, setStoreList] = useState([]);
  const [store, setStore] = useState();
  const [deliveryOrder, setdDeliveryOrder] = useState(false);
  const [storeOrder, setStoreOrder] = useState(false);
  const [showDeliveryExecutive, setShowDeliveryExecutive] = useState(false);
  const [showDeliveryTime, setShowDeliveryTime] = useState(false);
  const [showDeliveryDate, setShowDeliveryDate] = useState(false);
  let hourOption = DateTime.getHours(1);


  let customerSelectInfoOptions = [
    { label: "Disabled", value: 0 },
    { label: "Optional", value: 1 },
    { label: "Required", value: 2 },
  ];

  const orderTypeNameChange = (e) => {
    let value = e.target.value;
    setOrderTypeName(value);
  };

  const handleStoreChange = (values) => {
    setStore(values && values.value);
  };

  const showCustomerChange = (e) => {
    try {
      let value = e.collect_customer_info ? Number.Get(e.collect_customer_info?.value) : "";
      setCustomerSelection(value);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeliveryOrder = (e) => {
    try {
      let value = e.allow_delivery ? e.allow_delivery : "";
      setdDeliveryOrder(value);
    } catch (err) {
      console.log(err);
    }
  };

  const handleStoreOrder = (e) => {
    try {
      let value = e.allow_store_order ? e.allow_store_order : "";
      setStoreOrder(value);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowDeliveryExecutive = (e) => {
    try {
      let value = e.show_delivery_executive ? e.show_delivery_executive : "";
      setShowDeliveryExecutive(value);
    } catch (err) {
      console.log(err);
    }
  }

  const handleShowDeliveryTime = (e) => {
    try {
      let value = e.show_delivery_time ? e.show_delivery_time : "";
      setShowDeliveryTime(value);
    } catch (err) {
      console.log(err);
    }
  }

  const handleShowDeliveryDate = (e) => {
    try {
      let value = e.show_delivery_date ? e.show_delivery_date : "";
      setShowDeliveryDate(value);
    } catch (err) {
      console.log(err);
    }
  }

  const updateData = async (values) => {

    try {
      const id = details && details?.id;
      const data = new FormData();

      data.append("name", values && String.Get(values?.name));
      data.append("default_location", values?.default_location && (values?.default_location?.value));
      data.append("collect_customer_info", values && Number.Float(values?.collect_customer_info?.value));
      data.append("allow_delivery", values && values?.allow_delivery == true ? OrderType.ENABLE_DELIVERY_ORDER : OrderType.DISABLE_DELIVERY_ORDER);
      data.append("allow_store_order", values && values?.allow_store_order == true ? OrderType.ENABLE_STORE_ORDER : OrderType.DISABLE_STORE_ORDER);
      data.append("delivery_time", values && values?.delivery_time ? values?.delivery_time?.value : "");
      data.append("show_delivery_date", values && values?.show_delivery_date == true ? OrderType.ENABLE_DELIVERY_DATE : OrderType.DISABLE_DELIVERY_DATE);
      data.append("show_delivery_time", values && values?.show_delivery_time == true ? OrderType.ENABLE_DELIVERY_TIME : OrderType.DISABLE_DELIVERY_TIME);
      data.append("show_delivery_executive", values && values?.show_delivery_executive == true ? OrderType.ENABLE_DELIVERY_EXECUTIVE : OrderType.DISABLE_DELIVERY_EXECUTIVE);
      let params = {
        ...Url.GetAllParams(),
      };
      if (Number.isNotNull(id)) {
        await orderTypeService.update(id, data, (res) => {
          if (res && closeToggle) {
            dispatch(
              fetchList(
                "OrderType",
                `${endpoints().orderTypeAPI}/search`,
                1,
                25,
                params
              )
            );
            closeToggle && closeToggle();
          }
          getDetails && getDetails();
        });
      } else {
        dispatch(await orderTypeService.create(data, params, closeToggle));
      }
    } catch (err) {
      console.log(err);
    }
  };

  // initialValues
  const initialValues = {
    name: orderTypeName ? orderTypeName : details?.name || "",
    collect_customer_info: customerSelectInfoOptions.find((data) => data.value == (customerSelection || details?.collect_customer_info)),
    allow_store_order: storeOrder ? storeOrder : details?.allow_store_order,
    allow_delivery: deliveryOrder ? deliveryOrder : details?.allow_delivery,
    show_delivery_date: showDeliveryDate ? showDeliveryDate : details?.show_delivery_date,
    show_delivery_time: showDeliveryTime ? showDeliveryTime : details?.show_delivery_time,
    show_delivery_executive: showDeliveryExecutive ? showDeliveryExecutive : details?.show_delivery_executive,
    default_location: store
      ? storeList.find((data) => data.value == store) : details?.defaultLocation ?
        storeList.find((data) => data.label == details?.defaultLocation) : details?.default_location ? storeList.find((data) => data.value == details?.default_location) : "",
    delivery_time:
      hourOption &&
      hourOption.find(
        (data) => data.value === Number.Get(details?.delivery_time) || ""
      ),
  };

  return (
    <>
      {/* Form */}
      <div className="col">
        <Form
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => {
            updateData(values);
          }}
        >
          <div className="row">
            <div className={props.form == "Add" ? "col-lg-12 col-sm-6" : "col-lg-6 col-sm-6"}>
              <Text
                name="name"
                label="Name"
                placeholder="Enter Name"
                onChange={orderTypeNameChange}
                disabled={editable}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className={props.form == "Add" ? "col-lg-12 col-sm-6" : "col-lg-6 col-sm-6"}>
              <SelectStore
                label="Default Location"
                name="default_location"
                placeholder="Select Default Location"
                handleStoreChange={handleStoreChange}
                StoreList={setStoreList}
                defaultValue={store}
                isDisabled={editable}
              />
            </div>
          </div>
          <div className="row">
            <div className={props.form == "Add" ? "col-lg-12 col-sm-6" : "col-lg-6 col-sm-6"}>
              <Select
                name="collect_customer_info"
                label=" Collect Customer Info"
                options={customerSelectInfoOptions}
                className="accepted-terms mb-2 pb-0"
                clearable={false}
                isDisabled={editable}
                handleOnChangeSubmit={(value) =>
                  showCustomerChange(value)
                }
              />
            </div>
          </div>
          <br />
          <SingleCheckbox
            name="allow_store_order"
            label="Store Order"
            className="accepted-terms mb-1 pb-0"
            enabled={editable}
            handleOnChangeSubmit={(value) =>
              handleStoreOrder(value)
            }
          />
          <br />
          <SingleCheckbox
            name="allow_delivery"
            label="Allow Delivery"
            enabled={editable}
            className="accepted-terms mb-1 pb-0"
            handleOnChangeSubmit={(value) =>
              handleDeliveryOrder(value)
            }
          />
          <br />
          {enableDeliveryDate && (
            <>
              <SingleCheckbox
                name="show_delivery_date"
                label="Show Delivery Date"
                enabled={editable}
                className="accepted-terms mb-1 pb-0"
                handleOnChangeSubmit={(value) =>
                  handleShowDeliveryDate(value)
                }
              />
              <br />
            </>
          )}
          {enableDeliveryTime && (
            <>
              <SingleCheckbox
                name="show_delivery_time"
                label="Show Delivery Time"
                enabled={editable}
                className="accepted-terms mb-1 pb-0"
                handleOnChangeSubmit={(value) =>
                  handleShowDeliveryTime(value)
                }
              />
              <br />
            </>
          )}
          {enableDeliveryExecutive && (
            <>
              <SingleCheckbox
                name="show_delivery_executive"
                label="Show Delivery Executive"
                className="accepted-terms mb-1 pb-0"
                enabled={editable}
                handleOnChangeSubmit={(value) =>
                  handleShowDeliveryExecutive(value)
                }
              />
              <br />
            </>
          )}
          {(deliveryOrder || details?.allow_delivery) && (
            <HourComponent
              name="delivery_time"
              label="Delivery Time"
              range={1}
              isDisabled={editable}
            />)}
          <br />
          {!editable && (
            <div
              className={props.form == "Add" ? "position-absolute bottom-2 start-0 end-0 bg-white z-3" : ""}
            >
              <div
                className={
                  props.form == "Add" ? "d-flex justify-content-center" : "mx-3"
                }
              >
                <div className="mt-1 mb-4 pb-4 mb-md-0 pb-md-0">
                  <SaveButton />

                  {((props.form == "Add") || (props.form === "Edit")) && (
                    <CancelButton
                      onClick={() => {
                        if (props.form == "Add") {
                          closeToggle();
                        } else {
                          props.history.push("/setting/Order");
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default OrderTypeForm;
