import React, { useState } from 'react'
import ReduxTable, { ReduxColumn } from '../../../components/reduxTable';
import { endpoints } from '../../../api/endPoints';
import Link from "../../../components/Link"
import MoreDropdown from '../../../components/authentication/moreDropdown';
import { DropdownItem } from 'react-bootstrap';
import Drawer from '../../../components/Drawer';
import Text from '../../../components/Text';
import SaveButton from '../../../components/SaveButton';
import LocationRackService from '../../../services/LocationRackService';
import { useDispatch } from 'react-redux';
import { fetchList } from '../../../actions/table';
import DeleteModal from '../../../components/DeleteModal';
import StatusText from '../../../components/StatusText';
import Select from '../../../components/Select';
import LocationRack from '../../../helpers/LocationRack';

const LocationRackTab = (props) => {
  let { history, setIsDrawerModelOpen, isDrawerModelOpen, locationId } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [rowValue, setRowValue] = useState(null);



  let dispatch = useDispatch();


  let statusOptios = [
    {
      label: LocationRack.STATUS_ACTIVE_TEXT,
      value: LocationRack.STATUS_ACTIVE,
    },
    {
      label: LocationRack.STATUS_INACTIVE_TEXT,
      value: LocationRack.STATUS_INACTIVE,
    }
  ]


  let modelBody = (
    <>
      <Text name="name" label="Name" required />
      {rowValue?.id && <Select name="status" label="Status" options={statusOptios} />}
    </>
  );

  let modelFooter = (
    <>
      <SaveButton label={rowValue ? "Edit" : "Add"} />
    </>
  );

  let handleSubmit = async (values) => {
    let data = new FormData();
    data.append("name", values && values?.name);
    data.append("location_id", locationId);


    if (rowValue?.id) {
      data.append("status", values?.status?.value);
      dispatch(await LocationRackService.update(rowValue?.id, data, (res) => {
        dispatch(fetchList("locationRack", `${endpoints().LocationRackAPI}/search`, 1, 25, {
          location_id: locationId
        }));
        closeToggle()
      }))
    } else {
      dispatch(await LocationRackService.create(data, (res) => {
        dispatch(fetchList("locationRack", `${endpoints().LocationRackAPI}/search`, 1, 25, {
          location_id: locationId
        }));
        closeToggle()
      }))
    }



  };

  let toggleOpen = () => {
    setIsDrawerModelOpen(!isDrawerModelOpen)
  };

  let closeToggle = () => {
    setIsDrawerModelOpen(!isDrawerModelOpen)
    setRowValue(null)
  }


  const handleDelete = async (row) => {
    dispatch(await LocationRackService.delete(rowValue?.id, (res) => {
      dispatch(fetchList("locationRack", `${endpoints().LocationRackAPI}/search`, 1, 25, {
        location_id: locationId
      }));
    }))
  }

  return (
    <>
      <Drawer
        modelTitle={`${rowValue ? "Edit" : "Add"} Add Location Rack`}
        DrawerBody={modelBody}
        DrawerFooter={modelFooter}
        onSubmit={(values) => handleSubmit(values)}
        initialValues={{
          name: rowValue?.name ? rowValue?.name : "",
          status: statusOptios.find((data) => data?.value == rowValue?.statusId)
        }}
        handleOpenModal={toggleOpen}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isDrawerModelOpen}
        enableReinitialize={true}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false);
        }}
        title="Delete Location Rack"
        deleteFunction={() => {
          handleDelete();
        }}
        label={rowValue?.name}
        id={rowValue?.id}
      />
      <ReduxTable
        id="locationRack"
        paramsToUrl={true}
        apiURL={`${endpoints().LocationRackAPI}/search`}
        params={{
          location_id: locationId
        }}
        history={history}
        newTableHeading
        sortByDropdown
        searchPlaceholder="Search"
      >
        <ReduxColumn
          field="name"
          width="140px"
          sortBy="name"
          minWidth="140px"
          maxWidth="140px"
          renderField={(row) => (
            <div className="d-flex">
              <Link
                url={"#"}
                className="link-opacity-75"
                text={row.name}
                onClick={()=>{
                  toggleOpen();
                  setRowValue(row)
                }}
              />
            </div>)}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy="status"
          width="110px"
          className="text-center"
          renderField={(row) => (
            <StatusText backgroundColor={row?.statusColor} status={row?.status} />
          )}
        >
          Status
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          width="110px"
          maxWidth="110px"
          minWidth="120px"
          className="text-center"
          disableOnClick
          renderField={(row) => (
            <div className="action-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    toggleOpen();
                    setRowValue(row)
                  }}
                >
                  Quick View
                </DropdownItem>
                <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setRowValue(row)
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable></>
  )
}

export default LocationRackTab;
