import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { SUCCESS_RESPONSE, isBadRequest } from "../lib/Http";


class CurrencyDenominationService{

    static async create(data, callback) {
        await apiClient
            .post(`${endpoints().CurrencyDenominationAPI}/create`, data)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                    return callback(successMessage)
                }
            })
            .catch((err) => {
                if (isBadRequest(err)) {
                    let errorMessage;
                    const errorRequest = err.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                }
            });
    }

    static update = (id, data, callback) => {
          apiClient
            .put(`${endpoints().CurrencyDenominationAPI}/update/${id}`, data)
            .then((response) => {
              let successMessage;
              if (response && response.data) {
                successMessage = response.data.message;
                Toast.success(successMessage);
                return callback && callback(successMessage)
              }
            })
            .catch((error) => {
              if (isBadRequest(error)) {
                let errorMessage;
                const errorRequest = error.response.request;
                if (errorRequest && errorRequest.response) {
                  errorMessage = JSON.parse(errorRequest.response).message;
                }
                Toast.error(error.response.data.message);
                console.error(errorMessage);
              }
            });
       
      };

      static async delete(id, callback) {
        await apiClient
            .delete(`${endpoints().CurrencyDenominationAPI}/delete/${id}`)
            .then((res) => {
                if (res.status == SUCCESS_RESPONSE) {
                    Toast.success(res?.data?.message);
                    return callback(res?.data?.message)
                }
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(error.response.data.message);
                    console.error(errorMessage);
                }
            });
    }
}
export default CurrencyDenominationService;