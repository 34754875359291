/**
 * Is data True
 * @param value
 */
export const isTrue = (value) => {
  if (value == "true") {
    return true;
  } else return false;
};

/**
 * Is data False
 * @param value
 */
export const isFalse = (value) => {
  if (value != "true") {
    return true;
  } else return false;
};

export const isValidDraftFormat = (jsonString) => {
  try {
    const parsedData = JSON.parse(jsonString);
    return (
      Array.isArray(parsedData.blocks) &&
      parsedData.blocks.length > 0 &&
      typeof parsedData.blocks[0] === 'object' &&
      typeof parsedData.blocks[0].key === 'string' &&
      typeof parsedData.blocks[0].text === 'string' &&
      typeof parsedData.blocks[0].type === 'string' &&
      typeof parsedData.blocks[0].depth === 'number' &&
      Array.isArray(parsedData.blocks[0].inlineStyleRanges) &&
      Array.isArray(parsedData.blocks[0].entityRanges) &&
      typeof parsedData.blocks[0].data === 'object' &&
      typeof parsedData.entityMap === 'object'
    );
  } catch (error) {
    return false;
  }
};

export const convertTextToDraftFormat = (text) => {
  if (text) {
    const jsonObject = {
      blocks: [
        {
          key: '5l88h',
          text: text,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    };

    const jsonString = JSON.stringify(jsonObject);
    return jsonString;
  }
};