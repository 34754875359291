import {
  faBuildingColumns,
  faClipboard,
  faTableCellsLarge,
  faSackDollar,
  faList,
  faFileInvoice,
  faMoneyBillTransfer,
  faMoneyCheck,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";

// Helpers
import permission from "../helpers/Permission";
import Url from "../helpers/Url";

// Services
import { hasPermission } from "../services/UserRolePermissionService";

export function getAccountNavList(setting, permissionList) {
  let showAccountEntry = hasPermission(permission.ACCOUNT_ENTRY_VIEW);
  let showAccount = hasPermission(permission.ACCOUNT_VIEW);
  let showBill = hasPermission(permission.BILL_VIEW);
  let showCategory = hasPermission(permission.CATEGORY_VIEW);
  let showBankSettlement = hasPermission(permission.BANK_SETTLEMENT_VIEW);
  let showPayment = hasPermission(permission.PAYMENT_VIEW);

  let showPaymentAccount = hasPermission(permission.PAYMENT_ACCOUNT_VIEW);
  let arrayList = [];

  arrayList = arrayList.concat({
    name: "Dashboard",
    url: "/accountDashboard",
    icon: faTableCellsLarge,
  });
  if (showAccount) {
    arrayList = arrayList.concat({
      name: "Accounts",
      url: Url.Account_LIST,
      detailsPageurl: `/accounts`,
      icon: faBuildingColumns,
    });
  }
  if (showBill) {
    arrayList = arrayList.concat({
      name: "Bills",
      url: "/bill",
      detailsPageurl: "/bill/detail",
      icon: faFileInvoice,
    });
  }

  if (showBankSettlement) {
    arrayList = arrayList.concat({
      name: "Bank Settlements",
      url: "/bankSettlements",
      detailsPageurl: `/bankSettlement/`,
      icon: faBuildingColumns,
    });
  }
  if (showPayment) {
    arrayList = arrayList.concat({
      name: "Payments",
      url: "/payment",
      detailsPageurl: "/payment/detail",
      icon: faSackDollar,
    });
  }
  if (showAccountEntry) {
    arrayList = arrayList.concat({
      name: "Account Entries",
      url: "/accountEntry",
      detailsPageurl: "/accountEntry/details",
      icon: faMoneyBillTransfer,
    });
  }


  if (showCategory) {
    arrayList = arrayList.concat({
      name: "Categories",
      url: "/category",
      icon: faList,
    });
  }
  if (showPaymentAccount) {
    arrayList = arrayList.concat({
      name: "Payment Accounts",
      url: "/paymentAccount",
      detailsPageurl: "/paymentAccount/detail",
      icon: faMoneyCheck,
    });
  }
  arrayList = arrayList.concat({
    name: "Gst",
    url: Url.GST,
    icon: faMapLocationDot,
    horizontalLine: true
  });
  arrayList = arrayList.concat({
    name: "Reports",
    url: "/accountReports",
    detailsPageurl: "/accountReports/",
    AccountEntryReport: "/accountReports/AccountEntryReport",
    SalesGstReport: "/accountReports/SalesGstReport",
    PurchaseGstReport: "/accountReports/PurchaseGstReport",
    icon: faClipboard,
  });

  return arrayList;
}


