import React from 'react';
import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { Page } from "../../helpers/Page";

const HomeSetting = (props) => {

  const pageList = [
    {
      label: Page.HOME_TEXT,
      id: Page.HOME
    }
  ]

  return (
    <div>
      <PageTitle label="Home Page" />
      <table className='w-100' style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: 'black' }}>
            <th className="text-white text-center" style={{ border: '1px solid black', padding: '10px' }}>Home Sections</th>
          </tr>
        </thead>
        <tbody>
          {pageList.map(row => (
            <tr key={row.label} style={{ border: '1px solid black' }}>
              <td className='text-white' style={{ border: '1px solid lightgray', padding: '10px' }}>
                <Link
                  to={`/setting/Statues/Home/pageBlock/${row.id}`}
                  className='link-opacity-75 text-decoration-none lh-lg'
                >
                  {row.label}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default HomeSetting
