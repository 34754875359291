import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MessageCard from "../../../components/MessageCard";
import MessageChannelService from "../../../services/MessageChannelService";
import MessagesService from "../../../services/MessagesService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PageSearch from "../../../components/PageSearch";

const SideBar = (props) => {
  let {
    handleClick,
    handleGroupChange,
    messageList,
    channelsList,
  } = props;

  const params = useParams();
  const [channelList, setChannelList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    getChannelList();
  }, []);

  useEffect(() => {
    getMessageList();
  }, [!messageList]);

  useEffect(() => {
    setMessages(messageList);
    props.setDirectMessageList && props.setDirectMessageList(messageList);
  }, [messageList]);

  useEffect(() => {
    setChannelList(channelsList);
  }, [channelsList]);

  const getChannelList = async () => {
    let response = await MessageChannelService.search();
    let data = response?.data?.data;
    setChannelList(data);
    props?.setChannelList && props?.setChannelList(data);
  };

  const getMessageList = async () => {
    let response = await MessagesService.search();
    let data = response?.data?.data;
    setMessages(data);
    props.setDirectMessageList && props.setDirectMessageList(data);
  };

  window.refreshMessages = getMessageList;

  const formatMessageChannelList = (messages = [], channels = []) => {
    return [
      ...(Array.isArray(channels) ? channels.map((data) => ({
        channel_id: data?.channel_id,
        type: "channel",
        name: data?.channel_name,
        last_name: data?.last_name,
        media: null,
        recent_message: data?.mostRecentMessage,
        recent_message_timestamp: data?.timeStamp,
        isUnRead: false,
        showLocationShiftPermission: false,
        current_shift_name: null,
        current_location_name: null,
        channel_name: data?.channel_name,
        read_at:'',
        user_role:data?.user_role,
        user_filter_type:data?.user_filter_type,
        status:data?.status,
        location:data?.location,

      })) : []),
      ...(Array.isArray(messages) ? messages.map((data) => ({
        id: data?.id,
        type: "message",
        name: data?.first_name,
        last_name: data?.last_name,
        media: data?.media,
        recent_message: data?.recent_last_message,
        recent_message_timestamp: data?.recent_message_timestamp,
        isUnRead: data?.isUnRead,
        showLocationShiftPermission: data?.showLocationShiftPermission,
        current_shift_name: data?.current_shift_name,
        current_location_name: data?.current_location_name,
        read_at: data?.read_at,
      })) : []),
    ].sort((a, b) => 
      new Date(b.recent_message_timestamp || 0) - new Date(a.recent_message_timestamp || 0)
    );
};


let mergedList = formatMessageChannelList(messages, channelList).filter(item =>
  item.name.toLowerCase().includes(searchQuery.toLowerCase())
);
  return (
    <div className="px-2 d-flex flex-column">
       <div className="w-100 p-0">
      <PageSearch
        onSearchClick={() => {}}
        onKeyDown={() => {}}
        onChange={(event) => setSearchQuery(event.target.value.trim())}
        onKeyPress={() => {}}
        placeholder="Search"
        classnames="mb-2"
      />
      </div>
      {/* Merged List */}
      <div className="list-group overflow-auto " style={{ maxHeight: "80vh", scrollbarWidth: "none" }}>
        {mergedList?.length > 0 ? (
          mergedList.map((row) => (
            <Link
              key={row.id}
              className={`list-group-item ${params?.id == (row.channel_id || row?.id) ? "text-primary" : "text-dark"}`}
              onClick={() => row.type === "channel" ? handleGroupChange(row) : handleClick(row)}
            >
              <MessageCard
                first_name={row.name}
                last_name={row.last_name}
                last_message_time={row.recent_message_timestamp}
                recent_last_message={row.recent_message}
                url={row.media}
                read_at={row.read_at}
                unRead={row.isUnRead}
                isPermissionExist={row.showLocationShiftPermission}
                currentLocationName={row.current_location_name}
                currentShiftName={row.current_shift_name}
                isChannel={row.type === "channel"}
              />
            </Link>
          ))
        ) : (
          <p className="text-light">No messages available</p>
        )}
      </div>
    </div>
  );
};

export default SideBar;
