import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Components
import Action from "../../../components/Action";
import ActivityList from "../../../components/ActivityList";
import BreadCrumb from "../../../components/Breadcrumb";
import CancelButton from "../../../components/CancelButton";
import Currency from "../../../components/Currency";
import DateSelector from "../../../components/Date";
import DeleteModal from "../../../components/DeleteModal";
import Form from "../../../components/Form";
import HorizontalSpace from "../../../components/HorizontalSpace";
import PageTitle from "../../../components/PageTitle";
import Percentage from "../../../components/Percentage";
import SaveButton from "../../../components/SaveButton";
import StatusSelect from "../../../components/SelectStatus";
import SingleCheckbox from "../../../components/SingleCheckbox";
import Text from "../../../components/Text";

// Config
import { apiClient } from "../../../apiClient";

// API
import { endpoints } from "../../../api/endPoints";

// Lib
import Url from "../../../lib/Url";

// Helpers
import ObjectName from "../../../helpers/ObjectName";
import Permission from "../../../helpers/Permission";

// Service
import ProductPriceService from "../../../services/ProductPriceService";
import StatusService from "../../../services/StatusService";
import { hasPermission } from "../../../services/UserRolePermissionService";

// Constants
const Tab = {
  SUMMARY: "Summary",
  HISTORY: "History",
};

const ProductPriceDetail = (props) => {
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") || Tab.SUMMARY
  );
  const [productPriceDetail, setProductPriceDetail] = useState();
  const [defaultValue, setIsDefault] = useState(false);
  const [priceStatus, setPriceStatus] = useState();
  const [barCodeValue, setBarcode] = useState("");
  const [dateValue, setDate] = useState("");
  const [mrpValue, setMrp] = useState("");
  const [discountValue, setDiscount] = useState("");
  const [salePriceValue, setSalePrice] = useState("");
  const [costPriceValue, setCostPrice] = useState(null);
  const [priceStatusValue, setPriceStatusValue] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editable, setEditable] = useState(true);

  let hasEditPermission = hasPermission(Permission.PRODUCT_PRICE_EDIT);

  let hasDeletePermission = hasPermission(Permission.PRODUCT_PRICE_DELETE);

  let hasHistoryViewPermission = hasPermission(
    Permission.PRODUCT_PRICE_HISTORY_VIEW
  );

  let productPriceId = props && props?.match && props?.match?.params?.id;

  const dispatch = useDispatch();

  useEffect(() => {
    getBillDetail();
    getStatus();
  }, []);

  const getStatus = async () => {
    const status = await StatusService.getOption(ObjectName.PRODUCT_PRICE);

    setPriceStatusValue(status);
  };

  const getBillDetail = async () => {
    let id = productPriceId;
    try {
      const response = await apiClient.get(
        `${endpoints().productPrice}/detail/${id}`
      );
      const data = response.data ? response.data : "";
      setProductPriceDetail(data.data);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(errorMessage);
        console.error(errorMessage);
      }
    }
  };

  const calculateSalePrice = (mrp, discount) => {
    let mrpValue = mrp ? mrp : productPriceDetail.mrp;
    let discountValue = discount
      ? discount
      : productPriceDetail.discount_percentage;
    if (!isNaN(mrpValue) || !isNaN(discountValue)) {
      const salePrice = discountValue
        ? mrpValue - (mrpValue * discountValue) / 100
        : mrpValue;
      setSalePrice(salePrice);
    }
  };

  const initialValues = {
    mrp: mrpValue ? mrpValue : productPriceDetail ? productPriceDetail.mrp : "",
    salePrice: salePriceValue
      ? salePriceValue
      : productPriceDetail
      ? productPriceDetail.sale_price
      : "",
    costPrice: costPriceValue
      ? costPriceValue
      : productPriceDetail
      ? productPriceDetail.cost_price
      : "",
    barCode: barCodeValue
      ? barCodeValue
      : productPriceDetail
      ? productPriceDetail.barcode
      : "",
    isDefault: defaultValue
      ? defaultValue
      : productPriceDetail
      ? productPriceDetail.is_default
      : false,
    date: dateValue
      ? dateValue
      : productPriceDetail?.date
      ? productPriceDetail?.date
      : !productPriceDetail
      ? new Date()
      : "",
    discount_percentage: discountValue
      ? discountValue
      : productPriceDetail
      ? productPriceDetail.discount_percentage
      : "",
    status: priceStatus
      ? priceStatus
      : priceStatusValue &&
        priceStatusValue.find(
          (data) => data.value === productPriceDetail?.status
        ),
  };

  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Product",
      link: `/product/${productPriceDetail?.product_id}`,
    },
    {
      label: "Product Price Details",
      link: "",
    },
  ];

  const actionsMenuList = [];

  if (hasEditPermission) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  if (hasDeletePermission) {
    actionsMenuList.push({
      value: "delete",
      label: "Delete",
    });
  }

  const deleteProductPrice = async () => {
    dispatch(
      await ProductPriceService.delete(productPriceDetail.id, {
        product_id: productPriceDetail?.product_id,
        sort: "id",
        sortDir: "DESC",
      })
    );
    props.history.push(`/product/${productPriceDetail?.product_id}`);
  };

  const handleActionChange = (e) => {
    if (e == "delete") {
      setDeleteModal(true);
    }
    if (e == "edit") {
      setEditable(false);
    }
  };

  const toggle = (tab) => {
    setActiveTab(tab);

    props.history.push(`?tab=${tab}`);
  };

  const handleIsDefault = (data) => {
    let value = data && data.isDefault;
    setIsDefault(value);
  };

  const handleStatusChange = (data) => {
    let value = data;
    setPriceStatus(value);
  };

  const handleBarCode = (data) => {
    let value = data && data.target && data.target.value;
    setBarcode(value);
  };

  const handleDate = (data) => {
    const dateValue = data ? DateTime.toISOStringDate(data) : "";
    setDate(dateValue);
  };

  const handleMrpChange = (data) => {
    let value = (data && data.values && data.values.mrp) || " ";
    setMrp(value);
    calculateSalePrice(value, discountValue);
  };

  const handleDiscountPercentChange = (data) => {
    let value = (data && data.values && data.values.discount_percentage) || " ";
    setDiscount(value);
    calculateSalePrice(mrpValue, value);
  };

  const handleSalePrice = (data) => {
    let value = (data && data.values && data.values.salePrice) || " ";
    setSalePrice(value);
  };

  const handleCostPrice = (data) => {
    let value = (data && data.values && data.values.costPrice) || "";
    setCostPrice(value);
  };

  const handleSubmit = async (data) => {
    try {
      let productId = productPriceDetail?.product_id;
      dispatch(
        await ProductPriceService.update(productPriceDetail.id, data, {
          product_id: productId,
        })
      );
      setEditable(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <DeleteModal
        id={productPriceDetail?.id}
        label={productPriceDetail?.id}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Product Price"
        deleteFunction={deleteProductPrice}
      />
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between mb-3">
        <PageTitle label={`Product Price # ${productPriceId}`} />
        {actionsMenuList && actionsMenuList?.length > 0 && (
          <div className="d-flex">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        )}
      </div>

      <Nav tabs className="admin-tabs">
        {/* Detail Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.SUMMARY,
            })}
            onClick={() => {
              toggle(Tab.SUMMARY);
            }}
          >
            {Tab.SUMMARY}
          </NavLink>
        </NavItem>
        {/* History Tab */}
        {hasHistoryViewPermission && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => {
                toggle(Tab.HISTORY);
              }}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <div className="tabs-and-preview full-width d-flex admin-settings mb-4">
        <TabContent activeTab={activeTab}>
          {activeTab === Tab.SUMMARY && (
            <TabPane tabId={Tab.SUMMARY} className="w-100">
              <div className="card-body card">
                <Form
                  enableReinitialize={true}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-lg">
                      <DateSelector
                        label="Date"
                        name="date"
                        onChange={handleDate}
                        disabled={editable}
                      />
                      <Currency
                        name="mrp"
                        label="MRP"
                        required
                        onInputChange={handleMrpChange}
                        disabled={editable}
                      />
                      <Percentage
                        name="discount_percentage"
                        label="Discount %"
                        onInputChange={handleDiscountPercentChange}
                        disabled={editable}
                      />
                      <Currency
                        name="salePrice"
                        label="Sale Price"
                        onInputChange={handleSalePrice}
                        required
                        disabled={editable}
                      />
                      <Currency
                        name="costPrice"
                        label="Cost Price"
                        onInputChange={handleCostPrice}
                        disabled={editable}
                      />
                      <Text
                        name="barCode"
                        label="Barcode"
                        required
                        onChange={handleBarCode}
                        disabled={editable}
                      />
                      <StatusSelect
                        label="Status"
                        name="status"
                        objectName={ObjectName.PRODUCT_PRICE}
                        handleStatusChange={handleStatusChange}
                        isDisabled={editable}
                      />
                      <SingleCheckbox
                        name="isDefault"
                        label="Set as Default Price"
                        className="py-1"
                        handleOnChangeSubmit={handleIsDefault}
                        enabled={editable}
                      />
                    </div>
                  </div>
                  {!editable && activeTab == Tab.SUMMARY && (
                    <HorizontalSpace>
                      <>
                        <SaveButton label="Save" />
                        <CancelButton
                          onClick={() => {
                            props.history.push(
                              `/product/${productPriceDetail?.product_id}`
                            );
                          }}
                        />
                      </>
                    </HorizontalSpace>
                  )}
                </Form>
              </div>
            </TabPane>
          )}

          {/* History Tab*/}
          {activeTab == Tab.HISTORY && (
            <TabPane tabId={Tab.HISTORY} className="w-100">
              <ActivityList
                id={productPriceId}
                objectId={productPriceId}
                object_name={ObjectName.PRODUCT_PRICE}
                history={history}
              />
            </TabPane>
          )}
        </TabContent>
      </div>
    </div>
  );
};

export default ProductPriceDetail;