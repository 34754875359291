import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";

// Components
import AddButton from "../../components/AddButton";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";

// Lib
import Url from "../../lib/Url";

// Services
import HolidayService from "../../services/HolidayService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Helpers
import DateTime from "../../lib/DateTime";
import HolidayForm from "./HolidayForm";
import Permission from "../../helpers/Permission";

const HolidayList = (props) => {
    let { history } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [row, setRow] = useState(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    let dispatch = useDispatch();

    let hasDeletePermission = hasPermission(Permission.HOLIDAY_DELETE);

    const _toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleAddButtonClick = () => {
        _toggle();
        setRow("");
    };

    const openToggle = () => {
        setIsOpen(true)
    }

    const closeToggle = () => {
        setIsOpen(false)
        setRow(null)
    }

    const closeDeleteToggle = () => {
        setIsDeleteOpen(false)
        setRow(null)
    }


    let holidayForm = (
        <>
            <HolidayForm detail={row} closeToggle={closeToggle} form="Add" history={history} editable={true} />
        </>
    )
    const handleDelete = async (value) => {
        dispatch(await HolidayService.delete({ data: { id: row?.id } }, (res) => {
            dispatch(
                fetchList(
                    "holiday",
                    `${endpoints().holidayAPI}/search`,
                    Url.GetParam("page") ? Url.GetParam("page") : 1,
                    Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                    {
                        ...Url.GetAllParams()
                    }
                )
            );
            closeToggle()
        }))
    }

    const sortByOption = [
        {
            value: "id:DESC",
            label: "Most Recent",
        },
        {
            value: "name:ASC",
            label: "Name",
        },
    ];


    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <PageTitle label="Holiday" />
                <AddButton
                    className="ms-2 me-1"
                    label="Add"
                    onClick={handleAddButtonClick}
                />
            </div>
            <DeleteModal
                isOpen={isDeleteOpen}
                toggle={closeDeleteToggle}
                title="Delete Holiday"
                label={row?.name}
                deleteFunction={handleDelete}
            />
            <Drawer
                isModalOpen={isOpen}
                handleOpenModal={openToggle}
                handleCloseModal={closeToggle}
                handleDrawerClose={closeToggle}
                modelTitle={row?.name ? "Edit Holiday" : "Add Holiday"}
                DrawerBody={holidayForm}
                enableReinitialize={true}
            />
            <ReduxTable
                id="holiday"
                searchPlaceholder="Search"
                apiURL={`${endpoints().holidayAPI}/search`}
                newTableHeading
                paramsToUrl={true}
                history={history}
                sortByOptions={sortByOption}
            >
                <ReduxColumn
                    field="name"
                    sortBy="name"
                    className="text-center"
                    width="160px"
                    minWidth="160px"
                    maxWidth="160px"
                    renderField={(row) => (
                        <Link
                            to={`/admin/settings/holiday/${row.id}`}
                            className="link-opacity-75 text-decoration-none lh-lg"
                        >
                            {row.name}
                        </Link>
                    )}
                >
                    Name
                </ReduxColumn>
                <ReduxColumn
                    field="typeValue"
                    width="310px"
                    minWidth="310px"
                    maxWidth="310px"
                    className="text-center"
                    sortBy="type"
                >
                    Type
                </ReduxColumn>
                <ReduxColumn
                    field="role"
                    disableOnClick
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    className="text-center"
                    renderField={(row) => (
                        <>
                            {row?.role &&
                                row?.role.map((role, index) => (
                                    <span key={index}>
                                        {index > 0 && <br />}
                                        {role?.label}
                                    </span>
                                ))}
                        </>
                    )}
                >
                    Role
                </ReduxColumn>
                <ReduxColumn
                    field="date"
                    sortBy="date"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    className="text-center"
                    renderField={(row) => (
                        <span>{DateTime.getDate(row.date)}</span>
                    )}
                >
                    Date
                </ReduxColumn>
                <ReduxColumn
                    field="Action"
                    disableOnClick
                    width="120px"
                    renderField={(row) => (
                        <div className="text-center action-group-dropdown">
                            <MoreDropdown>
                                <DropdownItem
                                    onClick={() => {
                                        setRow(row)
                                        openToggle()
                                    }}
                                >
                                    Quick View
                                </DropdownItem>
                                {hasDeletePermission && (
                                    <DropdownItem
                                        className="text-danger"
                                        onClick={() => {
                                            setRow(row)
                                            setIsDeleteOpen(true)
                                        }}
                                    >
                                        Delete
                                    </DropdownItem>
                                )}
                            </MoreDropdown>
                        </div>
                    )}
                >
                    Action
                </ReduxColumn>
            </ReduxTable>
        </>
    );
};

export default HolidayList;
