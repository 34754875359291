import { paymentCreateError, receivePaymentAddPortal, requestAddPayment } from "../actions/payment";
import { fetchList } from "../actions/table";
import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import ArrayList from "../lib/ArrayList";
import { isBadRequest } from "../lib/Http";
import Url from "../lib/Url";


class LocationSalesGoalService {

    static async create(data,callback) {
        apiClient
      .post(`${endpoints().LocationSalesGoalApi}/create`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback(successMessage)
        }
      })
      .catch((error) => {

        if (
          error.response &&
          error.response.status >= HttpStatus.BAD_REQUEST
        ) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
    }

    static async update(id, data,callback) {
        apiClient
        .put(`${endpoints().LocationSalesGoalApi}/update/${id}`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback(successMessage)
          }
        })
        .catch((error) => {
  
          if (
            error.response &&
            error.response.status >= HttpStatus.BAD_REQUEST
          ) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
  
            Toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    }

    static async delete (id, callback){
        apiClient
        .delete(`${endpoints().LocationSalesGoalApi}/delete/${id}`)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback(successMessage)
          }
        })
        .catch((error) => {
  
          if (
            error.response &&
            error.response.status >= HttpStatus.BAD_REQUEST
          ) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
  
            Toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    }
}

export default LocationSalesGoalService;