import { APPROVED_TEXT, BLOCKED_TEXT } from "./Status";

const DeviceInfoStatus = {
PENDING : 1,
APPROVED : 2,
BLOCKED : 3,
PENDING_TEXT:"Pending",
APPROVED_TEXT:"Approved",
BLOCKED_TEXT:"Blocked",
};

export default DeviceInfoStatus;