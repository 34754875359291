import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Form from "../../components/Form";
import Currency from "../../components/Currency";
import DateSelector from "../../components/Date";
import CancelButton from "../../components/CancelButton";
import HorizontalSpace from "../../components/HorizontalSpace";
import Action from "../../components/Action";
import SaveButton from "../../components/SaveButton";
import DeleteModal from "../../components/DeleteModal";
import PageTitle from "../../components/PageTitle";
import BreadCrumb from "../../components/Breadcrumb";

// Services
import { hasPermission } from "../../services/UserRolePermissionService";
import BankSettlementsService from "../../services/BankSettlementsService";

// Helpers
import Permission from "../../helpers/Permission";

const BankSettlementsDetailPage = (props) => {
  const [detail, setDetail] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editable, setEditable] = useState(true);

  let showDelete = hasPermission(Permission.BANK_SETTLEMENT_DELETE);
  let showEditButton = hasPermission(Permission.BANK_SETTLEMENT_EDIT);

  let dispatch = useDispatch();

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    let id = props.match.params.id;
    let response = await BankSettlementsService.get(id);
    let data = response && response?.data;
    setDetail(data);
  };

  const onSubmit = async (values) => {
    let data = new FormData();
    data.append("amount", values && values?.amount ? values?.amount : "");
    data.append("date", values && values?.date ? values?.date : "");
    dispatch(
      await BankSettlementsService.update(detail?.id, data, (res) => {
        setEditable(true);
      })
    );
  };
  const handleDelete = async () => {
    dispatch(
      await BankSettlementsService.delete(props.match.params?.id, (res) => {
        if (res) {
          props.history.push("/bankSettlements");
        }
      })
    );
  };

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setOpenDeleteModal(true);
    }
  };

  const breadcrumbList = [
    { label: "Home", link: "/accountDashboard" },
    { label: "Bank Settlement", link: "/bankSettlements" },
    { label: "Bank Settlement Detail", link: "" },
  ];

  const actionsMenuList = [];

  if (showEditButton && editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  if (showDelete) {
    actionsMenuList.push({
      value: "delete",
      label: "Delete",
    });
  }
  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false);
        }}
        title="Delete BankSettlement"
        deleteFunction={() => {
          handleDelete();
        }}
        label={props.match.params.id}
      />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Detail page" />
        <div className="d-flex justify-content-between">
          <Action
            buttonLabel="Actions"
            hideCaret
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}
          />
        </div>
      </div>
      <div className="card card-body">
        <Form
          initialValues={{
            date: detail?.date ? detail?.date : "",
            amount: detail?.amount ? detail?.amount : "",
          }}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          <DateSelector name="date" label="Date" disabled={editable} />
          <Currency label="Amount" name="amount" disabled={editable} />
          {!editable && (
            <div>
              <HorizontalSpace bottom="2">
                <SaveButton label="Save" />
                <CancelButton
                  onClick={() => {
                    props.history.push("/bankSettlements");
                  }}
                />
              </HorizontalSpace>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default BankSettlementsDetailPage;
