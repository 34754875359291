import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import PageSearch from "../../components/PageSearch";
import PageTitle from "../../components/PageTitle";
import NoRecordsFound from "../../components/NoRecordsFound";

// Helpers
import ObjectName from "../../helpers/ObjectName";

const StatusDetails = (props) => {
  const { history } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStatusList, setFilteredStatusList] = useState([]);

  const handleSearch = () => {
    const statusList = ObjectName.Options;
    const filteredList = statusList.filter((data) => {
      return data.label.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredStatusList(filteredList);
  };

  const onSearchKeyUp = (event) => {
    if (event.charCode === 13) {
      handleSearch({ search: searchQuery });
    }
  };

  const onSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch({ search: searchQuery });
    }
  };

  useEffect(() => {
    handleSearch(); // Initial filtering based on search query
  }, []);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <PageTitle label="Statuses" />
      </div>

      <PageSearch
        onSearchClick={handleSearch}
        onKeyDown={onSearchKeyDown}
        onChange={(event) => setSearchQuery((event.target.value).trim())}
        onKeyPress={(e) => onSearchKeyUp(e)}
        placeholder="Search"
        classnames="w-full p-3 border border-gray-300 rounded-md shadow-sm"
      />

      <div className="overflow-x-auto mt-4">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-black text-white text-center">
              <th className="border border-gray-500 p-3">Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredStatusList && filteredStatusList.length > 0 ? (
              filteredStatusList.map(row => (
                <tr key={row.label} className="border border-gray-300">
                  <td className="border border-gray-300 p-3 text-gray-900 ">
                    <Link
                      to={`/setting/Statues/StatusList/${row.label}?object_name=${row.label}`}
                      className="text-blue-600 hover:underline"
                    >
                      {row.label}
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center p-5" colSpan="1">
                  <NoRecordsFound middleHeight="70vh" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StatusDetails;
