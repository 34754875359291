import React, { useState } from "react";
import ObjectName from "../../helpers/ObjectName";
import PageTitle from "../../components/PageTitle";
import { Nav, NavItem, NavLink, TabPane } from "reactstrap";
import classNames from "classnames";
import { Tabs } from "../../helpers/Setting";
import Url from "../../lib/Url";
import SettingsTab from "./components/SettingsTab";
import DragAndDropTable from "../../components/StatusTable/StatusDragAndDropTable";
const Salary = (props) => {
  let { history } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : Tabs.SALARY
  );
  const [row, setRow] = useState();

  const _toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  return (
    <>
      <div>
        <PageTitle
          label="Salary"
          buttonHandler={() => {
            _toggle();
            setRow("");
          }}
          buttonLabel="Add"
        />
      </div>
      <Nav tabs className="admin-tabs mb-3">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.SALARY,
            })}
            onClick={() => {
              handleTabChange(Tabs.SALARY);
            }}
          >
            {Tabs.SALARY}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.SETTING,
            })}
            onClick={() => {
              handleTabChange(Tabs.SETTING);
            }}
          >
            {Tabs.SETTING}
          </NavLink>
        </NavItem>
      </Nav>
      {activeTab === Tabs.SALARY && (
        <TabPane>
          <DragAndDropTable
            history={history}
            objectName={ObjectName.SALARY}
            showUrl
            _toggle={_toggle}
            isOpen={isOpen}
            row={row}
            setRow={setRow}
          />
        </TabPane>
      )}
      {activeTab === Tabs.SETTING && (
        <TabPane>
          <SettingsTab history={history} objectName={ObjectName.ORDER} />
        </TabPane>
      )}
    </>
  );
};

export default Salary;
