import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import HorizontalSpace from "../../components/HorizontalSpace";
import SaveButton from "../../components/SaveButton";
import CancelButton from "../../components/CancelButton";
import Spinner from "../../components/Spinner";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import MediaCarousel from "../../components/MediaCarousel";
import Action from "../../components/Action";
import GatePassForm from "./gatePassForm";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import { typeOption } from "../../helpers/GatePass";
import Permission from "../../helpers/Permission";

// Services
import GatePassService from "../../services/GatePassService";
import { hasPermission } from "../../services/UserRolePermissionService";

function GatePassDetail(props) {
  let { history } = props;
  let id = props.match.params.id;
  const [detail, setDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [editable, setEditable] = useState(true);
  const [imageCount, setImageCount] = useState(0);

  let hasEditPermission = hasPermission(Permission.GATE_PASS_EDIT);

  let hasDeletePermission = hasPermission(Permission.GATE_PASS_DELETE);

  let dispatch = useDispatch();

  useEffect(() => {
    getGatePassDetail();
  }, []);

  let initialValues = {
    notes: detail ? detail?.notes : "",
    person_name: detail ? detail?.person_name : "",
    type: typeOption && typeOption.find((data) => data.value === detail?.type),
  };

  const handleUpdate = async (values) => {
    const data = new FormData();
    data.append("notes", values?.notes ? values?.notes : "");
    data.append("person_name", values?.person_name ? values?.person_name : "");
    data.append("type", values?.type ? values?.type?.value : "");

    data.append(
      "owner_id",
      values?.owner_id?.id ? values?.owner_id?.id : detail?.owner_id
    );
    data.append("media_file", selectedFile ? selectedFile : "");
    dispatch(
      await GatePassService.update(id, data, (res) => {
        if (res) {
          getGatePassDetail();
          setSelectedFile("");
          setEditable(true);
        }
      })
    );
  };

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const getGatePassDetail = async () => {
    let data = await GatePassService.get(id);
    setDetail(data);
  };

  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    { label: "Gate Pass", link: "/gatePass" },
    { label: "Detail Page", link: "" },
  ];

  const actionsMenuList = [];

  if (hasDeletePermission) {
    actionsMenuList.push(
      {
        value: "Delete",
        label: "Delete",
      },
    )
  }

  if (hasEditPermission && editable) {
    actionsMenuList.unshift({
      value: "Edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e === "Delete") {
      deleteToggle();
    }
    if (e === "Edit") {
      setEditable(false);
    }
  };

  const handleDelete = async () => {
    dispatch(
      await GatePassService.delete(id, (res) => {
        if (res) {
          history.push("/gatePass");
        }
      })
    );
  };

  const deleteToggle = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <DeleteModal
        isOpen={isOpen}
        toggle={() => {
          deleteToggle();
        }}
        deleteFunction={handleDelete}
        title="Delete Image"
        label={detail?.person_name ? detail?.person_name : detail?.id}
      />
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Gate Pass Detail Page" />
        {actionsMenuList && actionsMenuList?.length > 0 && (
          <div className="d-flex justify-content-end">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        )}
      </div>

      <div className="card card-body" style={{ marginBottom: "12%" }}>
        <Form
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleUpdate}
        >
          <div className="row">
            <div className="col-sm-7">
              <GatePassForm
                handleDelete={handleDelete}
                rowValue={detail}
                editable={editable}
                isDetailPage

              />
            </div>
            <div className="col-sm-5">
              <MediaCarousel
                showCarasoul
                title="Bill"
                objectName={ObjectName.GATE_PASS}
                objectId={id}
                history={history}
                billView={true}
                attachmentsList={true}
                modalOpen={isModelOpen}
                toggle={deleteToggle}
                setIsModelOpen={setIsModelOpen}
                imageCount={setImageCount}
                numberOfImage={imageCount}
                Attachments={"Attachments"}
                editable={editable}
              />
            </div>
            {!editable && (
              <HorizontalSpace bottom="2">
                <SaveButton label="Save" />
                <CancelButton
                  onClick={() => {
                    history.push("/gatePass");
                  }}
                />
              </HorizontalSpace>
            )}
          </div>
        </Form>
      </div>
    </>
  );
}

export default GatePassDetail;
