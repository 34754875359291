import React, { useState } from "react";

// Components
import DateSelector from "../../../components/Date";
import TextArea from "../../../components/TextArea";
import Text from "../../../components/Text";
import Phone from "../../../components/Phone";
import Select from "../../../components/Select";
import Number from "../../../components/Number";
import Zipcode from "../../../components/Zipcode";
import Percentage from "../../../components/Percentage";
import SingleCheckbox from "../../../components/SingleCheckbox";
import JobsSelect from "../../../components/JobsSelect";
import FeatureImage from "../../../components/Image";
import PdfViewer from "../../../components/PdfViewer";
import { Link } from "react-router-dom";
import Currency from "../../../components/Currency"


const CandidateForm = (props) => {
  const {
    handleMaritalStatusChange,
    handlePositionChange,
    handleGenderChange,
    handleFirstNameChange,
    handleLastNameChange,
    handlePhoneNumberChange,
    handleEmailChange,
    details,
    editable,
    handleQualificationChange,
    handleDepartmentChange,
    handleYearOfPassingChange,
    handleStatyingWithChange
  } = props;
  const [isOpenPdfModel, setIsOpenPdfModel] = useState(false);

  const closeToggle = () => {
    setIsOpenPdfModel(false)
  }

  const genderOptions = [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];

  const maritalStatusOptions = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Married",
      label: "Married",
    },
  ];

  return (
    <>
      <div className="mt-2 mb-3">
        <div className="d-flex" style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <PdfViewer pdfUrl={details?.file_url} isOpen={isOpenPdfModel} onClose={closeToggle} />
            <JobsSelect
              name="position"
              label="Position"
              placeholder="Enter position"
              handleJobsChange={handlePositionChange}
              isDisabled={editable}
              required
            />
            <div className="row">
              <div className="col">
                <Text
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name..."
                  onChange={handleFirstNameChange}
                  error=""
                  required={true}
                  disabled={editable}
                />
              </div>
              <div className="col">
                <Text
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name..."
                  onChange={handleLastNameChange}
                  required
                  disabled={editable}
                />
              </div>
            </div>
            <div>
              <Phone
                name="phone"
                label="Phone"
                placeholder="Enter phone"
                onChange={handlePhoneNumberChange}
                required
                disabled={editable}
              />
            </div>
            <div>
              <Text
                name="email"
                label="Email"
                placeholder="Enter User email..."
                onChange={handleEmailChange}
                disabled={editable}
              />
            </div>
            <div className="row">
              <div className="col">
                <Number
                  label="Age"
                  name="age"
                  disabled={editable}
                />
              </div>
              <div className="col">
                <Select
                  name="gender"
                  label="Gender"
                  placeholder="Select Gender"
                  options={genderOptions}
                  onInputChange={handleGenderChange}
                  required
                  isDisabled={editable}
                />
              </div>
            </div>
          </div>
          {details && <div className="pt-4 mt-2" style={{ width: "100%" }}>
            <FeatureImage
              src={details?.image_url}
              alt="Feature product image"
              size={"large"}
            />
            <div className="text-center mt-5">
              <Link to="#" onClick={(e) => setIsOpenPdfModel(true)}>
                {"View Resume"}
              </Link>
            </div>
          </div>}
        </div>
        <Select
          name="maritalStatus"
          label="Marital Status"
          placeholder="Select Maritial Status"
          options={maritalStatusOptions}
          onInputChange={handleMaritalStatusChange}
          isDisabled={editable}

        />
        <div className="row">
          <div className="col">
            <Text
              name="qualification"
              label="Qualification"
              placeholder="Enter Qualification"
              disabled={editable}
              onChange={handleQualificationChange}

            />
          </div>
          <div className="col">
            <Text
              name="department"
              label="Department"
              placeholder="Enter Department..."
              error=""
              disabled={editable}
              onChange={handleDepartmentChange}

            />
          </div>
          <div className="col">
            <Number
              label="Year Of Passing"
              name="yearOfPassing"
              disabled={editable}
              onChange={handleYearOfPassingChange}
            />
          </div>
        </div>
        <Text
          name="stayingWith"
          label="Statying With"
          placeholder="Enter staying_with..."
          error=""
          disabled={editable}
          onChange={handleStatyingWithChange}
        />
        <Currency
          label="Expected Salary"
          name="expected_salary"
          disabled={editable}
        />
        <div className="row">
          <div className="col">
            <Text
              name="currentCity"
              label="Current City"
              placeholder="Enter Current Area..."
              error=""
              disabled={editable}

            />
          </div>
          <div className="col">
            <Text
              name="currentState"
              label="Current State"
              placeholder="Enter Current State..."
              error=""
              disabled={editable}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Text
              name="permanentCity"
              label="Permanent City"
              placeholder="Enter Current Area..."
              error=""
              disabled={editable}
            />
          </div>
          <div className="col">
            <Text
              name="permanentState"
              label="Permanent State"
              placeholder="Enter Current State..."
              error=""
              disabled={editable}
            />
          </div>
        </div>

        <TextArea
          name="message"
          label="Messagge"
          placeholder="Enter Message..."
          error=""
          disabled={editable}
        />

        {props.showEdit && (
          <>
            <div>
              <Text
                name="skills"
                label="Skills"
                placeholder="Enter skills..."
              />
              <DateSelector label="Interview Date" name="interviewDate" />
            </div>

            <TextArea
              name="currentAddress"
              label="Current Address"
              placeholder="Enter Current Address..."
              error=""
            />
            <Text
              name="currentArea"
              label="Current Area"
              placeholder="Enter Current Area..."
              error=""
            />
            <Text
              name="currentCountry"
              label="Current Country"
              placeholder="Enter Current Country..."
              error=""
            />
            <Zipcode name="currentPincode" label="Current Pincode" />
            <TextArea
              name="permanentAddress"
              label="Permanent Address"
              placeholder="Enter Current Address..."
              error=""
            />
            <Text
              name="permanentArea"
              label="Permanent Area"
              placeholder="Enter Current Area..."
              error=""
            />
            <Text
              name="permanentCountry"
              label="Permanent Country"
              placeholder="Enter Permanent Country..."
              error=""
            />

            <Zipcode name="permanentPincode" label="Permanent Pincode" />

            <Number label="Over All Experience" name="over_all_experience" />
            <Text
              name="projectTitle"
              label="Project Title"
              placeholder="Enter Project Title..."
              error=""
            />
            <Text
              name="projectPeriod"
              label="Project Peried"
              placeholder="Enter Current Area..."
              error=""
            />
            <Text
              name="projectDescription"
              label="Project Description"
              placeholder="Enter Current State..."
              error=""
            />
            <Text
              name="courseName"
              label="Course Name"
              placeholder="Enter Course Name..."
              error=""
            />
            <Text
              name="coursePeriod"
              label="Course Peried"
              placeholder="Enter Current Area..."
              error=""
            />
            <Text
              name="courseInstitution"
              label="Course Institution"
              placeholder="Enter Course Institution..."
              error=""
            />
            <Number label="Current Salary" name="currentSalary" />

            <Percentage name="percentage" label="Percentage" />
            <Text
              name="positionType"
              label="Position Type"
              placeholder="Enter Position Type..."
              error=""
            />
            <DateSelector label="Date Of Birth" name="dob" />
            <Number label="Relevant Experience" name="relevantExperience" />
            <Number label="Expected Cost Per Hour" name="expectedCostPerHour" />
            <Text
              name="jobReferenceType"
              label="Job Reference Type"
              placeholder="Enter Job Reference Type..."
              error=""
            />
            <Text
              name="jobReferenceName"
              label="Job Reference Name"
              placeholder="Enter Job Reference Type..."
              error=""
            />
            <Text
              name="willingToWorkInShift"
              label="Willing to Work in Shift"
              placeholder="Enter Willing to work in shift..."
              error=""
            />
            <Percentage
              name="tenthPercentage"
              label="Tenth Percentage"
              placeholder="Enter Tenth Percentage..."
              error=""
            />
            <Number
              name="tenthYearOfPassing"
              label="tenth year of passing"
              placeholder="Enter staying_with..."
              error=""
            />
            <Number
              label="Twelfth Year of passing"
              name="twelvethYearOfPassing"
            />
            <Percentage label="Twelfth percentage" name="twelvethPercentage" />
            <Text
              name="degreeArrear"
              label="Degree Arrear"
              placeholder="Enter Willing to work in shift..."
              error=""
            />
            <div className="my-2 d-flex justify-content-between">
              <SingleCheckbox name="didCourse" label="Did Course" />

              <SingleCheckbox name="didProject" label="Did Project" />
            </div>
            <Text
              name="linkedinProfileName"
              label="Linkedln Profile Name"
              placeholder="Enter linkedln_profile_name..."
              error=""
            />
            <Text
              name="facebookProfileName"
              label="Facebook Profile Name"
              placeholder="Enter linkedln_profile_name..."
              error=""
            />
            <Text
              name="jobTitle"
              label="Job Tilte"
              placeholder="Enter linkedln_profile_name..."
              error=""
            />
            <Number label="Joined Month" name="joinedMonth" />
            <Number label="Joined Year" name="joinedYear" />
            <Text
              name="companyName"
              label="Company Name"
              placeholder="Enter company name..."
              error=""
            />
            <Text
              name="knownLanguages"
              label="Known Languages"
              placeholder="Enter known languages..."
              error=""
            />
            <Text
              name="employmentEligibility"
              label="Employment Eligibility"
              placeholder="Enter known languages..."
              error=""
            />
            <Text
              name="didVaccineStatus"
              label="Vaccine status"
              placeholder="Enter Vaccine status ..."
              error=""
            />
          </>
        )}
      </div>
    </>
  );
};

export default CandidateForm;
