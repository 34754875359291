import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../../views/user/portalUser.scss";

// API

// Assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// Helpers
import { User } from "../../../helpers/User";

// Lib
import { endpoints } from "../../../api/endPoints";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import UserCard from "../../../components/UserCard";
import Url from "../../../lib/Url";

export const Tabs = {
  ACTIVE: "Active",
  ALL: "All",
  INACTIVE: "InActive",
};

const MessageUserList = (props) => {
  const { selectedCheckBox, setSelectedIds, excludedIds } = props;
  const sortByOption = [
    {
      value: "first_name:ASC",
      label: "Name",
    },
  ];
  const onBulkSelect = (value) => {
    setSelectedIds(value);
  };
  return (
    <>
      <ReduxTable
        id={"messageUserList"}
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().messageAPI}/userList`}
        newTableHeading
        icon={<FontAwesomeIcon icon={faUser} />}
        message="You can start by clicking add."
        sortByOptions={sortByOption}
        history={props.history}
        params={{ ...Url.GetAllParams(), excludedIds: excludedIds }}
        selectedCheckBox={selectedCheckBox}
        showRoleFilter
        paramsToUrl={true}
        bulkSelect
        showScroll
        onBulkSelect={onBulkSelect}
        showStoreFilter
        showCheckedInUser>
        <ReduxColumn
          field="first_name"
          type="link"
          isClickable="true"
          sortBy="first_name"
          width="350px"
          maxWidth="250px"
          minWidth="250px"
          renderField={(row) => (
            <>
              <Link to={`/user/${row.id}`} className="link-opacity-75">
                <UserCard
                  customSize={parseInt(50, 10)}
                  firstName={row.first_name}
                  url={row.media_url}
                  mobileNumber={row.mobileNumber1}
                  email={row.email}
                  lastName={row.last_name}
                  status={
                    row.status == User.STATUS_INACTIVE_VALUE
                      ? `(${User.STATUS_INACTIVE_TEXT})`
                      : ""
                  }
                />
              </Link>
            </>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="current_location_name"
          width="110px"
          maxWidth="110px"
          minWidth="110px"
          sortBy="current_location_name"
        >
           Location
        </ReduxColumn>
        <ReduxColumn
          field="current_shift_name"
          width="110px"
          maxWidth="110px"
          minWidth="110px"
          sortBy="current_shift_name"
        >
           Shift
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default MessageUserList;
