import React, { useEffect, useState } from "react";
import { Nav, TabPane } from "reactstrap";

// Components
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import SelectStore from "../../components/SelectStore";
import CustomFields from "../../components/customFields/CustomFields";
import Tab from "../../components/Tab";
import AddButton from "../../components/AddButton";
import SettingTab from "./settingTab";

// Helpers
import { Setting } from "../../helpers/Setting";
import { Tabs } from "../../helpers/Setting";
import ObjectName from "../../helpers/ObjectName";

// Lib
import ArrayList from "../../lib/ArrayList";
import { getKeyValueByObject } from "../../lib/Helper";

// Services
import { getCompanySettings, saveSetting } from "../../services/SettingService";
import Url from "../../lib/Url";

const Location = (props) => {
  const [store, setStore] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [settings, setSettings] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(Url.GetParam("tab") ? Url.GetParam("tab") :Tabs.GENERAL);

  const handleStore = (values) => {
    setStore(values && values.value);
  };

  const toggle = (tab) => {
      setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  const _toggle = () => {
    setIsOpen(!isOpen);
  };

  const getSettings = async () => {
    //get company setting
    const settingData = await getCompanySettings();
    //set setting in state
    setSettings(settingData);
  };

  useEffect(() => {
    getSettings();
  }, []);

  const initialValues = {
    Location:
      settings &&
      ArrayList.isNotEmpty(locationList) &&
      locationList.find(
        (data) =>
          data.id == getKeyValueByObject(settings, Setting.DISTRIBUTION_CENTER)
      ),
  };


  const submit = async (values) => {
    const data = new FormData();
    data.append(Setting.DISTRIBUTION_CENTER, values?.Location?.value);
    await saveSetting(data);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <PageTitle label="Location" />
        {activeTab === Tabs.CUSTOMFIELDS && (
          <div className="mt-1">
            <AddButton
              className="ms-2"
              onClick={() => {
                _toggle();
              }}
              label="Add"
            />
          </div>
        )}
      </div>
      <Nav tabs className="admin-tabs mb-3">
        <Tab
          name={Tabs.GENERAL}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
        <Tab
          name={Tabs.CUSTOMFIELDS}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
        <Tab
          name={Tabs.SETTING}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
      </Nav>
      {activeTab === Tabs.GENERAL && (
        <TabPane>
          <div className="card card-body">
            <Form
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values) => {
                submit(values);
              }}
            >
              <div className="row field-wrapper">
                <div className="col-lg-12 col-sm-12">
                  <SelectStore
                    name="Location"
                    label="Distribution Center"
                    placeholder="Select Distribution Center"
                    handleStoreChange={handleStore}
                    defaultValue={getKeyValueByObject(settings, Setting.DISTRIBUTION_CENTER)}
                    StoreList={setLocationList}
                  />
                  <SaveButton />
                </div>
              </div>
            </Form>
          </div>
        </TabPane>
      )}
      {activeTab === Tabs.CUSTOMFIELDS && (
        <TabPane>
          <CustomFields
            showBreadCrumb={true}
            _toggle={_toggle}
            objectName={ObjectName.LOCATION}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </TabPane>
      )}
      {activeTab === Tabs.SETTING && (
        <TabPane>
          <SettingTab />
        </TabPane>
      )}
    </>
  );
};

export default Location;
