import React from "react";

const CountBadge = (props) => {
  const { isActive, count, badgeColor, className, noValidateCount } = props;

  let badgeBGColor;
  if (badgeColor) {
    badgeBGColor = badgeColor;
  } else {
    badgeBGColor = isActive ? "#0a9dda" : "#BDBDC0";
  }

  return count > 0 || noValidateCount ? (
    <React.Fragment>
      <div
        className={
          className
            ? className
            : "text-white d-inline-block ms-2 ps-2 pe-2 status rounded-pill"}
        style={{
          backgroundColor: badgeBGColor,
        }}
      >
        {count}
      </div>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default CountBadge;
