import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { isBadRequest } from "../lib/Http";

class WhatsAppService {
  static async connect(data) {
    try {
      const response = await apiClient.post(`${endpoints().whatsAppAPI}`, data);
      if(response?.data && response?.data?.message){
        Toast.success(response?.data?.message);
      }
      return response && response?.data;
    } catch (err) {
      if (isBadRequest(err)) {
        let errMessage;
        const errRequest = err.response.data;
        if (errRequest && errRequest.response) {
          errMessage = JSON.parse(errRequest.response).message;
        }
        Toast.error(errMessage);
      }
    }
  }
}

export default WhatsAppService;
