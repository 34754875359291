import React, { useEffect, useState } from "react";

//components
import Form from "../../components/Form";

import SingleCheckbox from "../../components/SingleCheckbox";

import { getSetings, saveSetting } from "../../services/SettingService";

import { Setting } from "../../helpers/Setting";

import { getKeyValueByObject } from "../../lib/Helper";

import ArrayList from "../../lib/ArrayList";

import MultiSelect from "../../components/Multiselect";
import Numbers from "../../components/Number";
import SaveButton from "../../components/SaveButton";
import StoryPointSelect from "../../components/StoryPointSelect";
import Text from "../../components/Text";
import ObjectName from "../../helpers/ObjectName";
import Number from "../../lib/Number";
import ShiftService from "../../services/ShiftService";
import StoreSelector from "../location/components/storeSelector";
import orderTypeService from "../../services/OrderTypeService";
import AccountTypeSelect from "../../components/AccountTypeSelect";
import WeekdaysCheckbox from "../../components/WeekdaysCheckbox";

const RoleSetting = (props) => {
  const [settings, setSettings] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [minStoryPoints, setMinStoryPoints] = useState(null);
  const [shiftList, setShiftList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [logoutHours, setLogoutHours] = useState(null);
  const [orderTypes, setOrderTypes] = useState([]);
  const [accountTypes, setAccountType] = useState([]);
  const [minimumReplenishValue, setMinimumReplenishValue] = useState();
  const [stockEntryMinimumCountValue, setStockEntryMinimumCountValue] = useState();
  const [allowedShiftValue, setAllowedShiftValue] = useState(null);
  const [allowedOrderTypeValue, setAllowedOrderTypeValue] = useState(null);
  const [accountTypeValue, setAccountTypeValue] = useState(null);
  const [allowedLocationValue, setAllowedLocationValue] = useState(null);
  const [ticketsMinimumStoryPointsValue, setTicketsMinimumStoryPointsValue] = useState(null);

  useEffect(() => {
    getSettings();
    getShift();
    getOrderTypes();
  }, [props]);

  const getOrderTypes = async () => {
    let response = await orderTypeService.list();
    let data = response && response?.data;
    setOrderTypes(data);
  };

  // Get Shift List
  const getShift = async () => {
    const list = await ShiftService.getShiftLists();
    setShiftList(list);
  };
  const getSettings = async () => {
    //get company id from cookie
    let params = { object_id: Number.Get(props?.roleId) };
    //get company setting
    const settingData = await getSetings(params);

    //set setting in state
    setSettings(settingData);
    setSelectedDays(
      getKeyValueByObject(settingData, Setting.USER_WORKING_DAYS).split(",")
    );
  };

  const minimumStoryPoints = minStoryPoints
    ? minStoryPoints
    : settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.SETTING_MINIMUM_STORY_POINTS)
    ? getKeyValueByObject(settings, Setting.SETTING_MINIMUM_STORY_POINTS)
    : null;

  const accountType =
    settings &&
    ArrayList.isNotEmpty(settings) &&
    getKeyValueByObject(settings, Setting.ACCOUNT_TYPE)
      ? getKeyValueByObject(settings, Setting.ACCOUNT_TYPE)
      : null;
  let allowedShifts = getKeyValueByObject(settings, Setting.ROLE_ALLOWED_SHIFT);
  let shiftValue = [];
  if (allowedShifts) {
    let shidtData = allowedShifts && allowedShifts.split(",");
    shidtData.forEach((value) => {
      let data = shiftList && shiftList.find((data) => data?.value == value);
      shiftValue.push(data);
    });
  }

  let allowedOrderTypes = getKeyValueByObject(
    settings,
    Setting.ALLOWED_ORDER_TYPES
  );
  let orderTypesValue = [];
  if (allowedOrderTypes) {
    let orderTypeData = allowedOrderTypes && allowedOrderTypes.split(",");

    orderTypeData.forEach((value) => {
      let data = orderTypes && orderTypes.find((data) => data?.value == value);
      orderTypesValue.push(data);
    });
  }
  let allowedLocation = getKeyValueByObject(
    settings,
    Setting.ROLE_ALLOWED_LOCATIONS
  );
  let locationValue = [];
  if (allowedLocation) {
    let locationData = allowedLocation && allowedLocation.split(",");
    locationData.forEach((value) => {
      let data = storeList && storeList.find((data) => data?.id == value);
      locationValue.push(data);
    });
  }

  const getBooleanSetting = (key) =>
    settings && getKeyValueByObject(settings, key) === "true";

  // Initial values
  const initialValues = {
    validate_sales_settlement_on_attendance_checkout: getBooleanSetting(
      Setting.SETTING_VALIDATE_SALES_SETTLEMENT_ON_ATTENDANCE_CHECKOUT
    ),
    allow_additional_leave_add: getBooleanSetting(
      Setting.ALLOW_ADDITIONAL_LEAVE_ADD
    ),
    account_type: accountTypeValue ? accountTypeValue :
      accountTypes && accountTypes.find((value) => value.value == accountType),
    validate_pending_sales_settlement_on_attendance_checkout: getBooleanSetting(
      Setting.VALIDATE_PENDING_SALES_SETTLEMENT_ON_ATTENDANCE_CHECKOUT
    ),
    validate_shift_time_on_sale_settlement_add: getBooleanSetting(
      Setting.VALIDATE_SHIFT_TIME_ON_SALE_SETTLEMENT_ADD
    ),
    allow_replenishment: getBooleanSetting(Setting.ALLOW_REPLENISHMENT),
    validate_ip_address_on_checkin: getBooleanSetting(
      Setting.VALIDATE_IP_ADDRESS_ON_CHECKIN
    ),
    validate_ip_address_on_checkout: getBooleanSetting(
      Setting.VALIDATE_IP_ADDRESS_ON_CHECKOUT
    ),

    validate_pending_ticket_on_attendance_checkout: getBooleanSetting(
      Setting.SETTING_VALIDATE_PENDING_TICKET_ON_ATTENDANCE_CHECKOUT
    ),

    force_check_in_after_login: getBooleanSetting(
      Setting.FORCE_CHECK_IN_AFTER_LOGIN
    ),

    force_logout_after_checkout: getBooleanSetting(
      Setting.FORCE_LOGOUT_AFTER_CHECKOUT
    ),

    validate_timesheet_on_attendance_checkout: getBooleanSetting(
      Setting.SETTING_VALIDATE_TIMESHEET_ON_ATTENDANCE_CHECKOUT
    ),
    validate_pending_checkout_on_attendance_checkin: getBooleanSetting(
      Setting.SETTING_VALIDATE_PENDING_CHECKOUT_ON_ATTENDANCE_CHECKIN
    ),

    validate_pending_fines_on_attendance_checkout: getBooleanSetting(
      Setting.VALIDATE_PENDING_FINES_ON_ATTENDANCE_CHECKOUT
    ),
    validate_pending_orders_on_attendance_checkout: getBooleanSetting(
      Setting.VALIDATE_PENDING_ORDERS_ON_ATTENDANCE_CHECKOUT
    ),
    validate_pending_order_product_on_attendance_checkout: getBooleanSetting(
      Setting.VALIDATE_PENDING_ORDER_PRODUCT_ON_ATTENDANCE_CHECKOUT
    ),
    validate_pending_transfer_on_attendance_checkout: getBooleanSetting(
      Setting.VALIDATE_PENDING_TRANSFER_ON_ATTENDANCE_CHECKOUT
    ),
    validate_pending_payments_on_attendance_checkout: getBooleanSetting(
      Setting.VALIDATE_PENDING_PAYMENTS_ON_ATTENDANCE_CHECKOUT
    ),
    validate_pending_purchases_on_attendance_checkout: getBooleanSetting(
      Setting.VALIDATE_PENDING_PURCHASES_ON_ATTENDANCE_CHECKOUT
    ),
    validate_pending_bills_on_attendance_checkout: getBooleanSetting(
      Setting.VALIDATE_PENDING_BILLS_ON_ATTENDANCE_CHECKOUT
    ),
    stock_entry_required: getBooleanSetting(Setting.STOCK_ENTRY_REQUIRED),
    validate_shift_time_on_checkout: getBooleanSetting(
      Setting.VALIDATE_SHIFT_TIME_ON_CHECKOUT
    ),
    validate_minimum_replenish_products_on_checkout: getBooleanSetting(
      Setting.VALIDATE_MINIMUM_REPLENISH_PRODUCTS_ON_CHECKOUT
    ),
    validate_shift_hours_on_checkout: getBooleanSetting(
      Setting.VALIDATE_SHIFT_HOURS_ON_CHECKOUT
    ),
    minimum_replenish_products: minimumReplenishValue ? minimumReplenishValue :
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, "minimum_replenish_products")
        ? getKeyValueByObject(settings, "minimum_replenish_products")
        : "",
    stock_entry_minimum_count: stockEntryMinimumCountValue ? stockEntryMinimumCountValue :
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.STOCK_ENTRY_MINIMUM_COUNT)
        ? getKeyValueByObject(settings, Setting.STOCK_ENTRY_MINIMUM_COUNT)
        : "",
    reported_tickets_minimum_story_points: ticketsMinimumStoryPointsValue ? ticketsMinimumStoryPointsValue :
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, "reported_tickets_minimum_story_points")
        ? getKeyValueByObject(settings, "reported_tickets_minimum_story_points")
        : "",
    minimum_story_points: minimumStoryPoints
      ? {
          label: minimumStoryPoints,
          value: minimumStoryPoints,
        }
      : "",
    auto_logout_hours: logoutHours
      ? logoutHours
      : settings &&
        ArrayList.isNotEmpty(settings) &&
        getKeyValueByObject(settings, Setting.AUTO_LOGOUT_HOURS)
      ? getKeyValueByObject(settings, Setting.AUTO_LOGOUT_HOURS)
      : "",
    role_allowed_shift: allowedShiftValue ? allowedShiftValue :shiftValue,
    allowed_order_types: allowedOrderTypeValue ? allowedOrderTypeValue :orderTypesValue,
    role_allowed_locations: allowedLocationValue ? allowedLocationValue :locationValue,
    enable_otp_login: getBooleanSetting(Setting.ENABLE_OTP_LOGIN),

    enable_salary_additional_hours: getBooleanSetting(
      Setting.ENABLE_SALARY_ADDITIONAL_HOURS
    ),

    validate_ticket_minimum_story_points_on_checkout: getBooleanSetting(
      Setting.VALIDATE_TICKET_MINIMUM_STORY_POINTS_ON_CHECKOUT
    ),

    validate_pending_activities_on_attendance_checkout: getBooleanSetting(
      Setting.VALIDATE_PENDING_ACTIVITIES_ON_ATTENDANCE_CHECKOUT
    ),

    validate_late_checkin_on_checkout: getBooleanSetting(
      Setting.VALIDATE_LATE_CHECKIN_ON_CHECKOUT
    ),

    validate_reported_tickets_minimum_story_points_oncheckout:
      getBooleanSetting(
        Setting.VALIDATE_REPORTED_TICKETS_MINIMUM_STORY_POINTS_ONCHECKOUT
      ),

    fine_add_for_attendance_late_checkin: getBooleanSetting(
      Setting.FINE_ADD_FOR_ATTENDANCE_LATE_CHECKIN
    ),

    [Setting.BONUS_ADD_FOR_ATTENDANCE_EARLY_CHECKIN]: getBooleanSetting(
      Setting.BONUS_ADD_FOR_ATTENDANCE_EARLY_CHECKIN
    ),

    [Setting.BONUS_ADD_FOR_EXTRA_STOCK_ENTRY]: getBooleanSetting(
      Setting.BONUS_ADD_FOR_EXTRA_STOCK_ENTRY
    ),

    fine_add_for_stock_entry: getBooleanSetting(
      Setting.FINE_ADD_FOR_STOCK_ENTRY
    ),

    [Setting.FINE_ADD_FOR_PENDING_ACTIVITIES]: getBooleanSetting(
      Setting.FINE_ADD_FOR_PENDING_ACTIVITIES
    ),


    [Setting.FINE_ADD_FOR_REPLENISHMENT_MISSING]: getBooleanSetting(
      Setting.FINE_ADD_FOR_REPLENISHMENT_MISSING
    ),

    user_role_allow_multiple_checkin: getBooleanSetting(
      Setting.USER_ROLE_ALLOW_MULTIPLE_CHECKIN
    ),

    [Setting.BONUS_ADD_FOR_EXTRA_REPLENISHMENT]: getBooleanSetting(
      Setting.BONUS_ADD_FOR_EXTRA_REPLENISHMENT
    ),

    [Setting.FINE_ADD_FOR_EARLY_ATTENDANCE_CHECK_OUT]: getBooleanSetting(
      Setting.FINE_ADD_FOR_EARLY_ATTENDANCE_CHECK_OUT
    ),

    [Setting.ENQUIRY_TICKET_CREATE_FOR_LATE_CHECK_IN]: getBooleanSetting(
      Setting.ENQUIRY_TICKET_CREATE_FOR_LATE_CHECK_IN
    ),

    [Setting.ENQUIRY_TICKET_CREATE_FOR_STOCK_ENTRY]: getBooleanSetting(
      Setting.ENQUIRY_TICKET_CREATE_FOR_STOCK_ENTRY
    ),

    [Setting.ENQUIRY_TICKET_CREATE_FOR_REPLENISHMENT_MISSING]:
      getBooleanSetting(
        Setting.ENQUIRY_TICKET_CREATE_FOR_REPLENISHMENT_MISSING
      ),

    [Setting.ENQUIRY_TICKET_CREATE_FOR_EARLY_CHECK_OUT]: getBooleanSetting(
      Setting.ENQUIRY_TICKET_CREATE_FOR_EARLY_CHECK_OUT
    ),

    [Setting.ENQUIRY_TICKET_CREATE_FOR_LOCATION_CASH_AMOUNT_MISSING]:
      getBooleanSetting(
        Setting.ENQUIRY_TICKET_CREATE_FOR_LOCATION_CASH_AMOUNT_MISSING
      ),

    [Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_CASH_AMOUNT_MISSING]:
      getBooleanSetting(
        Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_CASH_AMOUNT_MISSING
      ),

    [Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_UPI_AMOUNT_MISSING]:
      getBooleanSetting(
        Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_UPI_AMOUNT_MISSING
      ),

    [Setting.ENQUIRY_TICKET_CREATE_FOR_DRAFT_AMOUNT_MISSING]: getBooleanSetting(
      Setting.ENQUIRY_TICKET_CREATE_FOR_DRAFT_AMOUNT_MISSING
    ),

    [Setting.FINE_ADD_FOR_TICKET_DUE_DATE_CHANGE]: getBooleanSetting(
      Setting.FINE_ADD_FOR_TICKET_DUE_DATE_CHANGE
    ),

    [Setting.FINE_ADD_FOR_PAYMENT_DUE_DATE_CHANGE]: getBooleanSetting(
      Setting.FINE_ADD_FOR_PAYMENT_DUE_DATE_CHANGE
    ),

    [Setting.FINE_ADD_FOR_BILL_DUE_DATE_CHANGE]: getBooleanSetting(
      Setting.FINE_ADD_FOR_BILL_DUE_DATE_CHANGE
    ),

    [Setting.BONUS_ADD_FOR_LATE_CHECKOUT]: getBooleanSetting(
      Setting.BONUS_ADD_FOR_LATE_CHECKOUT
    ),

    [Setting.SHOW_DASHBOARD_PENDING_ACTIVITIES_REMINDER]: getBooleanSetting(
      Setting.SHOW_DASHBOARD_PENDING_ACTIVITIES_REMINDER
    ),
    [Setting.ENABLE_ACTIVITY_SLACK_NOTIFICATION]: getBooleanSetting(
      Setting.ENABLE_ACTIVITY_SLACK_NOTIFICATION
    ),
    [Setting.ENABLE_VISITOR_FACE_RECOGNITION]: getBooleanSetting(
      Setting.ENABLE_VISITOR_FACE_RECOGNITION
    ),
    [Setting.ENABLE_DEVICE_USAGE]: getBooleanSetting(
      Setting.ENABLE_DEVICE_USAGE
    ),

    [Setting.VALIDATE_PENDING_ORDERS_ATTENDANCE_CHECKOUT]: getBooleanSetting(
      Setting.VALIDATE_PENDING_ORDERS_ATTENDANCE_CHECKOUT
    ),
  };

  const handleCheckBoxValue = (value, name) => {
    // Convert object to array
    let array = Object.entries(value);
    let checkBoxData = {};

    // Iterate over the array to find the matching name
    array.forEach((result) => {
      if (result[0] === name) {
        checkBoxData.label = result[0];
        checkBoxData.value = result[1];
      }
    });

    // Create new FormData
    const data = new FormData();

    // Append the specific checkbox value
    data.append(checkBoxData.label, checkBoxData.value);

    // Append additional fields if necessary
    data.append("objectId", props.roleId);
    data.append("objectName", ObjectName.ROLE);

    // Save settings
    saveSetting(data, null, () => {
      getSettings();
    });
  };

  const handleUpdate = (value) => {
    let allowedShift = [];
    for (let i = 0; i < value.role_allowed_shift.length; i++) {
      const data = value.role_allowed_shift[i];
      allowedShift.push(data?.value);
    }
    let allowedOrder = [];
    for (let i = 0; i < value.allowed_order_types.length; i++) {
      const data = value.allowed_order_types[i];
      allowedOrder.push(data?.value);
    }
    let allowedLocation = [];
    for (let i = 0; i < value.role_allowed_locations.length; i++) {
      const data = value.role_allowed_locations[i];
      allowedLocation.push(data?.id);
    }

    let data = new FormData();

    data.append(Setting.USER_WORKING_DAYS, selectedDays ? selectedDays : "");
    data.append(
      "minimum_story_points",
      value.minimum_story_points ? value.minimum_story_points.value : ""
    );
    data.append(
      "minimum_replenish_products",
      value?.minimum_replenish_products ? value?.minimum_replenish_products : ""
    );
    data.append(
      Setting.STOCK_ENTRY_MINIMUM_COUNT,
      value?.stock_entry_minimum_count ? value?.stock_entry_minimum_count : ""
    );
    data.append(
      "reported_tickets_minimum_story_points",
      value?.reported_tickets_minimum_story_points
        ? value?.reported_tickets_minimum_story_points
        : ""
    );
    data.append(
      "auto_logout_hours",
      value.auto_logout_hours ? value.auto_logout_hours : ""
    );
    data.append(
      Setting.ROLE_ALLOWED_SHIFT,
      allowedShift && allowedShift.length > 0 ? allowedShift.join(",") : ""
    );
    data.append(
      Setting.ALLOWED_ORDER_TYPES,
      allowedOrder && allowedOrder.length > 0 ? allowedOrder.join(",") : ""
    );
    data.append(
      Setting.ACCOUNT_TYPE,
      value?.account_type && value?.account_type?.value
        ? value?.account_type?.value
        : ""
    );
    data.append(
      Setting.ROLE_ALLOWED_LOCATIONS,
      allowedLocation && allowedLocation.length > 0
        ? allowedLocation.join(",")
        : ""
    );
    data.append("objectId", props.roleId);
    data.append("objectName", ObjectName.ROLE);

    saveSetting(data, null, () => {
      getSettings();
    });
  };

  const handleCheckboxChange = (day, checked) => {
    if (checked) {
      setSelectedDays([...selectedDays, day]);
    } else {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    }
  };

  const handleChange = (values) => {
    let dataValue = values && values?.values;
    if (dataValue?.minimum_story_points !== null) {
      let data =
        dataValue && dataValue?.minimum_story_points
          ? dataValue?.minimum_story_points.value
          : null;
      setMinStoryPoints(data);
    } else {
      setMinStoryPoints(null);
    }
  };

  const handleMinimumReplenishChange = (value) => {
    let data = value && value?.target?.value;
    setMinimumReplenishValue(data);
  }

  const handleStockEntryMinimumCountChange = (value) => {
    let data = value && value?.target?.value;
    setStockEntryMinimumCountValue(data);
  }

  const handleAllowedShiftChange = (value) => {
    let data = value?.role_allowed_shift;
    setAllowedShiftValue(data);
  }

  const handleAllowedOrderTypeChange = (value) => {
    let data = value?.allowed_order_types;
    setAllowedOrderTypeValue(data);
  }

  const handleAccountTypeChange = (value) => {
    let data = value && value?.values?.account_type;
    setAccountTypeValue(data);
  }

  const handleAllowedLocationChange = (value) => {
    let data = value?.role_allowed_locations;
    setAllowedLocationValue(data);
  }

  const handleTicketsMinimumStoryPointsChange = (value) => {
    let date = value && value?.target?.value;
    setTicketsMinimumStoryPointsValue(date);
  }

  const handleLogoutHours = (hours) => {
    let data = hours && hours.target.value;
    setLogoutHours(data);
  };
  const validationSettings = {
    checkIn: [
      {
        name: Setting.USER_ROLE_ALLOW_MULTIPLE_CHECKIN,
        label: "Allow Multiple CheckIn",
      },
      {
        name: Setting.FORCE_CHECK_IN_AFTER_LOGIN,
        label: "Force CheckIn After Login",
      },
      {
        name: Setting.VALIDATE_IP_ADDRESS_ON_CHECKIN,
        label: "Validate IP Address On CheckIn",
      },
      {
        name: Setting.SETTING_VALIDATE_PENDING_CHECKOUT_ON_ATTENDANCE_CHECKIN,
        label: "Validate Pending Checkout on Attendance CheckIn",
      },
    ],
    checkOut: [
      {
        name: Setting.VALIDATE_LATE_CHECKIN_ON_CHECKOUT,
        label: "Validate Late CheckIn On Checkout",
      },
      {
        name: Setting.SETTING_VALIDATE_SALES_SETTLEMENT_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Sales Settlement On Attendance CheckOut",
      },
      {
        name: Setting.SETTING_VALIDATE_PENDING_TICKET_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Pending Ticket On Attendance CheckOut",
      },
      {
        name: Setting.VALIDATE_IP_ADDRESS_ON_CHECKOUT,
        label: "Validate IP Address On CheckOut",
      },
      {
        name: Setting.VALIDATE_PENDING_SALES_SETTLEMENT_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Pending Sales Settlement on Attendance Checkout",
      },
      {
        name: Setting.SETTING_VALIDATE_TIMESHEET_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Timesheet on Attendance Checkout",
      },
      {
        name: Setting.VALIDATE_PENDING_FINES_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Pending Fines On Attendance Checkout",
      },
      {
        name: Setting.VALIDATE_PENDING_ORDERS_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Pending Orders On Attendance Checkout",
      },
      {
        name: Setting.VALIDATE_PENDING_TRANSFER_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Pending Transfer On Attendance Checkout",
      },
      {
        name: Setting.VALIDATE_PENDING_PAYMENTS_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Pending Payments On Attendance Checkout",
      },
      {
        name: Setting.VALIDATE_PENDING_PURCHASES_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Pending Purchases On Attendance Checkout",
      },
      {
        name: Setting.VALIDATE_SHIFT_TIME_ON_CHECKOUT,
        label: "Validate Shift Time On Checkout",
      },
      {
        name: Setting.VALIDATE_PENDING_BILLS_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Pending Bills On Attendance Checkout",
      },
      {
        name: Setting.VALIDATE_MINIMUM_REPLENISH_PRODUCTS_ON_CHECKOUT,
        label: "Validate Minimum Replenish Products On Checkout",
      },
      {
        name: Setting.FORCE_LOGOUT_AFTER_CHECKOUT,
        label: "Force Logout After Checkout",
      },
      {
        name: Setting.VALIDATE_TICKET_MINIMUM_STORY_POINTS_ON_CHECKOUT,
        label: "Validate Ticket Minimum Story Points On Checkout",
      },
      {
        name: Setting.VALIDATE_PENDING_ACTIVITIES_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Pending Activities On Attendance Checkout",
      },
      {
        name: Setting.VALIDATE_REPORTED_TICKETS_MINIMUM_STORY_POINTS_ONCHECKOUT,
        label: "Validate Reported Tickets Minimum Story Points On Checkout",
      },
      {
        name: Setting.VALIDATE_SHIFT_HOURS_ON_CHECKOUT,
        label: "Validate Shift Hours On Checkout",
      },
      {
        name: Setting.VALIDATE_SHIFT_TIME_ON_SALE_SETTLEMENT_ADD,
        label: "Validate Shift Time On Sales Settlement Add",
      },
      {
        name: Setting.ALLOW_ADDITIONAL_LEAVE_ADD,
        label: "Allow Additional Leave Add",
      },
      {
        name: Setting.ALLOW_REPLENISHMENT,
        label: "Allow Replenishment",
      },
      {
        name: Setting.STOCK_ENTRY_REQUIRED,
        label: "Stock Entry Required",
      },
      {
        name: Setting.ENABLE_OTP_LOGIN,
        label: "Enable OTP Login",
      },
    ],
    fine: [
      {
        name: Setting.FINE_ADD_FOR_ATTENDANCE_LATE_CHECKIN,
        label: "Fine Add For Attendance Late Check In",
      },
      {
        name: Setting.FINE_ADD_FOR_EARLY_ATTENDANCE_CHECK_OUT,
        label: "Fine Add For Early CheckOut",
      },
      {
        name: Setting.FINE_ADD_FOR_STOCK_ENTRY,
        label: "Fine Add For Stock Entry",
      },
      {
        name: Setting.FINE_ADD_FOR_REPLENISHMENT_MISSING,
        label: "Fine Add For Replenishment Missing",
      },
      {
        name: Setting.FINE_ADD_FOR_TICKET_DUE_DATE_CHANGE,
        label: "Fine Add For Ticket Due Date Change",
      },
      {
        name: Setting.FINE_ADD_FOR_PAYMENT_DUE_DATE_CHANGE,
        label: "Fine Add For Payment Due Date Change",
      },
      {
        name: Setting.FINE_ADD_FOR_BILL_DUE_DATE_CHANGE,
        label: "Fine Add For Bill Due Date Change",
      },
      {
        name: Setting.FINE_ADD_FOR_PENDING_ACTIVITIES,
        label: "Fine Add For Pending Activities",
      },
    ],
    order: [
      {
        name: Setting.VALIDATE_PENDING_ORDER_PRODUCT_ON_ATTENDANCE_CHECKOUT,
        label: "Validate Pending Order Product On Attendance CheckOut",
      },
    ],
    bonus: [
      {
        name: Setting.BONUS_ADD_FOR_ATTENDANCE_EARLY_CHECKIN,
        label: "Bonus Add For Attendance Early Check-In",
      },
      {
        name: Setting.BONUS_ADD_FOR_EXTRA_STOCK_ENTRY,
        label: "Bonus Add For Extra Stock Entry",
      },
      {
        name: Setting.BONUS_ADD_FOR_LATE_CHECKOUT,
        label: "Bonus Add For Late Check-Out",
      },
      {
        name: Setting.BONUS_ADD_FOR_EXTRA_REPLENISHMENT,
        label: "Bonus Add For Extra Replenishment",
      },
    ],
    enquiryTicket: [
      {
        name: Setting.ENQUIRY_TICKET_CREATE_FOR_LATE_CHECK_IN,
        label: "Enquiry Ticket create For Late Check In",
      },
      {
        name: Setting.ENQUIRY_TICKET_CREATE_FOR_STOCK_ENTRY,
        label: "Enquiry Ticket create For Stock Entry",
      },
      {
        name: Setting.ENQUIRY_TICKET_CREATE_FOR_REPLENISHMENT_MISSING,
        label: "Enquiry Ticket create For Replenishment Missing",
      },
      {
        name: Setting.ENQUIRY_TICKET_CREATE_FOR_EARLY_CHECK_OUT,
        label: "Enquiry Ticket create For Early Checkout",
      },
      {
        name: Setting.ENQUIRY_TICKET_CREATE_FOR_LOCATION_CASH_AMOUNT_MISSING,
        label: "Enquiry Ticket create For Location Cash Amount Missing",
      },
      {
        name: Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_CASH_AMOUNT_MISSING,
        label: "Enquiry Ticket create For Order Cash Amount Missing",
      },
      {
        name: Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_UPI_AMOUNT_MISSING,
        label: "Enquiry Ticket create For Order UPI Amount Missing",
      },
      {
        name: Setting.ENQUIRY_TICKET_CREATE_FOR_DRAFT_AMOUNT_MISSING,
        label: "Enquiry Ticket create For Draft Amount Missing",
      },
    ],
    salary: [
      {
        name: Setting.ENABLE_SALARY_ADDITIONAL_HOURS,
        label: "Enable Salary Additional Hours",
      },
    ],
    activity: [
      {
        name: Setting.SHOW_DASHBOARD_PENDING_ACTIVITIES_REMINDER,
        label: "Dashboard Pending Activities Reminder",
      },
      {
        name: Setting.ENABLE_ACTIVITY_SLACK_NOTIFICATION,
        label: "Enable Activity Slack Notification",
      }
    ],
    visitor: [
      {
        name: Setting.ENABLE_VISITOR_FACE_RECOGNITION,
        label: "Enable Visitor Face Recognition",
      },
    ],
    device: [
      {
        name: Setting.ENABLE_DEVICE_USAGE,
        label: "Enable Device Usage",
      },
    ],
  };

  const renderValidationSection = (title, settings, prefix) => (
    <>
      <div className="d-flex justify-content-start w-100 mt-3">
        <h2 className="font-weight-bold">{title}</h2>
      </div>
      <div className="d-flex flex-column">
        {settings.map(({ name, label }, index) => (
          <div className="field-wrapper mt-3" key={`${prefix}-${index}`}>
            <SingleCheckbox
              name={name}
              label={label}
              handleOnChangeSubmit={(value, name) => handleCheckBoxValue(value, name)}
            />
          </div>
        ))}
      </div>
    </>
  );
  return (
    <div className="mb-4 pb-4 mb-sm-2 pb-sm-2">
      <Form
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleUpdate}
      >
        <div className="card bg-white">
          <div className="card-body">
          {renderValidationSection("CheckIn Validations", validationSettings.checkIn, "checkin")}
          {renderValidationSection("CheckOut Validations", validationSettings.checkOut, "checkout")}
          {renderValidationSection("Fine", validationSettings.fine, "fine")}
          {renderValidationSection("Order", validationSettings.order, "order")}
          {renderValidationSection("Bonus", validationSettings.bonus, "bonus")}
          {renderValidationSection("Enquiry Ticket", validationSettings.enquiryTicket, "enquiryTicket")}
          {renderValidationSection("Salary", validationSettings.salary, "salary")}
          {renderValidationSection("Activity", validationSettings.activity, "activity")}
          {renderValidationSection("Visitor", validationSettings.visitor, "visitor")}
          {renderValidationSection("Device", validationSettings.device, "device")}

            <br />
            <div className="mt-4">
              <div className="col">
                <StoryPointSelect
                  name="minimum_story_points"
                  label="Minimum Story Points"
                  placeholder="Select Minimum Story Points"
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <Numbers
                  label="Minimum Replenish Products"
                  name="minimum_replenish_products"
                  onChange={handleMinimumReplenishChange}
                />
              </div>
              <div className="col">
                <Numbers
                  label="Stock Entry Minimum Count"
                  name={Setting.STOCK_ENTRY_MINIMUM_COUNT}
                  onChange={handleStockEntryMinimumCountChange}
                />
              </div>
              <div className="col">
                <MultiSelect
                  label="Allowed Shifts"
                  name={Setting.ROLE_ALLOWED_SHIFT}
                  options={shiftList ? shiftList : []}
                  handleOnChangeSubmit={handleAllowedShiftChange}
                />
              </div>
              <div className="col">
                <MultiSelect
                  label="Allowed Order Types "
                  name={Setting.ALLOWED_ORDER_TYPES}
                  options={orderTypes ? orderTypes : []}
                  handleOnChangeSubmit={handleAllowedOrderTypeChange}
                />
              </div>
              <div className="col">
                <AccountTypeSelect
                  label="Account Type"
                  name={Setting.ACCOUNT_TYPE}
                  accountTypeOption={setAccountType}
                  onChange={handleAccountTypeChange}
                />
              </div>
              <div className="col">
                <StoreSelector
                  label="Allowed Location"
                  name={Setting.ROLE_ALLOWED_LOCATIONS}
                  setStoreList={setStoreList}
                  handleOnChangeSubmit={handleAllowedLocationChange}
                />
              </div>

              <div className="col">
                <Text
                  label="Auto Logout Hours"
                  name="auto_logout_hours"
                  onChange={handleLogoutHours}
                />
              </div>
              <div className="col">
                <Numbers
                  label="Reported Tickets Minimum Story Points"
                  name="reported_tickets_minimum_story_points"
                  onChange={handleTicketsMinimumStoryPointsChange}
                />
              </div>
            </div>
            <label>Working Days</label>
            <br />
            <div className="my-3">
                <WeekdaysCheckbox
                   selectedDays={selectedDays}
                   handleCheckboxChange={handleCheckboxChange}
                />
            </div>
            <div className="mb-4">
              <div>
                <SaveButton />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RoleSetting;
