import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { isBadRequest } from "../lib/Http";


class AppVersionService {

    static create(data, callback) {

        apiClient
            .post(`${endpoints().appVersionAPI}`, data)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                }
                return callback(response && response.data);
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                    console.error(errorMessage);
                }
            });
    }

    static update(data, callback) {

        apiClient
            .put(`${endpoints().appVersionAPI}`, data)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                }
                return callback(response && response.data);
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                    console.error(errorMessage);
                }
            });
    }
x
static updateStatus(id,data, callback) {

    apiClient
        .put(`${endpoints().appVersionAPI}/status/${id}`, data)
        .then((response) => {
            let successMessage;
            if (response && response.data) {
                successMessage = response.data.message;
                Toast.success(successMessage);
            }
            return callback(response && response.data);
        })
        .catch((error) => {
            if (isBadRequest(error)) {
                let errorMessage;
                const errorRequest = error.response.request;
                if (errorRequest && errorRequest.response) {
                    errorMessage = JSON.parse(errorRequest.response).message;
                }
                Toast.error(errorMessage);
                console.error(errorMessage);
            }
        });
}
    static delete(id, callback) {

        apiClient
            .delete(`${endpoints().appVersionAPI}/${id}`)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                }
                return callback(response && response.data);
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                    console.error(errorMessage);
                }
            });
    }

    static get(data, callback) {

        apiClient
            .get(`${endpoints().appAPI}`, data)
            .then((response) => {
                return callback(response && response.data);
            })
    }
}

export default AppVersionService;