import { faBoxesPacking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { bindActionCreators } from "redux";
import "../../scss/_custom.scss";

// Components
import Action from "../../components/Action";
import AddButton from "../../components/AddButton";
import MoreDropdown from "../../components/authentication/moreDropdown";
import AvatarCard from "../../components/AvatarCard";
import CountBadge from "../../components/CountBadge";
import DeleteModal from "../../components/DeleteModal";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import SaveButton from "../../components/SaveButton";
import SelectStore from "../../components/SelectStore";
import StatusText from "../../components/StatusText";
import StockEntryDetailPage from "./stockEntryDetailPage";
import CustomAttachment from "../../components/CustomAttachment";
import DateSelector from "../../components/Date";
import Drawer from "../../components/Drawer";
import MediaCarousel from "../../components/MediaCarousel";
import ShiftSelect from "../../components/ShiftSelect";
import { isVideoURL } from "../../components/static/imageCarousel";
import Toast from "../../components/Toast";
import UserSelect from "../../components/UserSelect";

// Services
import StatusService from "../../services/StatusService";
import StockEntryService from "../../services/StockEntryService";
import StockEntryProductService from "../../services/StockProductEntryService";
import { hasPermission } from "../../services/UserRolePermissionService";
import CompanyUserService from "../../services/UserService";

// API
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Helpers
import { HttpStatus } from "../../helpers/HttpStatus";
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";
import * as location from "../../helpers/StoreList";
import { TODAY_VALUE } from "../../helpers/Date";

// Lib
import DateTime from "../../lib/DateTime";
import { isLoggedIn } from "../../lib/Helper";
import Url from "../../lib/Url";
import ArrayList from "../../lib/ArrayList";
import { isBadRequest, SUCCESS_RESPONSE } from "../../lib/Http";

// Actions
import { deleteStockEntry } from "../../actions/stockEntry";
import { fetchList } from "../../actions/table";

// Assets
import videoImage from "../../assets/img/videoImage.png";

let stockEntrys = "stockEntry";

export const Tabs = {
  ACTIVE: "Active",
  ALL: "All",
  DRAFT: "Draft",
  COMPLETED: "Completed",
  STOCK_ENTRY: "Stock Entry",
  STOCK_ENTRY_PRODUCT: "Stock Entry Product",
};

const StockEntry = (props) => {
  const [isDeleteModel, setIsDeleteModel] = useState(false);

  const [currentData, setCurrentData] = useState();

  const [storeModalOpen, setStoreModalOpen] = useState(false);

  const [storeList, setStoreList] = useState([]);

  const [ownerValue, setownerValue] = useState([]);

  const [StockOwner, setStockOwner] = useState();

  const [statusList, setStatusList] = useState([]);

  const [selectedProducts, setSelectedProduct] = useState([]);

  const [PermissionList, setPermissionList] = useState();

  const [statusDropdownList, setStatus] = useState([]);

  const [selectedCheckBox, setSelectedCheckBox] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [photos, setPhotos] = useState([]);

  const [row, setRow] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [shiftList, setShiftList] = useState([]);

  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") || Tabs.STOCK_ENTRY
  );

  const { history } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    isLoggedIn();
    getStoreList();
    getUserDetail();
    getUserRoleValue();
    getRolePermission();
    getStatus();
  }, []);

  //   Get Status List
  const getStatusList = async (currentStatusId) => {
    if (currentStatusId) {
      const data = await StatusService.nextStatusSearch(
        ObjectName.STOCK_ENTRY_PRODUCT,
        currentStatusId
      );

      if (data && data.length > 0) {
        setStatusList(data);
      }
    } else {
      const statusLists = await StatusService.getOption(
        ObjectName.STOCK_ENTRY_PRODUCT
      );

      setStatus(statusLists);
    }
  };

  //   Get Status List
  const getStatus = async () => {
    const statusLists = await StatusService.getOption(
      ObjectName.STOCK_ENTRY_PRODUCT
    );

    setStatus(statusLists);
  };

  //Sort By Option Values
  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "date:ASC",
      label: "Date",
    },
  ];

  const getRolePermission = async () => {
    const statusPermission = hasPermission(
      Permission.STOCK_ENTRY_MANAGE_OTHERS
    );
    setPermissionList(statusPermission);
  };

  const hasAddStockEntryPermission = hasPermission(Permission.STOCK_ENTRY_ADD);

  const StoreSelectModal = () => {
    setStoreModalOpen(!storeModalOpen);
    setRow(null);
  };

  const getUserDetail = async () => {
    try {
      let response = await CompanyUserService.search();
      const createdBy = response.id;
      setStockOwner(createdBy);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  const getUserRoleValue = async () => {
    const userRole = await CompanyUserService.list();
    const data = [];
    userRole &&
      userRole.length > 0 &&
      userRole.forEach((list) => {
        data.push({
          label: list.first_name,
          value: list.id,
        });
      });
    setownerValue(data);
  };

  const getStoreList = async () => {
    try {
      //create new array for location list

      //get location list
      let response = await apiClient.get(`${endpoints().locationAPI}/search`);
      let lists = [];
      let stores = response.data.data;
      if (stores && stores.length > 0) {
        stores.forEach((storesList) => {
          storesList.status == location.ACTIVE_STORE_TAB &&
            lists.push({
              id: storesList.id,
              value: storesList.id,
              label: storesList.name,
            });
        });
      }
      setStoreList(lists);
      if (lists && lists.length > 0) return lists;
    } catch (err) { }
  };

  const AddStockEntry = (values) => {
    try {
      //create stock entry data
      let stockEntryData = new Object();

      let storeId = values.location.value;

      stockEntryData.storeId =
        values && values.location && values.location.value;

      stockEntryData.owner_id = StockOwner;

      stockEntryData.storeProductId =
        values && values.storeProduct && values.storeProduct.value;

      stockEntryData.quantity = values.quantity;

      let params = { pagination: true };
      //add new stock entry
      dispatch(
        StockEntryService.create(stockEntryData, params, (response) => {
          //validate response exist or not
          if (response && response.stockEntryDetails) {
            //get stock entry details
            let stockEntryDetails = response.stockEntryDetails;
            //validate stock entry details exist or not
            if (stockEntryDetails) {
              history.push(`/stockEntry/${stockEntryDetails.id}`);
            }
          }
        })
      );
    } catch (err) { }
  };

  const updateStockEntry = async (values) => {
    try {
      const data = new FormData();
      // store id
      data.append("storeId", values?.location?.id);

      // date
      data.append("date", DateTime.toISOStringDate(values?.date));
      data.append("due_date", DateTime.toISOStringDate(values?.due_date));
      data.append("shift", values?.shift ? values?.shift?.value : "");

      data.append(
        "owner",
        values?.owner?.id ? values?.owner?.id : values?.owner?.value
      );

      apiClient
        .put(`${endpoints().stockEntry}/${row?.id}`, data)
        .then((res) => {
          if (res.status == SUCCESS_RESPONSE) {
            Toast.success(res?.data?.message);
            dispatch(
              fetchList(
                "stockEntry",
                `${endpoints().stockEntry}/search`,
                Url.GetParam("page") ? Url.GetParam("page") : 1,
                Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                {
                  ...Url.GetAllParams(),
                }
              )
            );
            StoreSelectModal();
          }
        })
        .catch((err) => {
          if (isBadRequest(err)) {
            let errorMessage;
            const errorRequest = err.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addStoreForm = (
    <>
      <SelectStore
        name="location"
        label="Location"
        placeholder="Select Location"
        required
        StoreList={setLocationList}
      />

      {row && (
        <>
          <DateSelector name="date" fontBolded label="Date" />
          <ShiftSelect name="shift" label="Shift" shiftOption={setShiftList} />
          <UserSelect
            label="Owner"
            name="owner"
            userList={setownerValue}
            showUserDetailsPageLink={row?.owner_id ? true : false}
            userId={row?.owner_id}
          />
          <DateSelector name="due_date" fontBolded label="Due Date" />
          <MediaCarousel
            showCarasoul
            objectName={ObjectName.STOCK_ENTRY}
            objectId={row?.id}
            history={history}
            attachmentsList={true}
            Attachments={"Attachments"}
          />
        </>
      )}
    </>
  );

  const addStoreFooter = (
    <div className="container-fluid">
      <div className="col-sm-12 mt-1 text-center">
        <SaveButton type="submit" label={row?.id ? "Update" : "Next"} className="ms-3" />
      </div>
    </div>
  );

  const toggleTab = (tab) => {
    setActiveTab(tab);
    history.push(`?tab=${tab}&date=${TODAY_VALUE}`);
  };

  const BulkSelect = (selectedProducts) => {
    setSelectedProduct(selectedProducts);
  };

  const handleChange = (value) => {
    setIsLoading(true);
    if (selectedProducts && selectedProducts.length > 0) {
      let body = { selectedIds: selectedProducts, status: value };
      dispatch(
        StockEntryProductService.updateStatus(
          body,
          {
            sort: Url.GetParam("sort"),
            sortDir: Url.GetParam("sortDir"),
            search: Url.GetParam("search"),
            page: Url.GetParam("page"),
            pageSize: Url.GetParam("pageSize"),
            brand: Url.GetParam("brand"),
            category: Url.GetParam("category"),
            stockEntryProductType: Url.GetParam("stockEntryProductType"),
            location: Url.GetParam("location"),
            tag: Url.GetParam("tag"),
            startDate: Url.GetParam("startDate"),
            endDate: Url.GetParam("endDate"),
            status: Url.GetParam("status"),
          },
          (res) => {
            if (res.status == HttpStatus.OK) {
              setIsLoading(false);
              setSelectedProduct("");
              setSelectedCheckBox(false);
              setSelectedCheckBox(true);
            }
          }
        )
      );
    } else {
      toast.error("Select Product(s)");
    }
  };

  const stockEntryDelete = (id) => {
    dispatch(
      deleteStockEntry(
        id,
        {
          pagination: true,
          sort: "id",
          sortDir: "DESC",
          search: Url.GetParam("search") || "",
          page: Url.GetParam("page") || "",
          pageSize: Url.GetParam("pageSize") || "",
        },
        props.stockEntryCurrentpage,
        props.stockEntryPageSize
      )
    );
    setIsDeleteModel(false);
  };

  const handleImageModelOpen = (photos, index) => {
    setIsAttachmentOpen(true);
    setPhotos(photos);
    document.body.style.overflow = "hidden";
  };

  const RenderPaytmImage = ({ row }) => {
    const imgRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting);
        },
        { threshold: 0.1 }
      );

      if (imgRef.current) observer.observe(imgRef.current);

      return () => observer.disconnect();
    }, []);

    const isVideo =
      row?.image_urls[0] && isVideoURL(row?.image_urls[0]?.file_path);

    if (!row?.image_urls?.length) return null;

    const mediaSrc = isVideo ? videoImage : row?.image_urls[0]?.media_url;

    return (
      <div style={{ position: "relative", display: "inline-block" }}>
        <img
          ref={imgRef}
          src={isVisible ? mediaSrc : ""}
          alt="img"
          width={50}
          height={50}
          loading="lazy"
          style={{
            opacity: isVisible ? 1 : 0.5,
            transition: "opacity 0.5s ease-in",
          }}
          onClick={() => handleImageModelOpen(row?.image_urls, 0)}
        />

        {row?.image_urls?.length > 1 && (
          <span
            style={{
              position: "absolute",
              top: "-9px",
              right: "-9px",
              backgroundColor: "red",
              color: "white",
              borderRadius: "50%",
              padding: "2px 6px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {row?.image_urls?.length}
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      {isAttachmentOpen && (
        <CustomAttachment
          isOpen={isAttachmentOpen}
          setIsOpen={setIsAttachmentOpen}
          mediaArray={photos}
        />
      )}
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title="Stock Entry"
        label={currentData?.stock_entry_number}
        deleteFunction={() => stockEntryDelete(currentData?.id)}
      />

      <Drawer
        modelTitle={row?.id ? "Edit Stock Entry" : "Select Location"}
        DrawerBody={addStoreForm}
        DrawerFooter={addStoreFooter}
        onSubmit={(values) => {
          if (row) {
            updateStockEntry(values);
          } else {
            AddStockEntry(values);
          }
        }}
        initialValues={{
          location:
            row && ArrayList.isArray(locationList)
              ? locationList.find((data) => data?.id == row?.store_id)
              : "",
          shift:
            row && ArrayList.isArray(shiftList)
              ? shiftList.find((data) => data?.id == row?.shiftId)
              : "",
          owner:
            row && ArrayList.isArray(ownerValue)
              ? ownerValue.find((data) => data?.id == row?.owner_id)
              : "",
          date: row?.date ? row?.date : "",
          due_date: row?.due_date ? row?.due_date : "",
        }}
        handleOpenModal={StoreSelectModal}
        handleCloseModal={StoreSelectModal}
        handleDrawerClose={StoreSelectModal}
        isModalOpen={storeModalOpen}
        enableReinitialize
      />

      <div className="d-flex justify-content-between">
        <PageTitle label="Stock Entry" />
        {activeTab === Tabs.STOCK_ENTRY && (
          <>
            {hasAddStockEntryPermission && (
              <AddButton
                className="ms-2 me-1"
                label="Add New"
                onClick={(e) => {
                  StoreSelectModal();
                }}
              />
            )}
          </>
        )}
        {activeTab === Tabs.STOCK_ENTRY_PRODUCT && (
          <Action
            dropdownLinks={
              activeTab === Tabs.STOCK_ENTRY_PRODUCT ? statusDropdownList : null
            }
            handleChange={handleChange}
          />
        )}
      </div>

      <Nav tabs className="admin-tabs mb-1">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.STOCK_ENTRY,
            })}
            onClick={() => {
              toggleTab(Tabs.STOCK_ENTRY);
            }}
          >
            Stock Entry
            <CountBadge count={props.stockEntryCount} />
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.STOCK_ENTRY_PRODUCT,
            })}
            onClick={() => {
              toggleTab(Tabs.STOCK_ENTRY_PRODUCT);
            }}
          >
            Stock Entry Products
            <CountBadge count={props.stockEntryProductCount} />
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {/* Stock Entry Tab */}
        {activeTab == Tabs.STOCK_ENTRY && (
          <TabPane tabId={Tabs.STOCK_ENTRY}>
            <div className="tab-content-wrapper">
              <div className="mt-4">
                <ReduxTable
                  id="stockEntry"
                  newTableHeading
                  searchPlaceholder="Search Stock Entry"
                  icon={<FontAwesomeIcon icon={faBoxesPacking} />}
                  apiURL={`${endpoints().stockEntry}/search`}
                  history={history}
                  sortByOptions={sortByOption}
                  paramsToUrl={true}
                  showHeader
                  params={{
                    objectName: ObjectName.STOCK_ENTRY,
                    sort: Url.GetParam("sort") ? Url.GetParam("sort") : "id",
                    sortDir: Url.GetParam("sortDir")
                      ? Url.GetParam("sortDir")
                      : "DESC",
                  }}
                  showCustomDateFilter
                  showStoreFilter
                  showUserFilter
                  showStatusFilter
                  showShiftFilter
                >
                  <ReduxColumn
                    className="text-center"
                    field="stock_entry_number"
                    sortBy="stock_entry_number"
                    isClickable="true"
                    type="link"
                    width="110px"
                    maxWidth="110px"
                    minWidth="110px"
                    renderField={(row) => (
                      <Link
                        to={`/stockEntry/${row.id}`}
                        className="link-opacity-75"
                      >
                        {row.stock_entry_number}
                      </Link>
                    )}
                  >
                    Stock Entry#
                  </ReduxColumn>
                  <ReduxColumn
                    sortBy="date"
                    width="110px"
                    maxWidth="110px"
                    minWidth="110px"
                    className="text-center"
                    renderField={(row) => (
                      <span>{DateTime.getDate(row.date)}</span>
                    )}
                  >
                    Date
                  </ReduxColumn>
                  <ReduxColumn
                    field="shift"
                    sortBy="shift_id"
                    width="110px"
                    maxWidth="110px"
                    minWidth="110px"
                  >
                    Shift
                  </ReduxColumn>
                  <ReduxColumn
                    field="location"
                    sortBy="location"
                    width="110px"
                    maxWidth="110px"
                    minWidth="110px"
                  >
                    Location
                  </ReduxColumn>

                  {PermissionList && (
                    <ReduxColumn
                      width="210px"
                      minWidth="210px"
                      maxWidth="210px"
                      renderField={(row) => (
                        <>
                          <div className="d-flex text-break">
                            <AvatarCard
                              id="avatar"
                              firstName={row.owner_first_name}
                              lastName={row.owner_last_name}
                              url={row.media_url}
                            />
                          </div>
                        </>
                      )}
                    >
                      Owner
                    </ReduxColumn>
                  )}
                  <ReduxColumn
                    field="status"
                    width="110px"
                    maxWidth="110px"
                    minWidth="110px"
                    renderField={(row) => (
                      <StatusText
                        backgroundColor={row.statusColor}
                        status={row.status}
                      />
                    )}
                  >
                    Status
                  </ReduxColumn>
                  <ReduxColumn
                    field="image_urls"
                    sortBy="image_urls"
                    className="text-align-last-center justify-content-center"
                    disableOnClick
                    renderField={(row) =>
                      row &&
                      row?.image_urls?.length > 0 && (
                        <RenderPaytmImage row={row} />
                      )
                    }
                  >
                    Photos
                  </ReduxColumn>
                  <ReduxColumn
                    field="Action"
                    width="110px"
                    maxWidth="110px"
                    minWidth="110px"
                    className="text-center"
                    disableOnClick
                    renderField={(row) => (
                      <div className="">
                        <MoreDropdown
                          onClick={() => {
                            setStatusList([]);
                            getStatusList(row.statusId);
                          }}
                        >
                          {statusList &&
                            statusList.length > 0 &&
                            statusList.map((data) => {
                              return (
                                <DropdownItem
                                  onClick={() => {
                                    dispatch(
                                      StockEntryService.updateStatus(
                                        row.id,
                                        data.value,
                                        {
                                          pagination: true,
                                          sort: "id",
                                          sortDir: "DESC",
                                          search: Url.GetParam("search") || "",
                                          page: Url.GetParam("page") || "",
                                          pageSize:
                                            Url.GetParam("pageSize") || "",
                                          location:
                                            Url.GetParam("location") || "",
                                          status: Url.GetParam("status") || "",
                                          user: Url.GetParam("user") || "",
                                          startDate: Url.GetParam("startDate"),
                                          endDate: Url.GetParam("endDate"),
                                          date: Url.GetParam("date"),
                                        },
                                        props.stockEntryCurrentpage,
                                        props.stockEntryPageSize
                                      )
                                    );
                                  }}
                                >
                                  {data.label}
                                </DropdownItem>
                              );
                            })}
                          <DropdownItem
                            onClick={() => {
                              StoreSelectModal();
                              setRow(row);
                            }}
                          >
                            Quick View
                          </DropdownItem>
                        </MoreDropdown>
                      </div>
                    )}
                  >
                    Action
                  </ReduxColumn>
                </ReduxTable>
              </div>
            </div>
          </TabPane>
        )}

        {/* Stock Entry Product Tab */}
        {activeTab == Tabs.STOCK_ENTRY_PRODUCT && (
          <TabPane tabId={Tabs.STOCK_ENTRY_PRODUCT}>
            <StockEntryDetailPage
              history={props.history}
              BulkSelect={BulkSelect}
              selectedCheckBox={selectedCheckBox}
              isLoading={isLoading}
              bulkSelect
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

function mapStateToProps(state) {
  const reduxTable = state.table;
  // Get Published products count
  const stockEntryCount =
    reduxTable[Tabs.ORDER] && reduxTable[Tabs.ORDER].isFetching == false
      ? reduxTable[Tabs.ORDER].totalCount
      : 0;

  const stockEntryProductCount =
    reduxTable["stockEntryProduct"] &&
      reduxTable["stockEntryProduct"].isFetching == false
      ? reduxTable["OrderProduct"].totalCount
      : 0;
  const stockEntryCurrentpage =
    reduxTable[stockEntrys] && reduxTable[stockEntrys].isFetching == false
      ? reduxTable[stockEntrys].currentPage
      : 1;
  const stockEntryPageSize =
    reduxTable[stockEntrys] && reduxTable[stockEntrys].isFetching == false
      ? reduxTable[stockEntrys].pageSize
      : 25;
  // Get Draft Products count
  return {
    stockEntryCount,
    stockEntryProductCount,
    stockEntryCurrentpage,
    stockEntryPageSize,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StockEntry);
