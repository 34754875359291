import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { endpoints } from "../../api/endPoints";
import StatusText from "../../components/StatusText";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import ObjectName from "../../helpers/ObjectName";
import Currency from "../../lib/Currency";
import DateTime from "../../lib/DateTime";
import Url from "../../lib/Url";
import { Order } from "../../helpers/Order";
import { DEFAULT_PAGE } from "../product";
import ProductCard from "../product/components/productCard";
import { useDispatch } from "react-redux";
import StatusService from "../../services/StatusService";
import { DropdownItem } from "reactstrap";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ProductInvoiceService from "../../services/productInvoiceService";


const invoiceProductTab = (props) => {
  let { handleBulkSelect, selectedCheckBox } = props;
  const [statusList, setStatusList] = useState([]);

  const [page, setPage] = useState(Url.GetParam("page"));
  const dispatch = useDispatch();

  useEffect(() => {
    getStatusList();
  }, []);


 const getStatusList = async (currentStatusId, allowed_statuses) => {
    try{
    if (currentStatusId) {
      const data = await StatusService.nextStatusSearch(
        ObjectName.INVOICE_PRODUCT,
        currentStatusId,
        "",
        allowed_statuses
      );

      if (data && data.length > 0) {
        setStatusList(data);
      }
    }
  }catch(err){
   console.log(err); 
  }
  };

  const orderSortOptions = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "product_display_name:ASC",
      label: "Name",
    },
  ];
  const typeOptions = [
    {
      label: Order.TYPE_DELIVERY_TEXT,
      value: Order.TYPE_DELIVERY,
    },
  ];

  return (
    <>
      <ReduxTable
        id="invoiceProduct"
        showHeader
        newTableHeading
        apiURL={`${endpoints().invoiceProductApi}`}
        searchPlaceholder="Search"
        paramsToUrl={true}
        sortByOptions={orderSortOptions}
        history={props.history}
        icon={<FontAwesomeIcon icon={faCartShopping} />}
        message="You can start by clicking on Add Order"
        showCustomDateFilter
        showCategoryFilter
        showBrandFilter
        showStoreFilter
        showStatusFilter
        customTypeOption={typeOptions}
        showProductFilter
        params={{
          tab: Url.GetParam("tab"),
          search: Url.GetParam("search"),
          pageSize: Url.GetParam("pageSize"),
          page: page ? page : DEFAULT_PAGE,
          objectName: ObjectName.ORDER_PRODUCT,
          type: Url.GetParam("type"),
        }}
        setPage={setPage}
      >
        <ReduxColumn
          width={"110px"}
          minWidth="110px"
          maxWidth="110px"
          field="id"
          sortBy="id"
        >
          Invoice#
        </ReduxColumn>
        <ReduxColumn
          field="orderDate"
          sortBy="order_date"
          renderField={(row) => (
            <span>
              {row.orderDate &&
                DateTime.getDateTimeByUserProfileTimezone(row.orderDate)}
            </span>
          )}
        >
          Date
        </ReduxColumn>
        <ReduxColumn field="locationName" sortBy="location">
          Location
        </ReduxColumn>
        <ReduxColumn
          field="product"
          type="link"
          sortBy="product_display_name"
          renderField={(row) => (
            <>
              <ProductCard
                productImageIcon
                square
                productName={row.product_name}
                brandName={row.brand_name}
                size={row.size}
                unit={row.unit}
                url={row.image}
                id={row.product_id}
                brand_id={row.brand_id}
              />
            </>
          )}
        >
          Product
        </ReduxColumn>
        <ReduxColumn field="quantity" sortBy="quantity" className="text-center">
          Quantity
        </ReduxColumn>
        <ReduxColumn
          field="unit_price"
          renderField={(row) => (
            <span className="float-end">
              {Currency.Format(row.unit_price)}
            </span>
          )}
          sortBy="unit_price"
        >
          Unit Price
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          renderField={(row) => (
            <span className="float-end">{Currency.Format(row.amount)}</span>
          )}
          sortBy="price"
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="cost_price"
          renderField={(row) => (
            <span className="float-end">
              {Currency.Format(row.cost_price)}
            </span>
          )}
          sortBy="cost_price"
        >
          Cost Price
        </ReduxColumn>
        <ReduxColumn
          field="profit_amount"
          sortBy="profit_amount"
          renderField={(row) => (
            <span className="float-end">
              {Currency.Format(row.profit_amount)}
            </span>
          )}
        >
          Profit Amount
        </ReduxColumn>
        <ReduxColumn
          field="status"
          width={"120px"}
          minWidth="120px"
          maxWidth="120px"
          className="column-status"
          renderField={(row) => (
            <StatusText
              backgroundColor={row?.colourCode}
              status={row?.status}
            />
          )}
        >
          Status
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          disableOnClick
          renderField={(row) => (
            <div className="action-group-dropdown">
              <MoreDropdown
                onClick={() => {
                  setStatusList([]);
                  getStatusList(row.currentStatusId, row.allowed_statuses);
                }}
              >
                {statusList &&
                  statusList.length > 0 &&
                  statusList.map((data) => {
                    return (
                      <DropdownItem
                        onClick={async () => {
                          dispatch(
                           await ProductInvoiceService.updateStatus(
                              row.saleproductInvoiceId,
                              {status:data?.value},
                              {
                                search: Url.GetParam("search") || "",
                                page: Url.GetParam("page") ,
                                sort: Url.GetParam("sort") ,
                                sortDir: Url.GetParam("sortDir") ,
                                startDate: Url.GetParam("startDate") ,
                                endDate: Url.GetParam("endDate") ,
                                pageSize:
                                  Url.GetParam("pageSize") ,
                              },
                              Url.GetParam("page"),
                              Url.GetParam("pageSize"),
                            )
                          );
                        }}
                      >
                        {data?.label}
                      </DropdownItem>
                    );
                  })}
                
              </MoreDropdown> 
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default invoiceProductTab;
