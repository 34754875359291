// Status Constants
const Status = {
  STATUS_ACTIVE: "Active",
  STATUS_INACTIVE: "InActive",
  STATUS_ACTIVE_VALUE: 1,
  STATUS_INACTIVE_VALUE: 2,
};

export default Status;

export const GroupStatus = [
  {
    value: Status.STATUS_ACTIVE_VALUE,
    label: Status.STATUS_ACTIVE,
  },
  {
    value: Status.STATUS_INACTIVE_VALUE,
    label: Status.STATUS_INACTIVE,
  },
];
