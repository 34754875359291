import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import BreadCrumb from "../../../components/Breadcrumb";
import Form from "../../../components/Form";
import SaveButton from "../../../components/SaveButton";
import CancelButton from "../../../components/CancelButton";
import ObjectName from "../../../helpers/ObjectName";
import ActivityList from "../../../components/ActivityList";
import Action from "../../../components/Action";
import DeleteModal from "../../../components/DeleteModal";
import Select from "../../../components/Select";
import PaymentPreferenceService from "../../../services/PaymentPreferenceService";
import Spinner from "../../../components/Spinner";
import Text from "../../../components/Text";
import Number from "../../../components/Number";
import PaymentPreference from "../../../helpers/AccountPaymentPreference";
import Url from "../../../lib/Url";
import Account from "../../../helpers/Account";

const PaymentPreferenceDetail = (props) => {
  const { history } = props;

  const Tab = {
    SUMMARY: "Summary",
    HISTORY: "History",
  };
  const Param = new URLSearchParams(props.history.location.search);

  const [detail, setDetail] = useState();

  const id = props.match.params.id;

  const dispatch = useDispatch();
  const tab = Param.get("tab");
  const [activeTab, setActiveTab] = useState(Tab.SUMMARY);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [typeValue, setTypeVale] = useState(null);
  const [statusValue, setStatusVale] = useState(null);
  const [editable, setEditable] = useState(true);
  const [accountNumberValue, setAccountNumberValue] = useState(null);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    const data = await PaymentPreferenceService.get(id);
    setDetail(data);
  };

  let accountId = detail && detail?.account_id;

  const paymentAccounts = [
    {
      value: "Saving",
      label: "Saving",
    },
    {
      value: "Current",
      label: "Current",
    },
    {
      value: "Overdraft",
      label: "Overdraft",
    },
    {
      value: "Cash Credit",
      label: "Cash Credit",
    },
    {
      value: "Loan Account",
      label: "Loan Account",
    },
    {
      value: "NRE",
      label: "NRE",
    },
  ];

  const statusOptions = [
    {
      label: PaymentPreference.STATUS_ACTIVE,
      value: PaymentPreference.ACTIVE,
    },
    {
      label: PaymentPreference.STATUS_INACTIVE,
      value: PaymentPreference.INACTIVE,
    },
  ];

  // Toggle change function
  const tabToggle = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = async (values) => {
    const data = new FormData();

    data.append("bank_name", values && values?.bank_name);
    data.append("bank_account_number", values && values?.bank_account_number);
    data.append("bank_account_name", values && values?.bank_account_name);
    data.append("type", values && values?.type?.value);
    data.append("ifsc_code", values && values?.ifsc_code);
    data.append("status", values && values?.status?.value);

    setIsLoading(true);
    await PaymentPreferenceService.update(id, data, (res) => {
      if (res) {
        getDetails();
      }
    });
    setEditable(true);
    setIsLoading(false);
  };

  const initialValues = {
    bank_name: detail?.bank_name ? detail?.bank_name : "",
    bank_account_number: accountNumberValue
      ? accountNumberValue
      : detail?.bank_account_number
      ? detail?.bank_account_number
      : "",
    bank_account_name: detail?.bank_account_name
      ? detail?.bank_account_name
      : "",
    type: typeValue
      ? {
          label: typeValue.label,
          value: typeValue.value,
        }
      : detail?.type
      ? paymentAccounts.find((value) => value.label == detail?.type)
      : "",
    ifsc_code: detail?.ifsc_code ? detail?.ifsc_code : "",
    status: statusValue
      ? {
          label: statusValue.label,
          value: statusValue.value,
        }
      : detail?.id
      ? statusOptions.find((data) => detail?.status == data.value)
      : "",
  };

  const breadcrumbList = [
    { label: "Home", link: `/accounts?status=${Account.ACTIVE}` },
    {
      label: "Payment Preference List",
      link: `/accounts/${accountId}?${props.location.search}`,
    },
    {
      label: "Payment Preference Details",
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  if (editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  const accountPaymentPreferenceDelete = async () => {
    dispatch(await PaymentPreferenceService.delete(id));
    history.push(`/accounts/${accountId}${props.location.search}`);
  };

  if (isLoading) {
    return <Spinner />;
  }

  function getSavedActiveTab() {
    const savedTab = localStorage.getItem("activeTab");
    return savedTab ? savedTab : "Summary";
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  const handleTypeChange = (e) => {
    let data = e.values.type;
    setTypeVale(data);
  };

  const handleStatusChange = (e) => {
    let data = e.values.status;
    setStatusVale(data);
  };

  const handleAccountnumberChange = (e) => {
    let value = e?.target?.value;
    setAccountNumberValue(value);
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Payment Preference"
        id={detail?.id}
        label={`${detail?.bank_account_name} - ${detail?.bank_name}`}
        deleteFunction={accountPaymentPreferenceDelete}
      />
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        <div>
          <PageTitle label={`Payment Preference Detail #${detail?.id}`} />
        </div>
        <div className="d-flex">
          <div className="ps-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>

      <div>
        <Nav tabs className="admin-tabs">
          {/* Detail Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.SUMMARY,
              })}
              onClick={() => {
                tabToggle(Tab.SUMMARY);
                handleTabChange(Tab.SUMMARY);
              }}
            >
              {Tab.SUMMARY}
            </NavLink>
          </NavItem>

          {/* History Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => {
                tabToggle(Tab.HISTORY);
                handleTabChange(Tab.HISTORY);
              }}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        </Nav>
      </div>

      <TabContent activeTab={activeTab}>
        {activeTab === Tab.SUMMARY && (
          <TabPane tabId={Tab.SUMMARY} className="w-100 card card-body">
            <Form
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <div className="">
                <Text
                  label="Bank Name"
                  name="bank_name"
                  required
                  disabled={editable}
                />
                <Number
                  label="Account Number"
                  name="bank_account_number"
                  required
                  disabled={editable}
                  onChange={handleAccountnumberChange}
                />
                <Text
                  label="Account Name"
                  name="bank_account_name"
                  required
                  disabled={editable}
                />
                <Select
                  label=" Type"
                  name="type"
                  required
                  options={paymentAccounts}
                  onInputChange={handleTypeChange}
                  isDisabled={editable}
                />
                <Text
                  label="IFSC"
                  name="ifsc_code"
                  required
                  disabled={editable}
                />
                <Select
                  fullWidth={true}
                  label="Status"
                  name="status"
                  isClearable
                  options={statusOptions}
                  required
                  onInputChange={handleStatusChange}
                  isDisabled={editable}
                />
                {!editable && (
                  <>
                    <SaveButton label="Save" />
                    <CancelButton
                      onClick={() =>
                        history.push(
                          `/accounts/${accountId}${props.location.search}`
                        )
                      }
                    />
                  </>
                )}
              </div>
            </Form>
          </TabPane>
        )}

        {activeTab === Tab.HISTORY && (
          <TabPane tabId={Tab.HISTORY} className="w-100">
            <ActivityList
              id={id}
              objectId={id}
              object_name={ObjectName.ACCOUNT_PAYMENT_PREFERENCE}
              history={history}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default PaymentPreferenceDetail;
