
export const Feature = [
    "enable_dashboard",
    "enable_account",
    "enable_bill",
    "enable_payment",
    "enable_account_entry",
    "enable_salary",
    "enable_account_entry_category",
    "enable_payment_account",
    "enable_order",
    "enable_delivery_order",
    "enable_transfer",
    "enable_replenishment",
    "enable_purchase",
    "enable_purchase_order",
    "enable_stock_entry",
    "enable_sale_settlement",
    "enable_product",
    "enable_inspection",
    "enable_brand",
    "enable_category",
    "enable_location",
    "enable_customer",
    "enable_vendor",
    "enable_wish_list",
    "enable_page",
    "enable_activity",
    "enable_attendance",
    "enable_fine",
    "enable_gate_pass",
    "enable_visitor",
    "enable_user",
    "enable_training",
    "enable_time_sheet",
    "enable_location_allocation",
    "enable_Contact",
    "enable_Call_Register",
    "enable_ticket",
    "enable_sprint",
    "enable_ticket_search",
    "enable_test_case",
    "enable_ticket_setting",
    "enable_job",
    "enable_candidate",
    "enable_lead",
    "enable_report",
    "enable_distribution",
    "enable_setting",
    "enable_sync",
    "enable_order_product",
    "enable_recurring_task",
    "enable_bulk_order",

];

export const FeatureCheckBox = [
    {
        title: "My Dashboard",
        checkBox: [
            {
                name: "enable_dashboard",
                label: "Enable Dashboard"
            },
        ]
    },
    {
        title: "Accounts",
        checkBox: [
            {
                name: "enable_account",
                label: "Enable Accounts"
            },
            {
                name: "enable_bill",
                label: "Enable Bills"
            },
            {
                name: "enable_payment",
                label: "Enable Payments"
            },
            {
                name: "enable_account_entry",
                label: "Enable Account Entries"
            },
            {
                name: "enable_salary",
                label: "Enable Salary"
            },
            {
                name: "enable_account_entry_category",
                label: "Enable Account Entry Categories"
            },
            {
                name: "enable_payment_account",
                label: "Enable Payment Acoounts"
            },
        ],
    },
    {
        title: "Commerce",
        checkBox: [
            {
                name: "enable_order",
                label: "Enable Orders"
            },
            {
                name: "enable_order_product",
                label: "Enable Order Products"
            },
            {
                name: "enable_bulk_order",
                label: "Enable Bulk Orders"
            },
            {
                name: "enable_delivery_order",
                label: "Enable Delivery Orders"
            },
            {
                name: "enable_transfer",
                label: "Enable Transfers"
            },
            {
                name: "enable_replenishment",
                label: "Enable Replenishments"
            },
            {
                name: "enable_purchase",
                label: "Enable Purchases"
            },
            {
                name: "enable_purchase_order",
                label: "Enable Purchase Orders"
            },
            {
                name: "enable_stock_entry",
                label: "Enable Stock Entries"
            },
            {
                name: "enable_sale_settlement",
                label: "Enable Sale Settlements"
            },
            {
                name: "enable_product",
                label: "Enable Products"
            },
            {
                name: "enable_inspection",
                label: "Enable Inspections"
            },
            {
                name: "enable_brand",
                label: "Enable Brands"
            },
            {
                name: "enable_category",
                label: "Enable Categories"
            },
            {
                name: "enable_location",
                label: "Enable Locations"
            },
            {
                name: "enable_customer",
                label: "Enable Customers"
            },
            {
                name: "enable_vendor",
                label: "Enable Vendors"
            },
            {
                name: "enable_wish_list",
                label: "Enable WishLists"
            },
            {
                name: "enable_distribution",
                label: "Enable Distributions"
            },
        ],
    },
    {
        title: "Pages",
        checkBox: [
            {
                name: "enable_page",
                label: "Enable Pages"
            },
        ],
    },
    {
        title: "Peoples",
        checkBox: [
            {
                name: "enable_activity",
                label: "Enable Activities"
            },
            {
                name: "enable_attendance",
                label: "Enable Attendance"
            },
            {
                name: "enable_fine",
                label: "Enable Fines"
            },
            {
                name: "enable_gate_pass",
                label: "Enable Gate Pass"
            },
            {
                name: "enable_visitor",
                label: "Enable Visitors"
            },
            {
                name: "enable_user",
                label: "Enable Users"
            },
            {
                name: "enable_training",
                label: "Enable Trainings"
            },
            {
                name: "enable_time_sheet",
                label: "Enable TimeSheets"
            },
            {
                name: "enable_location_allocation",
                label: "Enable Location Allocation"
            },
            {
                name: "enable_gst",
                label: "Enable Gst"
            },
            {
                name: "enable_Contact",
                label: "Enable Contact"
            },
            {
                name: "enable_Call_Register",
                label: "Enable Call Register"
            },
        ],
    },
    {
        title: "Projects",
        checkBox: [
            {
                name: "enable_ticket",
                label: "Enable Tickets"
            },
            {
                name: "enable_sprint",
                label: "Enable Sprints"
            },
            {
                name: "enable_ticket_search",
                label: "Enable Tickets Search"
            },
            {
                name: "enable_test_case",
                label: "Enable Test Cases"
            },
            {
                name: "enable_ticket_setting",
                label: "Enable Ticket Setting"
            },
        ],
    },
    {
        title: "Jobs",
        checkBox: [
            {
                name: "enable_job",
                label: "Enable Jobs"
            },
            {
                name: "enable_candidate",
                label: "Enable Candidates"
            },
        ],
    },
    {
        title: "Marketing",
        checkBox: [
            {
                name: "enable_lead",
                label: "Enable Leads"
            },
        ],
    },
    {
        title: "Report",
        checkBox: [
            {
                name: "enable_report",
                label: "Enable Reports"
            },
        ],
    },
    {
        title: "Admin",
        checkBox: [
            {
                name: "enable_setting",
                label: "Enable Settings"
            },
            {
                name: "enable_recurring_task",
                label: "Enable Recurring Tasks"
            },
        ],
    },
    {
        title: "Sync",
        checkBox: [
            {
                name: "enable_sync",
                label: "Enable Sync"
            },
        ],
    },
]

