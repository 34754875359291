import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { endpoints } from "../../../api/endPoints";
import UserCard from "../../../components/UserCard";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import ObjectName from "../../../helpers/ObjectName";
import { Group } from "../../../helpers/Status";

const PendingActivites = (props) => {
    let { history } = props;


    return (
        <>
            <ReduxTable
                id="pendingActivities"
                newTableHeading
                disableHeader
                apiURL={`${endpoints().activityAPI}/getPendingActivity`}
                paramsToUrl={true}
                history={history}
                message="You can start by clicking on Add New"
                icon={<FontAwesomeIcon icon={faTasks} />}
            >
                <ReduxColumn
                    field="owner"
                    sortBy="owner"
                    width="310px"
                    minWidth="310px"
                    maxWidth="310px"
                    renderField={(row) => (
                        <>
                            <UserCard
                                firstName={row.first_name}
                                url={row.media_url}
                                lastName={row.last_name}
                            />
                        </>
                    )}
                >
                    User
                </ReduxColumn>
                <ReduxColumn
                    field="activity_count"
                    sortBy="count"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    className="text-center"
                    renderField={(row) => (
                        <Link
                            to={`/activity?objectName=${ObjectName.ACTIVITY}&group=${Group.PENDING}&user=${row?.owner_id}`}
                            className="opacity-75 no-underline text-blue-600 hover:underline"
                        >
                            {row?.activity_count && row?.activity_count > 0 ? row?.activity_count :""}
                        </Link>
                    )}
                >
                    Count
                </ReduxColumn>
            </ReduxTable>
        </>
    );
};
export default PendingActivites;
