import React, { useState } from "react";

// Components
import Modal from "../../../components/Modal";

import DateTime from "../../../lib/DateTime";
import { endpoints } from "../../../api/endPoints";
import Button from "../../../components/Button";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCubes } from "@fortawesome/free-solid-svg-icons";
import Url from "../../../lib/Url";
import { Group, PAGE } from "../../../helpers/Status";
import ObjectName from "../../../helpers/ObjectName";
import UserCard from "../../../components/UserCard";
import StatusText from "../../../components/StatusText";
import Toast from "../../../components/Toast";
import TransferService from "../../../services/TransferService";
import ArrayList from "../../../lib/ArrayList";
import { useDispatch } from "react-redux";

const TransferSelectModal = ({
  history,
  params,
  toggle,
  modalOpen,
  toggleModalClose,
  initialValues,
  excludedId,
}) => {

  const [page, setPage] = useState(Url.GetParam("page"));
  const [transferIds, setTransferIds] = useState([]);
    const dispatch = useDispatch();
  
  const onBulkSelect = (ids) => {
    setTransferIds( ids );
  };

  const handleSubmit = async () => {
    if(ArrayList.isEmpty(transferIds)){
        return Toast.error("Select Id(s)")
    }
    let body={
        selectedId :transferIds,
        primaryTransferId:excludedId
    }
    dispatch(
    await TransferService.merge(body,excludedId, (res) => {
if(res){
    toggleModalClose()
    setTransferIds([])
}
})
)
  };
  

  const modalBody = (
    <ReduxTable
      id="transfer"
      showHeader
      searchPlaceholder="Search"
      newTableHeading
      bulkSelect
      onBulkSelect={onBulkSelect}
      icon={<FontAwesomeIcon icon={faCubes} />}
      message="You can start by clicking on Add New"
      apiURL={`${endpoints().transferApi}/search`}
      params={{
        page: page ? page : PAGE,
        pageSize: Url.GetParam("pageSize"),
        objectName: ObjectName.TRANSFER,
        group: Group.DRAFT,
        excludedId: excludedId,
        ...params,
      }}
      objectName={ObjectName.TRANSFER}
      paramsToUrl={true}
      history={history}
      setPage={setPage}
      onRowClick={(row) => {
        setSelectedId(row.id);
      }}
    >
      <ReduxColumn
        field="transfer_number"
        sortBy="transfer_number"
        isClickable="true"
        width="110px"
        minWidth="110px"
        maxWidth="110px"
        type="link"
        className="text-center"
      >
        Transfer#
      </ReduxColumn>
      <ReduxColumn
        field="date"
        sortBy="date"
        className="text-center"
        width="110px"
        minWidth="110px"
        maxWidth="110px"
        renderField={(row) => <span>{DateTime.getDate(row.date)}</span>}
      >
        Date
      </ReduxColumn>
      <ReduxColumn
        field="fromLocationName"
        sortBy="from_store_id"
        width="110px"
        minWidth="110px"
        maxWidth="110px"
      >
        From Location
      </ReduxColumn>
      <ReduxColumn
        field="toLocationName"
        sortBy="to_store_id"
        width="110px"
        minWidth="110px"
        maxWidth="110px"
      >
        To Location
      </ReduxColumn>
      <ReduxColumn
        field="product_count"
        sortBy="product_count"
        className="text-center"
        width="110px"
        minWidth="110px"
        maxWidth="110px"
      >
        Product Count
      </ReduxColumn>
      <ReduxColumn
        className="text-center"
        field="type"
        sortBy="type"
        width="110px"
        minWidth="110px"
        maxWidth="110px"
      >
        Type
      </ReduxColumn>
      <ReduxColumn
        className="text-break"
        field="owner_id"
        sortBy="owner_id"
        width="350px"
        maxWidth="210px"
        minWidth="210px"
        renderField={(row) => (
          <>
            <UserCard
              customSize={parseInt(50, 10)}
              firstName={row.owner}
              url={row.image}
              lastName={row.lastName}
            />
          </>
        )}
      >
        Owner
      </ReduxColumn>
      <ReduxColumn
        field="status"
        sortBy="status"
        width="110px"
        maxWidth="110px"
        minWidth="110px"
        renderField={(row) => (
          <StatusText backgroundColor={row?.statusColor} status={row?.status} />
        )}
      >
        Status
      </ReduxColumn>
    </ReduxTable>
  );

  const productFooter = (
    <Button
      label={"Merge"}
      onClick={(values) => {
        handleSubmit(values);
      }}
      showButtonLabelName
    />
  );

  return (
    <>
      <Modal
        className="expert-modal maxWidth-100"
        isOpen={modalOpen}
        toggle={toggle}
        toggleModalClose={toggleModalClose}
        modalTitle="Merge Transfer"
        initialValues={initialValues ? initialValues : {}}
        modalBody={modalBody}
        modalFooter={productFooter}
        FooterClassName="mt-5 pt-2"
        hideDefaultButtons
      />
    </>
  );
};

export default TransferSelectModal;
