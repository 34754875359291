import Permission from "../lib/Permission";

export function hasPermission(permission) {

    let permissionList = localStorage.getItem(Permission.USER_ROLE);

    if (permissionList) {
        const permissionsArray = permissionList.split(',');
        const isExist = permissionsArray.includes(permission);
        return isExist;
    }
    return false;
}



