import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

//components
import DeleteModal from "../../components/DeleteModal";
import Action from "../../components/Action";
import BreadCrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";

// API
import { endpoints } from "../../api/endPoints";

// Config
import { apiClient } from "../../apiClient";

// Helpers
import Customer from "../../helpers/Customer";

// Lib
import { isBadRequest } from "../../lib/Http";

// Services
import HolidayService from "../../services/HolidayService";
import { hasPermission } from "../../services/UserRolePermissionService";
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import ActivityList from "../../components/ActivityList";
import ObjectName from "../../helpers/ObjectName";
import Url from "../../lib/Url";
import HolidayForm from "./HolidayForm";
import Permission from "../../helpers/Permission";

const Tab = {
  SUMMARY: "Summary",
  HISTORY: "History",
};

const HolidayDetail = (props) => {
  const { match, history } = props;
  const [holidayDetails, setHolidayDetails] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editable, setEditable] = useState(false);
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : Tab.SUMMARY
  );
  const holidayId = props.match.params.id;

  let dispatch = useDispatch();

  let hasEditPermission = hasPermission(Permission.HOLIDAY_EDIT);

  let hasDeletePermission = hasPermission(Permission.HOLIDAY_DELETE);

  useEffect(() => {
    getHolidayDetails(match.params.id);
  }, [props]);

  //get the holiday details
  const getHolidayDetails = () => {
    let id = props.match.params.id;

    try {
      return apiClient
        .get(`${endpoints().holidayAPI}/${id}`)
        .then((response) => {
          const data = response?.data?.data;
          setHolidayDetails(data);
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (error) {
      console.log(err);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  const handleHolidayDelete = async (value) => {
    dispatch(
      await HolidayService.delete(
        { data: { id: props?.match?.params?.id } },
        (res) => {
          props.history.push("/setting/Holiday");
        }
      )
    );
  };

  const breadcrumbList = [
    { label: "Settings", link: "/setting/Holiday" },
    {
      label: "Holiday",
      link: "/setting/Holiday",
    },
    {
      label: Customer.GetDisplayName(holidayDetails?.name),
      link: "",
    },
  ];

  const actionsMenuList = [];

  if (hasEditPermission && !editable) {
    actionsMenuList.unshift({
      value: "Edit",
      label: "Edit",
    });
  }

  if (hasDeletePermission) {
    actionsMenuList.push({
      value: "Delete",
      label: "Delete",
    });
  }

  const handleActionChange = (e) => {
    if (e == "Delete") {
      setDeleteModal(true);
    }

    if (e == "Edit") {
      setEditable(true);
    }
  };

  return (
    <>
      <DeleteModal
        id={holidayId}
        label={holidayDetails?.name}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Holiday"
        deleteFunction={handleHolidayDelete}
      />
      <div>
        <BreadCrumb list={breadcrumbList} />
        <div className="d-flex justify-content-between mb-3">
          <PageTitle label={holidayDetails?.name} />

          {actionsMenuList && actionsMenuList.length > 0 && (
            <div className="ps-2">
              <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            </div>
          )}
        </div>

        <Nav tabs className="admin-tabs">
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.SUMMARY,
              })}
              onClick={() => handleTabChange(Tab.SUMMARY)}
            >
              {Tab.SUMMARY}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => handleTabChange(Tab.HISTORY)}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        </Nav>

        {activeTab === Tab.SUMMARY && (
          <div className="card card-body">
            <HolidayForm
              detail={holidayDetails}
              form="Edit"
              history={history}
              setEditable={setEditable}
              editable={editable}
            />
          </div>
        )}

        {activeTab === Tab.HISTORY && (
          <ActivityList
            id={holidayId}
            objectId={holidayId}
            object_name={ObjectName.HOLIDAY}
            history={history}
          />
        )}
      </div>
    </>
  );
};

export default HolidayDetail;
