import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Form from '../../../components/Form'
import SingleCheckbox from '../../../components/SingleCheckbox'
import SubTitle from '../../../components/SubTitle'
import { Feature, FeatureCheckBox } from '../../../helpers/AppSetting'
import ArrayList from '../../../lib/ArrayList'
import AppSettingService from '../../../services/AppSettingService'

const FeatureTab = (props) => {
    let { app_id }=props;
    const [settingList, setSettingList]=useState([]);
    let dispatch = useDispatch()

    useEffect(() => {
        getFeatures()
    }, [])
    
    let getFeatures=async ()=>{
        await AppSettingService.get({params:{app_id: app_id }},(res)=>{
            setSettingList(res?.data)
        })
    }

    let getInitialValues = (settingList) => {
        let featureArray = Feature
        let initialObj = {}
        for (let i = 0; i < featureArray.length; i++) {
            const featureKey = featureArray[i];

            let findData = settingList.find((data) => data?.name === featureKey && data?.value === "true") || false;
            if (findData) {
                initialObj[featureKey] = true
            } else {
                initialObj[featureKey] = false
            }
        }
        return initialObj;

    }


    let initialValues = getInitialValues(settingList,)

    let handleChange = async (values) => {
        const data = new FormData();
        data.append(values.target.id, values.target.checked);
        data.append("app_id", app_id);
        dispatch(await AppSettingService.save(data,(res)=>{

        }))
    }

    let checkBoxFields = FeatureCheckBox;
    return (
        <div className="card card-body mb-5 mb-sm-3 pb-sm-2">
        <Form enableReinitialize={true} initialValues={initialValues}>
            {ArrayList.isArray(checkBoxFields) &&  checkBoxFields.map((data, index) => (
                <React.Fragment key={index}>
                    <SubTitle label={data?.title} />
                    {data?.checkBox.map((value, idx) => (
                        <div className="form-wrapper mb-3" key={idx}>
                            <div className="field-wrapper">
                                <SingleCheckbox
                                    name={value?.name}
                                    label={value?.label}
                                    className="accepted-terms mb-0 pb-0 me-3"
                                    handleFeatureChange={handleChange}
                                />
                            </div>
                        </div>
                    ))}
                </React.Fragment>
            ))}
        </Form>
    </div>
    
    )
}

export default FeatureTab
