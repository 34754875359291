import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Text from "../../components/Text";
import DateSelector from "../../components/Date";
import RewardSelect from "../../components/DurationSelect";
import Form from "../../components/Form";
import SaveButton from "../../components/SaveButton";
import CancelButton from "../../components/CancelButton";
import AccountTypeSelect from "../../components/AccountTypeSelect";
import Currency from "../../components/Currency";

// Services
import PromotionService from "../../services/PromotionService";

// Actions
import { fetchList } from "../../actions/table";

// Lib
import Url from "../../lib/Url";

// API
import { endpoints } from "../../api/endPoints";

const PromotionForm = ({ rowValue, closeToggle, getdetail, form, history, editable, setEditable }) => {
  const [typeList, setTypeList] = useState([]);
  const [rewardList, setRewardList] = useState([]);
  const dispatch = useDispatch();

  const initialValues = {
    name: rowValue?.name || "",
    account_type:
      (typeList && typeList.find((value) => value.value == rowValue?.account_type)) ||
      "",
    start_date: rowValue?.start_date || "",
    end_date: rowValue?.end_date || "",
    reward_points:
      (rewardList &&
        rewardList.find((value) => value.value == rowValue?.reward_points)) ||
      "",
    order_minimum_amount: rowValue?.order_minimum_amount || "",
  };

  const handleSubmit = (id, values) => {
    let data = new FormData();
    data.append("name", values?.name);
    data.append("account_type", values && values?.account_type ? values?.account_type?.value : null);
    data.append("reward_points", values?.reward_points ? values?.reward_points?.value : null);
    data.append("start_date", values?.start_date ? values?.start_date : null);
    data.append("end_date", values?.end_date ? values?.end_date : null);
    data.append("order_minimum_amount", values?.order_minimum_amount ? values?.order_minimum_amount : null);
    if (rowValue && rowValue?.id) {
      data.append("id", rowValue?.id);
      dispatch(
        PromotionService.update(rowValue?.id, data, (res) => {
          if (res) {
            closeToggle && closeToggle();
          }
          getdetail && getdetail()
          dispatch(
            fetchList(
              "promotion",
              `${endpoints().promotionAPI}`,
              Url.GetParam("page"),
              Url.GetParam("pageSize"),

              Url.GetAllParams()
            )
          );
          setEditable(true);
        })
      );
    } else {
      dispatch(
        PromotionService.create(data, (res) => {
          if (res) {
            closeToggle && closeToggle();
          }
          dispatch(
            fetchList(
              "promotion",
              `${endpoints().promotionAPI}`,
              Url.GetParam("page"),
              Url.GetParam("pageSize"),

              Url.GetAllParams()
            )
          );
        })
      );
    }
  };

  //handleCancel function
  const handleCancel = () => {
    closeToggle && closeToggle();
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          handleSubmit(rowValue?.id, values);
        }}
        className="d-flex flex-column h-100"
      >
        <div
          className={
            form === "Add" || form === "Edit"
              ? `d-flex flex-column`
              : `d-flex flex-column`
          }
        >
          <div className="card-body">
            <Text
              name="name"
              label="Name"
              placeholder="Enter Name..."
              error=""
              required={true}
              disabled={editable}
            />
            <AccountTypeSelect
              label="Account Type"
              name="account_type"
              accountTypeOption={setTypeList}
              required
              isDisabled={editable}
            />
            <RewardSelect
              label="Reward Points"
              name="reward_points"
              list={setRewardList}
              isDisabled={editable}
            />
            <Currency
              label="Order Minimum Amount"
              name="order_minimum_amount"
              disabled={editable}
            />
            <DateSelector
              label="Start Date"
              name="start_date"
              dateOnly
              isClearable={!editable ? true : false}
              disabled={editable}
            />
            <DateSelector
              label="End Date"
              name="end_date"
              dateOnly
              isClearable={!editable ? true : false}
              disabled={editable}
            />
          </div>
        </div>
        <div
          className={
            form === "Add" || form === "Edit"
              ? `position-absolute bottom-0 start-0 end-0 bg-white z-3`
              : ""
          }
          style={
            form === "Add" || form === "Edit"
              ? {
                borderTop: "1px solid #dee2e6",
                padding: "10px 10px",
              }
              : {}
          }
        >
          <div
            className={
              form === "Add" || form === "Edit"
                ? "d-flex justify-content-center"
                : "float-start ms-3 mb-0"
            }
          >
            {((form === "edit" && !editable) || (form === "Edit") || (form === "Add")) && <div>
              <SaveButton type="submit" label="Save" />
              <CancelButton
                onClick={() => {
                  if (form === "Add" || form === "Edit") {
                    handleCancel();
                  } else {
                    history.push("/promotion");
                  }
                }}
              />
            </div>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default PromotionForm;
