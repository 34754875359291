import React, { useEffect, useState } from "react";

import Filter from "../../components/Filter";
import { orderPaymentTypeOptions } from "../../helpers/Order";
import { OrderProduct } from "../../helpers/orderProduct";
import DateTime from "../../lib/DateTime";
import Url from "../../lib/Url";
import OrderAnalysisReportOwnerWiseService from "../../services/OrderAnalysisReportOwnerWiseService";
import ShiftService from "../../services/ShiftService";
import { DateOptions } from "../../helpers/Date";

const OrderAnalysisReportOwnerWiseFilter = ({
  handleFilterChange,
  initialParam,
  getFilterParams,
  history,
}) => {

  const [shiftList, setShiftList] = useState("");
  const [userList, setUserList] = useState([]);
  const user = Url.GetParam("user") || "";
  const date = Url.GetParam("date") || "";
  const typeValue = initialParam.type || "";

  const paymentType = Url.GetParam("paymentType") || "";

  const [param, setParam] = useState({
    user: "",
    type: "",
    shift: "",
    date: "",
    paymentType: "",
  });
  const [spinValue, setSpin] = useState(false);

  // TypeOptions
  const typeOptions = [
    {
      label: "User Wise",
      value: "User Wise",
    },
    {
      label: "Date Wise",
      value: "Date Wise",
    },
    {
      label: "Month Wise",
      value: "Month Wise",
    },
  ];
  useEffect(() => {
    setParam({
      user: initialParam && initialParam.user ? initialParam.user : "",
      type:
        initialParam && initialParam.type
          ? initialParam.type
          : OrderProduct.REPORT_TYPE_USER_WISE,
      shift: initialParam && initialParam.shift ? initialParam.shift : "",
      date: initialParam && initialParam.date ? initialParam.date : "",
      paymentType:
        initialParam && initialParam.paymentType
          ? initialParam.paymentType
          : "",
    });
  }, [initialParam]);

  useEffect(() => {
    getShift();
  }, []);

  const refreshButtonOnClick = async () => {
    setSpin(true);
    let data = await OrderAnalysisReportOwnerWiseService.search(param);
    handleFilterChange(data, param);
    setSpin(false);
  };
  // Initial value
  const initialValues = {
    user: userList.find((data) => data.id == initialParam.user),
    type: typeOptions.find((data) => data.value == initialParam.type),
    shift:
      shiftList && shiftList.length > 0
        ? shiftList.find((data) => data.id == Url.GetParam("shift"))
        : "",
    date:DateOptions.find((data)=> data?.value == initialParam?.date),
    paymentType:
      orderPaymentTypeOptions.find(
        (data) => data.value == initialParam.paymentType
      ) || "",
    pagination: true,
  };

  const UpdateUrl = (params) => {
    const currentPage = window.location.pathname;
    let queryString = "";
    const queryStringArray = Object.entries(params);

    if (queryStringArray.length > 0) {
      queryString = "?";
      queryStringArray.forEach(async (queryParam) => {
        if (queryParam[1] !== "")
          queryString = `${queryString}&${queryParam[0]}=${queryParam[1]}`;
      });
    }
    if (history) {
      history.push(`${currentPage}${queryString}`);
    }
  };

  const handleParamsChange = async (updatedParams) => {
    const params = {
      user: user ? user : "",
      date: date ? date : "",
      type: typeValue ? typeValue : "",
      paymentType: paymentType ? paymentType : "",
      shift: Url.GetParam("shift"),

      ...updatedParams,
    };

    UpdateUrl(params);
    setParam(params);
    getFilterParams(params);
    let data = await OrderAnalysisReportOwnerWiseService.search(params);
    handleFilterChange(data, params);
  };
  const getShift = async () => {
    const list = await ShiftService.getShiftList();
    setShiftList(list);
  };
  const handleFilter =(values)=>{
    handleParamsChange({
      date: values?.date ? values?.date?.value : "",
      type : values?.type ? values?.type?.value :"",
      shift : values?.shift ? values?.shift?.value :"",
      user : values?.user ? values?.user?.id :"",
      paymentType : values?.paymentType ? values?.paymentType?.value :"",
    });
  };

  const handleDeleteFilter=(values)=>{
    handleParamsChange({
      ...param,
      ...values
    })
  }

  return (
    <Filter
      showHeader
      newTableHeading
      showSearch
      sortByDropdown
      initialValues={initialValues}
      showTypeFilter
      typeName="type"
      customTypeOption={typeOptions}
      showUserFilter
      userList={setUserList}
      showShiftFilter
      shiftName="shift"
      showPaymentTypeFilter
      paymentType={orderPaymentTypeOptions}
      showCustomDateFilter
      refreshButtonOnClick={refreshButtonOnClick}
      refreshValue={spinValue}
      handleFilter={handleFilter}
      handleDeleteFilter={handleDeleteFilter}
    />
  );
};

export default OrderAnalysisReportOwnerWiseFilter;
