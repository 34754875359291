import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import BreadCrumb from "../../../components/Breadcrumb";
import PageTitle from "../../../components/PageTitle";
import { Nav, TabContent, TabPane } from "reactstrap";
import Tab from "../../../components/Tab";
import Text from "../../../components/Text";
import Select from "../../../components/Select";
import Form from "../../../components/Form";
import SaveButton from "../../../components/SaveButton";
import DeleteModal from "../../../components/DeleteModal";
import Action from "../../../components/Action";

// Lib
import Url from "../../../lib/Url";

// Service
import ProjectComponentService from "../../../services/ProjectComponentService";

// Helpers
import { Projects } from "../../../helpers/Project";

// API
import { endpoints } from "../../../api/endPoints";

// Actions
import { fetchList } from "../../../actions/table";

const Tabs = {
  GENERAL: "General",
};

const ProjectComponentDetail = (props) => {
  const [activeTab, setActiveTab] = useState(Tabs.GENERAL);
  const [projectComponentData, setProjectComponentData] = useState();
  const [componentName, setComponentName] = useState();
  const [statusValue, setStatusValue] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    getComponentDetail();
  }, []);

  const breadcrumbList = [
    { label: "Home", link: "/admin/dashboard" },
    {
      label: "Project",
      link: `/project`,
    },
    {
      label: "Project Details",
      link: `/project/${Url.GetParam("projectId")}?projectId=${Url.GetParam(
        "projectId"
      )}`,
    },
    {
      label: "Project Component Details",
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
   
  ];

  const handleActionChange = (e) => {
    if (e === "Delete") {
      setDeleteModal(true);
    }
  };

  const getComponentDetail = async () => {
    const id = props?.match?.params?.componentId;
    const response = await ProjectComponentService.getProjectComponent(id);
    setProjectComponentData(response && response?.data);
  };

  const updateData = async (values) => {
    const componentId = props.match?.params?.componentId;

    values.name = values?.name;
    values.status = values?.status?.value;
    values.projectId = props?.match?.params?.id
      ? props?.match?.params?.id
      : Url.GetParam("projectId")
      ? Url.GetParam("projectId")
      : "";

    try {
      await ProjectComponentService.updateProjectComponent(
        componentId,
        values,
        async (res) => {
          if (res) {
            await dispatch(
              fetchList(
                "projectComponents",
                `${endpoints().projectComponentAPI}/search`,
                Url.GetParam("page") ? Url.GetParam("page") : 1,
                Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                {
                  sort: Url.GetParam("sort") || "",
                  sortDir: Url.GetParam("sortDir") || "",
                }
              )
            );
            addComponentToggle();
          }
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleNameChange = (e) => {
    let value = e.target.value;
    setComponentName(value);
  };

  const handleStatusChange = (values) => {
    setStatusValue(values?.values?.status);
  };

  const projectComponentDelete = async (id) => {
    dispatch(
      await ProjectComponentService.deleteProjectTicketType(projectComponentData?.id, {
        sort: "id",
        sortDir: "DESC",
        search: Url.GetParam("search") || "",
        pageSize: Url.GetParam("pageSize") || "",
      })
    );
    props.history.push(
        `/project/${Url.GetParam("projectId")}?projectId=${Url.GetParam("projectId")}`
      );
  };

  const statusOptions = [
    {
      label: Projects.STATUS_ACTIVE,
      value: Projects.STATUS_ACTIVE_VALUE,
    },
    {
      label: Projects.STATUS_INACTIVE,
      value: Projects.STATUS_INACTIVE_VALUE,
    },
  ];

  const initialValues = {
    name: componentName ? componentName : projectComponentData?.name,
    status: statusValue
      ? {
          value: statusValue?.value,
          label: statusValue?.label,
        }
      : statusOptions &&
        statusOptions.find(
          (status) => status.value == projectComponentData?.status
        ),
  };

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between">
        <PageTitle label="Project Component details " />
        <div>
          <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
          />
        </div>
      </div>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Project Component"
        label={projectComponentData?.name}
        deleteFunction={() => {
            projectComponentDelete();
        }}
      />
      <Nav tabs className="admin-tabs">
        <Tab
          name={Tabs.GENERAL}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
      </Nav>
      <TabContent activeTab={activeTab}>
        {/* General Tab */}
        <TabPane tabId={Tabs.GENERAL}>
          <div className="card">
            <div className="col-12 col-sm-8 col-md-8 col-lg-6 p-3">
              <div className="field-wrapper mb-0 form-wrapper">
                <Form
                  initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    updateData(values);
                  }}
                >
                  <Text
                    name="name"
                    label=" Name"
                    placeholder=" Name"
                    onChange={handleNameChange}
                    error=""
                    required={true}
                  />
                  <Select
                    label="Status"
                    name="status"
                    placeholder="Select Status"
                    options={statusOptions}
                    onInputChange={handleStatusChange}
                    required={true}
                  />
                  <br />
                  <div className="mt-2">
                    <SaveButton />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </>
  );
};

export default ProjectComponentDetail;
