import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";

// Components
import AddButton from "../../components/AddButton";
import Button from "../../components/Button";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import Select from "../../components/Select";
import DeleteModal from "../../components/DeleteModal";
import MoreDropdown from "../../components/authentication/moreDropdown";
import PageTitle from "../../components/PageTitle";

// API
import { endpoints } from "../../api/endPoints";

// Helpers
import Permission from "../../helpers/Permission";

// Lib
import Url from "../../lib/Url";

// Services
import { hasPermission } from "../../services/UserRolePermissionService";
import Text from "../../components/Text";
import Drawer from "../../components/Drawer";
import AccountMappingService from "../../services/AccountMappingService";
import { CsvSheetOptions, gstB2bColumns } from "../../helpers/CsvSheet";

export const AccountMapping = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [field, setField] = useState("");
  const [csvColumnName, setCsvColumName] = useState("");
  const [csvSheetName, setCsvSheetName] = useState("");

  const [isOpenDeleteModal, setIsDeleteModal] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [isOpenBulkUpdateModal, setOpenBulkUpdateModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState(false);
  const [arrayList, setArrayList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);

  let showAdd = hasPermission(Permission.ACCOUNT_MAPPING_ADD);
  let showDelete = hasPermission(Permission.ACCOUNT_MAPPING_DELETE);

  // Dispatch
  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  // Handle Add Modal
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  // Handle Add Modal
  const closeToggle = () => {
    setIsOpen(false);
    setField("");
    setCsvSheetName("");
    setCsvColumName("");
    setSelectedData("");
  };

  // Handle  Change
  const handleField = async (e) => {
    setField(e?.values?.field?.value);
  };

  const handlecsvColumnName = async (e) => {
    setCsvColumName(e?.target?.value);
  };

  const handlecsvSheetName = async (e) => {
    setCsvSheetName(e?.values?.csv_sheet_name?.value);
  };

  //Handle Add
  const handleAdd = (values) => {
    const data = new FormData();
    if (values && values.csv_column_name !== undefined) {
      data.append(
        "csv_column_name",
        values && values.csv_column_name ? values.csv_column_name : ""
      );
    }
    if (values && values.csv_sheet_name !== undefined) {
      data.append(
        "csv_sheet_name",
        values && values.csv_sheet_name && values.csv_sheet_name?.value
          ? values.csv_sheet_name?.value
          : ""
      );
    }
    if (values && values.field !== undefined) {
      data.append(
        "field",
        values && values.field && values.field?.value ? values.field?.value : ""
      );
    }
    if (selectedData && selectedData?.id) {
      dispatch(
        AccountMappingService.update(
          selectedData?.id,
          data,
          {
            sort: Url.GetParam("sort") ? Url.GetParam("sort") : "id",
            sortDir: Url.GetParam("sortDir") ? Url.GetParam("sortDir") : "DESC",
            page: Url.GetParam("page"),
            pageSize: Url.GetParam("pageSize"),
          },
          (res) => {
            if (res) {
              closeToggle();
            }
          }
        )
      );
    } else {
      dispatch(
        AccountMappingService.create(data, {
          sort: Url.GetParam("sort") ? Url.GetParam("sort") : "id",
          sortDir: Url.GetParam("sortDir") ? Url.GetParam("sortDir") : "DESC",
          page: Url.GetParam("page"),
          pageSize: Url.GetParam("pageSize"),
        })
      );
      closeToggle();
    }
  };

  const handleDelete = () => {
    if (selectedIds && selectedIds.length > 0) {
      dispatch(
        AccountMappingService.bulkDelete(selectedIds, setIsDeleteModal, {})
      );
    } else {
      dispatch(
        AccountMappingService.delete(selectedData.id, setIsDeleteModal, {
          sort: Url.GetParam("sort") ? Url.GetParam("sort") : "id",
          sortDir: Url.GetParam("sortDir") ? Url.GetParam("sortDir") : "DESC",
          page: Url.GetParam("page"),
          pageSize: Url.GetParam("pageSize"),
        })
      );
    }
    setIsDeleteModal(true);
    setSelectedIds([]);
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
    setIsDeleteModal(false);
    closeToggle();
  };

  const toggleBulkUpdateModal = () => {
    setOpenBulkUpdateModal(!isOpenBulkUpdateModal);
  };

  const onBulkSelect = (value) => {
    setSelectedIds(value);
  };

  // initial values while adding an
  const initialValues = {
    csv_column_name: csvColumnName || selectedData?.csv_column_name || "",
    field:
      gstB2bColumns.find(
        (value) => value.value == (field || selectedData?.field)
      ) || "",
    csv_sheet_name:
      CsvSheetOptions.find(
        (value) => value.value == (csvSheetName || selectedData?.csv_sheet_name)
      ) || "",
  };

  const actionsMenuList = [
    // {
    //   value: "Bulk Update",
    //   label: "Bulk Update",
    // },
    {
      value: "Delete",
      label: "Delete",
    },
  ];

  let params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
  };

  const handleActionChange = (e) => {
    if (e === "Delete" && selectedIds && selectedIds.length > 0) {
      setIsDeleteModal(true);
    }
    if (e == "Bulk Update") {
      if (selectedIds && selectedIds.length > 0) {
        toggleBulkUpdateModal();
      }
    }
  };

  const formContent = (
    <div className="mt-2 mb-3">
      <Select
        name="field"
        label="Field"
        options={gstB2bColumns}
        required={true}
        onInputChange={(e) => {
          handleField(e);
        }}
      />

      <Select
        name="csv_sheet_name"
        label="Csv Sheet Name"
        options={CsvSheetOptions}
        required={true}
        onInputChange={(e) => {
          handlecsvSheetName(e);
        }}
      />
      <Text
        name="csv_column_name"
        label="Csv Column Name"
        required={true}
        onChange={(e) => {
          handlecsvColumnName(e);
        }}
      />
    </div>
  );

  const footerContent = (
    <div className="overflow-hidden">
      <div className="col-sm-10 text-center">
        <Button
          type="submit"
          label={selectedData?.id ? "Save" : "Add"}
          showButtonLabelName
        />
      </div>
    </div>
  );
  let deleteLabel =
    gstB2bColumns &&
    gstB2bColumns.find((value) => value.value == selectedData?.field);

  return (
    <>
      <Drawer
        modelTitle={
          selectedData?.id ? "Edit Account Mapping" : "Add Account Mapping"
        }
        DrawerBody={formContent}
        DrawerFooter={footerContent}
        onSubmit={(values) => {
          handleAdd(values);
        }}
        initialValues={initialValues}
        handleOpenModal={toggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
        enableReinitialize
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={() => {
          setIsDeleteModal(false);
        }}
        title="Delete Account Mapping"
        id={selectedData && selectedData.id}
        label={deleteLabel?.label}
        deleteFunction={handleDelete}
      />
      <div>
        <div className="d-flex justify-content-between">
          <PageTitle label="Account Mapping" />

          <div className="d-flex justify-content-between">
            {/* <BulkUpdateModal
              isOpen={isOpenBulkUpdateModal}
              toggle={toggleBulkUpdateModal}
              selectedIds={selectedIds}
              setSelectedCheckBox={setSelectedCheckBox}
              setSelectedIds={setSelectedIds}
              params={params}
            /> */}
            {showAdd && (
              <AddButton
                className="ms-2 me-1 mt-1"
                label="Add"
                onClick={(e) => {
                  toggle();
                }}
              />
            )}
            {/* {showDelete && (
              <div className="ms-2">
                <Action
                  dropdownLinks={actionsMenuList}
                  handleChange={handleActionChange}
                />
              </div>
            )} */}
          </div>
        </div>

        <div className="mt-4">
          <ReduxTable
            id="accountMapping"
            searchPlaceholder="Search"
            apiURL={`${endpoints().accountMappingAPI}`}
            newTableHeading
            sortByOptions={sortByOption}
            paramsToUrl={true}
            history={props.history}
            arrayList={arrayList}
          >
            <ReduxColumn
              width="170px"
              minWidth="170px"
              maxWidth="170px"
              field="field"
              className="text-center"
              sortBy="field"
              renderField={(row) => (
                <span>
                  {gstB2bColumns &&
                    gstB2bColumns.find((value) => value.value == row?.field)
                      ?.label}
                </span>
              )}
            >
              Field
            </ReduxColumn>
            <ReduxColumn
              width="170px"
              minWidth="170px"
              maxWidth="170px"
              field="csv_sheet_name"
              className="text-center"
              sortBy="csv_sheet_name"
            >
              Csv Sheet Name
            </ReduxColumn>
            <ReduxColumn
              width="170px"
              minWidth="170px"
              maxWidth="170px"
              field="csv_column_name"
              className="text-center"
              sortBy="csv_column_name"
            >
              Csv Column Name
            </ReduxColumn>

            <ReduxColumn
              field="Action"
              width="90px"
              disableOnClick
              renderField={(row) => (
                <div className="text-center action-group-dropdown">
                  <MoreDropdown>
                    {showDelete && (
                      <>
                        <DropdownItem
                          onClick={() => {
                            setSelectedData(row);
                            toggle(true);
                          }}
                        >
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          className="text-danger"
                          onClick={() => {
                            setSelectedData(row);
                            setIsDeleteModal(true);
                          }}
                        >
                          Delete
                        </DropdownItem>
                      </>
                    )}
                  </MoreDropdown>
                </div>
              )}
            >
              Action
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    </>
  );
};
