import React, { useEffect, useState } from "react";
import Filter from "../../../components/Filter";
import Url from "../../../lib/Url";

const LocationFilter = ({
  history,
  getLocationList,
  setValues,
  values,
  page,
  pageSize,
}) => {
  const [searchItem, setSearch] = useState(Url.GetParam("search"));

  const customStatusOption = [
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "InActive",
      label: "InActive",
    },
    {
      value: "",
      label: "All",
    },
  ];

  const [spinValue, setSpin] = useState(false);

  useEffect(() => {
    UpdateUrl(values);
  }, []);

  const refreshButtonOnClick = async () => {
    setSpin(true);
    getLocationList && getLocationList({ ...values, page });
    UpdateUrl({ ...values, page, pageSize });
    setSpin(false);
  };

  const initialValues = {
    status: customStatusOption.find(
      (data) => data?.value == Url.GetParam("status")
    ),
  };

  const UpdateUrl = (params) => {
    const currentPage = window.location.pathname;
    let queryString = "";
    const queryStringArray = Object.entries(params);

    if (queryStringArray.length > 0) {
      queryString = "?";
      queryStringArray.forEach(async (queryParam) => {
        if (queryParam[1] !== "")
          queryString = `${queryString}&${queryParam[0]}=${queryParam[1]}`;
      });
    }
    if (history) {
      history.push(`${currentPage}${queryString}`);
    }
  };

  const handleDeleteFilter = () => {
    UpdateUrl({});
    getLocationList &&
      getLocationList({
        search: Url.GetParam("search"),
        status: "",
        page,
        pageSize,
      });
    setValues({
      search: Url.GetParam("search"),
      status: "",
      page,
      pageSize,
    });
  };

  const handleFilter = (values) => {
    let params = {
      status: values?.status ? values?.status?.value : "",
      search: Url.GetParam("search"),
      page,
      pageSize,
    };
    getLocationList && getLocationList(params);
    setValues && setValues(params);
    UpdateUrl(params);
  };

  const handleSearchTermChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    let params = {
      search: searchValue,
      page,
      pageSize,
    };
    getLocationList && getLocationList(params);
    setValues && setValues(params);
    UpdateUrl(params);
  };

  const onSearchKeyUp = (event) => {
    if (event.charCode === 13) {
      let params = {
        status: Url.GetParam("status"),
        search: searchItem,
        page,
        pageSize,
      };
      getLocationList && getLocationList(params);
      setValues && setValues(params);
      UpdateUrl(params);
    }
  };

  const handlePageSize = (pageSize) => {
    let params = {
      status: values?.status ? values?.status : "",
      search: Url.GetParam("search"),
      page,
      pageSize,
    };
    getLocationList && getLocationList(params);
    setValues && setValues(params);
    UpdateUrl(params);
  };

  return (
    <Filter
      showHeader
      newTableHeading
      sortByDropdown
      initialValues={initialValues}
      handleDeleteFilter={handleDeleteFilter}
      refreshButtonOnClick={refreshButtonOnClick}
      refreshValue={spinValue}
      showStatusFilter
      handleFilter={handleFilter}
      customStatusOption={customStatusOption}
      enableReinitializes
      pageSearchOnChange={(e) => {
        handleSearchTermChange(e);
      }}
      pageSearchValue={searchItem}
      onKeyPress={onSearchKeyUp}
      searchPlaceholder="Search..."
      getPageSizeByOptions={(e) => handlePageSize(e)}
      selectedPageSize={pageSize && pageSize}
      showPageSize
    />
  );
};

export default LocationFilter;
