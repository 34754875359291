import React, { useState } from "react";
import { DropdownItem } from "reactstrap";
import { useDispatch } from "react-redux";

// Components
import UserCard from "../../../components/UserCard";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import DeleteModal from "../../../components/DeleteModal";

// API
import { endpoints } from "../../../api/endPoints";

// Services
import TeamMemberService from "../../../services/TeamMemberService";

// Actions
import { fetchList } from "../../../actions/table";

// Lib
import String from "../../../lib/String";

const TeamTab = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rowValue, setRowValue] = useState("")
  let dispatch = useDispatch()

  const handleDelete = async () => {
    dispatch(await TeamMemberService.delete(rowValue?.id, (res) => {
      if (res) {
        dispatch(
          fetchList(
            "TeamList",
            `${endpoints().TeamMemberAPI}/search`,
            1,
            25,
            {
              user_id: props?.user_id
            }
          )
        );
        setIsOpen(false)
        setRowValue("")
      }
    }))
  }

  return (
    <>
      <DeleteModal
        id={rowValue?.id}
        label={String.concatName(rowValue?.teamUserFirstName, rowValue?.teamUserlastName)}
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
          setRowValue("")
        }}
        title="Delete Team Member"
        deleteFunction={handleDelete}
      />
      <ReduxTable
        id="TeamList"
        newTableHeading
        searchPlaceholder="Search"
        sortByDropdown
        apiURL={`${endpoints().TeamMemberAPI}/search`}
        paramsToUrl={true}
        params={{
          user_id: props && props.user_id,
        }}
        history={props.history}
        message="You can start by clicking on Add New"
      >
        <ReduxColumn
          field="teamUserFirstName"
          sortBy="teamUserFirstName"
          width="350px"
          maxWidth="250px"
          minWidth="250px"
          renderField={(row) => (
            <>
              <UserCard
                customSize={parseInt(50, 10)}
                firstName={row?.teamUserFirstName}
                url={row?.teamUserAvatarUrl}
                lastName={row?.teamUserlastName}
              />
            </>
          )}
        >
          Team User
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    className=" text-danger cursor-pointer"
                    onClick={() => {
                      setRowValue(row)
                      setIsOpen(true)
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default TeamTab;
