import React from "react";
import HourComponent from "../../../components/HourComponent";
import NumberComponent from "../../../components/Number";
import Select from "../../../components/Select";
import SingleCheckbox from "../../../components/SingleCheckbox";
import Text from "../../../components/Text";

import {
    statusOptions
} from "../../../helpers/AttendanceType";
import SaveButton from "../../../components/SaveButton";
import StatusSelect from "../../../components/SelectStatus";
import ObjectName from "../../../helpers/ObjectName";
import DateSelector from "../../../components/Date";

const  AttendanceTypeForm=(props)=> {
    let { daysArray, showSaveButton,categoryList , roleList, statusOption, hourOption } = props;


  return (
    <div>
      <Text name="name" label="Name" />
      <NumberComponent name="days_count" label="Days Count" required />
      <Select name="status" label="Status" required options={statusOptions} />
      <StatusSelect name={"default_status"} label="Default Status" objectName={ObjectName.ATTENDANCE} statusOption={statusOption} />
      <NumberComponent
        name="maximum_allowed"
        label="Maximum Allowed"
        placeholder="Enter .."
      />
      <HourComponent name="cutoff_time" label="Cut Off Time" range={12} hourOption={hourOption}/>
      <Select
            name="category"
            label="Category"
            placeholder="Select Category"
            options={categoryList}
          />
           <Select
            name="allowed_roles"
            label="Allowed Roles"
            placeholder="Select Allowed Roles"
            options={roleList}
            isMulti
          />
      <div className="d-inline-block">
        <div>
          <SingleCheckbox
            name="allow_late_checkin"
            label={"Allow Late Check-In"}
          />
        </div>
       
        <div>
          <SingleCheckbox
            name="is_additional_shift"
            label={"Is Additional Shift"}
          />
        </div>
        <div>
          <SingleCheckbox
            name="show_in_salary_slip"
            label={"Show In Salary Slip"}
          />
        </div>
       
      
        
      </div>
      <div className="d-flex justify-content-between border pt-3 ps-3 pe-3 shadow">
          {daysArray.map((day)=>
                 <div>
                 <SingleCheckbox
                   name={day}
                   label={day}
                 />
               </div>
          )}
        </div>

     <div className="row">
      <div className="col-6 mt-3">
        <DateSelector
                name="date"
                label={"Date"}
                placeholder="Date"
                isClearable
              />
              </div>
              </div>
        {showSaveButton && <SaveButton />}

    </div>
  )
}

export default AttendanceTypeForm;
