import React from "react";
import HourComponent from "../../../components/HourComponent";
import NumberComponent from "../../../components/Number";
import Select from "../../../components/Select";
import SingleCheckbox from "../../../components/SingleCheckbox";
import Text from "../../../components/Text";

import {
    statusOptions
} from "../../../helpers/AttendanceType";
import SaveButton from "../../../components/SaveButton";
import StatusSelect from "../../../components/SelectStatus";
import ObjectName from "../../../helpers/ObjectName";
import DateSelector from "../../../components/Date";
import CancelButton from "../../../components/CancelButton";

const  AttendanceTypeForm=(props)=> {
    let { daysArray, showSaveButton,categoryList , roleList, statusOption, hourOption, history, isEditable } = props;


  return (
    <div>
      <Text name="name" label="Name" disabled={isEditable} />
      <NumberComponent name="days_count" label="Days Count" required disabled={isEditable}/>
      <Select name="status" label="Status" required options={statusOptions} isDisabled={isEditable}/>
      <StatusSelect name={"default_status"} label="Default Status" objectName={ObjectName.ATTENDANCE} statusOption={statusOption} isDisabled={isEditable}/>
      <NumberComponent
        name="maximum_allowed"
        label="Maximum Allowed"
        placeholder="Enter .."
        disabled={isEditable}
      />
      <HourComponent name="cutoff_time" label="Cut Off Time" range={12} hourOption={hourOption} isDisabled={isEditable}/>
      <Select
            name="category"
            label="Category"
            placeholder="Select Category"
            options={categoryList}
            isDisabled={isEditable}
          />
           <Select
            name="allowed_roles"
            label="Allowed Roles"
            placeholder="Select Allowed Roles"
            options={roleList}
            isMulti
            isDisabled={isEditable}
          />
      <div className="d-inline-block">
        <div>
          <SingleCheckbox
            name="allow_late_checkin"
            label={"Allow Late Check-In"}
            enabled={isEditable}
          />
        </div>
       
        <div>
          <SingleCheckbox
            name="is_additional_shift"
            label={"Is Additional Shift"}
            enabled={isEditable}
          />
        </div>
        <div>
          <SingleCheckbox
            name="show_in_salary_slip"
            label={"Show In Salary Slip"}
            enabled={isEditable}
          />
        </div>
       
      
        
      </div>
      <div className="d-flex justify-content-between border pt-3 ps-3 pe-3 shadow">
          {daysArray.map((day)=>
                 <div>
                 <SingleCheckbox
                   name={day}
                   label={day}
                   enabled={isEditable}
                 />
               </div>
          )}
        </div>

     <div className="row">
      <div className="col-6 mt-3">
        <DateSelector
                name="date"
                label={"Date"}
                placeholder="Date"
                disabled={isEditable}
                isClearable={!isEditable ? true : false}
              />
              </div>
              </div>
      {showSaveButton && !isEditable && (
        <>
          <SaveButton />
          <CancelButton
            onClick={() => {
              history.push("/setting/Attendance");
            }}
          />
        </>
      )}

    </div>
  )
}

export default AttendanceTypeForm;
