import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import ProductCard from "../../product/components/productCard";
import StatusText from "../../../components/StatusText";

// Lib
import Currency from "../../../lib/Currency";

// Services
import OrderService from "../../../services/OrderService";

const ProductTab = (props) => {
    const {
        id,
        history,
        params,
        apiURL,
        showStatusColumn,
        bulkSelect,
        showBulkSelect,
        orderId,
        isRender,
        editable,
        setEditable,
        selectedCheckBox
    } = props;

    const [totalAmount, setAmount] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        getOrder()
    }, [orderId, isRender])

    const getOrder = async () => {

        if (orderId) {
            const response = await OrderService.search({ orderId: orderId })
            if (response && response.data) {
                let orderDetail = response.data
                orderDetail.forEach((detail) => {
                    setAmount(detail?.total_amount)
                    setEditable(true);

                })
            }
        }
    }

    const sortOptions = [
        {
            value: "id:DESC",
            label: "Most Recent",
        },
        {
            value: "product_display_name:ASC",
            label: "Name",
        },
    ];

    return (
        <Fragment className=" card card-body ">
            <ReduxTable
                id={id}
                searchPlaceholder="Search"
                apiURL={apiURL}
                paramsToUrl={true}
                sortByOptions={sortOptions}
                params={params}
                history={history}
                bulkSelect={showBulkSelect}
                onBulkSelect={bulkSelect}
                selectedCheckBox={selectedCheckBox}
                totalAmount
            >
                <ReduxColumn
                    field="product_name"
                    sortBy="product_display_name"
                    width="310px"
                    minWidth="310px"
                    maxWidth="310px"
                    renderField={(row) => (
                        <ProductCard
                            productImageIcon
                            square
                            productName={row.product_name}
                            brandName={row.brand_name}
                            url={row.image}
                            size={row.size}
                            id={row.product_id}
                            brand_id={row.brand_id}
                            showId
                        />
                    )}
                >
                    Product Name
                </ReduxColumn>
                <ReduxColumn
                    field="unit_price"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    className="text-center"
                    renderField={(row) => (
                        <span>{Currency.Format(row.unit_price)}</span>
                    )}
                >
                    Unit Price
                </ReduxColumn>
                <ReduxColumn
                    field="quantity"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    className="text-center"
                    renderField={(row) => (
                        <span>{row.quantity}</span>
                    )}
                >
                    Quantity
                </ReduxColumn>
                <ReduxColumn
                    field="amount"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    className="text-center"
                    renderField={(row) => (
                        <span>{Currency.Format(row.amount)}</span>
                    )}
                >
                    Amount
                </ReduxColumn>
                {showStatusColumn &&
                    <ReduxColumn
                        field="status"
                        width="170px"
                        minWidth="170px"
                        maxWidth="170px"
                        className="text-center"
                        renderField={(row) => (
                            <StatusText backgroundColor={row?.colourCode} status={row?.status} text={row.reason} />
                        )}
                    >
                        Status
                    </ReduxColumn>}

            </ReduxTable>
        </Fragment>
    )

}

export default ProductTab;