import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { fetchList } from '../../actions/table';
import { endpoints } from '../../api/endPoints';
import MoreDropdown from '../../components/authentication/moreDropdown';
import DeleteModal from '../../components/DeleteModal';
import Drawer from '../../components/Drawer';
import PageTitle from '../../components/PageTitle';
import ReduxTable, { ReduxColumn } from '../../components/reduxTable';
import SaveButton from '../../components/SaveButton';
import { Status, statusOptions } from '../../helpers/Lead';
import LeadSettingService from '../../services/LeadSettingService';
import LeadTypeForm from './components/LeadTypeForm';

const LeadType = (props) => {

    const [rowValue, setRowValue] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    let dispatch = useDispatch();

    let FormBody = (
        <LeadTypeForm />
    );

    let FormFooter = (
        <>
            <SaveButton type="submit" label={rowValue ? "Update" : "Add"} />
        </>
    );

    let initialValue = {
        name: rowValue?.name,
        status: statusOptions.find((data) => data?.value == rowValue?.status)
    };

    let openToggle = () => {
        setIsOpen(!isOpen)
    };

    let closeToggle = () => {
        setIsOpen(false)
        setRowValue(null)
    };

    const handleLeadSubmit = async (values) => {
        let pointTo = rowValue ? "update" : "create";

        let data = new FormData();
        data.append("name", values?.name ? values?.name : "")
        data.append("status", values?.status ? values?.status?.value : "");

        if (rowValue && rowValue?.id) {
            data.append("id", rowValue?.id);
        }

        dispatch(await LeadSettingService[pointTo](data, (res) => {
            dispatch(
                fetchList("LeadType", `${endpoints().LeadTypeApi}/search`, 1, 25, {})
            );
            closeToggle()
        }, rowValue?.id))

    };

    const handleDelete = async () => {
        dispatch(await LeadSettingService.delete(rowValue?.id, (res) => {
            dispatch(
                fetchList("LeadType", `${endpoints().LeadTypeApi}/search`, 1, 25, {})
            );
            closeToggle()
        }))
    }

    return (
        <div>
            <PageTitle label="Lead Types" buttonHandler={() => {
                openToggle()
            }} buttonLabel="Add" />
            <Drawer
                DrawerBody={FormBody}
                DrawerFooter={FormFooter}
                modelTitle={rowValue ? "Edit Lead Type" : "Add Lead Type"}
                onSubmit={(values) => {
                    handleLeadSubmit(values);
                }}
                initialValues={initialValue}
                handleOpenModal={openToggle}
                handleCloseModal={closeToggle}
                handleDrawerClose={closeToggle}
                isModalOpen={isOpen}
                enableReinitialize
                disableSubmitOnEnter={true}
            />
            <DeleteModal
                isOpen={openDeleteModal}
                toggle={() => {
                    setOpenDeleteModal(false);
                }}
                title="Delete Lead Type"
                deleteFunction={() => {
                    handleDelete();
                }}
                label={rowValue?.name}
                id={rowValue?.id}
            />
            <ReduxTable
                id="LeadType"
                showHeader
                searchPlaceholder="Search"
                paramsToUrl={true}
                history={props.history}
                apiURL={`${endpoints().LeadTypeApi}/search`}
                newTableHeading
                sortByDropdown
                params={{ status:Status.STATUS_ACTIVE }}
                showStatusFilter
                customStatusOption={statusOptions}
            >
                <ReduxColumn
                    field="name"
                    sortBy="name"
                    renderField={(row) => (
                        <Link
                            to={`/admin/settings/leadType/${row.id}`}
                            className="link-opacity-75 text-decoration-none"
                        >
                            {row.name}
                        </Link>
                    )}
                >
                    Name
                </ReduxColumn>
                <ReduxColumn
                    field="status"
                    sortBy="status"
                    width={"120px"}
                    minWidth="120px"
                    maxWidth="120px"
                    className="column-status"
                    renderField={(row) => (
                        <div
                            className={`status-input text-center rounded text-white text-uppercase ${row.status == Status.STATUS_ACTIVE
                                ? "bg-success"
                                : row.status == Status.STATUS_INACTIVE
                                    ? "bg-dark bg-opacity-50"
                                    : ""
                                }`}
                        >
                            <p>{row.statusName}</p>
                        </div>
                    )}
                >
                    Status
                </ReduxColumn>
                <ReduxColumn
                    field="Action"
                    disableOnClick
                    width="70px"
                    renderField={(row) => (
                        <>
                            <div className="text-center action-group-dropdown">
                                <MoreDropdown>
                                    <DropdownItem
                                        onClick={() => {
                                            setRowValue(row);
                                            openToggle();
                                        }}
                                    >
                                        Quick View
                                    </DropdownItem>
                                    <DropdownItem
                                        className="text-danger"
                                        onClick={() => {
                                            setRowValue(row);
                                            setOpenDeleteModal(true);
                                        }}
                                    >
                                        Delete
                                    </DropdownItem>
                                </MoreDropdown>
                            </div>
                        </>
                    )}
                >
                    Action
                </ReduxColumn>
            </ReduxTable>

        </div>
    )
}

export default LeadType;
