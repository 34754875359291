import {
  faBuildingColumns,
  faChartLine,
  faClipboard,
  faClipboardList,
  faContactCard,
  faMapLocationDot,
  faMessage,
  faMoneyBillTrendUp,
  faPersonCircleQuestion,
  faPersonShelter,
  faPhoneFlip,
  faReceipt,
  faSheetPlastic,
  faTableCellsLarge,
  faTasks,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Permission from "../helpers/Permission";
import Url from "../helpers/Url";
import { hasPermission } from "../services/UserRolePermissionService";

export function getPeopleNavList(setting, permissionList) {

  const showFine = hasPermission(Permission.FINE_VIEW);
  let showBonus = hasPermission(Permission.BONUS_VIEW);
  let showAttendance = hasPermission(Permission.ATTENDANCE_VIEW)
  let showReport = hasPermission(Permission.REPORT_VIEW)
  let showVisitor = hasPermission(Permission.VISITOR_VIEW)
  let showUser = hasPermission(Permission.USER_VIEW)
  let showSalary = hasPermission(Permission.SALARY_VIEW);
  let showGatePass = hasPermission(Permission.GATE_PASS_VIEW)
  let showLocationAllocation = hasPermission(Permission.LOCATION_ALLOCATION_VIEW)
  let showActivity = hasPermission(Permission.ACTIVITY_VIEW)
  let Reimbursement = hasPermission(Permission.REIMBURSEMENT_VIEW)
  let messagesManageOthers = hasPermission(Permission.MESSAGE_MANAGE_OTHERS);
  let showContact = hasPermission(Permission.CONTACT_VIEW);
  let showCallRegister = hasPermission(Permission.CALL_REGISTER_VIEW);
  let showTimesheet = hasPermission(Permission.TIMESHEET_VIEW);

  let arrayList = [];

  arrayList = arrayList.concat({
    name: "Dashboard",
    url: "/people/dashboard",
    icon: faTableCellsLarge,
    horizontalLine: true
  });

  if (showAttendance) {
    arrayList = arrayList.concat({
      name: "Attendance",
      url: "/attendance",
      addPageurl: "/attendance",
      detailsPageurl: "/attendance/",
      icon: faClipboardList,
    });
  }

  if (showFine) {
    arrayList = arrayList.concat({
      name: "Fines",
      url: Url.FINE_LIST,
      detailsPageurl: "/fine/",
      addPageurl: "/fine",
      icon: faTasks,
    });
  }

  if (showBonus) {
    arrayList = arrayList.concat({
      name: "Bonus",
      url: Url.BONUS_LIST,
      detailsPageurl: "/bonus/",
      addPageurl: "/bonus",
      icon: faTasks,
    });
  }

  if (showSalary) {
    arrayList = arrayList.concat({
      name: "Salary",
      url: "/salary",
      detailsPageurl: "/salary/detail/",
      editPageurl: "/salary/list",
      addPageurl: "/salary/add",
      icon: faReceipt,
      horizontalLine: true
    });
  }

  if (showLocationAllocation) {
    arrayList = arrayList.concat({
      name: "Location Allocation",
      url: Url.LOCATION_ALLOCATION,
      detailsPageurl: "/locationAllocation/:id",
      editPageurl: "/locationAllocation",
      icon: faMapLocationDot,
      horizontalLine: true
    });
  }

  if (showActivity) {
    arrayList = arrayList.concat({
      name: "Activities",
      url: Url.ACTIVITY_LIST,
      detailsPageurl: "/activity/detail",
      editPageurl: "/activity",
      icon: faChartLine,
    });
  }

  if (showCallRegister) {
    arrayList = arrayList.concat({
      name: "Call Register",
      url: "/callRegister",
      detailsPageurl: "/callRegister/",
      icon: faPhoneFlip,
    });
  }

  if (showGatePass) {
    arrayList = arrayList.concat({
      name: "Gate Pass",
      url: Url.GATE_PASS_LIST,
      editPageurl: "/gatePass",
      icon: faPersonShelter
    })
  }

  if (showVisitor) {
    arrayList = arrayList.concat({
      name: "Visitors",
      url: Url.VISITOR_LIST,
      editPageurl: "/visitor",
      icon: faPersonCircleQuestion,
      horizontalLine: true
    })
  };

  if (showContact) {
    arrayList = arrayList.concat({
      name: "Contacts",
      url: "/contacts",
      detailsPageurl: "/contact/",
      icon: faContactCard,
    });
  }

  arrayList = arrayList.concat({
    name: "Devices",
    url: "/devices",
    detailsPageurl: "/device/",
    icon: faUser,
  });

  if (messagesManageOthers) {
    arrayList = arrayList.concat({
      name: "Messages",
      url: "/messages",
      icon: faMessage,
    });
  }

  if (showUser) {
    arrayList = arrayList.concat({
      name: "Users",
      url: "/users",
      detailsPageurl: "/user/",
      icon: faUser,
      horizontalLine: true
    });
  }

  arrayList = arrayList.concat({
    name: "Trainings",
    url: "/training",
    detailsPageurl: "/training/",
    icon: faBuildingColumns,
  });

  if (showTimesheet) {
    arrayList = arrayList.concat({
      name: "Timesheets",
      url: "/timesheet",
      detailsPageurl: "/timesheet/",
      icon: faSheetPlastic,
      horizontalLine: true
    });
  }

  if (Reimbursement) {
    arrayList = arrayList.concat({
      name: "Reimbursement",
      url: "/reimbursement",
      detailsPageurl: "/reimbursement/",
      icon: faMoneyBillTrendUp,
      horizontalLine: true
    });
  }

  if (showReport) {
    arrayList = arrayList.concat({
      name: "Reports",
      url: "/Reports",
      detailsPageurl: "/attendanceSummaryReport",
      attendanceReportLocationWise: "/attendanceReportLocationWise",
      addPageurl: "/attendanceReport",
      editPageurl: "/fineReport",
      icon: faClipboard,
    });
  }

  return arrayList;
}
