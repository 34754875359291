import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const HeaderText = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const StatusBadge = styled.span`
  padding: 7px 25px;
  height: 35px;
  border-radius: 4px;
  background-color: ${(props) => {
    return props.badgeColor;
  }};
  color: white;
  font-weight: bold;
`;

const TitleWithStatusBadge = ({
  title,
  badgeColor,
  badgeLabel,
  children,
}) => {
  return (
    <HeaderContainer>
      {title && <HeaderText>{title}</HeaderText>}
      <div className='d-flex'>
        {children}
        {badgeLabel && (
          <StatusBadge badgeColor={"red"}>{badgeLabel}</StatusBadge>
        )}
      </div>
    </HeaderContainer>
  );
};

export default TitleWithStatusBadge;
