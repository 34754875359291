import { endpoints } from "../api/endPoints";
import ArrayList from "../lib/ArrayList";
import Url from "../lib/Url";





class LocationPerformanceReportService {

    static async getReport(params) {
        let queryString = await ArrayList.toQueryString(params);
        let response = await Url.get(`${endpoints().LocationPerformanceReportApi}`, queryString);
        return response && response.data;
    }
}
export default LocationPerformanceReportService;