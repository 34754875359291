import React, { useEffect, useState } from "react";

// Components
import ColorSelect from "../../components/colorSelect/colorSelect";
import MultiSelect from "../../components/Multiselect";
import Select from "../../components/Select";
import SingleCheckbox from "../../components/SingleCheckbox";
import Text from "../../components/Text";
import UserSelect from "../../components/UserSelect";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import { groupOption } from "../../helpers/Status";

// Services
import StatusService from "../../services/StatusService";
import { getUserRole } from "../../services/UserSettingService";
import Url from "../../lib/Url";

const StatusForm = (props) => {
  const {
    defaultReviewerId,
    defaultOwnerId,
    showDefaultOwnerDetailsPageLink,
    showDefaultReviewerDetailsPageLink,
  } = props;

  const [userRole, setUserRole] = useState();
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    getStatusList();
  }, [props?.objectName]);

  const getUserList = async () => {
    const roleData = await getUserRole();
    setUserRole(roleData);
  };

  const getStatusList = async () => {
    const list = await StatusService.getOption(
      props.objectName,
      props?.ticketTypeId
        ? props?.ticketTypeId
        : props?.detail?.ticket_type || null,
      null,
      props?.objectId ? props?.objectId : props?.detail?props?.detail?.object_id:Url.GetParam("objectId") || null
    );
    
    setStatusList(list);
  };

  const dueDateOption = [
    {
      label: "Today",
      value: "Today",
    },
    {
      label: "Tomorrow",
      value: "Tomorrow",
    },
  ];

  return (
    <div className="row">
      <div className="col">
        <div>
          <Text
            name="status"
            label="Status Name"
            placeholder="Enter Status Name "
          />
        </div>
        <ColorSelect
          name="colorcode"
          label="Color Code"
          defaultValue={props?.detail?.colorCode}
          setColor={props?.setColor}
        />

        <div>
          <MultiSelect
            label="Allowed Users"
            name="allowedUser"
            options={userRole ? userRole : []}
          />
        </div>
        <div>
          <MultiSelect
            label="Next Status"
            name="nextStatus"
            options={statusList}
          />
        </div>
        <UserSelect
          name="default_owner"
          label="Default Owner"
          selectedUserId={props?.defaultOwner ? props?.defaultOwner : null}
          showAssignToMeOption
          labelName="Logged In User"
          showUserDetailsPageLink={showDefaultOwnerDetailsPageLink}
          userId={defaultOwnerId}
        />
        <UserSelect
          name="default_reviewer"
          label="Default Reviewer"
          selectedUserId={
            props?.defaultReviewer ? props?.defaultReviewer : null
          }
          userList={props?.userList}
          showAssignToMeOption
          labelName="Logged In User"
          showUserDetailsPageLink={showDefaultReviewerDetailsPageLink}
          userId={defaultReviewerId}
        />
        <Select
          name="default_due_date"
          label="Default Due Date"
          options={dueDateOption}
        />
        <Select
          label="Group"
          name="group"
          options={groupOption}
          // handleChange={(e) => handleChange(e)}
        />
        <div className="form-wrapper mb-3">
  {[
    ObjectName.STOCK_ENTRY_PRODUCT,
    ObjectName.STOCK_ENTRY,
    ObjectName.ORDER_TYPE,
    ObjectName.ORDER_PRODUCT,
    ObjectName.TRANSFER,
    ObjectName.TRANSFER_PRODUCT,
  ].includes(props.objectName) && (
    <div className="field-wrapper">
      <SingleCheckbox
        name="update_quantity"
        label="Update Quantity"
        className="accepted-terms mb-0 pb-0 me-3"
      />
    </div>
  )}

  <div className="field-wrapper">
    <SingleCheckbox
      name="allow_edit"
      label="Allow Edit"
      className="accepted-terms mb-0 pb-0 me-3"
    />
  </div>
  <div className="field-wrapper">
    <SingleCheckbox
      name="send_notification_to_owner"
      label="Send Notification to Owner"
      className="accepted-terms mb-0 pb-0 me-3"
    />
  </div>
  <div className="field-wrapper">
    <SingleCheckbox
      name="send_notification_to_reviewer"
      label="Send Notification to Reviewer"
      className="accepted-terms mb-0 pb-0 me-3"
    />
  </div>

  {/* Specific Configurations */}
  {props.objectName === ObjectName.STOCK_ENTRY_PRODUCT && (
    <SingleCheckbox
      name="update_location_product_last_stock_entry_date"
      label="Update Location Product Last Stock Entry Date"
    />
  )}
  {props.objectName === ObjectName.PRODUCT_PRICE && (
    <SingleCheckbox name="is_active_price" label="Is Active Price" />
  )}
  {props.objectName === ObjectName.SALE_SETTLEMENT && (
    <SingleCheckbox name="validate_amount" label="Validate Amount" />
  )}
  {[
    ObjectName.PURCHASE_PRODUCT,
    ObjectName.PURCHASE,
  ].includes(props.objectName) && (
    <>
      {[
        { name: "update_product_price", label: "Update Product Price" },
        { name: "update_account_product", label: "Update Account Product" },
        {
          name: "update_quantity_in_location_product",
          label: "Update Quantity In Location Product",
        },
        { name: "not_received_product", label: "Not Received Products" },
        { name: "rejected_product", label: "Rejected Products" },
      ].map(({ name, label }) => (
        <div className="field-wrapper" key={name}>
          <SingleCheckbox
            name={name}
            label={label}
            className="accepted-terms mb-0 pb-0 me-3"
          />
        </div>
      ))}
    </>
  )}
  {[
    ObjectName.ORDER_PRODUCT,
    ObjectName.ORDER_TYPE,
  ].includes(props.objectName) && (
    <div className="field-wrapper">
      <SingleCheckbox
        name="allow_cancel"
        label="Allow Cancel"
        className="accepted-terms mb-0 pb-0 me-3"
      />
    </div>
  )}
  {props.objectName === ObjectName.TRANSFER && (
    <>
      {[
        {
          name: "update_distribution_quantity",
          label: "Update Distribution Quantity",
        },
        { name: "allow_product_add", label: "Allow Product Add" },
        { name: "allow_replenishment", label: "Allow Replenishment" },
      ].map(({ name, label }) => (
        <div className="field-wrapper" key={name}>
          <SingleCheckbox
            name={name}
            label={label}
            className="accepted-terms mb-0 pb-0 me-3"
          />
        </div>
      ))}
    </>
  )}
  {props.objectName === ObjectName.FINE && (
    <SingleCheckbox
      name="allow_to_view"
      label="Allow To View"
      className="accepted-terms mb-0 pb-0 me-3"
    />
  )}
  {props.objectName === ObjectName.ORDER_PRODUCT && (
    <div className="field-wrapper">
      <SingleCheckbox name="allow_refund" label="Allow Refund" />
    </div>
  )}
</div>

        </div>
    </div>
  );
};

export default StatusForm;
