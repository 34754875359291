import React, { useEffect, useState } from "react";
import { faClipboardList, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// API
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Components
import PageTitle from "../../components/PageTitle";
import StatisticsCountCard from "../../components/StatisticsCountCard";

// Lib
import { isBadRequest } from "../../lib/Http";

// Helpers
import { TODAY_VALUE } from "../../helpers/Date";

const PeopleDashboard = () => {
  const [countDetail, setCountDetail] = useState();

  useEffect(() => {
    getDashboardCount();
  }, []);

  const getDashboardCount = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().userDashboardAPI}/User`
      );
      const data = response && response.data;
      setCountDetail(data);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  return (
    <div>
      <PageTitle label="Dashboard" />
      <div className={["row me-5 ms-3 justify-content-start"].join(" ")}>
        <StatisticsCountCard
          label="Attendance"
          icon={<FontAwesomeIcon icon={faClipboardList} />}
          className={"text-white"}
          count={countDetail && countDetail.attendanceCount}
          redirectUrl={`/attendance?date=${TODAY_VALUE}`}
        />
        <StatisticsCountCard
          icon={<FontAwesomeIcon icon={faUser} />}
          className={"text-white"}
          label="Users"
          count={countDetail && countDetail.userCount}
          redirectUrl="/users"
        />
      </div>
    </div>
  );
};

export default PeopleDashboard;
