import React, { useEffect, useState } from "react";
import { apiClient } from "../../apiClient";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import UserCard from "../../components/UserCard";
import BreadCrumb from "../../components/Breadcrumb";

// Lib
import Currency from "../../lib/Currency";
import Url from "../../lib/Url";

// API
import { endpoints } from "../../api/endPoints";


// Helpers
import ObjectName from "../../helpers/ObjectName";

// Tabs Constants
const Fines = (props) => {
    const [productTagList, setProductTagList] = useState();

    useEffect(() => {
        getSeletedTagDetails();
    }, []);



    const getSeletedTagDetails = async () => {
        try {
            const response = await apiClient.get(`${endpoints().tagApi}/list`);
            const tagDetails = response.data.data;

            const tagList = tagDetails
                .filter(tag => tag.type === "FineType")
                .map(tag => ({
                    label: tag.name,
                    value: tag.id,
                    id: tag.id,
                    status: tag.status
                }));

            setProductTagList(tagList);
        } catch (err) {
            // Handle error
        }
    };


    const sortByOption = [
        {
            value: "user:ASC",
            label: "Name"
        },
        {
            value: "amount:DESC",
            label: "Amount"
        }
    ];

    // Bread crumb list
    const breadcrumbList = [
        { label: "Home", link: "/people/dashboard" },
        {
            label: "Reports",
            link: "/Reports"
        },
        {
            label: "Fine Report",
            link: ""
        }
    ];



    return (
        <>
            <BreadCrumb list={breadcrumbList} />
            <div className="row mx-1 justify-content-between mb-2">
                <PageTitle label="Fine Report" />
            </div>
            <div>
                <ReduxTable
                    id="fines"
                    showHeader
                    newTableHeading
                    apiURL={`${endpoints().fineReportAPI}/search`}
                    searchPlaceholder="Search"
                    params={{
                        sort: Url.GetParam("sort"),
                        sortDir: Url.GetParam("sortDir"),
                        objectName: ObjectName.FINE,
                        showTotal: true
                    }}
                    showBackgroundColor
                    sortByOptions={sortByOption}
                    showTypeFilter
                    customTypeOption={productTagList}
                    showCustomDateFilter
                    showUserFilter
                    showStatusFilter
                    paramsToUrl={true}
                    history={props.history}
                    icon={<FontAwesomeIcon icon={faCartShopping} />}
                    message="You can start by clicking on Add Order">
                    <ReduxColumn
                        className="ellipsis text-start"
                        sortBy="user"
                        renderField={(row) => (
                            <div className="mx-2 mt-2 pb-0">
                            <UserCard
                                customSize={parseInt(50, 10)}
                                firstName={row.first_name}
                                url={row.media_url}
                                lastName={row.last_name}
                            />
                        </div>

                        )}>
                        User
                    </ReduxColumn>
                    <ReduxColumn
                        sortBy="amount"
                        width="90px"
                        minWidth="130px"
                        className="text-right"
                        renderField={(row) => <span>{Currency.Format(row.amount)}</span>}>
                        Amount
                    </ReduxColumn>
                </ReduxTable>
            </div>
        </>
    );
};

export default Fines;
