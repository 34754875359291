import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, DropdownItem } from "reactstrap";

// Components
import MoreDropdown from "../../components/authentication/moreDropdown";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import StatusText from "../../components/StatusText";
import Text from "../../components/Text";
import UrlComponent from "../../components/Url";

//Config
import { endpoints } from "../../api/endPoints";

// Services
import CompanyService from "../../services/CompanyService";
import PageService from "../../services/PageService";
import StatusService from "../../services/StatusService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Lib
import Url from "../../lib/Url";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";

// Action
import { fetchList } from "../../actions/table";

const Page = (props) => {
  const { history } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [pageId, setPageId] = useState("");
  const [currentData, setCurrentData] = useState();
  const [portalUrl, setPortalUrl] = useState();
  const [url, setUrl] = useState();
  const [statusList, setStatusList] = useState([]);

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "updatedAt:DESC",
      label: "Most Recent",
    },
  ];

  const dispatch = useDispatch();

  const [hasDeletePermission, hasAddPermission] = [
    hasPermission(Permission.PAGE_DELETE),
    hasPermission(Permission.PAGE_ADD),
  ];

  useEffect(() => {
    companyDetail();
  }, []);

  //Toggle
  const toggle = (id) => {
    if (id) {
      setPageId(id);
    } else if (!id) {
      setPageId("");
      setCurrentData("");
    }
    setIsOpen(!isOpen);
  };

  const getStatusList = async (currentStatusId) => {
    if (currentStatusId) {
      const data = await StatusService.nextStatusSearch(
        ObjectName.PAGE,
        currentStatusId
      );

      if (data && data.length > 0) {
        setStatusList(data);
      }
    }
  };

  /**
   * Create Page
   *
   * @param data
   */

  const params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
  };

  const pageCreate = async (data, toggle) => {
    data.url = url;
    dispatch(await PageService.create(data, params, toggle));
  };

  /**
   * Update Page
   *
   * @param data
   */
  const updatePageData = async (id, data) => {
    data.url = url;
    dispatch(await PageService.update(id, data, params));
    toggle();
  };

  /**
   * Delete Page
   *
   * @param id
   */
  const pageDelete = async (id) => {
    dispatch(
      await PageService.delete(id, (res) => {
        dispatch(
          fetchList("page", `${endpoints().pageAPI}/search`, 1, 25, params)
        );
      })
    );
    setIsDeleteModal(false);
  };

  const companyDetail = async () => {
    const response = await CompanyService.get();
    setPortalUrl(response.portal_url);
  };

  const handleChange = (e) => {
    setUrl(`${portalUrl}/page=${e.target.value}`);
  };

  const addPageForm = (
    <div className="mt-2 mb-3">
      <div>
        <Text
          name="name"
          label="Page Name"
          placeholder="Page Name..."
          error=""
          fontBolded
          onChange={(e) => {
            handleChange(e);
          }}
          required={true}
        />
      </div>
      <div>
        {pageId && (
          <UrlComponent
            name="url"
            label="Page URL"
            placeholder="Enter Page URL..."
            required
          />
        )}
      </div>
    </div>
  );

  const pageFooter = (
    <div className="container-fluid">
      <div className="col-sm-12 text-center">
        <Button type="submit" label="" className="ms-3">
          {pageId ? "Update" : "Create"} Page
        </Button>
      </div>
    </div>
  );

  const handleStatusChange = async (id, status) => {
    dispatch(
      await PageService.statusUpdate(id, { status }, (res) => {
        dispatch(
          fetchList("page", `${endpoints().pageAPI}/search`, 1, 25, {
            ...params,
          })
        );
      })
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModal}
        toggle={() => {
          setIsDeleteModal(false);
        }}
        title="Delete Portal"
        id={currentData && currentData.id}
        label={currentData && currentData.name}
        deleteFunction={pageDelete}
      />
      <Drawer
        modelTitle={`${pageId ? "Edit " : "Add"} Page`}
        DrawerBody={addPageForm}
        DrawerFooter={pageFooter}
        onSubmit={(values) => {
          pageId ? updatePageData(pageId, values) : pageCreate(values, toggle);
        }}
        initialValues={{
          ...currentData,
          name: currentData && currentData.name ? currentData.name : "",
          url: currentData && currentData.url ? currentData.url : "",
        }}
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize
      />
      {/* /.page-heading */}
      <PageTitle
        label="Pages"
        {...(hasAddPermission && {
          buttonHandler: (e) => {
            toggle();
          },
          buttonLabel: "Add New",
        })}
      />

      <div className="mt-4">
        <ReduxTable
          id="page"
          showHeader
          searchPlaceholder="Search"
          paramsToUrl={true}
          history={props.history}
          apiURL={`${endpoints().pageAPI}/search`}
          newTableHeading
          onRowClick={(row) => {
            history.push(`/pages/pagelist/detail/${row.id}`);
          }}
          sortByOptions={sortByOption}
        >
          <ReduxColumn
            type="link"
            field="name"
            sortBy="name"
            isClickable="true"
            width="150px"
          >
            Page Name
          </ReduxColumn>
          <ReduxColumn
            field="status"
            width="150px"
            disableOnClick
            renderField={(row) => (
              <StatusText backgroundColor={row.colorCode} status={row.status} />
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            width="150px"
            renderField={(row) => (
              <div className="text-center action-group-dropdown">
                <MoreDropdown
                  onClick={() => {
                    getStatusList(row.statusId);
                  }}
                >
                  {statusList &&
                    statusList.length > 0 &&
                    statusList.map((data) => {
                      return (
                        <DropdownItem
                          onClick={() => {
                            handleStatusChange(row?.id, data?.value);
                          }}
                        >
                          {data.label}
                        </DropdownItem>
                      );
                    })}
                  <DropdownItem
                    onClick={() => {
                      setCurrentData(row);
                      setPageId(row.id);
                      toggle(row.id);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  {hasDeletePermission && (
                    <DropdownItem
                      className="text-danger"
                      onClick={() => {
                        setCurrentData(row);
                        setPageId(row.id);
                        setIsDeleteModal(true);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};
export default Page;
