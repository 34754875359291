import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Helpers
import { Projects } from "../../../helpers/Project";

// Components
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import Drawer from "../../../components/Drawer";
import Text from "../../../components/Text";
import SaveButton from "../../../components/SaveButton";

// API
import { endpoints } from "../../../api/endPoints";

// Lib
import Url from "../../../lib/Url";

// Services
import ProjectTicketTypeService from "../../../services/ProjectTicketTypeService";

// Actions
import { fetchList } from "../../../actions/table";

const Tab = {
  GENERAL: "General",
  TICKET_TYPE: "Ticket Type",
};

const TicketType = (props) => {
  const { setCurrentData, handleOpenModal, setIsDeleteModel } = props;
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false)
  const [rowValue, setRowValue] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false)
  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "name:ASC",
      label: "Name",
    },
  ];

  let dispatch = useDispatch();

  let modalForm = (
    <>
      <Text
        name="name"
        label="Name"
        placeholder="Enter Name"
        required
      />
    </>
  )

  const Footer = (
    <SaveButton
      type="submit"
      loading={isSubmiting}
      label={"Clone"}
    />
  );

  const openToggle = () => {
    setIsCloneModalOpen(!isCloneModalOpen)
  }

  const closeToggle = () => {
    setIsSubmiting(false)
    setIsCloneModalOpen(false)
  }

  const handleCloneTicketType = async (values) => {
    setIsSubmiting(true)

    const data = {
      name: values?.name,
    };
    await ProjectTicketTypeService.clone(rowValue?.id, data, (err, res) => {
      if (res) {
        dispatch(
          fetchList(
            "projectTicketType",
            `${endpoints().projectTicketTypeAPI}/search`,
            1,
            25,
            { ...Url.GetAllParams() },
          )
        );
        closeToggle()
      }

      if (err) {
        setIsSubmiting(false)
      }
    })
  }

  return (
    <div className="mt-4">
      <Drawer
        DrawerBody={modalForm}
        DrawerFooter={Footer}
        modelTitle={"Clone Ticket Type"}
        onSubmit={(values) => {
          handleCloneTicketType(values);
        }}
        initialValues={{
          name: "",
        }}
        handleOpenModal={openToggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isCloneModalOpen}
      />
      <ReduxTable
        id="projectTicketType"
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().projectTicketTypeAPI}/search`}
        projectId={props?.projectId ? props?.projectId : null}
        newTableHeading
        params={{
          projectId: props?.projectId ? props?.projectId : null,
        }}
        history={props.history}
        sortByOptions={sortByOption}
        paramsToUrl={true}
      >
        <ReduxColumn
          type="link"
          isClickable="true"
          field="name"
          sortBy="name"
          width="140px"
          minWidth="140px"
          maxWidth="140px"
          renderField={(row) => (
            <Link
              to={`/project/${row.project_id}/${row.id
                }?projectId=${Url.GetParam("projectId")}`}
            >
              {row.name}
            </Link>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          isClickable="true"
          field="sort"
          sortBy="sort"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Sort
        </ReduxColumn>

        <ReduxColumn
          field="status"
          sortBy="status"
          width="120px"
          minWidth="120px"
          maxWidth="120px"
          renderField={(row) => (
            <div
              className={`status-input text-center rounded text-white text-uppercase  my-3 mx-auto ${row.status && row.status == Projects.STATUS_ACTIVE
                ? "bg-success"
                : row.status == Projects.STATUS_INACTIVE
                  ? "bg-dark bg-opacity-50"
                  : ""
                }`}
            >
              <p>{row.status}</p>
            </div>
          )}
        >
          Status
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="action-group-dropdown text-center">
              <MoreDropdown>

                <DropdownItem
                  className="cursor-pointer"
                  onClick={() => {
                    setRowValue(row);
                    setIsCloneModalOpen(true);
                  }}
                >
                  Clone
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setCurrentData(row);
                    handleOpenModal();
                  }}
                >
                  Quick View
                </DropdownItem>
                <DropdownItem
                  className=" text-danger  cursor-pointer"
                  onClick={() => {
                    setCurrentData(row);
                    setIsDeleteModel(true);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

export default TicketType;
