import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import AddModal from "../../../components/Modal";
import Quantity from "../../../components/Quantity";
import Button from "../../../components/Button";

// Services
import StoreProductService from "../../../services/StoreProductService";

// Lib
import Url from "../../../lib/Url";
import Select from "../../../components/Select";
import LocationRackService from "../../../services/LocationRackService";

const storeProductBulkUpdateModal = (props) => {
  const {
    isOpen,
    toggle,
    storeIds,
    productId,
    setSelectedCheckBox,
    setSelectedIds,
  } = props;
  const [rackList, setRackList] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    getLocationRackList()
  }, [])
  

  const getLocationRackList=async ()=>{
    let data = await LocationRackService.list();
    setRackList(data)
  }

  const bulkUpdateBody = (
    <>
      <Quantity label="Quantity" name="quantity" maxQuantity={500} />
      <Quantity label="Min Quantity" name="min_quantity" maxQuantity={500} />
      <Quantity label="Max Quantity" name="max_quantity" maxQuantity={500} />
      <Select name="location_rack" label="Rack#" options={rackList}/>

    </>
  );

  const editModelFooter = (
    <Button type="submit" label="Update" />
  );

  const initialValues = {
    min_quantity: "",
    max_quantity: "",
    quantity: "",
    location_rack:""
  };

  const handleBulkUpdate = (data) => {
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      search: Url.GetParam("search"),
    };
    data.storeProductIds = storeIds?.selectedIds;
    data.min_quantity = data.min_quantity;
    data.max_quantity = data.max_quantity;
    data.quantity = data.quantity ? data.quantity : "";
    data.location_rack = data?.location_rack;
    data.productId = productId;
    dispatch(StoreProductService.bulkUpdate(data, toggle, params, productId));
    setSelectedIds("");
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  return (
    <>
      <AddModal
        modalTitle="Bulk Update"
        modalBody={bulkUpdateBody}
        isOpen={isOpen}
        initialValues={initialValues}
        toggle={toggle}
        modalFooter={editModelFooter}
        toggleModalClose={toggle}
        hideDefaultButtons
        onSubmit={(values) => {
          handleBulkUpdate(values);
        }}
      />
    </>
  );
};

export default storeProductBulkUpdateModal;
