import React from 'react'
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import { endpoints } from "../../../api/endPoints";
import { Link } from "react-router-dom";
import Drawer from "../../../components/Drawer";
import TextArea from "../../../components/TextArea";
import SaveButton from "../../../components/SaveButton";
import { useDispatch } from "react-redux";
import TestCaseService from "../../../services/TestCaseService";
import { useState } from "react";
import { fetchList } from "../../../actions/table";
import DeleteButton from "../../../components/DeleteButton";
import DeleteModal from "../../../components/DeleteModal";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import { DropdownItem } from "reactstrap";

const TestCaseTab=(props)=> {
    let dispatch = useDispatch()

    const [rowValue, setRowValue]=useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);


    const handleDelete = async () => {
      dispatch(
        await TestCaseService.delete(rowValue?.id, (res) => {
          if (res) {
            dispatch(
              fetchList("testCases", `${endpoints().TestCaseAPI}/search`, 1, 25, {
                ticket_id: props.ticketId
              })
            );
            props.testCasesCloseToggle && props.testCasesCloseToggle()
            setRowValue(null);
          }
        })
      );
    };

    let modelBody=(
        <>
        <TextArea name="testScenario" label="Test Scenario" required/>
        <TextArea name="testSteps" label="Test Steps"/>
        <TextArea name="prerequisites" label="Prerequisites"/>
        <TextArea name="testData" label="Test Data"/>
        <TextArea name="expectedResult" label="Expected Result"/>
        </>
    )

    let DrawerFooter =(
        <>
        <SaveButton label={rowValue?.id ? "Save" :"Add"}/>
        {rowValue && rowValue?.id && (
        <DeleteButton onClick={()=>{
          setOpenDeleteModal(true);
        }} label="Delete" className="ms-3" />
      )}
        </>
    )

    let initialValues ={
        testScenario: rowValue?.test_scenario ? rowValue?.test_scenario :"",
        testSteps: rowValue?.test_steps ? rowValue?.test_steps :"",
        prerequisites: rowValue?.prerequisites ? rowValue?.prerequisites :"",
        testData: rowValue?.test_data ? rowValue?.test_data :"",
        expectedResult: rowValue?.expected_result ? rowValue?.expected_result :"",
    }

    let handleSubmit =async (values)=>{
        values.ticketId = props?.ticketId;


        if(rowValue && rowValue?.id){
          dispatch(await TestCaseService.update(rowValue?.id,values,(res)=>{
            if(res){
              dispatch(
                fetchList("testCases", `${endpoints().TestCaseAPI}/search`, 1, 25, {
                  ticket_id: props.ticketId
                })
              );
              props.testCasesCloseToggle && props.testCasesCloseToggle()
              setRowValue(null);
            }
          }))
        }else{

dispatch(await TestCaseService.create(values,(res)=>{
  if(res){
    dispatch(
      fetchList("testCases", `${endpoints().TestCaseAPI}/search`, 1, 25, {
        ticket_id: props.ticketId
      })
    );
    props.testCasesCloseToggle && props.testCasesCloseToggle()
  }
}))
    }
    }

    const openModel = (rowValue) => {
      props.testCasesOpenToggle && props.testCasesOpenToggle();
      setRowValue(rowValue);
    };

    const handleToggleClose=()=>{
      props.testCasesCloseToggle && props.testCasesCloseToggle();
      setRowValue(null);
    }

  return (
    <div>
          <DeleteModal
          isOpen={openDeleteModal}
          toggle={() => {
            setOpenDeleteModal(false);
          }}
          title="Delete Test Case"
          deleteFunction={() => {
            handleDelete();
          }}
          label={rowValue?.test_number}
        />
         <Drawer
        modelTitle={rowValue?.id ? "Update Test Cases" : "Add Test Cases"}
        DrawerBody={modelBody}
        DrawerFooter={DrawerFooter}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        handleOpenModal={props.testCasesOpenToggle}
        handleCloseModal={handleToggleClose}
        handleDrawerClose={handleToggleClose}
        isModalOpen={props.isTestCasesOpen}
        showButton
        hideAddButton
      />
         <ReduxTable
        id="testCases"
        disableHeader
        paramsToUrl={true}
        params={{
          ticket_id: props.ticketId
        }}
        apiURL={`${endpoints().TestCaseAPI}/search`}
        history={props.history}
      >
        <ReduxColumn
          field="test_number"
          sortBy="test_number"
          minWidth="112px"
          className="text-center"
          renderField={(row) => (
            <Link to="#" onClick={(e) => openModel(row)}>
              {row.test_number}
            </Link>
          )}
        >
          TS NO#
        </ReduxColumn>
        <ReduxColumn field="test_scenario" sortBy="test_scenario"  className="text-center">
        Test Scenario
        </ReduxColumn>
        <ReduxColumn
            field="Action"
            disableOnClick
            renderField={(row) => (
              <div className="action-group-dropdown text-center">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      openModel(row);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className=" text-danger  cursor-pointer"
                    onClick={() => {
                      setRowValue(row)
                      setOpenDeleteModal(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
      </ReduxTable>
      
    </div>
  )
}

export default TestCaseTab;
