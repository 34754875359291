import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Components
import MoreDropdown from "../../../components/authentication/moreDropdown";
import AvatarCard from "../../../components/AvatarCard";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import DeleteModal from "../../../components/DeleteModal";

// API
import { endpoints } from "../../../api/endPoints";

// Helpers
import { Brand } from "../../../helpers/Brand";
import Permission from "../../../helpers/Permission";

// Assets
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Lib
import Url from "../../../lib/Url";

// Services
import BrandService from "../../../services/BrandService";
import { hasPermission } from "../../../services/UserRolePermissionService";

const brandListpage = (props) => {
  const { id, status, tab, setRow, _toggle } = props;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteType, setDeleteType] = useState("");

  const hasDeletePermission = hasPermission(Permission.BRAND_DELETE);

  const params = {
    tab: tab ? tab : "",
    status: status ? status : "",
  };

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const dispatch = useDispatch();

  const handleDelete = async (id) => {
    dispatch(
      await BrandService.delete(id, {
        sort: Url.GetParam("sort"),
        sortDir: Url.GetParam("sortDir"),
        search: Url.GetParam("search") || "",
        AllPage: Url.GetParam("page") || props.AllCurrentPage,
        AllPageSize: Url.GetParam("pageSize") || props.AllCurrentPageSize,
      })
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false);
        }}
        title="Delete Brand"
        deleteFunction={() => {
          handleDelete(deleteType.id);
        }}
        label={deleteType.name}
        id={deleteType.id}
      />
      <ReduxTable
        id={id}
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().brandAPI}/search`}
        newTableHeading
        icon={<FontAwesomeIcon icon={faStar} />}
        message="You can start by clicking add."
        sortByOptions={sortByOption}
        params={params}
        customStatus={{ status: status ? status : "" }}
        paramsToUrl={true}
        onRowClick={(row) => {
          history.push(`/brands/${row.id}`);
        }}
        history={props.history}
        hideQueryParams={{
          status: true,
        }}
      >
        <ReduxColumn
          field="name"
          type="link"
          sortBy="name"
          width="550px"
          minwidth="90px"
          maxwidth="90px"
          isClickable="true"
          renderField={(row) => (
            <>
              <Link to={`/brands/${row.id}`} className="link-opacity-75">
                <AvatarCard firstName={row.name} url={row.brandImageUrl} />
              </Link>
            </>
          )}
        >
          Brand Name
        </ReduxColumn>
        <ReduxColumn
          field="productCount"
          className="text-center"
          sortBy="productCount"
          width="550px"
          minwidth="90px"
          maxwidth="90px"
          isClickable="true"
        >
          Product Count
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy="status"
          width="240px"
          minwidth="240px"
          maxwidth="240px"
          renderField={(row) => (
            <div
              className={`status-input text-center rounded text-white text-uppercase mx-auto ${row.status && row.status === Brand.STATUS_ACTIVE_TEXT
                ? "bg-success"
                : row.status === Brand.STATUS_INACTIVE_TEXT
                  ? "bg-dark bg-opacity-50"
                  : ""
                }`}
            >
              <p>{row.status}</p>
            </div>
          )}
        >
          Status
        </ReduxColumn>

        <ReduxColumn
          width="70px"
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="text-center action-group-dropdown">
              <MoreDropdown>
                {row.status !== Brand.STATUS_ACTIVE_TEXT ? (
                  <DropdownItem
                    // className={"text-danger"}
                    onClick={async () => {
                      dispatch(
                        await BrandService.updateStatus(
                          row.id,
                          Brand.STATUS_ACTIVE_TEXT,
                          {
                            sort: Url.GetParam("sort"),
                            sortDir: Url.GetParam("sortDir"),
                            search: Url.GetParam("search") || "",
                            AllPage:
                              Url.GetParam("page") || props.AllCurrentPage,
                            AllPageSize:
                              Url.GetParam("pageSize") ||
                              props.AllCurrentPageSize,
                          }
                        )
                      );
                    }}
                  >
                    Make as Active
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    onClick={async () => {
                      dispatch(
                        await BrandService.updateStatus(
                          row.id,
                          Brand.STATUS_INACTIVE_TEXT,
                          {
                            sort: Url.GetParam("sort"),
                            sortDir: Url.GetParam("sortDir"),
                            search: Url.GetParam("search") || "",
                            AllPage:
                              Url.GetParam("page") || props.AllCurrentPage,
                            AllPageSize:
                              Url.GetParam("pageSize") ||
                              props.AllCurrentPageSize,
                          }
                        )
                      );
                    }}
                  >
                    Make as InActive
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={() => {
                    setRow(row);
                    _toggle();
                  }}
                >
                  Quick View
                </DropdownItem>
                {hasDeletePermission && (
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setDeleteType(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                )}
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default brandListpage;
