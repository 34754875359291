import React from "react";
import { DropdownItem } from "reactstrap";

// Components
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import MoreDropdown from "../../../components/authentication/moreDropdown";

// Endpoint
import { endpoints } from "../../../api/endPoints";

// Lib
import Url from "../../../lib/Url";
import { Link } from "react-router-dom";

// Helpers
import { Projects } from "../../../helpers/Project";

const ProjectComponent = (props) => {
  const { handleOpenModal, setCurrentData, setIsDeleteModel } = props;

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  return (
    <div>
      <div className="mt-4">
        <ReduxTable
          id="projectComponent"
          showHeader
          searchPlaceholder="Search"
          apiURL={`${endpoints().projectComponentAPI}/search`}
          newTableHeading
          params={{
            projectId: props?.projectId ? props?.projectId : null,
            sort: Url.GetParam("sort"),
            sortDir: Url.GetParam("sortDir"),
          }}
          projectId={props?.projectId ? props?.projectId : null}
          history={props.history}
          paramsToUrl={true}
          sortByOptions={sortByOption}
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            field="name"
            sortBy="name"
            width="140px"
            minWidth="140px"
            maxWidth="140px"
            renderField={(row) => (
              <Link
                to={`/projects/${row.project_id}/${
                  row.id
                }?projectId=${Url.GetParam("projectId")}`}
              >
                {row.name}
              </Link>
            )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            field="status"
            sortBy="status"
            width="120px"
            minWidth="120px"
            maxWidth="120px"
            renderField={(row) => (
              <div
                className={`
                                status-input text-center rounded text-white text-uppercase  my-3 mx-auto 
                                ${
                                  row.status &&
                                  row.status == Projects.STATUS_ACTIVE
                                    ? "bg-success"
                                    : row.status == Projects.STATUS_INACTIVE
                                    ? "bg-dark bg-opacity-50"
                                    : ""
                                }`}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            renderField={(row) => (
              <div className="action-group-dropdown text-center">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setCurrentData(row);
                      handleOpenModal();
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className=" text-danger  cursor-pointer"
                    onClick={() => {
                      setCurrentData(row);
                      setIsDeleteModel(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </div>
  );
};

export default ProjectComponent;
