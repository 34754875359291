import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";

//  Components
import "../../../components/reduxTable/styles.scss";

// API
import "../../../scss/_custom.scss";

import { endpoints } from "../../../api/endPoints";
import { TagsIcon } from "../../../assets/icons";
import { Tab } from "../../../helpers/ProductTag";
import DateTime from "../../../lib/DateTime";
import { isLoggedIn } from "../../../lib/Helper";
import Url from "../../../lib/Url";
import BreadCrumb from "../../../components/Breadcrumb";
import DeleteModal from "../../../components/DeleteModal";
import Drawer from "../../../components/Drawer";
import PageTitle from "../../../components/PageTitle";
import SaveButton from "../../../components/SaveButton";
import Text from "../../../components/Text";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import AppVersionService from "../../../services/AppVersionService";
import { fetchList } from "../../../actions/table";
import App from "../../../helpers/App";

const VersionTab = (props) => {
  const {
    history,
    tagType,
    noTagDetail,
    __toggle,
    isModel,
    pageTitle,
    columnName,
    label,
    customTypeOption,
    hideQueryParams,
    app_id
  } = props;

  const [isOpen, setIsOpen] = useState(isModel || false);
  const [name, setName] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteTag, setDeleteTag] = useState("");
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [appId,setAppId] = useState("")

  const dispatch = useDispatch();

  const statusOptions = [
    {
      value: Tab.STATUS_ACTIVE_TEXT,
      label: Tab.STATUS_ACTIVE_TEXT,
    },
    {
      value: Tab.STATUS_INACTIVE_TEXT,
      label: Tab.STATUS_INACTIVE_TEXT,
    },
    {
      value: "",
      label: "All",
    },
  ];

  // Use Effect
  useEffect(() => {
    isLoggedIn();
  }, []);

  // get params
  const _toggle = (tagId) => {
    __toggle && __toggle();
    setId(tagId || 0);
    setIsOpen(!isOpen);
    setIsSubmit(false);
  };

  const toggle = (tab) => {
    __toggle && __toggle();
    setIsOpen(!isOpen);
    setName("");
    setId("");
    setAppId("");
    setIsSubmit(false);
  };

  const params = {
    type: tagType,
    status: Url.GetParam("status"),
    search: Url.GetParam("search"),
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
  };

  const handleDelete = async (id) => {
 
    dispatch(await AppVersionService.delete(id, (res) => {
        dispatch(
            fetchList(
                "appVersion",
                `${endpoints().appVersionAPI}/search`,
                Url.GetParam("page") ? Url.GetParam("page") : 1,
                Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                {
                    ...Url.GetAllParams()
                }
            )
        );
    }))
}
  

  const updateStatus = async(value,status)=>{
    dispatch(await AppVersionService.updateStatus(value,status, (res) => {
        dispatch(
            fetchList(
                "appVersion",
                `${endpoints().appVersionAPI}/search`,
                Url.GetParam("page") ? Url.GetParam("page") : 1,
                Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                {
                    ...Url.GetAllParams()
                }
            )
        );
    }))
  }

  /**
   * Create Product
   *
   * @param values
   */
  const addtagForm = async (values) => {
    try {
      setIsSubmit(true);

      const data = new FormData();

      if (!id) {
        data.append("name", values.name.trim());
        data.append(
          "app_id",
          app_id ? app_id  : ""
        );
        dispatch(await AppVersionService.create(data, (res) => {
            dispatch(
                fetchList(
                    "appVersion",
                    `${endpoints().appVersionAPI}/search`,
                    Url.GetParam("page") ? Url.GetParam("page") : 1,
                    Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                    {
                        ...Url.GetAllParams()
                    }
                )
            );
            toggle()
        }))
        setIsSubmit(false);
      } else {
        data.append("name", values.name.trim());
        data.append("id", id);
        data.append("status", status);
        dispatch(await AppVersionService.update(data, (res) => {
            dispatch(
                fetchList(
                    "appVersion",
                    `${endpoints().appVersionAPI}/search`,
                    Url.GetParam("page") ? Url.GetParam("page") : 1,
                    Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                    {
                        ...Url.GetAllParams()
                    }
                )
            );
            toggle()
        }))
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  const breadcrumbList = [
    { label: "Home", link: "/admin/dashboard/" },
    {
      label: "Tags",
      link: "/tags",
    },
    {
      label: tagType,
    },
  ];

  const addProductTagForm = (
    <>
      <Text
        name="name"
        label={"Name"}
        required={true}
      />
     
    </>
  );

  const modalOpen = isModel !== undefined ? isModel : isOpen;

  const sortByOption = props.mobileApp
    ? [
        {
          value: "id:DESC",
          label: "Most Recent",
        },
        {
          value: "name:ASC",
          label: "Name",
        },
      ]
    : [
        {
          value: "name:ASC",
          label: "Name",
        },
      ];

  const productTagFooter = (
    <SaveButton
      type="submit"
      loading={isSubmit}
      label={name ? "Save" : "Add"}
    />
  );

  return (
    <div>
      <>
        <Drawer
          DrawerBody={addProductTagForm}
          DrawerFooter={productTagFooter}
          modelTitle={
            name
              ? `Edit ${label ? label : "Tag"}`
              : `Add ${label ? label : "Tag"}`
          }
          onSubmit={(values) => {
            addtagForm(values);
          }}
          initialValues={{
            name: name || "",
            app_id: appId || "",
          }}
          handleOpenModal={toggle}
          handleCloseModal={toggle}
          handleDrawerClose={toggle}
          isModalOpen={modalOpen}
        />
        <DeleteModal
          isOpen={openDeleteModal}
          toggle={() => {
            setOpenDeleteModal(false);
          }}
          title="Delete Tag Type"
          deleteFunction={() => {
            handleDelete(deleteTag.id);
          }}
          label={deleteTag.name}
          id={deleteTag.id}
        />
        {!noTagDetail && <BreadCrumb list={breadcrumbList} />}
        <div>
          {!props.showPageTitle && (
            <div className="pb-4">
              <PageTitle
                label={
                  props?.match?.params.id
                    ? props?.match?.params.id
                    : pageTitle
                    ? pageTitle
                    : tagType
                }
                buttonLabel="Add New"
                buttonHandler={(_e) => {
                  toggle();
                }}
              />
            </div>
          )}
        </div>
      </>
      <ReduxTable
        id="appVersion"
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().appVersionAPI}/search`}
        newTableHeading
        icon={<TagsIcon />}
        message="You can start by clicking Add New"
        customStatusOption={statusOptions}
        sortByOptions={sortByOption}
        showStatusFilter
        customTypeOption={customTypeOption}
        params={{
          appId: app_id
        }}
        hideQueryParams={hideQueryParams}
        paramsToUrl={true}
        history={history}
        onRowClick={(row) => {
          setName(row.name);
          setAppId(row.app_id)
          setStatus(row.status);
          return _toggle(row.id);
        }}
      >
        <ReduxColumn
          field="name"
          type="link"
          sortBy="name"
          width="160px"
          minWidth="160px"
          maxWidth="160px"
          isClickable="true"
          renderField={(row) => (
            <>
              {" "}
              {row.name}
              {row && row?.status == App.STATUS_INACTIVE_TEXT && (
                <span className="text-danger">({row?.status})</span>
              )}
            </>
          )}
        >
          {columnName ? columnName : "Name"}
        </ReduxColumn>
        
        {props.mobileApp && (
          <ReduxColumn
            field="createdAt"
            sortBy="createdAt"
            className="text-center"
            width="130px"
            minWidth="130px"
            maxWidth="130px"
            renderField={(row) => (
              <span>
                {DateTime.getDateTimeByUserProfileTimezone(row.createdAt)}
              </span>
            )}
          >
            Created At
          </ReduxColumn>
        )}
        <ReduxColumn
          minWidth={"100px"}
          width={"100px"}
          maxWidth={"100px"}
          field="status"
          disableOnClick
          className="action-column"
          renderField={(row) => (
            <div className="text-center landing-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    toggle();
                    setName(row?.name);
                    setAppId(row?.app_id)
                    setStatus(row?.statusId);
                    setId(row?.id);
                  }}
                >
                  Quick View
                </DropdownItem>
                {row.statusId !== App.STATUS_ACTIVE ? (
                  <DropdownItem
                    onClick={async () => {
                        updateStatus(row.id,
                            App.STATUS_ACTIVE_TEXT)
                    }}
                  >
                    Make as Active
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    onClick={async () => {
                        updateStatus(row.id,
                            App.STATUS_INACTIVE_TEXT)
                    }}
                  >
                    Make as InActive
                  </DropdownItem>
                )}
                {/* {row.type === TYPE_USER_DEFINED && ( */}
                <DropdownItem
                  className={"text-danger"}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setDeleteTag(row);
                  }}
                >
                  Delete
                </DropdownItem>
                {/* )} */}
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};
export default VersionTab;
