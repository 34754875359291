import React, { useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle';
import BreadCrumb from '../../components/Breadcrumb';
import LeadSettingService from '../../services/LeadSettingService';
import Form from '../../components/Form';
import LeadTypeForm from './components/LeadTypeForm';
import SaveButton from '../../components/SaveButton';
import CancelButton from '../../components/CancelButton';
import { statusOptions } from '../../helpers/Lead';

const LeadTypeDetail = (props) => {
    const [detail, setDetail] = useState(null)

    useEffect(() => {
        getDetail()
    }, [])


    const breadcrumbList = [
        { label: "Home", link: "/admin/companyProfile" },
        { label: "Lead Types", link: "/setting/leadType" },
        { label: "Lead Type Detail", link: "" },
    ];

    const getDetail = async () => {
        let data = await LeadSettingService.get(props?.match?.params?.id);
        setDetail(data)
    }

    let initialValues = {
        name: detail?.name ? detail?.name : "",
        status: statusOptions.find((data) => data?.value == detail?.status)
    };

    let handleFormSubmit = async (values) => {
        let data = new FormData();
        data.append("name", values?.name ? values?.name : "")
        data.append("status", values?.status ? values?.status?.value : "");

        data.append("id", detail?.id);

        await LeadSettingService["update"](data, (res) => {
            props?.history?.goBack()
        })
    }

    return (
        <div>
            <BreadCrumb list={breadcrumbList} />

            <PageTitle label="Lead Type Detail Page" />
            <div className='card card-body'>
                <Form
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                >
                    <LeadTypeForm />
                    <div className="d-flex">
                        <SaveButton style={{
                            borderRadius: "7px",
                        }}
                            className="btn-sm my-1"
                        />
                        <CancelButton
                            style={{
                                borderRadius: "7px",
                            }}
                            className="btn-sm my-1"
                            fontSize="small"
                            onClick={() => {
                                props?.history?.goBack()
                            }}
                        />
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default LeadTypeDetail;
