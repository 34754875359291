export const Type = {
    INCOMING_LABEL: "INCOMING",
    OUTGOING_LABEL: "OUTGOING",
    MISSED_LABEL: "MISSED",
    UNKNOWN_LABEL: "UNKNOWN",

    INCOMING_VALUE: 1,
    OUTGOING_VALUE: 2,
    MISSED_VALUE: 3,
    UNKNOWN_VALUE: 4,
}


const callRegisterType = [

    {
        label: Type.INCOMING_LABEL,
        value: Type.INCOMING_VALUE
    },
    {
        label: Type.OUTGOING_LABEL,
        value: Type.OUTGOING_VALUE
    },
    {
        label: Type.MISSED_LABEL,
        value: Type.MISSED_VALUE
    },
    {
        label: Type.UNKNOWN_LABEL,
        value: Type.UNKNOWN_VALUE
    },
];


export default callRegisterType