import React, { useEffect, useState } from "react";
import PublicPageBlockService from "../services/PublicPageBlockService";
import Carousel from "../components/Carousel";

const HomePage = () => {

  const [detail, setDetail]=useState([]);


  useEffect(() => {
    getPageBlockList()
  }, [])
  

  const getPageBlockList=async ()=>{
    let data = await PublicPageBlockService.get();
    setDetail(data)
  }


  return (
    <div className="">
      <Carousel detail={detail}/>
    </div>
  );
};

export default HomePage;
