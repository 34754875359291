import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { bindActionCreators } from "redux";
import { fetchList } from "../../actions/table";
import CountBadge from "../../components/CountBadge";
import PageTitle from "../../components/PageTitle";
import { isLoggedIn } from "../../lib/Helper";
import Url from "../../lib/Url";
import InvoiceTab from "./invoiceTab";
import InvoiceProductTab from "./invoiceProductTab";
import UrlValue from "../../helpers/Url";

export const Tabs = {
  INVOICES: "Invoice",
  INVOICE_PRODUCTS: "Invoice Products",
};

const Invoice = (props) => {
  const { history, invoice, invoiceProductCount } = props;
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : Tabs.INVOICES
  );

  useEffect(() => {
    isLoggedIn();
  }, []);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };
  const _handleTabChange = (tab) => {
      history.push(`${UrlValue.INVOICE}&tab=${tab}`);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <PageTitle label="Invoice" />
        </div>
      </div>

      <div className="bg-white mt-3 card-body">
        <Nav tabs className="admin-tabs">
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tabs.INVOICES,
              })}
              onClick={() => {
                toggleTab(Tabs.INVOICES);
                _handleTabChange(Tabs.INVOICES);
              }}
            >
              {Tabs.INVOICES}
              <CountBadge count={invoice} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tabs.INVOICE_PRODUCTS,
              })}
              onClick={() => {
                toggleTab(Tabs.INVOICE_PRODUCTS);
                _handleTabChange(Tabs.INVOICE_PRODUCTS);
              }}
            >
               Invoice Products
              <CountBadge count={invoiceProductCount} />
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          {activeTab == Tabs.INVOICES && (
            <TabPane tabId={Tabs.INVOICES}>
              <InvoiceTab id="invoice" history={history} />
            </TabPane>
          )}
          {activeTab == Tabs.INVOICE_PRODUCTS && (
            <TabPane tabId={Tabs.INVOICE_PRODUCTS}>
              <InvoiceProductTab history={history} />
            </TabPane>
          )}
        </TabContent>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const reduxTable = state.table;

  const invoice =
    reduxTable["invoice"] && reduxTable["invoice"].isFetching == false
      ? reduxTable["invoice"].totalCount
      : 0;
  const invoiceProductCount =
    reduxTable["invoiceProduct"] &&
    reduxTable["invoiceProduct"].isFetching == false
      ? reduxTable["invoiceProduct"].totalCount
      : 0;
  const storeProductDetails = reduxTable && reduxTable?.invoiceProduct?.[1];
  return {
    invoice,
    invoiceProductCount,
    storeProductDetails,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
