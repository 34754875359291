import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Components
import MoreDropdown from "../../../components/authentication/moreDropdown";
import DeleteModal from "../../../components/DeleteModal";
import Drawer from "../../../components/Drawer";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";

// Services
import orderTypeService from "../../../services/OrderTypeService";

// API
import { endpoints } from "../../../api/endPoints";

// Lib
import Url from "../../../lib/Url";
import OrderTypeForm from "./OrderTypeForm";

const OrderType = (props) => {
  const { _toggle, isOpen, history, setCurrentData, currentData, closeToggle } =
    props;

  const dispatch = useDispatch();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
    setCurrentData("");
  };

  const OrderType = (
    <OrderTypeForm
      form="Add"
      details={currentData}
      closeToggle={closeToggle}
      toggle={_toggle}
    />
  );

  const handleDelete = async (id) => {
    let params = {
      ...Url.GetAllParams(),
    };
    dispatch(await orderTypeService.delete(id, params));
  };

  return (
    <div>
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={closeDeleteModal}
        title="Delete Order Type"
        deleteFunction={() => {
          handleDelete(currentData?.id);
        }}
        label={currentData?.name}
        id={currentData?.id}
      />
      <Drawer
        modelTitle={currentData?.id ? "Edit Type" : "Add Type"}
        handleOpenModal={_toggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
        enableReinitialize
        DrawerBody={OrderType}
      />
      <div className="mt-4">
        <ReduxTable
          id="OrderType"
          showHeader
          searchPlaceholder="Search"
          apiURL={`${endpoints().orderTypeAPI}/search`}
          newTableHeading
          history={history}
          paramsToUrl={true}
          sortByOptions={sortByOption}
          onRowClick={(row) => {
            setCurrentData(row);
            // _toggle();
          }}
        >
          <ReduxColumn
            type="link"
            field="name"
            sortBy="name"
            isClickable="true"
            renderField={(row) => (
              <Link to={`/admin/settings/orderType/${row.id}`}>{row.name}</Link>
            )}
          >
            Name
          </ReduxColumn>
         
          <ReduxColumn
            field="defaultLocation"
            sortBy="default_location"
            isClickable="true"
            className="text-center"
          >
            Default Location
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            width="70px"
            renderField={(row) => (
              <>
                <div className="text-center action-group-dropdown">
                  <MoreDropdown>
                    
                    <DropdownItem
                      onClick={() => {
                        setCurrentData(row);
                        _toggle();
                      }}
                    >
                      Quick View
                    </DropdownItem>
                    <DropdownItem
                      className={"text-danger"}
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setCurrentData(row);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </MoreDropdown>
                </div>
              </>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </div>
  );
};
export default OrderType;
