import React from 'react';
import { endpoints } from '../../api/endPoints';
import PageTitle from '../../components/PageTitle';
import ReduxTable, { ReduxColumn } from '../../components/reduxTable';
import Permission from '../../helpers/Permission';
import Currency from '../../lib/Currency';
import { hasPermission } from '../../services/UserRolePermissionService';
import UserMonthWiseCard from './components/UserMonthWiseCard';
import BreadCrumb from '../../components/Breadcrumb';

const OrderReportUserMonthWise = (props) => {
    const { history } = props;

    let manageOthers = hasPermission(Permission.ORDER_MANAGE_OTHERS);
    const sortByOption = [
        {
            value: 'name:ASC',
            label: 'Name',
        },
        {
            value: 'amount:ASC',
            label: 'Amount',
        },
    ];

    const breadcrumbList = [
        { label: "Home", link: "/locationDashboard" },
        {
          label: "Reports",
          link: "/report",
        },
        {
          label: "Order Report - User Month Wise",
          link: "",
        },
      ];

    return (
        <>
            <BreadCrumb list={breadcrumbList} />
            <PageTitle label="Order Report - User Month Wise" />
            <ReduxTable
                id={'orderReportUserMonthWise'}
                apiURL={`${endpoints().orderAPI}/orderReportUserMonthWise`}
                params={{

                }}
                sortByOptions={sortByOption}
                history={props?.history}
                paramsToUrl={true}
                showHeader
                searchPlaceholder="Search"
                newTableHeading
                message="You can start by clicking Add New"
                showUserFilter={manageOthers}
                showShiftFilter
                showCustomDateFilter
            >
                <ReduxColumn
                    field='name'
                    sortBy='name'
                    renderField={(row) => (
                        <>
                            <UserMonthWiseCard
                                data={row}
                                manageOthers={manageOthers}
                            />
                        </>
                    )}
                >
                    User
                </ReduxColumn>
                <ReduxColumn
                    field='amount'
                    sortBy='amount'
                    className="text-end"
                    renderField={(row) => (
                        <>
                            {Currency.GetFormatted(row?.total_order_amount)}
                        </>
                    )}
                >
                    Amount
                </ReduxColumn>
            </ReduxTable>
        </>
    );
};
export default OrderReportUserMonthWise;
