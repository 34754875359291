import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { DropdownItem } from "reactstrap";
import styled from "styled-components";

// Components
import Action from "../../components/Action";
import BreadCrumb from "../../components/Breadcrumb";
import Form from "../../components/Form";
import SaveButton from "../../components/SaveButton";
import UserSelect from "../../components/UserSelect";
import VerticalSapce from "../../components/VerticalSapce";
import MoreDropdown from "../../components/authentication/moreDropdown";
import LeaveList from "./components/LeaveList";
import ShareModal from "./components/ShareModal";
import StoreNotAssignedUserList from "./components/StoreNotAssignedUserList";

// Services
import LocationAllocationService from "../../services/LocationAllocationService";
import LocationAllocationUserService from "../../services/LocationAllocationUserService";
import ShiftService from "../../services/ShiftService";
import StoreService from "../../services/StoreService";
import CompanyUserService from "../../services/UserService";

// Helpers
import Status from "../../helpers/LocationAllocationUser";

// Lib
import { fetchList } from "../../actions/table";
import { endpoints } from "../../api/endPoints";
import AvatarCard from "../../components/AvatarCard";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import StatusComponent from "../../components/Status";
import ObjectName from "../../helpers/ObjectName";
import { Setting } from "../../helpers/Setting";
import ArrayList from "../../lib/ArrayList";
import DateTime from "../../lib/DateTime";
import { getKeyValueByObject } from "../../lib/Helper";
import { getCompanySettings } from "../../services/SettingService";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";
import AddModal from "../../components/Modal";
import TextArea from "../../components/TextArea";

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #292934;
    color: white;
    font-weight: bold;
    text-align: center;
    position: sticky;
    top: 0;
  }
  td {
    background-color: #fff;
    color: #333;
  }
`;

const DropdownList = styled.div`
  position: relative;
  z-index: 10;
`;

const LocationAllocationDetailPage = (props) => {
  const [shiftList, setShiftList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [detail, setDetail] = useState([]);
  const [leaveUserIds, setLeaveUserIds] = useState([]);
  const [userList, setUserList] = useState([]);
  const [locationAllocationDetail, setLocationAllocationDetail] = useState({});
  const [allowedShiftIds, setAllowedShiftIds] = useState([]);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [rowValue, setRowValue] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [notesRowValue, setNotesRowValue] = useState(null)

  const userRef = useRef({ alloredRoles: "", userLists: [] });
  let allowedRole = userRef.current.alloredRoles;
  let userLists = userRef.current.userLists;
  let hasEditPermission = hasPermission(Permission.LOCATION_ALLOCATION_EDIT);
  let dispatch = useDispatch();
  useEffect(() => {
    getShifts();
    getLocations();
    getUsers();
    getLocationAllocationDetail();
    getSettings();
  }, []);
  useEffect(() => {
    getUsers();
  }, [allowedRole]);

  useEffect(() => {
    getDetail();
  }, [locationAllocationDetail?.date]);
  const getLocationAllocationDetail = async () => {
    await LocationAllocationService.get(
      props?.match?.params?.id,
      ({ data }) => {
        setLocationAllocationDetail(data);
      }
    );
  };
  const getSettings = async () => {
    const settingData = await getCompanySettings();
    let allowedRole = getKeyValueByObject(
      settingData,
      Setting.LOCATION_ALLOCATION_ALLOWED_ROLES
    );
    userRef.current.alloredRoles = allowedRole;
  };
  const getShifts = async () => {
    let {
      data: { data },
    } = await ShiftService.list();
    setShiftList(data);
  };
  const getLocations = async () => {
    let params = {
      type: 3,
    };
    await StoreService.list((res) => {
      let shiftIds = [];
      if (res && res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          const { allowedShift } = res[i];
          let allowedShiftIds = allowedShift ? allowedShift?.split(",") : [];
          shiftIds.push(...allowedShiftIds);
        }
      }
      setAllowedShiftIds(shiftIds);
      setLocationList(res);
    }, params);
  };
  const getUsers = async () => {
    let params = {};
    if (userRef && userRef.current && userRef.current.alloredRoles !== "") {
      params.role = userRef.current.alloredRoles;
      const users = await CompanyUserService.getOption(params);
      setUserList(users);
      userRef.current.userLists = users;
    }
  };
  const getDetail = async () => {
    if (locationAllocationDetail && locationAllocationDetail?.date) {
      let response = await LocationAllocationUserService.search({
        location_allocation_id: props?.match?.params?.id,
        date: locationAllocationDetail && locationAllocationDetail?.date,
      });
      let data = [];
      if (response && response?.data && response?.data.length > 0) {
        for (let i = 0; i < response?.data.length; i++) {
          const value = response?.data[i];
          data.push(value);
        }
      }
      setDetail(data);
      setLeaveUserIds(response && response?.leaveUserIds);
    }
  };
  let initialValues = (location, shift) => {
    let isDetail =
      (ArrayList.isArray(detail) &&
        detail.find(
          (data) => data.location_id == location.id && data.shift_id == shift.id
        )) ||
      null;
    let userValue =
      (ArrayList.isArray(userList) &&
        userList.find((data) => data?.id == isDetail?.user_id)) ||
      null;
    return {
      user: userValue,
    };
  };
  let isRecord = (location, shift) => {
    let isDetail =
      (ArrayList.isArray(detail) &&
        detail.find(
          (data) =>
            data.location_id == location.id &&
            data.shift_id == shift.id &&
            data.user_id !== null
        )) ||
      null;
    return isDetail ? true : false;
  };
  let handleStatusChange = async (location, shift, status, selectedUser) => {
    let data = new FormData();
    data.append("location_allocation_id", props?.match?.params?.id);
    data.append("location_id", location?.id);
    data.append("shift_id", shift?.id);
    data.append("status", status);
    data.append("user_id", selectedUser ? selectedUser : null);
    dispatch(
      await LocationAllocationUserService.updateStatus(data, (res) => {
        getDetail();
        getUsers();
      })
    );
  };
  const handleUserSelect = async (location, shift, selectedUser) => {
    let data = new FormData();
    data.append("location_allocation_id", props?.match?.params?.id);
    data.append("location_id", location?.id);
    data.append("shift_id", shift?.id);
    data.append("user_id", selectedUser ? selectedUser : null);
    dispatch(
      await LocationAllocationUserService.create(data, (res) => {
        getDetail();
        getUsers();
        setIsOpenModel(false);
        setRowValue({});
      })
    );
  };

  const handleUserRemove = async (location, shift, selectedUser) => {
    let data = new FormData();
    data.append("location_allocation_id", props?.match?.params?.id);
    data.append("location_id", location?.id);
    data.append("shift_id", shift?.id);
    data.append("user_id", selectedUser ? selectedUser : null);
    dispatch(
      await LocationAllocationUserService.update(data, (res) => {
        getDetail();
        getUsers();
        setIsOpenModel(false);
        setRowValue({});
      })
    );
  };

  const handleResetToDefault = async () => {
    dispatch(
      await LocationAllocationUserService.resetToDefault(
        props?.match?.params?.id,
        (res) => {
          if (res) {
            getDetail();
            setIsOpenModel(false);
          }
          dispatch(
            fetchList(
              "leaveList",
              `${endpoints().LocationAllocationUserAPI}/leaveList`,
              1,
              25,
              {
                date: locationAllocationDetail?.date,
                role_id: userRef?.current?.alloredRoles,
              }
            )
          );
        }
      )
    );
  };

  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    {
      label: "Location Allocation",
      link: "/locationAllocation",
    },
    {
      label: "Location Allocation Detail",
      link: "",
    },
  ];
  let isValidate = (location, shift) => {
    let isAllowedShift = location?.allowedShift?.includes(
      shift?.id?.toString()
    );
    return isAllowedShift;
  };
  // Helper function to group details by location_id and shift_id
  const groupDetailByLocationAndShift = (detail) => {
    return detail.reduce((acc, item) => {
      const key = `${item.location_id}-${item.shift_id}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item.user_id);
      return acc;
    }, {});
  };

  // Use the helper function to create a grouped detail object
  const groupedDetail = groupDetailByLocationAndShift(detail);

  let avatarCardProps = (location, shift) => {
    // Create a key based on location_id and shift_id
    const key = `${location.id}-${shift.id}`;

    // Get the list of user IDs for the given location and shift
    const userIds = groupedDetail[key] || [];

    let userValue =
      Array.isArray(userLists) && Array.isArray(userIds)
        ? userList.filter((data) => userIds.includes(data?.id))
        : [];
    return userValue.map((user) => ({
      firstName: user?.first_name,
      lastName: user?.last_name,
      url: user?.media_url,
      id: user?.id,
      isMismatch:
        user.primary_shift_id !== shift.id ||
        user.primary_location_id !== location.id,
    }));
  };
  let statusProps = (location, shift, user) => {
    let isDetail =
      (ArrayList.isArray(detail) &&
        detail.find(
          (data) =>
            data.location_id == location.id &&
            data.shift_id == shift.id &&
            data.user_id == user?.id
        )) ||
      null;
    let color =
      isDetail && isDetail?.status == Status.STATUS_PENDING
        ? "red"
        : isDetail && isDetail?.status == Status.STATUS_CONFIRMED
        ? "green"
        : "";
    let statusName =
      isDetail && isDetail?.status == Status.STATUS_PENDING
        ? "Pending"
        : isDetail && isDetail?.status == Status.STATUS_CONFIRMED
        ? "Confirmed"
        : "";
    return { backgroundColor: color, status: statusName };
  };

  let isPendingStatus = (location, shift, user) => {
    let isDetail =
      (ArrayList.isArray(detail) &&
        detail.find(
          (data) =>
            data.location_id == location.id &&
            data.shift_id == shift.id &&
            data.user_id == user?.id
        )) ||
      null;
    let isPending =
      isDetail && isDetail?.status == Status.STATUS_PENDING ? true : false;
    return isPending;
  };

  const closeToggle = () => {
    setIsOpenModel(!isOpenModel);
    setRowValue({});
  };

  const finalUnassignedList =
    (Array.isArray(leaveUserIds) &&
      userList.filter(
        (user) =>
          !detail.some((data) => data.user_id === user.id) &&
          !leaveUserIds.includes(user.id)
      )) ||
    [];

  const unAssignedUsers =
    (Array.isArray(leaveUserIds) &&
      userList.filter(
        (user) =>
          !detail.some(
            (data) =>
              data.shift_id === rowValue?.shift?.id && data.user_id === user.id
          ) && !leaveUserIds.includes(user.id)
      )) ||
    [];

  let modalBody = (
    <>
      <UserSelect required customUserOption={unAssignedUsers} />
    </>
  );
  let modalFooter = (
    <>
      <SaveButton label={rowValue?.isAdd ? "Add" : "Change"} />
    </>
  );
  const filteredShifts =
    ArrayList.isArray(shiftList) &&
    shiftList?.filter((shift) =>
      allowedShiftIds?.includes(shift?.id?.toString())
    );

  let actionsMenuList = [
    {
      label: "Refresh",
      value: "Refresh",
    },
    {
      label: "Send Notification",
      value: "Send Notification",
    },
    {
      label: "Export",
      value: "Export",
    },
  ];

  const handleActionChange = async (actionValue) => {
    if (actionValue == "Refresh") {
      handleResetToDefault();
    }
    if (actionValue == "Export") {
      setIsClicked(true);
    }
    if (actionValue == "Send Notification") {
      let data = new FormData();
      data.append("location_allocation_id", props?.match?.params?.id);
      data.append("date", locationAllocationDetail?.date);

      await LocationAllocationService.sendNotification(data);
    }
  };

  const handleSendIndivitualNotification = async (location, shift, user) => {
    let data = new FormData();
    data.append("location_allocation_id", props?.match?.params?.id);
    data.append("location_id", location?.id);
    data.append("shift_id", shift?.id);
    data.append("user_id", user);
    data.append("date", locationAllocationDetail?.date);

    await LocationAllocationService.sendNotification(data);
  };

  const toggle=()=>{
    setIsModalOpen(false)
    setNotesRowValue(null)
      }

  const handleNotes = async (values) => {
    let data = new FormData();
    data.append("location_allocation_id", props?.match?.params?.id);
    data.append("location_id", notesRowValue?.location);
    data.append("shift_id", notesRowValue?.shift);
    data.append("user_id", notesRowValue?.user);
    data.append("notes", values?.notes);

    await LocationAllocationUserService.updateNotes(data,(res)=>{
      if(res){
        getDetail();
        toggle()
      }

    });
  };

  const handlStatusChange = async (value) => {
    await LocationAllocationService.updateStatus(
      locationAllocationDetail?.id,
      { status: value },
      (res) => {
        if (res) {
          getLocationAllocationDetail();
        }
      }
    );
  };


 

  let modalForm = (
    <>
      <TextArea
        name="notes"
        label="Notes"
        placeholder="Enter Notes"
      />
    </>
  );

  let ModalFooter = (
    <>
      <SaveButton label={isAdd ? "Add": "Save"} />
    </>
  );

  let returnNotesValue =(location,shift,user)=>{
    let isDetail =
    (ArrayList.isArray(detail) &&
      detail.find(
        (data) =>
          data.location_id == location.id &&
          data.shift_id == shift.id &&
          data.user_id == user?.id
      )) ||
    null;
    
    return (isDetail && isDetail?.notes?.trim()) ? isDetail?.notes :""
  }

  return (
    <>
        <AddModal
        isOpen={isModalOpen}
        toggle={toggle}
        toggleModalClose={toggle}
        modalTitle={`${isAdd ?  "Add" : "Update"} Notes`}
        modalBody={modalForm}
        modalFooter={ModalFooter}
        initialValues={{
          notes: notesRowValue?.notes ? notesRowValue?.notes :"",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleNotes(values)
        }}
        hideDefaultButtons
      />
      <Drawer
        modelTitle={rowValue?.isAdd ? "Add User" : "Change User"}
        DrawerBody={modalBody}
        DrawerFooter={modalFooter}
        onSubmit={(values) => {
          handleUserSelect(
            rowValue?.location,
            rowValue?.shift,
            values && values?.user?.id
          );
        }}
        initialValues={{
          user: "",
        }}
        handleOpenModal={closeToggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpenModel}
        enableReinitialize
      />

      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between">
        <div>
          <PageTitle
            title={`Date: ${DateTime.getDate(locationAllocationDetail?.date)}`}
          />
        </div>
        <div className="d-flex pb-5">
          <div className="ps-2">
            <StatusComponent
              objectName={ObjectName.LOCATION_ALLOCATION}
              handleChange={handlStatusChange}
              buttonLabel={locationAllocationDetail?.statusName}
              currentStatusId={locationAllocationDetail?.statusId}
            />
          </div>
          <div className="ps-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
          <div className="ps-2">
            {detail && detail.length > 0 && isClicked && (
              <div className="ms-2">
                <ShareModal
                  locationList={locationList}
                  filteredShifts={filteredShifts}
                  date={locationAllocationDetail?.date}
                  isRecord={isRecord}
                  detail={detail}
                  userList={userList}
                  visible={isVisible}
                  onClose={() => setIsVisible(false)}
                  isClicked={isClicked}
                  setIsClicked={setIsClicked}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <TableContainer>
        <div className="mt-2">
          <Table>
            <thead>
              <tr>
                <th></th>
                {ArrayList.isArray(filteredShifts) &&
                  filteredShifts.map((shift, index) => (
                    <th key={index}>{shift?.name}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {ArrayList.isArray(locationList) &&
                locationList.map((location, rowIndex) => (
                  <tr key={rowIndex}>
                    <td
                      style={{
                        backgroundColor: "#292934",
                      }}
                      className="text-white fw-bold text-start"
                    >
                      {location?.label}
                    </td>
                    {ArrayList.isArray(filteredShifts) &&
                      filteredShifts.map((shift, colIndex) => (
                        <td key={colIndex}>
                          {isValidate(location, shift) && (
                            <Form
                              enableReinitialize={true}
                              initialValues={initialValues(location, shift)}
                            >
                              <div
                                className={
                                  !isRecord(location, shift)
                                    ? "text-center"
                                    : "text-end"
                                }
                              >
                                <Link
                                  to="#"
                                  className={`link-opacity-75 ${
                                    !isRecord(location, shift)
                                      ? "text-red"
                                      : "text-secondary"
                                  } text-decoration-none`}
                                  onClick={() => {
                                    setIsOpenModel(true),
                                      setRowValue({
                                        location,
                                        shift,
                                        isAdd: true,
                                      });
                                  }}
                                >
                                  <i className="fa fa-plus" />
                                </Link>
                              </div>
                              {isRecord(location, shift) && (
                                <div className="d-flex justify-content-center">
                                  <div className="col" style={{ flexGrow: 1 }}>
                                    {avatarCardProps(location, shift) &&
                                      avatarCardProps(location, shift).map(
                                        (user, index) => (
                                          <div
                                            key={index}
                                            style={{ paddingTop: 2 }}
                                            className="align-items-center"
                                          >
                                            <Link
                                              to={`/user/${user?.id}`}
                                              className="link-opacity-75"
                                            >
                                              <AvatarCard
                                                firstName={`${user?.firstName}`}
                                                lastName={user?.lastName}
                                                url={user?.url}
                                                size="customSize"
                                                imageSize="30"
                                                status={
                                                  user &&
                                                  user?.id &&
                                                  isPendingStatus(
                                                    location,
                                                    shift,
                                                    user
                                                  )
                                                    ? "(Pending)"
                                                    : ""
                                                }
                                                notes={returnNotesValue(location,shift,user)}
                                                statusColor="red"
                                                className={
                                                  user?.isMismatch
                                                    ? "text-red d-table-cell align-middle"
                                                    : ""
                                                }
                                                index={`${location?.id}-${shift?.id}-${user?.id}-${index}`}
                                              />
                                            </Link>
                                          </div>
                                        )
                                      )}
                                  </div>
                                  <div className="col">
                                    <p className="m-0">
                                      <div className="d-flex align-items-center">
                                        <div className="col">{""}</div>
                                        <div>
                                          <div style={{ textAlign: "end" }}>
                                            <div className="action-group-dropdown">
                                              {avatarCardProps(
                                                location,
                                                shift
                                              )?.map((user) => (
                                                <MoreDropdown key={user.id}>
                                                  {statusProps(
                                                    location,
                                                    shift,
                                                    user
                                                  ).status ===
                                                    Status.STATUS_PENDING_TEXT && (
                                                    <DropdownItem
                                                      onClick={() =>
                                                        handleStatusChange(
                                                          location,
                                                          shift,
                                                          Status.STATUS_CONFIRMED,
                                                          user?.id
                                                        )
                                                      }
                                                    >
                                                      Confirmed
                                                    </DropdownItem>
                                                  )}
                                                  {statusProps(
                                                    location,
                                                    shift,
                                                    user
                                                  ).status ===
                                                    Status.STATUS_CONFIRMED_TEXT && (
                                                    <DropdownItem
                                                      onClick={() =>
                                                        handleStatusChange(
                                                          location,
                                                          shift,
                                                          Status.STATUS_PENDING,
                                                          user?.id
                                                        )
                                                      }
                                                    >
                                                      Pending
                                                    </DropdownItem>
                                                  )}
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handleSendIndivitualNotification(
                                                        location,
                                                        shift,
                                                        user?.id
                                                      );
                                                    }}
                                                  >
                                                    Send Notification
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      setNotesRowValue({location: location?.id,shift: shift?.id,user : user?.id, notes: returnNotesValue(location,shift,user)})
                                                      setIsModalOpen(true)
                                                      setIsAdd(returnNotesValue(location,shift,user) ? false : true )
                                                    }}
                                                  >
                                                    {returnNotesValue(location,shift,user) ? "Edit Notes" :"Add Note"}
                                                  </DropdownItem>

                                                  {hasEditPermission && (
                                                    <DropdownItem
                                                      className="text-danger"
                                                      onClick={() => {
                                                        handleUserRemove(
                                                          location,
                                                          shift,
                                                          user?.id
                                                        );
                                                      }}
                                                    >
                                                      Remove
                                                    </DropdownItem>
                                                  )}
                                                </MoreDropdown>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Form>
                          )}
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <VerticalSapce bottom={3} />
        {locationAllocationDetail &&
          locationAllocationDetail?.date &&
          userRef?.current?.alloredRoles && (
            <LeaveList
              history={props.history}
              params={{
                date: locationAllocationDetail?.date,
                role_id: userRef?.current?.alloredRoles,
              }}
            />
          )}
        <StoreNotAssignedUserList filteredUserList={finalUnassignedList} />
      </TableContainer>
    </>
  );
};
export default LocationAllocationDetailPage;
