import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { FaStickyNote } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Avatar from "./Avatar";

class AvatarCard extends React.Component {
  render() {
    const {
      title,
      firstName,
      lastName,
      square,
      email,
      url,
      locationName,
      mobileNumber,
      className,
      status,
      statusColor,
      dateTime,
      typeName,
      purpose,
      notes,
      index
    } = this.props;
    const name = [firstName, lastName].filter(Boolean).join(" ");

    const handleMailClick = () => {
      window.location.href = `mailto:${email}`;
    };
    const handlePhoneClick = () => {
      window.location.href = `tel:${mobileNumber}`;
    };

    return (
      <div className="d-table">
        {(name || title || url) && (
          <div className="d-table-row">
            {/* Avatar */}
            <div className="d-table-cell align-middle pe-2">
              <Avatar {...this.props} />
            </div>

            {/* User Details */}
            <div className={className || "d-table-cell align-middle"}>
              {dateTime && <h6 className="fw-normal">{dateTime}</h6>}
              {(title || name) && <h6 className="fw-normal">{title || name}</h6>}

              {email && (
                <h6>
                  <Link onClick={handleMailClick}>{email}</Link>
                </h6>
              )}

              {mobileNumber && (
                <h6>
                  <Link onClick={handlePhoneClick}>{mobileNumber}</Link>
                </h6>
              )}

              {locationName && <h6 className="fw-normal">{locationName}</h6>}
              {typeName && <h6 className="fw-normal">{typeName}</h6>}
              {purpose && <h6 className="fw-normal">{purpose}</h6>}
            </div>

            <div className="d-flex">
              {/* Status */}
              {status && (
                <h6 className={classNames("fw-normal mx-2", `text-${statusColor}`)}>
                  {status}
                </h6>
              )}

              {notes && (
                <div>
                  <FaStickyNote
                    data-tooltip-id={index}
                    size={10}
                    className="text-gray-700 hover:text-blue-500 cursor-pointer"
                  />

                  {/* Tooltip */}
                  <Tooltip id={index} place="top" content={notes} style={{ maxWidth: "300px", whiteSpace: "normal" }} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

AvatarCard.propTypes = {
  url: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  defaultUrl: PropTypes.string,
  size: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  square: PropTypes.bool,
  customSize: PropTypes.number,
  email: PropTypes.string,
  mobileNumber: PropTypes.string,
  locationName: PropTypes.string,
  dateTime: PropTypes.string,
  typeName: PropTypes.string,
  purpose: PropTypes.string,
  status: PropTypes.string,
  statusColor: PropTypes.string,
  notes: PropTypes.bool,
};

export default AvatarCard;
