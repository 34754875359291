import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import Url from "../../helpers/Url";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";

const PeoplesReportList = () => {
  const [nameToggle, setNameToggle] = useState(true);
  let showAttendanceSummaryReport = hasPermission(Permission.ATTENDANCE_SUMMARY_REPORT_VIEW)
  let showAttendanceReport = hasPermission(Permission.ATTENDANCE_REPORT_VIEW)
  let showFineReport = hasPermission(Permission.FINE_REPORT_VIEW)

  // store report data
  const reportAttendence = []
  if(showAttendanceSummaryReport){
    reportAttendence.push( {
      title: "Attendance Summary Report",
      link: Url.ATTENDENCE_SUMMARY_REPORT,
    });

  }
  if(showAttendanceReport){
    reportAttendence.push( {
      title: "Attendance Report",
      link: Url.ATTENDENCE_REPORT,
    });
  }
  if(showFineReport){
    reportAttendence.push( {
      title: "Fine Report",
      link: Url.FINE_REPORT,
    })
  }

  return (
    <>
      <PageTitle label="Reports" />
      <div className="accordion mt-3" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={`accordion-button bg-body-tertiary ${nameToggle ? '' : 'collapsed'}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded={nameToggle ? "true" : "false"}
              aria-controls="collapseOne"
              onClick={() => setNameToggle(!nameToggle)}
            >
              <h5 className="fw-normal">Attendance</h5>
            </button>
          </h2>
          <div
            id="collapseOne"
            className={`accordion-collapse collapse ${nameToggle ? 'show' : ''}`}
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  {reportAttendence.map((dataList, index) => (
                    <div key={index}>
                      <Link to={dataList.link} className="my-2 text-dark text-decoration-none">
                        {dataList.title}
                      </Link>
                      <br />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PeoplesReportList;
