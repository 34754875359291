

export const TICKET_FIELD_TEST = 1
export const TICKET_FIELD_TASK = 2
export const TICKET_FIELD_POA = 3
export const TICKET_FIELD_TEST_CASE_CREATION_TIME = 4
export const TICKET_FIELD_TEST_CASE_EXECUTION_TIME = 5
export const TICKET_FIELD_PROJECT = 6
export const TICKET_FIELD_TYPE = 7
export const TICKET_FIELD_STATUS = 8
export const TICKET_FIELD_SEVERITY = 9
export const TICKET_FIELD_COMPONENT = 10
export const TICKET_FIELD_LABEL = 11
export const TICKET_FIELD_AFFECTED_VERSION = 12
export const TICKET_FIELD_PRIORITY = 13
export const TICKET_FIELD_RELEASE_VERSION = 14
export const TICKET_FIELD_SPRINT = 15
export const TICKET_FIELD_DESCRIPTION = 16
export const TICKET_FIELD_USER_IMPACT = 17
export const TICKET_FIELD_PRODUCTION_STATUS = 18
export const TICKET_FIELD_ACCEPTANCE_CRITERIA = 19
export const TICKET_FIELD_APPLICABLE_DEVICES = 20
export const TICKET_FIELD_ENVIRONMENTS = 21
export const TICKET_FIELD_BUILD = 22
export const TICKET_FIELD_TEST_STEPS = 23
export const TICKET_FIELD_ACTUAL_RESULTS = 24
export const TICKET_FIELD_EXPECTED_RESULTS = 25
export const TICKET_FIELD_REFERENCE_SCREENSHOTS = 26
export const TICKET_FIELD_SUB_TASKS = 27
export const TICKET_FIELD_REPORTER = 28
export const TICKET_FIELD_ASSIGNEE = 29
export const TICKET_FIELD_REVIEWER = 30
export const TICKET_FIELD_DUE_DATE = 31
export const TICKET_FIELD_DUE_DATE_TIME = 32
export const TICKET_FIELD_INITIAL_DUE_DATE = 33
export const TICKET_FIELD_CREATED_AT = 34
export const TICKET_FIELD_UPDATED_AT = 35
export const TICKET_FIELD_JIRA_CREATED_AT = 36
export const TICKET_FIELD_COMPLETED_AT = 37
export const TICKET_FIELD_ESTIMATED_HOURS = 38
export const TICKET_FIELD_STORY_POINTS = 39
export const TICKET_FIELD_DELIVERY_DATE = 40
export const TICKET_FIELD_CUSTOMER_DELIVERY_DATE = 41
export const TICKET_FIELD_TICKET_ID = 42
export const TICKET_FIELD_PARENT_TICKET_ID = 43
export const TICKET_FIELD_JIRA_TICKET_ID = 44
export const TICKET_FIELD_UPDATE_TICKET_ID_WITH_JIRA_ID = 45
export const TICKET_FIELD_JIRA_ASSIGNEE = 46
export const TICKET_FIELD_ATTACHMENT_PAGE_NAME = 47
export const TICKET_FIELD_ATTACHMENT_PLATFORM = 48
export const TICKET_FIELD_ATTACHMENT_SUMMARY = 49
export const TICKET_FIELD_SUMMARY = 50
export const TICKET_FIELD_ATTACHMENT_IMAGE = 51
export const TICKET_FIELD_DEVELOPER = 52
export const TICKET_FIELD_TESTER = 53
export const TICKET_FIELD_DATE = 54
export const TICKET_FIELD_COMMENT = 55
export const TICKET_FIELD_TEST_CASES = 56
export const TICKET_FIELD_HISTORY = 57
export const TICKET_FIELD_FROM_LOCATION = 58
export const TICKET_FIELD_TO_LOCATION = 59
export const TICKET_FIELD_VOICE_NOTES = 60
