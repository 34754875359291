import React from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormikForm } from "formik";

class Form extends React.Component {
  handleKeyDown = (event) => {
    // Prevent form submission when Enter is pressed
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  render() {
    const { initialValues, enableReinitialize, onSubmit, children, id } =
      this.props;

    return (
      <div style={{ width: this.props.width ? this.props.width : "" }}>
        <Formik
          id={id || children}
          initialValues={initialValues}
          enableReinitialize={enableReinitialize}
          onSubmit={(values, formikBag) => {
            onSubmit && onSubmit(values, formikBag.resetForm);
          }}
        >
          {() => (
            <FormikForm onKeyDown={this.handleKeyDown}>
              {children}
            </FormikForm>
          )}
        </Formik>
      </div>
    );
  }
}

Form.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isReset: PropTypes.bool,
};

Form.defaultProps = {
  isReset: false,
};

export default Form;
