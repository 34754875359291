import React, { useEffect, useState } from "react";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import TagSelect from "../../components/TagSelect";
import DefaultContent from "../../components/content/defaultContent";
import { Setting } from "../../helpers/Setting";
import { TagTypeName } from "../../helpers/Tag";
import ArrayList from "../../lib/ArrayList";
import { getKeyValueByObject } from "../../lib/Helper";
import { getCompanySettings, saveSetting } from "../../services/SettingService";

const Replenishment = () => {
  const [settings, setSettings] = useState({});
  const [bonusTypeList, setBonusTypeList] = useState([]);
  const [fineTypeList, setFineTypeList] = useState([]);


  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    setSettings(settingData);
  };


  const replenishmentByOption = [
    { label: "Stock", value: 1 },
    { label: "Order", value: 2 },
    { label: "Order Average", value: 3 },
  ];

  const initialValues = {
    replenishment_by:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      replenishmentByOption.find(
        (data) =>
          data.value == getKeyValueByObject(settings, Setting.REPLENISHMENT_BY)
      ),
      [Setting.REPLENISHMENT_MISSING_FINE_TYPE]:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      fineTypeList.find(
        (data) =>
          data.value == getKeyValueByObject(settings, Setting.REPLENISHMENT_MISSING_FINE_TYPE)
      ),
      [Setting.REPLENISHMENT_EXTRA_BONUS_TYPE]:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      bonusTypeList.find(
        (data) =>
          data.value == getKeyValueByObject(settings, Setting.REPLENISHMENT_EXTRA_BONUS_TYPE)
      ),
  };

  const handleSubmit = (values) => {
    const data = new FormData();
    if (values && values.replenishment_by !== undefined) {
      data.append(
        Setting.REPLENISHMENT_BY,
        values.replenishment_by ? values.replenishment_by.value : ""
      );
    }

    if (values && values.replenishment_missing_fine_type !== undefined) {
      data.append(
        Setting.REPLENISHMENT_MISSING_FINE_TYPE,
        values.replenishment_missing_fine_type ? values.replenishment_missing_fine_type.value : ""
      );
    }

    if (values && values.replenishment_extra_bonus_type !== undefined) {
      data.append(
        Setting.REPLENISHMENT_EXTRA_BONUS_TYPE,
        values.replenishment_extra_bonus_type ? values.replenishment_extra_bonus_type.value : ""
      );
    }
    // Save settings
    saveSetting(data, null, () => {
      getSettings();
    });
  };

  return (
    <>
      <PageTitle label="Replenishment" />

      <DefaultContent>
        <Form
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <div className="row field-wrapper ">
            <div className="col-lg-6 col-sm-6">
              <Select
                name="replenishment_by"
                label="Replenishment By"
                options={replenishmentByOption}
              />
            </div>
          </div>
          <div className="row field-wrapper ">
            <div className="col-lg-6 col-sm-6">
            <TagSelect
              name={Setting.REPLENISHMENT_MISSING_FINE_TYPE}
              label="Replenish Missing Count Fine Type"
              params={{ type: TagTypeName.FINE_TYPE }}
              TagList={setFineTypeList}
              defaultValue={getKeyValueByObject(settings, Setting.REPLENISHMENT_MISSING_FINE_TYPE)}
            />
            </div>
          </div>
          <div className="row field-wrapper ">
            <div className="col-lg-6 col-sm-6">
            <TagSelect
              name={Setting.REPLENISHMENT_EXTRA_BONUS_TYPE}
              label="Replenishment Extra Count Bonus Type"
              params={{ type: TagTypeName.BONUS_TYPE }}
              TagList={setBonusTypeList}
              defaultValue={getKeyValueByObject(settings, Setting.REPLENISHMENT_EXTRA_BONUS_TYPE)}
            />
            </div>
          </div>

          <SaveButton label={"Save"} />
        </Form>
      </DefaultContent>
    </>
  );
};

export default Replenishment;
