import React from "react";
import { Navbar } from "reactstrap";
import * as commonConstant from "../../common/constants";
import Logo from "../Logo";

const PublicHeader = ({
  portalName,
  headerColor,
  headerTextColor,
  portalLogo,
  portalLogoRedirectUrl,
}) => {
  portalLogo = portalLogo ? portalLogo : "";

  return (
    <div>
      <Navbar
        className={`navbar navbar-expand-lg text-white`}
        light
        expand="md"
        style={{
          backgroundColor: headerColor
            ? headerColor
            : commonConstant.DEFAULT_HEADER_COLOR,
          color: headerTextColor,
        }}
      >
        <div className={["container-fluid d-flex justify-content-between align-items-center"].join(" ")}>
          <Logo
            src={portalLogo}
            link={portalLogoRedirectUrl}
            altText={portalName}
            label={portalName}
          />
        </div>
      </Navbar>
    </div>
  );
};

export default PublicHeader;
