import React from "react";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

// Components
import Form from "../../components/Form";
import Url from "../../components/Url";
import DefaultContent from "../../components/content/defaultContent";
import SaveButton from "../../components/SaveButton";

// Social Themes Tab
export const SocialLinks = {
  SOCIAL_LINK_FACEBOOK_URL: "facebook_url",
  SOCIAL_LINK_INSTAGRAM_URL: "instagram_url",
  SOCIAL_LINK_TWITTER_URL: "twitter_url",
  SOCIAL_LINK_LINKEDIN_URL: "linkedIn_url",
  SOCIAL_LINK_YOUTUBE_URL: "youtube_url",
};

const SocialLinksTab = (props) => {
  const {
    faceBookFun,
    instagramFun,
    linkedInFun,
    twitterFun,
    youTubFun,
    handleUpdate,
    initialValue,
    history,
  } = props;

  return (
    <>
      <Form
        enableReinitialize={true}
        initialValues={{ ...initialValue }}
        onSubmit={(values) => handleUpdate(values)}
      >
        <div className="d-flex">
          <DefaultContent>
            <div className="row">
              {/* Facebook URL */}
              <div className="col-lg-12 col-sm-12">
                <Url
                  id={SocialLinks.SOCIAL_LINK_FACEBOOK_URL}
                  name={SocialLinks.SOCIAL_LINK_FACEBOOK_URL}
                  placeholder="Enter Facebook URL"
                  onChange={faceBookFun}
                  showIcon
                  icon={faFacebook}
                />
              </div>
            </div>

            {/* Instagram URL */}
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <Url
                  id={SocialLinks.SOCIAL_LINK_INSTAGRAM_URL}
                  name={SocialLinks.SOCIAL_LINK_INSTAGRAM_URL}
                  placeholder="Enter Instagram URL"
                  onChange={instagramFun}
                  showIcon
                  icon={faInstagram}
                />
              </div>
            </div>

            {/* LinkedIn URL */}
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <Url
                  id={SocialLinks.SOCIAL_LINK_LINKEDIN_URL}
                  name={SocialLinks.SOCIAL_LINK_LINKEDIN_URL}
                  placeholder="Enter LinkedIn URL"
                  onChange={linkedInFun}
                  showIcon
                  icon={faLinkedin}
                />
              </div>
            </div>

            {/* Twitter URL */}
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <Url
                  id={SocialLinks.SOCIAL_LINK_TWITTER_URL}
                  name={SocialLinks.SOCIAL_LINK_TWITTER_URL}
                  placeholder="Enter Twitter URL"
                  onChange={twitterFun}
                  showIcon
                  icon={faTwitter}
                />
              </div>
            </div>

            {/* YouTube URL */}
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <Url
                  id={SocialLinks.SOCIAL_LINK_YOUTUBE_URL}
                  name={SocialLinks.SOCIAL_LINK_YOUTUBE_URL}
                  placeholder="Enter YouTube URL"
                  onChange={youTubFun}
                  showIcon
                  icon={faYoutube}
                />
              </div>
            </div>

            {/* Buttons */}
            <div className="btn-wrapper mt-3">
              <SaveButton />
            </div>
          </DefaultContent>
        </div>
      </Form>
    </>
  );
};

export default SocialLinksTab;
