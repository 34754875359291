import React, { useEffect } from "react";

const MessageInput = ({ setText, text = "", title }) => {
  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("Text"); // For plain text
    const pastedHTML = clipboardData.getData("Text/HTML"); // For HTML content
    setText(pastedHTML || pastedText); // Prioritize HTML if available
  };

  const handleChange = (e) => {
    // When content is changed, update the state with the new HTML
    setText(e.target.innerHTML);
  };

  useEffect(() => {
    const contentEditableDiv = document.getElementById("message-input");
    if (contentEditableDiv && text !== contentEditableDiv.innerHTML) {
      contentEditableDiv.innerHTML = text;
    }
  }, [text]);

  return (
    <div>
      <h2>{title}</h2>

      {/* ContentEditable Div to handle paste and change with styles */}
      <div
        id="message-input"
        contentEditable
        onPaste={handlePaste}
        onInput={handleChange} // Handle changes (typing, editing)
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          minHeight: "200px",
          whiteSpace: "pre-wrap",
          overflowY: "auto",
        }}
        placeholder="Paste something here..."
      />
    </div>
  );
};

export default MessageInput;
