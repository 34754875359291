import React, { useContext, useEffect, useState } from "react";
//Service
import { getSettingsValue } from "../../services/SettingService";
//API

//Defatult Icon
import DefaultPortalIcon from "../../assets/img/oneportalFavicon.png";
//Common
//Context
import UserProvider from "../../context/userContext/userProvider";
//Helpers
import {
  clearAllCookies,
  COOKIE_SESSION_TOKEN,
} from "../../lib/Cookie";
import Cookies, { isLoggedIn, setCookie } from "../../lib/Helper";
import { isNotEmpty } from "../../lib/String";

//Nav Header
import AppNav from "../AppNav";
//Components
import Footer from "../Footer";
import Header from "../header";
import OutsideAlerter from "../OutSideClickAlerter";
import SideBarNavigation from "../sidebarNavigation";
import Toastcontainer from "../ToastContainer";
import { DefaultColor } from "../../helpers/Color";
import UserContext from "../../context/userContext/userContext";
import notificationSound from "../../assets/notification.mp3";
import notificationIcon from "../../assets/icons/oneportal.png";
import { connectSocket, getSocketInstance } from "../../services/SocketService";


const AdminLayout = (props) => {
  const { settings, navList, history, showProjectSelector, projectNavList } =
    props;

  const [portalList, setPortalList] = useState([]);
  const [default_id, setDefaultProjectId] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const [companyDetail, setCompanyDetail] = useState(null);
  const [companyNames, setCompanyName] = useState();
  const { user } = useContext(UserContext);
  const [settingsData, setSettingData] = useState({});
  const defaultId = (values) => {

    setDefaultProjectId(values);
  };

    useEffect(() => {
      if (user && user?.userId) {
        let socket = getSocketInstance();
        if (!socket || !socket.connected) {
          socket = connectSocket(user?.userId);
        }
        if (!socket) return;
      const handleReceiveMessage = (data) => {
       if(data){
        showNotification("New Message", `You have a new message: ${data?.message}`);
       }
      };
      socket.on("receiveMessage", handleReceiveMessage);
      return () => {
        socket.off("receiveMessage", handleReceiveMessage);
      };
    } 
    }, [user]); 

  
    const showNotification = (title, body) => {
      if (Notification.permission === "granted") {
          new Notification(title, { body,
            icon:notificationIcon
           });
  
          // Play the imported sound
          let audio = new Audio(notificationSound);
          audio.play().catch(error => console.error("Error playing sound:", error));
  
      } else if (Notification.permission !== "denied") {
          Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                  new Notification(title, { body,
                    icon: notificationIcon
                   });
  
                  // Play the imported sound
                  let audio = new Audio(notificationSound);
                  audio.play().catch(error => console.error("Error playing sound:", error));
              }
          });
      }
  };
  isLoggedIn();
  // Login By Admin
  // const handlePortalChange = async (id) => {
  //   return apiClient
  //     .get(`${endpoints().companyAPI}/loginByAdmin/${id}`)
  //     .then(async (response) => {
  //       if (response && response.data) {
  //         const { token, role, userId, portalUrl, companyId } = response.data;
  //         await clearAllCookies();
  //         setCookie(COOKIE_SESSION_TOKEN, token);

  //         if (portalUrl) {
  //           window.location.replace(`${portalUrl}/dashboard`);
  //         } else {
  //           window.location.replace("/dashboard");
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       if (isBadRequest(error)) {
  //         let errorMessage;
  //         const errorRequest = error.response.request;
  //         if (errorRequest && errorRequest.response) {
  //           errorMessage = JSON.parse(errorRequest.response).message;
  //         }
  //         console.error(errorMessage);
  //       }
  //     });
  // };

  const getSetting = async (settings) => {
    const data = await getSettingsValue(settings);
    setSettingData(data);
  };

  // Get company list
  // const fetchCompanyList = async () => {
  // const data = await CompanyService.search();
  //   let list = [];
  //   data && data.length > 0 && data.forEach((companyList) => {
  //     list.push({
  //       value: companyList.id,
  //       label: companyList.company_name,
  //       id: companyList.id,
  //     });
  //   });
  //   setCompanyList(list)
  // };

  // Get company detail of login company
  // const fetchCompanyDetail = async () => {
  //   let data = await CompanyService.getCompanyDetail();
  //   if (data) {
  //     setCompanyName(data.company_name);
  //   }
  //   setCompanyDetail(data);
  // };

  // useEffect(() => {
  //   fetchCompanyList();
  //   fetchCompanyDetail();
  // }, []);

  useEffect(() => {
    if (isNotEmpty(settings.settings)) {
      getSetting(settings);
    }
  }, [settings]);

  const {
    headerColor,
    portalLogo,
    portalName,
    portalFavIcon,
    headerTextColor,
    footerColor,
    footerTextColor,
    footerCopyRightsText,
    leftNavigationBackgroundImage,
    leftNavigationTextColor,
    leftNavigationTextHoverColor,
    leftNavigationBackgroundColor,
    instagram_url,
    youtube_url,
    twitter_url,
    linkedIn_url,
    facebook_url,
    portalLogoUrl,
  } = settingsData;

  const logo = portalLogo ? portalLogo : "";
  const portalNames = portalName ? portalName : "";
  //Set PortalName
  let name = document.getElementById("portalName");
  if (companyNames) {
    name.innerHTML = companyNames;
  }

  // Company logo
  let companyLogoUrl = companyDetail?.company_logo;

  // Company Name
  let companyName = companyDetail?.company_name;

  let PortalName = settingsData?.portalName;

  useEffect(() => {
    let sessionToken = Cookies.get(COOKIE_SESSION_TOKEN);
    if (!sessionToken) {
      getCompanyDetails();
    }
  }, []);

  // const getCompanyDetails = async () => {
  //   const data = await CompanyService.CompanyDetail();
  //   setCompanyName(data.company_name);
  // };

  //SetPortal Favicon
  const favIcon = document.getElementById("portalFavIcon");

  if (companyLogoUrl && favIcon.href !== companyLogoUrl) {
    favIcon.href = companyLogoUrl;
  } else favIcon.href = DefaultPortalIcon;

  return (
    <div className="app">
      <UserProvider>
        <div className="app-body">
          <Toastcontainer />
          <main
            className="main drawer-container position-relative"
          >
            <div>
              <OutsideAlerter>
                <AppNav
                  leftNavigationBackgroundColor={leftNavigationBackgroundColor || DefaultColor.BACKGROUND}
                  leftNavigationTextColor={leftNavigationTextColor || DefaultColor.TEXT}
                  leftNavigationTextHoverColor={leftNavigationTextHoverColor|| DefaultColor.TEXT}

                />
              </OutsideAlerter>
              <Header
                headerColor={headerColor || DefaultColor.BACKGROUND}
                companyLogo={portalLogoUrl && portalLogoUrl}
                companyName={PortalName}
                headerTextColor={headerTextColor || DefaultColor.TEXT}
                // handlePortalChange={handlePortalChange}
                portalList={portalList}
                companyList={companyList}
                leftNavigationBackgroundColor={leftNavigationBackgroundColor || DefaultColor.BACKGROUND}
                leftNavigationTextColor={leftNavigationTextColor|| DefaultColor.TEXT}
                leftNavigationTextHoverColor={leftNavigationTextHoverColor || DefaultColor.TEXT}
              />
            </div>
            <SideBarNavigation
              projectId={default_id}
              showProjectSelector={showProjectSelector}
              history={history}
              navList={projectNavList ? projectsNavList : navList}
              settings={settingsData}
              leftNavigationBackgroundImage={leftNavigationBackgroundImage }
              leftNavigationTextColor={leftNavigationTextColor || DefaultColor.TEXT}
              leftNavigationTextHoverColor={leftNavigationTextHoverColor || DefaultColor.TEXT}
              leftNavigationBackgroundColor={leftNavigationBackgroundColor|| DefaultColor.BACKGROUND}
              defaultId={(e) => {
                defaultId(e);
              }}
            />
          </main>
        </div>
        <Footer
          footerColor={footerColor}
          footerTextColor={footerTextColor}
          footerCopyRightsText={footerCopyRightsText}
          facebookUrl={facebook_url}
          instagramUrl={instagram_url}
          linkedInUrl={linkedIn_url}
          twitterUrl={twitter_url}
          youtubeUrl={youtube_url}
          portalName={portalNames}
        />
      </UserProvider>
    </div>
  );
};

export default AdminLayout;
