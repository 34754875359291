import React, { useState } from "react";
import DragAndDropTable from "../../components/StatusTable/StatusDragAndDropTable";
import ObjectName from "../../helpers/ObjectName";
import PageTitle from "../../components/PageTitle";
import { Nav, NavItem, NavLink, TabPane } from "reactstrap";
import classNames from "classnames";
import { Tabs } from "../../helpers/Setting";
import SettingPage from "./components/settingPage.";
import AddButton from "../../components/AddButton";
import OrderTypeList from "./components/orderTypeList";
import Url from "../../lib/Url";

const OrderSetting = (props) => {
  let { history } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : Tabs.ORDERTYPE
  );
  const [row, setRow] = useState();
  const [currentData, setCurrentData] = useState({});

  const _toggle = () => {
    setIsOpen(!isOpen);
  };

  const closeToggle = () => {
    setIsOpen(!isOpen);
    setCurrentData({});
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <PageTitle label="Order" />
        {activeTab === Tabs.ORDERTYPE && (
          <AddButton
            className="mt-1"
            label="Add"
            onClick={(e) => {
              setIsOpen(true);
            }}
          />
        )}
      </div>
      <Nav tabs className="admin-tabs mb-3">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.ORDERTYPE,
            })}
            onClick={() => {
              handleTabChange(Tabs.ORDERTYPE);
            }}
          >
            {Tabs.ORDERTYPE}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.SETTING,
            })}
            onClick={() => {
              handleTabChange(Tabs.SETTING);
            }}
          >
            {Tabs.SETTING}
          </NavLink>
        </NavItem>
      </Nav>
      {activeTab === Tabs.ORDERTYPE && (
        <TabPane>
          <OrderTypeList
            _toggle={_toggle}
            closeToggle={closeToggle}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            history={history}
            setCurrentData={setCurrentData}
            currentData={currentData}
          />
        </TabPane>
      )}
      {activeTab === Tabs.SETTING && (
        <TabPane>
          <SettingPage history={history} objectName={ObjectName.ORDER} />
        </TabPane>
      )}
    </>
  );
};

export default OrderSetting;
