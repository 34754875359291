import React, { useEffect, useState, useCallback } from "react";
import StatusService from "../services/StatusService";
import SelectDropdown from "./SelectDropdown";

const Status = (props) => {
  const {
    handleChange,
    objectName,
    buttonLabel,
    currentStatusId,
    projectId,
    color,
    disabled,
    allowed_statuses,
    customList
  } = props;

  const [list, setList] = useState([]);
  const [handleStatus, setHandleStatus] = useState(null);
  const [colorCode, setColorCode] = useState(null);

  // Memoize getStatusList to avoid unnecessary re-creation
  const getStatusList = useCallback(async () => {
    if (currentStatusId || handleStatus) {
      const data = await StatusService.nextStatusSearch(
        objectName,
        handleStatus || currentStatusId,
        null,
        allowed_statuses
      );
      
      if (data?.length > 0) {
        const sortedList = data
          .sort((a, b) => parseFloat(a.sort_order) - parseFloat(b.sort_order))
          .map((item) => ({
            value: item?.value,
            label: item?.label,
          }));
        setList(sortedList);
      }
    }
  }, [currentStatusId]);

  // Fetch status details only when currentStatusId changes
  useEffect(() => {
    const fetchStatusDetail = async () => {
      if (currentStatusId) {
        const response = await StatusService.get(currentStatusId);
        const data = response?.data;
        setColorCode(data?.colorCode);
      }
    };
    fetchStatusDetail();
  }, [currentStatusId]);

  // Fetch status list only when dependencies change
  useEffect(() => {
    getStatusList();
  }, [getStatusList]);

  // Trigger fetching of the list when dropdown gains focus
  const onFocus = () => {
    if (list.length === 0) {
      getStatusList();
    }
  };

  return (
    <SelectDropdown
      buttonLabel={buttonLabel || "Status"}
      hideCaret={true}
      dropdownLinks={customList? customList:list}
      handleChange={(e) => {
        setHandleStatus(e);
        handleChange(e);
      }}
      disabled={disabled || !list.length}
      onFocus={onFocus}
      colorCode={colorCode}
    />
  );
};

export default Status;
