import { apiClient } from "../apiClient";
import { endpoints } from "../api/endPoints";
import { fetchList } from "../actions/table";
import toast from "../components/Toast";
import { isBadRequest } from "../lib/Http";
import * as InventoryTransfer from "../actions/transferType";
import Url from "../lib/Url";
import ArrayList from "../lib/ArrayList";
class AccountTypeService {
  async get() {
    const response = apiClient.get(`${endpoints().accountTypeAPI}/search`);
    return response;
  }
  async list(params) {
    let queryString = await ArrayList.toQueryString(params);
    let response = await Url.get(`${endpoints().accountTypeAPI}`,queryString);
    return response && response.data;
  }
  async create(data, params, toggle) {
    return (dispatch) => {
      dispatch(InventoryTransfer.requestAddTransferTypes());
      return apiClient
        .post(`${endpoints().accountTypeAPI}/create`, data)
        .then((res) => {
          if (res && res.data) {
            // callback(true);
            toast.success("Account Type Added Successfully");
            toggle();
          }
        })
        .then(() => {
          dispatch(
            fetchList(
              "AccountType",
              `${endpoints().accountTypeAPI}/search`,
              1,
              25,
              params
            )
          );
        })
        .catch((err) => {
          dispatch(InventoryTransfer.addTransferTypesError());
          if (isBadRequest(err)) {
            let errorMessage;
            const errorRequest = err.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(errorMessage);
          }
        });
    };
  }
  async getDetails(id, callback) {
    apiClient
      .get(`${endpoints().accountTypeAPI}/${id}`)
      .then((response) => {
        return callback(response.data);
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = err.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  }
  async update(id, updateData, params, callback, dispatch) {
    apiClient
      .put(`${endpoints().accountTypeAPI}/${id}`, updateData)
      .then((response) => {
        toast.success(response.data.message);
        callback(response.data.message);
        return response;
      })
      .then(() => {
        return apiClient.get(`${endpoints().accountTypeAPI}/search`);
      })
      .then((response) => {
        dispatch(
          fetchList(
            "AccountType",
            `${endpoints().accountTypeAPI}/search`,
            1,
            25,
            params
          )
        );
      })
      .catch((error) => {
        dispatch(InventoryTransfer.addTransferTypesError());
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  }

  async delete(id, params) {
    return (dispatch) => {
      apiClient
        .delete(`${endpoints().accountTypeAPI}/${id}`)
        .then((response) => {
          let successMessage;

          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
          }
        })
        .then(() => {
          dispatch(
            fetchList(
              "AccountType",
              `${endpoints().accountTypeAPI}/search`,
              params.page ? params.page : 1,
              params.pageSize ? params.pageSize : 25,
              params
            )
          );
        })

        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;

            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    };
  }
}
const accountTypeService = new AccountTypeService();
export default accountTypeService;
