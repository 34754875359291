import React, { useEffect, useState } from "react";

// Components
import CancelButton from "../../../components/CancelButton";
import Form from "../../../components/Form";
import SaveButton from "../../../components/SaveButton";
import Text from "../../../components/Text";
import DefaultContent from "../../../components/content/defaultContent";
import SingleCheckbox from "../../../components/SingleCheckbox";

// Helpers
import { Setting } from "../../../helpers/Setting";

// lib
import { getKeyValueByObject } from "../../../lib/Helper";
import String from "../../../lib/String";

// Services
import AppSettingService from "../../../services/AppSettingService";

const Settings = (props) => {
    const [settings, setSettings] = useState({});
    const [backgroundFetchIntervalValue, setBackgroundFetchIntervalValue] = useState();

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = async () => {
        //get company id from cookie
        let params = {
            appId: props.app_id
        };
        //get company setting
        const settingData = await AppSettingService.getAppThemeSetings(props.app_id, params);

        //set setting in state
        setSettings(settingData);
    };

    const handleBackgroundFetchInterval = (values) => {
        let value = values?.target?.value;
        setBackgroundFetchIntervalValue(value);
    }

    const initialValues = {
        message_background_fetch_interval:
            backgroundFetchIntervalValue
                ? backgroundFetchIntervalValue
                : getKeyValueByObject(settings, Setting.MESSAGE_BACKGROUND_FETCH_INTERVAL) || "",
        unmute_phone_sound: getKeyValueByObject(settings, Setting.UNMUTE_PHONE_SOUND) == 1 ? true : false || "",
        enable_login_face_recognition: getKeyValueByObject(settings, Setting.ENABLE_LOGIN_FACE_RECOGNITION) == "true" ? true : false || "",
    };

    const submit = async (values) => {
        const data = new FormData();
        data.append(Setting.MESSAGE_BACKGROUND_FETCH_INTERVAL, values?.message_background_fetch_interval);
        data.append("app_id", props && props.app_id ? props.app_id : "");

        AppSettingService.saveAppThemeSetting(data, props.app_id, (err, res) => {
            if (res) {
                getSettings()
            }
        })
    };

    const handleCheckBoxValue = (values) => {
        try {
            const data = new FormData();

            if (values && values.unmute_phone_sound !== undefined) {
                data.append(Setting.UNMUTE_PHONE_SOUND, values?.unmute_phone_sound == true ? 1 : 0)

            }
            if (values && values.enable_login_face_recognition !== undefined) {
                data.append(Setting.ENABLE_LOGIN_FACE_RECOGNITION, values?.enable_login_face_recognition)
            }
            data.append("app_id", props && props.app_id ? props.app_id : "");

            // Save settings
            AppSettingService.saveAppThemeSetting(data, props.app_id, (err, res) => {
                if (res) {
                    getSettings()
                }
            })
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <DefaultContent>
                <Form
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        submit(values);
                    }}
                >
                    <div className="row field-wrapper">
                        <div className="col-lg-12 col-sm-12">
                            <Text
                                name="message_background_fetch_interval"
                                label="Background Fetch Interval"
                                placeholder="Enter Background Fetch Interval"
                                onChange={handleBackgroundFetchInterval}
                                error=""
                            />
                            <SingleCheckbox
                                name={Setting.UNMUTE_PHONE_SOUND}
                                label={"Unmute Phone Sound"}
                                handleOnChangeSubmit={(value, name) =>
                                    handleCheckBoxValue(value, name)
                                }
                            />
                            <SingleCheckbox
                                name={Setting.ENABLE_LOGIN_FACE_RECOGNITION}
                                label={String.createLabel(Setting.ENABLE_LOGIN_FACE_RECOGNITION)}
                                handleOnChangeSubmit={(value, name) =>
                                    handleCheckBoxValue(value, name)
                                }
                            />
                        </div>
                    </div>
                    <SaveButton />
                    {!props.showCancelButton && (
                        <CancelButton onClick={() => props.toggle && props.toggle("General")} />
                    )}
                </Form>
            </DefaultContent>
        </>
    );
};

export default Settings;
