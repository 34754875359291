import React, { useState, useEffect } from "react";
import Select from "./Select";
import { MonthOption } from "../helpers/Salary";

const MonthSelect = ({
  label,
  width,
  required,
  onChange,
  name,
  isDisabled,
  defaultValue,
}) => {
  return (
    <>
      <Select
        fullWidth={true}
        width="100"
        label={label ? label : ""}
        name={name ? name : "Year"}
        placeholder={label ? label : "Select Month"}
        isClearable
        isSearchable
        required={required}
        options={MonthOption}
        isSingleSelect={true}
        onInputChange={onChange}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        showVirtualizedMenu={true}
      />
    </>
  );
};

export default MonthSelect;
