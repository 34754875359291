import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

// API
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Components
import PageTitle from "../../components/PageTitle";
import StatisticsCountCard from "../../components/StatisticsCountCard";

// Lib
import { isBadRequest } from "../../lib/Http";

const JobsDashboard = () => {

  const [countDetail, setCountDetail] = useState();

  useEffect(() => {
    getDashboardCount();
  }, []);

  const getDashboardCount = async () => {
    try {
      const response = await apiClient.get(`${endpoints().jobDashboardAPI}/Job`);
      const data = response && response.data;
      setCountDetail(data);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  return (
    <div>
      <PageTitle label="Dashboard" />
      <div
        className={["row me-5 ms-3 justify-content-start"].join(" ")}
      >
        <StatisticsCountCard
          icon={<FontAwesomeIcon icon={faAddressCard} />}
          className={"text-white"}
          label="Jobs"
          count={countDetail && countDetail.jobCount}
          redirectUrl="/jobs"
        />
      </div>
    </div>
  );
};

export default JobsDashboard;
