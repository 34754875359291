import React, { useState } from "react";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { endpoints } from "../../api/endPoints";
import PageTitle from "../../components/PageTitle";
import DateTime from "../../lib/DateTime";
import AvatarCard from "../../components/AvatarCard";
import ProductCard from "../product/components/productCard";
import BreadCrumb from "../../components/Breadcrumb";
import { Collapse } from "reactstrap"; // Import the Collapse component
import { Status } from "../../helpers/Product";
import StatusText from "../../components/StatusText";
import { Link } from "react-router-dom";
import { Order } from "../../helpers/Order";
import Currency from "../../lib/Currency";


const orderProductCancelledReport = (props) => {
  let { history } = props;
  const [activeAccordion, setActiveAccordion] = useState({});

  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report",
    },
    {
      label: "Order Product Cancelled Report",
      link: "",
    },
  ];

  //Sort By Option Values
  const sortByOption = [
    {
      value: "user_name:ASC",
      label: "Owner",
    },
    {
      value: "product_name:ASC",
      label: "Name",
    },
    {
      value: "quantity:DESC",
      label: "Quantity",
    },
  ];

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <PageTitle label="Order Product Cancelled Report" />
      <ReduxTable
        id={"orderProductCancelledReport"}
        searchPlaceholder="Search"
        newTableHeading
        showHeader
        apiURL={`${endpoints().orderProductCancelledReportAPI}/search`}
        sortByOptions={sortByOption}
        paramsToUrl={true}
        history={history}
        defaultSortOption={[
          {
            value: "user_name:ASC",
            label: "Owner",
          },
        ]}
        icon={<FontAwesomeIcon icon={faTruck} />}
        message="You can start by clicking on Add New"
        params={{showTotal:true}}
        showCustomDateFilter
        showStoreFilter
        showCategoryFilter
        showBrandFilter
        showProductFilter
        showPaymentTypeFilter
        showBackgroundColor
      >
           <ReduxColumn
          field="order_number"
          sortBy="order_number"
          className="text-center"
          renderField={(row) => (
            <Link to={row?.order_type == Order.TYPE_DELIVERY ?  `/deliveryOrder/${row.order_id}` : `/order/${row.order_id}`}
              className='link-opacity-75'
            >
              {row.order_number}
            </Link>
          )}
        >
          Order#
        </ReduxColumn>
        <ReduxColumn
          field="order_date"
          sortBy="order_date"
          className="text-center"
          renderField={(row) => (
            <span>{DateTime.UTCtoLocalTime(row.order_date)}</span>
          )}
        >
          Order Date
        </ReduxColumn>

        <ReduxColumn
          field="location_name"
          sortBy="location_name"
          className="text-center"
        >
          Location
        </ReduxColumn>
        <ReduxColumn
          field="user_name"
          sortBy="user_name"
          className="text-center"
        >
          Owner
        </ReduxColumn>
        <ReduxColumn
          field="product"
          sortBy="product_name"
          renderField={(row) => (
            <>
            { row.product_id &&
              <ProductCard
                productImageIcon
                square
                productName={row.product_name}
                brandName={row.brand_name}
                size={row.size}
                unit={row.unit}
                salePrice={row.sale_price}
                mrp={row.mrp}
                url={row.featured_media_url}
                id={row.product_id}
                brand_id={row.brand_id}
                packSize={row.pack_size}
                status={
                  row.status == Status.INACTIVE_VALUE ? Status.INACTIVE : ""
                }
              />
          }
            </>
          )}
        >
          Product
        </ReduxColumn>
        <ReduxColumn field="quantity" sortBy="quantity" className="text-center">
           Quantity
        </ReduxColumn>
        <ReduxColumn field="cash_amount" sortBy="cash_amount" className="text-center"
           renderField={(row) => (
            <span>{Currency.Format(row?.cash_amount)}</span>
          )}
        >
          Cash Amount
        </ReduxColumn>
        <ReduxColumn field="upi_amount" sortBy="upi_amount" className="text-center" 
        renderField={(row) => (
          <span>{Currency.Format(row?.upi_amount)}</span>
        )}
        >
          Upi Amount
        </ReduxColumn>
        <ReduxColumn field="status_name" sortBy="status_name" className="text-center"
      renderField={(row) => (
        <StatusText
          backgroundColor={row.color_code}
          status={row.status_name}
        />
      )}
        >
          Status
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

export default orderProductCancelledReport;
