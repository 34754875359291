import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import TagSelect from "../../components/TagSelect";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import { TagTypeName } from "../../helpers/Tag";
import TicketTestCaseService from "../../services/TicketTestCaseService";
import DeleteModal from "../../components/DeleteModal";
import DraftEditor from "../../components/Draft";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import HorizontalSpace from "../../components/HorizontalSpace";
import BreadCrumb from "../../components/Breadcrumb";

const TestCaseDetailPage = (props) => {
  const [detail, setDetail] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    EditorState.createEmpty();
  });

  let dispatch = useDispatch();
  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    let id = props?.match?.params?.id;
    let response = await TicketTestCaseService.get(id);
    let data = response && response?.data;
    setDetail(data);
  };

  let initialValues = {
    name: detail?.name,
    description: detail?.description,
    testSteps: detail?.testSteps,
    prerequisite: detail?.prerequisite,
    testData: detail?.testData,
    expectedResult: detail?.expectedResult,
    moduleTag: detail?.moduleTag
      ? {
          label: detail?.moduleTagName,
          value: detail?.moduleTag,
        }
      : "",
  };
  const handleSubmit = async (values) => {
    let id = props?.match?.params?.id;
    let data = new FormData();
    data.append("name", values && values?.name ? values?.name : "");
    data.append(
      "description",
      values && values?.description ? values?.description : ""
    );
    data.append(
      "expectedResult",
      values && values?.expectedResult ? values?.expectedResult : ""
    );
    data.append(
      "prerequisite",
      values && values?.prerequisite ? values?.prerequisite : ""
    );
    data.append("testData", values && values?.testData ? values?.testData : "");
    data.append(
      "moduleTag",
      values && values?.moduleTag ? values?.moduleTag?.value : ""
    );
    data.append(
      "testSteps",
      values && values?.testSteps ? values?.testSteps : ""
    );
    let rawComment;
    if (editorState) {
      rawComment = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
    }

    data.append("comments", rawComment || "");

    dispatch(await TicketTestCaseService.update(id, data, (res) => {
        if(res){
            getDetail()  
        }
    }));
  };
  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const deleteFunction = async () => {
    let id = props?.match?.params?.id;
    dispatch(
      await TicketTestCaseService.delete(id, (res) => {
        if (res) {
          props.history.push("/ticketTestCase");
        }
      })
    );
  };


  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const breadcrumbList = [
    { label: "Home", link: "/dashboard/ticket" },
    { label: "Test Case", link: "/ticketTestCase" },
    { label: "Detail Page", link: "" },
  ];

  return (
    <div className="mb-4 pb-4 mb-sm-2 pb-sm-2">
      <BreadCrumb list={breadcrumbList} />

      <DeleteModal
        isOpen={openDeleteModal}
        label={props?.match?.params?.id}
        toggle={closeDeleteModal}
        title="Delete Test Case"
        deleteFunction={deleteFunction}
      />
      <PageTitle
        label="Detail page"
        DeleteButtonLabel="Delete"
        deletebuttonHandler={() => {
          closeDeleteModal();
        }}
      />
      <div className="card card-body">
        <Form
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          <Text name="name" label="Name" />
          <TagSelect
            name="moduleTag"
            label="Module Tag"
            params={{
              type: TagTypeName.MODULE,
            }}
          />
          <TextArea name="description" label="Description" />
          <TextArea name="testSteps" label="Test Steps" />
          <TextArea name="prerequisite" label="Prerequisite" />
          <TextArea name="testData" label="Test Data" />
          <TextArea name="expectedResult" label="Expected Result" />
          <DraftEditor
              name="comments"
              label={"Comments"}
              editorState={
                editorState &&  editorState !==undefined
                  ? editorState
                  : detail?.comments
                    ? EditorState.createWithContent(convertFromRaw(JSON.parse(detail?.comments)))
                    : ""
              }
              onChange={handleEditorChange}
            />
            <HorizontalSpace/>
          <SaveButton label="Save" />
        </Form>
      </div>
    </div>
  );
};

export default TestCaseDetailPage;
