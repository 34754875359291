import React, { useEffect, useState } from "react";

// Components
import BreadCrumb from "../../../components/Breadcrumb";
import CancelButton from "../../../components/CancelButton";
import Form from "../../../components/Form";
import PageTitle from "../../../components/PageTitle";
import SaveButton from "../../../components/SaveButton";
import Text from "../../../components/Text";
import DefaultContent from "../../../components/content/defaultContent";
import Button from "../../../components/Button";

// Helpers
import { Setting } from "../../../helpers/Setting";

// Lib
import { getKeyValueByObject } from "../../../lib/Helper";

// Services
import {
  getCompanySettings,
  saveSetting,
} from "../../../services/SettingService";
import { generateOauthToken } from "../../../services/OauthService";

const WordPress = (props) => {
  const [settings, setSettings] = useState({});

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    setSettings(settingData);
  };

  useEffect(() => {
    getSettings();
  }, []);

  const initialValues = {
    wordpress_access_token: getKeyValueByObject(
      settings,
      Setting.SETTING_WORDPRESS_ACCESS_TOKEN
    ),
  };

  const submit = async (values) => {
    const data = new FormData();
    data.append(
      Setting.SETTING_WORDPRESS_ACCESS_TOKEN,
      values?.wordpress_access_token
    );
    await saveSetting(data);
  };

  const breadcrumbList = [
    { label: "Home", link: "/admin/companyProfile" },
    { label: "Integrations", link: "/integrations" },
    { label: "WordPress", link: "" },
  ];

  const handleCreateToken = async () => {
    await generateOauthToken((err, res) => {
      if (res) {
        getSettings();
      }
    });
  };

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <PageTitle label="WordPress" />

      <DefaultContent>
        <Form
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => {
            submit(values);
          }}
        >
          <div className="row field-wrapper">
            <div className="col-lg-12 col-sm-12">
              <div className="d-flex align-items-center mb-3">
                <div className="me-2 flex-grow-1">
                  <Text
                    name="wordpress_access_token"
                    label="Access Token"
                    placeholder="Enter Access Token"
                    error=""
                    required={true}
                  />
                </div>
                <div className="mt-3">
                  <Button
                    label="Generate Token"
                    type="button"
                    className="text-center p-0 p-md-2 m-0 m-md-2"
                    onClick={handleCreateToken}
                    showButtonLabelName
                  />
                </div>
              </div>
              <SaveButton />
              <CancelButton
                onClick={() => props.history.push("/integrations")}
              />
            </div>
          </div>
        </Form>
      </DefaultContent>
    </>
  );
};

export default WordPress;
