import React from "react";
import { Button, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import Text from "../../components/Text";
import StateDropdown from "../../components/State";
import Zipcode from "../../components/Zipcode";
import Country from "../../components/Country";
import Phone from "../../components/Phone";
import MoreDropdown from "../../components/authentication/moreDropdown";
import Drawer from "../../components/Drawer";

// API
import { endpoints } from "../../api/endPoints";

// Services
import { hasPermission } from "../../services/UserRolePermissionService";

// Helpers
import Permission from "../../helpers/Permission";

const AddressDetailTab = (props) => {
  const {
    selectedOption,
    selectedCountryId,
    selectedCountryName,
    handleCountryChange,
    initialValue,
    history,
    AddressOpen,
    AddressSelectModal,
    handleSubmit,
    setIsDeleteModel,
    setAddressId,
    setAddress,
    EditModal,
    setRow,
    id,
    object_id,
    objectName,
    setTitle,
    setDeleteRow,
    hideQueryParams
  } = props;

  let hasDeletePermission = hasPermission(Permission.ADDRESS_DELETE);

  let hasEditPermission = hasPermission(Permission.ADDRESS_EDIT);

  const addAddressFormFooter = (
    <Button type="submit">
      {id ? "Update" : "Add"}
    </Button>
  );

  const sortByOption = [
    {
      value: "title:ASC",
      label: "Title",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const addAddressForm = (
    <div>
      <Text
        name="title"
        label="Title"
        placeholder="Enter Title"
        error=""
        required
      />
      <Text name="name" label="Name" placeholder="Enter Name" error="" />
      <Text
        name="address1"
        label="Address 1"
        placeholder="Enter Address 1"
        error=""
      />
      <Text
        name="address2"
        label="Address 2"
        placeholder="Enter Address 2"
        error=""
      />

      <Text name="city" label="City" placeholder="Enter City" error="" />
      <Country
        name="country"
        label="Country"
        placeholder="Enter Country"
        onChange={handleCountryChange}
      />
      <StateDropdown
        name="state"
        label="State"
        placeholder="Enter State"
        selectedCountry={
          selectedOption
            ? selectedOption
            : selectedCountryId
              ? selectedCountryId
              : ""
        }
        selectedCountryName={selectedCountryName ? selectedCountryName : ""}
      />
      <Zipcode
        label="Pin Code"
        name="pin_code"
        placeholder="Enter Pin Code"
        error=""
      />
      <Phone
        name="phone_number"
        label="Phone Number"
        placeholder="Enter Phone Number"
        error=""
      />
      <Text
        label="GST Number"
        name="gst_number"
        placeholder="GST Number"
        notify
      />
      <Text
        name="longitude"
        label="Longitude"
        placeholder="Enter Longitude"
        error=""
      />
      <Text
        name="latitude"
        label="Latitude"
        placeholder="Enter Latitude"
        error=""
      />
    </div>
  );

  return (
    <>
      <Drawer
        modelTitle={id ? "Edit Address" : "Add Address"}
        DrawerBody={addAddressForm}
        DrawerFooter={addAddressFormFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={{
          ...initialValue,
        }}
        handleOpenModal={AddressSelectModal}
        handleCloseModal={AddressSelectModal}
        handleDrawerClose={AddressSelectModal}
        isModalOpen={AddressOpen}
        buttonLabel={true}
        enableReinitialize={true}
      />

      <div className="mt-4">
        <ReduxTable
          id="address"
          apiURL={`${endpoints().addressAPI}/search`}
          showHeader
          searchPlaceholder="Search"
          newTableHeading={
            props.newTableHeading ? props.newTableHeading : false
          }
          showSearch={props.showSearch ? props.showSearch : false}
          sortByOptions={sortByOption}
          onRowClick
          params={{
            pagination: true,
            object_id: object_id,
            objectName: objectName,
          }}
          history={history}
          paramsToUrl={true}
          hideQueryParams={hideQueryParams}
        >
          <ReduxColumn
            field="title"
            type="Link"
            sortBy="title"
            renderField={(row) => (
              <div className="text-start action-group-dropdown">
                <Link
                  onClick={() => {
                    setRow(row);
                    return EditModal(row.id);
                  }}
                  className="link-opacity-75 text-decoration-none"
                >
                  {row.title}
                </Link>
              </div>
            )}
          >
            Title
          </ReduxColumn>
          <ReduxColumn
            field="name"
            sortBy="name"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            field="address1"
            sortBy="address1"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Address 1
          </ReduxColumn>
          <ReduxColumn
            field="address2"
            sortBy="address2"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Address 2
          </ReduxColumn>
          <ReduxColumn
            field="city"
            sortBy="City"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            City
          </ReduxColumn>
          <ReduxColumn
            field="country"
            sortBy="country"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Country
          </ReduxColumn>
          <ReduxColumn
            field="state"
            sortBy="state"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            State
          </ReduxColumn>
          <ReduxColumn
            field="pin_code"
            sortBy="pin_code"
            width="100px"
            minWidth="100px"
            maxWidth="100px"
          >
            Pin Code
          </ReduxColumn>
          <ReduxColumn
            field="gst_number"
            sortBy="gst_number"
            width="210px"
            minWidth="210px"
            maxWidth="210px"
          >
            GST
          </ReduxColumn>
          <ReduxColumn field="longitude" sortBy="longitude">
            Longitude
          </ReduxColumn>
          <ReduxColumn field="latitude" sortBy="latitude">
            Latitude
          </ReduxColumn>
          {(hasEditPermission || hasDeletePermission) && (
            <ReduxColumn
              field="Action"
              disableOnClick
              width="120px"
              minWidth="80px"
              maxWidth="80px"
              renderField={(row) => (
                <div className=" text-center action-group-dropdown">
                  <MoreDropdown>
                    {hasEditPermission && (
                      <DropdownItem
                        onClick={() => {
                          setRow(row);
                          return EditModal(row.id);
                        }}
                      >
                        Quick View
                      </DropdownItem>
                    )}
                    {hasDeletePermission && (
                      <DropdownItem
                        className=" text-danger cursor-pointer"
                        onClick={() => {
                          setDeleteRow && setDeleteRow(row);
                          setIsDeleteModel(true);
                          setAddressId(row.id);
                          setAddress(row.id);
                          setTitle(row.title);
                        }}
                      >
                        Delete
                      </DropdownItem>
                    )}
                  </MoreDropdown>
                </div>
              )}
            >
              Action
            </ReduxColumn>
          )}
        </ReduxTable>
      </div>
    </>
  );
};
export default AddressDetailTab;
