const Order = {
    TYPE_GROUP_STORE: 1,
    TYPE_GROUP_DELIVERY: 2,
    TYPE_GROUP_ONLINE: 3,
    TYPE_GROUP_STORE_TEXT: "Store",
    TYPE_GROUP_DELIVERY_TEXT: "Delivery",
    TYPE_GROUP_ONLINE_TEXT: "Online",
  };
  
  const orderTypeGroupOptions = [
    {
      label: Order.TYPE_GROUP_STORE_TEXT,
      value: Order.TYPE_GROUP_STORE,
    },
    {
      label: Order.TYPE_GROUP_DELIVERY_TEXT,
      value: Order.TYPE_GROUP_DELIVERY,
    },
    {
      label: Order.TYPE_GROUP_ONLINE_TEXT,
      value: Order.TYPE_GROUP_ONLINE,
    },
  ];
  const OrderType = {
    ENABLE_CUSTOMER_SELECTION: 1,
    DISABLE_CUSTOMER_SELECTION: 0,
    ENABLE_STORE_ORDER: 1,
    DISABLE_STORE_ORDER: 0,
    ENABLE_DELIVERY_ORDER: 1,
    DISABLE_DELIVERY_ORDER: 0,
    ENABLE_DELIVERY_DATE: 1,
    DISABLE_DELIVERY_DATE: 0,
    ENABLE_DELIVERY_TIME: 1,
    DISABLE_DELIVERY_TIME: 0,
    ENABLE_DELIVERY_EXECUTIVE: 1,
    DISABLE_DELIVERY_EXECUTIVE: 0,
  };
  
  module.exports = {
    orderTypeGroupOptions,
    OrderType
  };
  