import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Currency from "../../../lib/Currency";

// Table Component
const SalaryAttendanceTable = ({ data }) => {
  // Group data by type
  const groupedData = data.reduce((acc, item) => {
    const { type } = item;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(item);
    return acc;
  }, {});

  // Extract Working Day and Leave items
  const workingDayItems = groupedData["Working Day"] || [];
  const leaveItems = groupedData["Leave"] || [];

  // Function to render the table section
  const renderTableSection = (type, items) => {
    const totalSalary = items.reduce((sum, item) => {
      return sum + (item?.amount || 0);
    }, 0);

    return (
      <div key={type}>
        <div className="table-responsive p-1">
          <div className="p-1 d-flex justify-content-start">
            {type} Details
          </div>

          <table className="table table-bordered col-12">
            <tbody>
              {items.length > 0 ? (
                <>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item?.typeName} ({item?.count}{" "}
                        {item?.count > 1
                          ? "days"
                          : item?.count === 1
                          ? "day"
                          : ""}
                        )
                      </td>
                      <td>{Currency.GetFormatted(item?.amount)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="1" className="text-end table-secondary">
                      <strong>Total</strong>
                    </td>
                    <td className="table-secondary">
                      <strong>{Currency.GetFormatted(totalSalary)}</strong>
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan="2" className="text-center text-danger">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderTableSection("Working Day", workingDayItems)}
      {renderTableSection("Leave", leaveItems)}
    </div>
  );
};

export default SalaryAttendanceTable;
