import React, { useState } from 'react';
import { DropdownItem } from 'reactstrap';
import { useDispatch } from 'react-redux';

// Components
import ReduxTable, { ReduxColumn } from '../../../components/reduxTable';
import UserCard from '../../../components/UserCard';
import MoreDropdown from '../../../components/authentication/moreDropdown';
import DeleteModal from '../../../components/DeleteModal';
import Toast from '../../../components/Toast';
import PageTitle from '../../../components/PageTitle';
import Action from '../../../components/Action';

// API
import { endpoints } from '../../../api/endPoints';

// Lib
import DateTime from '../../../lib/DateTime';
import Url from '../../../lib/Url';

// Services
import { hasPermission } from '../../../services/UserRolePermissionService';
import MessagesService from '../../../services/MessagesService';

// Helpers
import Permission from '../../../helpers/Permission';

// Actions
import { fetchList } from '../../../actions/table';

// Assets
import { TagsIcon } from '../../../assets/icons';

const MessageList = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowValue, setRowValue] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);

  let dispatch = useDispatch();
  let messagesManageOthers = hasPermission(Permission.MESSAGE_MANAGE_OTHERS);


  const Toggle = () => {
    setDeleteModal(!deleteModal);
    setRowValue("");
  }

  let params = {
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize")
  }

  const handleBulkSelect = (ids) => {
    setSelectedIds({ selectedIds: ids });
  };

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
  ];

  const handleActionChange = async (e) => {
    if (
      selectedIds &&
      selectedIds?.selectedIds &&
      selectedIds?.selectedIds?.length > 0
    ) {
      if (e == "Delete") {
        setDeleteModal(true);
      } else {
        Toast.error("Select Atleast One Item");
      }
    }
  };

  const handleDelete = async () => {
    await MessagesService.delete(rowValue, (err, res) => {
      if (res) {
        Toast.success(res?.data?.message);
        dispatch(
          fetchList("message", `${endpoints().messageAPI}/list`,
            params?.page ? params?.page : 1,
            params?.pageSize ? params?.pageSize : 25,
            { ...Url.GetAllParams() }
          )
        );
      }
    })
  }

  const messageBulkDelete = async (selectedIds) => {
    let ids = { selectedId: selectedIds?.selectedIds };
    dispatch(
      await MessagesService.bulkDelete(ids, null, {
        ...Url.GetAllParams()
      })
    );
    setSelectedIds([]);
    setIsDeleteModel(false);
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  }

  const renderNotesWithLinks = (text) => {
    if (!text) return null;

    const urlRegex = /(https?:\/\/[^\s]+)/g; // Match URLs
    return text.split(urlRegex).map((part, index) =>
      part.match(urlRegex) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModal}
        toggle={Toggle}
        title="Delete Message"
        id={rowValue}
        label={rowValue ? rowValue : "Bulk Delete"}
        deleteFunction={() => {
          if (rowValue) {
            handleDelete(rowValue);
          } else {
            messageBulkDelete(selectedIds);
          }
        }}
      />
      <div className="d-flex justify-between mb-3">
        <PageTitle label="Message List" />
        <div>
          <Action
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}
          />
        </div>
      </div>
      <ReduxTable
        id="message"
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().messageAPI}/list`}
        newTableHeading
        icon={<TagsIcon />}
        message="You can start by clicking Add New"
        paramsToUrl={true}
        history={props?.history}
        sortByDropdown
        showReceiverFilter
        showSenderFilter={messagesManageOthers}
        showCustomDateFilter
        bulkSelect
        onBulkSelect={handleBulkSelect}
      >
        <ReduxColumn
          field='id'
          sortBy='id'
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          className="text-center"
        >
          Message#
        </ReduxColumn>
        <ReduxColumn
          field='date'
          sortBy='date'
          className="text-center"
          renderField={(row) => (
            <span>
              {DateTime.getDateTimeByUserProfileTimezone(row?.date)}
            </span>
          )}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field='sender_first_name'
          sortBy='sender_first_name'
          renderField={(row) => (
            <>
              <UserCard
                customSize={parseInt(50, 10)}
                firstName={row.sender_first_name}
                lastName={row.sender_last_name}
                url={row.sender_media_url}
              />
            </>
          )}
        >
          Sender
        </ReduxColumn>
        <ReduxColumn
          field='reciever_first_name'
          sortBy='reciever_first_name'
          renderField={(row) => (
            <>
              <UserCard
                customSize={parseInt(50, 10)}
                firstName={row.reciever_first_name}
                lastName={row.reciever_last_name}
                url={row.reciever_media_url}
              />
            </>
          )}
        >
          Reciever
        </ReduxColumn>
        <ReduxColumn
          field='message'
          sortBy='message'
          renderField={(row) => (
            <div>
              <p style={{ whiteSpace: "pre-wrap" }} >{renderNotesWithLinks(row.message)}</p>
            </div>
          )}
        >
          Message
        </ReduxColumn>
        <ReduxColumn
          field='read_at'
          sortBy='read_at'
          className="text-center"
          renderField={(row) => (
            <span>
              {DateTime.getDateTimeByUserProfileTimezone(row?.read_at)}
            </span>
          )}
        >
          Read At
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="120px"
          renderField={(row) => (
            <div className="text-center action-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    setRowValue(row.id);
                    setDeleteModal(true);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};
export default MessageList;
