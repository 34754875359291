import MediaService from "../services/MediaService";
import Media from "./Media";

class MediaUpload {
  static async uploadFile(mediaParams,callback) {
    let { selectedFile, objectId, ObjectName, values,visiblity, showToast=false } = mediaParams;
    try {
      if (selectedFile && objectId) {
        const mediaFile = selectedFile ? selectedFile : "";

        const media = selectedFile?.name;
        const name = values?.name
        const tagId = values?.category && values.category?.value ? values.category?.value : "";
        const data = new FormData();

        if (mediaFile) {
          data.append([Media.MEDIA_FILE], mediaFile ? mediaFile : "");
        }
        if (media !== undefined) {
          data.append([Media.MEDIA_NAME], media ? media : "");
        }
        data.append("object", ObjectName);

        data.append("object_id", objectId);
        data.append("name", name ? name : "");
        data.append("tagId", tagId ? tagId : "");

        data.append([Media.MEDIA_VISIBILITY], visiblity ? visiblity : Media.VISIBILITY_PUBLIC);
        // if(
        const response = await MediaService.saveImage(data, showToast);
        if (response) {
          callback && callback(response);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}
export default MediaUpload;
