import React, { useEffect, useState } from "react";
import Select from "./Select";
import { apiClient } from "../apiClient";
import { endpoints } from "../api/endPoints";

const JobCategorySelect = (props) => {
 let {
    name,
    placeholder,
    handleCategoryChange,
    required,
    label,
    isDisabled,
    fontBolded,
  }=props;
  const [jobCategoryList, setJobCategoryList] = useState([]);

  useEffect(() => {
    getJobCategoryList();
  }, []);

  const getJobCategoryList = async () => {
    try {
      const response = await apiClient.get(`${endpoints().tagApi}/list`);
      const tagDetails = response.data.data;

      const tagList = tagDetails
        .filter(tag => tag.type === "Job Category")
        .map(tag => ({
          label: tag.name,
          value: tag.id,
          id: tag.id,
          status: tag.status
        }));

      setJobCategoryList(tagList);
      props?.setJobCategoryList && props.setJobCategoryList(tagList);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Select
        name={name ? name : "jobCategory"}
        placeholder={placeholder ? placeholder : "Select Job Category"}
        onInputChange={handleCategoryChange}
        label={label ? label : "Job Category"}
        options={jobCategoryList}
        isDisabled={isDisabled}
        required={required}
        fontBolded={fontBolded}
      />
    </div>
  );
};

export default JobCategorySelect;
