import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";

// Components
import Action from "../../components/Action";
import ActivityList from "../../components/ActivityList";
import BreadCrumb from "../../components/Breadcrumb";
import CancelButton from "../../components/CancelButton";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import HorizontalSpace from "../../components/HorizontalSpace";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Spinner from "../../components/Spinner";
import OrderList from "../order/components/OrderList";
import CustomerForm from "./components/CustomersForm";

// Helpers
import { TODAY_VALUE } from "../../helpers/Date";
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";

// Services
import customerService from "../../services/CustomerService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Lib
import String from "../../lib/String";
import Url from "../../lib/Url";

const Tab = {
  SUMMARY: "Summary",
  HISTORY: "History",
  ORDER: "Order",
};

const CustomersDetail = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const [detail, setDetail] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") || Tab.SUMMARY
  );
  const [isEditable, setIsEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    { label: "Customers", link: `/customers?date=${TODAY_VALUE}` },
    { label: "Customer Detail", link: "" },
  ];

  // Customer id
  const id = props.match.params.id;

  let hasEditPermission = hasPermission(Permission.CUSTOMER_EDIT);

  let hasDeletePermission = hasPermission(Permission.CUSTOMER_DELETE);

  const hasHistoryViewPermission = hasPermission(
    Permission.CUSTOMER_HISTORY_VIEW
  );

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    customerService.get(id, (response) => {
      if (response && response?.data) {
        setDetail(() => response.data?.data);
      }
    });
  };

  const deleteCustomer = async () => {
    dispatch(await customerService.delete(id, history, {}));
  };

  // Handle form Submit
  const handleSubmit = async (values) => {
    try {
      const data = new FormData();
      data.append("first_name", values?.first_name);
      data.append("last_name", values.last_name);
      data.append("mobile_number", values.mobile_number);

      dispatch(
        await customerService.update(id, data, (res) => {
          if (res) {
            setIsLoading(true);
            getDetails();
            setIsLoading(false);
            setIsEditable(true);
          }
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  // Form initial values
  const initialValues = {
    first_name: detail?.first_name ? detail?.first_name : "",
    last_name: detail?.last_name ? detail?.last_name : "",
    mobile_number: detail?.mobile_number ? detail?.mobile_number : "",
  };

  const actionsMenuList = [];

  if (hasDeletePermission) {
    actionsMenuList.push({
      value: "delete",
      label: "Delete",
    });
  }

  if (hasEditPermission && isEditable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "edit") {
      setIsEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <DeleteModal
        id={props.match.params.id}
        label={
          detail?.first_name
            ? String.concatName(detail?.first_name, detail?.last_name)
            : detail?.id
        }
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Customer"
        deleteFunction={deleteCustomer}
      />

      {/* Bread Crumb Section */}
      <BreadCrumb list={breadcrumbList} />

      {/* Page title */}
      <div className="d-flex justify-content-between">
        <div>
          <PageTitle label="Customer Detail" DeleteButtonLabel="Delete" />
        </div>
        <div className="d-flex">
          <HorizontalSpace paddingLeft="2" />
          {actionsMenuList && actionsMenuList?.length > 0 && (
            <div className="ps-2">
              <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            </div>
          )}
        </div>
      </div>

      <Nav tabs className="admin-tabs">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.SUMMARY,
            })}
            onClick={() => handleTabChange(Tab.SUMMARY)}
          >
            {Tab.SUMMARY}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.ORDER,
            })}
            onClick={() => handleTabChange(Tab.ORDER)}
          >
            {Tab.ORDER}
          </NavLink>
        </NavItem>

        {hasHistoryViewPermission && (
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => handleTabChange(Tab.HISTORY)}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>

      {/* Form */}
      {activeTab === Tab.SUMMARY && (
        <div className="card mt-3 mb-5 pb-2 mb-sm-3 pb-sm-0">
          <div className="card-body ">
            <Form
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <CustomerForm isEditable={isEditable} />
              {!isEditable && (
                <div>
                  <SaveButton label="Save" />
                  <CancelButton
                    onClick={() =>
                      props.history.push(`/customers?date=${TODAY_VALUE}`)
                    }
                  />
                </div>
              )}
            </Form>
          </div>
        </div>
      )}
      {activeTab === Tab.HISTORY && (
        <ActivityList
          id={id}
          objectId={id}
          object_name={ObjectName.CUSTOMER}
          history={history}
        />
      )}
      {activeTab === Tab.ORDER && (
        <OrderList
          params={{
            customer_id: id,
          }}
          history={props.history}
        />
      )}
    </>
  );
};

export default CustomersDetail;
