import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { useDispatch } from "react-redux";

// Components
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import AddCountryModal from "./AddModal";
import PageTitle from "../../../components/PageTitle";
import DeleteModal from "../../../components/DeleteModal";
import MoreDropdown from "../../../components/authentication/moreDropdown";

//Config
import { endpoints } from "../../../api/endPoints";

// Actions
import { deleteCountry } from "../../../actions/country";

// Lib
import Url from "../../../lib/Url";

// Services
import { hasPermission } from "../../../services/UserRolePermissionService";

// Helpers
import Permission from "../../../helpers/Permission";

const Country = (props) => {
  const [countryModalOpen, setCountryModalOpen] = useState();

  const [countryData, setCountryData] = useState();

  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();

  let hasDeletePermission = hasPermission(Permission.COUNTRY_DELETE);

  const sortByOption = [
    {
      value: "country_name",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const toggle = () => {
    setCountryModalOpen(!countryModalOpen);
  };

  const handledelete = (id) => {
    dispatch(
      deleteCountry(id, {
        search: Url.GetParam("search"),
        sort: Url.GetParam("sort"),
        sortDir: Url.GetParam("sortDir"),
        page: Url.GetParam("page"),
        pageSize: Url.GetParam("pageSize"),
      })
    );
  };

  return (
    <>
      {/* Add Scheduler Modal */}
      <AddCountryModal
        toggle={toggle}
        isOpen={countryModalOpen}
        onModalClose={toggle}
        hideDefaultButtons
      />

      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Country"
        id={countryData?.id}
        label={countryData?.name}
        deleteFunction={handledelete}
      />

      {/* /.page-heading */}
      <PageTitle
        label="Countries"
        buttonHandler={toggle}
        buttonLabel="Add New"
      />

      {/* Redux table*/}
      {/* Country list Table */}
      <ReduxTable
        id="country"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().countryAPI}/search`}
        sortByOptions={sortByOption}
        history={props.history}
        paramsToUrl={true}
        sortByDropdown
        showPageSize
      >
        <ReduxColumn
          field="name"
          sortBy="country_name"
          className="no-underline"
          type="link"
          renderField={(row) => (
            <Link
              to={`/admin/settings/country/${row.id}`}
              className="link-opacity-75 text-decoration-none lh-lg"
            >
              {row.name}
            </Link>
          )}
        >
          Name
        </ReduxColumn>
        {hasDeletePermission && (
          <ReduxColumn
            field="Action"
            disableOnClick
            renderField={(row) => (
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  {hasDeletePermission && (
                    <DropdownItem
                      className="text-danger"
                      onClick={() => {
                        setCountryData(row);
                        setDeleteModal(true);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        )}
      </ReduxTable>
    </>
  );
};

export default Country;
