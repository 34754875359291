import React from "react";
import ToolTipComponent from "./ToolTip";

const StatusText = ({ backgroundColor, status, text, mxAuto = true }) => {
  return (
    <div className="d-flex justify-content-center align-items-center my-3">
      <div className="d-flex align-items-center position-relative">
        {status &&
          <div
            className={`status-input text-center rounded text-white text-uppercase ${mxAuto ? "mx-auto" : ""}`}
            style={{ backgroundColor: status && (backgroundColor ? backgroundColor : 'black') }}
          >
            <p className="m-0">{status}</p>
          </div>
        }
        {text && (
          <div className="position-absolute" style={{ right: '-30px' }}>
            <ToolTipComponent text={text} />
          </div>
        )}
      </div>
    </div>
  );
}
export default StatusText;