import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SaveButton from "../../../components/SaveButton";
import ChannelMessagesService from "../../../services/ChannelMessageService";
import MessageChannelUser from "../../../services/MessageChannelUser";
import Drawer from "../../../components/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faCirclePlus, faXmarksLines, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import MessageBubble from "./MessageBubble";
import ArrayList from "../../../lib/ArrayList";
import Toast from "../../../components/Toast";
import AvatarCard from "../../../components/AvatarCard";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import { DropdownItem } from "reactstrap";
import GroupForm from "./GroupForm";
import DeleteModal from "../../../components/DeleteModal";
import { hasPermission } from "../../../services/UserRolePermissionService";
import Permission from "../../../helpers/Permission";
import Image from "../../../components/Image";
import Media from "../../../lib/Media";
import {
  connectSocket,
  getSocketInstance,
} from "../../../services/SocketService";
import MessageUserList from "./MessageUserList";
import { FaTimes } from "react-icons/fa";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isVideoURL } from "../../../components/static/imageCarousel";
import VideoPlayer from "../../../components/VideoPlayer";
import { Modal } from "reactstrap";
import MessageChannelService from "../../../services/MessageChannelService";
import UserCard from "../../../components/UserCard";
const ChannelMessage = (props) => {
  let { logUser, getChannelList,activeChannel,autoRefresh } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [channelOpen, setIsChannelOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [currentMessage, setCurrentMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [channelUser, setChannelUser] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [fileName, setFileName] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [detail, setDetail] = useState([]);
  const [memberToggle, setIsMemberToggle] = useState(false);
  const dispatch = useDispatch();
  const textAreaRef = useRef(null);
  let hasEditPermission = hasPermission(Permission.MESSAGE_GROUP_EDIT);
  let hasDeletePermission = hasPermission(Permission.MESSAGE_GROUP_DELETE);
  let socket = getSocketInstance();
  const params = useParams();
  const [openMemberDelete, setMemberDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  useEffect(() => {
    if (logUser && logUser?.id) {
      if (!socket || !socket.connected) {
        socket = connectSocket(logUser?.id);
      }
      if (!socket) return;

      const handleReceiveMessage = (data) => {
        if (data) {
          getChannelMessagesList();
        }
      };
      socket.on("receiveMessage", handleReceiveMessage);
      return () => {
        socket.off("receiveMessage", handleReceiveMessage);
      };
    }
  }, [logUser]);

  useEffect(() => {
    if (autoRefresh) {

      const interval = setInterval(() => {

        if(params.path == "group"){
          getChannelMessagesList();
          }
      }, 60000); // 60 seconds
  
      return () => clearInterval(interval); // Cleanup on unmount or when autoRefresh changes
    }
  }, [autoRefresh,params]);
  useEffect(() => {
  const storedData = JSON.parse(localStorage.getItem("channelAttachment"));
  if (storedData) {
    if (storedData && storedData.channelId == activeChannel?.channel_id) {
      setAttachment && setAttachment(storedData.previewURL);
      setFileName && setFileName(storedData.name);
    } else {
        setAttachment && setAttachment("");
        setFileName && setFileName("");
    }
  }else{
     setAttachment && setAttachment("");
      setFileName && setFileName("");
  }
}, [activeChannel?.channel_id]);


  useEffect(() => {
    const handleDragOver = (event) => {
      event.preventDefault(); // Prevent default behavior (like opening the file in a new tab)
    };

    const handleDrop = (event) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (!file.type.startsWith("image/") && !file.type.startsWith("video/")) {
        Toast.error("Please upload an image or video file only!");
        return;
    }
      setFileName(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result;
        setAttachment && setAttachment(base64Image);
        if(file.type.startsWith("image/")){
        if(activeChannel && activeChannel?.channel_id ){
          localStorage.setItem(
            "channelAttachment",
            JSON.stringify({
              channelId: activeChannel?.channel_id,
              previewURL: base64Image,
              name:file.name
            })
          );
        }else{
            localStorage.setItem(
              "channelAttachment",
              JSON.stringify({
                channelId: activeChannel?.channel_id,
                previewURL: base64Image,
                name:file.name
              })
            );
        }
        }
      };
    };

    // Add event listeners
    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);

    // Cleanup on unmount
    return () => {
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
    };
  }, []);
  useEffect(() => {
    getChannelMessagesList();
  }, [params?.id]);

  useEffect(() => {
    getMemberData();
  }, [memberToggle]);

  useEffect(() => {
    if (activeChannel?.channel_id) {
      getChannelUserList(activeChannel?.channel_id);
    }
  }, [activeChannel?.channel_id]);

  const getMemberData = async () => {
      let response = await MessageChannelService.getMembers(params?.id);
      let data = response && response?.data && response?.data?.data;
      setDetail(data);
  };
  const refreshValue = async (e) => {
    setRefresh(true);
    getChannelMessagesList();
  };
  const uniqueUsers = channelUser.reduce((acc, user) => {
    if (!acc.some((u) => u.userId === user.userId)) {
      acc.push(user);
    }
    return acc;
  }, []);
  const messagesContainerRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [currentMessage]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  // Scroll on mount & when messages update
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const toggle = () => {
    setIsOpen(!isOpen);
    setSelectedIds([]);
  };
  const toggleChannel = () => {
    setIsChannelOpen(!channelOpen);
  };

  const toggleMember = () => {
    setIsMemberToggle(!memberToggle);
    setDetail([])
  };
  const deleteMemberToggle = () => {
    setMemberDelete(!openMemberDelete);
  };
  const deleteModelToggle = () => {
    setOpenDeleteModal(false);
  };
  const getChannelMessagesList = async () => {
    
      let response = await ChannelMessagesService.search({channel_id: activeChannel?.channel_id});
      let data = response && response?.data;
      setMessages(data);
    setRefresh(false);
  };
  const getChannelUserList = async (id) => {
    let params = {
      channel_id: id,
    };
    let response = await MessageChannelUser.list(params);
    setChannelUser(response?.data);
  };
  const handleUserChange = (values) => {
    setSelectedIds(values);
    const uniqueIds = [...new Set([...(selectedIds || []), ...values])];
    setSelectedIds(uniqueIds);
  };

  const handleUserDelete = async (id) => {
      await MessageChannelUser.delete(id,(error,response)=>{
        if(response){
          getMemberData()
          getChannelUserList(params?.id)
        }
      });
      
  };

  let uniqueUserIds = ArrayList.isArray(uniqueUsers)
    ? uniqueUsers.map((value) => value.userId)
    : [];
  // User Add Section
  let userFormBody = (
    <>
      <MessageUserList
        selectedCheckBox={selectedCheckBox}
        setSelectedIds={handleUserChange}
        excludedIds={uniqueUserIds}
        history={props?.history}
      />
    </>
  );
  let userFormFooter = (
    <>
      <SaveButton type="submit" label={"Add"} />
    </>
  );
   let memberBody = (
    <>
        {detail?.length > 0 && (
       <div>
       {detail?.map((user) => (
         <div key={user.channelUserId} className="d-flex align-items-center justify-content-between mb-2 p-1 shadow-sm bg-gray-100 rounded border-0">
           <div className="d-flex align-items-center">
             <Link
               to={`/user/${user?.userId}`}
               className="link-opacity-75 text-primary"
               target="_blank"
             >
               <UserCard
                 id="avatar"
                 firstName={user?.userName}
                 url={user?.userImage}
                 locationName={user?.current_location_name}
                 shiftName={user?.current_shift_name}
                 className="text-secondary text-xs"
               />
             </Link>
           </div>
           {user?.isStaticUser &&
           <FontAwesomeIcon icon={faTrash} className="me-2 text-red-500 cursor-pointer" onClick={()=>{
            setMemberDelete(true)
            setSelectedUser(user)
          }
            }/>
}
         </div>
       ))}
     </div>
      )}
    </>
  );

  const handleDeleteFunction = async (id) => {
    dispatch(
      await MessageChannelService.delete(id, (res) => {
        if (res) {
          deleteModelToggle();
          getChannelList && getChannelList(true);
        }
      })
    );
  };
  const handleUserAdd = async (values) => {
    let data = new FormData();
    if (ArrayList.isEmpty(selectedIds)) {
      return Toast.error("Please Select Id(s)");
    }
    data.append("channel_user", selectedIds);
    data.append("channel_id", activeChannel && activeChannel?.channel_id);
    data.append("channel_name", activeChannel && activeChannel?.channel_name);
    dispatch(
      await MessageChannelUser.create(data, (res) => {
        if (res) {
          toggle();
          getChannelUserList(activeChannel?.channel_id);
          setSelectedIds([]);
        }
      })
    );
  };

  const handleSendMessage = async () => {
    if (!currentMessage && !attachment) {
      return null;
    }
    let data = new FormData();
    data.append("message", currentMessage);
    data.append("channel_id", activeChannel && activeChannel?.channel_id);
    if (attachment) {
      const file = Media.ConvertBase64ToFile(attachment, fileName);
      data.append("media_file", file ? file : "");
      data.append("media_name", fileName ? fileName : "");
    }

    let messageResponse = await ChannelMessagesService.Create(data);
    if (messageResponse) {
      removeAttachment()
      getChannelList && getChannelList();
      getChannelMessagesList();
      setCurrentMessage("");
      setSelectedIds([]);
      setAttachment();
    }
  };

  // handle attachment
  const handleAttachmentChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.startsWith("image/") && !file.type.startsWith("video/")) {
      Toast.error("Please upload an image or video file only!");
      return;
  }
    if (file) {
      setFileName(file.name)
      const reader = new FileReader();
      reader.readAsDataURL(file); // ✅ Convert to Base64
      reader.onload = () => {
        const base64Image = reader.result; // ✅ Get Base64 string
        setAttachment && setAttachment(base64Image);
        if(file.type.startsWith("image/")){
        if(activeChannel && activeChannel?.channel_id ){
          localStorage.setItem(
            "channelAttachment",
            JSON.stringify({
              channelId: activeChannel?.channel_id,
              previewURL: base64Image,
              name:file.name
            })
          );
        }else{
            localStorage.setItem(
              "channelAttachment",
              JSON.stringify({
                channelId: activeChannel?.channel_id,
                previewURL: base64Image,
                name:file.name
              })
            );
        }
      };
    }
    }
  };


  // group message by date
  const groupMessagesByDate = (messages) => {
    const grouped = messages.reduce((acc, msg) => {
      const dateKey = moment(msg.timestamp).format("DD-MMM-YY");
      if (!acc[dateKey]) acc[dateKey] = [];
      acc[dateKey].push(msg);
      return acc;
    }, {});

    return Object.keys(grouped)
      .sort((a, b) => new Date(b) - new Date(a)) // 🔹 Sort dates in descending order (newest first)
      .reduce((acc, key) => {
        acc[key] = grouped[key].sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp) // 🔹 Messages still sorted ascending within each date
        );
        return acc;
      }, {});
  };
  const groupedMessages = groupMessagesByDate(messages);
  // remove media
  const removeAttachment = () => {
    setAttachment(null);
    localStorage.removeItem("channelAttachment");
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={deleteModelToggle}
        title="Delete Group"
        deleteFunction={() => {
          handleDeleteFunction(activeChannel && activeChannel?.channel_id);
        }}
        label={activeChannel && activeChannel?.channel_name}
        id={activeChannel && activeChannel?.channel_id}
      />
      {imageModal && isVideoURL(attachment) && (
        <Modal
          isOpen={imageModal}
          onRequestClose={() => {
            setImageModal(false);
          }}
          contentLabel="Video Modal"
        >
          <div className="modal-content p-0">
            <div className="d-flex justify-content-end">
              <FontAwesomeIcon
                icon={faXmarksLines}
                className="cursor-pointer align-right fw-bold h3 mt-0 pt-0"
                onClick={() => {
                  setImageModal(false);
                }}
              />
            </div>
            <VideoPlayer
              className="react-player"
              url={attachment}
              controls={true}
              style={{ backgroundImage: attachment }}
              volume={0}
              playInlineVideo={true}
              setImageModal={setImageModal}
            />
          </div>
        </Modal>
      ) }
      <DeleteModal
        isOpen={openMemberDelete}
        toggle={deleteMemberToggle}
        title="Delete Member"
        deleteFunction={() => {
          handleUserDelete(selectedUser && selectedUser?.messageChannelUserId);
        }}
        label={selectedUser && selectedUser?.userName}
        id={selectedUser && selectedUser?.messageChannelUserId}
      />
      <Drawer
        modelTitle="Members"
        DrawerBody={memberBody}
        onSubmit={(values) => handleUserAdd(values)}
        initialValues={{ member: "" }}
        handleOpenModal={toggleMember}
        handleCloseModal={toggleMember}
        handleDrawerClose={toggleMember}
        isModalOpen={memberToggle}
        enableReinitialize={true}
      />
       <Drawer
        modelTitle="Add User"
        DrawerBody={userFormBody}
        DrawerFooter={userFormFooter}
        onSubmit={(values) => handleUserAdd(values)}
        initialValues={{ channel_user: "" }}
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize={true}
      />
      <GroupForm
        getChannelList={getChannelList}
        toggle={toggleChannel}
        isOpen={channelOpen}
        detail={activeChannel}
      />

      <div
        className="d-flex align-items-center  p-2 shadow-sm w-100"
        style={{
          backgroundColor: "#075E54",
          color: "white",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <div className="col-3">
          <h3>{activeChannel?.channel_name}</h3>
        </div>
        <div
          className="d-flex align-items-center justify-content-end shadow-sm w-100 gap-1 p-0 m-0"
          style={{
            backgroundColor: "#075E54",
            color: "white",
            width: "100px",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          {uniqueUsers.slice(0, 7).map((user, index) =>
            user.userImage ? (
              <img
                key={user.userId}
                src={user.userImage}
                alt={user.userName}
                className="user-avatar"
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "35%",
                  border: "2px solid white",
                  objectFit: "cover",
                  marginLeft: index === 0 ? "0" : "-15px",
                  zIndex: uniqueUsers.length - index,
                  position: "relative",
                  marginRight: "2px",
                }}
                onError={(e) => (e.target.style.display = "none")} // Hide broken images
              />
            ) : (
              <div
                key={user.userId}
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "35%",
                  backgroundColor: "grey",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  border: "2px solid white",
                  marginLeft: index === 0 ? "0" : "-15px",
                  zIndex: uniqueUsers.length - index,
                  position: "relative",
                  marginRight: "2px",
                }}
              >
                {user.userName ? user.userName.slice(0, 2) : "??"}
              </div>
            )
          )}

          {/* Show count of extra users if more than 8 */}
          {uniqueUsers.length > 4 && (
            <div
              className="user-count"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "35%",
                backgroundColor: "grey",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "14px",
                fontWeight: "bold",
                marginLeft: "-10px",
                border: "2px solid white",
              }}
            >
              +{uniqueUsers.length - 4}
            </div>
          )}
        </div>
        <button onClick={refreshValue} className=" mx-2">
          <i
            className={`fa ${
              refresh ? "fa-sync fa-spin" : "fa-refresh"
            } text-white`}
          />
        </button>
        {hasEditPermission && (
          <MoreDropdown className="text-white">
            <DropdownItem
              onClick={() => {
                toggle();
              }}
            >
              Add User
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                toggleChannel();
              }}
            >
              Edit
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setIsMemberToggle(true);
              }}
            >
              Members
            </DropdownItem>
            {hasDeletePermission && (
              <DropdownItem
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
                className="text-danger"
              >
                Delete
              </DropdownItem>
            )}
          </MoreDropdown>
        )}
      </div>

      <div
        className={`bg-white d-flex flex-column ${isOpen ? "" : `max-h-[73%]`}`}
      >
        <div
          ref={messagesContainerRef}
          className="overflow-auto flex-grow-1"
          style={{ scrollbarWidth: "none" }}
        >
          <div
            className="text-wrap overflow-auto mt-1 pb-3 pb-0 flex-grow-1 h-screen"
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              scrollbarColor: "transparent transparent",
            }}
          >
            {Object.entries(groupedMessages).map(([date, msgs], dateIndex) => (
              <div key={dateIndex}>
                {/* Date Separator */}
                <div className="text-center">
                  <span
                    className="px-3 py-1 bg-secondary text-white rounded"
                    style={{ fontSize: "12px" }}
                  >
                    {date}
                  </span>
                </div>

                {/* Messages */}
                {msgs.map((msg, index) => {
                  const isSender = msg.user_id === logUser?.id;
              // Format first_name with location & shift info if permission exists
              let locationPart = msg?.current_location_name ? `${msg?.current_location_name}` : "";
              let shiftPart = msg?.current_shift_name ? `${msg?.current_shift_name}` : "";
              let locationShiftPart =
                locationPart && shiftPart
                  ? `(${locationPart}, ${shiftPart})`
                  : locationPart
                  ? `(${locationPart})`
                  : shiftPart
                  ? `(${shiftPart})`
                  : "";
                  return (
                    <div
                      key={index}
                      className={`d-flex p-2 align-items-center ${
                        isSender
                          ? "justify-content-end"
                          : "justify-content-start"
                      }`}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: isSender ? "flex-end" : "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        {/* Avatar on top */}
                        <div className="d-flex jsutify-content-between">
                        <AvatarCard
                          id="avatar"
                          size="xs"
                          url={
                            isSender
                              ? logUser?.avatarUrl
                              : msg?.media || msg?.userImage
                          }
                          firstName={isSender ? logUser?.name : msg?.first_name}
                          lastName={
                            isSender ? logUser?.lastName : msg?.last_name
                          }
                          style={{ marginBottom: 4 }}
                        />
{msg?.showLocationShiftPermission && locationShiftPart && (
            <span className="ms-1 text-secondary pt-2 m-0" style={{ fontSize: "12px"}}>
              {locationShiftPart}
            </span>
          )}
          </div>
                        {/* Message bubble below */}
                        <MessageBubble
                          msg={msg}
                          showDeleteButton={false}
                          manageOthersPermission={false}
                          fetchData={getChannelMessagesList}
                          width="100%"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col p-1 sticky bottom-0 w-full">
        {/* Chat Input Section */}
        <div className="d-flex align-items-center">
          <div>
            <input
              type="file"
              id="attachment"
              style={{ display: "none" }}
              accept="image/*,video/*"
              onChange={(event) => {
                handleAttachmentChange(event); // Handle file selection
              }}
            />
            <FontAwesomeIcon
              size="2x"
              className="icon-pointer text-success"
              icon={faCirclePlus}
              onClick={() => document.getElementById("attachment").click()}
            />        {/* Preview Section */}
       

          </div>
          <div className="flex items-center p-2 mx-1 bg-white rounded-full border border-gray-300 shadow-sm w-100">
            {/* Image on Left */}
            {attachment && attachment.trim() !== "" && (
          <div
            className="d-flex align-items-start justify-content-end pr-2"
            style={{
              position: "relative",
              width: "50",
              zIndex: 1,
            }}
          >{isVideoURL(fileName) ? (
            <VideoPlayer
              className="react-player"
              url={attachment}
              controls={true}
              style={{ backgroundImage: attachment }}
              volume={0}
              height="50px"
              playInlineVideo={true}
              setImageModal={setImageModal}
            />
          ) :(
            <Image
              src={attachment}
              alt="No Image"
              className=""
              width="50px"
              height="50px"
              alignRight
              borderRadius="15px"
            />
          )}

            {/* Close (X) Button */}
            <FaTimes              
            style={{
                position: "absolute",
                right: "6px",
                cursor: "pointer",
                color: "white",
                backgroundColor: "red",
                borderRadius: "30%",
                fontSize: "12px",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
              }}
              onClick={removeAttachment}
            />
          </div>
        )}

            {/* Text Area */}
            <textarea
              ref={textAreaRef}
              className="flex-grow border-0"
              placeholder="Type your message here..."
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              rows={1}
              style={{
                resize: "none",
                overflow: "hidden",
                width: "100%",
                outline: "none",
                background: "transparent",
              }}
            />

            {/* Send Button */}
            <button
              className="relative cursor-pointer"
              onClick={handleSendMessage}
            >
              <FontAwesomeIcon icon={faPaperPlane} color="green" size="2x" />
              {attachment && (
                <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold rounded-full px-1.5 py-0.5">
                  1
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChannelMessage;
