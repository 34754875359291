import React, { useEffect, useState } from "react";

// API
import { endpoints } from "../../../api/endPoints";
import { apiClient } from "../../../apiClient";

// Components
import CancelButton from "../../../components/CancelButton";
import Currency from "../../../components/Currency";
import DateSelector from "../../../components/Date";
import Form from "../../../components/Form";
import NumberComponent from "../../../components/Number";
import SaveButton from "../../../components/SaveButton";
import TagSelect from "../../../components/TagSelect";
import Toast from "../../../components/Toast";
import UserSelect from "../../../components/UserSelect";

// Helpers
import { TagTypeNames } from "../../../helpers/Tag";

// Lib
import DateTime from "../../../lib/DateTime";
import { isBadRequest } from "../../../lib/Http";
import ArrayList from "../../../lib/ArrayList";
import Number from "../../../lib/Number";

// Services
import CompanyUserService from "../../../services/CompanyUserService";

const UserEmployment = (props) => {
  const { history, id } = props;
  const [userDetail, setUserDetail] = useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [designation, setDesignation] = useState(null);
  const [salary, setSalary] = useState("");
  const [minimumWorkingHours, setMinimumWorkingHours] = useState();
  const [loginTime, setLoginTime] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [resignationDate, setResignationDate] = useState();
  const [leaveBalance, setLeaveBalance] = useState(null);
  const [userEmploymentDetail, setUserEmploymentDetail] = useState(null);
  const [userList, setUserList] = useState([]);
  const [manager, setManager] = useState("");
  const [salaryPerDay, setSalaryPerDay] = useState("");


  const getUserDetail = async () => {
    const userDetail = await CompanyUserService.get(id);
    setUserDetail(userDetail);
    setSalary(userDetail?.salary);
    setSalaryPerDay(userDetail?.salary_per_day);
    setSelectedDays(userDetail.workingDays.split(","));
    setUserEmploymentDetail(userDetail);
  };

  useEffect(() => {
    getUserDetail();
    getMinimumWorkingHours();
  }, []);

  const getMinimumWorkingHours = () => {
    let minimumWorkinHours = new Array();

    for (let i = 1; i <= 12; i++) {
      minimumWorkinHours.push({
        label: `${i} ${i == 1 ? "Hour" : "Hours"}`,
        value: i,
      });
    }
    setMinimumWorkingHours(minimumWorkinHours);
  };

  const handleDesignationChange = (selectedOption) => {
    setDesignation(selectedOption);
  };

  const updateUserEmployment = async (values) => {
    try {
      const response = await apiClient.put(
        `${endpoints().userEmploymentAPI}/update/${id}`,
        values
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        Toast.success(successMessage);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  const handleUserEmployment = async (value) => {
    const values = new FormData();
    values.append("start_date", value.start_date ? value.start_date : "");
    values.append("end_date", value.end_date ? value.end_date : "");
    values.append(
      "designation",
      value?.designation?.value ? value?.designation?.value : ""
    );
    values.append("salary", Number.Get(value?.salary));
    values.append("salary_per_day", Number.Get(value?.salary_per_day));
    values.append(
      "location",
      value?.location?.value ? value?.location?.value : ""
    );

    values.append("working_days", selectedDays ? selectedDays : "");
    values.append(
      "login_time",
      value.login_time ? DateTime.getTime(value.login_time) : ""
    );
    values.append(
      "minimum_working_hours",
      value.minimumWorkingHours ? value.minimumWorkingHours.value : ""
    );
    values.append("leave_balance", value ? value.leave_balance : "");
    values.append("manager", value && value.manager ? value.manager?.id : "");
    values.append("resignation_date", value?.resignation_date ? value?.resignation_date : "");


    updateUserEmployment(values);
  };

  const convertTimeStringToDate = (timeString) => {
    const [hours, minutes] = timeString && timeString.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  };

  const handleSalaryChange = (value) => {
    setSalary(value?.target?.value);
  };
  const handlePerDaySalaryChange = (value) => {
    setSalaryPerDay(value?.target?.value);
  };


  const handleStartDate = async (startDate) => {
    const date = startDate ? DateTime.toISOStringDate(startDate) : "";
    setStartDate(date);
  };

  const handleEndDate = async (endDate) => {
    const date = endDate ? DateTime.toISOStringDate(endDate) : "";
    setEndDate(date);
  };

  const handleResignationDate = async (value) => {
    const date = value ? DateTime.toISOStringDate(value) : "";
    setResignationDate(date);
  };

  const handleLeaveBalance = (e) => {
    let data = e && e?.values && e?.values?.leave_balance;
    setLeaveBalance(data);
  };

  const handleMangerChange = async (e) => {
    setManager(e.id);
  };

  const loginTimes = userDetail.login_time
    ? convertTimeStringToDate(userDetail.login_time)
    : null;

  return (
    <Form
      enableReinitialize={true}
      initialValues={{
        login_time: loginTime ? loginTime : loginTimes ? loginTimes : "",
        designation: designation
          ? designation
          : userEmploymentDetail?.designation
            ? {
              label: userEmploymentDetail?.designation,
              value: userEmploymentDetail?.designationId,
            }
            : "",
        salary: salary === "" ? "" : salary ? salary : "",
        salary_per_day: salaryPerDay === "" ? "" : salaryPerDay ? salaryPerDay : "",
        location: location ? location : "",
        start_date:
          startDate === ""
            ? ""
            : startDate
              ? startDate
              : userEmploymentDetail?.start_date,
        end_date:
          endDate === ""
            ? ""
            : endDate
              ? endDate
              : userEmploymentDetail?.end_date || "",
        minimumWorkingHours: userDetail.minimum_working_hours
          ? minimumWorkingHours.find(
            (data) => data.value == userDetail.minimum_working_hours
          )
          : "",
        leave_balance:
          leaveBalance === ""
            ? ""
            : leaveBalance
              ? leaveBalance
              : userDetail?.leave_balance
                ? userDetail?.leave_balance
                : "",
        manager: 
          ArrayList.isNotEmpty(userList) && userList.find((data) => data.id == userEmploymentDetail?.manager),
          resignation_date:
          resignationDate === ""
            ? ""
            : resignationDate
              ? resignationDate
              : userEmploymentDetail?.resignation_date || "",
      }}
      onSubmit={(values) => {
        handleUserEmployment(values);
      }}
    >
      <div className="card bg-white mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <TagSelect
                    name="designation"
                    label="Designation"
                    placeholder="Select Designation"
                    params={{ type: TagTypeNames.Designation }}
                    handleTagChange={handleDesignationChange}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <Currency
                    label="Salary"
                    name="salary"
                    onChange={handleSalaryChange}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <Currency
                    label="Salary Per Day "
                    name="salary_per_day"
                    onChange={handlePerDaySalaryChange}
                  />
                </div>
              </div>
                <div className="col-12 col-sm-4">
                  <NumberComponent
                    name="leave_balance"
                    label="Leave Balance"
                    placeholder="Enter Leave Balance"
                    onInputChange={handleLeaveBalance}
                  />
                </div>

              <div className="row">
                <div className="col-12 col-sm-6">
                  <DateSelector
                    name="start_date"
                    label="Start Date"
                    onChange={handleStartDate}
                    isClearable
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <DateSelector
                    name="end_date"
                    label="End Date"
                    onChange={handleEndDate}
                    isClearable
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <UserSelect
                    label="Manager"
                    name="manager"
                    handleUserChange={handleMangerChange}
                    userList={setUserList}
                  />
                </div>
                <div className="col-12 col-sm-6">
                <DateSelector
                    name="resignation_date"
                    label="Resignation Date"
                    onChange={handleResignationDate}
                    isClearable
                  />
                </div>
              </div>
              <br />
              <div className="mb-4">
                <div>
                  <SaveButton />
                  <CancelButton onClick={() => history?.push("/users")} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default UserEmployment;
