import React, { useState } from "react";
import { endpoints } from "../../api/endPoints";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import UserCard from "../../components/UserCard";
import BreadCrumb from "../../components/Breadcrumb";
import { Link } from "react-router-dom";

const ReplenishmentAllocationReport = (props) => {
  let { history } = props;

  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report",
    },
    {
      label: "Replenishment Allocation Report",
      link: "",
    },
  ];

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <ReduxTable
        id="replenishmentAllocationReport"
        showHeader
        newTableHeading
        apiURL={`${endpoints().replenishmentAllocationAPI}/report`}
        searchPlaceholder="Search"
        paramsToUrl={true}
        history={history}
        sortByDropdown
        params={{}}
        showUserFilter
      >
        <ReduxColumn
          field="user"
          sortBy="user"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          renderField={(row) => (
            <div>
              <div className=" mx-1 row justify-content-between">
                <UserCard
                  firstName={row?.ownerName}
                  url={row?.media_url}
                  lastName={row?.last_name}
                />
              </div>
            </div>
          )}
        >
          User
        </ReduxColumn>
        <ReduxColumn
          field="pendingStatus"
          disableOnClick
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="align-top text-center"
          renderField={(row) => (
            <Link
              target="_blank"
              to={`/replenishmentProduct?status=${row.pendingStatusId}&user=${row.ownerId}`}
            >
              {row.pendingStatus}
            </Link>
          )}
        >
          Pending
        </ReduxColumn>
        <ReduxColumn
          field="completedStatus"
          disableOnClick
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="align-top text-center"
          renderField={(row) => (
            <Link
              target="_blank"
              to={`/replenishmentProduct?status=${row.completedStatusId}&user=${row.ownerId}`}
            >
              {row.completedStatus}
            </Link>
          )}
        >
          Completed
        </ReduxColumn>
        <ReduxColumn
          field="replenishCount"
          sortBy="replenishCount"
          disableOnClick
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-center"
          renderField={(row) => `${row.replenishCount}`}
        >
          Product Count
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

export default ReplenishmentAllocationReport;
