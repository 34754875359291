import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import CancelButton from "../../components/CancelButton";
import AddModal from "../../components/Modal";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import SelectStore from "../../components/SelectStore";
import TextArea from "../../components/TextArea";
import Text from "../../components/Text";
import UserSelect from "../../components/UserSelect";
import AttendanceTypeSelect from "../../components/AttendanceTypeSelect";

// Helpers
import {
  attendanceStatusOptions,
  lateHoursStatusOptions,
  statusOptions
} from "../../helpers/Attendance";

// Services
import AttendanceService from "../../services/AttendanceService";
import AttendanceTypeService from "../../services/AttendanceTypeService";
import NumberSelect from "../../components/NumberSelect";
import String from "../../lib/String";
import Drawer from "../../components/Drawer";

const BulkUpdateModal = (props) => {
  const {
    isOpen,
    toggle,
    usersList,
    attendanceIds,
    storeList,
    shiftList,
    param,
    params,
    setSelectedCheckBox,
    setSelectedIds
  } = props;
  const [typeList, setTypeList] = useState([]);
  const [notesChange, setNotesChange] = useState();
  const [daysCount, setDaysCount] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectTypeValue, setSelectTypeValue] = useState();
  const [storeValue, setStoreValue] = useState();
  const [shiftValue, setShiftValue] = useState();
  const [lateHoursStatusValue, setLateHoursStatusValue] = useState()
  const [attendanceStatus, setAttendanceStatus] = useState();
  const [activityStatus, setActivityStatus] = useState();

  useEffect(() => {
    getAttendanceTypes()
  }, [])


  const getAttendanceTypes = async () => {
    let data = await AttendanceTypeService.list()
    setTypeList(data)
  }

  const handleNotesChange = (e) => {
    let value = e?.target?.value ? e?.target?.value : "";
    setNotesChange(value);
  };

  const handleUserChange = (values) => {
    setSelectedUser(values);
  };

  const handleTypeChange = (e) => {
    setSelectTypeValue(e);
  };

  const handleShiftChange = (x) => {
    let data = x?.values?.shift ? x?.values?.shift : "";
    setShiftValue(data);
  };

  const handleLateHoursChange = (e) => {
    let value = e && e?.values && e?.values?.late_hours_status;
    setLateHoursStatusValue(value);
  };

  const handleAttendanceStatusChange = (e) => {
    let value = e && e?.values && e?.values?.attendance_status;
    setAttendanceStatus(value);
  }

  const handleActivityStatusChange = (e) => {
    let value = e && e?.values && e?.values.activity_status;
    setActivityStatus(value);
  }

  const handleStoreChange = (x) => {
    let data = x ? x : "";
    setStoreValue(data);
  };


  const handleDaysCountChange = (e) => {

    setDaysCount(e.target.value||"");
  };

  // Use Dispatch
  const dispatch = useDispatch();

  // Bulk Update modal Body
  const bulkUpdateBody = (
    <>
      <UserSelect
        label="User"
        name="user"
        handleUserChange={handleUserChange}
        userList={usersList}
      />
      <AttendanceTypeSelect
        name="type"
        handleChange={handleTypeChange}
        accountTypeOption={(x) => props?.accountTypeOption(x)}
      />
      <SelectStore
        name="location"
        label="Location"
        handleStoreChange={handleStoreChange}
      />
      <Select
        name="shift"
        label="Shift"
        placeholder="Select Shift"
        onInputChange={handleShiftChange}
        options={shiftList}
      />
      <Select
        name="late_hours_status"
        label="Late Hours Status"
        placeholder="Select Late Hours Status"
        options={lateHoursStatusOptions}
        onInputChange={handleLateHoursChange}
      />
      <Select
        name="attendance_status"
        label="Attendance Status"
        placeholder="Select Status"
        options={attendanceStatusOptions}
        onInputChange={handleAttendanceStatusChange}
      />
      <Select
        name="activity_status"
        label="Activity Status"
        placeholder="Select Activity Status"
        onInputChange={handleActivityStatusChange}
        options={statusOptions}
      />
      <Text
        name="days_count"
        label="Days Count"
        placeholder="Enter Days Count"
        onChange={handleDaysCountChange}
        limit={10}
      />
      <TextArea
        name="notes"
        label="Notes"
        placeholder="Reason"
        onChange={handleNotesChange}
      />
    </>
  );

  // Bulk Update modal footer
  const bulkUpdateFooter = (
    <div>
      <SaveButton />
    </div>
  );

  // Initial Values
  const initialValues = {
    user: selectedUser ? selectedUser : "",
    type: selectTypeValue ? typeList.find((value) => value.value == selectTypeValue.value) : "",
    late_hours_status: lateHoursStatusValue ? lateHoursStatusValue : "",
    attendance_status: attendanceStatus ? attendanceStatus : "",
    activity_status: activityStatus ? activityStatus : "",
    notes: notesChange ? notesChange : "",
    location: storeValue ? storeValue : "",
    shift: shiftValue ? shiftList.find((data) => data.value == shiftValue?.value) : "",
    days_count: daysCount || "" ,
  };

  // Handle on bulk update
  const handleBulkUpdate = (data) => {
    if(String.isObjectEmpty(data)){
      return
    }

    data.ids = attendanceIds;

    data.user = data.user && data.user.value ? data.user.id : "";

    data.days_count = data && data.days_count ? data.days_count : "";
    data.type = data.type && data.type.value ? data.type.value : "";

    data.late_hours_status = data.late_hours_status.value
      ? data.late_hours_status.value
      : "";

    data.attendance_status = data.attendance_status.value
      ? data.attendance_status.value
      : "";

    data.activity_status =
      data.activity_status && data.activity_status.value
        ? data.activity_status.value
        : "";

    data.notes = data.notes ? data.notes : "";
    dispatch(AttendanceService.bulkUpdateAttendance(data, toggle, params));
    setSelectedIds("");
    setNotesChange("");
    setDaysCount("");
    setSelectedUser("");
    setSelectTypeValue("");
    setStoreValue("");
    setShiftValue("");
    setLateHoursStatusValue("");
    setAttendanceStatus("");
    setActivityStatus("");
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  return (
    <div>
      {/* Bulk Update Modal */}
      <Drawer
        modelTitle="Bulk Update"
        DrawerBody={bulkUpdateBody}
        DrawerFooter={bulkUpdateFooter}
        onSubmit={(values) => {
          handleBulkUpdate(values);
        }}
        initialValues={initialValues}
        enableReinitialize
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
      />
    </div>
  );
};
export default BulkUpdateModal;
