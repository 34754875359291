import React, { useEffect, useState } from "react";
import Filter from "../../../components/Filter";
import ArrayList from "../../../lib/ArrayList";
import DateTime from "../../../lib/DateTime";
import Url from "../../../lib/Url";
import DailySummaryReportService from "../../../services/DailySummaryReportService";

const DailySummaryReportFilter = (props) => {
    let { history, setData } = props;

    const [searchItem, setSearch] = useState(Url.GetParam("search"));
    const [spinValue, setSpin] = useState(false);
    const [allParams, setAllParams] = useState({ ...Url.GetAllParams() });

    useEffect(() => {
        getDetails(allParams)
    }, [])


    let getDetails = async (params) => {
        let { data: { data } } = await DailySummaryReportService.getReport(params);
        setData && setData(data)
    }



    const UpdateUrl = (params) => {
        const currentPage = window.location.pathname;
        let queryString = "";
        const queryStringArray = Object.entries(params);

        if (queryStringArray.length > 0) {
            queryString = "?";
            queryStringArray.forEach(async (queryParam) => {
                if (queryParam[1] !== "")
                    queryString = `${queryString}&${queryParam[0]}=${queryParam[1]}`;
            });
        }
        if (history) {
            history.push(`${currentPage}${queryString}`);
        }
    };

    let filterInitialValues = {
        date: DateTime.getDate(Url.GetParam("date")),
    };

    const handleSearchTermChange = (e) => {
        const searchValue = e.target.value;
        setSearch(searchValue);
        setAllParams((preValue) => ({
            ...preValue,
            search: searchValue,
        }));
    };

    const onSearchKeyUp = async (event) => {
        if (event.charCode === 13) {
            let params = {
                ...allParams,
                search: searchItem,
            };

            getDetails(params)
            UpdateUrl(params);
        }
    };

    

    const refreshButtonOnClick = async () => {
        const filteredValues = Object.fromEntries(
            Object.entries(allParams)
                .filter(([key, _]) => !isNaN(key))
                .map(([_, value]) => {
                    const [prop, val] = value?.split("=");
                    return [prop, val];
                })
        );
        setSpin(true);
        getDetails(filteredValues)
        setSpin(false);
    };

    const handleFilter = (values) => {
        values.date = values?.date ? DateTime.getDate(values?.date): "";
        let filterParams = ArrayList.toQueryString(values);
        const filteredValues = Object.fromEntries(
            Object.entries(filterParams)
                .filter(([key, _]) => !isNaN(key))
                .map(([_, value]) => {
                    const [prop, val] = value?.split("=");
                    return [prop, val];
                })
        );
        getDetails(filteredValues)
        UpdateUrl(filteredValues);
        setAllParams(filteredValues);
    };

    const handleDeleteFilter = async (removedFilter) => {
        let params = {
            ...allParams,
            ...removedFilter,
        };
        getDetails(params)
        UpdateUrl(params);
        setAllParams(params);
    };

    return (
        <div>
            <Filter
                showHeader
                newTableHeading
                pageSearchOnChange={(e) => {
                    handleSearchTermChange(e);
                }}
                sortByDropdown
                pageSearchValue={searchItem}
                searchPlaceholder="Search"
                refreshButtonOnClick={refreshButtonOnClick}
                refreshValue={spinValue}
                handleDeleteFilter={handleDeleteFilter}
                initialValues={filterInitialValues}
                handleFilter={handleFilter}
                onKeyPress={onSearchKeyUp}
                showSingleDateFilter
            />
        </div>
    );
};

export default DailySummaryReportFilter;
