import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Components
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
// Endpoints
import { endpoints } from "../../../api/endPoints";
// Lib
import Currency from "../../../lib/Currency";
// Helpers
import ObjectName from "../../../helpers/ObjectName";
// services
import StatusService from "../../../services/StatusService";
// Font Awesome Icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import StatusText from "../../../components/StatusText";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import { DropdownItem } from "reactstrap";
const BillList = (props) => {
  const { history, section, StoreSelectModal, setRowValue, apiUrl, vendorId, purchase_id, showAccountFilter = true } = props;
  const [statusList, setStatusList] = useState([]);
  useEffect(() => {
    getStatus();
  }, []);

  //Sort By Option Values
  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "account_name:ASC",
      label: "Name",
    },
  ];

  const getStatus = async () => {
    const status = await StatusService.search(ObjectName.BILL, "");
    const value = [];
    status.forEach((statusValue) => {
      value.push({
        value: statusValue?.id,
        label: statusValue?.name,
        id: statusValue?.id,
      });
    });
    setStatusList(value);
  };

  let param = {};

  if (section) {
    param.section = section;
  }
  if (purchase_id) {
    param.purchase_id = purchase_id;
  }

  param.objectName = ObjectName.BILL;

  if (vendorId) {
    param.account_id = vendorId
  }
  param.showTotalAmount = true

  return (
    <>
      <ReduxTable
        id="billId"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={apiUrl ? apiUrl : `${endpoints().billAPI}/search`}
        params={param}
        paramsToUrl={true}
        history={history}
        sortByOptions={sortByOption}
        message="You can start by clicking on Add New"
        icon={<FontAwesomeIcon icon={faCartShopping} />}
        customStatusOption={statusList}
        showStatusFilter
        showCustomDateFilter
        showAccountFilter={showAccountFilter}
        showGstStatusFilter
        objectName={ObjectName.BILL_GST_STATUS}
        showBackgroundColor
        isMultiStatus
      >
        <ReduxColumn
          field="bill_number"
          width="120px"
          minWidth="120px"
          className="text-center"
          maxWidth="220px"
          type="link"
          sortBy="bill_number"
          isClickable="true"
          renderField={(row) => (
            <Link to={`/bill/detail/${row.id}`} className="link-opacity-75">{row.bill_number}</Link>
          )}
        >
          Bill#
        </ReduxColumn>
        <ReduxColumn
          field="invoiceNumber"
          className="text-center"
          width="140px"
          minWidth="140px"
          maxWidth="140px"
          sortBy="invoice_number"
        >
          Invoice Number
        </ReduxColumn>
        <ReduxColumn
          field="bill_date"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          sortBy="bill_date"
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="account_name"
          sortBy="account_name"
          minWidth="100px"
          maxWidth="100px"
        >
          Account
        </ReduxColumn>
        <ReduxColumn
          field="netAmount"
          className="text-end"
          renderField={(row) => <span>{Currency.Format(row.netAmount)}</span>}
          width="80px"
          minWidth="110px"
          maxWidth="80px"
          sortBy="net_amount"
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy="status"
          width="80px"
          minWidth="110px"
          maxWidth="80px"
          renderField={(row) => (
            <StatusText backgroundColor={row.colorCode} status={row.status} />
          )}
        >
          Status
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRowValue(row);
                      StoreSelectModal();
                    }}
                  >
                    Quick View
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Actions
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};
export default BillList;
