import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import CurrencyComponent from "../../components/Currency";
import TagSelect from "../../components/TagSelect";
import SaveButton from "../../components/SaveButton";
import Drawer from "../../components/Drawer";
import PercentageComponent from "../../components/Percentage";
import DeleteModal from "../../components/DeleteModal";

// API
import { endpoints } from "../../api/endPoints";

// Services
import TaxService from "../../services/TaxService";

// Helpers
import ObjectName from "../../helpers/ObjectName";

// Lib
import Currency, { Percentage } from "../../lib/Currency";

const TaxList = ({ toggles, isOpen, id }) => {
  const [taxType, setTaxType] = useState("");
  const [detail, setDetail] = useState("");
  const [amount, setAmount] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [taxAmount, setTaxAmount] = useState(null);
  const [tagList, setTagList] = useState([]);
  const [isDeleteModelOpen, setDeleteModelOpen] = useState(false);

  const dispatch = useDispatch();

  const handleTaxType = (e) => {
    const value = e?.values?.tax_type?.id;
    setTaxType(value);
  };

  const handleAmountChange = (e) => {
    let value = e.target.value ? e.target.value : null;
    setAmount(value);
  };

  const handleTaxAmountChange = (e) => {
    let value = e.target.value ? e.target.value : null;
    setTaxAmount(value);
  };

  const handlePercentageChange = (e) => {
    let value = e?.values?.tax_percentage ? e?.values?.tax_percentage : null;
    setPercentage(value);
  };

  const addTaxForm = (
    <>
      <TagSelect
        name="tax_type"
        label="Type"
        placeholder="Type"
        params={{ type: "Tax" }}
        onChange={handleTaxType}
        TagList={setTagList}
        defaultValue={taxType || detail?.taxTypeId}
        required
      />
      <CurrencyComponent
        name="amount"
        label="Amount"
        onChange={handleAmountChange}
      />
      <PercentageComponent
        name="tax_percentage"
        label="Tax Percentage"
        onInputChange={handlePercentageChange}
      />
      <CurrencyComponent
        name="tax_amount"
        label="Tax Amount"
        onChange={handleTaxAmountChange}
      />
    </>
  );

  const taxFooter = (
    <>
      <SaveButton type="submit" label={detail ? "Update" : "Add"} />
    </>
  );

  const initialValues = {
    tax_type:
      tagList &&
      tagList.length > 0 &&
      tagList.find((item) => item.value == detail?.taxTypeId ? detail?.taxTypeId : taxType),
    tax_amount: taxAmount ? taxAmount : detail ? detail?.taxAmount : "",
    amount: amount ? amount : detail ? detail?.amount : "",
    tax_percentage: percentage
      ? percentage
      : detail
        ? detail?.taxPercentage
        : "",
  };

  const handleSubmit = async (values) => {
    let params = { object_id: id, object_name: ObjectName.BILL };
    let data = new FormData();
    if (detail?.id) {
      data.append(
        "tax_type",
        values && values?.tax_type?.id ? values?.tax_type?.id : ""
      );
      data.append(
        "tax_amount",
        values && values?.tax_amount ? values?.tax_amount : ""
      );
      data.append("amount", values && values?.amount ? values?.amount : "");
      data.append(
        "tax_percentage",
        values && values?.tax_percentage ? values?.tax_percentage : ""
      );
      data.append("object_name", ObjectName.BILL);
      data.append("object_id", id);
      TaxService.update(data, toggles, dispatch, params, detail?.id, setDetail);
      setAmount("");
      setPercentage("");
      setTaxAmount("");
      setTaxType("");
    } else {
      data.append(
        "tax_type",
        values && values?.tax_type?.id ? values?.tax_type?.id : ""
      );
      data.append("tax_amount", values && values?.tax_amount);
      data.append("amount", values && values?.amount);
      data.append("tax_percentage", values && values?.tax_percentage);
      data.append("object_name", ObjectName.BILL);
      data.append("object_id", id);
      TaxService.create(data, toggles, dispatch, params);
      setAmount("");
      setPercentage("");
      setTaxAmount("");
      setDetail("");
      setTaxType("");
    }
  };

  const handleDelete = () => {
    setDeleteModelOpen(!isDeleteModelOpen);
  };

  const deleteTax = async () => {
    let params = { object_id: id };
    await TaxService.delete(detail?.id, toggles, dispatch, params, setDetail);
    toggles();
  };

  const _toggle = () => {
    toggles();
    setDetail("");
    setAmount("");
    setPercentage("");
    setTaxAmount("");
    setTaxType("");
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModelOpen}
        toggle={() => {
          handleDelete();
        }}
        deleteFunction={deleteTax}
        title="Delete Tax"
        label={detail?.taxType}
      />
      <Drawer
        hideAddButton
        DrawerBody={addTaxForm}
        DrawerFooter={taxFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        enableReinitialize
        handleOpenModal={_toggle}
        handleCloseModal={_toggle}
        handleDrawerClose={_toggle}
        isModalOpen={isOpen}
        buttonLabel={true}
        showButton={true}
        modelTitle={detail ? "Update Tax" : "Add Tax"}
        showDeleteButton={detail?.id ? true : false}
        handleDelete={handleDelete}
      />

      <ReduxTable
        id="tax"
        disableHeader
        showHeader
        newTableHeading
        apiURL={`${endpoints().taxApi}/search`}
        params={{ object_id: id, object_name: ObjectName.BILL }}
        icon={<FontAwesomeIcon icon={faCartShopping} />}
        onRowClick={(row) => {
          setDetail(row);
          toggles();
        }}
      >
        <ReduxColumn
          field="taxType"
          className="text-center"
          sortBy="tax_type_id"
          isClickable="true"
          type="link"
        >
          Tax Type
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          className="text-end"
          sortBy="amount"
          renderField={(row) => <span>{Currency.Format(row.amount)}</span>}
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="taxPercentage"
          className="text-end"
          sortBy="tax_percentage"
          renderField={(row) => <span>{Percentage(row.taxPercentage)}</span>}
        >
          Tax Percentage
        </ReduxColumn>
        <ReduxColumn
          field="taxAmount"
          className="text-end"
          sortBy="tax_amount"
          renderField={(row) => <span>{Currency.Format(row.taxAmount)}</span>}
        >
          Tax Amount
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default TaxList;
