import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import AddButton from "../../components/AddButton";
import PageTitle from "../../components/PageTitle";
import { Tabs } from "../../helpers/Setting";
import TagDetail from "../../components/TagList";
import Url from "../../lib/Url";

const EducationalQualification = (props) => {
  let { history } = props;
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : Tabs.EDUCATIONAL_QUALIFICATION
  );
  const [isTypeModelOpen, setIsTypeModelOpen] = useState(false);
  const [isCategoryModelOpen, setIsCategoryModelOpen] = useState(false);

  const _toggles = () => {
    setIsTypeModelOpen(!isTypeModelOpen);
  };

  const toggles = () => {
    setIsCategoryModelOpen(!isCategoryModelOpen);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <PageTitle label="Jobs" />
        {activeTab === Tabs.EDUCATIONAL_QUALIFICATION && (
          <AddButton
            className="ms-2 me-1"
            label="Add Type"
            onClick={() => _toggles()}
          />
        )}

        {activeTab === Tabs.CATEGORY && (
          <AddButton
            className="ms-2 me-1"
            label="Add Type"
            onClick={() => toggles()}
          />
        )}
      </div>
      <Nav tabs className="admin-tabs mb-3">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.EDUCATIONAL_QUALIFICATION,
            })}
            onClick={() => handleTabClick(Tabs.EDUCATIONAL_QUALIFICATION)}
          >
            {Tabs.EDUCATIONAL_QUALIFICATION}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.CATEGORY,
            })}
            onClick={() => handleTabClick(Tabs.CATEGORY)}
          >
            {Tabs.CATEGORY}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {activeTab === Tabs.EDUCATIONAL_QUALIFICATION && (
          <TabPane tabId={Tabs.EDUCATIONAL_QUALIFICATION}>
            <TagDetail
              tagType={"Educational Qualifications"}
              noTagDetail={true}
              history={history}
              showPageTitle={true}
              showEditPopup
              isModel={isTypeModelOpen}
              __toggle={_toggles}
              props={props}
              label="Educational Qualifications"
              hideQueryParams={{ type: true }}
            />
          </TabPane>
        )}
        {activeTab === Tabs.CATEGORY && (
          <TabPane tabId={Tabs.CATEGORY}>
            <TagDetail
              tagType={"Job Category"}
              noTagDetail={true}
              history={history}
              showPageTitle={true}
              showEditPopup
              isModel={isCategoryModelOpen}
              __toggle={toggles}
              props={props}
              label="Job Category"
              hideQueryParams={{ type: true }}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default EducationalQualification;
