import React, { useEffect, useState } from "react";
import Form from "../../components/Form";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import { getCompanySettings, saveSetting } from "../../services/SettingService";
import { getKeyValueByObject } from "../../lib/Helper";
import { Setting } from "../../helpers/Setting";
import ArrayList from "../../lib/ArrayList";
import ProjectTicketTypeService from "../../services/ProjectTicketTypeService";

const SettingPage = () => {
  const [type, setType] = useState(null);
  const [list, setList] = useState([]);
  const [EnquiryType, setEnquiryType] = useState(null);
  const [lateCheckInEnquiryType, setLateCheckInEnquiryType] = useState(null);
  const [stockEntryMissingEnquiryType, setStockEntryMissingEnquiryType] = useState(null);
  const [replenishMissingEnquiryType, setReplenishMissingEnquiryType] = useState(null);
  const [earlyCheckoutEnquiryType, setEarlyCheckoutEnquiryType] = useState(null);


  const [settings, setSettings] = useState([]);

  useEffect(() => {
    getStatusList();
    getSettings();
  }, []);

  const getStatusList = async () => {
    const data = await ProjectTicketTypeService.list({},true);
    setList(data);
  };

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    setSettings(settingData);
  };

  const handleUpdate = async (values) => {
    let data = new FormData();
    data.append(
      Setting.SALE_SETTLEMENT_MISSING_TICKET_TYPE,
      values && values?.sales_settlement_missing_ticket_type?.value
        ? values?.sales_settlement_missing_ticket_type?.value
        : ""
    );

    data.append(
      Setting.SALE_SETTLEMENT_ENQUIRY_TICKET_TYPE,
      values && values[Setting.SALE_SETTLEMENT_ENQUIRY_TICKET_TYPE]?.value
        ? values[Setting.SALE_SETTLEMENT_ENQUIRY_TICKET_TYPE].value
        : ""
    );

    data.append(
      Setting.ATTENDANCE_LATE_CHECK_IN_ENQUIRY_TICKET_TYPE,
      values && values[Setting.ATTENDANCE_LATE_CHECK_IN_ENQUIRY_TICKET_TYPE]?.value
        ? values[Setting.ATTENDANCE_LATE_CHECK_IN_ENQUIRY_TICKET_TYPE].value
        : ""
    );

    data.append(
      Setting.STOCK_ENTRY_MISSING_ENQUIRY_TICKET_TYPE,
      values && values[Setting.STOCK_ENTRY_MISSING_ENQUIRY_TICKET_TYPE]?.value
        ? values[Setting.STOCK_ENTRY_MISSING_ENQUIRY_TICKET_TYPE].value
        : ""
    );

    data.append(
      Setting.REPLENISHMENT_MISSING_ENQUIRY_TICKET_TYPE,
      values && values[Setting.REPLENISHMENT_MISSING_ENQUIRY_TICKET_TYPE]?.value
        ? values[Setting.REPLENISHMENT_MISSING_ENQUIRY_TICKET_TYPE].value
        : ""
    );

    data.append(
      Setting.ATTENDANCE_EARLY_CHECK_OUT_ENQUIRY_TICKET_TYPE,
      values && values[Setting.ATTENDANCE_EARLY_CHECK_OUT_ENQUIRY_TICKET_TYPE]?.value
        ? values[Setting.ATTENDANCE_EARLY_CHECK_OUT_ENQUIRY_TICKET_TYPE].value
        : ""
    );

    saveSetting(data, null, (res) => {
      if (res) {
      }
    });
  };
  
  const handleChange = (values) => {
    let dataValue = values && values?.value;
    if (dataValue !== null) {
      setType(dataValue);
    } else {
      setType(null);
    }
  };


  const handleEnquiryChange = (values) => {
    let dataValue = values && values?.value;
    if (dataValue !== null) {
      setEnquiryType(dataValue);
    } else {
      setEnquiryType(null);
    }
  };

  const handleLateCheckInEnquiryChange = (values) => {
    let dataValue = values && values?.value;
    if (dataValue !== null) {
      setLateCheckInEnquiryType(dataValue);
    } else {
      setLateCheckInEnquiryType(null);
    }
  };

  const handleStockEntryMissingEnquiryChange = (values) => {
    let dataValue = values && values?.value;
    if (dataValue !== null) {
      setStockEntryMissingEnquiryType(dataValue);
    } else {
      setStockEntryMissingEnquiryType(null);
    }
  };

  const handleReplenishmentMissingEnquiryChange = (values) => {
    let dataValue = values && values?.value;
    if (dataValue !== null) {
      setReplenishMissingEnquiryType(dataValue);
    } else {
      setReplenishMissingEnquiryType(null);
    }
  };

  const handleEarlyCheckoutEnquiryChange = (values) => {
    let dataValue = values && values?.value;
    if (dataValue !== null) {
      setEarlyCheckoutEnquiryType(dataValue);
    } else {
      setEarlyCheckoutEnquiryType(null);
    }
  };


  return (
    <div>
      <Form
        enableReinitialize={true}
        initialValues={{
            sales_settlement_missing_ticket_type: type
            ? list.find((data) => data.value == type)
            : settings &&
              ArrayList.isNotEmpty(settings) &&
              getKeyValueByObject(settings, Setting.SALE_SETTLEMENT_MISSING_TICKET_TYPE)
            ? list.find(
                (data) =>
                  data.value ==
                  getKeyValueByObject(settings, Setting.SALE_SETTLEMENT_MISSING_TICKET_TYPE)
              )
            : "",
            [Setting.SALE_SETTLEMENT_ENQUIRY_TICKET_TYPE]:   EnquiryType
            ? list.find((data) => data.value == EnquiryType)
            : settings &&
              ArrayList.isNotEmpty(settings) &&
              getKeyValueByObject(settings, Setting.SALE_SETTLEMENT_ENQUIRY_TICKET_TYPE)
            ? list.find(
                (data) =>
                  data.value ==
                  getKeyValueByObject(settings, Setting.SALE_SETTLEMENT_ENQUIRY_TICKET_TYPE)
              )
            : "",
            [Setting.ATTENDANCE_LATE_CHECK_IN_ENQUIRY_TICKET_TYPE]:   lateCheckInEnquiryType
            ? list.find((data) => data.value == lateCheckInEnquiryType)
            : settings &&
              ArrayList.isNotEmpty(settings) &&
              getKeyValueByObject(settings, Setting.ATTENDANCE_LATE_CHECK_IN_ENQUIRY_TICKET_TYPE)
            ? list.find(
                (data) =>
                  data.value ==
                  getKeyValueByObject(settings, Setting.ATTENDANCE_LATE_CHECK_IN_ENQUIRY_TICKET_TYPE)
              )
            : "",
            [Setting.STOCK_ENTRY_MISSING_ENQUIRY_TICKET_TYPE]:   stockEntryMissingEnquiryType
            ? list.find((data) => data.value == stockEntryMissingEnquiryType)
            : settings &&
              ArrayList.isNotEmpty(settings) &&
              getKeyValueByObject(settings, Setting.STOCK_ENTRY_MISSING_ENQUIRY_TICKET_TYPE)
            ? list.find(
                (data) =>
                  data.value ==
                  getKeyValueByObject(settings, Setting.STOCK_ENTRY_MISSING_ENQUIRY_TICKET_TYPE)
              )
            : "",
            [Setting.REPLENISHMENT_MISSING_ENQUIRY_TICKET_TYPE]:   replenishMissingEnquiryType
            ? list.find((data) => data.value == replenishMissingEnquiryType)
            : settings &&
              ArrayList.isNotEmpty(settings) &&
              getKeyValueByObject(settings, Setting.REPLENISHMENT_MISSING_ENQUIRY_TICKET_TYPE)
            ? list.find(
                (data) =>
                  data.value ==
                  getKeyValueByObject(settings, Setting.REPLENISHMENT_MISSING_ENQUIRY_TICKET_TYPE)
              )
            : "",
            [Setting.ATTENDANCE_EARLY_CHECK_OUT_ENQUIRY_TICKET_TYPE]:   earlyCheckoutEnquiryType
            ? list.find((data) => data.value == earlyCheckoutEnquiryType)
            : settings &&
              ArrayList.isNotEmpty(settings) &&
              getKeyValueByObject(settings, Setting.ATTENDANCE_EARLY_CHECK_OUT_ENQUIRY_TICKET_TYPE)
            ? list.find(
                (data) =>
                  data.value ==
                  getKeyValueByObject(settings, Setting.ATTENDANCE_EARLY_CHECK_OUT_ENQUIRY_TICKET_TYPE)
              )
            : "",
        }}
        onSubmit={(values) => {
          handleUpdate(values);
        }}>
        <div className="card bg-white mb-3">
          <div className="card-body">
            <Select
              name={Setting.SALE_SETTLEMENT_MISSING_TICKET_TYPE}
              label="Sales Settlement Missing Ticket Type"
              placeholder="Select Type"
              options={list}
              handleChange={handleChange}
            />
              <Select
              name={Setting.SALE_SETTLEMENT_ENQUIRY_TICKET_TYPE}
              label="Sales Settlement Enquiry Ticket Type"
              placeholder="Select Type"
              options={list}
              handleChange={handleEnquiryChange}
            />
                <Select
              name={Setting.ATTENDANCE_LATE_CHECK_IN_ENQUIRY_TICKET_TYPE}
              label="Attendance Late Check-IN Enquiry Ticket Type"
              placeholder="Select Type"
              options={list}
              handleChange={handleLateCheckInEnquiryChange}
            />
              <Select
              name={Setting.STOCK_ENTRY_MISSING_ENQUIRY_TICKET_TYPE}
              label="Stock Entry Missing Enquiry Ticket Type"
              placeholder="Select Type"
              options={list}
              handleChange={handleStockEntryMissingEnquiryChange}
            />  
              <Select
            name={Setting.REPLENISHMENT_MISSING_ENQUIRY_TICKET_TYPE}
            label="Replenishment Missing Enquiry Ticket Type"
            placeholder="Select Type"
            options={list}
            handleChange={handleReplenishmentMissingEnquiryChange}
          />
            <Select
            name={Setting.ATTENDANCE_EARLY_CHECK_OUT_ENQUIRY_TICKET_TYPE}
            label="Attendace Early Checkout Enquiry Ticket Type"
            placeholder="Select Type"
            options={list}
            handleChange={handleEarlyCheckoutEnquiryChange}
          />
            <div className="mb-4">
              <div>
                <SaveButton />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default SettingPage;
