import React from "react";

// Components
import Text from "../../../components/Text";
import Phone from "../../../components/Phone";

const CustomerForm = (props) => {
  const { handleFirstNameChange, handleLastNameChange, isEditable } = props;

  return (
    <>
      <div className="card-body">
        <Text
          name="first_name"
          label="First Name"
          placeholder="Enter First Name"
          required={true}
            disabled={isEditable}
          error=""
          onChange={handleFirstNameChange}
        />

        <Text
          name="last_name"
          label="Last Name"
          placeholder="Enter Last Name"
            disabled={isEditable}
          onChange={handleLastNameChange}
        />

        <Phone
          name="mobile_number"
          label="Mobile Number"
          placeholder="Enter Mobile Number"
            disabled={isEditable}
        />
      </div>
    </>
  );
};

export default CustomerForm;
