import React from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { FormGroup, FormFeedback } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import Label from "./Label";

class MultiSelect extends React.Component {
  validate(value) {
    const { label, placeholder, required, error } = this.props;

    let errorMessage;
    let inputLabel = label || placeholder;
    const errorMessageLabel = error;

    if (required && (!value || (Array.isArray(value) && value.length === 0))) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }

    return errorMessage;
  }

  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.defaultValue &&
  //     prevProps.defaultValue !== this.props.defaultValue &&
  //     this.props.defaultValue !== this.fieldValue
  //   ) {
  //     this.setFieldValue(this.props.name, this.props.defaultValue);
  //   }
  // }

  renderInput({ field, form: { touched, errors, setFieldValue, setFieldTouched, values } }) {
    const { submitCount } = useFormikContext();
    const {
      name,
      id,
      label,
      placeholder,
      options,
      isLoading,
      onOpen,
      onSearch,
      onInputChange,
      required,
      closeMenuOnSelect,
      onRender,
      handleOnChangeSubmit,
      isSingleSelect,
      disabled,
      onKeyDown,
      defaultValue,
      fontBolded,
      minWidth,
      allowManualSearch,
    } = this.props;

    const errorMessage = (touched[name] || submitCount > 0) && errors[name] ? errors[name] : null;
    const inputId = id || name;

    let placeholderText;
    if (placeholder) {
      placeholderText = placeholder;
    } else if (label) {
      placeholderText = `Select ${label}`;
    }

    const handleBlur = () => {
      setFieldTouched(name, true); // Explicitly set touched to true on blur
      field.onBlur({ target: { name } }); // Keep Formik's blur handling
    };


    const fieldSelect = (
      <FormGroup
        className="w-100 position-relative"
        style={{ minWidth: minWidth ? minWidth : "100px" }}
      >
        {label && (
          <Label
            id={inputId}
            required={required}
            className={`${fontBolded ? "fw-bold" : ""}`}
          >
            {label}
          </Label>
        )}
        <CreatableSelect
          id={id}
          onKeyDown={onKeyDown}
          isMulti={!isSingleSelect}
          classNamePrefix="select"
          defaultValue={defaultValue}
          value={field.value || defaultValue}
          formatCreateLabel={(e) => e}
          isClearable={true}
          isSearchable={allowManualSearch}
          options={options}
          isLoading={isLoading}
          closeMenuOnSelect={closeMenuOnSelect}
          onMenuOpen={() => onOpen && onOpen()}
          placeholder={<div className="text-nowrap">{placeholderText}</div>}
          onChange={(value) => {
            setFieldValue(name, value);
            if (onInputChange) {
              values[name] = value;
              onInputChange({ setFieldValue, values });
            }
            if (handleOnChangeSubmit) {
              values[name] = value;
              handleOnChangeSubmit(values);
            }
          }}
          onBlur={handleBlur}
          onInputChange={(value) => onSearch && onSearch(value)}
          styles={{
            control: (provided, state) => {
              let boxShadow;
              let background;
              let borderColor = errorMessage
                ? "#f86c6b !important"
                : provided.borderColor;
              if (state.isFocused) {
                boxShadow = errorMessage
                  ? "0 0 0 0.2rem rgba(248, 108, 107, 0.25)"
                  : "0 0 0 0.2rem rgba(32, 168, 216, 0.25)";
                borderColor = errorMessage ? borderColor : "#8ad4ee !important";
              } else {
                background = errorMessage ? background : "#f3f3f4 !important";
              }
              return Object.assign({}, provided, {
                borderColor,
                boxShadow,
                background,
              });
            },
          }}
          isDisabled={disabled}
        />

        <span
          className={`d-none form-control ${errorMessage ? "is-invalid" : ""}`}
        />

        {errorMessage && (
          <FormFeedback className="position-absolute" style={{ marginTop: 1 }}>
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );

    if (!onRender) {
      return fieldSelect;
    }

    return onRender(values, (err, render) => (render ? fieldSelect : null));
  }

  render() {
    const { name, id } = this.props;

    return (
      <Field
        id={id || name}
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  onOpen: PropTypes.func,
  onSearch: PropTypes.func,
  onInputChange: PropTypes.func,
  allowManualSearch: PropTypes.bool, // New prop type
};

MultiSelect.defaultProps = {
  allowManualSearch: true, // Default value for the new prop
};

export default MultiSelect;