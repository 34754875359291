import React from "react";
import PropTypes from "prop-types";

const CustomUserCard = ({ imageUrl, firstName, lastName, size = 50, className }) => {
    const defaultSize = size;
    const imageStyle = {
        width: defaultSize,
        height: defaultSize,
        borderRadius: "50%",
        objectFit: "cover",
        marginRight: "10px",
    };

    const cardStyle = {
        display: "flex",
        alignItems: "center",
    };

    return (
        <div className={className} style={cardStyle}>
            <img src={imageUrl}  style={imageStyle}  />
            <div>
                <div style={{ fontWeight: "bold" }}>
                    {firstName} {lastName}
                </div>
            </div>
        </div>
    );
};

CustomUserCard.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    size: PropTypes.number,
    className: PropTypes.string,
};

export default CustomUserCard;
