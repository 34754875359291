import React, { useEffect, useState } from "react";
import Select from "./Select";
import ProjectTicketTypeService from "../services/ProjectTicketTypeService";
import Url from "../lib/Url";

const TicketType = (props) => {
  const {
    name,
    placeholder,
    handleTicketTypeChange,
    required,
    label,
    projectId,
    typeList,
    isMulti,
    ticketTypeOption,
    showDefaultValue,
    defaultValue = null,
    onDisplayDefaultValue,
    menuPortal,
    disabled
  } = props;

  const [ticketType, setTicketType] = useState([]);

  useEffect(() => {
    getTicketType();
  }, [projectId]);

  const getTicketType = async () => {
    const project_id = projectId ? projectId.toString() : Url.GetParam("projectId") || Url.GetParam("project_id");
    let response = await ProjectTicketTypeService.list({ projectId: project_id, defaultValue: defaultValue });
    if (response) {
      setTicketType(response);
      typeList && typeList(response);
    } else {
      setTicketType([]);
    }
  };

  const handleDisplayDefaultValue = (value) => {
    onDisplayDefaultValue(value);
  };

  return (
    <div>
      <Select
        name={name ? name : "ticketType"}
        placeholder={placeholder ? placeholder : "Select Ticket Type"}
        onInputChange={handleTicketTypeChange}
        label={(label == "" || label !== "") ? label : "Ticket Type"}
        options={ticketTypeOption ? ticketTypeOption : ticketType}
        showDefaultValue={showDefaultValue}
        required={required}
        onDisplayDefaultValue={handleDisplayDefaultValue}
        isMulti={isMulti}
        menuPortal={menuPortal}
        isDisabled={disabled}
      />
    </div>
  );
};

export default TicketType;
