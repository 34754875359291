import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Components
import AddButton from "../../components/AddButton";
import DeleteModal from "../../components/DeleteModal";
import PageTitle from "../../components/PageTitle";
import PaymentList from "./components/paymentList";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import Payment from "../../helpers/Payment";
import { Tabs } from "../../helpers/Tabs";

// Services
import PaymentService from "../../services/PaymentService";

// Lib
import Url from "../../lib/Url";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";

const PaymentListPage = (props) => {
  const { history, activeTab, tabId } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [rowValue, setRowValue] = useState(null);
  const [detail, setDetail] = useState();
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [dueDate, setDueDate] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setRowValue("");
    setDetail("");
    setIsSubmitting(true);
  };

  let dispatch = useDispatch();

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
    setRowValue("");
    setDetail("");
  };

  const options = [
    {
      label: Payment.STATUS_NEW_TEXT,
      value: Payment.STATUS_NEW_TEXT,
    },
    {
      label: Payment.STATUS_PAID_TEXT,
      value: Payment.STATUS_PAID_TEXT,
    },
  ];

  const toggles = () => {
    setIsOpen(!isOpen);
    setDueDate("");
  };

  let params = { objectName: ObjectName.PAYMENT, showTotal: true };

  if (props.purchase_id) {
    params.purchaseId = props.purchase_id;
  }

  const paymentsDelete = async () => {
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      search: Url.GetParam("search"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      showTotal: true,
      account: Url.GetParam("account"),
      accountType: Url.GetParam("accountType"),
      status: Url.GetParam("status"),
      type: Url.GetParam("type"),
      paymentAccount: Url.GetParam("paymentAccount"),
      user: Url.GetParam("user"),
    };
    dispatch(await PaymentService.delete(rowValue?.id, null, params));
    setRowValue("");
    setDetail("");
    closeDeleteModal();
  };

  let hasPaymentAddPermission = hasPermission(Permission.PAYMENT_ADD);


  return (
    <>
      {activeTab !== Tabs.PAYMENTS && (
        <div className="d-flex justify-content-between pb-2">
          <PageTitle label="Payments" />
          {hasPaymentAddPermission && (
            <AddButton
              className="pull-right"
              label={"Add"}
              onClick={handleOpenModal}
            />
          )}
        </div>
      )}
      <DeleteModal
        isOpen={openDeleteModal}
        label={rowValue?.id}
        toggle={closeDeleteModal}
        title="Delete Payment"
        deleteFunction={() => {
          paymentsDelete(rowValue?.id);
        }}
      />
      <PaymentList
        history={history}
        options={options}
        isOpen={isOpen}
        toggles={toggles}
        setIsSubmitting={setIsSubmitting}
        isSubmitting={isSubmitting}
        handleCloseModal={handleCloseModal}
        showAccountFilter
        showPaymentAccountFilter
        showUserFilter
        showStatusFilter
        assigneePlaceholder="Select Owner"
        params={params}
        setRowValue={setRowValue}
        rowValue={rowValue}
        setDueDate={setDueDate}
        dueDate={dueDate}
        setDetail={setDetail}
        detail={detail}
        notesValue={detail?.notes}
        showLoggedInUser
        setOpenDeleteModal={setOpenDeleteModal}
      />
    </>
  );
};

export default PaymentListPage;
