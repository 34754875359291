import React, { useEffect, useState } from "react";
import Form from "../../../components/Form";
import SaveButton from "../../../components/SaveButton";
import TagSelect from "../../../components/TagSelect";
import { Setting } from "../../../helpers/Setting";
import { TagTypeName } from "../../../helpers/Tag";
import { getKeyValueByObject } from "../../../lib/Helper";
import { getCompanySettings, saveSetting } from "../../../services/SettingService";
import TagTypeService from "../../../services/TagTypeService";
import SingleCheckbox from "../../../components/SingleCheckbox";
import AttendanceTypeSelect from "../../../components/AttendanceTypeSelect";
const SettingTab = (props) => {
  const [typeList, setTypeList] = useState([]);
  const [fineValue, setFineValue] = useState([]);
  const [bonusTypeValue, setBonusTypeValue] = useState("");
  const [earlyCheckOutValue, setEarlyCheckOutValue] = useState("");
  const [lateCheckOutBonusType, setLateCheckOutBonusType] = useState("");
  const [missingCheckOutFineType, setMissingCheckOutFineType] = useState("");
  const [attendanceAbsentType, setAttendanceAbsentType] = useState("");
  const [attendanceMissingFineTypeValue, setAttendanceMissingFineTypeValue] = useState("");
  const [geoLocation, setGeoLocation] = useState(false);
  const [attendanceTypeList, setAttendanceTypeList] = useState([]);

  useEffect(() => {
    getFineTypes();
    getSettings();
  }, []);

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    let cash = getKeyValueByObject(
      settingData,
      Setting.ATTENDANCE_LATE_CHECK_IN_FINE_TYPE
    );
    let bonusTypeValue = getKeyValueByObject(
      settingData,
      Setting.ATTENDANCE_EARLY_CHECK_IN_BONUS_TYPE
    );
    let earlyCheckoutFineTypeValue = getKeyValueByObject(
      settingData,
      Setting.ATTENDANCE_EARLY_CHECK_OUT_FINE_TYPE
    );
    let lateCheckoutBonusTypeValue = getKeyValueByObject(
      settingData,
      Setting.ATTENDANCE_LATE_CHECK_OUT_BONUS_TYPE
    );
    let missingCheckOutFineType = getKeyValueByObject(
      settingData,
      Setting.ATTENDANCE_CHECKOUT_MISSING_FINE_TYPE
    );
    let geoLocation = getKeyValueByObject(
      settingData,
      Setting.ENABLE_GEO_LOCATION
    );

    let attendanceType = getKeyValueByObject(
      settingData,
      Setting.ATTENDANCE_ABSENT_TYPE
    );

    let attendanceMissingFineType = getKeyValueByObject(
      settingData,
      Setting.ATTENDANCE_MISSING_FINE_TYPE
    );

    setAttendanceMissingFineTypeValue(attendanceMissingFineType)

    setAttendanceAbsentType(attendanceType)
    setFineValue(cash);
    setBonusTypeValue(bonusTypeValue);
    setEarlyCheckOutValue(earlyCheckoutFineTypeValue);
    setLateCheckOutBonusType(lateCheckoutBonusTypeValue);
    setMissingCheckOutFineType(missingCheckOutFineType);
    setGeoLocation(geoLocation == "true" ? true : false);
  };
  const getFineTypes = async () => {
    let response = await TagTypeService.TagList("");
    let data = response && response?.data;
    if (data && data.length > 0) {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        const { id, name } = data[i];
        list.push({
          value: id,
          label: name,
        });
      }
      setTypeList(list);
    }
  };
  const handleUpdate = async (values) => {
    let data = new FormData();
    data.append(
      Setting.ATTENDANCE_LATE_CHECK_IN_FINE_TYPE,
      values &&
        values?.attendance_late_check_in_fine_type?.value &&
        values?.attendance_late_check_in_fine_type?.value
    );

    data.append(
      Setting.ATTENDANCE_EARLY_CHECK_IN_BONUS_TYPE,
      values &&
        values?.attendance_early_check_in_bonus_type?.value &&
        values?.attendance_early_check_in_bonus_type?.value
    );

    data.append(
      Setting.ATTENDANCE_EARLY_CHECK_OUT_FINE_TYPE,
      values &&
        values?.attendance_early_check_out_fine_type?.value &&
        values?.attendance_early_check_out_fine_type?.value
    );

    data.append(
      Setting.ATTENDANCE_LATE_CHECK_OUT_BONUS_TYPE,
      values && values?.attendance_late_check_out_bonus_type?.value
    );

    data.append(
      Setting.ATTENDANCE_CHECKOUT_MISSING_FINE_TYPE,
      values && values?.attendance_checkout_missing_fine_type?.value
    );
    data.append(
      Setting.ATTENDANCE_ABSENT_TYPE,
      values && values?.attendance_absent_type?.value
    );
    data.append(
      Setting.ATTENDANCE_MISSING_FINE_TYPE,
      values && values[Setting.ATTENDANCE_MISSING_FINE_TYPE]?.value
    );
    data.append(
      Setting.ENABLE_GEO_LOCATION,
      values &&
        values?.enable_geo_location
    );

    saveSetting(data, null, (res) => {
      if (res) {
      }
    });
  };

  return (
    <div>
      <Form
        enableReinitialize={true}
        initialValues={{
          attendance_late_check_in_fine_type:
            typeList &&
            typeList.length > 0 &&
            typeList.find((data) => data?.value == fineValue),
          [Setting.ATTENDANCE_EARLY_CHECK_IN_BONUS_TYPE]:
            typeList &&
            typeList.length > 0 &&
            typeList.find((data) => data?.value == bonusTypeValue),
          [Setting.ATTENDANCE_EARLY_CHECK_OUT_FINE_TYPE]:
            typeList &&
            typeList.length > 0 &&
            typeList.find((data) => data?.value == earlyCheckOutValue),
          [Setting.ATTENDANCE_LATE_CHECK_OUT_BONUS_TYPE]:
            typeList &&
            typeList.length > 0 &&
            typeList.find((data) => data?.value == lateCheckOutBonusType),
          [Setting.ATTENDANCE_CHECKOUT_MISSING_FINE_TYPE]:
            typeList &&
            typeList.length > 0 &&
            typeList.find((data) => data?.value == missingCheckOutFineType),
            [Setting.ENABLE_GEO_LOCATION]:geoLocation,
            [Setting.ATTENDANCE_ABSENT_TYPE]:
            attendanceTypeList &&
            attendanceTypeList.length > 0 &&
            attendanceTypeList.find((data) => data?.value == attendanceAbsentType),
            [Setting.ATTENDANCE_MISSING_FINE_TYPE]:
            typeList &&
            typeList.length > 0 &&
            typeList.find((data) => data?.value == attendanceMissingFineTypeValue),
        }}
        onSubmit={(values) => {
          handleUpdate(values);
        }}
      >
        <div className="card bg-white mb-3">
          <div className="card-body">
            <TagSelect  name={Setting.ATTENDANCE_LATE_CHECK_IN_FINE_TYPE}
              label="Attendance Late CheckIn Fine Type"
              params={{ type: TagTypeName.FINE_TYPE }}
            />
            <TagSelect  name={Setting.ATTENDANCE_EARLY_CHECK_IN_BONUS_TYPE}
              label="Attendance Early CheckIn Bonus Typ"
              params={{ type: TagTypeName.BONUS_TYPE }}
            />
            <TagSelect  name={Setting.ATTENDANCE_EARLY_CHECK_OUT_FINE_TYPE}
              label="Attendance Early CheckOut Fine Type"
              params={{ type: TagTypeName.FINE_TYPE }}
            />
            <TagSelect  name={Setting.ATTENDANCE_LATE_CHECK_OUT_BONUS_TYPE}
              label="Attendance Late CheckOut Bonus Type"
              params={{ type: TagTypeName.BONUS_TYPE }}
            />
            <TagSelect  name={Setting.ATTENDANCE_CHECKOUT_MISSING_FINE_TYPE}
              label="Attendance Missing Checkout Fine Type"
              params={{ type: TagTypeName.FINE_TYPE }}
            />
             <TagSelect  name={Setting.ATTENDANCE_MISSING_FINE_TYPE}
              label="Attendance Missing Fine Type"
              params={{ type: TagTypeName.FINE_TYPE }}
            />
            <AttendanceTypeSelect
        name={Setting.ATTENDANCE_ABSENT_TYPE}
          label="Attendance Absent Type"
          accountTypeOption={setAttendanceTypeList}
      />
            <SingleCheckbox
              name={Setting.ENABLE_GEO_LOCATION}
              label="Enable Geo Location"
              className="p-0 m-0"
            />
            <br />
            <div className="mb-4 mt-1">
              <div>
                <SaveButton />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default SettingTab;
