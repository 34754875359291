import React, { useState, useEffect } from "react";
import ArrayList from "../lib/ArrayList";
import Select from "./Select";
import UserRoleService from "../services/UserRoleService";

const RoleSelect = (props) => {
  let { handleChange, name, list,label,isMulti,disabled } = props;
  const [roleList, setRoleList] = useState([]);
  useEffect(() => {
    getTypes();
  }, []);
  const getTypes = async () => {
    if (ArrayList.isEmpty(roleList)) {
      try {
        const response = await UserRoleService.list();
        setRoleList(response);
        if (list) {
          list(response);
        }
      } catch (error) {
        console.error("Failed to fetch roles:", error);
      }
    }
  };

  return (
    <>
      <Select
        label={label?label:""}
        name={name ? name : "role"}
        placeholder="Select Role"
        options={roleList}
        handleChange={handleChange}
        isMulti={isMulti?true:false}
        isDisabled={disabled}
      />
    </>
  );
};

export default RoleSelect;
