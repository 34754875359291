import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";



class PublicPageBlockService{

    static async get(){
            const response = await apiClient.get(`${endpoints().publicPageBlockAPI}`);
            const data = response && response.data && response.data.data;
            return data;
    }
}

export default PublicPageBlockService;