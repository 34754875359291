import React from "react";
import { useDispatch } from "react-redux";

// Components
import Button from "../../../components/Button";
import CancelButton from "../../../components/CancelButton";
import AddModal from "../../../components/Modal";
import Select from "../../../components/Select";

// Actions
import { bulkUpdateMedia } from "../../../actions/media";

// Helpers
import Media from "../../../helpers/Media";

// Lib
import String from "../../../lib/String";
import Drawer from "../../../components/Drawer";

export default function BulkUpdateModal(props) {
  const dispatch = useDispatch();

  // Props
  const { isOpen, toggle, MediaParam, setSelectedIds } = props;

  // Media Bulk Update function
  const handleBulkUpdate = (values) => {
    const data = new FormData();
    data.append("ids", MediaParam.selectedIds ? MediaParam.selectedIds : "");
    data.append(
      "visibility",
      values.visibility && String.Get(values.visibility.value)
    );
    dispatch(bulkUpdateMedia(data, toggle));
    setSelectedIds("");
  };

  // DropDown List
  const productStatusOptions = [
    {
      value: Media.VISIBILITY_ARCHIVE,
      label: Media.VISIBILITY_ARCHIVE_TEXT,
    },
    {
      value: Media.VISIBILITY_PUBLIC,
      label: Media.VISIBILITY_PUBLIC_TEXT,
    },
    {
      value: Media.VISIBILITY_PRIVATE,
      label: Media.VISIBILITY_PRIVATE_TEXT,
    },
  ];

  // Defining the initial values
  const initialValues = {
    visibility: "",
  };

  // Bulk Update Form
  const bulkUpdateForm = (
    <Select
      name="visibility"
      label="Visibility"
      placeholder="Select Status"
      options={productStatusOptions}
    />
  );

  // Bulk Update Modal Footer
  const bulkUpdateFooter = (
    <>
      <div className="justify-content-center">
        <Button type="submit" label="Update" />
      </div>
    </>
  );

  return (
    <div>
      <Drawer
        modelTitle="Add Group"
        DrawerBody={bulkUpdateForm}
        DrawerFooter={bulkUpdateFooter}
        onSubmit={(values) => handleBulkUpdate(values)}
        initialValues={initialValues}
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize={true}
      />
    </div>
  );
}
