import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Components
import BreadCrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import CustomForm from "../../components/customFieldsList/CustomForm";
import DeleteModal from "../../components/DeleteModal";
import Action from "../../components/Action";

// Helpers
import ObjectName from "../../helpers/ObjectName";

// Lib
import Url from "../../lib/Url";

// Services
import InspectionService from "../../services/InspectionService";

const InspectionForm = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const locationName = Url.GetParam("location");

  const tagId = Url.GetParam("tagId");

  const breadcrumbList = [
    { label: "Home", link: "/inspections" },
    {
      label: locationName,
      link: `/inspections`,
    },
  ];

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e === "Delete") {
      setDeleteModal(true);
    }
  };

  const inspectionId = props?.match?.params?.id;

  const dispatch = useDispatch();

  const deleteInspection = async () => {
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      search: Url.GetParam("search"),
    };
    dispatch(
      InspectionService.delete(inspectionId, params, () => {
        setDeleteModal(false);
      })
    );
    props.history.push("/inspections");
  };

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <div className="pb-4">
        <div className="d-flex justify-content-between pb-3">
          <PageTitle label={`${locationName}`} />
          <div>
            <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
            />
          </div>
        </div>
        <DeleteModal
          isOpen={deleteModal}
          toggle={() => {
            setDeleteModal(false);
          }}
          title="Delete Inspection"
          label={locationName}
          deleteFunction={() => {
            deleteInspection();
          }}
        />
        <CustomForm
          objectName={ObjectName.INSPECTION}
          objectId={props.match.params.id}
          tagId={tagId}
          cancelButtonOnClick={() => {
            props.history.push("/inspections");
          }}
        />
      </div>
    </div>
  );
};

export default InspectionForm;
