
export const TODAY_VALUE=1
export const CUSTOM_VALUE=9

export const DateOptions = [
    { label: "Today", value: 1 },
    { label: "Yesterday", value: 2 },
    { label: "This Week", value: 3 },
    { label: "Last Week", value: 4 },
    { label: "This Month", value: 5 },
    { label: "Last Month", value: 6 },
    { label: "This Year", value: 7 },
    { label: "Last Year", value: 8 },
    { label: "Custom", value: 9 },
  ];