import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";

// Components
import Form from "../../../components/Form";
import PageTitle from "../../../components/PageTitle";
import Currency from "../../../components/Currency";
import CancelButton from "../../../components/CancelButton";
import SaveButton from "../../../components/SaveButton";
import BreadCrumb from "../../../components/Breadcrumb";
import Action from "../../../components/Action";
import UserSelect from "../../../components/UserSelect";
import DeleteModal from "../../../components/DeleteModal";
import StatusComponent from "../../../components/Status";
import MediaCarousel from "../../../components/MediaCarousel";
import Text from "../../../components/Text";
import TextArea from "../../../components/TextArea";
import Comment from "../../../components/comment";
import ActivityList from "../../../components/ActivityList";
import AttendanceList from "../../attendance/list";
import FineList from "../../fine/components/FineList";

// Services
import SalaryService from "../../../services/SalaryService";
import { hasPermission } from "../../../services/UserRolePermissionService";
import fineService from "../../../services/FineService";
import bonusService from "../../../services/BonusService";

// Helpers
import Permission from "../../../helpers/Permission";
import ObjectName from "../../../helpers/ObjectName";

// Lib
import Url from "../../../lib/Url";
import String from "../../../lib/String";

// API
import { endpoints } from "../../../api/endPoints";
import SalaryAttendanceTable from "./SalaryAttendanceTable";
import ArrayList from "../../../lib/ArrayList";
import { Group } from "../../../helpers/Status";
import StatusService from "../../../services/StatusService";
import Number from "../../../lib/Number";

const Tab = {
  SUMMARY: "Summary",
  ATTENDANCE: "Attendance",
  FINE: "Fine",
  BONUS: "Bonus",
  COMMENTS: "Comments",
  HISTORY: "History",
  ATTACHMENTS: "Attachments",
};

function FormList(props) {
  const {
    initialValues,
    handleSubmit,
    pageTitle,
    breadcrumbList,
    calculate,
    showUserDetailsPageLink,
    userId,
    id,
    detail,
    setUserList,
  } = props;

  const [options, setOptions] = useState();
  const history = useHistory();
  const [editable, setEditable] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : Tab.SUMMARY
  );
  const [rowValue, setRowValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  let showEditButton = hasPermission(Permission.SALARY_EDIT);
  let showDeleteButton = hasPermission(Permission.SALARY_DELETE);
  let showHistory = hasPermission(Permission.SALARY_HISTORY_VIEW);
  let dispatch = useDispatch();

  useEffect(() => {
    getNumberList();
  }, []);

  const _handleTabChange = async (tab) => {
    if (tab == Tab.FINE || tab == Tab.BONUS) {

      const status = await StatusService.list({
        objectName:
          tab == Tab.FINE ? ObjectName.FINE : ObjectName.BONUS,
      });

      const statusList = status?.data?.data?.map(({ label, value, group }) => ({
        label,
        value,
        group,
      }));

      const completedStatus = statusList.find(
        (item) => item.group == Group.COMPLETED
      );

      if (Number.isNotNull(completedStatus?.value)) {

        props.history.push(
          `?tab=${tab}${tab === Tab.ATTENDANCE || Tab.FINE || Tab.BONUS
            ? `&startDate=${detail?.startDate}&endDate=${detail?.endDate}&status=${completedStatus?.value}`
            : ""
          }`
        );
      }
    } else {
      props.history.push(
        `?tab=${tab}${tab === Tab.ATTENDANCE
          ? `&startDate=${detail?.startDate}&endDate=${detail?.endDate}`
          : ""
        }`
      );
    }
  };

  const Toggle = () => {
    setIsOpen(!isOpen);
  };

  const getNumberList = () => {
    let NumberList = new Array();
    for (let i = 1; i <= 100; i++) {
      NumberList.push({
        label: i,
        value: i,
      });
    }
    setOptions(NumberList);
  };

  const onStatusChange = (value) => {
    if (value) {
      handleSubmits(value);
    }
  };

  const handleSubmits = async (values) => {
    let data = new FormData();
    data.append("selectedIds", props?.id);
    data.append("status", values);

    SalaryService.statusUpdate(data);
    if (props.getDetails) {
      props.getDetails();
    }
  };

  const handleDelete = async (id) => {
    dispatch(
      await SalaryService.delete(id, (res) => {
        if (res) {
          history.push(`/salary/list/${detail?.salary_id}`);
        }
      })
    );
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const actionsMenuList = [
    {
      value: "Recalculate",
      label: "Recalculate",
    },
  ];

  if (showDeleteButton) {
    actionsMenuList.push({
      value: "Delete",
      label: "Delete",
    });
  }

  if (showEditButton && editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "Recalculate") {
      calculate();
    }
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "Delete") {
      setDeleteModal(true);
    }
  };

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between pb-3">
        <PageTitle label={pageTitle} />
        <div className="d-flex">
          <DeleteModal
            isOpen={deleteModal}
            title="Delete Salary"
            label={String.concatName(detail?.first_name, detail?.last_name)}
            id={id}
            toggle={() => {
              setDeleteModal(false);
            }}
            deleteFunction={() => handleDelete(id)}
          />
          <StatusComponent
            objectName={ObjectName.SALARY}
            handleChange={onStatusChange}
            buttonLabel={detail?.status}
            currentStatusId={detail?.statusId}
          />
          <div className="ms-3">
            <Action
              buttonLabel="Actions"
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>
      <Nav tabs className="admin-tabs mb-1">
        {/* Summary Tab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.SUMMARY,
            })}
            onClick={() => {
              toggle(Tab.SUMMARY);
              _handleTabChange(Tab.SUMMARY);
            }}
          >
            Summary
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.ATTENDANCE,
            })}
            onClick={() => {
              toggle(Tab.ATTENDANCE);
              _handleTabChange(Tab.ATTENDANCE);
            }}
          >
            Attendance
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.FINE,
            })}
            onClick={() => {
              toggle(Tab.FINE);
              _handleTabChange(Tab.FINE);
            }}
          >
            Fines
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.BONUS,
            })}
            onClick={() => {
              toggle(Tab.BONUS);
              _handleTabChange(Tab.BONUS);
            }}
          >
            Bonus
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.COMMENTS,
            })}
            onClick={() => {
              toggle(Tab.COMMENTS);
            }}
          >
            Comments
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.ATTACHMENTS,
            })}
            onClick={() => {
              toggle(Tab.ATTACHMENTS);
            }}
          >
            Attachments
          </NavLink>
        </NavItem>
        <NavItem>
          {showHistory && (
            <NavLink
              className={classnames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => {
                toggle(Tab.HISTORY);
              }}
            >
              History
            </NavLink>
          )}
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {activeTab == Tab.SUMMARY && (
          <TabPane tabId={Tab.SUMMARY}>
            <Form
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values) => {
                setEditable(false);
                handleSubmit(values);
                setEditable(true);
              }}
            >
              <div className="">
                <div className="card shadow">
                  <div className="card-header">Personal Information</div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4">
                        <UserSelect
                          label="User"
                          isDisabled
                          showUserDetailsPageLink={showUserDetailsPageLink}
                          userId={userId}
                          userList={setUserList}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 mb-3 mb-md-0">
                        <Text
                          name="startDate"
                          label="Start Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <Text
                          name="endDate"
                          label="End Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-4">
                        <Text
                          name="working_days"
                          label="Working Days"
                          options={options}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mt-2 my-3">
                  <div className="card-header">Net Salary Information</div>
                  <div className="card-body row">
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency name="net_salary" label="Net Salary" disabled />
                    </div>

                  </div>
                </div>
                <div className="card shadow mt-2">
                  <div className="card-header">Salary Information</div>
                  <div className="card-body row">
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency
                        name="monthly_salary"
                        label="Monthly Salary"
                        disabled
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency
                        name="salaryPerDay"
                        label="Salary Per Day"
                        disabled
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency name="basic" label="Basic" disabled />
                    </div>

                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency name="hra" label="HRA" disabled />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency
                        name="special_allowance"
                        label="Special Allowance"
                        disabled
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency
                        name="standard_allowance"
                        label="Standard Allowance"
                        disabled
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency
                        name="other_allowance"
                        label="Other Allowance"
                        disabled={editable}
                      />
                    </div>

                  </div>
                </div>
                <div className="card shadow mt-2">
                  <div className="card-header">Work Days Information</div>
                  {detail && detail?.enableAddtionalDayCalculation &&
                    <div className="card-body row">

                      <div className="col-lg-4 col-md-6 mb-md-0">
                        <Text
                          name="additional_hours"
                          label="Additional Hours"
                          disabled
                        />
                      </div>

                      <div className="col-lg-4 col-md-6 mb-md-0">
                        <Currency
                          name="additional_hours_salary"
                          label="Additional Hours Salary"
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 mb-md-0">
                        <Text
                          name="worked_days"
                          label="Worked Days"
                          disabled
                        />
                      </div>
                    </div>
                  }
                  {ArrayList.isArray(detail?.attendanceCount) && (
                    <SalaryAttendanceTable data={detail?.attendanceCount} />
                  )}
                </div>
                <div className="card shadow mt-2">
                  <div className="card-body row">

                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency name="fine" label="Fine" disabled />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency name="bonus" label="Bonus" disabled />
                    </div>

                  </div>
                </div>
                <div className="card shadow mt-2">
                  <div className="card-header">Deductions Information</div>
                  <div className="card-body row">
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency name="tds" label="TDS" disabled />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency
                        name="medical_insurance"
                        label="Medical Insurance"
                        disabled
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency name="gratuity" label="Gratuity" disabled />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency name="provident_fund" label="PF" disabled />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency name="professional_tax" label="PT" disabled />
                    </div>
                    <div className="col-12 col-md-6 mb-6 mb-md-0">
                      <Currency
                        name="other_deductions"
                        label="Other Deductions"
                        disabled={editable}
                      />
                    </div>


                  </div>
                </div>
                <div className="card shadow mt-2">
                  <div className="card-header">Notes Information</div>
                  <div className="card-body row">
                    <div className="col-12">
                      <TextArea
                        name="notes"
                        label="Notes"
                        disabled={editable}
                      />
                    </div>
                  </div>
                </div>

              </div>

              {!editable && (
                <div className="mb-4 pb-4 mb-sm-1 pb-sm-1">
                  <SaveButton />
                  <CancelButton onClick={() => history.push(`/salary`)} />
                </div>
              )}
            </Form>
          </TabPane>
        )}
        {activeTab === Tab.ATTACHMENTS && (
          <div className="px-5 mx-5">
            <MediaCarousel
              showCarasoul
              objectName={ObjectName.SALARY}
              objectId={id}
              history={history}
              attachmentsList={true}
              Attachments={"Attachments"}
              hideAttachments
              width="50%"
            />
          </div>
        )}
        {activeTab === Tab.ATTENDANCE && (
          <TabPane tabId={Tab.ATTENDANCE}>
            <AttendanceList
              userView={"false"}
              showUserFilter={false}
              user={detail?.user_id}
              showPageSize={true}
              sortByDropdown
              refreshButton
              history={history}
              bulkSelect={false}
            />
          </TabPane>
        )}
        {activeTab == Tab.FINE && (
          <TabPane tabId={Tab.FINE}>
            {Url.GetParam("tab") == Tab.FINE &&
              <FineList
                setRowValue={setRowValue}
                rowValue={rowValue}
                Toggle={Toggle}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                history={history}
                user_id={detail?.user_id}
                showUserFilter={false}
                bulkSelect={false}
                showSearch={false}
                service={fineService}
                param={{
                  isFineType: true,
                  startDate: detail?.startDate,
                  endDate: detail?.endDate, status: Url.GetParam("status")
                }}
                apiURL={endpoints().fineApi}
                objectName={ObjectName.FINE}
              />
            }
          </TabPane>
        )}
        {activeTab == Tab.BONUS && (
          <TabPane tabId={Tab.BONUS}>
            {Url.GetParam("tab") == Tab.BONUS &&
              <FineList
                setRowValue={setRowValue}
                rowValue={rowValue}
                Toggle={Toggle}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                history={history}
                user_id={detail?.user_id}
                showUserFilter={false}
                bulkSelect={false}
                showSearch={false}
                param={{
                  isBonusType: true,
                  startDate: detail?.startDate,
                  endDate: detail?.endDate, status: Url.GetParam("status")
                }}
                apiURL={endpoints().bonusApi}
                service={bonusService}
                objectName={ObjectName.BONUS}
              />
            }
          </TabPane>
        )}
        {/* Comment Tab */}
        {activeTab == Tab.COMMENTS && (
          <TabPane tabId={Tab.COMMENTS} className="w-100">
            <Comment
              objectId={detail?.id}
              objectName={ObjectName.SALARY}
              assignee_id={detail?.user_id}
              ticketDetails={detail}
            />
          </TabPane>
        )}
        {/* History Tab */}
        {showHistory && activeTab == Tab.HISTORY && (
          <TabPane tabId={Tab.HISTORY} className="w-100">
            <ActivityList
              id={detail?.id}
              objectId={detail?.id}
              object_name={ObjectName.SALARY}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
}

export default FormList;
