import React, { useState } from "react";
import { TabPane, TabContent } from "reactstrap";
import { Tabs } from "../../helpers/Setting";
import RatingTypeList from "../ratingType";

const RatingSetting = (props) => {
  let { history } = props;

  // Initializing state using useState
  const [activeTab, setActiveTab] = useState(Tabs.RATING);
  const [isTypeModelOpen, setIsTypeModelOpen] = useState(false);

  const _toggles = () => {
    setIsTypeModelOpen(!isTypeModelOpen);
  };

  return (
    <>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tabs.RATING}>
          <RatingTypeList
            tagType={"RatingType"}
            noTagDetail={true}
            history={history}
            showPageTitle={true}
            showEditPopup
            isModel={isTypeModelOpen}
            __toggle={_toggles}
            props={props}
            label="Rating"
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default RatingSetting;
