import React, { Component } from "react";

// Components
import Text from "../../components/Text";
import Phone from "../../components/Phone";
import ToggleSwitch from "../../components/ToggleSwitch";
import Cookies,{ setCookie } from "../../lib/Helper";
import { COOKIE_READ_RECEIPTS } from "../../lib/Cookie";

class EditGeneralDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readReceipts: false,
    };
  }
  componentDidMount() {
    this.getReadReceipts();
  }

  // Render the form fields
   handleEnableFeature = (value) => {
    this.setState({ readReceipts: value });
    setCookie(COOKIE_READ_RECEIPTS, value == true?1:0);
  };
  getReadReceipts = () => {
    let value = Cookies.get(COOKIE_READ_RECEIPTS)
      this.setState({ readReceipts: value== 1?true:false });
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <Text
              name="name"
              label="First Name"
              placeholder="Enter First Name"
              required={true}
              error=""
            />
          </div>

          <div className="col-lg-12 col-sm-12">
            <Text
              name="lastName"
              label="Last Name"
              placeholder="Enter Last Name"
              error=""
              required={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <Text
              name="email"
              label="Email"
              placeholder="Enter Email"
              required={true}
              error=""
            />
          </div>
        </div>
        <div className="row">

          <div className="col-lg-12 col-sm-12">
            <Phone
              name="mobile"
              label="Mobile Number"
              placeholder="Enter Mobile Number"
              error=""
              fontBolded
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
        <p>Read Receipts</p>
        <ToggleSwitch
          name="read_receipts"
          handleChange={(e) => {
            this.handleEnableFeature(e.target.checked);
          }}
          value={
            this.state.readReceipts
          }
          outlined
        />
      </div>
      </div>
    );
  }
}

export default EditGeneralDetail;
