import React, { useEffect } from "react";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import PageTitle from "../../components/PageTitle";
import ProductCard from "../product/components/productCard";
import { endpoints } from "../../api/endPoints";
import Url from "../../lib/Url";
import BreadCrumb from "../../components/Breadcrumb";
import ReportType from "../../helpers/stockReport";

const storeProductNegativeStockReport = (props) => {

  useEffect(() => {
  }, []);

  const sortByOption = [
    {
      value: "location:ASC",
      label: "Location Name",
    },
    {
      value: "product_name:ASC",
      label: "Product Name",
    },
    {
      value: "quantity:DESC",
      label: "Quantity",
    },

  ];

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report",
    },
    {
      label: "Store Product Negative Stock Report",
      link: "",
    },
  ];

  const actionOptions = [
    {
      label: "Download As PDF",
      value: "Download As PDF",
    }
  ];

  const reportTypes = [
    { label: "Low Stock", value: ReportType.LOW_STOCK },
    { label: "High Stock", value: ReportType.HIGH_STOCK },
  ];

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <div className="row mx-1 justify-content-between mb-2">
        <PageTitle
          label="Store Product Negative Stock Report"
        />
      </div>
      <div className="mt-4">
        <ReduxTable
          id="storeProductNegativeStockReport"
          showHeader={true}
          apiURL={`${endpoints().storeProductNegativeStockReportAPI}/search`}
          searchPlaceholder="Search"
          sortByOptions={sortByOption}
          defaultSortOption={[
            {
              value: "quantity:DESC",
              label: "Quantity",
            },

          ]}
          paramsToUrl={true}
          history={props.history}
          params={{
            sort: Url.GetParam("sort") ? Url.GetParam("sort") : "product_name",
            sortDir: Url.GetParam("sortDir") ? Url.GetParam("sortDir") : "ASC",
            pageSize: Url.GetParam("pageSize") ? Url.GetParam("pageSize") : ""
          }}
          newTableHeading
          showBrandFilter
          showCategoryFilter
          showStoreFilter
          showStockReportFilter>
          <ReduxColumn
            field="location"
            sortBy="location"
            width="140px"
            minWidth="140px"
            maxWidth="140px"
            className="text-start"
          >
            Location
          </ReduxColumn>
          <ReduxColumn
            field="product"
            sortBy="product_name"
            width="310px"
            minWidth="310px"
            maxWidth="310px"
            renderField={(row) => (
              <>
                <ProductCard
                  productImageIcon
                  square
                  productName={row.product}
                  brandName={row.brand}
                  size={row.size}
                  unit={row.unit}
                  url={row.image}
                  id={row.product_id}
                  brand_id={row.brand_id}
                  salePrice={row.sale_price}
                  mrp={row.mrp}

                />
              </>
            )}>
            Product
          </ReduxColumn>
          <ReduxColumn
            field="quantity"
            sortBy="quantity"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-center"
          >
            Quantity
          </ReduxColumn>
          <ReduxColumn
            field="order_quantity"
            sortBy="order_quantity"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-center"
          >
            Order Quantity
          </ReduxColumn>

          <ReduxColumn
            field="min_quantity"
            sortBy="min_quantity"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-center"
          >
            Min Quantity
          </ReduxColumn>
          <ReduxColumn
            field="max_quantity"
            sortBy="max_quantity"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-center"
          >
            Max Quantity
          </ReduxColumn>
          <ReduxColumn
            field="last_stock_entry_date"
            sortBy="last_stock_entry_date"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Last Stock Entry Date
          </ReduxColumn>
          <ReduxColumn
            field="last_order_date"
            sortBy="last_order_date"
            className="text-center"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Last Order Date
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

export default storeProductNegativeStockReport;
