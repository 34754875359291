import React, { useEffect, useState } from "react";

// Components
import PageTitle from "../../components/PageTitle";
import OrderReportChart from "./components/OrderReportChart";

// CSS
import BreadCrumb from "../../components/Breadcrumb";
import { OrderProduct } from "../../helpers/orderProduct";
import Cookie, { isLoggedIn, setCookie } from "../../lib/Helper";
import Url from "../../lib/Url";
import "../../scss/_custom.scss";
import OrderReportService from "../../services/OrderSummaryReportService";
import Filter from "./orderReportFilter";
import Spinner from "../../components/Spinner";
import DateTime from "../../lib/DateTime";
import ToggleSwitch from "../../components/ToggleSwitch";
import ToggleButton from "../../components/ToggleSwitchButton";
import { TODAY_VALUE } from "../../helpers/Date";

const Report = (props) => {
  const { history } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshEnabled, setIsRefreshEnabled] = useState(
    Cookie.get("toggleSwitch") == "1"
  );

  // Filter
  const [paramm, setParam] = useState({
    location: Url.GetParam("location") && Url.GetParam("location"),
    type: Url.GetParam("type")
      ? Url.GetParam("type")
      : OrderProduct.REPORT_TYPE_LOCATION_WISE,
    shift: Url.GetParam("shift") && Url.GetParam("shift"),
    date: Url.GetParam("date")
      ? Url.GetParam("date")
      : TODAY_VALUE,
    paymentType: Url.GetParam("paymentType") && Url.GetParam("paymentType"),
    sortType: Url.GetParam("sortType") && Url.GetParam("sortType"),
  });

  const [graphData, setGraphData] = useState();
  const [defaultGraph, setDefaultGraph] = useState(true);

  useEffect(() => {
    getOrderInitialData();
  }, []);

  useEffect(() => {
    let interval;
    if (isRefreshEnabled) {
      interval = setInterval(() => {
        getOrderInitialData();
      }, 60000); // Refresh every minutes
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRefreshEnabled]);

  const getOrderInitialData = async () => {
    let params = new Object();

    let location = Url.GetParam("location") && Url.GetParam("location");

    let type = Url.GetParam("type")
      ? Url.GetParam("type")
      : OrderProduct.REPORT_TYPE_LOCATION_WISE;

    let shift = Url.GetParam("shift") && Url.GetParam("shift");

    let date = Url.GetParam("date")
      ? Url.GetParam("date")
      :TODAY_VALUE;

    let paymentType =
      Url.GetParam("paymentType") && Url.GetParam("paymentType");

    if (location) {
      params.location = location;
    }
    let sortType = Url.GetParam("sortType") && Url.GetParam("sortType");

    if (type) {
      params.type = type;
    }

    if (shift) {
      params.shift = shift;
    }

    if (date) {
      params.date = date;
    }

    if (paymentType) {
      params.paymentType = paymentType;
    }

    if (sortType) {
      params.sortType = sortType;
    }

    getFilterParams(params);

    let response = await OrderReportService.search(params);

    handleChange(response, params);
    setIsLoading(false);
  };

  // // get filter params
  const getFilterParams = (data) => {
    const location = data.location ? data.location : "";
    const type = data.type ? data.type : OrderProduct.REPORT_TYPE_LOCATION_WISE;
    const date = data.date
      ? data.date
      : TODAY_VALUE
    const shift = data.shift ? data.shift : "";
    const paymentType = data.paymentType ? data.paymentType : "";
    const sortType = data.sortType ? data.sortType : "";



    if (date) {
      data.date = date;
    }

    if (location) {
      data.location = location;
    }

    if (type) {
      data.type = type;
    }

    if (shift) {
      data.shift = shift;
    }

    if (paymentType) {
      data.paymentType = paymentType;
    }

    if (sortType) {
      data.sortType = sortType;
    }
    // saleUrlPush(data);
    setParam(data);
  };

  useEffect(() => {
    isLoggedIn();
    const {  date, location, type, shift, paymentType, sortType } =
      getParams();

    const data = {
      date,
      location,
      type,
      shift,
      paymentType,
      sortType,
    };

    setParam(data);
  }, []);

  const getParams = () => {
    const location = Url.GetParam("location") || "";
    const shift = Url.GetParam("shift") || "";
    const date = Url.GetParam("date") || TODAY_VALUE;
    const type = Url.GetParam("type")
      ? Url.GetParam("type")
      : OrderProduct.REPORT_TYPE_LOCATION_WISE;
    const paymentType = Url.GetParam("paymentType") || "";
    const sortType = Url.GetParam("sortType") || "";

    const data = {
      location,
      date,
      type,
      shift,
      paymentType,
      sortType,
    };
    return data;
  };
  const handleFilterChange = async (data, params) => {
    setParam(params);
    if (!params.location && params.type) {
      setDefaultGraph(false);
      setGraphData(data);
    } else if (!params.location && !params.type) {
      setDefaultGraph(true);
      setGraphData(data);
    } else {
      setDefaultGraph(false);
      setGraphData(data);
    }
  };

  const handleChange = async (data, params) => {
    if (params.location && params.type) {
      setDefaultGraph(false);
      setGraphData(data);
    } else if (!params.location && !params.type) {
      setDefaultGraph(true);
      setGraphData(data);
    } else {
      setDefaultGraph(false);
      setGraphData(data);
    }
  };

  // Breadcrumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report",
    },
    {
      label: "Order Summary Report ",
      link: "",
    },
  ];

  const handleToggle = () => {
    const newRefreshState = !isRefreshEnabled;
    setIsRefreshEnabled(newRefreshState);
    setCookie("toggleSwitch", newRefreshState ? 1 : 0);
    getOrderInitialData();
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Order Summary Report " />
        <div className="d-flex justify-content-between">
          <ToggleButton
            toggleOffLabel={"Auto Refresh"}
            LabelfontSize = {12}
            toggled={Number(isRefreshEnabled)}
            handleToggle={handleToggle}
          />
        </div>
      </div>
      <Filter
        initialParam={paramm}
        getFilterParams={(e) => {
          getFilterParams(e);
        }}
        handleFilterChange={handleFilterChange}
        history={history}
      />
      {/* Graph Table Start */}
      <OrderReportChart
        defaultGraph={defaultGraph}
        graphData={graphData}
        history={history}
        params={paramm}
      />
    </>
  );
};

export default Report;
