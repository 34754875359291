import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

// Components
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import DeleteModal from "../../components/DeleteModal";
import AddButton from "../../components/AddButton";
import DeviceList from "../mobileApp/deviceList";
import Settings from "../setting/components/Message";
import VersionTab from "./components/versionTab";
import BreadCrumb from "../../components/Breadcrumb";
import FeatureTab from "./components/FeatureTab";
import Action from "../../components/Action";
import AppSettingForm from "./components/AppSettingForm";
import MenuTab from "../admin/app/components/MenuTab";
import ActivityList from "../../components/ActivityList";
import CancelButton from "../../components/CancelButton";
import GeneralThemes from "./generalTheme";
import HorizontalSpace from "../../components/HorizontalSpace";

// Helpers
import App from "../../helpers/App";
import { portalTemplate } from "../../helpers/Support";
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";

// Services
import DeviceInfoService from "../../services/DeviceInfoService";
import AppService from "../../services/AppService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";

// Lib
import Url from "../../lib/Url";

export const Type = {
  MOBILE: "Mobile",
};

const Tab = {
  GENERAL_TAB: "General",
  FEATURE_TAB: "Feature",
  VERSIONS: "Versions",
  DEVICES: "Devices",
  SETTINGS: "Settings",
  THEMES: "Themes",
  MENUS: "Menus",
  HISTORY: "History",
};

const appDetailPage = (props) => {
  const [detail, setDetail] = useState({});
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(Url.GetParam("tab") ? Url.GetParam("tab") : Tab.GENERAL_TAB);
  const [isTypeModelOpen, setIsTypeModelOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [versionDeleteModal, setVersionDeleteModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [hostUrlValue, setHostUrlValue] = useState();
  const [templateValue, setTemplateValue] = useState(null);
  const [nameSpaceNameSpacValue, setNameSpaceNameSpacValue] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [editable, setEditable] = useState(true);
  const { id } = useParams();

  let dispatch = useDispatch();

  useEffect(() => {
    getDetail();
  }, []);

  let hasDeletePermission = hasPermission(Permission.APP_DELETE);

  let hasEditPermission = hasPermission(Permission.APP_EDIT);

  let showHistory = hasPermission(Permission.APP_HISTORY_VIEW);

  let hasVersionAddPermission = hasPermission(Permission.APP_VERSION_ADD);

  let getDetail = async () => {
    await AppService.get(
      { params: { id: props?.match?.params?.id } },
      (res) => {
        setDetail(res && res?.data);
      }
    );
  };

  const onBulkSelect = (ids) => {
    setSelectedIds(ids);
  };

  const toggle = (tab) => {
    setActiveTab(tab);
    props.history.push(
      `?tab=${activeTab}${tab == Tab.VERSIONS ? `&status=Active` : ""}`
    );
  };

  const _toggles = () => {
    setIsTypeModelOpen(!isTypeModelOpen);
  };

  const _toggle = () => {
    setIsOpen(!isOpen);
  };

  let customStatusOption = [
    {
      label: App.STATUS_ACTIVE_TEXT,
      value: App.STATUS_ACTIVE,
    },
    {
      label: App.STATUS_INACTIVE_TEXT,
      value: App.STATUS_INACTIVE,
    },
  ];

  const actionsMenuList = [
    {
      value: "Bulk Delete",
      label: "Bulk Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e == "Bulk Delete") {
      if (selectedIds && selectedIds.length > 0) {
        setIsDeleteModel(true);
      } else {
        toast.error("Select Atleast One Item");
      }
    }
  };

  const actionsMenusList = [];

  if (hasEditPermission && editable) {
    actionsMenusList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  if (hasDeletePermission) {
    actionsMenusList.push({
      value: "delete",
      label: "Delete",
    });
  }

  const handleActionsChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setIsDeleteOpen(true);
    }
  };

  const handleHostUrlChange = (e) => {
    let value = e?.target?.value;
    setHostUrlValue(value);
  };

  const handleTemplateChange = (e) => {
    let value = e?.values?.template;
    setTemplateValue(value);
  };

  const handleNameSpaceChange = (e) => {
    let value = e?.target?.value;
    setNameSpaceNameSpacValue(value);
  };

  const handleStatusChange = (e) => {
    setStatusValue(e);
  }

  let initialValues = {
    name: detail?.name ? detail?.name : "",
    nameSpace: nameSpaceNameSpacValue
      ? nameSpaceNameSpacValue
      : detail?.name_space
        ? detail?.name_space
        : "",
    status: statusValue ? customStatusOption.find((data) => data?.value == statusValue?.value) : customStatusOption.find((data) => data?.value == detail?.status),
    template: templateValue
      ? portalTemplate &&
      portalTemplate.find((data) => data?.value == templateValue?.value)
      : portalTemplate.find((data) => data?.value == detail?.template),
    host_url: hostUrlValue
      ? hostUrlValue
      : detail?.host_url
        ? detail?.host_url
        : "",
  };

  const handleSubmit = async (values) => {
    try {
      let data = new FormData();
      data.append("name", values && values?.name ? values?.name : "");
      data.append(
        "nameSpace",
        values && values?.nameSpace ? values?.nameSpace : ""
      );
      data.append(
        "status",
        values && values?.status ? values?.status?.value : ""
      );
      data.append("id", props?.match?.params?.id);
      data.append(
        "template",
        values && values?.template ? values?.template?.value : ""
      );
      data.append(
        "host_url",
        values && values?.host_url ? values?.host_url : ""
      );
      dispatch(
        await AppService.update(data, (res) => {
          setEditable(true);
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const closeDeleteToggle = () => {
    setIsDeleteOpen(false);
  };

  const handleDelete = async (value) => {
    dispatch(
      await AppService.delete(
        { data: { id: props?.match?.params?.id } },
        (res) => {
          props.history.push("/Apps");
        }
      )
    );
  };

  const deviceBulkDelete = async (selectedIds) => {
    let ids = { selectedId: selectedIds };

    if (selectedIds && selectedIds.length > 0) {
      await DeviceInfoService.bulkDelete(ids, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "deviceInfo",
              `${endpoints().userDeviceInfo}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams(),
              }
            )
          );
          setIsDeleteModel(false);
          setSelectedIds([]);
          setSelectedCheckBox(false);
          setSelectedCheckBox(true);
        }
      });
    }
  };

  const breadcrumbList = [
    { label: "Home", link: "/admin/companyProfile" },
    { label: "Apps", link: `/Apps?status=${App.STATUS_ACTIVE}` },
    { label: "App Detail", link: "" },
  ];

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title="Device"
        id={selectedIds?.id}
        label={"Bulk Delete"}
        deleteFunction={() => {
          deviceBulkDelete(selectedIds);
        }}
      />
      <DeleteModal
        isOpen={isDeleteOpen}
        toggle={closeDeleteToggle}
        title={"Delete Apps"}
        label={activeTab !== Tab.DEVICES && detail?.name}
        deleteFunction={handleDelete}
      />
      <div className="d-flex justify-content-between">
        <PageTitle label={detail && detail?.name} />
        <div className="d-flex align-items-center">
          {actionsMenusList
            && actionsMenusList.length > 0
            && activeTab === Tab.GENERAL_TAB && (
              <div className="me-2">
                <Action
                  buttonLabel="Actions"
                  hideCaret
                  dropdownLinks={actionsMenusList}
                  handleChange={handleActionsChange}
                />
              </div>
            )}
          <div className="d-flex">
            {hasVersionAddPermission && activeTab === Tab.VERSIONS && (
              <AddButton
                label="Add New"
                onClick={(e) => {
                  _toggles();
                }}
              />
            )}
            {activeTab === Tab.MENUS && (
              <AddButton
                label="Add New"
                onClick={(e) => {
                  _toggle();
                }}
              />
            )}
            {activeTab === Tab.DEVICES && (
              <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            )}
          </div>
        </div>
      </div>

      <Nav tabs className="admin-tabs">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.GENERAL_TAB,
            })}
            onClick={() => {
              setActiveTab(Tab.GENERAL_TAB);
            }}
          >
            {Tab.GENERAL_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.THEMES,
            })}
            onClick={() => {
              setActiveTab(Tab.THEMES);
            }}
          >
            {Tab.THEMES}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.MENUS,
            })}
            onClick={() => {
              setActiveTab(Tab.MENUS);
            }}
          >
            {Tab.MENUS}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.FEATURE_TAB,
            })}
            onClick={() => {
              setActiveTab(Tab.FEATURE_TAB);
            }}
          >
            {Tab.FEATURE_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.VERSIONS,
            })}
            onClick={() => {
              toggle(Tab.VERSIONS);
            }}
          >
            {Tab.VERSIONS}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.DEVICES,
            })}
            onClick={() => {
              toggle(Tab.DEVICES);
            }}
          >
            {Tab.DEVICES}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.SETTINGS,
            })}
            onClick={() => {
              toggle(Tab.SETTINGS);
            }}
          >
            {Tab.SETTINGS}
          </NavLink>
        </NavItem>
        {showHistory && (<NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.HISTORY,
            })}
            onClick={() => {
              toggle(Tab.HISTORY);
            }}
          >
            {Tab.HISTORY}
          </NavLink>
        </NavItem>)}
      </Nav>

      {activeTab === Tab.GENERAL_TAB && (
        <div className="card card-body">
          <Form
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            <AppSettingForm
              handleTemplateChange={handleTemplateChange}
              handleHostUrlChange={handleHostUrlChange}
              handleNameSpaceChange={handleNameSpaceChange}
              editable={editable}
              handleStatusChange={handleStatusChange}
            />
            {!editable && (
              <div>
                <HorizontalSpace bottom="2">
                  <SaveButton label="Save" />
                  <CancelButton
                    onClick={() => {
                      props.history.push(`/Apps?status=${App.STATUS_ACTIVE}`);
                    }}
                  />
                </HorizontalSpace>
              </div>
            )}
          </Form>
        </div>
      )}
      {activeTab === Tab.VERSIONS && (
        <VersionTab
          nameFieldLabel={"Version"}
          app_id={props?.match?.params?.id}
          noTagDetail
          showPageTitle
          __toggle={_toggles}
          isModel={isTypeModelOpen}
          history={props?.history}
          showTagTypefield={true}
          tagType={Type.MOBILE}
          mobileApp
          columnName="Version"
          label="Version"
          props={props}
          customTypeOption={[
            {
              label: Type.MOBILE,
              value: Type.MOBILE,
            },
          ]}
          hideQueryParams={{
            type: true,
          }}
        />
      )}
      {activeTab === Tab.DEVICES && (
        <DeviceList
          setProjectDeleteModal={setVersionDeleteModal}
          onBulkSelect={onBulkSelect}
          history={props?.history}
          params={{ app_id: props?.match?.params?.id }}
          selectedCheckBox={selectedCheckBox}
        />
      )}
      {activeTab === Tab.SETTINGS && (
        <Settings
          history={props.history}
          toggle={toggle}
          app_id={props?.match?.params?.id}
          showCancelButton
        />
      )}

      {activeTab == Tab.FEATURE_TAB && (
        <FeatureTab app_id={props?.match?.params?.id} />
      )}
      {activeTab === Tab.THEMES && (
        <div className="tab-content-wrapper">
          <GeneralThemes settings={props.settings} appId={id} />
        </div>
      )}
      {activeTab === Tab.MENUS && (
        <div className="tab-content-wrapper">
          <MenuTab
            app_id={props?.match?.params?.id}
            __toggle={_toggle}
            isModel={isOpen}
            history={props?.history}
            props={props}
          />
        </div>
      )}
      {showHistory && activeTab === Tab.HISTORY && (
        <div className="tab-content-wrapper">
          <ActivityList
            id={props?.match?.params?.id}
            objectId={props?.match?.params?.id}
            object_name={ObjectName.APP}
            history={props?.history}
          />
        </div>
      )}
    </>
  );
};

export default appDetailPage;
