import React from "react";
import Text from "../../../components/Text";
import DragAndDropField from "../../../components/FileUpload";
import TagSelect from "../../../components/TagSelect";

const DocumentForm = (props) => {
  let {
    selectedFile,
    onDrop,
    handleDelete,
    errorMessage,
    file,
    billView,
    currentData,
    handleTypeChange,
    TagList
  } = props;

  return (
    <div>
      {!billView && (
        <TagSelect
          name="category"
          label="Category"
          placeholder="Category"
          params={{ type: "File Category" }}
          TagList={TagList}
          handleTagChange={handleTypeChange}
        />
      )}
      {!billView && (
        <Text name="name" label="Name" placeholder="Enter Name..." />
      )}
      {!currentData && (
        <DragAndDropField
          onDrop={onDrop}
          label="Upload File"
          selectedFile={selectedFile}
          handleDelete={handleDelete}
          required={true}
          errorMessage={errorMessage}
          fileNames={file}
        />
      )}
      {currentData && currentData?.id && (
        <DragAndDropField
          onDrop={onDrop}
          label="Upload File"
          selectedFile={selectedFile}
          required={true}
          fileNames={file}
          initialValue={currentData && currentData?.url ? currentData?.url : ""}
        />
      )}
    </div>
  );
};

export default DocumentForm;
