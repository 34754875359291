// API
import { endpoints } from "../api/endPoints";

// Config
import { apiClient } from "../apiClient";

// Components
import Toast from "../components/Toast";

// Helpers
import { HttpStatus } from "../helpers/HttpStatus";

// Lib
import { isBadRequest } from "../lib/Http";

class PromotionProductService {
  static create = (data, callback) => {
    apiClient
      .post(endpoints().promotionProductAPI, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback && callback(successMessage);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status >= HttpStatus.BAD_REQUEST) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  static delete = (selectedId, callback) => {
    try {
      apiClient
        .delete(`${endpoints().promotionProductAPI}`, {
          data: selectedId,
        })
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
          }
          return callback && callback(successMessage);
        })

        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  static get = (params, callback) => {
    try {
      apiClient
        .get(`${endpoints().promotionProductAPI}`, params)
        .then((response) => {
          if (response && response?.data && response?.data?.response) {
            return callback && callback(response?.data?.response);
          }
        })

        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
}

export default PromotionProductService;
