import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import DateSelector from "./Date";
import Text from "./Text";
import TicketType from "./TicketType";
import UserCard from "./UserCard";
import ProjectSelect from "./projectSelect";
import ProjectComponentSelect from "./ProjectComponentSelect";
import DraftEditor from "./Draft";
import Drawer from "./Drawer";
import MediaCarousel from "./MediaCarousel";
import ProjectUserSelect from "./ProjectUserSelect";
import SaveButton from "./SaveButton";
import StoryPointSelect from "./StoryPointSelect";
import Select from "./Select";

// Helpers
import Media from "../helpers/Media";
import ObjectName from "../helpers/ObjectName";
import {
  TICKET_FIELD_COMPONENT,
  TICKET_FIELD_DESCRIPTION,
  TICKET_FIELD_DUE_DATE,
  TICKET_FIELD_FROM_LOCATION,
  TICKET_FIELD_STORY_POINTS,
  TICKET_FIELD_TO_LOCATION,
} from "../helpers/ProjectTicketType";

// Lib
import DateTime from "../lib/DateTime";
import Url from "../lib/Url";

// Services
import MediaService from "../services/MediaService";
import TicketService from "../services/TicketService";
import StatusService from "../services/StatusService";
import CompanyUserService from "../services/UserService";
import ArrayList from "../lib/ArrayList";
import Cookies from "../lib/Helper";
import Cookie from "../helpers/Cookie";

const CreateTicketModel = (props) => {
  let {
    rowValue = null,
    name,
    setRowValue,
    handleCloseModal,
    buttonLabel,
    isModalOpen,
    setModalOpen,
    cloneValue = null,
    isDueDateDisabled,
    recurring_task_id,
    subTaskValue = null,
  } = props;

  const [projectList, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [due_date, setDueDateChange] = useState(null);
  const [ticketType, setTicketType] = useState("");
  const [projectComponentDetail, setProjectComponentDetail] = useState("");
  const [projectValue, setProjectValue] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [imageurl, setImageUrl] = useState([]);
  const [editorState, setEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  const [summaryValue, setSummaryValue] = useState("");
  const [assignee, setAssignee] = useState("");
  const [reviewer, setReviewer] = useState("");
  const [storyPoints, setStoryPoints] = useState();
  const [isSubmit, setIsSubmit] = useState(true);
  const [statusList, setStatusList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [fieldValue, setFieldValue] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [fromLocationValue, setFromLocationValue] = useState("");
  const [toLocationValue, setToLocationValue] = useState("");
  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    if (selectedFile) {
      getUrl();
    }
  }, [isLoading, selectedFile]);

  useEffect(() => {
    getStatusList();
    getUserList();
  }, []);

  const getUserList = async () => {
    const response = await CompanyUserService.list();
    const userList = response && response.data;
    const data = [];
    userList &&
      userList.length > 0 &&
      userList.forEach((list) => {
        data.push({
          label: getUserName(list.media_url, list.first_name, list.last_name),
          value: list.first_name,
          id: list.id,
        });
      });
    setUserList(data);
  };

  const getUserName = (avatarUrl, firstName, lastName) => {
    return (
      <div className="d-flex">
        <UserCard
          id="avatar"
          firstName={firstName}
          lastName={lastName}
          url={avatarUrl}
        />
      </div>
    );
  };

  const toggle = () => {
    setProjectValue("");
    setTicketType("");
    setDueDateChange("");
    setSelectedFile("");
    handleCloseModal();
    setImageUrl("");
    setEditorState("");
    setSummaryValue("");
    setAssignee("");
    setReviewer("");
    setProjectComponentDetail("");
    setStoryPoints("");
    setIsSubmit(true);
    setFieldValue("");
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (isModalOpen && defaultValue) {
      setFieldValue(defaultValue.fields || []);
      setTicketType(defaultValue.type_id || "");
      setAssignee(defaultValue.default_assignee || "");
    }
  }, [isModalOpen, defaultValue]);

  const handleOpenModal = () => {
    setModalOpen(!isModalOpen);
    setIsSubmit(true);
  };

  const getUrl = () => {
    let url = [];
    if (selectedFile && selectedFile.length > 0) {
      for (let i = 0; i < selectedFile.length; i++) {
        const file = selectedFile[i];
        const imageUrl = URL.createObjectURL(file && file[0]);
        url.push({ url: imageUrl, image_id: file.id });
      }
      setImageUrl && setImageUrl(url);
    }
  };

  const getStatusList = async (statusId) => {
    setIsLoading(true);
    const data = await StatusService.nextStatusSearch(
      ObjectName.TICKET,
      rowValue ? rowValue?.statusId : ""
    );
    if (data && data.length > 0) {
      setStatusList(data);
    }
    setIsLoading(false);
  };

  const handleImageRemove = async (deletedvalue) => {
    if (deletedvalue) {
      const updatedImageUrlArray = selectedFile.filter(
        (item) => item.id !== deletedvalue.image_id
      );
      await setIsLoading(true);
      setSelectedFile(updatedImageUrlArray);
      setImageUrl(updatedImageUrlArray);
      setIsLoading(false);
    }
  };

  const dispatch = useDispatch();

  const initialValues = {
    summary: summaryValue
      ? summaryValue
      : rowValue?.summary
        ? rowValue?.summary
        : cloneValue?.summary
          ? cloneValue?.summary
          : subTaskValue?.summary
            ? subTaskValue?.summary
            : "",
    description: "",
    fromLocation: fromLocationValue
      ? fromLocationValue
      : rowValue?.from_location
        ? rowValue?.from_location
        : "",
    toLocation: toLocationValue
      ? toLocationValue
      : rowValue?.to_location
        ? rowValue?.to_location
        : "",
    type_id: ticketType
      ? {
        label: ticketType?.label,
        value: ticketType?.value,
      }
      : rowValue
        ? {
          label: rowValue?.ticketType,
          value: rowValue?.ticketTypeId,
        }
        : cloneValue
          ? {
            label: cloneValue?.type_name,
            value: cloneValue?.type_id,
          }
          : subTaskValue
            ? {
              label: subTaskValue?.type_name,
              value: subTaskValue?.type_id,
            }
            : "",
    component_id: projectComponentDetail
      ? {
        label: projectComponentDetail?.values?.component_id?.label,
        value: projectComponentDetail?.values?.component_id?.value,
      }
      : rowValue
        ? {
          label: rowValue?.component ? rowValue?.component : "",
          value: rowValue?.componentId ? rowValue?.componentId : null,
        }
        : cloneValue
          ? {
            label: cloneValue?.component,
            value: cloneValue?.component_id,
          }
          : "",
    due_date: due_date
      ? due_date
      : rowValue?.due_date
        ? rowValue?.due_date
        : DateTime.getDateTimeByUserProfileTimezone(new Date()),
    projectName: projectValue
      ? {
        label: projectValue?.label,
        value: projectValue?.value,
      }
      : rowValue
        ? {
          label: rowValue?.project,
          value: rowValue?.projectId,
        }
        : cloneValue
          ? {
            label: cloneValue?.projectName,
            value: cloneValue?.project,
          }
          : subTaskValue
            ? {
              label: subTaskValue?.projectName,
              value: subTaskValue?.project,
            }
            : projectList &&
            projectList?.find((data) => data?.value == Url.GetParam("projectId")),
    reviewer: reviewer
      ? {
        label: reviewer?.label,
        value: reviewer?.id,
      }
      : rowValue && rowValue?.reviewer
        ? userList && userList.find((data) => data.id == rowValue?.reviewer)
        : cloneValue && cloneValue?.reviewer
          ? userList && userList.find((data) => data.id == cloneValue?.reviewer)
          : subTaskValue && subTaskValue?.reviewer
            ? userList && userList.find((data) => data.id == subTaskValue?.reviewer)
            : "",
    assignee_id:
      assignee && assignee
        ? userList && userList.find((data) => data.id == assignee)
        : ticketType && ticketType?.default_assignee
          ? userList &&
          userList.find((data) => data.id == ticketType?.default_assignee)
          : rowValue && rowValue?.assignee_id
            ? userList && userList.find((data) => data.id == rowValue?.assignee_id)
            : cloneValue && cloneValue?.assignee_id
              ? userList &&
              userList.find((data) => data.id == cloneValue?.assignee_id)
              : subTaskValue && subTaskValue?.assignee_id
                ? userList &&
                userList.find((data) => data.id == subTaskValue?.assignee_id)
                : "",
    story_points: storyPoints
      ? {
        label: storyPoints.label,
        value: storyPoints.value,
      }
      : ticketType && ticketType?.default_story_point
        ? {
          label: ticketType?.default_story_point,
          value: ticketType?.default_story_point,
        }
        : rowValue?.story_points
          ? {
            label: rowValue?.story_points,
            value: rowValue?.story_points,
          }
          : cloneValue?.story_points
            ? {
              label: cloneValue?.story_points,
              value: cloneValue?.story_points,
            }
            : subTaskValue?.story_points
              ? {
                label: subTaskValue?.story_points,
                value: subTaskValue?.story_points,
              }
              : "",
    status: rowValue
      ? {
        label: rowValue?.statusName,
        value: rowValue?.statusId,
      }
      : "",
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const summaryChange = (x) => {
    let data = x?.target?.value;
    setSummaryValue(data);
  };

  const fromLocationChanage = (x) => {
    let data = x?.target?.value;
    setFromLocationValue(data);
  };

  const toLocationChanage = (x) => {
    let data = x?.target?.value;
    setToLocationValue(data);
  };

  const handleUserChange = (values) => {
    setAssignee(values && values.id ? values.id : "");
  };

  const handleReviewer = (values) => {
    setReviewer(values);
  };

  const oninputProjectChange = (value) => {
    setProjectValue(value);
  };

  const uploadFile = async (objectId, showToastMessage = false) => {
    try {
      if (selectedFile && selectedFile.length > 0 && objectId) {
        for (let i = 0; i < selectedFile.length; i++) {
          const File = selectedFile[i];
          const mediaFile = File ? File[0] : "";
          const media = File[0]?.name;

          const data = new FormData();

          if (mediaFile) {
            data.append([Media.MEDIA_FILE], mediaFile ? mediaFile : "");
          }
          if (media !== undefined) {
            data.append([Media.MEDIA_NAME], media ? media : "");
          }
          data.append("object", ObjectName.TICKET);

          data.append("object_id", objectId);

          data.append([Media.MEDIA_VISIBILITY], Media.VISIBILITY_PUBLIC);

          await MediaService.saveImage(data, showToastMessage);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  let param = {
    pagination: true,
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate"),
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    user: Url.GetParam("user"),
    reporter: Url.GetParam("reporter"),
    sprint: Url.GetParam("sprint"),
    status: Url.GetParam("status"),
    group: Url.GetParam("group"),
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate"),
    search: Url.GetParam("search"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    ticketType: Url.GetParam("ticketType"),
    ticketComponent: Url.GetParam("ticketComponent"),
    reviewer: Url.GetParam("reviewer"),
    recurring_task_id: recurring_task_id,
    projectId: Url.GetParam("projectId")
      ? Url.GetParam("projectId")
      : Url.GetParam("project_id")
        ? Url.GetParam("project_id")
        : Cookies.get(Cookie.PROJECT_ID),
    parent_ticket_id: subTaskValue?.id ? subTaskValue?.id : "",
    objectName: ObjectName.TICKET,
    date: Url.GetParam("date")
  };

  const createTicket = async (value) => {
    setIsSubmit(false);
    try {
      const data = new FormData();
      data.append(
        rowValue && rowValue?.id ? "assignee" : "assignee_id",
        value && value?.assignee_id?.id
          ? value?.assignee_id?.id
          : value?.assignee_id?.value
            ? value?.assignee_id?.value
            : ""
      );
      data.append(
        rowValue && rowValue?.id ? "reviewer" : "reviewer_id",
        value && value?.reviewer?.id
          ? value?.reviewer?.id
          : value?.reviewer?.value
            ? value?.reviewer?.value
            : ""
      );
      data.append("due_date", value.due_date);
      data.append(
        "story_points",
        value && value?.story_points ? value?.story_points?.value : ""
      );

      const rawComment = editorState
        ? JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        : "";
      if (rawComment) {
        data.append("description", rawComment);
      }
      data.append(
        "projectName",
        value?.projectName?.value ? value?.projectName?.value : ""
      );
      data.append(
        "projectId",
        projectValue && projectValue?.value
          ? projectValue?.value
          : cloneValue?.project
            ? cloneValue?.project
            : subTaskValue?.project
              ? subTaskValue?.project
              : Url.GetParam("projectId")
      );
      data.append(
        "project",
        value && value?.projectName?.value ? value?.projectName?.value : ""
      );
      data.append("summary", value.summary);
      data.append("fromLocation", value.fromLocation);
      data.append("toLocation", value.toLocation);
      data.append(
        "type_id",
        value && value?.type_id?.value ? value?.type_id?.value : ""
      );
      data.append(
        "component_id",
        value && value?.component_id?.value ? value?.component_id?.value : ""
      );
      if (value.etaTime) {
        const [date, time] = value.etaTime.split("T");

        const concatenatedDateTime = `${DateTime.formatDate(
          value.due_date
        )}${""}T${""}${time}`;

        data.append("due_date", concatenatedDateTime);
      }
      data.append("status", value && value?.status ? value?.status?.value : "");
      data.append(
        "statusName",
        value && value?.status ? value?.status?.label : ""
      );
      if (subTaskValue) {
        data.append("parent_ticket_id", subTaskValue?.id);
      }

      if (rowValue && rowValue?.id) {
        dispatch(
          TicketService.update(
            rowValue && rowValue?.id,
            data,
            param,
            (response) => {
              if (response) {
                toggle();
                setRowValue && setRowValue("");
                setIsSubmit(true);
              }
            }
          )
        );
      } else {
        setIsSubmit(false);
        dispatch(
          TicketService.createTicket(data, param, (response) => {
            if (response && response.data && response.data.ticketDetails) {
              uploadFile(response?.data?.ticketDetails?.id);
              toggle();
              setIsSubmit(true);
            }
            handleCloseModal();
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleImageValue = (images) => {
    setIsLoading(true);
    setSelectedFile([...selectedFile, { ...images }]);
    setIsLoading(false);
  };

  const onDropImage = (images) => {
    handleImageValue({
      ...images,
      id: selectedFile.length + 1,
    });
  };

  const handleTicketTypeChange = (e) => {
    setTicketType(e.values.type_id);
    if (e?.values?.type_id?.fields == null) {
      setFieldValue("");
    }
    let fields = e?.values?.type_id?.fields;
    if (
      fields &&
      fields !== null &&
      fields &&
      fields?.includes(TICKET_FIELD_COMPONENT.toString())
    ) {
      let value = e?.values?.type_id?.fields;
      setFieldValue(value);
    }
  };

  const handleProjectComponentChange = (e) => {
    setProjectComponentDetail(e);
  };

  const handleChange = ({ values }) => {
    setStoryPoints(values && values?.story_points);
  };

  const handleDueDateChange = (e) => {
    setDueDateChange(e);
  };

  const handleDisplayDefaultValue = (value) => {
    if (value !== null && value !== "") {
      setDefaultValue(value);
    }
  };
  let field = ArrayList.isArray(fieldValue)
    ? fieldValue
    : ArrayList.isArray(typeList)
      ? typeList.find((data) => data?.value == initialValues?.type_id?.value)
        ?.fields
      : [];
  // Add Ticket Form
  const DrawerBody = (
    <>
      {rowValue && <Select label="Status" name="status" options={statusList} />}
      <div className="row">
        <ProjectSelect
          label="Project"
          oninputProjectChange={oninputProjectChange}
          projectList={setProjectList}
        />
      </div>
      <div className="row">
        <div
          className={
            defaultValue &&
              defaultValue?.fields &&
              defaultValue?.fields.includes(TICKET_FIELD_COMPONENT.toString())
              ? "col-12 col-sm-6 col-md-6 col-lg-6"
              : fieldValue &&
                fieldValue.includes(TICKET_FIELD_COMPONENT.toString())
                ? "col-12 col-sm-6 col-md-6 col-lg-6"
                : "col-12 col-sm-12 col-md-12 col-lg-12"
          }
        >
          <TicketType
            label="Ticket Type"
            name="type_id"
            handleTicketTypeChange={(e) => handleTicketTypeChange(e)}
            showDefaultValue={true}
            projectId={
              projectValue?.value ||
              rowValue?.projectId ||
              cloneValue?.project ||
              (subTaskValue && subTaskValue?.project)
            }
            onDisplayDefaultValue={handleDisplayDefaultValue}
            required
            typeList={setTypeList}
            menuPortal=""
          />
        </div>
        {fieldValue &&
          (fieldValue.includes(TICKET_FIELD_COMPONENT.toString()) ||
            (defaultValue &&
              defaultValue?.fields &&
              defaultValue?.fields.includes(
                TICKET_FIELD_COMPONENT.toString()
              ))) && (
            <div className="col-12 col-sm-6">
              <ProjectComponentSelect
                label="Component"
                name="component_id"
                handleProjectComponentChange={(e) =>
                  handleProjectComponentChange(e)
                }
                showDefaultValue={true}
                projectId={
                  projectValue?.value ||
                  rowValue?.projectId ||
                  cloneValue?.project
                }
                menuPortal=""
              />
            </div>
          )}
      </div>
      <div className="row">
        <div className={rowValue?.id ? "col-lg-6" : "col-lg-12"}>
          <ProjectUserSelect
            label="Assignee"
            name="assignee_id"
            placeholder={"Select Assignee"}
            handleUserChange={handleUserChange}
            projectId={
              (projectValue && projectValue.value) ||
              rowValue?.projectId ||
              (cloneValue && cloneValue?.project) ||
              (subTaskValue && subTaskValue?.project)
            }
            isDisabled={
              rowValue?.allow_for_assignee_change_permission == false
                ? true
                : false
            }
          />
        </div>
        {rowValue && (
          <div className="col-lg-6 col-sm-6 col-12">
            <ProjectUserSelect
              label="Reviewer"
              name="reviewer"
              placeholder={"Select Assignee"}
              handleUserChange={handleReviewer}
              projectId={
                (projectValue && projectValue.value) ||
                rowValue?.projectId ||
                (cloneValue && cloneValue?.project) ||
                (subTaskValue && subTaskValue?.project)
              }
            />
          </div>
        )}
      </div>
      <div className="row">
        {fieldValue &&
          (fieldValue.includes(TICKET_FIELD_DUE_DATE.toString()) ||
            (defaultValue &&
              defaultValue?.fields &&
              defaultValue?.fields.includes(TICKET_FIELD_DUE_DATE.toString()))) && (
            <div className={
              defaultValue &&
                defaultValue?.fields &&
                defaultValue?.fields.includes(TICKET_FIELD_STORY_POINTS.toString())
                ? (fieldValue && fieldValue.includes(TICKET_FIELD_STORY_POINTS.toString())
                  ? "col-12 col-sm-6"
                  : "col-12 col-sm-12")
                : "col-12 col-sm-12"
            }>
              <DateSelector
                name="due_date"
                label={"Due Date"}
                placeholder="Select Due Date"
                isClearable={!isDueDateDisabled}
                disabled={isDueDateDisabled}
                onChange={handleDueDateChange}
              />
            </div>
          )}

        {fieldValue &&
          (fieldValue.includes(TICKET_FIELD_STORY_POINTS.toString()) ||
            (defaultValue &&
              defaultValue?.fields &&
              defaultValue?.fields.includes(TICKET_FIELD_STORY_POINTS.toString()))) && (
            <div className={
              defaultValue &&
                defaultValue?.fields &&
                defaultValue?.fields.includes(TICKET_FIELD_DUE_DATE.toString())
                ? (fieldValue && fieldValue.includes(TICKET_FIELD_DUE_DATE.toString())
                  ? "col-12 col-sm-6"
                  : "col-12 col-sm-12")
                : "col-12 col-sm-12"
            }>
              <StoryPointSelect
                name="story_points"
                label="Story Points"
                placeholder="Select Story Points"
                onChange={(values) => {
                  handleChange(values);
                }}
                menuPortal=""
              />
            </div>
          )}
      </div>
      <div className="row">
        {fieldValue &&
          (fieldValue.includes(TICKET_FIELD_FROM_LOCATION.toString()) ||
            (defaultValue &&
              defaultValue?.fields &&
              defaultValue?.fields.includes(
                TICKET_FIELD_FROM_LOCATION.toString()
              ))) && (
            <div className="col-12 col-sm-6">
              <Text
                name="fromLocation"
                label="From Location"
                placeholder="From Location"
                onChange={fromLocationChanage}
              />
            </div>
          )}
        {fieldValue &&
          (fieldValue.includes(TICKET_FIELD_TO_LOCATION.toString()) ||
            (defaultValue &&
              defaultValue?.fields &&
              defaultValue?.fields.includes(
                TICKET_FIELD_TO_LOCATION.toString()
              ))) && (
            <div className="col-12 col-sm-6">
              <Text
                name="toLocation"
                label="To Location"
                placeholder="To Location"
                onChange={toLocationChanage}
              />
            </div>
          )}
      </div>
      <Text
        name="summary"
        label="Summary"
        placeholder="Summary"
        onChange={summaryChange}
        required
      />
      {fieldValue &&
        (fieldValue.includes(TICKET_FIELD_DESCRIPTION.toString()) ||
          (defaultValue &&
            defaultValue?.fields &&
            defaultValue?.fields.includes(
              TICKET_FIELD_DESCRIPTION.toString()
            ))) && (
          <div className="custom-description-container">
            <DraftEditor
              name="description"
              label={"Description"}
              editorState={
                editorState
                  ? editorState
                  : cloneValue?.description
                    ? EditorState.createWithContent(
                      convertFromRaw(JSON.parse(cloneValue?.description))
                    )
                    : subTaskValue?.description
                      ? EditorState.createWithContent(
                        convertFromRaw(JSON.parse(subTaskValue?.description))
                      )
                      : name
              }
              onChange={handleEditorChange}
              height="90px"
            />
          </div>
        )}
      <div className="p-0 mt-2">
        {!rowValue && (
          <div className="col-12">
            <MediaCarousel
              showCarasoul
              Attachments
              onDropImage={onDropImage}
              imageUrl={imageurl}
              handleImageRemove={handleImageRemove}
              isLoading={isLoading}
            />
          </div>
        )}

        {rowValue && rowValue?.id && (
          <MediaCarousel
            showCarasoul
            objectName={ObjectName.TICKET}
            objectId={rowValue && rowValue.id}
            history={history}
            attachmentsList={true}
            Attachments={"Attachments"}
          />
        )}
      </div>
    </>
  );

  // Ticket Footer
  const DrawerFooter = (
    <>
      <SaveButton
        type="submit"
        loading={isSubmit == false}
        label={rowValue?.id ? "Save" : "Create"}
      />
    </>
  );

  return (
    <>
      <Drawer
        modelTitle={
          subTaskValue && rowValue
            ? "Edit Sub Task"
            : rowValue?.id
              ? "Edit Ticket "
              : cloneValue
                ? "Clone Ticket"
                : subTaskValue
                  ? "Create Sub Task"
                  : "Create Ticket"
        }
        DrawerBody={DrawerBody}
        DrawerFooter={DrawerFooter}
        onSubmit={(values) => {
          createTicket(values);
        }}
        initialValues={initialValues}
        handleOpenModal={handleOpenModal}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isModalOpen}
        buttonLabel={buttonLabel}
        showAddButton={props.showAddButton}
        enableReinitialize
      />
    </>
  );
};
export default CreateTicketModel;
