import { apiClient } from "../apiClient";
import { endpoints } from "../api/endPoints";
import { fetchList } from "../actions/table";
import toast from "../components/Toast";
import { isBadRequest } from "../lib/Http";
import * as InventoryTransfer from "../actions/transferType";
import Url from "../lib/Url";
import ArrayList from "../lib/ArrayList";
class OrderTypeService {
  async list(params) {
    if (params && Object.keys(params).length > 0) {
      // Convert params to query string
      let queryString = await ArrayList.toQueryString(params);
      // Make the request with query string
      let response = await Url.get(`${endpoints().orderTypeAPI}/list?${queryString}`);
      return response;
  } else {
      // Make the request without query string
      let response = await Url.get(`${endpoints().orderTypeAPI}/list`);
      return response;
  }
  }
  async get(id) {
    const response = await apiClient.get(`${endpoints().orderTypeAPI}/${id}`);
    return response && response.data;
  }
  async create(data, params, toggle) {
    return (dispatch) => {
      dispatch(InventoryTransfer.requestAddTransferTypes());
      return apiClient
        .post(`${endpoints().orderTypeAPI}/create`, data)
        .then((res) => {
          if (res && res.data) {
            // callback(true);
            toast.success("Order Type Added Successfully");
            toggle();
          }
        })
        .then(() => {
          dispatch(
            fetchList(
              "OrderType",
              `${endpoints().orderTypeAPI}/search`,
              1,
              25,
              params
            )
          );
        })
        .catch((err) => {
          dispatch(InventoryTransfer.addTransferTypesError());
          if (isBadRequest(err)) {
            let errorMessage;
            const errorRequest = err.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(errorMessage);
          }
        });
    };
  }

  async update(id, updateData, callback) {
    try{
      apiClient
        .put(`${endpoints().orderTypeAPI}/${id}`, updateData)
        .then((response) => {
          toast.success(response.data.message);
          return callback(response.data.message);
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(errorMessage);
          }
        });
      }catch(err){
        console.log(err);
      }
  }

  async delete(id, params) {
    return (dispatch) => {
      apiClient
        .delete(`${endpoints().orderTypeAPI}/${id}`)
        .then((response) => {
          let successMessage;

          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
          }
        })
        .then(() => {
          dispatch(
            fetchList(
              "OrderType",
              `${endpoints().orderTypeAPI}/search`,
              params.page ? params.page : 1,
              params.pageSize ? params.pageSize : 25,
              params
            )
          );
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;

            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    };
  }

  async getDetails(id, callback) {
    apiClient
      .get(`${endpoints().orderTypeAPI}/${id}`)
      .then((response) => {
        return callback(response.data);
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = err.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  }

  
}
const orderTypeService = new OrderTypeService();
export default orderTypeService;
