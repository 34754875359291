


const LocationRack={
    STATUS_ACTIVE_TEXT: "Active",
    STATUS_INACTIVE_TEXT: "InActive",

    STATUS_ACTIVE: 0,
    STATUS_INACTIVE: 1,
}
export default LocationRack;