import React, { useState } from "react";
import TextArea from "../../components/TextArea";
import { Setting } from "../../helpers/Setting";
import "../../scss/_custom.scss";

const LegalDisclosure = (props) => {
  const { toggleColor, legalToggle, legalNavToggles } = props;
  const [isExpanded, setIsExpanded] = useState(true);

  const legalColor = isExpanded ? "#ECF5FF" : "#FFFFFF";

  return (
    <>
      <div className="accordion mt-3" id="accordionLegalDisclosure">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingLegalDisclosure">
            <button
              className={`accordion-button ${isExpanded ? "" : "collapsed"}`}
              style={{ backgroundColor: legalColor }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseLegalDisclosure"
              aria-expanded={isExpanded ? "true" : "false"}
              aria-controls="collapseLegalDisclosure"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <h5 className="text-primary">Legal Disclosure</h5>
            </button>
          </h2>
          <div
            id="collapseLegalDisclosure"
            className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
            aria-labelledby="headingLegalDisclosure"
            data-bs-parent="#accordionLegalDisclosure"
          >
            <div className="accordion-body p-3">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <TextArea
                    className="border rounded"
                    name={Setting.SETTINGS_LEGAL_DISCLOSURE}
                    placeholder="Enter the legal disclosure..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalDisclosure;
