import React, { useEffect, useState } from "react";
import Select from "./Select";
import CategoryService from "../services/CategoryService";
import ArrayList from "../lib/ArrayList";
import Url from "../lib/Url";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";

const CategorySelect = (props) => {
  const {
    name,
    handleCategoryChange,
    customCategoryOption,
    categoryList,
    label,
    isDisabled,
    AccountProductCategoryList,
    showCategoryDetailsPageLink,
    categoryId
  } = props;

  const [categoryOption, setCategoryOption] = useState([]);

  useEffect(() => {
    if (ArrayList.isEmpty(categoryOption)) {
      if (AccountProductCategoryList) {
        getCategoryList();
      } else {
        getCategory();
      }
    }
  }, [categoryOption, AccountProductCategoryList]);

  const getCategory = async () => {
    const response = await CategoryService.getOption();
    setCategoryOption(response);
    categoryList(response);
  };

  const getCategoryList = async () => {
    let params = {
      account_id: Url.GetParam("accountId"),
      pagination: false,
    };
    const response = await CategoryService.search(params);
    const categoryList = [];

    if (response && response.data && response.data.length > 0) {
      response.data.forEach((category) => {
        categoryList.push({
          id: category.id,
          value: category.id,
          label: category.name,
        });
      });
    }
    setCategoryOption(categoryList);
    categoryList(categoryList);
  };

  return (
    <div className="d-flex w-100">
      <Select
        name={name ? name : "category"}
        placeholder="Select Category"
        label={label}
        options={customCategoryOption ? customCategoryOption : categoryOption}
        handleChange={handleCategoryChange}
        menuPortal={props.menuPortal}
        isDisabled={isDisabled}
      />
      {showCategoryDetailsPageLink && (
        <div className="flex-shrink-1 bd-highlight ms-1">
          <Link target="_blank" to={`/category/${categoryId}`}>
            <FontAwesomeIcon
              className="text-body-secondary p-1"
              icon={faSquareArrowUpRight}
              style={{
                fontSize: "40px",
              }}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default CategorySelect;
