// Actions
import ProjectUser from "../actions/projectUser";
import { fetchList } from "../actions/table";

// API
import { endpoints } from "../api/endPoints";

// Config
import { apiClient } from "../apiClient";

// Components
import Toast from "../components/Toast";

// Helpers
import { HttpStatus } from "../helpers/HttpStatus";

// Lib
import { isBadRequest } from "../lib/Http";

class PromotionService {
  static create = (data, callback) => {
    apiClient
      .post(endpoints().promotionAPI, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback && callback(successMessage);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status >= HttpStatus.BAD_REQUEST) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  static delete = (id,callback) => {
    try {
      apiClient
        .delete(`${endpoints().promotionAPI}/${id}`)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
          }
          return callback && callback(successMessage)
        })

        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  static async updateStatus(id, status, callback) {
    let data = {};
    data.status = status;
    apiClient
      .put(`${endpoints().promotionAPI}/updateStatus/${id}`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback && callback(successMessage);
        }
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }
  static update = (id, data, callback) => {
    apiClient
      .put(`${endpoints().promotionAPI}/${id}`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback && callback(successMessage);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status >= HttpStatus.BAD_REQUEST) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  static updateStatus = (id, data, callback) => {
    apiClient
      .put(`${endpoints().promotionAPI}/status/${id}`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback && callback(successMessage);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status >= HttpStatus.BAD_REQUEST) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
  static get = (id,callback) => {
    try {
      apiClient
        .get(`${endpoints().promotionAPI}/${id}`)
        .then((response) => {
          if (response && response?.data && response?.data?.response) {
            return callback && callback(response?.data?.response)
          }
        })

        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
}

export default PromotionService;
