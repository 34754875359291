// User Tab Constants
export const User = {
  ACCEPTED_USERS_TAB: "Accepted",
  ACTIVE_USER_TAB: "Active",
  INACTIVE_USER_TAB: "InActive",
  ALL_USERS_TAB: "All",
  USER: "PortalUser",

  GENERAL_TAB: "General",
  ADDRESS: "Address",
  SECURITY: "security",
  EMPLOYMENT: "Employment",
  FILES: "Files",
  FINES: "Fines",
  SLACK: "Slack",
  SALARY: "Salary",
  HISTORY: "History",
  ATTENDANCE: "Attendance",
  DEVICES: "Devices",
  GEO_LOCATIONS: "Geo Locations",
  LOCATIONS: "Locations",
  SETTINGS: "Settings",
  USER_LOCATION: "User Location",
  TEAM: "Team",
  STATUS_ACTIVE_TEXT: "Active",
  STATUS_INACTIVE_TEXT: "InActive",
  STATUS_ALL_TEXT: "All",

  STATUS_ACTIVE_VALUE: 1,
  STATUS_INACTIVE_VALUE: 2,

  PRIMARY_TYPE: 1,
  SECONDARY_TYPE: 2,
  LOGGED_IN_USER: "Logged In User",
  LOGGED_IN_USER_VALUE: -1,

  PREFERRED_LOCATIONS: "Preferred Locations",
  ENABLED_ALLOW_LEAVE: "Enabled",
  DISABLED_ALLOW_LEAVE: "Disabled"
};

//Admin Action
export const adminActionOptions = [
  {
    value: "Login As",
    label: "Login As",
  },
  {
    value: "Resend Invite",
    label: "Resend Invite",
  },
  {
    value: "Delete",
    label: "Delete",
  },
];

export const leaveOption = [
  {
      value: "true",
      label: "Enabled"
  },
  {
      value: "false",
      label: "Disabled"
  },
  ]

export const FieldLabel = {
  PRIMARY_LOCATION: "Primary Location",
  PRIMARY_SHIFT: "Primary Shift",
  LAST_LOGGEDIN_AT: "Last LoggedIn At",
  DATE_OF_JOINING: "Date Of Joining",
  DATE_OF_LEAVING: "Date Of Leaving",
  SALARY: "Salary",
  LEAVE_BALANCE: "Leave Balance",
  CURRENT_LOCATION: "current_location",
  CURRENT_SHIFT: "current_shift",
  ALLOW_LEAVE: "Allow Leave",
  LAST_CHECK_IN_AT: "Last Check In At",
  WORKED_DAYS: "Worked Days",
  LEAVE_DAYS: "Leave Days",
  EXPERIENCE: "Experience",
};
