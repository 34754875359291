import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";

// Components
import AddButton from "../../components/AddButton";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Text from "../../components/Text";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";

// Lib
import Url from "../../lib/Url";

// Services
import HolidayService from "../../services/HolidayService";

// Helpers
import DateSelector from "../../components/Date";
import DateTime from "../../lib/DateTime";
import Holiday from "../../helpers/Holiday";
import Select from "../../components/Select";

const HolidayList = (props) => {
    let { history } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [row, setRow] = useState(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [holidayDate, setHolidayDate] = useState();
    const [holidayName, setHolidayName] = useState();
    const [type, setType] = useState(row?.type || "");

    let dispatch = useDispatch();

    const _toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleAddButtonClick = () => {
        _toggle();
        setRow("");
    };

    const openToggle = () => {
        setIsOpen(true)
    }

    const closeToggle = () => {
        setIsOpen(false)
        setRow(null)
        setType("")
        setHolidayName("")
        setHolidayDate("")
    }

    const closeDeleteToggle = () => {
        setIsDeleteOpen(false)
        setRow(null)
    }

    const holidayNameChange = (e) => {
        let value = e.target.value;
        setHolidayName(value)
    }

    const handleDate = async (date) => {
        const value = date ? DateTime.toISOStringDate(date) : "";
        setHolidayDate(value);
    };

    const handleTypeChange = (e) => {
        setType(e?.value ? e.value : "");
    };

    const holidayTypeOptions = [
        {
            value: Holiday.COMPULSORY_VALUE,
            label: Holiday.COMPULSORY_TEXT,
        },
        {
            value: Holiday.OPTIONAL_VALUE,
            label: Holiday.OPTIONAL_TEXT,
        },
    ];

    let holidayForm = (
        <>
            <Text
                name="name"
                label="Name"
                onChange={holidayNameChange}
                required
            />
            <Select
                fullWidth={true}
                label="Type"
                name="type"
                isClearable
                options={holidayTypeOptions}
                handleChange={handleTypeChange}
                required
            />
            <DateSelector
                name="date"
                label="Date"
                onChange={handleDate}
                required
                isClearable
            />
        </>
    )

    let formFooter = (
        <>
            <SaveButton label={row ? "Save" : "Add"} />
        </>
    )

    let initialValues = {
        name: holidayName ? holidayName : row?.name,
        date: DateTime.getDateTimeByUserProfileTimezone(holidayDate) ? DateTime.getDateTimeByUserProfileTimezone(holidayDate) : row?.date,
        type: type ? holidayTypeOptions.find((data) => data.value == type)
            : holidayTypeOptions.find((data) => data.value == row?.typeId)
    }

    let handleSubmit = async (values) => {
        let data = new FormData()
        data.append("name", values && values?.name ? values?.name : "")
        data.append("date", DateTime.toISOStringDate(values.date));
        data.append("type", values && values?.type ? values?.type?.value : "");
        if (row?.id) {
            data.append("id", row?.id);
            dispatch(await HolidayService.update(row?.id, data, (res) => {
                dispatch(
                    fetchList(
                        "holiday",
                        `${endpoints().holidayAPI}/search`,
                        Url.GetParam("page") ? Url.GetParam("page") : 1,
                        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                        {
                            ...Url.GetAllParams()
                        }
                    )
                );
                closeToggle()
            }))
        } else {
            dispatch(await HolidayService.create(data, (res) => {
                dispatch(
                    fetchList(
                        "holiday",
                        `${endpoints().holidayAPI}/search`,
                        Url.GetParam("page") ? Url.GetParam("page") : 1,
                        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                        {
                            ...Url.GetAllParams()
                        }
                    )
                );
                closeToggle()
            }))
        }
    }

    const handleDelete = async (value) => {
        dispatch(await HolidayService.delete({ data: { id: row?.id } }, (res) => {
            dispatch(
                fetchList(
                    "holiday",
                    `${endpoints().holidayAPI}/search`,
                    Url.GetParam("page") ? Url.GetParam("page") : 1,
                    Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                    {
                        ...Url.GetAllParams()
                    }
                )
            );
            closeToggle()
        }))
    }

    const sortByOption = [
        {
            value: "id:DESC",
            label: "Most Recent",
        },
        {
            value: "name:ASC",
            label: "Name",
        },
    ];


    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <PageTitle label="Holiday" />
                <AddButton
                    className="ms-2 me-1"
                    label="Add"
                    onClick={handleAddButtonClick}
                />
            </div>
            <DeleteModal
                isOpen={isDeleteOpen}
                toggle={closeDeleteToggle}
                title="Delete Holiday"
                label={row?.name}
                deleteFunction={handleDelete}
            />
            <Drawer
                isModalOpen={isOpen}
                handleOpenModal={openToggle}
                handleCloseModal={closeToggle}
                handleDrawerClose={closeToggle}
                modelTitle={row ? "Edit Holiday" : "Add Holiday"}
                DrawerBody={holidayForm}
                DrawerFooter={formFooter}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
                hideDefaultButtons
            />
            <ReduxTable
                id="holiday"
                searchPlaceholder="Search"
                apiURL={`${endpoints().holidayAPI}/search`}
                newTableHeading
                paramsToUrl={true}
                history={history}
                sortByOptions={sortByOption}
            >
                <ReduxColumn
                    field="name"
                    sortBy="name"
                    className="text-center"
                    width="160px"
                    minWidth="160px"
                    maxWidth="160px"
                    renderField={(row) => (
                        <Link
                            to={`/admin/settings/holiday/${row.id}`}
                            className="link-opacity-75 text-decoration-none lh-lg"
                        >
                            {row.name}
                        </Link>
                    )}
                >
                    Name
                </ReduxColumn>
                <ReduxColumn
                    field="type"
                    width="310px"
                    minWidth="310px"
                    maxWidth="310px"
                    className="text-center"
                    sortBy="type"
                >
                    Type
                </ReduxColumn>
                <ReduxColumn
                    field="date"
                    sortBy="date"
                    width="110px"
                    minWidth="110px"
                    maxWidth="110px"
                    className="text-center"
                    renderField={(row) => (
                        <span>{DateTime.getDate(row.date)}</span>
                    )}
                >
                    Date
                </ReduxColumn>
                <ReduxColumn
                    field="Action"
                    disableOnClick
                    width="120px"
                    renderField={(row) => (
                        <div className="text-center action-group-dropdown">
                            <MoreDropdown>
                                <DropdownItem
                                    onClick={() => {
                                        setRow(row)
                                        openToggle()
                                    }}
                                >
                                    Quick View
                                </DropdownItem>
                                <DropdownItem
                                    className="text-danger"
                                    onClick={() => {
                                        setRow(row)
                                        setIsDeleteOpen(true)
                                    }}
                                >
                                    Delete
                                </DropdownItem>
                            </MoreDropdown>
                        </div>
                    )}
                >
                    Action
                </ReduxColumn>
            </ReduxTable>
        </>
    );
};

export default HolidayList;
