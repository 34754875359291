import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Actions
import { fetchList } from "../../../actions/table";

// API
import { endpoints } from "../../../api/endPoints";

// Components
import Drawer from "../../../components/Drawer";
import SaveButton from "../../../components/SaveButton";
import Spinner from "../../../components/Spinner";
import TextArea from "../../../components/TextArea";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import TagSelect from "../../../components/TagSelect";
import Select from "../../../components/Select";

// Lib
import Url from "../../../lib/Url";

// Service
import RatingService from "../../../services/RatingService";
import RatingTypeSelect from "../../../components/RatingTypeSelect";

const buttonLabel = true;

const RatingList = (props) => {
  const {
    history,
    paymentTab,
    isOpen,
    toggles,
    handleOpenModal,
    handleCloseModal,
    setRowValue,
    rowValue = null,
    setDetail,
    setIsSubmitting,
    isSubmitting,
    setOpenDeleteModal,
    apiUrl,
    vendorDetails,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [ratingComment, setRatingComment] = useState();
  const [typeDetails, setTypeDetails] = useState();
  const [ratingTypeList, setRatingTypeList] = useState([]);
  const [ratingValue, setRatingValue] = useState();
  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  useEffect(() => {
    getDetails();
  }, [rowValue]);

  const getDetails = async () => {
    const data = await RatingService.get(rowValue?.id);
    if (data) {
      setDetail(data);
    }
  };

  let vendorId = vendorDetails?.data?.vendorId;

  const _toggle = () => {
    setRowValue("");
    handleCloseModal();
    setRatingComment("");
    setDetail("");
    setTypeDetails(null);
    setRatingValue(null);
  };

  const initialValues = {
    comment: ratingComment
      ? ratingComment
      : rowValue?.comment
        ? rowValue?.comment
        : "",
    type: typeDetails
      ? typeDetails
      : rowValue?.rating_tag_id
        ? ratingTypeList.find((data) => data?.value == rowValue?.rating_tag_id)
        : "",
    rating: ratingValue
      ? ratingValue
      : rowValue?.rating
        ? { label: rowValue?.rating, value: rowValue?.rating }
        : "",
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(false);
      const data = new FormData();
      let param = {
        ...Url.GetAllParams(),
      };
      if (rowValue && rowValue?.id) {
        data.append("type", values && values.type.value);
        data.append("rating", values && values.rating.value);
        data.append("comment", values && values.comment);
        data.append("vendorId", vendorId);

        setIsLoading(true);
        await RatingService.update(
          rowValue?.id,
          data,
          setIsSubmitting,
          (res) => {
            if (res) {
              handleCloseModal();
              dispatch(
                fetchList(
                  "rating",
                  `${endpoints().ratingAPI}/search`,
                  1,
                  25,
                  valueProps
                )
              );
            }
          }
        );
        setIsLoading(false);
        setDetail("");
        setRatingComment("");
      } else {
        data.append("type", values && values.type.value);
        data.append("rating", values && values.rating.value);
        data.append("comment", values && values.comment);
        data.append("vendorId", vendorId);

        dispatch(
          await RatingService.create(data, param, toggles, (response) => {
            setIsLoading(true);
            if (response) {
              setIsLoading(false);
              setIsSubmitting(true);
              setDetail("");
              setRatingComment("");
              handleCloseModal();
              _toggle();
              // Update the list after successful creation
              dispatch(
                fetchList(
                  "rating",
                  `${endpoints().ratingAPI}/search`,
                  1,
                  25,
                  valueProps
                )
              );
            }
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCommentTerms = (e) => {
    setRatingComment(e?.target?.value);
  };

  const handleTypeChange = (e) => {
    setTypeDetails(e);
  };

  const handleRatingChange = (e) => {
    let data = e.values?.rating;
    setRatingValue(data);
  };

  const numbers = Array.from({ length: 9 }, (_, index) => 1 + index * 0.5);

  const addRatingForm = (
    <>
      <RatingTypeSelect
        name="type"
        label="Type"
        placeholder="Type"
        params={{ type: "RatingType" }}
        handleTagChange={handleTypeChange}
        required
        // defaultValue={typeId}
        RatingTypeList={setRatingTypeList}
      />
      <Select
        onInputChange={handleRatingChange}
        name="rating"
        label="Rating"
        options={numbers.map((number) => ({
          value: number.toString(),
          label: number.toString(),
        }))}
        required
        menuPortal=""
      />
      <TextArea
        name="comment"
        label="Comments"
        placeholder="Comments"
        onChange={handleCommentTerms}
        required
      />
    </>
  );

  const ratingFooter = (
    <SaveButton type="submit" loading={isSubmitting == false} />
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Drawer
        modelTitle={rowValue?.id ? "Edit Rating " : "Add Rating"}
        DrawerBody={addRatingForm}
        DrawerFooter={ratingFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        handleOpenModal={handleOpenModal}
        handleCloseModal={_toggle}
        handleDrawerClose={_toggle}
        isModalOpen={isOpen}
        buttonLabel={buttonLabel}
        enableReinitialize
      />

      <ReduxTable
        id="rating"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={apiUrl ? apiUrl : `${endpoints().ratingAPI}/search`}
        history={history}
        params={{ account_id: vendorId }}
        sortByOptions={sortByOption}
        disableHeader={paymentTab ? true : false}
        paramsToUrl={true}
      >
        <ReduxColumn
          field="rating_tag_name"
          sortBy="id"
          className="text-center"
          renderField={(row) => (
            <Link
              to={`/ratingDetail/detail/${row.id}?tab=${Url.GetParam("tab")}`}
              className="link-opacity-75 text-decoration-none"
            >
              {row.rating_tag_name}
            </Link>
          )}
        >
          Type
        </ReduxColumn>

        <ReduxColumn field="rating" sortBy="rating" className="text-center">
          Rating
        </ReduxColumn>
        <ReduxColumn
          field="comment"
          sortBy="comment"
          className="text-center"
          width="300px"
          minWidth="300px"
          maxWidth="300px"
        >
          Comment
        </ReduxColumn>

        <ReduxColumn
          field="Action"
          disableOnClick
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRowValue && setRowValue(row);
                      toggles();
                      setIsLoading(true);
                      getDetails();
                      setIsLoading(false);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className=" text-danger cursor-pointer"
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setRowValue(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Actions
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default RatingList;
