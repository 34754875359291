import React, { useState } from "react";

// Components
import Form from "../../../components/Form";
import HorizontalSpace from "../../../components/HorizontalSpace";
import SaveButton from "../../../components/SaveButton";
import CancelButton from "../../../components/CancelButton";
import AccountForm from "./accountForm";

// Lib
import String from "../../../lib/String";

// Helpers
import { vendorStatusOptions } from "../../../helpers/Vendor";

const General = (props) => {
  let { vendorDetails, handleSubmit, history, billingOption } = props;
  const [status, setStatus] = useState("");
  const [type, setType] = useState(vendorDetails?.data?.type || "");
  const [accountCategory, setAccountCategory] = useState(vendorDetails?.data?.accountCategory || "");
  const [paymentAccountName, setpaymentAccountName] = useState([]);
  const [paymentAccountId, setpaymentAccountId] = useState("");
  const [vendorValue, setVendorValue] = useState();
  const [returnTerms, setReturnTerms] = useState();
  const [paymentTerms, setPaymentTerms] = useState();
  const [url, setUrl] = useState();
  const [billingName, setBillingName] = useState("");
  const [typeOption, setTypeOption] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState();

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  // Handle Vendor Status Change
  const handleStatusChange = (selectStatus) => {
    if (selectStatus) {
      setStatus(selectStatus);
    }
  };

  const handleTypeChange = (e) => {
    setType(e?.value? e?.value:"");
    setAccountCategory(e?.category? e?.category:"")
  };

  const handlePaymentAccountChange = (e) => {
    setpaymentAccountId(e);
  };
  const handleBillingNameChange = (e) => {
    let value = e && e.value ? e.value : "";
    setBillingName(value);
  };

  const handleReturnTerms = (e) => {
    setReturnTerms(e?.target?.value);
  };

  const handlePaymentTerms = (e) => {
    setPaymentTerms(e?.target?.value);
  };

  const handleChange = (e) => {
    let data = e?.target?.value;
    setUrl(data);
  };

  const initialValue = {
    id: vendorDetails && vendorDetails?.data?.vendorId,
    vendor_name: vendorValue
      ? String.Get(vendorValue)
      : vendorDetails && String.Get(vendorDetails?.data?.vendorName),
    vendor_url: url
      ? url
      : vendorDetails && String.Get(vendorDetails.data?.vendorUrl),
    status: status
      ? {
          label: status.label,
          value: status.value,
        }
      : vendorDetails?.data?.status
      ? vendorStatusOptions.find(
          (data) => data.value == vendorDetails?.data?.status
        )
      : "",
    gst_number: vendorDetails && vendorDetails?.data?.gstNumber,
    type: type
      ? typeOption.find((data) => data.value == type)
      : typeOption.find((data) => data.value == vendorDetails?.data?.type),
    cash_discount: vendorDetails && vendorDetails?.data?.cash_discount,
    return_terms: returnTerms
      ? returnTerms
      : vendorDetails && vendorDetails?.data?.return_terms
      ? vendorDetails?.data?.return_terms
      : "",
    payment_terms: paymentTerms
      ? paymentTerms
      : vendorDetails && vendorDetails?.data?.payment_terms,
    payment_account: paymentAccountId
      ? {
          label: paymentAccountId?.label,
          value: paymentAccountId?.value,
        }
      : paymentAccountName.find(
          (items) => items?.value == vendorDetails?.data?.payment_account
        ),
    billing_name:
      billingOption && billingOption.length > 0
        && billingName ?
          billingOption.find((data) => data.value == billingName)
        : billingOption &&
          billingOption.find(
            (data) => data.value == vendorDetails?.data?.billing_id
          ),
    mobile: phoneNumber ? phoneNumber : vendorDetails && vendorDetails?.data?.mobile
  };

  const vendorChange = (e) => {
    let data = e?.target?.value;
    setVendorValue(data);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e)
  }

  return (
    <div className="mb-5 mb-sm-0">
      <Form
        enableReinitialize
        initialValues={initialValue}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <AccountForm
          vendorChange={vendorChange}
          handlePaymentAccountChange={handlePaymentAccountChange}
          handleStatusChange={handleStatusChange}
          handleTypeChange={handleTypeChange}
          type={accountCategory}
          vendorDetails={vendorDetails}
          setpaymentAccountName={setpaymentAccountName}
          editorState={props?.editorState}
          handleEditorChange={props?.handleEditorChange}
          editable={props?.editable}
          handleReturnTerms={handleReturnTerms}
          handlePaymentTerms={handlePaymentTerms}
          handleBillingNameChange={handleBillingNameChange}
          handleChange={handleChange}
          accountTypeOption={setTypeOption}
          handlePhoneNumberChange={handlePhoneNumberChange}
        />
        {!props.editable && (
          <HorizontalSpace>
            <SaveButton label="Save" />
            <CancelButton
              onClick={() => {
                history.goBack();
              }}
            />
          </HorizontalSpace>
        )}
      </Form>
    </div>
  );
};

export default General;
