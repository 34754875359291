import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";

//  Components
import "../components/reduxTable/styles.scss";

// API
import "../scss/_custom.scss";

import { endpoints } from "../api/endPoints";
import { Category } from "../helpers/Category";
import { Tab } from "../helpers/ProductTag";
import { STATUS_INACTIVE_TEXT } from "../helpers/Store";
import { isLoggedIn } from "../lib/Helper";
import Url from "../lib/Url";
import BreadCrumb from "./Breadcrumb";
import DeleteModal from "./DeleteModal";
import Drawer from "./Drawer";
import PageTitle from "./PageTitle";
import SaveButton from "./SaveButton";
import Text from "./Text";
import MoreDropdown from "./authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "./reduxTable";
import RatingTypeService from "../services/RatingTypeService";

const RatingTypeDetail = (props) => {
  const {
    history,
    ratingType,
    noTagDetail,
    __toggle,
    isModel,
    pageTitle,
    columnName,
    label,
    nameFieldLabel,
    customTypeOption,
    hideQueryParams,
  } = props;

  const [isOpen, setIsOpen] = useState(isModel || false);
  const [detail, setDetail] = useState(false);
  const [name, setName] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState();
  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();

  const statusOptions = [
    {
      value: Tab.STATUS_ACTIVE_TEXT,
      label: Tab.STATUS_ACTIVE_TEXT,
    },
    {
      value: Tab.STATUS_INACTIVE_TEXT,
      label: Tab.STATUS_INACTIVE_TEXT,
    },
    {
      value: "",
      label: "All",
    },
  ];

  // Use Effect
  useEffect(() => {
    isLoggedIn();
    RatingTypeDetails();
  }, []);

  // get params
  const _toggle = (ratingTypeId) => {
    __toggle && __toggle();
    setId(ratingTypeId || 0);
    setIsOpen(!isOpen);
    setIsSubmit(false);
  };

  const toggle = (tab) => {
    __toggle && __toggle();
    setIsOpen(!isOpen);
    setName("");
    setId("");
    setType("");
    setIsSubmit(false);
  };

  const params = {
    type: ratingType,
    status: Url.GetParam("status"),
    search: Url.GetParam("search"),
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
  };

  const handleDelete = async (id) => {
    const params = {
      type: ratingType,
      status: Url.GetParam("status"),
      search: Url.GetParam("search"),
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
    };
    dispatch(await RatingTypeService.delete(id, params));
  };

  const RatingTypeDetails = async () => {
    try {
      //create new array for store list
      const response = await RatingTypeService.get(ratingType);
      setDetail(() => response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Create Product
   *
   * @param values
   */
  const addRatingForm = async (values) => {
    try {
      setIsSubmit(true);

      const data = new FormData();
      let params = {
        typeId: detail.id,
        pagination: true,
        status: Url.GetParam("status"),
        type: Url.GetParam("type"),
        page: 1,
        pageSize: 25,
        sort: Url.GetParam("sort"),
        sortDir: Url.GetParam("sortDir"),
        search: Url.GetParam("search"),
      };

      if (!id) {
        data.append("name", values.name.trim());
        data.append("type", ratingType);
        dispatch(await RatingTypeService.create(data, params, toggle));
        setIsSubmit(false);
      } else {
        data.append("name", values.name.trim());
        data.append("id", id);
        data.append("status", status);
        dispatch(await RatingTypeService.update(id, data, params, toggle));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  const breadcrumbList = [
    { label: "Home", link: "/setting/Rating" },
    {
      label: "RatingType",
      link: "/setting/Rating",
    },
    {
      label: ratingType,
    },
  ];

  const addRatingTypeForm = (
    <>
      <Text
        name="name"
        label={nameFieldLabel ? nameFieldLabel : "Name"}
        required={true}
      />
    </>
  );

  const modalOpen = isModel !== undefined ? isModel : isOpen;

  const sortByOption = props.mobileApp
    ? [
      {
        value: "id:DESC",
        label: "Most Recent",
      },
      {
        value: "name:ASC",
        label: "Name",
      },
    ]
    : [
      {
        value: "name:ASC",
        label: "Name",
      },
    ];

  const ratingTypeFooter = (
    <SaveButton
      type="submit"
      loading={isSubmit}
      label={name ? "Save" : "Add"}
    />
  );

  return (
    <div>
      <>
        <Drawer
          DrawerBody={addRatingTypeForm}
          DrawerFooter={ratingTypeFooter}
          modelTitle={
            name
              ? `Edit ${label ? label : "Rating Type"}`
              : `Add ${label ? label : "Rating Type"}`
          }
          onSubmit={(values) => {
            addRatingForm(values);
          }}
          initialValues={{
            name: name || "",
          }}
          handleOpenModal={toggle}
          handleCloseModal={toggle}
          handleDrawerClose={toggle}
          isModalOpen={modalOpen}
        />
        <DeleteModal
          isOpen={openDeleteModal}
          toggle={() => {
            setOpenDeleteModal(false);
          }}
          title="Delete Rating Type"
          deleteFunction={() => {
            handleDelete(deleteType.id);
          }}
          label={deleteType.name}
          id={deleteType.id}
        />
        {!noTagDetail && <BreadCrumb list={breadcrumbList} />}
        <div>
          {!props.showPageTitle && (
            <div className="pb-3">
              <PageTitle
                label={
                  props?.match?.params.id
                    ? props?.match?.params.id
                    : pageTitle
                      ? pageTitle
                      : ratingType
                }
                buttonLabel="Add New"
                buttonHandler={(_e) => {
                  toggle();
                }}
              />
            </div>
          )}
        </div>
      </>
      <ReduxTable
        id="ratingType"
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().ratingTypeRoute}/search`}
        newTableHeading
        message="You can start by clicking Add New"
        customStatusOption={statusOptions}
        sortByOptions={sortByOption}
        showStatusFilter
        customTypeOption={customTypeOption}
        hideQueryParams={hideQueryParams}
        params={{
          type: ratingType ? ratingType : Url.GetParam("type"),
        }}
        paramsToUrl={true}
        history={history}
        onRowClick={(row) => {
          setName(row.name);
          setType(row.type);
          setStatus(row.status);
          return _toggle(row.id);
        }}
      >
        <ReduxColumn
          field="name"
          type="link"
          sortBy="name"
          width="160px"
          minWidth="160px"
          maxWidth="160px"
          isClickable="true"
          className="lh-lg"
          renderField={(row) => (
            <>
              {" "}
              {row.name}
              {row && row?.status == Category.STATUS_INACTIVE && (
                <span className="text-danger">({row?.status})</span>
              )}
            </>
          )}
        >
          {columnName ? columnName : "Name"}
        </ReduxColumn>
        <ReduxColumn
          minWidth={"100px"}
          width={"100px"}
          maxWidth={"100px"}
          field="status"
          disableOnClick
          className="action-column"
          renderField={(row) => (
            <div className="text-center landing-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    toggle();
                    setName(row?.name);
                    setType(row?.type);
                    setStatus(row?.status);
                    setId(row?.id);
                  }}
                >
                  Quick View
                </DropdownItem>
                {row.status !== Tab.STATUS_ACTIVE_TEXT ? (
                  <DropdownItem
                    onClick={async () => {
                      dispatch(
                        await RatingTypeService.updateStatus(
                          row.id,
                          Tab.STATUS_ACTIVE_TEXT,
                          ratingType,
                          params
                        )
                      );
                    }}
                  >
                    Make as Active
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    onClick={async () => {
                      dispatch(
                        await RatingTypeService.updateStatus(
                          row.id,
                          STATUS_INACTIVE_TEXT,
                          ratingType,
                          params
                        )
                      );
                    }}
                  >
                    Make as InActive
                  </DropdownItem>
                )}
                <DropdownItem
                  className={"text-danger"}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setDeleteType(row);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};
export default RatingTypeDetail;
