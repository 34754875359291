import React, { useState, useEffect } from "react";
import Select from "./Select";

const HourComponent = ({
  label,
  required,
  onChange,
  name,
  isDisabled,
  defaultValue,
  range,
  hourOption,
}) => {
  const [hourList, setHourList] = useState([]);

  useEffect(() => {
    getList();
  }, [range]);

  const getList = () => {
    const hoursList = Array.from({ length: 168 }, (_, i) => ({
      value: i + 1,
      label: `${i + 1} Hour${i + 1 > 1 ? 's' : ''}`,
    }));
    
    setHourList(hoursList);
    if (hourOption) {
      hourOption(hoursList);
    }
  };

  return (
    <>
      <Select
        fullWidth={true}
        width="100"
        label={label ? label : "Hour"}
        name={name ? name : "hour"}
        placeholder={label ? label : "select hour"}
        isClearable
        isSearchable
        required={required}
        options={hourList}
        isSingleSelect={true}
        onInputChange={onChange}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        showVirtualizedMenu={true}
      />
    </>
  );
};

export default HourComponent;
