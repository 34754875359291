import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.scss";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import "../scss/_custom.scss";
import PageTitle from "../components/PageTitle";
import ArrayList from "../lib/ArrayList";

const Carousel = (props) => {
  const { detail } = props;


  const sliderSettings = {
    infinite: true,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };



  const renderSlider = (data) => {
    const cardRefs = useRef([]);
    const sliderRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState(0);
    useEffect(() => {
      const adjustCardHeights = () => {
        let newMaxHeight = 0;

        cardRefs.current.forEach((card) => {
          if (card) {
            const cardTextContainer = card.querySelector(".card-body");
            if (cardTextContainer) {
              const cardHeight = cardTextContainer.offsetHeight;
              if (cardHeight > newMaxHeight) {
                newMaxHeight = cardHeight;
              }
            }
          }
        });
        setMaxHeight(newMaxHeight);
      };

      adjustCardHeights();
      window.addEventListener("resize", adjustCardHeights);

      return () => {
        window.removeEventListener("resize", adjustCardHeights);
      };
    }, [detail]);



    return (

      <Slider ref={sliderRef} {...sliderSettings} className="slider">
        {ArrayList.isArray(data?.PageBlockFieldsListArray) &&
          data?.PageBlockFieldsListArray.map((slide, index) => (
            <div key={index} className="slide">
              <div
                className="card banner-card me-2"
                ref={(el) => (cardRefs.current[index] = el)}
              >
                <img
                  src={slide?.media_url}
                  alt={`slide${index + 1}`}
                  className="banner-carousel-image"
                />
                <div className=" card-body banner-card-body">
                  <h5 className="card-title">{slide?.title}</h5>
                  <div
                    className="card-text-container"
                    style={{ minHeight: `${maxHeight - 70}px` }}
                  >
                    <p className="banner-card-text">{slide?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    )
  }

  return (
    <>
      <div className="mt-3">
        {ArrayList.isArray(detail) &&
          detail.map((data) => (
            <div className="container" key={data.id}>
              <div className="banner-carousel-container">
                <PageTitle label={data?.title} />
                {renderSlider(data)}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Carousel;
