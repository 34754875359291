import { endpoints } from '../api/endPoints';
import { apiClient } from '../apiClient';
import Toast from '../components/Toast';
import { isBadRequest } from '../lib/Http';

class BankSettlementsService {
  static create = async (data, callback) => {
    await apiClient
      .post(`${endpoints().BankSettlementAPI}/create`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
        }
        return callback(response && response.data);
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  static update = async (id, data, callback) => {
        await apiClient
            .put(`${endpoints().BankSettlementAPI}/update/${id}`, data)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                    return callback && callback(successMessage)
                }
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(error.response.data.message);
                    console.error(errorMessage);
                }
            });
}

static delete = async (id,callback) => {
    try {
        await apiClient
          .delete(`${endpoints().BankSettlementAPI}/delete/${id}`)
          .then((response) => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
              return callback && callback(successMessage)
            }
          })
          .catch((error) => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
            }
          });
    } catch (error) {
      console.log(error)
    }

  }


  static async get(id) {
    const { data } = await apiClient.get(`${endpoints().BankSettlementAPI}/${id}`);
    return data;
  }
}

export default BankSettlementsService;
