import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Components
import SaveButton from "../../components/SaveButton";
import Action from "../../components/Action";
import ActivityList from "../../components/ActivityList";
import BreadCrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import CancelButton from "../../components/CancelButton";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import HorizontalSpace from "../../components/HorizontalSpace";
import PageTitle from "../../components/PageTitle";
import Spinner from "../../components/Spinner";
import SchedulerJobForm from "./schedulerJobForm";
import Toast from "../../components/Toast";

// Constants
import { SchedulerApiUrl, schedulerJob } from "./constants";

// Lib
import DateTime from "../../lib/DateTime";

// Config
import { apiClient } from "../../apiClient";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";
import { Status } from "../../helpers/SchedulerJob";
import {
  dateOption,
  dateTypeOption,
  monthOption,
  typeOptions,
} from "../../helpers/recurringTask";

// Services
import SchedulerJobService from "../../services/SchedulerJobService";
import SlackService from "../../services/SlackService";
import { hasPermission } from "../../services/UserRolePermissionService";
import ArrayList from "../../lib/ArrayList";
import StatusService from "../../services/StatusService";
import Number from "../../lib/Number";

const Tabs = {
  GENERAL: "General",
  HISTORY: "History",
};

const DetailSchedulerModal = (props) => {
  let id = props?.match?.params?.id;
  const [slackChannelList, setChannelList] = useState([]);
  const [schedulerJobDetail, setSchedulerJobDetail] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [schedulerName, setSchedulerName] = useState("");
  const [type, setType] = useState(null);
  const [scheduler, setScheduler] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [month, setMonth] = useState(null);
  const [date, setDate] = useState(null);
  const [dateOptions, setDataOption] = useState([]);
  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);
  const [intervalValue, setIntervalValue] = useState(null);
  const [email, setEmail] = useState(null);
  const [notes, setNotes] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [objectName, setObjectName] = useState(null);
  const [dateType, setDateType] = useState(null);
  const [statusOption, setStatusOption] = useState([]);
  const [objectStatus, setObjectStatus] = useState(null)

  useEffect(() => {
    getSlackChannelList();
    schedulerDetail();
    getDateOptions();
    if(objectName || schedulerJobDetail?.object_name){
      getStatusList(objectName ? objectName : schedulerJobDetail?.object_name)
    }
  }, [id]);

  useEffect(() => {
    if (schedulerJobDetail && schedulerJobDetail?.day) {
      setSelectedDays(schedulerJobDetail && schedulerJobDetail?.day);
    }
    if(objectName || schedulerJobDetail?.object_name){
      getStatusList(objectName ? objectName : schedulerJobDetail?.object_name)
    }
  }, [schedulerJobDetail]);

  const dispatch = useDispatch();

  const getDateOptions = async () => {
    let dateOptions = dateOption();
    setDataOption(dateOptions);
  };

  const statusOptions = [
    {
      label: schedulerJob.STATUS_ACTIVE_TEXT,
      value: schedulerJob.STATUS_ACTIVE,
    },
    {
      label: schedulerJob.STATUS_INACTIVE_TEXT,
      value: schedulerJob.STATUS_INACTIVE,
    },
  ];

  const param = new URLSearchParams(props.history.location.search);
  const selectedTab = param.get("tab");
  const [activeTab, setActiveTab] = useState(
    selectedTab ? selectedTab : Tabs.GENERAL
  );
  let showHistory = hasPermission(Permission.SCHEDULER_JOBS_HISTORY_VIEW);
  const [isOpen, setIsOpen] = useState(false);
  const [statusValue, setStatusValue] = useState();

  const getStatusList = async (value)=>{
    let patams={
      objectName: Number.isNotNull(value) ? value : objectName ? objectName : schedulerJobDetail?.object_name
    }
    let response = await StatusService.list(patams);
    setStatusOption(response?.data?.data)
  }

  const getSlackChannelList = async () => {
    try {
      //create new array fro slack channel
      let response = await SlackService.getChannelList();

      if (response && response.channels) {
        let channel = [];

        let channelList = response.channels;

        if (channelList && channelList.length > 0) {
          for (let i in channelList) {
            let { id, name } = channelList[i];
            channel.push({
              label: name,
              value: id,
              id: id,
            });
          }
        }
        //set channel list
        setChannelList(channel);
      }
    } catch (err) {}
  };

  const handleSchedulerChange = (e) => {
    let value = e.values.job;
    setSchedulerName(value);
  };

  const _handleTabChange = (tab) => {
    props.history.push(`?tab=${tab}`);
  };

  const schedulerDetail = async () => {
    setIsloading(true);
    let data = await SchedulerJobService.get(id);
    setSchedulerJobDetail(data);
    setStartTimeValue(data.startTime);
    setEndTimeValue(data.endTime);
    setStartDate(data.start_date);
    setEndDate(data.end_date);
    setIntervalValue(data.interval);
    setIsloading(false);
  };

  // Handle Project Category form submit
  const handleSubmit = async (values) => {
    try {
      let data = {};
      let attendance_missing_report_email = [];

      let to_email = [];

      if (values && values.job) {
        data.job_name = values?.job?.label;
        data.api_url = values?.job?.value;
      }

      if (values && values.name) {
        data.name = values.name;
      }

      if (values && values.status) {
        data.label = values?.label;
        data.status = values.status.value;
      }
      data.startTime = startTimeValue;

      data.endTime = endTimeValue;

      data.interval = intervalValue && intervalValue;

      if (values.to_email !== undefined) {
        values.to_email.forEach((element) => {
          to_email.push(element.value);
        });

        data.to_email = to_email ? to_email : "";
      }
      if (values.attendance_missing_report_email !== undefined) {
        values.attendance_missing_report_email.forEach((element) => {
          attendance_missing_report_email.push(element.value);
        });
      }
      if (values.to_slack && values.to_slack.value !== undefined) {
        data.to_slack = values.to_slack.value ? values.to_slack.value : "";
      }

      data.attendance_missing_report_email = attendance_missing_report_email
        ? attendance_missing_report_email
        : "";

      if (values.taskType  !== undefined) {
        data.taskType = values?.taskType ? values.taskType.value : "";
      }
      values.day =
        selectedDays && selectedDays.length > 0
          ? JSON.stringify(selectedDays)
          : [];
      if (values.day) {
        data.day = values.day;
      }

      if (values.month || month) {
        data.month = month ? month : values?.month?.value;
      }
      if (date || values.date) {
        data.date = date ? date : values?.date?.value;
      }
      data.notes = notes ? notes : values?.notes;

      data.start_date = values.start_date;

      data.end_date = values.end_date;

      if(objectStatus || objectStatus == ""){
        data.object_status = objectStatus ? objectStatus : null
      }

      if(objectName || objectName == ""){
        data.object_name = objectName ? objectName : ""
      }

      if(dateType || dateType == ""){
        data.date_type = dateType ? dateType : null
      }

      if(values?.job?.value == "/v1/scheduler/order/orderReport"){
        data.isOrderReportSchedulerJob = true
      }


      await SchedulerJobService.update(
        schedulerJobDetail.id,
        data,
        schedulerDetail,
        (res)=>{
          if(objectName || schedulerJobDetail?.object_name){
            getStatusList(objectName ? objectName : schedulerJobDetail?.object_name)
          }
        }
      );
      setType("")
      setStatusOption(null)

    } catch (err) {
      console.log(err);
    }
  };

  const deleteSchedulerJob = async (id) => {
    try {
      dispatch(await SchedulerJobService.deleteJob(id));
      props.history.push("/schedulerJobs");
    } catch (err) {}
  };
  // // Form initial values
  const initialValues = {
    job: schedulerName
      ? {
        label: schedulerName?.label,
        value: schedulerName?.value,
      }
      : SchedulerApiUrl.find(
        (data) => data.value == schedulerJobDetail?.api_url
      ),
    status: statusValue
      ? {
        label: statusValue?.label,
        value: statusValue?.value,
      }
      : statusOptions.find((data) => data?.value == schedulerJobDetail?.status),

    notes: schedulerJobDetail.notes,
    name: scheduler
      ? scheduler
      : schedulerName.label
        ? schedulerName.label
        : schedulerJobDetail.name,
    interval: DateTime.getSchedulerTime().find(
      (data) => data.value == intervalValue
    ),
    startTime: startTimeValue || "",
    endTime: endTimeValue || "",
    to_email: email ? email : schedulerJobDetail.to_email,
    to_slack: slackChannelList.find(
      (data) => data.value == schedulerJobDetail?.to_slack
    ),

    taskType: type
      ? typeOptions.find((data) => data.label == type)
      : typeOptions.find((data) => data.label == schedulerJobDetail?.type),
    month: month
      ? monthOption.find((data) => data.value == month)
      : monthOption.find(
        (data) => data.value == schedulerJobDetail?.monthValue?.value
      ),
    date: date
      ? dateOptions.find((data) => data?.value == date)
      : schedulerJobDetail?.date
        ? dateOptions.find((data) => data?.value == schedulerJobDetail?.date)
        : "",
    end_date: endDate || "",
    start_date: startDate || "",
    object_name: objectName ? ObjectName.Options.find((data) => data?.value == objectName) : schedulerJobDetail?.object_name ? ObjectName.Options.find((data) => data?.value == schedulerJobDetail?.object_name) : "",
    date_type: dateType ? dateTypeOption.find((data) => data?.value == dateType) : schedulerJobDetail?.date_type ? dateTypeOption.find((data) => data?.value == schedulerJobDetail?.date_type) : "",
    object_status: objectStatus && ArrayList.isArray(statusOption) ? statusOption.find((data) => data?.id == objectStatus) : schedulerJobDetail?.object_status && ArrayList.isArray(statusOption) ? statusOption.find((data) => data?.id == schedulerJobDetail?.object_status) : ""
  };

  const toggle = (tab) => {
    setIsOpen(!isOpen);
    setActiveTab(tab);
  };

  const breadcrumbList = [
    { label: "Home", link: "/dashboard" },
    {
      label: "Scheduler Jobs",
      link: `/schedulerJobs?status=${Status.ACTIVE}`,
    },
    {
      label: activeTab,
      link: "",
    },
  ];

  const handleJobRun = async () => {
    try {
      let response = await apiClient.post(
        `${schedulerJobDetail?.api_url}/manualRun?id=${schedulerJobDetail?.id}`
      );
      if (response && response.data) {
        schedulerDetail()
        Toast.success(response.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  const handleTypeChange = async (e) => {
    setType(e.label);
  };

  const handleNameChange = (e) => {
    setScheduler(e.target.value);
  };

  const handleCheckboxChange = (day, checked) => {
    if (checked) {
      setSelectedDays([...selectedDays, day]);
    } else {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    }
  };

  const handleMonthChange = async (e) => {
    let value = e && e?.values && e?.values?.month && e?.values?.month?.value;
    setMonth(value);
  };

  const handleDate = async (e) => {
    let value = e && e?.values && e?.values?.date && e?.values?.date?.value;
    setDate(value);
  };

  const handleStartTimeChange = (e) => {
    setStartTimeValue(e);
  };

  const handleEndTimeChange = (e) => {
    setEndTimeValue(e);
  };

  const handleIntervalOnchange = (e) => {
    let value = e && e?.values && e?.values?.interval && e.values.interval;
    setIntervalValue(value && value.value);
  };

  const handleStatusChange = (selectStatus) => {
    if (selectStatus) {
      setStatusValue(selectStatus);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.values ? e.values.to_email : "");
  };

  const handleNotesChange = (e) => {
    const value = e.target.value ? e.target.value : "";
    setNotes(value);
  };

  if (isloading) {
    return <Spinner />;
  }
  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const objectNameChange=(value)=>{
    setObjectName(value ? value :"")
    if(value){
      getStatusList(value ? value :"")
    }
  }

  const handleDateTypeChange =({values})=>{
    setDateType(values?.date_type?.value ? values?.date_type?.value :"")
  }

  const handleObjectStatusChange =(value)=>{
    setObjectStatus(value?.value ? value?.value :"")
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Scheduler Jobs"
        id={schedulerJobDetail?.id}
        label={schedulerJobDetail?.name}
        deleteFunction={(id) => deleteSchedulerJob(id)}
      />
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between">
        <div>
          <PageTitle
            label={schedulerJobDetail?.job_name}
            labelClassName="ellipse"
          />
        </div>
        <div className="d-flex mt-md-0">
          <Button
            className="mx-2"
            label="Run"
            onClick={handleJobRun}
            showButtonLabelName
          />

          <div className="ps-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>
      <Nav tabs className="admin-tabs">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tabs.GENERAL,
            })}
            onClick={() => {
              toggle(Tabs.GENERAL);
              _handleTabChange(Tabs.GENERAL);
            }}
          >
            {Tabs.GENERAL}
          </NavLink>
        </NavItem>
        <NavItem>
          {showHistory && (
            <NavLink
              className={classnames({
                active: activeTab === Tabs.HISTORY,
              })}
              onClick={() => {
                toggle(Tabs.HISTORY);
                _handleTabChange(Tabs.HISTORY);
              }}
            >
              {Tabs.HISTORY}
            </NavLink>
          )}
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tabs.GENERAL}>
          <Form
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            <div className="card bg-white mt-3 col-lg-12">
              <div className="card-body">
                <SchedulerJobForm
                  handleSchedulerChange={handleSchedulerChange}
                  handleTypeChange={handleTypeChange}
                  handleNameChange={handleNameChange}
                  type={type}
                  row={schedulerJobDetail}
                  selectedDays={selectedDays}
                  handleCheckboxChange={handleCheckboxChange}
                  handleMonthChange={handleMonthChange}
                  handleDate={handleDate}
                  handleStartTimeChange={handleStartTimeChange}
                  handleEndTimeChange={handleEndTimeChange}
                  handleIntervalOnchange={handleIntervalOnchange}
                  handleStatusChange={handleStatusChange}
                  handleEmailChange={handleEmailChange}
                  handleNotesChange={handleNotesChange}
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                  objectNameChange={objectNameChange}
                  handleDateTypeChange={handleDateTypeChange}
                  handleObjectStatusChange={handleObjectStatusChange}
                  objectName={objectName ? objectName : schedulerJobDetail?.object_name}
                  schedulerName={schedulerName}
                />
                <HorizontalSpace>
                  <SaveButton label="Save" />
                  <CancelButton
                    onClick={() => {
                      props.history.push(`/schedulerJobs?status=${Status.ACTIVE}`);
                    }}
                  />
                </HorizontalSpace>
              </div>
            </div>
          </Form>
        </TabPane>
        {showHistory && activeTab == Tabs.HISTORY && (
          <TabPane tabId={Tabs.HISTORY}>
            <ActivityList
              id={id}
              objectId={id}
              object_name={ObjectName.SCHEDULER_JOB}
              activeTab={activeTab}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default DetailSchedulerModal;
