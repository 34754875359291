import { useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";

// Helpers
import { Projects } from "../../../helpers/Project";
import ProjectUser from "../../../helpers/ProjectUser";

// Components
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import UserCard from "../../../components/UserCard";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import DeleteModal from "../../../components/DeleteModal";

// API
import { endpoints } from "../../../api/endPoints";

// Lib
import Url from "../../../lib/Url";

// Services
import ProjectUserService from "../../../services/ProjectUserService";

// Actions
import { fetchList } from "../../../actions/table";

const ProjectUsers = (props) => {
  const [currentData, setCurrentData] = useState("");
  const [isDeleteModel, setIsDeleteModel] = useState("");

  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "user_id:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const projectUserDelete = async (id) => {
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      search: Url.GetParam("search"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      projectId: Url.GetParam("projectId"),
    };
    dispatch(
      await ProjectUserService.deleteProjectUser(currentData?.id, params)
    );
  };

  const handleStatusChange = async (id, status) => {
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      search: Url.GetParam("search") || "",
      projectId: Url.GetParam("projectId"),
    };
    dispatch(
      await ProjectUserService.updateStatus(id, status, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "projectUser",
              `${endpoints().ProjectUserApi}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              params
            )
          );
        }
      })
    );
  };

  return (
    <div>
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title=" Project User"
        label={currentData?.userName}
        deleteFunction={projectUserDelete}
      />
      <div className="mt-4">
        <ReduxTable
          id="projectUser"
          showHeader
          searchPlaceholder="Search"
          apiURL={`${endpoints().ProjectUserApi}/search`}
          newTableHeading
          params={{
            projectId: Url.GetParam("projectId"),
            sort: Url.GetParam("sort"),
            sortDir: Url.GetParam("sortDir"),
          }}
          history={props.history}
          paramsToUrl={true}
          sortByOptions={sortByOption}
        >
          <ReduxColumn
            field="userName"
            sortBy="user_id"
            renderField={(row) => (
              <>
                <UserCard
                  customSize={parseInt(50, 10)}
                  firstName={row?.userFirstName}
                  lastName={row?.userLastName}
                  url={row?.userMediaUrl}
                />
              </>
            )}
          >
            User
          </ReduxColumn>
          <ReduxColumn
            field="status"
            sortBy="status"
            width="120px"
            minWidth="120px"
            maxWidth="120px"
            renderField={(row) => (
              <div
                className={`status-input text-center rounded text-white text-uppercase  my-3 mx-auto ${
                  row.status && row.status == Projects.STATUS_ACTIVE
                    ? "bg-success"
                    : row.status == Projects.STATUS_INACTIVE
                    ? "bg-dark bg-opacity-50"
                    : ""
                }`}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            renderField={(row) => (
              <div className="action-group-dropdown text-center">
                <MoreDropdown>
                  {row.status !== ProjectUser.STATUS_ACTIVE_TEXT ? (
                    <DropdownItem
                      onClick={() => {
                        handleStatusChange(row.id, true);
                      }}
                    >
                      Make as Active
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      onClick={() => {
                        handleStatusChange(row.id, false);
                      }}
                    >
                      Make as InActive
                    </DropdownItem>
                  )}
                  <DropdownItem
                    className=" text-danger  cursor-pointer"
                    onClick={() => {
                      setCurrentData(row);
                      setIsDeleteModel(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </div>
  );
};

export default ProjectUsers;
