import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import CallRegisterList from "./components/callRegisterList";

const callRegisterListPage = (props) => {
  let { history } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      <PageTitle
        label={"Call Register"}
        buttonHandler={() => {
          openToggle();
        }}
        buttonLabel="Add"
      />

      <CallRegisterList
        history={history}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default callRegisterListPage;
