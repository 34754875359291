import React from "react";

// Components
import DateSelector from "../../../components/Date";
import Select from "../../../components/Select";
import TextArea from "../../../components/TextArea";
import Currency from "../../../components/Currency";
// Helper
import { SaleSettlement } from "../../../helpers/SaleSettlement";
import ObjectName from "../../../helpers/ObjectName";
import SelectStore from "../../../components/SelectStore";
import MediaCarousel from "../../../components/MediaCarousel";
import UserSelect from "../../../components/UserSelect";
const SaleSettlementForm = (props) => {
  const {
    showDiscrepancy,
    showCalculatedAmount,
    showReceivedAmount,
    showMediaTab,
    showCashInStore,
    onCashChange,
    onUpiChange,
    onStoreChange,
    onShiftChange,
    onSalesExecutiveChange,
    onReceivedCashChange,
    addSaleSettlementForm,
    onReceivedUpiChange,
    onDateChange,
    onStoreCashChange,
    onNotesChange,
    notes,
    onCashToOfficeChange,
    statusSelected,
    editable,
    id,
    showUserDetailsPageLink,
    userId,
    handleReviewerChange,
    handleDueDateChange,
    userList,
    reviewer,
    setLocationList,
    locationId
  } = props;
  return (
    <>
      {/* Form */}
      <div className="row mb-4">
        <div
          className={
            addSaleSettlementForm
              ? "col-lg-12 col-sm-12 col-md-12"
              : "col-lg-8 col-sm-8 col-md-8"
          }
        >
          <div className="row">
            <div className="col-sm-6">
              <DateSelector
                label="Date"
                name="date"
                format="dd-MMM-yyyy"
                required
                onChange={onDateChange}
                disabled={editable}
              />
            </div>

            <div className="col-sm-6">
              <SelectStore
                name="location"
                label="Location"
                handleStoreChange={onStoreChange}
                required
                isDisabled={editable}
                StoreList={setLocationList}
                defaultValue={locationId}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <UserSelect
                name="salesExecutive"
                label="Sales Executive"
                required
                isDisabled={editable}
                customUserOption={props.salesExecutive}
                handleUserChange={onSalesExecutiveChange}
                showUserDetailsPageLink={showUserDetailsPageLink}
                userId={userId}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <Select
                name="shift"
                label="Shift"
                options={props.shiftList}
                required
                onInputChange={onShiftChange}
                isDisabled={editable}
              />
            </div>
          </div>

          {/* CashAmount, UPIAmount, TotalAmount, OrderCashAmount, OrderUPIAmount, OrderTotalAmount Field */}
          <div className={` ${id ? ' p-3 mt-2 card shadow ' : ''}`}>
            <div className="row ">
              <div className="col-sm-4">
                <Currency
                  label="Amount(Cash)"
                  name="amount_cash"
                  onInputChange={onCashChange}
                  required
                  disabled={editable}
                />
              </div>
              <div className="col-sm-4">
                <Currency
                  label="Amount(UPI)"
                  onInputChange={onUpiChange}
                  name="amount_upi"
                  required
                  disabled={editable}
                />
              </div>
              <div className="col-sm-4">
                <Currency label="Total Amount" name="total_amount" disabled />
              </div>
            </div>
            {id && (
              <div className="row ">
                <div className="col-sm-4">
                  <Currency
                    label="Order Amount(Cash)"
                    name="order_cash_amount"
                    disabled
                  />
                </div>
                <div className="col-sm-4">
                  <Currency
                    label="Order Amount(UPI)"
                    name="order_upi_amount"
                    disabled
                  />
                </div>
                <div className="col-sm-4">
                  <Currency
                    label="Order Total Amount"
                    name="order_total_amount"
                    disabled
                  />
                </div>
              </div>
            )}
            {showDiscrepancy && (
              <div className="row">
                <div className="col-12 col-sm-4">
                  <Currency
                    label="Order Discrepancy Amount(Cash)"
                    name="discrepancy_amount_cash"
                    disabled
                    allowNegative
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <Currency
                    label=" Order Discrepancy Amount(UPI)"
                    name="discrepancy_amount_upi"
                    disabled
                    allowNegative
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <Currency
                    label="Order Total Discrepancy Amount"
                    name="total_discrepancy_amount"
                    disabled
                    allowNegative
                  />
                </div>
              </div>
            )}
          </div>

          {/* ReceivedCashAmount, ReceivedUpiAmount, ReceivedTotalAmount Field */}
          <div className={` ${id && showReceivedAmount ? ' p-3 mt-2 card shadow ' : ''}`}>
            {showReceivedAmount && (
              <div className="row">
                <div className="col-12 col-sm-4">
                  <Currency
                    label="Received Amount(Cash)"
                    name="received_amount_cash"
                    required={
                      statusSelected == SaleSettlement.STATUS_DRAFT
                        ? false
                        : statusSelected == SaleSettlement.STATUS_REVIEW
                          ? true
                          : statusSelected == SaleSettlement.STATUS_COMPLETED
                            ? true
                            : false
                    }
                    onInputChange={onReceivedCashChange}
                    disabled={editable}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <Currency
                    label="Received Amount(UPI)"
                    name="received_amount_upi"
                    required={
                      statusSelected == SaleSettlement.STATUS_DRAFT
                        ? false
                        : statusSelected == SaleSettlement.STATUS_REVIEW
                          ? true
                          : statusSelected == SaleSettlement.STATUS_COMPLETED
                            ? true
                            : false
                    }
                    onInputChange={onReceivedUpiChange}
                    disabled={editable}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <Currency
                    label="Total Received Amount"
                    name="total_received_amount"
                    disabled
                  />
                </div>
              </div>
            )}

            {showCalculatedAmount && (
              <div className="row">
                <div className="col-12 col-sm-4">
                  <Currency
                    label="Received Discrepancy Amount(Cash)"
                    name="calculated_amount_cash"

                    allowNegative
                    disabled
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <Currency
                    label="Received Discrepancy Amount(UPI)"
                    name="calculated_amount_upi"

                    allowNegative
                    disabled
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <Currency
                    label="Total Received Discrepancy Amount"
                    name="total_calculated_amount"
                    disabled
                    allowNegative
                  />
                </div>
              </div>
            )}
          </div>

          {/* Cash In Location, Cash In Office Field */}
          <div className={` ${id ? ' p-3 mt-2 card shadow ' : ''}`}>
            <div className="row ">
              {showCashInStore && (
                <div className="col-sm-6">
                  <Currency
                    label="Cash In Location"
                    onInputChange={onStoreCashChange}
                    name="cash_in_store"
                    required
                    disabled={editable}
                  />
                </div>
              )}
              <div className="col-sm-6">
                <Currency
                  label="Cash To Office"
                  onInputChange={onCashToOfficeChange}
                  name="cash_to_office"
                  required
                  disabled={editable}
                />
              </div>
            </div>
            {id &&
              <div className="col-sm-6 w-100 ps-0" >
                <Currency
                  label="Draft Order Amount"
                  name="draft_order_amount"
                  disabled={true}
                />
              </div>
            }
          </div>

          {/* Due date and Reviewer Field */}
          <div className={` ${id ? ' p-3 mt-2 card shadow ' : ''}`}>
            {id &&
              <div className=" row">
                <div className="col-lg-6 col-sm-6 col-md-6">
                  <DateSelector
                    label="Due Date"
                    name="due_date"
                    onChange={handleDueDateChange}
                    disabled={editable}
                  />
                </div>
                <div className="col-lg-6 col-sm-6 col-md-6">

                  <UserSelect
                    label="Reviewer"
                    name="reviewer"
                    isDisabled={editable}
                    showUserDetailsPageLink={true}
                    handleUserChange={handleReviewerChange}
                    userList={(x) => userList(x)}
                    userId={reviewer}
                  />
                </div>
              </div>
            }
          </div>

          {/* Notes Field */}
          <div className={` ${id ? ' p-3 mt-2 card shadow ' : ''}`}>
            <TextArea
              className="w-100  pull-left"
              name="notes"
              value={notes}
              onChange={onNotesChange}
              label="Notes"
              disabled={editable}
            />
          </div>
        </div>
        <div className="col-lg-4 col-sm-4 col-md-4">
          {showMediaTab && (
            <MediaCarousel
              showCarasoul
              objectName={ObjectName.SALE_SETTLEMENT}
              objectId={id}
              Attachments={"Attachments"}
              editable={editable}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SaleSettlementForm;
