import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

// Components
import AddModal from "../../components/Modal";
import PageTitle from "../../components/PageTitle";
import MessageBox from "./components/MessageBox";
import SideBar from "./components/SideBar";
import Form from "../../components/Form";
import Button from "../../components/Button";
import DirectMessage from "./components/DirectMessage";
import MessageUserList from "./components/MessageUserList";
import Toast from "../../components/Toast";
import MessageUserSelect from "../../components/MessageUserList";
import GroupForm from "./components/GroupForm";
import ToggleButton from "../../components/ToggleSwitchButton";
import Action from "../../components/Action";

// Services
import MessagesService from "../../services/MessagesService";
import CompanyUserService from "../../services/UserService";
import { connectSocket, getSocketInstance } from "../../services/SocketService";
import MessageChannelService from "../../services/MessageChannelService";

// Lib
import ArrayList from "../../lib/ArrayList";
import { COOKIE_MESSAGE_AUTO_REFRESH, COOKIE_READ_RECEIPTS } from "../../lib/Cookie";
import Cookies, { setCookie } from "../../lib/Helper";

function Message(props) {
  let params = useParams()
  const [isOpen, setIsOpen] = useState(false);
  const [logUser, setLogUser] = useState("");
  const [refresh, setRefresh] = useState("");
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [bulkSelect, setBulkSelect] = useState(false);
  const location = useLocation();
  const [messageList, setMessageList] = useState([]);
  const [directMessageList, setDirectMessageList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [userList, setUserList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [bulkIds, setBulkIds] = useState([]);
  const [fileName, setFileName] = useState("");
  const [activeUser, setActiveUser] = useState("");
  const [activeChannel, setActiveChannel] = useState("");
  const [groupOpen, setGroupOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prev) => !prev);
  const [autoRefresh, setAutoRefresh] = useState(Cookies.get(COOKIE_MESSAGE_AUTO_REFRESH) == "true" ? true : false);
  const { isDrawerClick, isDrawerGroupClick, customParam, customGroupParam } =
    location.state || {};
  useEffect(() => {
    getMessageList();
    getLogedInUser();
  }, []);

  useEffect(() => {
    getMessageList();
  }, [customParam]);

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        handleRefresh();
      }, 60000); // 60 seconds
      console.log("---->>>>>>> ~ interval:", interval)
  
      return () => clearInterval(interval); // Cleanup on unmount or when autoRefresh changes
    }
  }, [autoRefresh]); 
  let userData = directMessageList.find(value=>value.id == params?.id)
  useEffect(() => {
    if(userData){
      setActiveUser(userData)
    }
    
  }, [directMessageList,userData]);
 
  
  useEffect(() => {
    handleGroupChange(customGroupParam);
  }, [isDrawerClick, isDrawerGroupClick, customGroupParam]);
  useEffect(() => {
    if (logUser && logUser?.id) {
      let socket = getSocketInstance();
      if (!socket || !socket.connected) {
        socket = connectSocket(logUser?.id);
      }
      if (!socket) return;

      const handleReceiveMessage = (data) => {
        if (data && !data?.channel) {
          getMessageList();
        }
        if (data && data?.channel) {
          getChannelList();
        }
      };
      socket.on("receiveMessage", handleReceiveMessage);
      return () => {
        socket.off("receiveMessage", handleReceiveMessage);
      };
    }
  }, [logUser]);

  const dispatch = useDispatch();
  const getMessageList = async (id) => {
    let response = await MessagesService.search();
    let responseData = response.data.data;
    if (responseData) {
      setMessageList(responseData);
      if(isOpen){
      props?.history.push(`/message/user/${responseData[0]?.id}`);
      setIsOpen(!isOpen);
      setActiveUser(responseData[0])
      }
    }
  };
  const getChannelList = async ( isDeleted ) => {
    let response = await MessageChannelService.search();
    let data = response && response?.data && response?.data?.data;
    setChannelList(data);
    if (isDeleted && data?.length > 0) {
      props.history.push(`/message/group/${data[0]?.channel_id}`);
    } else if (isDeleted && data?.length == 0) {
      if (messageList?.length > 0) {
        props.history.push(`/message/user/${messageList[0]?.id}`);
      }
    }
  };

  const handleClick = async (user) => {
    setIsOpen(false);
    if (user && user?.id) {
      setActiveUser(user)
      const data = new FormData();
      data.append("user_id", user?.id);
      let value = Cookies.get(COOKIE_READ_RECEIPTS)
      if((!value || value == 1))
        {
      dispatch(await MessagesService.update(data));
        }
    }
    props.history.push(`/message/user/${user?.id}`)
  };

  const getLogedInUser = async () => {
    const user = await CompanyUserService.search();
    setLogUser(user);
  };
  const toggle = (e) => {
    if(!isOpen){
    setIsOpen(!isOpen);
    }
    getMessageList();
    setSelectedIds([]);
  };
  const groupToggle = (e) => {
    setGroupOpen(!groupOpen);
  };
  const handleRefresh = async (e) => {
    getChannelList()
    getMessageList();
  };

  const actionsMenuList = [
    {
      value: "Create Group",
      label: "Create Group",
    },
  ];

  const handleChange = (values) => {
    let valueData =
    values && values.length > 0 ? values.map((value) => value.id) : "";
    setSelectedIds(valueData);
    setBulkIds(valueData)
  };

  let userValueData = [];
  if (userList && userList.length > 0) {
    if (selectedIds && selectedIds.length > 0) {
      for (let i = 0; i < selectedIds.length; i++) {
        let value = userList.find((value) => value.id == selectedIds[i]);
        userValueData.push(value);
      }
    }
  }

  const UserList = (
    <div>
      <Form initialValues={{ user: userValueData || "" }} enableReinitialize>
        <div className="card card-body shadow">
          <div className="d-flex justify-content-between pt-1">
            <div className="col-8 col-sm-10 mx-1">
              <MessageUserSelect
                name="user"
                handleUserChange={handleChange}
                userList={setUserList}
                isMulti
                showLocationName={true}
                showShiftName={true}
                params={{ pagination: "false" }}
              />{" "}
            </div>
            <div className="col-5 col-sm-4 mt-1">
              <Button
                label="Bulk Select"
                type={"submit"}
                onClick={() => {
                  setBulkSelect(!bulkSelect);
                }}
                showButtonLabelName
              />
            </div>
          </div>
          <div>
            <DirectMessage
              refresh={refresh}
              logUser={logUser}
              customUserParam={customParam}
              selectedUserIds={selectedIds ? selectedIds : []}
              setSelectedUserIds={setSelectedIds}
              closeModel={toggle}
              getMessageList={getMessageList}
              mediaAttachment
              isModel={isOpen}
              setAttachment={setAttachment}
              attachment={attachment}
              setFileName={setFileName}
              fileName={fileName}
              history={props?.history}
            />
          </div>
        </div>
      </Form>
    </div>
  );

  const handleActionChange = (e) => {
    if (e === "Create Group") {
      groupToggle(true);
    }
  };

  const handleGroupChange = (rowValue) => {
    if(rowValue){
      setActiveChannel(rowValue)
    props.history.push(`/message/group/${rowValue?.channel_id}`)
    }
  };

  const handleUserChange = (values) => {
    setSelectedIds(values);
    const uniqueIds = [...new Set([...(selectedIds || []), ...values])];
    setSelectedIds([]);
    setBulkIds(uniqueIds);
  };
  const handleAdd = () => {
    if(ArrayList.isEmpty(bulkIds) || !bulkIds){
      return Toast.error("Select Id(s)")
    }
  setSelectedIds(bulkIds);
  setBulkSelect(!bulkSelect);
  };
  const addActivityForm = (
    <div className="mt-2 mb-3 z-40 maxWidth-100">
      <Form initialValues={{ user: "" }} enableReinitialize>
        <div>
          <MessageUserList
            selectedCheckBox={selectedCheckBox}
            setSelectedIds={handleUserChange}
            excludedIds={selectedIds}
            history={props?.history}
          />
        </div>
      </Form>
    </div>
  );
  // drawer initial values
  const forwardInitialValues = {
    user: userValueData || "",
  };
  // drawer toggle
  const bulkToggle = () => {
    setBulkSelect(!bulkSelect);
  };
  const modalFooter = (
    <div>
      <Button
        label="Add"
        className="btn btn-outline-primary m-0 pl-0"
        onClick={() => handleAdd()}
      />
    </div>
  );
  const handleToggleChange = () => {
    setAutoRefresh(!autoRefresh)
    setCookie(COOKIE_MESSAGE_AUTO_REFRESH, !autoRefresh);
  };
  return (
    <>
    <GroupForm
        getChannelList={getChannelList}
        toggle={groupToggle}
        isOpen={groupOpen}
        history={history}
      />
      <AddModal
        isOpen={isOpen}
        toggle={toggle}
        className="expert-modal maxWidth-100"
        toggleModalClose={toggle}
        modalTitle={"Send Message"}
        modalBody={UserList}
        hideDefaultButtons
      />

      {bulkSelect && (
        <AddModal
          modelTitle={"User"}
          modalBody={addActivityForm}
          className="expert-modal maxWidth-100"
          initialValues={forwardInitialValues}
          toggle={bulkToggle}
          toggleModalClose={bulkToggle}
          hideDefaultButtons
          isOpen={bulkSelect}
          modalFooter={modalFooter}
          onSubmit={(values) => {
            bulkToggle(values);
          }}
        />
      )}
      <div className="d-flex justify-content-end">
        <div className="mt-1">
          <ToggleButton
            toggleOffLabel={"Auto Refresh"}
            handleToggle={() => handleToggleChange()}
            LabelfontSize={10}
            toggled={autoRefresh}
          />
        </div>
        <div className="ms-2">
          <Action
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}
          />
        </div>
      </div>
      <div className="w-sm-40 w-md-40 col-sm-12 col-md-6 col-lg-4 p-2">
        <div className="d-flex  justify-content-between">
          <PageTitle label="Messages" />
          <div className="d-flex justify-content-end gap-1">
            <button
              onClick={handleRefresh}
              className="btn btn-dark btn-sm "
              style={{ height: "30px" }}
            >
              <i
                className={`fa ${refresh ? "fa-sync fa-spin" : "fa-refresh"
                  } text-white`}
              />
            </button>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle tag="span" className="icon-pointer">
                <FontAwesomeIcon
                  size="2x"
                  className="text-success"
                  icon={faSquarePlus}
                  onClick={() => toggle(true)}
                />
              </DropdownToggle>
            </Dropdown>

          </div>

        </div>
      </div>

      <div className="d-flex flex-column flex-md-row">
        {/* Sidebar */}
        <div className="w-sm-40 w-md-40 col-sm-12 col-md-6 col-lg-4">
          <SideBar
            toggle={toggle}
            handleClick={handleClick}
            handleGroupChange={handleGroupChange}
            showDirectMessageSection
            showChennalSection
            refreshValue={handleRefresh}
            refresh={refresh}
            messageList={messageList}
            setDirectMessageList={setDirectMessageList}
            channelsList={channelList}
            history={props?.history}
            getChannelList={getChannelList}
            setChannelList={setChannelList}
          />
        </div>

        {/* Message Box */}
        <div className="d-flex flex-column flex-grow-1 col-sm-12 col-md-6 col-lg-8">
          <div className="flex-grow-1 w-100">
            <MessageBox
              refresh={refresh}
              logUser={logUser}
              setSelectedCheckBox={setSelectedCheckBox}
              selectedCheckBox={selectedCheckBox}
              bulkSelect={bulkSelect}
              getMessageList={getMessageList}
              customUserParam={customParam}
              directMessageList={directMessageList}
              selectedUserIds={selectedIds}
              setSelectedUserIds={setSelectedIds}
              getChannelList={getChannelList}
              setAttachment={setAttachment}
              attachment={attachment}
              setFileName={setFileName}
              fileName={fileName}
              params={props?.match?.params}
              isModel={isOpen}
              history={props?.history}
              activeChannel={activeChannel}
              activeUser={activeUser}
              autoRefresh={autoRefresh}
            />
          </div>
        </div>
      </div>
      <div className="d-sm-none d-block d-flex justify-content-between">
        <PageTitle label="Direct Messages" />
        <div className="">
          <FontAwesomeIcon
            size="2x"
            className="icon-pointer"
            color="#009dda"
            icon={faSquarePlus}
            onClick={() => {
              toggle();
            }}
          />
       
        </div>
      </div>
    </>
  );
}

export default Message;
