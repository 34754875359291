import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { HttpStatus } from "../helpers/HttpStatus";
import ArrayList from "../lib/ArrayList";
import { isBadRequest } from "../lib/Http";
import Url from "../lib/Url";


class LocationRackService{
    static async create(data, callback) {
        try {
              apiClient
                .post(`${endpoints().LocationRackAPI}`, data)
                .then((response) => {
                  let successMessage;
                  if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                    return callback && callback(response);
                  }
                }).catch((err) => {
                  if (isBadRequest(err)) {
                    let errorMessage;
                    const errorRequest = err.response.request;
                    if (errorRequest && errorRequest.response) {
                      errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                  }
                });
        } catch (err) {
          console.log(err);
        }
      }

      static async delete(id,callback) {
        apiClient
          .delete(`${endpoints().LocationRackAPI}`,{params:{id}})
          .then((response) => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
              return callback(successMessage)
            }
          })
          .catch((error) => {
    
            if (
              error.response &&
              error.response.status >= HttpStatus.BAD_REQUEST
            ) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
    
              Toast.error(errorMessage);
              console.error(errorMessage);
            }
          });
      }
      static update(id, data,callback) {
        apiClient
            .put(`${endpoints().LocationRackAPI}`, data,{params:{id}})
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                    return callback(successMessage)
                }
            })
            .catch((err) => {
                if (isBadRequest(err)) {
                    let errorMessage;
                    const errorRequest = err.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                }
            });
}
static async list(params) {
  let queryString = await ArrayList.toQueryString(params);
  let response = await Url.get(`${endpoints().LocationRackAPI}/list`,queryString);
  return response && response.data;
}
}
export default LocationRackService;