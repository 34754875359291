import PropTypes from "prop-types";
import React from "react";
import Currency from "../../../lib/Currency";
import FeatureImage from "../../../components/Image";
import Number from "../../../lib/Number";
import "../product.scss";
import { Status } from "../../../helpers/Product";
import { Link } from "react-router-dom";

class AvatarCard extends React.Component {
  render() {
    const {
      productName,
      brandName,
      hideLinkUnderline,
      size,
      unit,
      salePrice,
      mrp,
      id,
      url,
      packSize,
      status,
      brand_id,
      disableLink,
      quantity,
      disableLinks,
      showHSNCode,
      hsn_code,
      price,
      showId,
    } = this.props;

    const getMrp = (mrp) => {
      if (mrp && Number.Float(mrp) !== Number.Float(salePrice)) {
        return mrp;
      }
      return "";
    };

    return (
      <div style={{ display: "table" }}>
        {/* Product Image */}
        <div
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            paddingRight: 25,
            width: "100px",
            height: "80px",
          }}
        >
          <FeatureImage
            size="imageSize"
            src={url}
            className="img-fluid p-1 bg-white border rounded"
            minWidth="70px"
            maxHeight="80px"
          />
        </div>

        {/* Product Details */}
        {productName && (
          <div className="table-cell align-middle ">
            {/* Brand Name Link */}
            {brandName && (
              <div className="inline-flex items-center">
                <Link
                  target="_blank"
                  to={brand_id ? `/brands/${brand_id}` : "#"}
                  className={`text-blue-600 hover:underline ${hideLinkUnderline ? "no-underline" : ""
                    }`}
                  style={{ pointerEvents: disableLink ? "none" : "auto", cursor: disableLink ? "default" : "pointer" }}
                >
                  <h6 className="font-bold">{brandName}</h6>
                </Link>
                {status === Status.INACTIVE && (
                  <h6 className="font-bold mx-2 text-red-600">{`(${status})`}</h6>
                )}
              </div>
            )}

            {/* Product Name Link */}
            <Link
              target="_blank"
              to={id ? `/product/${id}` : "#"}
              className={`text-blue-600 hover:underline ${hideLinkUnderline ? "no-underline" : ""
                }`}
              style={{ pointerEvents: disableLinks ? "none" : "auto", cursor: disableLinks ? "default" : "pointer" }}
            >
              <h6 className="p-0 m-0">
                {productName}
                <span className="">
                  {size ? `, ${size}` : ""}
                  {unit ? ` ${unit}` : ""}
                  {packSize ? ` (Pack Size: ${packSize})` : ""}
                </span>
              </h6>
            </Link>

            {/* HSN Code */}
            {showHSNCode && hsn_code && (
              <div className="text-gray-500 dark:text-gray-900">
                {`HSN Code: ${hsn_code}`}
              </div>
            )}

            {/* Pricing Details */}
            {mrp && getMrp(mrp) && (
              <div className="table-cell  align-middle">
                {mrp && salePrice ? (
                  <h6>
                    <del>
                      {`${Currency.Format(parseFloat(mrp))},`}
                      &nbsp;
                    </del>
                  </h6>
                ) : (
                  <h6>
                    {`${Currency.Format(parseFloat(mrp))},`}
                    &nbsp;
                  </h6>
                )}
              </div>
            )}

            {/* Sale Price */}
            {salePrice && (
              <div className="table-cell  align-middle font-bold">
                <h6>{Currency.Format(parseFloat(salePrice))}</h6>
              </div>
            )}

            {/* Quantity & Price */}
            {quantity && (
              <h6 style={{ color: "gray" }}>
                {quantity} x {price}
              </h6>
            )}

            {/* Product ID */}
            {showId && (
              <h6 className="fw-bold" style={{ color: "lightgray" }}>
                {`Product# ${id}`}
              </h6>
            )}
          </div>
        )}
      </div>
    );
  }
}

AvatarCard.propTypes = {
  url: PropTypes.string,
  defaultUrl: PropTypes.string,
  size: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  customSize: PropTypes.number,
};

export default AvatarCard;
