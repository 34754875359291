import React, { useEffect, useState } from "react";
import Form from "../../../components/Form";
import SaveButton from "../../../components/SaveButton";
import TagSelect from "../../../components/TagSelect";
import { Setting } from "../../../helpers/Setting";
import { TagTypeName } from "../../../helpers/Tag";
import { getKeyValueByObject } from "../../../lib/Helper";
import { getCompanySettings, saveSetting } from "../../../services/SettingService";
import TagTypeService from "../../../services/TagTypeService";
const SettingTab = (props) => {
    const [typeList, setTypeList] = useState([]);
    const [fineValue, setFineValue] = useState([]);

    useEffect(() => {
        getFineTypes();
        getSettings();
    }, []);

    const getSettings = async () => {
        const settingData = await getCompanySettings();
        let cash = getKeyValueByObject(
            settingData,
            Setting.PAYMENT_DUE_DATE_CHANGE_FINE_TYPE
        );

        setFineValue(cash);
    };
    const getFineTypes = async () => {
        let response = await TagTypeService.TagList("");
        let data = response && response?.data;
        if (data && data.length > 0) {
            let list = [];
            for (let i = 0; i < data.length; i++) {
                const { id, name } = data[i];
                list.push({
                    value: id,
                    label: name,
                });
            }
            setTypeList(list);
        }
    };
    const handleUpdate = async (values) => {
        let data = new FormData();
        data.append(
            Setting.PAYMENT_DUE_DATE_CHANGE_FINE_TYPE,
            values && values[Setting.PAYMENT_DUE_DATE_CHANGE_FINE_TYPE].value
        );

        saveSetting(data, null, (res) => {
            if (res) {
            }
        });
    };

    return (
        <div>
            <Form
                enableReinitialize={true}
                initialValues={{
                    [Setting.PAYMENT_DUE_DATE_CHANGE_FINE_TYPE]:
                        typeList &&
                        typeList.length > 0 &&
                        typeList.find((data) => data?.value == fineValue),
                }}
                onSubmit={(values) => {
                    handleUpdate(values);
                }}
            >
                <div className="card bg-white mb-3">
                    <div className="card-body">
                        <TagSelect name={Setting.PAYMENT_DUE_DATE_CHANGE_FINE_TYPE}
                            label="Payment Due Date Change Fine Type"
                            params={{ type: TagTypeName.FINE_TYPE }}
                        />
                        <div className="mb-4">
                            <div>
                                <SaveButton />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default SettingTab;
