export const Status ={
    STATUS_ACTIVE_TEXT:"Active",
    STATUS_INACTIVE_TEXT:"InActive",
    
    STATUS_ACTIVE:0,
    STATUS_INACTIVE:1,
 }
 
 
 
 export const statusOptions =[
    {
        label: Status.STATUS_ACTIVE_TEXT,
        value: Status.STATUS_ACTIVE
    },
    {
        label: Status.STATUS_INACTIVE_TEXT,
        value: Status.STATUS_INACTIVE
    }
 ]