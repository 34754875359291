import React from "react";
import AccountTypeSelect from "../../../components/AccountTypeSelect";
import AlphaNumericText from "../../../components/AlphaNumericText";
import BillingNameSelect from "../../../components/BillingNameSelect";
import DraftEditor from "../../../components/Draft";
import PaymentAccountSelect from "../../../components/PaymentAccountSelect";
import Percentage from "../../../components/Percentage";
import Select from "../../../components/Select";
import Text from "../../../components/Text";
import TextArea from "../../../components/TextArea";
import Url from "../../../components/Url";

// Helpers
import Account from "../../../helpers/Account";
import { vendorStatusOptions } from "../../../helpers/Vendor";
import Phone from "../../../components/Phone";

const AccountForm = (props) => {
  let { row } = props;

  return (
    <>
      <div className="card border-0 p-3">
        <div className="row">
          <div className="col order-2 order-lg-1 d-inline-block">
            <Text
              label="Name"
              name="vendor_name"
              placeholder="Name"
              onChange={props?.vendorChange}
              required
              notify
              disabled={props.editable}
            />
            <AccountTypeSelect
              label="Type"
              name="type"
              handleChange={props?.handleTypeChange}
              accountTypeOption={(x) => props?.accountTypeOption(x)}
              isDisabled={props.editable}
              defaultValue={row?.typeId}
            />
            <Select
              fullWidth={true}
              label="Status"
              name="status"
              isClearable
              options={vendorStatusOptions}
              handleChange={(e) => props?.handleStatusChange(e)}
              isDisabled={props.editable}
            />
            <Url
              label="Website"
              name="vendor_url"
              placeholder="Website"
              disabled={props.editable}
              onChange={props?.handleChange}
            />
            {(props.type ? props.type : props?.row && props?.row?.typeId) ===
              Account.CATEGORY_CUSTOMER && (
              <AlphaNumericText
                label="GST Number"
                name="gst_number"
                placeholder="GST Number"
                notify
                disabled={props.editable}
                onChange={props.handleGstNumber}
              />
            )}
            {(props?.type ? props?.type : props?.row && props?.row?.typeId) ==
              Account.CATEGORY_VENDOR && (
              <>
                <PaymentAccountSelect
                  handleChange={props?.handlePaymentAccountChange}
                  setpaymentAccountName={props?.setpaymentAccountName}
                  detail={
                    props?.row?.payment_account
                      ? props?.row?.payment_account
                      : props?.vendorDetails &&
                        props?.vendorDetails?.data?.payment_account
                  }
                  name={"payment_account"}
                  label={"Payment Account"}
                  isDisabled={props.editable}
                />
                <Percentage
                  name="cash_discount"
                  label="Cash Discount %"
                  disabled={props.editable}
                  onInputChange={props?.handleCashDiscount}
                />

                <BillingNameSelect
                  label="Billing Name"
                  name="billing_name"
                  isDisabled={props.editable}
                  handleChange={props?.handleBillingNameChange}
                />
                <TextArea
                  name="payment_terms"
                  label="Payment Terms"
                  placeholder="Payment Terms"
                  disabled={props.editable}
                  onChange={props?.handlePaymentTerms}
                />
                <TextArea
                  name="return_terms"
                  label="Return Terms"
                  placeholder="Return Terms"
                  disabled={props.editable}
                  onChange={props?.handleReturnTerms}
                />
              </>
            )}
            <Phone
              name="mobile"
              label="Phone Number"
              placeholder="Enter Mobile Number"
              onChange={props.handlePhoneNumberChange}
            />
            <DraftEditor
              name="notes"
              label={"Notes"}
              editorState={props?.editorState}
              onChange={props?.handleEditorChange}
              readOnly={props?.editable}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountForm;
