import React, { useEffect, useState } from "react";
import { getUserRole } from "../../../services/UserSettingService";
import { Setting } from "../../../helpers/Setting";
import {
  getCompanySettings,
  saveSetting,
} from "../../../services/SettingService";
import MultiSelect from "../../../components/Multiselect";
import { getKeyValueByObject } from "../../../lib/Helper";
import Form from "../../../components/Form";
import SaveButton from "../../../components/SaveButton";
import ObjectName from "../../../helpers/ObjectName";

const SettingTab = () => {
  const [settings, setSettings] = useState("");
  const [userRole, setUserRole] = useState();

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    let allowedRole = getKeyValueByObject(settingData, Setting.LOCATION_ALLOCATION_ALLOWED_ROLES);
    setSettings(allowedRole);
  };

  let allowedRoleValue = [];
  if (settings) {
    let roleData = settings && settings?.split(",");
    roleData.forEach((value) => {
      let data = userRole && userRole.find((data) => data?.value == value);
      allowedRoleValue.push(data);
    });
  }

  useEffect(() => {
    getSettings();
    getUserList();
  }, []);

  const getUserList = async () => {
    const roleData = await getUserRole();
    setUserRole(roleData);
  };

  const initialValues = {
    location_allocation_allowed_roles: allowedRoleValue,
  };

  const handleSubmite = (values) => {
    let allowedRole = [];
    for (let i = 0; i < values.location_allocation_allowed_roles.length; i++) {
      const data = values.location_allocation_allowed_roles[i];
      allowedRole.push(data?.value);
    }

    let data = new FormData();

    data.append(
      Setting.LOCATION_ALLOCATION_ALLOWED_ROLES,
      allowedRole && allowedRole.length > 0 ? allowedRole.join(",") : ""
    );
    data.append("objectName", ObjectName.LOCATION_ALLOCATION);

    // Save settings
    saveSetting(data, null, () => {
      getSettings();
    });
  };

  return (
    <>
      <div className="card card-body">
        <Form
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmite}
        >
          <div className="row field-wrapper mt-3">
            <div className="col-lg-12 col-sm-12">
              <MultiSelect
                label="Allowed Roles"
                name={Setting.LOCATION_ALLOCATION_ALLOWED_ROLES}
                options={userRole ? userRole : []}
              />
            </div>
          </div>
          <div className="col-6">
            <SaveButton label="Save" />
          </div>
        </Form>
      </div>
    </>
  );
};

export default SettingTab;
