import { useState } from "react";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import PromotionForm from "./PromotionForm";
import Button from "../../components/Button";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import { endpoints } from "../../api/endPoints";
import { useDispatch } from "react-redux";
import PromotionService from "../../services/PromotionService";
import StatusText from "../../components/StatusText";
import MoreDropdown from "../../components/authentication/moreDropdown";
import { DropdownItem } from "reactstrap";
import Promotion from "../../helpers/Promotion";
import { fetchList } from "../../actions/table";
import Url from "../../lib/Url";
import DeleteModal from "../../components/DeleteModal";
import { Link } from "react-router-dom";

const PromotionIndex = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rowValue, setRowValue] = useState();

  const [isDeleteModel, setDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const closeToggle = () => {
    setIsOpen(!isOpen);
    setRowValue();
  };

  const form = (
    <PromotionForm
      rowValue={rowValue}
      closeToggle={closeToggle}
      toggle={toggle}
      form={rowValue && rowValue?.id ? "Edit" : "Add"}
    />
  );
 

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "name:ASC",
      label: "Name",
    },
  ];
  const closeDeleteModal = () => {
    setDeleteModal(false);
    setRowValue();
  };
  const handleDelete = async () => {
    dispatch(
      await PromotionService.delete(rowValue?.id, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "promotion",
              `${endpoints().promotionAPI}`,
              1,
              25,
              Url.GetAllParams()
            )
          );
          closeDeleteModal();
        }
      })
    );
  };
  return (
    <div>
      <PageTitle
        buttonLabel="Add New"
        buttonHandler={(e) => {
          toggle();
        }}
        label="Promotion"
      />
      <DeleteModal
        isOpen={isDeleteModel}
        title="Delete Prmotion"
        label={rowValue?.name}
        toggle={closeDeleteModal}
        deleteFunction={handleDelete}
      />
      <Drawer
        handleOpenModal={toggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
        DrawerBody={form}
        enableReinitialize
        modelTitle={rowValue?.id ? "Edit Promotion" : "Add Promotion"}
      />
      <div className="mt-3">
        <ReduxTable
          id="promotion"
          showHeader
          searchPlaceholder="Search"
          apiURL={`${endpoints().promotionAPI}`}
          newTableHeading
          history={props.history}
          paramsToUrl={true}
          sortByOptions={sortByOption}
        >
          <ReduxColumn
            field="name"
            sortBy="name"
            width="190px"
            minWidth="190px"
            maxWidth="190px"
            renderField={(row) => (
                <Link to={`/promotion/${row.id}`} className="link-opacity-75 text-decoration-none">
                 {row?.name}
                </Link>
              )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            field="accountTypeName"
            width="190px"
            className="text-center"
            minWidth="190px"
            maxWidth="190px"
            disableOnClick
          >
            Account Type
          </ReduxColumn>
          <ReduxColumn
            field="reward_points"
            sortBy="reward_points"
            width="190px"
            minWidth="190px"
            className="text-center"
            maxWidth="190px"
          >
            Reward Points
          </ReduxColumn>
          <ReduxColumn
            field="start_date"
            sortBy="start_date"
            className="text-center"
            width="190px"
            minWidth="190px"
            maxWidth="190px"
          >
            Start Date
          </ReduxColumn>
          <ReduxColumn
            field="end_date"
            sortBy="end_date"
            width="190px"
            className="text-center"
            minWidth="190px"
            maxWidth="190px"
          >
            End Date
          </ReduxColumn>
          <ReduxColumn
            field="status"
            sortBy="status"
            width="190px"
            minWidth="190px"
            className="text-center"
            maxWidth="190px"
            renderField={(row) => (
              <StatusText
                backgroundColor={row.statusColor}
                status={row.status}
              />
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            width="70px"
            field="Action"
            disableOnClick
            renderField={(row) => (
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  {
                    <DropdownItem
                      onClick={() => {
                        setRowValue(row);
                        toggle();
                      }}
                    >
                      Quick View
                    </DropdownItem>
                  }

                  {row.statusId !== Promotion.STATUS_ACTIVE ? (
                    <DropdownItem
                      onClick={async () => {
                        const data = { status: Promotion.STATUS_ACTIVE };
                        dispatch(
                          await PromotionService.updateStatus(
                            row.id,
                            data,
                            (res) => {
                              if (res) {
                                dispatch(
                                  fetchList(
                                    "promotion",
                                    `${endpoints().promotionAPI}`,
                                    Url.GetParam("page"),
                                    Url.GetParam("pageSize"),
                                    Url.GetAllParams()
                                  )
                                );
                              }
                            }
                          )
                        );
                      }}
                    >
                      Make as Active
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      onClick={async () => {
                        const data = { status: Promotion.STATUS_INACTIVE };
                        dispatch(
                          await PromotionService.updateStatus(
                            row.id,
                            data,
                            (res) => {
                              if (res) {
                                dispatch(
                                  fetchList(
                                    "promotion",
                                    `${endpoints().promotionAPI}`,
                                    Url.GetParam("page"),
                                    Url.GetParam("pageSize"),
                                    Url.GetAllParams()
                                  )
                                );
                              }
                            }
                          )
                        );
                      }}
                    >
                      Make as InActive
                    </DropdownItem>
                  )}
                  {
                    <DropdownItem
                      onClick={() => {
                        setRowValue(row);
                        setDeleteModal(true);
                      }}
                    >
                      <div className="text-danger">Delete</div>
                    </DropdownItem>
                  }
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </div>
  );
};
export default PromotionIndex;
