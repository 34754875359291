import ActivityType from "../views/activityType";

const ObjectName = {
  ACTIVITY: "ACTIVITY",
  ACCOUNT_AGREEMENT: 'ACCOUNT_AGREEMENT',
  ACCOUNT_RATING: 'ACCOUNT_RATING',
  ACCOUNT_PAYMENT_PREFERENCE: "ACCOUNT_PAYMENT_PREFERENCE",
  ACCOUNT_ENTRY: "ACCOUNT_ENTRY",
  ACTIVITY_TYPE: "ACTIVITY_TYPE",
  ATTENDANCE: "ATTENDANCE",
  ACCOUNT: "ACCOUNT",
  APP : "APP",
  BILL: "BILL",
  BILL_GST_STATUS:"BILL_GST_STATUS",
  BILL_GST_STATUS:"BILL_GST_STATUS",
  BILL_TAX:"BILL_TAX",
  BONUS: "BONUS",
  BANK_SETTLEMENT:"BANK_SETTLEMENT",
  CANDIDATE:"CANDIDATE",
  CATEGORY: "CATEGORY",
  CALL_REGISTER:"CALL_REGISTER",
  CUSTOMER: "CUSTOMER",
  CONTACT: "CONTACT",
  COMPANY: "COMPANY",
  COMMENT: "COMMENT",
  COUNTRY:"COUNTRY",
  EMPLOYEE : "EMPLOYEE",
  FINE: "FINE",
  HOLIDAY: "HOLIDAY",
  INSPECTION: "INSPECTION",
  LOCATION: "LOCATION",
  LOCATION_ALLOCATION: "LOCATION_ALLOCATION",
  LEAD: "LEAD",
  MEDIA: "MEDIA",
  ORDER: "ORDER",
  ORDER_TYPE:"ORDER_TYPE",
  ORDER_PRODUCT: "ORDER_PRODUCT",
  PAGE: "PAGE",
  PAGE_BLOCK_FIELDS:"PAGE_BLOCK_FIELDS",
  PAYMENT: "PAYMENT",
  PAYMENT_ACCOUNT: "PAYMENT_ACCOUNT",
  PORTAL_LOGO_URL:"PORTAL_LOGO_URL",
  PRODUCT: "PRODUCT",
  PROJECT: "PROJECT",
  PURCHASE: "PURCHASE",
  PRODUCT_PRICE: "PRODUCT_PRICE",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  PURCHASE_PRODUCT: "PURCHASE_PRODUCT",
  PURCHASE_ORDER_PRODUCT: "PURCHASE_ORDER_PRODUCT",
  ROLE: "ROLE",
  RECURRING_BILL: "RECURRING_BILL",
  RECURRING_PAYMENT: "RECURRING_PAYMENT",
  RECURRING_ACTIVITE:"RECURRING_ACTIVITE",
  RECURRING_TICKET: "RECURRING_TICKET",
  REIMBURSEMENT: "REIMBURSEMENT",
  STATUS:"STATUS",
  SALE_SETTLEMENT: "SALE_SETTLEMENT",
  SALARY: "SALARY",
  SHIFT: "SHIFT",
  SCHEDULER_JOB: "SCHEDULER_JOB",
  SPRINT: "SPRINT",
  STOCK_ENTRY: "STOCK_ENTRY",
  STOCK_ENTRY_PRODUCT: "STOCK_ENTRY_PRODUCT",
  TRANSFER_PRODUCT: "TRANSFER_PRODUCT",
  TIMESHEET:"TIMESHEET",
  TRANSFER: "TRANSFER",
  TRANSFER_TYPE: "TRANSFER_TYPE",
  TICKET: "TICKET",
  TICKET_TASK: "TICKET_TASK",
  TICKET_TEST: "TICKET_TEST",
  TRAINING: "TRAINING",
  USER: "USER",
  VENDOR_PRODUCT: "VENDOR_PRODUCT",
  VENDOR: "VENDOR",
  VISITOR:"VISITOR",
  TAX:"TAX",
  TIMESHEET:"TIMESHEET",
  GATE_PASS : "GATE_PASS",
  PRODUCT_BRAND:"PRODUCT_BRAND",
  DELIVERY : "DELIVERY",
  INVOICE_PRODUCT: "INVOICE_PRODUCT",
  PROMOTION: "PROMOTION",
  INVOICE: "INVOICE",
};

ObjectName.Options = Object.entries(ObjectName)
  .map(([key, value]) => ({ label: key, value }))
  .sort((a, b) => a.label.localeCompare(b.label));

export default ObjectName;
