export const Local={
    SPRINT_LIST:"sprintList",
    PROJECT_LIST:"projectList",
    USER_LIST:"userList",
    SEVERITY_LIST:"severityList",
    PRIORITY_LIST:"priorityList",
    TICKET_TYPE_LIST:"ticketTypeList",
    LOCATION_LIST:"location_list",
    THEME:"theme",
    MENU:"menu",
    COMPANY:"company"
}