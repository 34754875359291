class Media {
  static ConvertBase64ToFile(base64String, fileName) {
    const arr = base64String.split(",");
    const mime = arr[0].match(/:(.*?);/)[1]; // Extract MIME type
    const bstr = atob(arr[1]); // Decode Base64
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }
  static isApkURL(url) {
    return url?.toLowerCase().endsWith(".apk");
  }

  static isVideoURL(url) {
    return url?.match(/\.(mp4|mkv|mov|avi|webm)$/i);
  }

  static isPdfURL(url) {
    return url?.toLowerCase().endsWith(".pdf");
  }
  static isMp3URL(url) {
    return url?.toLowerCase().endsWith(".mp3");
  }
}
export default Media;
