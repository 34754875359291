import React from "react";
import TextArea from "../../components/TextArea";
import DragAndDropField from "../../components/FileUpload";
import UserSelect from "../../components/UserSelect";
import ObjectName from "../../helpers/ObjectName";
import MediaCarousel from "../../components/MediaCarousel";
import Text from "../../components/Text";
import Select from "../../components/Select";
import { typeOption } from "../../helpers/GatePass";

const GatePassForm = (props) => {
  const {
    onDrop,
    selectedFile,
    handleDelete,
    name,
    rowValue,
    editable,
    handleUserChange,
    user,
    setLogedInUser,
    isDetailPage,
    hasEditPermission
  } = props;

  return (
    <>
      <div>
        <Text
          name="person_name"
          label="Person Name"
          placeholder="Person Name"
          required={true}
          disabled={editable}
        />
        <Select
          label={"Type"}
          name={"type"}
          options={typeOption}
          isDisabled={editable}
        />
        <TextArea
          name="notes"
          label="Notes"
          placeholder="Notes"
          disabled={editable}
        />
      </div>

      {!isDetailPage && !rowValue && (
        <DragAndDropField
          onDrop={onDrop}
          selectedFile={selectedFile}
          handleDelete={handleDelete}
          width="100%"
        />
      )}
      {!isDetailPage && rowValue && rowValue?.id && (
        <MediaCarousel
          showCarasoul
          objectName={ObjectName.GATE_PASS}
          objectId={rowValue && rowValue.id}
          history={props.history}
          attachmentsList={true}
          Attachments={"Attachments"}
          editable={!hasEditPermission}
        />
      )}
    </>
  );
};

export default GatePassForm;
