import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Components
import MoreDropdown from "../../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import Spinner from "../../../components/Spinner";
import StatusText from "../../../components/StatusText";
import UserCard from "../../../components/UserCard";

// Endpoints
import { endpoints } from "../../../api/endPoints";

// Lib
import Currency from "../../../lib/Currency";
import Url from "../../../lib/Url";
import DateTime from "../../../lib/DateTime";

// Font Awesome Icon
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Services
import { AccountService } from "../../../services/AccountService";

// Helpers
import ObjectName from "../../../helpers/ObjectName";

const PurchaseList = (props) => {
  const { history, section, apiUrl, setRowValue, StoreSelectModal, showPurchase = true } = props;

  const [page, setPage] = useState();
  const [isSubmit, setIsSubmit] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);



  const toggle = () => {
    setIsOpen(!isOpen);
    setIsSubmit(true);
    setUser("");
  };



  // Sort By Option Values
  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "purchase_date:ASC",
      label: "Date",
    },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <ReduxTable
        id={props?.id ? props?.id : "purchase"}
        showHeader
        disableHeader={props?.disableHeader}
        newTableHeading
        searchPlaceholder="Search"
        apiURL={apiUrl ? apiUrl : `${endpoints().purchaseAPI}/search`}
        setPage={setPage}
        params={{
          section: section ? section : "",
          search: Url.GetParam("search") || "",
          objectName: ObjectName.PURCHASE,
          bill_id: props?.billId ? props?.billId : "",
          showTotal: true,
        }}
        paramsToUrl={true}
        history={history}
        sortByOptions={sortByOption}
        message="You can start by clicking on Add New"
        icon={<FontAwesomeIcon icon={faCartShopping} />}
        showStatusFilter
        showAccountFilter
        showCustomDateFilter
        vendorPlaceholder="Select Vendor"
        showBackgroundColor
        accountSelectParams={{
          allow_purchase: true,
        }}
      >
        <ReduxColumn
          field="purchaseNumber"
          width="120px"
          minWidth="120px"
          className="text-center"
          maxWidth="120px"
          type="link"
          sortBy="purchase_number"
          isClickable="true"
          renderField={(row) => (
            <Link to={`/purchase/${row.id}`} className="link-opacity-75">
              {row.purchaseNumber}
            </Link>
          )}
        >
          Purchase#
        </ReduxColumn>
        <ReduxColumn
          field="purchaseDate"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          sortBy="purchase_date"
          renderField={(row) => (
            <span>{DateTime.getDate(row.purchaseDate)}</span>
          )}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="vendor_invoice_date"
          className="text-center"
          width="180px"
          minWidth="180px"
          maxWidth="180px"
          sortBy="vendor_invoice_date"
        >
          Vendor Invoice Date
        </ReduxColumn>
        <ReduxColumn
          field="vendorName"
          sortBy="vendor_name"
          width="130px"
          minWidth="130px"
          maxWidth="130px"
        >
          Vendor
        </ReduxColumn>
        <ReduxColumn
          field="description"
          sortBy="description"
          minWidth="150px"
          maxWidth="150px"
          width="150px"
        >
          Description
        </ReduxColumn>
        <ReduxColumn
          field="net_amount"
          className="text-end"
          renderField={(row) => <span>{Currency.Format(row.net_amount)}</span>}
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          sortBy="amount"
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="ownerName"
          sortBy="owner_id"
          className="text-center"
          width="310px"
          minWidth="310px"
          maxWidth="310px"
          renderField={(row) => (
            <>
              <UserCard
                customSize={parseInt(50, 10)}
                firstName={row.ownerName}
                url={row.ownerAvatarUrl}
                lastName={row.ownerLastName}
              />
            </>
          )}
        >
          Owner
        </ReduxColumn>
        <ReduxColumn
          field="statusName"
          sortBy="status"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <StatusText
              backgroundColor={row.statusColor}
              status={row.statusName}
            />
          )}
        >
          Status
        </ReduxColumn>
        {showPurchase && (
          <ReduxColumn
            field="Action"
            width={"70px"}
            disableOnClick
            renderField={(row) => (
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRowValue && setRowValue(row);
                      toggle();
                      StoreSelectModal && StoreSelectModal();
                    }}
                  >
                    Quick view
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        )}
      </ReduxTable>
    </>
  );
};

export default PurchaseList;
