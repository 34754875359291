import { fetchList } from "../actions/table";
import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { isBadRequest, SUCCESS_RESPONSE } from "../lib/Http";

class DeviceInfoService {
  static async statusUpdate(id, data, cb) {
    try {
      if (id && data) {
        await apiClient
          .put(`${endpoints().UserDeviceInfoApi}/status/${id}`, data)
          .then((response) => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
              return cb(successMessage);
            }
          })
          .catch((err) => {
            if (isBadRequest(err)) {
              let errorMessage;
              const errorRequest = err.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  }

  static bulkDelete = async (selectedId, callback) => {
    try {
      const response = await apiClient.delete(`${endpoints().userDeviceInfo}/bulkDelete`, {
        data: selectedId,
      });
      if (response.status === SUCCESS_RESPONSE) {
        Toast.success(response?.data?.message);
        if (callback) {
          return callback(response.data.message);
        }
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(errorMessage);
      }
    }
  };


  static async update(id, data, cb) {
    try {
      if (id && data) {
        await apiClient
          .put(`${endpoints().UserDeviceInfoApi}/${id}`, data)
          .then((response) => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
              return cb(successMessage);
            }
          })
          .catch((err) => {
            if (isBadRequest(err)) {
              let errorMessage;
              const errorRequest = err.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  }

  static delete = (ids) => {
    try {
      return (dispatch) => {
        apiClient
          .post(`${endpoints().userDeviceInfo}/bulkUpdate`, {id: ids})
          .then((response) => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
            }
          })
          .catch((error) => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
            }
          });
      };
    } catch (error) {
      console.log(error);
    }
  };
}
export default DeviceInfoService;
