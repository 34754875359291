import React from "react";
import { endpoints } from "../../../api/endPoints";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import Currency, { Percentage } from "../../../lib/Currency";
import DateTime from "../../../lib/DateTime";
import { Link } from "react-router-dom";
import Url from "../../../lib/Url";

const PurchaseList = (props) => {
  const { product_id } = props;
  const sortByOptions = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  return (
    <>
      <ReduxTable
        id="purchase"
        params={{
          product_id: product_id,
          tab: Url.GetParam("tab"),
          sort: Url.GetParam("sort") ? Url.GetParam("sort") : "id",
          sortDir: Url.GetParam("sortDir") ? Url.GetParam("sortDir") : "DESC",
          showTotalAmount: true
        }}
        apiURL={`${endpoints().purchaseProductAPI}/search`}
        showHeader
        paramsToUrl={true}
        showBackgroundColor
        newTableHeading
        searchPlaceholder="Search"
        sortByOptions={sortByOptions}
      >
        <ReduxColumn
          field="purchase_number"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <Link to={`/purchase/${row.purchase_id}`} className="link-opacity-75" target="_blank">
              {row.purchase_number}
            </Link>
          )}
        >
          Purchase#
        </ReduxColumn>
        <ReduxColumn
          field="purchase_date"
          sortBy="purchase_date"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span>{DateTime.getDate(row.purchase_date)}</span>
          )}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="vendor_name"
          sortBy="vendor_name"
          className="text-center"
          width="190px"
          minWidth="190px"
          maxWidth="190px"
        >
          Vendor Name
        </ReduxColumn>
        <ReduxColumn
          field="quantity"
          sortBy="quantity"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Quantity
        </ReduxColumn>
        <ReduxColumn
          field="mrp"
          sortBy="mrp"
          className="text-end"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => <span>{Currency.Format(row.mrp)}</span>}
        >
          MRP
        </ReduxColumn>
        <ReduxColumn
          field="unit_price"
          sortBy="unit_price"
          className="text-end"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => <span>{Currency.Format(row.unit_price)}</span>}
        >
          Unit Price
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          sortBy="amount"
          className="text-end"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => <span>{Currency.Format(row.netAmount)}</span>}
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="margin_percentage"
          sortBy="margin_percentage"
          className="text-end"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span>{Percentage(row.margin_percentage)}</span>
          )}
        >
          Margin %
        </ReduxColumn>
        <ReduxColumn
          field="unit_margin_amount"
          sortBy="unit_margin_amount"
          className="text-end"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <span>{Currency.Format(row.unit_margin_amount)}</span>
          )}
        >
          Margin Amount
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default PurchaseList;
