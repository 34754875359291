import { fetchList } from "../actions/table";
import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { HttpStatus } from "../helpers/HttpStatus";
class ProductInvoiceService {
  // create

  static updateStatus = async (Id, data, params) => {
    return (dispatch) => {
      apiClient
        .put(`${endpoints().invoiceProductApi}/${Id}`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
          }
        })
        .then(() => {
          dispatch(
            fetchList(
              "invoiceProduct",
              `${endpoints().invoiceProductApi}`,
              1,
              25,
              params
            )
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status >= HttpStatus.BAD_REQUEST
          ) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }

            Toast.error(errorMessage);
            // toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    };
  };
}
export default ProductInvoiceService;
