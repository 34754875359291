import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
    FaAndroid,
  FaCheck,
  FaCheckDouble,
  FaDownload,
  FaEllipsisV,
  FaInfoCircle,
  FaShare,
  FaTrash,
} from "react-icons/fa";
import Image from "../../../components/Image";
import MessagesService from "../../../services/MessagesService";
import DateTime from "../../../lib/DateTime";
import { isVideoURL } from "../../../components/static/imageCarousel";
import VideoPlayer from "../../../components/VideoPlayer";
import pdfImage from "../../../assets/img/pdfImage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "reactstrap";
import Lightbox from "react-image-lightbox";
import Media from "../../../lib/Media";

const MessageBubble = ({
  msg,
  showDeleteButton = false,
  manageOthersPermission = false,
  fetchData,
  setIsForWardModalOpen,
  setForwardMessageDetail,
  width,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [hover, setHover] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const menuRef = useRef(null);
  const [imageModal, setImageModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
        setShowInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = async () => {
    await MessagesService.delete(msg?.id, (err, res) => {
      if (res) {
        setShowMenu(false);
        fetchData && fetchData();
      }
      if (err) {
        setShowMenu(false);
      }
    });
  };
  const handleDownload = async (url) => {
    if (!url) return;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", ""); // This forces the browser to download the file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderWithLinks = (text) => {
    if (!text) return null;

    const urlRegex = /(https?:\/\/[^\s]+)/g; // Match URLs
    return text.split(urlRegex).map((part, index) =>
      part.match(urlRegex) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline break-words"
        >
          {part}
        </a>
      ) : (
        <span key={index} className="break-words">
          {part}
        </span>
      )
    );
  };
  const isPdfURL = (url) => {
    const pdfExtensions = [".pdf"];
    const fileExtension = url && url.slice(url.lastIndexOf(".")).toLowerCase();
    return fileExtension && pdfExtensions.includes(fileExtension);
  };
  const closeLightbox = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };
  let isShowDeleteButton = msg?.isSender
    ? showDeleteButton
    : showDeleteButton && manageOthersPermission;
  return (
    <div
      className="relative p-2  pr-10 shadow-sm bg-gray-100 text-black dark:bg-gray-800 dark:text-white mb-2"
      style={{ maxWidth: width ? width : "90%", borderRadius: "10px" }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {imageModal && isVideoURL(msg?.media_url) ? (
        <Modal
          isOpen={imageModal}
          onRequestClose={() => {
            setImageModal(false);
          }}
          contentLabel="Video Modal"
        >
          <div className="modal-content p-0">
            <div className="d-flex justify-content-end">
              <FontAwesomeIcon
                icon={faXmark}
                className="cursor-pointer align-right fw-bold h3 mt-0 pt-0"
                onClick={() => {
                  setImageModal(false);
                }}
              />
            </div>
            <VideoPlayer
              className="react-player"
              url={msg?.media_url}
              controls={true}
              style={{ backgroundImage: msg?.media_url }}
              volume={0}
              playInlineVideo={true}
              setImageModal={setImageModal}
            />
          </div>
        </Modal>
      ) : (
        isOpen && (
          <Lightbox
            mainSrc={msg?.media_url}
            onCloseRequest={closeLightbox}
            reactModalStyle={{
              overlay: { zIndex: 99999 }, // Ensure it's always on top
              content: { zIndex: 99999, background: "rgba(0, 0, 0, 0.9)" },
            }}
          />
        )
      )}
      {/* 3-dot menu icon (shown on hover) */}
      {hover && (
        <button
          className="absolute top-2 right-2 text-gray-600 dark:text-gray-300 hover:text-gray-900"
          onClick={() => setShowMenu(!showMenu)}
        >
          <FaEllipsisV />
        </button>
      )}

      {/* Message Text */}
      {msg?.message && (
        <p style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
          {renderWithLinks(msg?.message)}
        </p>
      )}
      {/* Media (if available) */}
      {isVideoURL(msg?.media_url) ? (
  <VideoPlayer
    className="react-player"
    url={msg?.media_url}
    controls={true}
    style={{ backgroundImage: msg?.media_url }}
    volume={0}
    height="100px"
    playInlineVideo={false}
    setImageModal={setImageModal}
  />
) : Media.isApkURL(msg?.media_url) ? (
    <div className="flex items-center  pr-4 rounded-lg w-fit">
      <FaAndroid className="text-green-500 text-3xl mr-3" />
      <a
        href={msg?.media_ur}
        download
        className="text-blue-600 dark:text-blue-400 font-medium text-lg  truncate"
      >
        {msg?.media_url?.split("/").pop()}
      </a>
    </div>
) : (
  msg?.media_url &&
  <Image
    src={isPdfURL(msg?.media_url) ? pdfImage : msg?.media_url}
    alt="No Image"
    className=""
    width="200px"
    height="200px"
  />
  
)}

       

      {/* Timestamp & Read Status */}
      <div
        className="d-flex align-items-center justify-content-end mt-1"
        style={{ fontSize: "xx-small", opacity: 0.7 }}
      >
        {DateTime.formatTimestamp(msg?.timestamp)}
        {msg.isSender && (
          <span
            style={{
              color: msg.read_at ? "#34B7F1" : "#aaa",
              marginLeft: "4px",
            }}
          >
            {msg.read_at ? <FaCheckDouble /> : <FaCheck />}
          </span>
        )}
      </div>
      {showInfo && msg.isSender && (
        <div className="absolute transform -translate-x-1/2 bottom-[100px] mb-2 bg-gray-800 text-white text-sm rounded-md py-3 mb-5 pb-4 shadow-lg w-60 z-1">
          <div className="flex items-center w-full space-x-4">
            <div className="flex items-center pl-1">
              {msg.read_at ? (
                <FaCheckDouble
                  className="text-lg"
                  style={{ color: "#34B7F1" }}
                />
              ) : (
                <FaCheck className="text-lg" />
              )}
              <span className="text-sm ">Read</span>

              <span className="text-sm mx-1">
                {msg.read_at
                  ? DateTime.UTCtoLocalTimeAndMmmFormat(msg.read_at)
                  : null}
              </span>
              {!msg.read_at && (
                <FaEllipsisV className="text-gray-400 text-xl rotate-90" />
              )}
            </div>
          </div>

          <div className="absolute left-1/2 transform -translate-x-1/2 top-full w-0 h-0 border-t-gray-800 border-t-8 border-x-transparent border-x-8"></div>
        </div>
      )}

      {/* Delete menu (shown on click) */}
      {showMenu && (
        <div
          ref={menuRef}
          className="absolute bottom-5 right-2 bg-white dark:bg-gray-700 shadow-md rounded-md text-sm w-32 z-1"
        >
          {msg.isSender && (
            <button
              className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-center"
              onClick={() => {
                setShowInfo(true);
              }}
            >
              <FaInfoCircle /> info
            </button>
          )}
           {msg?.media_url && (
            <button
              className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-center"
              onClick={() => {
                handleDownload(msg?.media_url);
              }}
            >
              <FaDownload /> Download
            </button>
          )}
          <button
            className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-center"
            onClick={() => {
              setIsForWardModalOpen(true);
              setForwardMessageDetail(msg);
            }}
          >
            <FaShare /> Forward
          </button>
          {isShowDeleteButton && (
            <button
              className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-red-500 text-center"
              onClick={() => handleDelete()}
            >
              <FaTrash /> Delete
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageBubble;
