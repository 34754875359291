import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabContent, TabPane } from "reactstrap";

// Components
import DateSelector from "../../../components/Date";
import Form from "../../../components/Form";
import SaveButton from "../../../components/SaveButton";
import Select from "../../../components/Select";
import TextArea from "../../../components/TextArea";
import TicketType from "../../../components/TicketType";
import ProjectSelect from "../../../components/projectSelect";
import ProjectUserSelect from "../../../components/ProjectUserSelect";
import AccountSelect from "../../../components/AccountSelect";
import Currency from "../../../components/Currency";
import UserSelect from "../../../components/UserSelect";
import NavTab from "../../../components/NavTab";
import TicketList from "../../../components/TicketList";
import ActivityList from "../../../components/ActivityList";
import CreateTicketModel from "../../../components/createTicketModel";
import UserCard from "../../../components/UserCard";

// Helpers
import ObjectName from "../../../helpers/ObjectName";
import {
  dateOption,
  monthOption,
  typeOptions,
} from "../../../helpers/recurringTask";

// Services
import RecurringService from "../../../services/RecurringService";
import CompanyUserService from "../../../services/UserService";

// API
import { endpoints } from "../../../api/endPoints";

// Lib
import Url from "../../../lib/Url";

function Checkbox({ day, checked, onChange, disabled }) {
  return (
    <label>
      <input
        className="me-2 ms-2"
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(day, e.target.checked)}
        disabled={disabled}
      />
      {day}
    </label>
  );
}

const General = (props) => {
  const { details, recurringTaskTab, NavTabList, activeTab, getDetails, showHistory, editable, setEditable } =
    props;
  const [dateOptions, setDataOption] = useState([]);
  const [userList, setUserList] = useState([]);
  const [type, setType] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [assignee, setAssignee] = useState("");
  const [projectValue, setProjectValue] = useState("");
  const [rowValue, setRowValue] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [name, setName] = useState();
  const [dueDatePermission, setDueDatePermission] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [amountValue, setAmountValue] = useState(null);
  const [accountValue, setAccountValue] = useState(null);
  const [summary, setSummary] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    getDateOptions();
    if (details && details?.day) {
      setSelectedDays(details && details?.day);
    }
  }, []);

  useEffect(() => {
    getUserList();
  }, []);

  const handleCloseModal = () => {
    setRowValue(null);
    setDueDatePermission(true);
    setName("");
  };

  const getDateOptions = async () => {
    let dateOptions = dateOption();
    setDataOption(dateOptions);
  };

  const getUserName = (avatarUrl, firstName, lastName) => {
    return (
      <div className="d-flex">
        <UserCard
          id="avatar"
          firstName={firstName}
          lastName={lastName}
          url={avatarUrl}
        />
      </div>
    );
  };

  const getUserList = async () => {
    const response = await CompanyUserService.list();
    const userList = response && response.data;
    const data = [];
    userList &&
      userList.length > 0 &&
      userList.forEach((list) => {
        data.push({
          label: getUserName(list.media_url, list.first_name, list.last_name),
          value: list.first_name,
          id: list.id,
        });
      });
    setUserList(data);
  };

  let dayValue = [];

  if (details?.day) {
    details?.day.forEach((result) => {
      dayValue.push({
        value: result.id,
        label: result.name,
      });
    });
  }

  let weekValue = [];

  if (details?.week) {
    details?.week.forEach((result) => {
      weekValue.push({
        value: result.id,
        label: result.name,
      });
    });
  }

  const updateData = (data) => {
    if (data.taskType) {
      data.taskType = data.taskType.value;
    }

    data.day =
      selectedDays && selectedDays.length > 0
        ? JSON.stringify(selectedDays)
        : [];

    if (data.month) {
      data.month = data.month.value;
    }
    if (data.summary) {
      data.summary = data.summary;
    }
    if (data.date) {
      data.date = data.date.value;
    }
    if (data.assignee) {
      data.assignee = data.assignee.id;
    }
    data.project_id = data && data?.projectName ? data?.projectName?.value : "";
    data.ticketType = data && data?.ticketType ? data?.ticketType?.value : "";
    if (data.week) {
      data.week = data.week;
    }

    if (startDate) {
      data.start_date = startDate;
    }

    if (accountValue) {
      data.account_id = accountValue && accountValue?.value;
    }

    if (amountValue) {
      data.amount = amountValue;
    }

    dispatch(
      RecurringService.update(details?.id, data, (res) => {
        if (res) {
          getDetails();
          setEditable(true);
        }
      })
    );
  };

  const oninputProjectChange = (value) => {
    setProjectValue(value);
  };

  const handleUserChange = (values) => {
    setAssignee(values);
  };

  const handleCheckboxChange = (day, checked) => {
    if (checked) {
      setSelectedDays([...selectedDays, day]);
    } else {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    }
  };

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleAccountChange = (e) => {
    setAccountValue(e && e);
  };

  const handleAmountChange = (e) => {
    setAmountValue(e && e?.values && e?.values?.amount);
  };

  const handleSummaryChange = (e) => {
    let value = e?.target?.value;
    setSummary(value);
  };

  // initialValues
  const initialValues = {
    summary: summary ? summary : details?.summary || "",
    date: details?.date
      ? dateOptions.find((data) => data?.value == details?.date)
      : "",
    day: dayValue,
    month: monthOption.find((data) => data.value == details?.month) || null,
    taskType: typeOptions.find((data) => data.label == details?.type) || null,
    assignee: assignee
      ? assignee
      : userList && userList.find((data) => data.id == details?.assignee_id),
    projectName: projectValue
      ? projectValue
      : projectList &&
      projectList.find((data) => data?.value == details?.project_id),
    ticketType:
      typeList &&
      typeList.find((data) => data?.value == details?.ticket_type_id),
    start_date: startDate
      ? startDate
      : details && details?.start_date
        ? details?.start_date
        : "",
    account: accountValue
      ? accountValue
      : details?.accountName
        ? { label: details?.accountName, value: details?.account_id }
        : "",
    amount: amountValue ? amountValue : details?.amount ? details?.amount : "",
  };

  const handleTypeChange = async (e) => {
    setType(e.label);
  };

  const handleOpenModal = () => {
    setModalOpen(!isModalOpen);
    setIsSubmit(true);
  };

  return (
    <>
      <NavTab list={NavTabList} />
      <TabContent activeTab={activeTab}>
        {activeTab == recurringTaskTab.GENERAL && (
          <TabPane tabId={recurringTaskTab.GENERAL}>
            <div className="card p-3">
              <div className="field-wrapper mb-0 form-wrapper">
                <Form
                  initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    updateData(values);
                  }}
                >
                  <TextArea
                    name="summary"
                    label="Summary"
                    placeholder="Summary"
                    onChange={handleSummaryChange}
                    disabled={editable}
                    required
                  />

                  {details?.object_name === ObjectName.RECURRING_TASK ? (
                    <>
                      <ProjectSelect
                        label="Project"
                        projectList={setProjectList}
                        oninputProjectChange={oninputProjectChange}
                        disabled={editable}
                      />
                      <TicketType
                        typeList={setTypeList}
                        label="Ticket Type"
                        projectId={
                          (projectValue && projectValue.value) ||
                          details?.project_id
                        }
                        disabled={editable}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {(projectValue && projectValue.value) ||
                    details?.project_id ? (
                    <ProjectUserSelect
                      label="Assignee"
                      name="assignee"
                      placeholder={"Select Assignee"}
                      handleUserChange={handleUserChange}
                      projectId={
                        (projectValue && projectValue.value) ||
                        details?.project_id
                      }
                      showUserDetailsPageLink={
                        details?.assignee_id ? true : false
                      }
                      userId={details?.assignee_id}
                      isDisabled={editable}
                    />
                  ) : (
                    <UserSelect
                      label="Assignee"
                      name="assignee"
                      placeholder={"Select Assignee"}
                      handleUserChange={handleUserChange}
                      showUserDetailsPageLink={
                        details?.assignee_id ? true : false
                      }
                      userId={details?.assignee_id}
                      userList={setUserList}
                      isDisabled={editable}
                    />
                  )}

                  {details?.object_name == ObjectName.RECURRING_BILL ||
                    details?.object_name == ObjectName.RECURRING_PAYMENT ? (
                    <>
                      <AccountSelect
                        name="account"
                        label="Account"
                        handleVendorChange={handleAccountChange}
                        showAccountDetailsPageLink={
                          details?.account_id ? true : false
                        }
                        accountId={details?.account_id}
                        isDisabled={editable}
                      />
                      <Currency
                        label="Amount"
                        name="amount"
                        onInputChange={handleAmountChange}
                        disabled={editable}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  <Select
                    name="taskType"
                    label="Recurring Type"
                    placeholder="Select Task"
                    options={typeOptions}
                    handleChange={handleTypeChange}
                    isDisabled={editable}
                  />

                  {type == "Weekly" || (details?.type == "Weekly" && !type) ? (
                    <div className="my-3">
                      <Checkbox
                        day="Monday"
                        checked={selectedDays.includes("Monday")}
                        onChange={handleCheckboxChange}
                        disabled={editable}
                      />
                      <Checkbox
                        day="Tuesday"
                        checked={selectedDays.includes("Tuesday")}
                        onChange={handleCheckboxChange}
                        disabled={editable}
                      />
                      <Checkbox
                        day="Wednesday"
                        checked={selectedDays.includes("Wednesday")}
                        onChange={handleCheckboxChange}
                        disabled={editable}
                      />
                      <Checkbox
                        day="Thursday"
                        checked={selectedDays.includes("Thursday")}
                        onChange={handleCheckboxChange}
                        disabled={editable}
                      />
                      <Checkbox
                        day="Friday"
                        checked={selectedDays.includes("Friday")}
                        onChange={handleCheckboxChange}
                        disabled={editable}
                      />
                      <Checkbox
                        day="Saturday"
                        checked={selectedDays.includes("Saturday")}
                        onChange={handleCheckboxChange}
                        disabled={editable}
                      />
                      <Checkbox
                        day="Sunday"
                        checked={selectedDays.includes("Sunday")}
                        onChange={handleCheckboxChange}
                        disabled={editable}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {type == "Annually" ||
                    (details?.type == "Annually" && !type) ? (
                    <>
                      <Select
                        name="month"
                        label="Month"
                        options={monthOption}
                        isDisabled={editable}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {type == "Monthly" ||
                    type == "Annually" ||
                    (details?.type == "Monthly" && !type) ||
                    (details?.type == "Annually" && !type) ? (
                    <>
                      <Select
                        name="date"
                        label="Date"
                        options={dateOptions}
                        isDisabled={editable}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  <DateSelector
                    name="start_date"
                    label={"Start Date"}
                    placeholder="Select Start Date"
                    isClearable
                    onChange={handleStartDate}
                    disabled={editable}
                  />

                  {!editable && (
                    <SaveButton />
                  )}
                </Form>
              </div>
            </div>
          </TabPane>
        )}
        {activeTab == recurringTaskTab.TICKET && (
          <TabPane tabId={recurringTaskTab.TICKET} className="w-100">
            <div className=" d-flex">
              <div className="mx-2">
                <CreateTicketModel
                  buttonLabel="Add New"
                  rowValue={rowValue}
                  name={name}
                  isModalOpen={isModalOpen}
                  setModalOpen={setModalOpen}
                  handleCloseModal={handleCloseModal}
                  recurring_task_id={details.id}
                />
              </div>
            </div>

            <TicketList
              props={props}
              history={props.history}
              apiUrl={`${endpoints().ticketAPI}/search`}
              recurring_task_id={details.id}
              setRowValue={setRowValue}
              handleOpenModal={handleOpenModal}
              setName={setName}
              isModalOpen={isModalOpen}
              name={name}
              setDueDatePermission={setDueDatePermission}
              startDateFilter={Url.GetParam("startDate")}
              endDateFilter={Url.GetParam("endDate")}
            />
          </TabPane>
        )}

        {showHistory && activeTab == recurringTaskTab.HISTORY && (
          <TabPane tabId={recurringTaskTab.HISTORY} className="w-100">
            <ActivityList
              id={details?.id}
              objectId={details?.id}
              object_name={ObjectName.RECURRING_TASK}
              history={props.history}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default General;
