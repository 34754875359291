import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { groupOption } from "../../helpers/Status";
import Url from "../../lib/Url";
import StatusService from "../../services/StatusService";
import { getUserRole } from "../../services/UserSettingService";
import Drawers from "../Drawer";
import SaveButton from "../SaveButton";
import Spinner from "../Spinner";
import "./style.scss";

import DeleteModal from "../DeleteModal";
import MoreDropdown from "../authentication/moreDropdown";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Link from "../../components/Link";
import StatusForm from "../../views/status/statusForm";
import UserCard from "../UserCard";
import NoRecordsFound from "../NoRecordsFound";
import { User } from "../../helpers/User";
import Color from "../../helpers/Color";

const DragAndDropTable = (props) => {
  let { _toggle, isOpen, project_id, row, setRow, ticketTypeId, objectId } = props;
  const [name, setName] = useState(Url.GetParam("object_name"));
  const [data, setData] = useState("");
  const [rows, setRows] = useState(props.rows);
  const [userRole, setUserRole] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [detail, setDetail] = useState();
  const [objectName, setObjectName] = useState();
  const [color, setColor] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [userList, setUserList] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDetails();
    setName(props.objectName);
  }, [props.rows, project_id]);

  useEffect(() => {
    getDetails();
  }, [project_id]);

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    getStatusList();
      setColor(Color.options())
  }, [objectName]);

  const getUserList = async () => {
    const roleData = await getUserRole();
    setUserRole(roleData);
  };

  const getStatusList = async () => {
    const list = await StatusService.search(
      props.objectName,
      null,
      ticketTypeId ? ticketTypeId : null,
      null,
      objectId ? objectId : null
    );
    let nextStatusValue = [];
    list &&
      list.forEach((value) => {
        nextStatusValue.push({
          label: value.name,
          id: value.id,
          value: value.id,
        });
      });
    setStatusList(nextStatusValue);
  };

  const getStatusDetail = async (id) => {

    const data = await StatusService.get(id);
    setDetail(data.data);
    setObjectName(data?.data?.object_name);
    getStatusList(data?.data?.object_name);
    setIsLoading(false);
  };

  const getDetails = async () => {
    setIsLoading(true);
    const response = await StatusService.search(
      props.objectName,
      null,
      ticketTypeId ? ticketTypeId : null,
      null,
      objectId ? objectId : null
    );
    const data = response || [];
    setRows(data);
    setIsLoading(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const draggedRow = rows[result.source.index];
    const newRows = [...rows];
    newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, draggedRow);

    // update the index property of each item in the newRows array
    newRows.forEach((item, index) => {
      item.index = index;
    });
    setRows(newRows);
    StatusService.updateOrder(newRows);
  };

  let selectedTags = [];

  if (detail?.allowedUser) {
    detail?.allowedUser.forEach((result) => {
      selectedTags.push({
        value: result.id,
        label: result.name,
      });
    });
  }

  let nextStatusValue = [];

  if (detail?.nextStatus) {
    detail?.nextStatus.forEach((result) => {
      nextStatusValue.push({
        value: result.id,
        label: result.name,
      });
    });
  }

  const dueDateOption = [
    {
      label: "Today",
      value: "Today",
    },
    {
      label: "Tomorrow",
      value: "Tomorrow",
    },
  ];

  const addStatus = (
    <StatusForm
      objectName={props.objectName}
      detail={detail}
      defaultOwner={row?.default_owner}
      defaultReviewer={row?.default_reviewer}
      setColor={setColor}
      project_id={detail?.project_id ? detail?.project_id : project_id}
      ticketTypeId={ticketTypeId}
      userList={setUserList}
      showDefaultOwnerDetailsPageLink={
        detail?.id && detail?.default_owner ? true : false
      }
      showDefaultReviewerDetailsPageLink={
        detail?.id && detail?.default_reviewer ? true : false
      }
      defaultOwnerId={detail?.id ? detail?.default_owner : null}
      defaultReviewerId={detail?.id ? detail?.default_reviewer : null}
      objectId={objectId}
    />
  );

  const addFooter = (
    <div className="d-flex align-items-center">
      <SaveButton type="submit" label={row?.id ? "Save" : "Add"} />{" "}
    </div>
  );

  const statusAdd = async (values) => {
    const data = new FormData();
    const objectName = props.objectName;
    data.append("objectName", objectName ? objectName : "");
    data.append("name", values.status.trim());
    data.append(
      "nextStatus",
      values && values?.nextStatus ? JSON.stringify(values?.nextStatus) : ""
    );
    if (values && values.allowedUser) {
      data.append(
        "allowedUser",
        values && values.allowedUser ? JSON.stringify(values.allowedUser) : ""
      );
    }
    data.append(
      "update_account_product",
      values && values?.update_account_product
        ? values?.update_account_product
        : ""
    );
    data.append("sortOrder", values.sortOrder);
    data.append("colorCode", values?.colorcode?.value);

    data.append("update_quantity", values.update_quantity);
    data.append(
      "update_quantity_in_location_product",
      values && values.update_quantity_in_location_product
    );
    data.append("allow_edit", values.allow_edit);

    data.append(
      "send_notification_to_owner",
      values.send_notification_to_owner
    );
    data.append(
      "send_notification_to_reviewer",
      values.send_notification_to_reviewer
    );
    data.append(
      "location_product_last_stock_entry_date_update",
      values.update_location_product_last_stock_entry_date
    );
    data.append("update_product_price", values.update_product_price);

    data.append(
      "group",
      values && values?.group?.value ? values?.group?.value : ""
    );

    data.append("project_id", project_id ? project_id : "");
    data.append(
      "default_due_date",
      values && values?.default_due_date ? values?.default_due_date?.value : ""
    );
    data.append(
      "default_owner",
      values && values?.default_owner && values?.default_owner?.value == User.LOGGED_IN_USER ?User.LOGGED_IN_USER_VALUE: values?.default_owner?values?.default_owner?.id : ""
    );
    data.append(
      "default_reviewer",
      values && values?.default_reviewer
        ? values?.default_reviewer?.id
        : ""
    );

    data.append(
      "update_distribution_quantity",
      values && values?.update_distribution_quantity
        ? values?.update_distribution_quantity
        : ""
    );
    data.append(
      "allow_cancel",
      values && values?.allow_cancel ? values?.allow_cancel : ""
    );
    data.append("validate_amount", values.validate_amount);
    data.append("allow_refund", values.allow_refund);
    data.append("allow_to_view", values.allow_to_view);

    data.append("allow_replenishment", values.allow_replenishment);

    data.append("allow_product_add", values.allow_product_add);

    data.append("is_active_price", values.is_active_price);

    if (ticketTypeId) {
      data.append("ticket_type_id", ticketTypeId);
    }

    if (objectId) {
      data.append("object_id", objectId);
    }

    if (row?.id) {
      data.append("id", row?.id ? row?.id : "");
      await StatusService.update(
        row?.id,
        data,
        {
          sort: Url.GetParam("sort"),
          sortDir: Url.GetParam("sortDir"),
        },
        _toggle,
        setData,
        setRows,
        objectName,
        project_id,
        ticketTypeId ? ticketTypeId : null,
        objectId ? objectId : null
      );
    } else {
      await StatusService.add(
        data,
        {
          sort: Url.GetParam("sort"),
          sortDir: Url.GetParam("sortDir"),
        },
        _toggle,
        setData,
        setRows,
        props.objectName,
        project_id,
        ticketTypeId ? ticketTypeId : null,
        objectId ? objectId : null
      );
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  const deleteStatus = async (id) => {
    setIsLoading(true);
    await StatusService.Delete(
      id,
      row?.object_name,
      project_id,
      ticketTypeId ? ticketTypeId : null,
      null,
      null,
      null,
      objectId ? objectId : null
    );
    setIsLoading(true);
    await getDetails();
    _toggle();
    setIsLoading(false);
  };

  return (
    <>
      <DeleteModal
        id={row?.id}
        label={row?.name}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Status"
        deleteFunction={() => deleteStatus(row?.id)}
      />

      <Drawers
        DrawerBody={addStatus}
        DrawerFooter={addFooter}
        modelTitle={row?.id ? "Edit Status" : "Add Status"}
        onSubmit={(values) => {
          statusAdd(values);
        }}
        initialValues={{
          status: row?.id ? detail?.name : "",
          object_name: row?.id
            ? { label: detail?.object_name, value: detail?.object_name }
            : { label: props?.objectName, value: props?.objectName },
          colorcode: row?.id
            ?color && 
            color.find((option) => option.value === detail?.colorCode)
            : "",
          allowedUser: row?.id ? selectedTags : "",
          nextStatus: row?.id ? nextStatusValue : "",
          sortOrder: row?.id ? detail?.sortOrder : "",
          update_quantity: row?.id
            ? detail?.update_quantity == 1
              ? true
              : false
            : "",
          update_quantity_in_location_product: row?.id
            ? detail?.update_quantity_in_location_product == 1
              ? true
              : false
            : "",
          allow_edit: row?.id ? (detail?.allow_edit == 1 ? true : false) : "",
          send_notification_to_owner: row?.id
            ? detail?.notifyToOwner == 1
              ? true
              : false
            : "",
            send_notification_to_reviewer: row?.id
            ? detail?.notifyToReviewer == 1
              ? true
              : false
            : "",
          update_location_product_last_stock_entry_date: row?.id
            ? detail?.location_product_last_stock_entry_date_update
              ? true
              : false
            : "",
          update_product_price: row?.id
            ? detail?.update_product_price == 1
              ? true
              : false
            : "",
          group: row?.id
            ? groupOption.find((data) => data.value == detail?.groupValue)
            : "",
          default_owner: row?.id
            ? (userList &&
                userList.length > 0 &&
                userList.find((data) => data?.id == detail?.default_owner)) ||
              null
            : null,
          default_reviewer: row?.id
            ? (userList &&
                userList.length > 0 &&
                userList.find(
                  (data) => data?.id == detail?.default_reviewer
                )) ||
              null
            : null,
          allow_cancel: row?.id
            ? detail?.allowCancel == 1
              ? true
              : false
            : "",
          update_distribution_quantity: row?.id
            ? detail?.updateDistributionQuantity
            : "",
          default_due_date: detail?.default_due_date
            ? dueDateOption.find(
                (data) => data?.value === detail?.default_due_date
              )
            : "",
          validate_amount: row?.id
            ? detail?.validate_amount == 1
              ? true
              : false
            : "",
            allow_refund: row?.id
            ? detail?.allow_refund == 1
              ? true
              : false
            : "",
          allow_to_view: row?.id
            ? detail?.allow_to_view == 1
              ? true
              : false
            : "",
          update_account_product: row?.id
            ? detail?.update_account_product
              ? true
              : false
            : "",
          allow_replenishment: row?.id ? detail?.allowReplenishment : "",
          allow_product_add: row?.id ? detail?.allow_product_add : "",
          is_active_price: row?.id ? detail?.isActivePrice : "",
          not_received_product:
          detail?.not_received_product == 1
            ? true
            : false,
            rejected_product:
            detail?.rejected_product == 1
              ? true
              : false,
        }}
        handleOpenModal={_toggle}
        handleCloseModal={_toggle}
        handleDrawerClose={_toggle}
        showDeleteButton={row?.id ? true : false}
        handleDelete={() => {
          setDeleteModal(true);
        }}
        isModalOpen={isOpen}
        enableReinitialize
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="table-responsive">
          <table className="w-100 table table-hover">
            <thead>
              <tr className="table-dark">
                <th className="mx-3 p-3">Name</th>
                <th className="mx-3 p-3">Next Statuses</th>
                <th className="mx-3 p-3">Color Code</th>
                <th className="mx-3 p-3">Group</th>
                <th className="mx-3 p-3">Default Owner</th>
                <th className="mx-3 p-3">Default Reviewer</th>
                <th className="mx-3 p-3">Default Due Date</th>
                <th className="mx-3 p-3">Action</th>
              </tr>
            </thead>
            {rows && rows.length > 0 ? (
              <Droppable droppableId="table-rows">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {rows &&
                      rows.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={`table-row-${item.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <td className="m-2 p-4 text-start fw-normal">
                                <Link
                                  text={item.name}
                                  className="link-opacity-75 text-decoration-none"
                                  url={`/Status/${item.id}?object_name=${
                                    item.objectName
                                  }&ticket_type=${
                                    ticketTypeId ? ticketTypeId : ""
                                  }&objectId=${objectId ? objectId : ""}`}
                                />
                              </td>

                              <td className="m-2 p-4  text-center fw-normal">
                                {item.nextStatus &&
                                  item.nextStatus.map((role, index) => (
                                    <span key={index}>
                                      {index > 0 && <br />}
                                      {role}
                                    </span>
                                  ))}
                              </td>
                              <td className="m-2 p-4 text-center fw-normal">
                                <div className="d-flex justify-content-start align-items-start">
                                  <h2
                                    
                                  ></h2>
                                  <div className="fw-normal">
                                    {
                                      Color.options(30,30).find(
                                        (colorName) =>
                                          colorName?.value == item?.colorCode
                                      )?.label
                                    }
                                  </div>
                                </div>
                              </td>
                              <td className="m-2 p-4 text-center fw-normal">
                                {item.group}
                              </td>
                              <td className="m-2 p-4 text-center fw-normal">
                                <UserCard
                                  firstName={item.default_owner_name}
                                  lastName={item.default_owner_last_name}
                                  url={item.default_owner_media_url}
                                />
                              </td>
                              <td className="m-2 p-4 text-center fw-normal">
                                <UserCard
                                  firstName={item.default_reviewer_name}
                                  lastName={item.default_reviewer_last_name}
                                  url={item.default_reviewer_media_url}
                                />
                              </td>
                              <td className="m-2 p-4 text-center fw-normal">
                                {item.default_due_date}
                              </td>
                              <td className="m-2 p-4 text-center fw-normal">
                                <MoreDropdown>
                                  <DropdownItem
                                    onClick={() => {
                                      setRow(item);
                                      getStatusDetail(item?.id);
                                      _toggle();
                                    }}
                                  >
                                    Quick View
                                  </DropdownItem>
                                </MoreDropdown>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            ) : (
              <tr>
                <td className="text-center" colSpan="8">
                  <NoRecordsFound
                    showMessage={true}
                    hideCard={true}
                    message="No Status Found"
                    middleHeight={"70vh"}
                  />
                </td>
              </tr>
            )}
          </table>
        </div>
      </DragDropContext>
    </>
  );
};

export default DragAndDropTable;
