import React from 'react';
import './style.css';

const ToggleButton = ({ handleToggle, toggleOnLabel = "On", toggleOffLabel = "Off", LabelfontSize = "15px", toggled }) => {
  return (
    <label className="switchToggle">
      <input 
        type="checkbox" 
        checked={toggled} 
        onChange={handleToggle} 
        className="toggleInput"
      />
      <span className="slider"></span>
      <span 
        style={{ fontSize: LabelfontSize }} 
        className="labels text-center"
      >
        {toggled ? toggleOnLabel : toggleOffLabel}
      </span>
    </label>
  );
};

export default ToggleButton;