import React, { Fragment } from "react";
import LocationProductList from "../product/components/LocationProductList";

const StoreProductList = (props) => {
  let { history, bulkSelectId , selectedCheckBox} = props;

  return (
    <Fragment>
      <LocationProductList
        history={history}
        selectedCheckBox={selectedCheckBox}
        productId=""
        handleBulkSelect={bulkSelectId}
        filterProps={{
          showStoreFilter: true,
          showBrandFilter: true,
          showCategoryFilter: true,
          showProductFilter: true
        }}
      />
    </Fragment>
  );
};
export default StoreProductList;
