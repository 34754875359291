import { Field, useField } from "formik";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormGroup, FormFeedback } from "reactstrap";

const ImageUpload = (property) => {
  const { label, required, ...props } = property;
  const [selectedImage, setSelectedImage] = useState(null);
  const [field, meta, helpers] = useField(props.name);

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

  const validateFile = (file) => {
    if (required && !file) {
      return "Image is required.";
    }
    if (file && file.size > MAX_FILE_SIZE) {
      return "File size exceeds 10MB.";
    }
    return null;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    helpers.setError(null);

    const error = validateFile(file);
    if (error) {
      helpers.setError(error);
      helpers.setValue(null);
      setSelectedImage(null);
      return;
    }

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      helpers.setValue(file);
    }
  };

  const handleClearImage = () => {
    setSelectedImage(null);
    helpers.setValue(null);
    helpers.setError(null);
  };

  const isError = meta.touched && meta.error;

  // Reset the selectedImage when the form is reset
  useEffect(() => {
    if (!field.value) {
      setSelectedImage(null);
      helpers.setValue(null);
      helpers.setError(null);
    }
  }, [field.value]);

  return (
    <FormGroup>
      {label && (
        <label>
          {label} {required && "*"}
        </label>
      )}
      <div style={styles.container}>
        <div
          style={{
            ...styles.previewContainer,
            border: isError ? "3px solid red" : "3px solid #f4f4f6",
          }}
        >
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Uploaded Preview"
              style={styles.imagePreview}
            />
          ) : (
            <div style={styles.placeholder}>No Image Selected</div>
          )}
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          id="file-input"
          style={{ display: "none" }}
        />
        {!selectedImage && (
          <label htmlFor="file-input" style={styles.link}>
            Upload Image
          </label>
        )}

        {selectedImage && (
          <a onClick={handleClearImage} style={styles.removeLink}>
            Remove Image
          </a>
        )}

        <FormFeedback>{meta.error}</FormFeedback>
      </div>
    </FormGroup>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    textAlign: "center",
  },
  previewContainer: {
    width: "180px",
    height: "180px",
    marginBottom: "15px",
    borderRadius: "50%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imagePreview: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f4f4f6",
    color: "#999",
  },
  link: {
    color: "#007bff",
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "16px",
    marginTop: "10px",
  },
  removeLink: {
    color: "#ff0000",
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "16px",
    marginTop: "10px",
  },
};

ImageUpload.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

const FormImageUpload = (props) => {
  const { required } = props;

  const validate = (value) => {
    let errorMessage;
    if (required && !value) {
      errorMessage = `Image is required`;
    }
    return errorMessage;
  };

  return (
    <Field name={props.name} validate={validate}>
      {({ field, form }) => (
        <ImageUpload
          {...field}
          {...props}
          error={form.touched[field.name] && form.errors[field.name]}
        />
      )}
    </Field>
  );
};

export default FormImageUpload;
