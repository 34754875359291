/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../scss/_custom.scss";
import { useDispatch } from "react-redux";
import { EditorState } from "draft-js";

// Components
import PageTitle from "../../components/PageTitle";
import * as statusConstant from "../../components/constants/status";
import AddAccountModal from "./components/AddAccountModal";
import VendorListPage from "./components/AccountList";
import AddModal from "../../components/Modal";
import SaveButton from "../../components/SaveButton";
import AddButton from "../../components/AddButton";
import Toast from "../../components/Toast";
import AccountTypeSelect from "../../components/AccountTypeSelect";
import Action from "../../components/Action";

// Lib
import { isLoggedIn } from "../../lib/Helper";
import Url from "../../lib/Url";

// Action
import { fetchList } from "../../actions/table";

// Helpers
import Account from "../../helpers/Account";

// Services
import AccountService from "../../services/AccountService";

// API
import { endpoints } from "../../api/endPoints";

// Tabs Constants
export const Tabs = {
  CUSTOMER_TAB: "Customers",
  EMPLOYEE_TAB: "Employees",
  VENDOR_TAB: "Vendors",
  ALL: "All",
};

const VendorList = (props) => {
  let pathName = props?.history?.location?.pathname?.replace(/\/\d+$/, "");
  const { history } = props;

  const search = window.location.search;
  const pathParams = new URLSearchParams(search);
  const searchItem = pathParams.get("search");
  const param = new URLSearchParams(props.history.location.search);
  const role = param.get("tab");
  const [addVendorModal, setAddVendorModal] = useState();
  const [activeTab, setActiveTab] = useState(
    pathName == "/vendor" ? Tabs.VENDOR_TAB : Tabs.CUSTOMER_TAB
  );
  const [searchitem, setSearchTerm] = useState(searchItem);
  const [editorState, setEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  const [name, setName] = useState();
  const [rows, setRows] = useState();
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [isOpenBulkUpdateModal, setOpenBulkUpdateModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const dispatch = useDispatch();

  // Sort by Option
  const sortByOption = [
    {
      value: "vendorName:ASC",
      label: "Name",
    },
    {
      value: "updatedAt:DESC",
      label: "Most Recent",
    },
  ];

  const actionsMenuList = [
    {
      value: "Bulk Update",
      label: "Bulk Update",
    },
  ];

  // Use Effect
  useEffect(() => {
    isLoggedIn();
  }, []);

  // Add Product Category modal handler
  const handleAddVendor = () => {
    setAddVendorModal(!addVendorModal);
  };

  const toggleBulkUpdateModal = () => {
    setOpenBulkUpdateModal(!isOpenBulkUpdateModal);
  };

  let params = {
    status: Url.GetParam("status") ? Url.GetParam("status") : "",
    pagination: true,
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
    accountType: Url.GetParam("accountType"),
  };

  const handleSumbit = async (values) => {
    try {
      let data = new FormData();
      data.append("accountIds", selectedIds?.selectedIds);
      data.append("type", values && values?.type && values?.type?.value);
      const response = await AccountService.bulkUpdate(data);
      dispatch(
        fetchList(
          "allVendor",
          pathName == "/accounts"
            ? `${endpoints().accountAPI}/search`
            : pathName == "/customers"
              ? `${endpoints().accountAPI}/search`
              : `${endpoints().accountAPI}/vendorSearch`,
          params?.page ? params?.page : 1,
          params?.pageSize ? params?.pageSize : 25,
          {
            ...params,
          }
        )
      );
      Toast.success(response.message);
      toggleBulkUpdateModal();
      setSelectedIds("");
      setSelectedCheckBox(false);
      setSelectedCheckBox(true);
    } catch (err) {
      const res = err.response;
      res && Toast.error(res.data.message);
    }
  };

  const handleActionChange = (e) => {
    if (e == "Bulk Update") {
      if (
        selectedIds &&
        selectedIds.selectedIds &&
        selectedIds.selectedIds.length > 0
      ) {
        toggleBulkUpdateModal();
      }
    }
  };

  const onBulkSelect = (ids) => {
    setSelectedIds({ selectedIds: ids });
  };

  const bulkUpdateFooter = (
    <div>
      <SaveButton />
    </div>
  );

  const bulkUpdateBody = (
    <>
      <AccountTypeSelect name="type" label="Type" placeholder="Select Type" />
    </>
  );

  return (
    <>
      <div className="d-flex justify-content-between">
        <PageTitle
          label={
            pathName == "/accounts"
              ? "Accounts"
              : pathName == "/customers"
                ? "Customers"
                : "Vendors"
          }
        />
        <div className="d-flex">
          <AddButton
            onClick={(e) => {
              setRows("");
              setEditorState("");
              handleAddVendor();
            }}
            label="Add New"
          />
          {pathName === "/accounts" && (
            <div className="ms-2">
              <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            </div>
          )}
        </div>
      </div>
      <AddModal
        modalTitle="Bulk Update"
        modalBody={bulkUpdateBody}
        modalFooter={bulkUpdateFooter}
        isOpen={isOpenBulkUpdateModal}
        toggle={toggleBulkUpdateModal}
        toggleModalClose={toggleBulkUpdateModal}
        initialValues={{ accountCategory: "" }}
        hideDefaultButtons
        onSubmit={handleSumbit}
      />

      {/* Add Product Category modal */}
      <AddAccountModal
        accountCategory={
          pathName == "/vendor"
            ? Account.CATEGORY_VENDOR
            : pathName == "/customers"
              ? Account.CATEGORY_CUSTOMER
              : ""
        }
        toggle={addVendorModal}
        onModalClose={handleAddVendor}
        rows={rows}
        setRows={setRows}
        editorState={editorState}
        setEditorState={setEditorState}
        name={name}
        pathName={pathName}
        activeTab={activeTab}
      />

      <div className="bg-white mt-3">
        <VendorListPage
          id={"allVendor"}
          tab={activeTab}
          searchItem={searchitem}
          sortByOption={sortByOption}
          status={Url.GetParam("status") ? Url.GetParam("status") : ""}
          accountType={
            Url.GetParam("accountType") ? Url.GetParam("accountType") : ""
          }
          history={history}
          pathName={pathName}
          onModalClose={handleAddVendor}
          selectedCheckBox={selectedCheckBox}
          onBulkSelect={onBulkSelect}
          setRows={setRows}
          setEditorState={setEditorState}
          showStatus
        />
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}

const mapStateToProps = (state) => {
  const { vendor } = state.table;

  const sort = vendor && !vendor.isFetching ? vendor.sort : statusConstant.SORT;

  const sortDir =
    vendor && !vendor.isFetching ? vendor.sortDir : statusConstant.SORT_DIR;

  const pageSize =
    vendor && !vendor.isFetching ? vendor.pageSize : statusConstant.PAGESIZE;

  const currentPage =
    vendor && !vendor.isFetching ? vendor.currentPage : statusConstant.PAGE;

  const search = vendor && !vendor.isFetching ? vendor.search : "";

  const status =
    vendor && !vendor.isFetching ? vendor.status : statusConstant.ACTIVE;

  return {
    sort,
    sortDir,
    pageSize,
    currentPage,
    search,
    status,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
