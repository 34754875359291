import React, { useEffect, useState } from "react";

// Components
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SingleCheckbox from "../../components/SingleCheckbox";
import DefaultContent from "../../components/content/defaultContent";

// Lib
import ArrayList from "../../lib/ArrayList";
import { getKeyValueByObject } from "../../lib/Helper";

// Services
import { getCompanySettings, saveSetting } from "../../services/SettingService";

// Helpers
import { Setting } from "../../helpers/Setting";

const Otp = () => {
  const [settings, setSettings] = useState({});

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    setSettings(settingData);
  };

  useEffect(() => {
    getSettings();
  }, []);

  const initialValues = {
    allow_sms_notification:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.ALLOW_SMS_NOTIFICATION) == "true"
        ? true
        : false,
    allow_whatsapp_notification:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.ALLOW_WHATSAPP_NOTIFICATION) ==
        "true"
        ? true
        : false,
        allow_email_notification:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.ALLOW_EMAIL_NOTIFICATION) == "true"
        ? true
        : false,
  };

  const handleOtpCheckBoxValue = (values) => {
    const data = new FormData();

    if (values && values.allow_sms_notification !== undefined) {
      data.append(
        Setting.ALLOW_SMS_NOTIFICATION,
        values.allow_sms_notification
      );
    }
    if (values && values.allow_email_notification !== undefined) {
      data.append(
        Setting.ALLOW_EMAIL_NOTIFICATION,
        values.allow_email_notification
      );
    }
    if (values && values.allow_whatsapp_notification !== undefined) {
      data.append(
        Setting.ALLOW_WHATSAPP_NOTIFICATION,
        values.allow_whatsapp_notification
      );
    }

    // Save settings
    saveSetting(data, null, () => {
      getSettings();
    });
  };

  return (
    <>
      <PageTitle label="OTP Settings" />
      <DefaultContent>
        <Form enableReinitialize={true} initialValues={initialValues}>
          <div className="row field-wrapper mt-3">
            <div className="col-lg-12 col-sm-12">
              <SingleCheckbox
                name={Setting.ALLOW_SMS_NOTIFICATION}
                label={"SMS Notification"}
                handleOnChangeSubmit={(value, name) =>
                  handleOtpCheckBoxValue(value, name)
                }
              />
            </div>
          </div>{" "}
          <div className="row field-wrapper mt-3">
            <div className="col-lg-12 col-sm-12">
              <SingleCheckbox
                name={Setting.ALLOW_EMAIL_NOTIFICATION}
                label={"Email Notification"}
                handleOnChangeSubmit={(value, name) =>
                  handleOtpCheckBoxValue(value, name)
                }
              />
            </div>
          </div>{" "}
          <div className="row field-wrapper mt-3">
            <div className="col-lg-12 col-sm-12">
              <SingleCheckbox
                name={Setting.ALLOW_WHATSAPP_NOTIFICATION}
                label={"WhatsApp Notification"}
                handleOnChangeSubmit={(value, name) =>
                  handleOtpCheckBoxValue(value, name)
                }
              />
            </div>
          </div>{" "}
        </Form>
      </DefaultContent>
    </>
  );
};

export default Otp;
