import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import BreadCrumb from "../../components/Breadcrumb";
import LocationPerformanceGraph from "./components/LocationPerformanceGraph";
import LocationPerformanceReportFilter from "./components/Filter";
import Currency from "../../lib/Currency";

const LocationPerformanceReport = (props) => {
    let { history } = props;
    const [graphData, setGraphData] = useState({});
    const [isLoading, setIsLoading] = useState(false)

    const breadcrumbList = [
        { label: "Home", link: "/locationDashboard" },
        {
            label: "Reports",
            link: "/report",
        },
        {
            label: "Location Performance Report",
            link: "",
        },
    ];


    return (
        <div>
            <BreadCrumb list={breadcrumbList} />
            <PageTitle label="Location Performance Report" />
            <LocationPerformanceReportFilter history={history} setGraphData={setGraphData} setIsLoading={setIsLoading} />
            {/* ✴---Order Graph---✴ */}
            <LocationPerformanceGraph title={`Order Total Amount: ${Currency.GetFormatted(graphData?.orderGraphData?.totalAmount)}`} isLoading={isLoading} history={history} graphArrayData={graphData?.orderGraphData?.data}  key1="location" key2="total_amount" />

             {/* ✴---Order Product Cancelled Graph---✴ */}
             <LocationPerformanceGraph title={`Cancelled Order Total Amount: ${Currency.GetFormatted(graphData?.cancelledOrderProductGraphData?.totalAmount)}`}  isLoading={isLoading} history={history} graphArrayData={graphData?.cancelledOrderProductGraphData?.data}  key1="location_name" key2="amount" />
        
            {/* ✴---Transfer Graph---✴ */}
            <LocationPerformanceGraph isQuantityWise={true} title={`Transfer Total Quantity: ${graphData?.transferReportData?.totalQuantity}`}  isLoading={isLoading} history={history} graphArrayData={graphData?.transferReportData?.data}  key1="location_name" key2="total_quantity" />

            {/* ✴---Transfer Excess Graph---✴ */}
            <LocationPerformanceGraph isQuantityWise={true} title={`Transfer Excess Total Quantity: ${graphData?.excessReturnReportData?.totalQuantity}`}  isLoading={isLoading} history={history} graphArrayData={graphData?.excessReturnReportData?.data}  key1="location_name" key2="total_quantity" />

             {/* ✴---Transfer Expired Graph---✴ */}
             <LocationPerformanceGraph isQuantityWise={true} title={`Transfer Expired Total Quantity: ${graphData?.expiredReturnReportData?.totalQuantity}`}  isLoading={isLoading} history={history} graphArrayData={graphData?.expiredReturnReportData?.data}  key1="location_name" key2="total_quantity" />
        </div>
    );
};

export default LocationPerformanceReport;
