import { fetchList } from "../actions/table";
import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import UserCard from "../components/UserCard";
import ArrayList from "../lib/ArrayList";
import { isBadRequest } from "../lib/Http";
import String from "../lib/String";
import Url from "../lib/Url";

 const REQUEST_UPDATE_MESSAGE = "REQUEST_UPDATE_MESSAGE";

 function requestUpdateMessage() {
  return {
    type: REQUEST_UPDATE_MESSAGE,
  };
}

class MessagesService {
  static async Create(data) {
    try {
      if (data) {
        let response = await apiClient.post(
          `${endpoints().messageAPI}/create`,
          data
        );
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async getMessages(id) {
    try {
      if (id) {
        let response = await apiClient.get(`${endpoints().messageAPI}/${id}`);
        return response;
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async search(params) {
    try {
      let queryString = await ArrayList.toQueryString(params);
      let response = await Url.get(`${endpoints().messageAPI}/search`, queryString)
      return response;
    } catch (err) {
      console.log(err);
      throw err
    }
  }

  static update = (data) => {
    return (dispatch) => {
      dispatch(requestUpdateMessage());
      apiClient
        .put(`${endpoints().messageAPI}`,data)
        .then(() => {
          dispatch(
            fetchList(
              "navBar",
              `${endpoints().messageAPI}/search`,
              1,
              25,
            )
          );
        })
    };
  }
  static async unRead() {
    try {
      let response = await Url.get(`${endpoints().messageAPI}/unRead`)
      return response;
    } catch (err) {
      console.log(err);
      throw err
    }
  }

  static delete = (messageId, callback) => {
      apiClient
        .delete(`${endpoints().messageAPI}/delete/${messageId}`)
        .then((res) => {
          return callback && callback(null, res)
        }).catch((error)=>{
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(error.response.data.message);
            console.error(errorMessage);
            return callback && callback(errorMessage, null)
          }
        })
  }

  static forward = async (forwardData, callback) => {
      await apiClient
        .put(`${endpoints().messageAPI}/forward`,forwardData)
        .then((res) => {
           return callback && callback(null, res)
        })
  }
  static async getUserName(media_url, firstName, lastName,locationName, shiftName) {
      return (
        <div className="d-flex">
          <UserCard
            id="avatar"
            firstName={firstName}
            lastName={lastName}
            url={media_url}
            locationName={locationName}
            shiftName={shiftName}
          />
        </div>
      );
    }

  static async bulkDelete(selectedId, toggle, params) {
    try {
      return (dispatch) => {
        dispatch(requestUpdateMessage());
        apiClient
          .delete(`${endpoints().messageAPI}/bulkDelete`, {
            data: selectedId,
          })
          .then((response) => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
            }
          })
          .then(() => {
            dispatch(
              fetchList(
                "message",
                `${endpoints().messageAPI}/list`,
                params?.page ? params?.page : 1,
                params?.pageSize ? params?.pageSize : 25,
                params
              )
            );
          })
          .catch((error) => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
              console.error(errorMessage);
            }
          });
      };
    } catch (err) { }
  }

  static async list(params) {
    try {
      let List=[]
      let queryString = await ArrayList.toQueryString(params);
      let response = await Url.get(`${endpoints().messageAPI}/userList`, queryString)
      if (response && response?.data?.data?.length > 0) {
        response?.data?.data.forEach(async (list) => {
          List.push({
            value: `${list.first_name} ${list?.showLocationShiftPermission && list?.current_location_name ? `${list?.current_location_name}` : ""}${list?.showLocationShiftPermission && list?.current_shift_name ? `, ${list?.current_shift_name}` : ""}`,
            id: list.id,
            label: await this.getUserName(
              list?.media_url ,
              list.first_name || "",
              String.Get(list?.last_name) ||"",
              list?.showLocationShiftPermission ? String.Get(list?.current_location_name) :"",
              list?.showLocationShiftPermission ? String.Get(list?.current_shift_name) :"",
            ),
            media_url: list?.media_url,
            first_name: list?.first_name ||"",
            last_name: String.Get(list?.last_name) || "",
          });
        });
      }
      return List;
    } catch (err) {
      console.log(err);
      throw err
    }
  }
}
export default MessagesService;
