import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Components
import Action from "../../components/Action";
import ActivityList from "../../components/ActivityList";
import BreadCrumb from "../../components/Breadcrumb";
import CancelButton from "../../components/CancelButton";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Spinner from "../../components/Spinner";
import StatusComponent from "../../components/Status";
import Toast from "../../components/Toast";
import SaleSettlementForm from "./components/saleSettlementForm";
import CurrencyDenominationTab from "./components/CurrencyDenominationTab";
import OrderList from "../order/components/OrderList";
import FineList from "../fine/components/FineList";

// Lib
import ArrayList from "../../lib/ArrayList";
import DateTime from "../../lib/DateTime";
import { isBadRequest, SUCCESS_RESPONSE } from "../../lib/Http";
import Number from "../../lib/Number";
import String from "../../lib/String";

// Api
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Constants
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";
import Urls from "../../helpers/Url";
import { CUSTOM_VALUE, TODAY_VALUE } from "../../helpers/Date";

// Services
import SaleSettlementService from "../../services/SaleSettlementService";
import ShiftService from "../../services/ShiftService";
import StatusService from "../../services/StatusService";
import { hasPermission } from "../../services/UserRolePermissionService";
import UserService from "../../services/userServices";

/* Sales Settlement tabs */
const Tab = {
  DETAIL: "Detail",
  PRODUCTS: "Products",
  HISTORY: "History",
  ORDERS: "Orders",
  FINE: "Fines",
};

const Detail = (props) => {
  const { history } = props;
  const [locationList, setLocationList] = useState([]);
  const [detail, setDetail] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [salesExecutive, setSalesExecutive] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [amountCash, setAmountCash] = useState();
  const [amountUpi, setAmountUpi] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [amount_cash, setCash] = useState();
  const [amount_upi, setUpi] = useState();
  const [location_name, setLocationName] = useState();
  const [shift, setShift] = useState();
  const [sales_executive, setsalesExecutive] = useState("");
  const [totalCaculatedAmount, setCalculatedTotalAmount] = useState();
  const [receivedDiscrepancyAmountCash, setReceivedDiscrepancyAmountCash] =
    useState(0);
  const [receivedDiscrepancyAmountUpi, setReceivedDiscrepancyAmountUpi] =
    useState(0);
  const [cashReceived, setReceivedAmountCash] = useState();
  const [upiReceived, setReceivedAmountUpi] = useState();
  const [totalReceivedAmount, setReceivedTotalAmount] = useState();
  const [date, setDate] = useState();
  const [cashInStore, setCashInStore] = useState();
  const [notes, setNotes] = useState("");
  const [selectedStatus, setSelectedStatus] = useState();
  const [PermissionList, setPermissionList] = useState();
  const [shiftList, setShiftList] = useState("");
  const [statusSelected, setStatus] = useState("");
  const [inActiveSaleExecutive, setInActiveSaleExecutive] = useState([]);
  const [cashToOffice, setCashToOffice] = useState();
  const Param = new URLSearchParams(props.history.location.search);
  const tab = Param.get("tab");
  const [activeTab, setActiveTab] = useState(tab ? tab : Tab.DETAIL);
  const [editable, setEditable] = useState(true);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [discrepancyCash, setDiscrepancyCash] = useState();
  const [discrepancyUpi, setDiscrepancyUpi] = useState();
  const [dueDate, setDueDate] = useState();
  const [reviewer, setReviewer] = useState();
  const [userList, setUserList] = useState([]);

  let showHistory = hasPermission(Permission.SALE_SETTLEMENT_HISTORY_VIEW);

  let showEditButton = hasPermission(Permission.SALE_SETTLEMENT_EDIT);
  const dispatch = useDispatch();
  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    { label: "Sales Settlements", link: Urls.SALES_SETTLEMENT_LIST },
    { label: "Sales Settlement Detail", link: "" },
  ];
  // Sales entry id
  const id = props.match.params.id;

  //   Get Status List
  const getStatusDetail = async (id) => {
    const data = await StatusService.get(id);

    setStatus(data?.data.name);
  };

  const getShift = async () => {
    const list = await ShiftService.getShiftList();
    setShiftList(list);
  };

  const getUserRoleValue = async () => {
    const data = await UserService.getSalesExecutive();
    let result = data.activeList;
    const userList = [];
    result &&
      result.forEach((list) => {
        userList.push({
          value: list.id,
          label: list?.label,
        });
      });
    setSalesExecutive(userList);
    setInActiveSaleExecutive(data.inActiveList);
  };
  const handleReviewerChange = (e) => {
    let value = e && e.id;
    setReviewer(value);
  };

  const handleDueDateChange = (values) => {
    setDueDate(values);
  };
  // Get Sales Details
  const getDetails = async () => {
    const response = await apiClient.get(
      `${endpoints().saleSettlementAPI}/${id}`
    );
    setDetail(() => response.data);
    setDiscrepancyCash(
      response && response.data && response.data.discrepancy_amount_cash
    );
    setDiscrepancyUpi(
      response && response.data && response.data.discrepancy_amount_upi
    );
    setReceivedDiscrepancyAmountCash(
      response && response.data && response.data.calculated_amount_cash
    );
    setReceivedDiscrepancyAmountUpi(
      response && response.data && response.data.calculated_amount_upi
    );
    setCalculatedTotalAmount(
      response && response.data && response.data.total_calculated_amount
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getDetails();
    getShift();
    getUserRoleValue();
    getRolePermissions();
  }, []);

  const statusOptions = [
    {
      value: "Draft",
      label: "Draft",
    },
    {
      value: "Review",
      label: "Review",
    },
    {
      value: "Complete",
      label: "Complete",
    },
  ];

  // Actions menu list
  const actionOptions = [
    {
      label: "Update Order Amount",
      value: "Update Order Amount",
    },
    {
      label: "Delete",
      value: "Delete",
    },
  ];

  if (showEditButton && editable && activeTab === Tab.DETAIL) {
    actionOptions.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const _handleTabChange = (tab) => {
    if (tab == Tab.ORDERS) {
      props.history.push(
        `?startDate=${detail?.date}&endDate=${detail?.date}&shift=${detail?.shift}&location=${detail?.store_id}&date=${CUSTOM_VALUE}`
      );
    } else {
      props.history.push(`?tab=${tab}`);
    }
  };

  const updateOrderAmount = async () => {
    try {
      const data = new FormData();
      // status
      data.append("id", id);
      await apiClient
        .post(`${endpoints().saleSettlementAPI}/updateOrderAmount`, data)
        .then((response) => {
          if (response && response.data) {
            Toast.success(response.data.message);
            getDetails()
          }
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "Delete") {
      setDeleteModal(true);
    }
    if (e == "Update Order Amount") {
      updateOrderAmount();
    }
  };

  const handleChangeStatus = async (selectStatus) => {
    dispatch(await SaleSettlementService.updateStatus(id, selectStatus, {}));
  };
  // Toggling the tab
  const tabToggle = (tab) => {
    setActiveTab(tab);
  };

  const deleteSaleSettements = async () => {
    dispatch(await SaleSettlementService.delete(id));
    history.push(`/salessettlement?date=${TODAY_VALUE}`);
  };

  //Get Role Permission
  const getRolePermissions = async () => {
    const statusPermission = hasPermission(
      Permission.SALE_SETTLEMENT_STATUS_UPDATE
    );
    setPermissionList(statusPermission);
    setIsLoading(false);
  };

  // Handle status change
  const handleStatusChange = (status) => {
    const data = new FormData();
    // status
    data.append("status", status ? status : "");

    try {
      if (
        detail?.received_amount_cash == null &&
        detail?.received_amount_upi == null
      ) {
        Toast.error("Fill The Required Fields");
      }
      apiClient
        .put(`${endpoints().saleSettlementAPI}/status/${id}`, data)
        .then((res) => {
          if (res.status == SUCCESS_RESPONSE) {
            Toast.success(res?.data?.message);
          }
          getDetails(id);
          setSelectedStatus("");
        })
        .catch((err) => {
          if (isBadRequest(err)) {
            let errorMessage;
            const errorRequest = err.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  // Handle form Submit
  const handleSubmit = async (values) => {
    try {
      const data = new Object();

      // location id
      data.storeId = values?.location?.id;

      // date
      data.date = DateTime.toISOStringDate(values?.date);

      // Shift
      data.shift = values?.shift.value;

      // Type
      data.type = values && String.Get(values.type);

      // Amount
      data.amount = values.amount && Number.Float(values.amount);

      data.amount_upi = values.amount_upi && Number.Float(values.amount_upi);

      data.amount_cash = values.amount_cash && Number.Float(values.amount_cash);

      data.discrepancy_amount_cash = Number.Float(values.discrepancy_amount_cash)

      data.discrepancy_amount_upi = Number.Float(values.discrepancy_amount_upi)


      data.calculated_amount_cash = Number.Float(values.calculated_amount_cash)

      data.calculated_amount_upi = Number.Float(values.calculated_amount_upi)

      data.received_amount_upi = Number.Float(values.received_amount_upi)

      data.cash_in_store = cashInStore
        ? cashInStore
        : values.cash_in_store
          ? values.cash_in_store
          : "";

      data.cash_to_office = cashToOffice
        ? cashToOffice
        : values.cash_to_office
          ? values.cash_to_office
          : "";

      data.received_amount_cash = Number.Float(values.received_amount_cash)

      data.salesExecutive =
        values &&
        values.salesExecutive &&
        Number.Get(values.salesExecutive.value);
      // Notes
      data.notes = String.Get(values.notes);

      data.status = selectedStatus ? selectedStatus : values.status.label;
      data.due_date = values.due_date ? values.due_date : null;
      data.reviewer =
        values.reviewer && values.reviewer ? values.reviewer.id : null;

      apiClient
        .put(`${endpoints().saleSettlementAPI}/${id}`, data)
        .then((res) => {
          if (res.status == SUCCESS_RESPONSE) {
            Toast.success(res?.data?.message);
          }
          setIsLoading(true);
          getDetails(id);
          setIsLoading(false);
          setEditable(true);
        })
        .catch((err) => {
          if (isBadRequest(err)) {
            let errorMessage;
            const errorRequest = err.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
            setEditable(true);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  const onCashChange = (e) => {
    const cashAmount = e.values.amount_cash ? e.values.amount_cash : " ";
    let totalAmounts =
      (Number.Float(cashAmount) ? Number.Float(cashAmount) : null) +
      Number.Float(amountUpi ? amountUpi : detail.amount_upi);
    setTotalAmount(totalAmounts);
    setDiscrepancyCash(
      Number.Get(cashAmount) - Number.Get(detail?.order_cash_amount)
    );
    setAmountCash(cashAmount);
    setCash(cashAmount);
  };

  const onUpiChange = (e) => {
    const upiAmount = e.values.amount_upi ? e.values.amount_upi : " ";
    let total_amount =
      (Number.Float(upiAmount) ? Number.Float(upiAmount) : null) +
      Number.Float(amountCash ? amountCash : detail.amount_cash);
    setTotalAmount(total_amount);
    setDiscrepancyUpi(
      Number.Get(upiAmount) - Number.Get(detail?.order_upi_amount)
    );
    setAmountUpi(upiAmount);
    setUpi(upiAmount);
  };

  const onStoreChange = (e) => {
    const locationName = e?.id ? e?.id : "";
    setLocationName(locationName);
  };

  const onShiftChange = (e) => {
    const shiftValue = e.values.shift.value;
    setShift(shiftValue);
  };

  const onSalesExecutiveChange = (e) => {
    const sales_executive = e?.value ? e?.value : "";
    setsalesExecutive(sales_executive);
  };

  const onReceivedCashChange = (e) => {
    const receivedCash = e.values.received_amount_cash
      ? e.values.received_amount_cash
      : " ";
    let total_amount =
      (Number.Float(receivedCash) ? Number.Float(receivedCash) : null) +
      Number.Float(upiReceived ? upiReceived : detail.received_amount_upi);
    setReceivedTotalAmount(total_amount);
    setReceivedDiscrepancyAmountCash(
      Number.Float(receivedCash) - Number.Float(detail.order_cash_amount)
    );
    let totalAmounts =
      Number.Float(receivedCash) -
      Number.Float(detail.order_cash_amount) +
      Number.Get(receivedDiscrepancyAmountUpi);
    setCalculatedTotalAmount(totalAmounts);
    setReceivedAmountCash(receivedCash);
  };

  const onReceivedUpiChange = (e) => {
    const receivedUpi = e.values.received_amount_upi
      ? e.values.received_amount_upi
      : " ";
    let totalAmounts =
      (Number.Float(receivedUpi) ? Number.Float(receivedUpi) : null) +
      Number.Float(cashReceived ? cashReceived : detail.received_amount_cash);
    setReceivedTotalAmount(totalAmounts);
    setReceivedDiscrepancyAmountUpi(
      Number.Float(receivedUpi) - Number.Float(detail.order_upi_amount)
    );
    let totalAmount =
      Number.Float(receivedUpi) -
      Number.Float(detail.order_upi_amount) +
      Number.Float(receivedDiscrepancyAmountCash);

    setCalculatedTotalAmount(totalAmount);
    setReceivedAmountUpi(receivedUpi);
  };

  const totalDiscrepancy =
    Number.Float(discrepancyCash) + Number.Float(discrepancyUpi);

  const onDateChange = (e) => {
    setDate(e);
  };

  const onStoreCashChange = (e) => {
    const storerCash = e.values.cash_in_store ? e.values.cash_in_store : "";
    setCashInStore(storerCash);
  };

  const onNotesChange = (e) => {
    let note = e.target.value;
    setNotes(note);
  };

  const onCashToOfficeChange = (x) => {
    const data = x.values.cash_to_office;
    setCashToOffice(data);
  };

  let salesExecutiveId = sales_executive
    ? sales_executive
    : detail.sales_executive;

  let salesExecutives =
    ArrayList.isArray(salesExecutive) &&
      salesExecutive.find((data) => data.value == salesExecutiveId)
      ? salesExecutive.find((data) => data.value == salesExecutiveId)
      : ArrayList.isArray(inActiveSaleExecutive) &&
      inActiveSaleExecutive.find((data) => data.value == salesExecutiveId);

  // Form initial values
  const initialValues = {
    location: locationList.find((data) => data.id == location_name)
      ? locationList.find((data) => data.id == location_name)
      : Array.isArray(locationList) &&
      locationList.find((data) => data.id == detail?.store_id),
    date: date ? date : DateTime.getDateTimeByUserProfileTimezone(detail?.date),
    shift:
      shiftList && shiftList.find((data) => data.id == shift)
        ? shiftList && shiftList.find((data) => data.id == shift)
        : Array.isArray(shiftList) &&
        shiftList.find((data) => data.id == detail?.shift),
    type: detail?.type,
    amount: detail?.amount,
    amount_cash: amountCash ? amountCash : detail?.amount_cash,
    amount_upi: amountUpi ? amountUpi : detail?.amount_upi,
    discrepancy_amount_cash: discrepancyCash,
    discrepancy_amount_upi: discrepancyUpi,
    calculated_amount_cash:
      receivedDiscrepancyAmountCash == 0 || receivedDiscrepancyAmountCash !== 0
        ? receivedDiscrepancyAmountCash
        : detail?.calculated_amount_cash,
    calculated_amount_upi:
      receivedDiscrepancyAmountUpi == 0 || receivedDiscrepancyAmountUpi !== 0
        ? receivedDiscrepancyAmountUpi
        : detail?.calculated_amount_upi,
    received_amount_cash: cashReceived
      ? cashReceived
      : detail?.received_amount_cash,
    received_amount_upi: upiReceived
      ? upiReceived
      : detail?.received_amount_upi,
    cash_in_store:
      cashInStore === ""
        ? ""
        : cashInStore
          ? cashInStore
          : detail?.cash_in_store,
    cash_to_office:
      cashToOffice == ""
        ? ""
        : cashToOffice
          ? cashToOffice
          : detail?.cash_to_office,
    salesExecutive: salesExecutives,
    notes: notes ? notes : detail?.notes,
    total_amount: totalAmount ? totalAmount : detail?.total_amount,
    total_calculated_amount:
      totalCaculatedAmount == 0 || totalCaculatedAmount !== 0
        ? totalCaculatedAmount
        : detail?.total_calculated_amount,
    total_received_amount: totalReceivedAmount
      ? totalReceivedAmount
      : detail?.total_received_amount,
    total_discrepancy_amount: totalDiscrepancy
      ? totalDiscrepancy
      : detail?.total_discrepancy_amount,
    status: selectedStatus ? selectedStatus : { label: detail?.status },
    order_total_amount: detail.order_total_amount,
    order_cash_amount: detail.order_cash_amount,
    order_upi_amount: detail.order_upi_amount,
    due_date: dueDate ? dueDate : detail.due_date,
    reviewer: userList.find(
      (value) => value.id == (reviewer || detail.reviewer)
    ),
    draft_order_amount: detail?.draft_order_amount
      ? detail?.draft_order_amount
      : "",
  };

  const onStatusChange = (value) => {
    getStatusDetail(value);

    if (value) {
      handleStatusChange(value);
    }
  };

  const openToggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      <DeleteModal
        id={detail?.store_id}
        label={detail?.sale_settlement_number}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Product "
        deleteFunction={deleteSaleSettements}
      />

      {/* Breadd Crumb Section */}
      <BreadCrumb list={breadcrumbList} />

      {/* Page title */}

      <div className="d-flex nav justify-content-between">
        <PageTitle
          label={`Sales Settlement #${detail?.sale_settlement_number}`}
        />
        <div className="d-flex me-2  justify-content-between">
          <StatusComponent
            objectName={ObjectName.SALE_SETTLEMENT}
            handleChange={onStatusChange}
            buttonLabel={detail?.status}
            currentStatusId={detail?.statusId}
          />
          <div className="ps-1">
            <Action dropdownLinks={actionOptions} handleChange={handleChange} />
          </div>
        </div>
      </div>

      <div>
        <Nav tabs className="admin-tabs">
          {/* Detail Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.DETAIL,
              })}
              onClick={() => {
                tabToggle(Tab.DETAIL);
                _handleTabChange(Tab.DETAIL);
              }}
            >
              {Tab.DETAIL}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.ORDERS,
              })}
              onClick={() => {
                tabToggle(Tab.ORDERS);
                _handleTabChange(Tab.ORDERS);
              }}
            >
              {Tab.ORDERS}
            </NavLink>
          </NavItem>

          {/* Fine Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.FINE,
              })}
              onClick={() => {
                tabToggle(Tab.FINE);
                _handleTabChange(Tab.FINE);
              }}
            >
              {Tab.FINE}
            </NavLink>
          </NavItem>

          {/* History Tab */}
          <NavItem>
            {showHistory && (
              <NavLink
                className={classNames({
                  active: activeTab === Tab.HISTORY,
                })}
                onClick={() => {
                  tabToggle(Tab.HISTORY);
                  _handleTabChange(Tab.HISTORY);
                }}
              >
                {Tab.HISTORY}
              </NavLink>
            )}
          </NavItem>
        </Nav>
      </div>

      {/* Form */}
      <div className="card mt-3">
        <div className="card-body ">
          {/* Tab Content section */}
          <TabContent activeTab={activeTab}>
            {/* Detail Tab Start*/}
            {activeTab == Tab.DETAIL && (
              <TabPane tabId={Tab.DETAIL} className="w-100">
                <Form
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  <SaleSettlementForm
                    location={locationList}
                    id={id}
                    showDiscrepancy={true}
                    shiftList={shiftList}
                    salesExecutive={salesExecutive}
                    showCalculatedAmount={true}
                    showReceivedAmount={true}
                    showMediaTab={true}
                    showCashInStore={true}
                    onCashChange={onCashChange}
                    onUpiChange={onUpiChange}
                    onStoreChange={onStoreChange}
                    onShiftChange={onShiftChange}
                    onSalesExecutiveChange={onSalesExecutiveChange}
                    onReceivedCashChange={onReceivedCashChange}
                    onReceivedUpiChange={onReceivedUpiChange}
                    onDateChange={onDateChange}
                    onStoreCashChange={onStoreCashChange}
                    onNotesChange={onNotesChange}
                    notes={notes}
                    statusOptions={statusOptions}
                    status={true}
                    handleChange={(e) => handleChangeStatus(e)}
                    PermissionList={PermissionList}
                    saleStatus={detail?.status}
                    showProductCount={true}
                    statusSelected={statusSelected}
                    onCashToOfficeChange={onCashToOfficeChange}
                    editable={editable}
                    showUserDetailsPageLink={
                      detail?.sales_executive ? true : false
                    }
                    userId={detail?.sales_executive}
                    userList={setUserList}
                    handleReviewerChange={handleReviewerChange}
                    handleDueDateChange={handleDueDateChange}
                    reviewer={detail?.reviewer}
                    setLocationList={setLocationList}
                    locationId={detail?.store_id}
                  />
                  <div>
                    {!editable && (
                      <>
                        <SaveButton label="Save" />
                        <CancelButton
                          onClick={() => props.history.push(Urls.SALES_SETTLEMENT_LIST)}
                        />
                      </>
                    )}
                  </div>
                </Form>

                <CurrencyDenominationTab
                  openToggle={openToggle}
                  isModelOpen={isModelOpen}
                  setIsModelOpen={setIsModelOpen}
                  id={id}
                  history={history}
                />
              </TabPane>
            )}
            {/* History Tab Start*/}
            {showHistory && activeTab == Tab.HISTORY && (
              <TabPane tabId={Tab.HISTORY} className="w-100">
                <ActivityList
                  id={id}
                  objectId={id}
                  object_name={ObjectName.SALE_SETTLEMENT}
                />
              </TabPane>
            )}
          </TabContent>
          {activeTab == Tab.ORDERS && (
            <TabPane tabId={Tab.ORDERS} className="w-100">
              <OrderList history={history} params={{ showTotalAmount: true, objectName: ObjectName.ORDER_TYPE }} />
            </TabPane>
          )}
          {activeTab == Tab.FINE && (
            <TabPane tabId={Tab.FINE} className="w-100">
              <FineList
                history={history}
                bulkSelect={false}
                showSearch={false}
                param={{ object_id: id, object_name: ObjectName.SALE_SETTLEMENT }}
                showActionColumn={false}
                apiURL={endpoints().fineApi}
                objectName={ObjectName.FINE}
              />
            </TabPane>
          )}
        </div>
      </div>
    </>
  );
};

export default Detail;
