import React from "react";

// Components
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import BreadCrumb from "../../components/Breadcrumb";
import ProductCard from "../../views/product/components/productCard";

//css
import "../../scss/_custom.scss";

// API
import { endpoints } from "../../api/endPoints";

// Helpers
import { TagTypeName } from "../../helpers/Tag";

const Report = (props) => {

  const sortByOption = [
    {
      value: "quantity:DESC",
      label: "Quantity",
    },

  ];

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report",
    },
    {
      label: "Purchase Products Report",
      link: "",
    },
  ];

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Purchase Products Report" />
      </div>

      <div className="mt-4">
        <ReduxTable
          id="productReport"
          showHeader
          newTableHeading
          searchPlaceholder="Search"
          paramsToUrl={true}
          sortByOptions={sortByOption}
          params={{}}
          apiURL={`${endpoints().purchaseProductReportAPI}/search`}
          showCustomDateFilter
          showStoreFilter
          showAccountFilter
          showCategoryFilter
          showBrandFilter
          tagFilterType={{ type: TagTypeName.PRODUCT }}
          showTagFilter
          history={props.history}
        >
          <ReduxColumn
            field="vendor"
            sortBy="vendor"
            width="140px"
            minWidth="140px"
            maxWidth="140px"
          >
            Vendor
          </ReduxColumn>
          <ReduxColumn
            field="product_name"
            sortBy="product_name"
            width="310px"
            minWidth="310px"
            maxWidth="310px"
            renderField={(row) => (
              <>
                <ProductCard
                  productImageIcon
                  square
                  productName={row.product_name}
                  brandName={row.brand_name}
                  salePrice={row.sale_price}
                  size={row.size}
                  unit={row.unit}
                  url={row.image}
                  id={row.product_id}
                  brand_id={row.brand_id}
                />
              </>
            )}
          >
            Product
          </ReduxColumn>
          <ReduxColumn
            field="quantity"
            className="text-center"
            sortBy="quantity"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
          >
            Quantity
          </ReduxColumn>
        </ReduxTable>
      </div>
      {/* Graph Table End */}
    </>
  );
};


export default Report;
