import React, { useEffect, useState } from "react";

//components
import Form from "../../components/Form";

import SingleCheckbox from "../../components/SingleCheckbox";

import { getSetings, saveSetting } from "../../services/SettingService";

import { Setting } from "../../helpers/Setting";

import { getKeyValueByObject } from "../../lib/Helper";

import ArrayList from "../../lib/ArrayList";

import MultiSelect from "../../components/Multiselect";
import Numbers from "../../components/Number";
import SaveButton from "../../components/SaveButton";
import StoryPointSelect from "../../components/StoryPointSelect";
import Text from "../../components/Text";
import ObjectName from "../../helpers/ObjectName";
import DateTime from "../../lib/DateTime";
import Number from "../../lib/Number";
import ShiftService from "../../services/ShiftService";
import StoreSelector from "../location/components/storeSelector";
import orderTypeService from "../../services/OrderTypeService";
import AccountTypeSelect from "../../components/AccountTypeSelect";

function Checkbox({ day, checked, onChange }) {
  return (
    <label>
      <input
        className="me-2 ms-2"
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(day, e.target.checked)}
      />
      {day}
    </label>
  );
}

const RoleSetting = (props) => {
  const [settings, setSettings] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [minWorkHours, setMinWorkHours] = useState(null);
  const [minStoryPoints, setMinStoryPoints] = useState(null);
  const [shift, setShift] = useState(null);
  const [loginTime, setLoginTime] = useState(null);
  const [shiftList, setShiftList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [logoutHours, setLogoutHours] = useState(null);
  const [orderTypes, setOrderTypes] = useState([]);
  const [accountTypes, setAccountType] = useState([]);

  useEffect(() => {
    getSettings();
    getShift();
    getOrderTypes()

  }, [props]);

  const getOrderTypes=async ()=>{
    let response = await orderTypeService.list();
    let data = response && response?.data
  setOrderTypes(data)
  }
  const generateMinutesOptions = (start, end, interval) => {
    const options = [];
    for (let i = start; i <= end; i += interval) {
      options.push({
        label: `${i} minute${i !== 1 ? "s" : ""}`,
        value: i,
      });
    }
    return options;
  };

  // Get Shift List
  const getShift = async () => {
    const list = await ShiftService.getShiftLists();
    setShiftList(list);
  };
  const getSettings = async () => {
    //get company id from cookie
    let params = { object_id: Number.Get(props?.roleId) };
    //get company setting
    const settingData = await getSetings(params);

    //set setting in state
    setSettings(settingData);
    setSelectedDays(
      getKeyValueByObject(settingData, Setting.USER_WORKING_DAYS).split(",")
    );
  };

  const convertTimeStringToDate = (timeString) => {
    const [hours, minutes] = timeString && timeString.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  };

  const minimumStoryPoints = minStoryPoints
    ? minStoryPoints
    : settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.SETTING_MINIMUM_STORY_POINTS)
    ? getKeyValueByObject(settings, Setting.SETTING_MINIMUM_STORY_POINTS)
    : null;

    const accountType = settings &&
    ArrayList.isNotEmpty(settings) &&
    getKeyValueByObject(settings, Setting.ACCOUNT_TYPE)
    ? getKeyValueByObject(settings, Setting.ACCOUNT_TYPE)
    : null;
    let allowedShifts = getKeyValueByObject(settings, Setting.ROLE_ALLOWED_SHIFT);
  let shiftValue = [];
  if (allowedShifts) {
    let shidtData = allowedShifts && allowedShifts.split(",");
    shidtData.forEach((value) => {
      let data = shiftList && shiftList.find((data) => data?.value == value);
      shiftValue.push(data);
    });
  }

  let allowedOrderTypes = getKeyValueByObject(settings, Setting.ALLOWED_ORDER_TYPES);
  let orderTypesValue = [];
  if (allowedOrderTypes) {
    let orderTypeData = allowedOrderTypes && allowedOrderTypes.split(",");

    orderTypeData.forEach((value) => {

      let data = orderTypes && orderTypes.find((data) => data?.value == value);
      orderTypesValue.push(data);
    });
  }
  let allowedLocation = getKeyValueByObject(
    settings,
    Setting.ROLE_ALLOWED_LOCATIONS
  );
  let locationValue = [];
  if (allowedLocation) {
    let locationData = allowedLocation && allowedLocation.split(",");
    locationData.forEach((value) => {
      let data = storeList && storeList.find((data) => data?.id == value);
      locationValue.push(data);
    });
  }

  // Initial values
  const initialValues = {
    validate_sales_settlement_on_attendance_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.SETTING_VALIDATE_SALES_SETTLEMENT_ON_ATTENDANCE_CHECKOUT
      ) == "true"
        ? true
        : false,
        allow_additional_leave_add:
        settings &&
        ArrayList.isNotEmpty(settings) &&
        getKeyValueByObject(
          settings,
          Setting.ALLOW_ADDITIONAL_LEAVE_ADD
        ) == "true"
          ? true
          : false,
          account_type:
        accountTypes && accountTypes.find(value => value.value == accountType),
        validate_pending_sales_settlement_on_attendance_checkout:
        settings &&
        ArrayList.isNotEmpty(settings) &&
        getKeyValueByObject(
          settings,
          Setting.VALIDATE_PENDING_SALES_SETTLEMENT_ON_ATTENDANCE_CHECKOUT
        ) == "true"
          ? true
          : false,
          validate_shift_time_on_sale_settlement_add:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.VALIDATE_SHIFT_TIME_ON_SALE_SETTLEMENT_ADD
      ) == "true"
        ? true
        : false,
        allow_replenishment:
        settings &&
        ArrayList.isNotEmpty(settings) &&
        getKeyValueByObject(
          settings,
          Setting.ALLOW_REPLENISHMENT
        ) == "true"
          ? true
          : false,
        validate_ip_address_on_checkin:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.VALIDATE_IP_ADDRESS_ON_CHECKIN
      ) == "true"
        ? true
        : false,
        validate_ip_address_on_checkout:
        settings &&
        ArrayList.isNotEmpty(settings) &&
        getKeyValueByObject(
          settings,
          Setting.VALIDATE_IP_ADDRESS_ON_CHECKOUT
        ) == "true"
          ? true
          : false,
    validate_pending_ticket_on_attendance_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.SETTING_VALIDATE_PENDING_TICKET_ON_ATTENDANCE_CHECKOUT
      ) == "true"
        ? true
        : false,
    force_check_in_after_login:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.FORCE_CHECK_IN_AFTER_LOGIN) ==
        "true"
        ? true
        : false,
    force_logout_after_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.FORCE_LOGOUT_AFTER_CHECKOUT) ==
        "true"
        ? true
        : false,
    validate_timesheet_on_attendance_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.SETTING_VALIDATE_TIMESHEET_ON_ATTENDANCE_CHECKOUT
      ) == "true"
        ? true
        : false,
    validate_pending_checkout_on_attendance_checkin:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.SETTING_VALIDATE_PENDING_CHECKOUT_ON_ATTENDANCE_CHECKIN
      ) == "true"
        ? true
        : false,
    validate_pending_fines_on_attendance_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.VALIDATE_PENDING_FINES_ON_ATTENDANCE_CHECKOUT
      ) == "true"
        ? true
        : false,
    validate_pending_orders_on_attendance_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.VALIDATE_PENDING_ORDERS_ON_ATTENDANCE_CHECKOUT
      ) == "true"
        ? true
        : false,
    validate_pending_transfer_on_attendance_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.VALIDATE_PENDING_TRANSFER_ON_ATTENDANCE_CHECKOUT
      ) == "true"
        ? true
        : false,
    validate_pending_payments_on_attendance_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.VALIDATE_PENDING_PAYMENTS_ON_ATTENDANCE_CHECKOUT
      ) == "true"
        ? true
        : false,
    validate_pending_purchases_on_attendance_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.VALIDATE_PENDING_PURCHASES_ON_ATTENDANCE_CHECKOUT
      ) == "true"
        ? true
        : false,
    validate_pending_bills_on_attendance_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.VALIDATE_PENDING_BILLS_ON_ATTENDANCE_CHECKOUT
      ) == "true"
        ? true
        : false,
    stock_entry_required:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.STOCK_ENTRY_REQUIRED) == "true"
        ? true
        : false,
    validate_shift_time_on_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.VALIDATE_SHIFT_TIME_ON_CHECKOUT) ==
        "true"
        ? true
        : false,
        validate_minimum_replenish_products_on_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.VALIDATE_MINIMUM_REPLENISH_PRODUCTS_ON_CHECKOUT) == "true"
        ? true
        : false,
    validate_shift_hours_on_checkout:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.VALIDATE_SHIFT_HOURS_ON_CHECKOUT) ==
        "true"
        ? true
        : false,
    minimum_replenish_products:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, "minimum_replenish_products")
        ? getKeyValueByObject(settings, "minimum_replenish_products")
        : "",
        stock_entry_minimum_count:
        settings &&
        ArrayList.isNotEmpty(settings) &&
        getKeyValueByObject(settings, Setting.STOCK_ENTRY_MINIMUM_COUNT)
          ? getKeyValueByObject(settings, Setting.STOCK_ENTRY_MINIMUM_COUNT)
          : "",
        reported_tickets_minimum_story_points:
        settings &&
        ArrayList.isNotEmpty(settings) &&
        getKeyValueByObject(settings, "reported_tickets_minimum_story_points")
          ? getKeyValueByObject(settings, "reported_tickets_minimum_story_points")
          : "",
    minimum_story_points: minimumStoryPoints
      ? {
          label: minimumStoryPoints,
          value: minimumStoryPoints,
        }
      : "",
    auto_logout_hours: logoutHours
      ? logoutHours
      : settings &&
        ArrayList.isNotEmpty(settings) &&
        getKeyValueByObject(settings, Setting.AUTO_LOGOUT_HOURS)
      ? getKeyValueByObject(settings, Setting.AUTO_LOGOUT_HOURS)
      : "",
    role_allowed_shift: shiftValue,
    allowed_order_types: orderTypesValue,
    role_allowed_locations: locationValue,
    enable_otp_login:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.ENABLE_OTP_LOGIN) == "true"
        ? true
        : false,
        enable_salary_additional_hours:
        settings &&
        ArrayList.isNotEmpty(settings) &&
        getKeyValueByObject(settings, Setting.ENABLE_SALARY_ADDITIONAL_HOURS) == "true"
          ? true
          : false,
        validate_ticket_minimum_story_points_on_checkout:
        settings &&
        ArrayList.isNotEmpty(settings) &&
        getKeyValueByObject(settings, Setting.VALIDATE_TICKET_MINIMUM_STORY_POINTS_ON_CHECKOUT) == "true"
          ? true
          : false,
          validate_pending_activities_on_attendance_checkout:
          settings &&
          ArrayList.isNotEmpty(settings) &&
          getKeyValueByObject(settings, Setting.VALIDATE_PENDING_ACTIVITIES_ON_ATTENDANCE_CHECKOUT) == "true"
            ? true
            : false,
            validate_late_checkin_on_checkout:
            settings &&
            ArrayList.isNotEmpty(settings) &&
            getKeyValueByObject(settings, Setting.VALIDATE_LATE_CHECKIN_ON_CHECKOUT) == "true"
              ? true
              : false,
              validate_reported_tickets_minimum_story_points_oncheckout:
              settings &&
              ArrayList.isNotEmpty(settings) &&
              getKeyValueByObject(settings, Setting.VALIDATE_REPORTED_TICKETS_MINIMUM_STORY_POINTS_ONCHECKOUT) == "true"
                ? true
                : false,
                fine_add_for_attendance_late_checkin:
                settings &&
                ArrayList.isNotEmpty(settings) &&
                getKeyValueByObject(settings, Setting.FINE_ADD_FOR_ATTENDANCE_LATE_CHECKIN) == "true"
                  ? true
                  : false,
                  [Setting.BONUS_ADD_FOR_ATTENDANCE_EARLY_CHECKIN]:
                  settings &&
                  ArrayList.isNotEmpty(settings) &&
                  getKeyValueByObject(settings, Setting.BONUS_ADD_FOR_ATTENDANCE_EARLY_CHECKIN) == "true"
                    ? true
                    : false,
                  [Setting.BONUS_ADD_FOR_EXTRA_STOCK_ENTRY]:
                  settings &&
                  ArrayList.isNotEmpty(settings) &&
                  getKeyValueByObject(settings, Setting.BONUS_ADD_FOR_EXTRA_STOCK_ENTRY) == "true"
                    ? true
                    : false,
                    fine_add_for_stock_entry:
                    settings &&
                    ArrayList.isNotEmpty(settings) &&
                    getKeyValueByObject(settings, Setting.FINE_ADD_FOR_STOCK_ENTRY) == "true"
                      ? true
                      : false,
                    [Setting.FINE_ADD_FOR_REPLENISHMENT_MISSING]:
                    settings &&
                    ArrayList.isNotEmpty(settings) &&
                    getKeyValueByObject(settings, Setting.FINE_ADD_FOR_REPLENISHMENT_MISSING) == "true"
                      ? true
                      : false,
                      user_role_allow_multiple_checkin :
                      settings &&
                      ArrayList.isNotEmpty(settings) &&
                      getKeyValueByObject(settings, Setting.USER_ROLE_ALLOW_MULTIPLE_CHECKIN) == "true"
                        ? true
                        : false,
                        [Setting.BONUS_ADD_FOR_EXTRA_REPLENISHMENT]:
                        settings &&
                        ArrayList.isNotEmpty(settings) &&
                        getKeyValueByObject(settings, Setting.BONUS_ADD_FOR_EXTRA_REPLENISHMENT) == "true"
                          ? true
                          : false,
                          [Setting.FINE_ADD_FOR_EARLY_ATTENDANCE_CHECK_OUT]:
                          settings &&
                          ArrayList.isNotEmpty(settings) &&
                          getKeyValueByObject(settings, Setting.FINE_ADD_FOR_EARLY_ATTENDANCE_CHECK_OUT) == "true"
                            ? true
                            : false,
                            [Setting.ENQUIRY_TICKET_CREATE_FOR_LATE_CHECK_IN]:
                            settings &&
                            ArrayList.isNotEmpty(settings) &&
                            getKeyValueByObject(settings, Setting.ENQUIRY_TICKET_CREATE_FOR_LATE_CHECK_IN) ==
                              "true"
                              ? true
                              : false,
                              [Setting.ENQUIRY_TICKET_CREATE_FOR_STOCK_ENTRY]:
                              settings &&
                              ArrayList.isNotEmpty(settings) &&
                              getKeyValueByObject(settings, Setting.ENQUIRY_TICKET_CREATE_FOR_STOCK_ENTRY) ==
                                "true"
                                ? true
                                : false,
                                [Setting.ENQUIRY_TICKET_CREATE_FOR_REPLENISHMENT_MISSING]:
                              settings &&
                              ArrayList.isNotEmpty(settings) &&
                              getKeyValueByObject(settings, Setting.ENQUIRY_TICKET_CREATE_FOR_REPLENISHMENT_MISSING) ==
                                "true"
                                ? true
                                : false,
                                [Setting.ENQUIRY_TICKET_CREATE_FOR_EARLY_CHECK_OUT]:
                                settings &&
                                ArrayList.isNotEmpty(settings) &&
                                getKeyValueByObject(settings, Setting.ENQUIRY_TICKET_CREATE_FOR_EARLY_CHECK_OUT) ==
                                  "true"
                                  ? true
                                  : false,
                                  [Setting.ENQUIRY_TICKET_CREATE_FOR_LOCATION_CASH_AMOUNT_MISSING]:
                                  settings &&
                                  ArrayList.isNotEmpty(settings) &&
                                  getKeyValueByObject(settings, Setting.ENQUIRY_TICKET_CREATE_FOR_LOCATION_CASH_AMOUNT_MISSING) ==
                                    "true"
                                    ? true
                                    : false,

                                    [Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_CASH_AMOUNT_MISSING]:
                                    settings &&
                                    ArrayList.isNotEmpty(settings) &&
                                    getKeyValueByObject(settings, Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_CASH_AMOUNT_MISSING) ==
                                      "true"
                                      ? true
                                      : false,

                                    [Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_UPI_AMOUNT_MISSING]:
                                    settings &&
                                    ArrayList.isNotEmpty(settings) &&
                                    getKeyValueByObject(settings, Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_UPI_AMOUNT_MISSING) ==
                                      "true"
                                      ? true
                                      : false,
                                      [Setting.ENQUIRY_TICKET_CREATE_FOR_DRAFT_AMOUNT_MISSING]:
                                      settings &&
                                      ArrayList.isNotEmpty(settings) &&
                                      getKeyValueByObject(settings, Setting.ENQUIRY_TICKET_CREATE_FOR_DRAFT_AMOUNT_MISSING) ==
                                        "true"
                                        ? true
                                        : false,
                                        [Setting.FINE_ADD_FOR_TICKET_DUE_DATE_CHANGE]:
                                        settings &&
                                        ArrayList.isNotEmpty(settings) &&
                                        getKeyValueByObject(settings, Setting.FINE_ADD_FOR_TICKET_DUE_DATE_CHANGE) ==
                                          "true"
                                          ? true
                                          : false,
                                          [Setting.FINE_ADD_FOR_PAYMENT_DUE_DATE_CHANGE]:
                                          settings &&
                                          ArrayList.isNotEmpty(settings) &&
                                          getKeyValueByObject(settings, Setting.FINE_ADD_FOR_PAYMENT_DUE_DATE_CHANGE) ==
                                            "true"
                                            ? true
                                            : false,
                                            [Setting.FINE_ADD_FOR_BILL_DUE_DATE_CHANGE]:
                                            settings &&
                                            ArrayList.isNotEmpty(settings) &&
                                            getKeyValueByObject(settings, Setting.FINE_ADD_FOR_BILL_DUE_DATE_CHANGE) ==
                                              "true"
                                              ? true
                                              : false,
                                              [Setting.BONUS_ADD_FOR_LATE_CHECKOUT]:
                                              settings &&
                                              ArrayList.isNotEmpty(settings) &&
                                              getKeyValueByObject(settings, Setting.BONUS_ADD_FOR_LATE_CHECKOUT) ==
                                                "true"
                                                ? true
                                                : false,
                                                [Setting.SHOW_DASHBOARD_PENDING_ACTIVITIES_REMINDER]:
                                                settings &&
                                                ArrayList.isNotEmpty(settings) &&
                                                getKeyValueByObject(settings, Setting.SHOW_DASHBOARD_PENDING_ACTIVITIES_REMINDER) == "true"
                                                  ? true
                                                  : false,
                      
  };


  const handleCheckBoxValue = (value, name) => {

    // Convert object to array
    let array = Object.entries(value);
    let checkBoxData = {};

    // Iterate over the array to find the matching name
    array.forEach((result) => {
      if (result[0] === name) {
        checkBoxData.label = result[0];
        checkBoxData.value = result[1];
      }
    });

    // Create new FormData
    const data = new FormData();

    // Append the specific checkbox value
    data.append(checkBoxData.label, checkBoxData.value);

    // Append additional fields if necessary
    data.append("objectId", props.roleId);
    data.append("objectName", ObjectName.ROLE);

    // Save settings
    saveSetting(data, null, () => {
      getSettings();
    });
  };

  const handleUpdate = (value) => {
    let allowedShift = [];
    for (let i = 0; i < value.role_allowed_shift.length; i++) {
      const data = value.role_allowed_shift[i];
      allowedShift.push(data?.value);
    }
    let allowedOrder = [];
    for (let i = 0; i < value.allowed_order_types.length; i++) {
      const data = value.allowed_order_types[i];
      allowedOrder.push(data?.value);
    }
    let allowedLocation = [];
    for (let i = 0; i < value.role_allowed_locations.length; i++) {
      const data = value.role_allowed_locations[i];
      allowedLocation.push(data?.id);
    }

    let data = new FormData();

    data.append(Setting.USER_WORKING_DAYS, selectedDays ? selectedDays : "");
    data.append(
      "minimum_story_points",
      value.minimum_story_points ? value.minimum_story_points.value : ""
    );
    data.append(
      "minimum_replenish_products",
      value?.minimum_replenish_products ? value?.minimum_replenish_products : ""
    );
    data.append(
      Setting.STOCK_ENTRY_MINIMUM_COUNT,
      value?.stock_entry_minimum_count ? value?.stock_entry_minimum_count : ""
    );
    data.append(
      "reported_tickets_minimum_story_points",
      value?.reported_tickets_minimum_story_points
        ? value?.reported_tickets_minimum_story_points
        : ""
    );
    data.append(
      "auto_logout_hours",
      value.auto_logout_hours ? value.auto_logout_hours : ""
    );
    data.append(
      Setting.ROLE_ALLOWED_SHIFT,
      allowedShift && allowedShift.length > 0 ? allowedShift.join(",") : ""
    );
    data.append(
      Setting.ALLOWED_ORDER_TYPES,
      allowedOrder && allowedOrder.length > 0 ? allowedOrder.join(",") : ""
    );
    data.append(
      Setting.ACCOUNT_TYPE,
      value?.account_type && value?.account_type?.value?  value?.account_type?.value : ""
    );
    data.append(
      Setting.ROLE_ALLOWED_LOCATIONS,
      allowedLocation && allowedLocation.length > 0
        ? allowedLocation.join(",")
        : ""
    );
    data.append("objectId", props.roleId);
    data.append("objectName", ObjectName.ROLE);

    saveSetting(data, null, () => {
      getSettings();
    });
  };

  const handleCheckboxChange = (day, checked) => {
    if (checked) {
      setSelectedDays([...selectedDays, day]);
    } else {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    }
  };

  const handleChange = (values) => {
    let dataValue = values && values?.values;
    if (dataValue?.minimum_story_points !== null) {
      let data =
        dataValue && dataValue?.minimum_story_points
          ? dataValue?.minimum_story_points.value
          : null;
      setMinStoryPoints(data);
    } else {
      setMinStoryPoints(null);
    }
  };
  const handleShiftChange = (values) => {
    let dataValue = values && values?.values;
    if (dataValue?.shift !== null) {
      let data = dataValue && dataValue?.shift ? dataValue?.shift.value : null;
      setShift(data);
    } else {
      setShift(null);
    }
  };

  const handleLoginTimeChange = (selectedTime) => {
    let date = selectedTime ? DateTime.getTime(selectedTime) : "";
    const data = date ? convertTimeStringToDate(date) : "";
    setLoginTime(data);
  };


  const handleLogoutHours = (hours) => {
    let data = hours && hours.target.value;
    setLogoutHours(data);
  };

  return (
    <div className="mb-4 pb-4 mb-sm-2 pb-sm-2">
      <Form
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleUpdate}
      >
        <div className="card bg-white">
          <div className="card-body">
          <div className="d-flex justify-content-start w-100 mt-3">
              <h2 className="font-weight-bold">CheckIn Validations</h2>
            </div>
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.USER_ROLE_ALLOW_MULTIPLE_CHECKIN}
                label="Allow Multiple CheckIn"
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.FORCE_CHECK_IN_AFTER_LOGIN}
                label={"Force CheckIn After Login"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.VALIDATE_IP_ADDRESS_ON_CHECKIN
                }
                label={"Validate IP Address On CheckIn"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.SETTING_VALIDATE_PENDING_CHECKOUT_ON_ATTENDANCE_CHECKIN
                }
                label={"Validate Pending Checkout on Attendance CheckIn"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <div className="d-flex justify-content-start w-100 mt-3">
              <h2 className="font-weight-bold">CheckOut Validations</h2>
            </div>
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_LATE_CHECKIN_ON_CHECKOUT}
                label={"Validate Late CheckIn On Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.SETTING_VALIDATE_SALES_SETTLEMENT_ON_ATTENDANCE_CHECKOUT
                }
                label={"Validate Sales Settlement On Attendance CheckOut"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.SETTING_VALIDATE_PENDING_TICKET_ON_ATTENDANCE_CHECKOUT
                }
                label={"Validate Pending Ticket On Attendance CheckOut"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_IP_ADDRESS_ON_CHECKOUT}
                label={"Validate IP Address On CheckOut"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.VALIDATE_PENDING_SALES_SETTLEMENT_ON_ATTENDANCE_CHECKOUT
                }
                label={"Validate Pending Sales Settlement on Attendance Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.SETTING_VALIDATE_TIMESHEET_ON_ATTENDANCE_CHECKOUT}
                label={"Validate Timesheet on Attendance Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_PENDING_FINES_ON_ATTENDANCE_CHECKOUT}
                label={"Validate Pending Fines On Attendance Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_PENDING_ORDERS_ON_ATTENDANCE_CHECKOUT}
                label={"Validate Pending Orders On Attendance Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_PENDING_TRANSFER_ON_ATTENDANCE_CHECKOUT}
                label={"Validate Pending Transfer On Attendance Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_PENDING_PAYMENTS_ON_ATTENDANCE_CHECKOUT}
                label={"Validate Pending Payments On Attendance Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_PENDING_PURCHASES_ON_ATTENDANCE_CHECKOUT}
                label={"Validate Pending Purchases On Attendance Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_SHIFT_TIME_ON_CHECKOUT}
                label={"Validate Shift Time On Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_PENDING_BILLS_ON_ATTENDANCE_CHECKOUT}
                label={"Validate Pending Bills On Attendance Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_MINIMUM_REPLENISH_PRODUCTS_ON_CHECKOUT}
                label={"Validate Minimum Replenish Products On Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.FORCE_LOGOUT_AFTER_CHECKOUT}
                label={"Force Logout After Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_TICKET_MINIMUM_STORY_POINTS_ON_CHECKOUT}
                label={"Validate Ticket Minimum Story Points On Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.VALIDATE_PENDING_ACTIVITIES_ON_ATTENDANCE_CHECKOUT
                }
                label={"Validate Pending Activities On Attendance Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.VALIDATE_REPORTED_TICKETS_MINIMUM_STORY_POINTS_ONCHECKOUT
                }
                label={
                  "Validate Reported Tickets Minimum Story Points On Checkout"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.VALIDATE_SHIFT_HOURS_ON_CHECKOUT}
                label={"Validate Shift Hours On Checkout"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
                <SingleCheckbox
                  name={Setting.VALIDATE_SHIFT_TIME_ON_SALE_SETTLEMENT_ADD}
                  label={"Validate Shift Time On Sales Settlement Add"}
                  handleOnChangeSubmit={(value, name) =>
                    handleCheckBoxValue(value, name)
                  }
                />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.ALLOW_ADDITIONAL_LEAVE_ADD}
                label="Allow Additional Leave Add"
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.ALLOW_REPLENISHMENT}
                label="Allow Replenishment"
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.STOCK_ENTRY_REQUIRED}
                label={"Stock Entry Required"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={Setting.ENABLE_OTP_LOGIN}
                label={"Enable OTP Login"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="d-flex justify-content-start w-100 mt-3">
              <h2 className="font-weight-bold">Fine</h2>
            </div>
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.FINE_ADD_FOR_ATTENDANCE_LATE_CHECKIN
                }
                label={
                  "Fine Add For Attendance Late Check In"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.FINE_ADD_FOR_EARLY_ATTENDANCE_CHECK_OUT
                }
                label={
                  "Fine Add For Early CheckOut"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.FINE_ADD_FOR_STOCK_ENTRY
                }
                label={
                  "Fine Add For Stock Entry"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.FINE_ADD_FOR_REPLENISHMENT_MISSING
                }
                label={
                  "Fine Add For Replenishment Missing"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.FINE_ADD_FOR_TICKET_DUE_DATE_CHANGE
                }
                label={
                  "Fine Add For Ticket Due Date Change"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.FINE_ADD_FOR_PAYMENT_DUE_DATE_CHANGE
                }
                label={
                  "Fine Add For Payment Due Date Change"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.FINE_ADD_FOR_BILL_DUE_DATE_CHANGE
                }
                label={
                  "Fine Add For Bill Due Date Change"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="d-flex justify-content-start w-100 mt-3">
              <h2 className="font-weight-bold">Bonus</h2>
            </div>
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.BONUS_ADD_FOR_ATTENDANCE_EARLY_CHECKIN
                }
                label={
                  "Bonus Add For Attendance Early Check-In"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.BONUS_ADD_FOR_EXTRA_STOCK_ENTRY
                }
                label={
                  "Bonus Add For Extra Stock Entry"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.BONUS_ADD_FOR_LATE_CHECKOUT
                }
                label={
                  "Bonus Add For Late Check-Out"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.BONUS_ADD_FOR_EXTRA_REPLENISHMENT
                }
                label={
                  "Bonus Add For Extra Replenishment"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="d-flex justify-content-start w-100 mt-3">
              <h2 className="font-weight-bold">Enquiry Ticket</h2>
            </div>
            <br />
            <div className="field-wrapper">
              <SingleCheckbox
                name={
                  Setting.ENQUIRY_TICKET_CREATE_FOR_LATE_CHECK_IN
                }
                label={
                  "Enquiry Ticket create For Late Check In"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.ENQUIRY_TICKET_CREATE_FOR_STOCK_ENTRY
                }
                label={
                  "Enquiry Ticket create For Stock Entry"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.ENQUIRY_TICKET_CREATE_FOR_REPLENISHMENT_MISSING
                }
                label={
                  "Enquiry Ticket create For Replenishment Missing"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.ENQUIRY_TICKET_CREATE_FOR_EARLY_CHECK_OUT
                }
                label={
                  "Enquiry Ticket create For Early Checkout"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>

            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.ENQUIRY_TICKET_CREATE_FOR_LOCATION_CASH_AMOUNT_MISSING
                }
                label={
                  "Enquiry Ticket create For Location Cash Amount Missing"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_CASH_AMOUNT_MISSING
                }
                label={
                  "Enquiry Ticket create For Order Cash Amount Missing"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.ENQUIRY_TICKET_CREATE_FOR_ORDER_UPI_AMOUNT_MISSING
                }
                label={
                  "Enquiry Ticket create For Order UPI Amount Missing"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>

            <br />
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.ENQUIRY_TICKET_CREATE_FOR_DRAFT_AMOUNT_MISSING
                }
                label={
                  "Enquiry Ticket create For Draft Amount Missing"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
            <div className="d-flex justify-content-start w-100 mt-3">
              <h2 className="font-weight-bold">Salary</h2>
            </div>
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.ENABLE_SALARY_ADDITIONAL_HOURS
                }
                label={
                  "Enable Salary Additional Hours"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>


            <div className="d-flex justify-content-start w-100 mt-3">
              <h2 className="font-weight-bold">Activity</h2>
            </div>
            <div className="field-wrapper mt-4">
              <SingleCheckbox
                name={
                  Setting.SHOW_DASHBOARD_PENDING_ACTIVITIES_REMINDER
                }
                label={
                  "Dashboard Pending Activities Reminder"
                }
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
           
            
            <br />
            <div className="mt-4">
              <div className="col">
                <StoryPointSelect
                  name="minimum_story_points"
                  label="Minimum Story Points"
                  placeholder="Select Minimum Story Points"
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <Numbers
                  label="Minimum Replenish Products"
                  name="minimum_replenish_products"
                />
              </div>
              <div className="col">
                <Numbers
                  label="Stock Entry Minimum Count"
                  name={Setting.STOCK_ENTRY_MINIMUM_COUNT}
                />
              </div>
              <div className="col">
                <MultiSelect
                  label="Allowed Shifts"
                  name={Setting.ROLE_ALLOWED_SHIFT}
                  options={shiftList ? shiftList : []}
                />
              </div>
              <div className="col">
                <MultiSelect
                  label="Allowed Order Types "
                  name={Setting.ALLOWED_ORDER_TYPES}
                  options={orderTypes ? orderTypes : []}
                />
              </div>
              <div className="col">
                <AccountTypeSelect
                  label="Account Type"
                  name={Setting.ACCOUNT_TYPE}
                  accountTypeOption={setAccountType}
                />
              </div>
              <div className="col">
                <StoreSelector
                  label="Allowed Location"
                  name={Setting.ROLE_ALLOWED_LOCATIONS}
                  setStoreList={setStoreList}
                />
              </div>

              <div className="col">
                <Text
                  label="Auto Logout Hours"
                  name="auto_logout_hours"
                  onChange={handleLogoutHours}
                />
              </div>
              <div className="col">
                <Numbers
                  label="Reported Tickets Minimum Story Points"
                  name="reported_tickets_minimum_story_points"
                />
              </div>
            </div>
            <label>Working Days</label>
            <br />
            <div className="my-3">
              <Checkbox
                day="Monday"
                checked={selectedDays.includes("Monday")}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                day="Tuesday"
                checked={selectedDays.includes("Tuesday")}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                day="Wednesday"
                checked={selectedDays.includes("Wednesday")}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                day="Thursday"
                checked={selectedDays.includes("Thursday")}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                day="Friday"
                checked={selectedDays.includes("Friday")}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                day="Saturday"
                checked={selectedDays.includes("Saturday")}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                day="Sunday"
                checked={selectedDays.includes("Sunday")}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="mb-4">
              <div>
                <SaveButton />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RoleSetting;
