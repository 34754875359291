import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import ContactList from "./components/ContactList";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";

const contactListPage = (props) => {
  let { history } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  let showAddButton = hasPermission(Permission.CONTACT_ADD);

  return (
    <div>
      {showAddButton && (
        <PageTitle
          label={"Contact"}
          buttonHandler={() => {
            openToggle();
          }}
          buttonLabel="Add"
        />
      )}

      <ContactList
        history={history}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default contactListPage;
