import React, { useEffect, useState } from "react";
import Form from "../../../components/Form";

import { useDispatch } from "react-redux";
import { updateStore } from "../../../actions/storeList";
import SaveButton from "../../../components/SaveButton";
import SingleCheckbox from "../../../components/SingleCheckbox";
import ShiftSelect from "../../../components/ShiftSelect";
import ShiftService from "../../../services/ShiftService";
const SettingsTab = (props) => {
  const { storeId, productData } = props;
  const [shiftList, setShiftList] = useState([])

  useEffect(() => {
    getShifts()
  }, [])
  const dispatch = useDispatch();

  const getShifts = async () => {
    const list = await ShiftService.getShiftList();
    if (list.length > 0) {
      setShiftList(list);
    }
  };


  const handleUpdate = async (values) => {
    try {

      const data = new FormData();

      let shiftIds = [];

      if (values && values?.allowed_shift?.length > 0) {
        for (let i = 0; i < values?.allowed_shift.length; i++) {
          const { id } = values?.allowed_shift[i];
          shiftIds.push(id);
        }
      }
      data.append(
        'allowed_shift',
        shiftIds && shiftIds.length > 0 ? shiftIds.join(',') : ''
      );
      data.append(
        "sales_settlement_required",
        values.sales_settlement_required
      );
      data.append("allow_sale", values.allow_sale);

      data.append("allow_replenishment", values.allow_replenishment);

      data.append("allow_purchase", values.allow_purchase);

      data.append("allow_check_in", values.allow_check_in);

      dispatch(updateStore(storeId, data, {}));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (values) => {

    let data = new FormData();

    let shiftIds = [];


    if (values && values?.allowed_shift?.length > 0) {
      for (let i = 0; i < values?.allowed_shift.length; i++) {
        const { id } = values?.allowed_shift[i];
        shiftIds.push(id);
      }
    }
    data.append(

      'allowed_shift',
      shiftIds && shiftIds.length > 0 ? shiftIds.join(',') : ''
    );
    data.append(
      "sales_settlement_required",
      values.sales_settlement_required
    );
    data.append("allow_sale", values.allow_sale);

    data.append("allow_replenishment", values.allow_replenishment);

    data.append("allow_purchase", values.allow_purchase);
    dispatch(updateStore(storeId, data, {}));
  };

  let shiftArrayValue = []
  if (productData && productData?.data && productData?.data?.allowed_shift) {
    let splitShiftIds = productData?.data && productData?.data?.allowed_shift && productData?.data?.allowed_shift?.split(",");
    splitShiftIds && splitShiftIds.length > 0 && splitShiftIds.forEach(id => {
      let shiftValue = shiftList && shiftList.length > 0 && shiftList.find((data) => data?.value == id)
      shiftArrayValue.push(shiftValue)
    });
  }
  return (
    <Form
      enableReinitialize={true}
      initialValues={{
        sales_settlement_required: productData?.data?.sales_settlement_required,
        allow_sale: productData?.data?.allow_sale,
        allow_replenishment: productData.data?.allow_replenishment,
        allow_purchase: productData?.data?.allow_purchase,
        allow_check_in: productData?.data?.allow_check_in,
        allowed_shift: shiftArrayValue
      }}
      onSubmit={handleSubmit}
    >
      <div className="card bg-white mt-3 col-lg-12">
        <div className="card-body">
          <div className="form-wrapper">
            <div className="field-wrapper">
              <SingleCheckbox
                name="sales_settlement_required"
                label="Sales Settlement Required"
                className="accepted-terms"
                handleOnChangeSubmit={(value, name) =>
                  handleUpdate(value, name)
                }
              />
            </div>
          </div>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <SingleCheckbox
                name="allow_sale"
                label="Allow Sale"
                className="accepted-terms"
                handleOnChangeSubmit={(value, name) =>
                  handleUpdate(value, name)
                }
              />
            </div>
          </div>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <SingleCheckbox
                name="allow_replenishment"
                label="Allow Replenishment"
                className="accepted-terms"
                handleOnChangeSubmit={(value, name) =>
                  handleUpdate(value, name)
                }
              />
            </div>
          </div>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <SingleCheckbox
                name="allow_purchase"
                label="Allow Purchase"
                className="accepted-terms"
                handleOnChangeSubmit={(value, name) =>
                  handleUpdate(value, name)
                }
              />
            </div>
          </div>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <SingleCheckbox
                name="allow_check_in"
                label="Allow Check-In"
                className="accepted-terms"
                handleOnChangeSubmit={(value, name) =>
                  handleUpdate(value, name)
                }
              />
            </div>
          </div>
          <ShiftSelect name="allowed_shift" label="Allowed Shift" isMulti />
          <SaveButton />
        </div>
      </div>
    </Form>
  );
};

export default SettingsTab;
