import React from "react";
import Phone from "../../components/Phone";
import TagSelect from "../../components/TagSelect";
import TextArea from "../../components/TextArea";
import Text from "../../components/Text";
import DateSelector from "../../components/Date";
import UserSelect from "../../components/UserSelect";
import { getKeyValueByObject } from "../../lib/Helper";
import { useEffect } from "react";
import { getCompanySettings } from "../../services/SettingService";
import { Setting } from "../../helpers/Setting";
import { useState } from "react";
import ObjectName from "../../helpers/ObjectName";
import MediaCarousel from "../../components/MediaCarousel";
import JobsSelect from "../../components/JobsSelect";
import VisitorType from "../../helpers/VisitorType";
import StoreSelector from "../location/components/storeSelector";

const VisitorForm = (props) => {
  const {
    TagList,
    rowValue,
    editable,
    setUserList,
    selectedType,
    handleTypeChange,
    visitorDetail,
    showUserDetailsPageLink,
    userId,
    isAddPage = false,
    onDrop,
    imageUrl,
    handleDelete,
    isLoading,
    setStoreList,
    setJobList
  } = props;


  const [showVisitorType, setShowVisitorType] = useState([]);

  useEffect(() => {
    getSettings();
  }, [props]);

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    let showVisitorType = getKeyValueByObject(
      settingData,
      Setting.VISITOR_SHOW_TITLE
    );
    setShowVisitorType(showVisitorType);
  };

  return (
    <>
      <div>
        {rowValue?.id && (
          <DateSelector
            showTimeSelect
            label="Date"
            name="date"
            format="dd-MMM-yyyy hh:mm aa"
            disabled
          />
        )}
        <TagSelect
          name="type"
          label="Visitor Type"
          placeholder="Visitor Type"
          params={{ type: "Visitor Type" }}
          TagList={TagList}
          required={true}
          isDisabled={editable}
          handleTagChange={handleTypeChange}
        />
        <Text
          name="visitor"
          label="Name"
          placeholder="Visitor"
          required
          disabled={editable}
        />
       {(
  (visitorDetail?.typeName === VisitorType.INTERVIEW_CANDIDATE_TEXT) ||
  (rowValue?.typeName === VisitorType.INTERVIEW_CANDIDATE_TEXT) ||
  (selectedType?.label === VisitorType.INTERVIEW_CANDIDATE_TEXT)
            ) && (
              <JobsSelect
              name="position"
              label="Position"
              placeholder="Select position"
              isDisabled={editable}
              required
              setJobList={setJobList}
            />
           )}
        {showVisitorType.includes(
          rowValue?.type || selectedType?.value || visitorDetail?.type
        ) && (
            <Text
              name="title"
              label="Title"
              placeholder="Title"
              disabled={editable}
              required
            />
          )}

        <UserSelect
          label="Person To Meet"
          required={true}
          name="person_to_meet"
          isDisabled={editable}
          userList={setUserList}
          showUserDetailsPageLink={showUserDetailsPageLink}
          userId={userId}
        />
        <StoreSelector
            setStoreList={setStoreList}
            disabled={editable}
            isSingleSelect={true}
        /> 
         <Phone
          name="mobileNumber"
          label="Phone Number"
          placeholder="Phone Number"
          error=""
          disabled={editable}
        />
        <Text
          name="purpose"
          label="Purpose"
          placeholder="Purpose"
          required
          disabled={editable}
        />
        <TextArea
          name="notes"
          label="Notes"
          placeholder="Notes"
          disabled={editable}
        />
        <div className="w-100">
          {rowValue && rowValue?.id && (
            <MediaCarousel
              showCarasoul
              objectName={ObjectName.VISITOR}
              objectId={rowValue && rowValue.id}
              history={props.history}
              attachmentsList={true}
              Attachments={"Attachments"}
            />
          )}
        </div>
        {(!rowValue && isAddPage) && (
          <div className="w-100">
            <MediaCarousel
              showCarasoul
              Attachments
              onDropImage={onDrop}
              imageUrl={imageUrl}
              handleImageRemove={handleDelete}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default VisitorForm;
