/**
 * Table
 */
export const ACTIVE = "Active";
export const IN_ACTIVE = "InActive";
export const ALL = "All";
export const PAGESIZE = 25;
export const SORT = "id";
export const SORT_DIR = "DESC";
export const PAGE = 1;
export const SORT_UPDATEAT = "updatedAt";
