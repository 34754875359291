import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import ArrayList from "../lib/ArrayList";
import { SUCCESS_RESPONSE, isBadRequest } from "../lib/Http";
import Url from "../lib/Url";


class MessageChannelUser {

    static create (data,callback){

        apiClient
        .post(`${endpoints().MessageChannelUserApi}/create`, data)
        .then((res) => {
          if (res.status == SUCCESS_RESPONSE) {
            Toast.success(res?.data?.message);
            return callback(res)
          }
        })
        .catch((err) => {
          if (isBadRequest(err)) {
            let errorMessage;
            const errorRequest = err.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });

    }
    static async list (params){

      let queryString = await ArrayList.toQueryString(params);
      let { data } = await Url.get(
        `${endpoints().MessageChannelUserApi}`,
        queryString
      );
      return data;
  }
  static async delete(id,callback) {
    try {
      apiClient
      .delete(`${endpoints().MessageChannelUserApi}/${id}`)
      .then((res) => {
        if (res.status == SUCCESS_RESPONSE) {
          Toast.success(res?.data?.message);
          return callback(null,res?.data?.message)
        }
      })
      .catch((err) => {
        if (isBadRequest(err)) {
          let errorMessage;
          const errorRequest = err.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
          return callback(errorMessage,null)

        }
      });
    } catch (err) {
      console.log(err);
    }
  }
}

export default MessageChannelUser;