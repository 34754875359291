import React, { useEffect, useState } from "react";
import Filter from "../../components/Filter";
import String from "../../lib/String";
import Url from "../../lib/Url";
import StoreService from "../../services/StoreService";
import { TagTypeName } from "../../helpers/Tag";
import CategoryService from "../../services/CategoryService";
import BrandService from "../../services/BrandService";
import TagService from "../../services/TagService";

const StockReportFilter = (props) => {
  const {
    setPageSize,
    setPage,
    pageSize,
    getDetails,
    productParams,
    getFilterData,
    setProductParams,
    isModalOpen,
    openModel
  } = props;
  const [brandList, setBrandList] = useState([]);

  const [categoryValue, setCategory] = useState(Url.GetParam("category"));
  const [brandValue, setBrand] = useState(Url.GetParam("brand"));
  const [tagValue, setTag] = useState(Url.GetParam("tag"));
  const [categoryList, setCategoryList] = useState([]);

  const [searchTerm, setSearchTerm] = useState();
  const [searchItem, setSearch] = useState(Url.GetParam("search"));
  const [currentParams, setCurrentParams] = useState({});
  const [brandData, setBrandData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [pageSizeValue, setPageSizeValue] = useState(Url.GetParam("pageSize"));
  const [tagOption, setProductTag] = useState([]);

  const [sortValue, setSortValue] = useState(Url.GetParam("sort"));
  const [sortByValue, setSortBy] = useState("total_order_quantity");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [storeList, setStoreList] = useState([]);
  const [storeValue, setStore] = useState(Url.GetParam("location"));

  const [selectedSortValue, setSelectedSort] = useState(
    Url.GetParam("sortValues")
  );
  const [spinValue, setSpin] = useState(false);

  //Sort By Option Values
  const sortByOption = [
    {
      value: "total_order_quantity:DESC",
      label: "Order Quantity"
    },
    {
      value: "available_quantity:DESC",
      label: "Available Quantity"
    },
    {
      value: "required_quantity:DESC",
      label: "Required Quantity"
    },
    {
      value: "product_name:ASC",
      label: "Name"
    }
  ];

  useEffect(() => {
    getBrandDetail(isModalOpen);
    getCategoryDetail(isModalOpen);
    getTagList(isModalOpen);
    getStoreList(isModalOpen);
  }, [isModalOpen]);

  if (categoryValue) {
    productParams.category = categoryValue ? categoryValue : Url.GetParam("category");
  }

  if (brandValue) {
    productParams.brand = brandValue ? brandValue : Url.GetParam("brand");
  }

  if (!brandValue) {
    productParams.brand = "";
  }

  if (!categoryValue) {
    productParams.category = "";
  }

  if (tagValue) {
    productParams.tag = tagValue ? tagValue : Url.GetParam("tag");
  }
  if (sortDirection) {
    productParams.sortDirection = sortDirection;
  }
  if (sortValue) {
    productParams.sort = sortValue;
  }
  if (sortByValue) {
    productParams.sort = sortByValue;
  }
  if (!tagValue) {
    productParams.tag = "";
  }

  if (searchItem) {
    productParams.search = searchItem;
  }

  if (!searchItem) {
    productParams.search = "";
  }

  if (storeValue) {
    productParams.location = storeValue ? storeValue : Url.GetParam("location");
  }

  if (!storeValue) {
    productParams.location = "";
  }
  if (pageSizeValue) {
    productParams.pageSize = pageSizeValue;
  }

  const getBrandDetail = async (isModalOpen) => {
    try {
      if ( brandList && brandList.length == 0) {
        const response = await BrandService.getBrandOption();
        setBrandList(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get all tag list
  const getTagList = async (isModalOpen) => {
    try {
      if ( tagOption && tagOption.length == 0) {
        let tagParams={ type: TagTypeName.PRODUCT }
        const response = await TagService.getOption(tagParams);
        setProductTag(response);
      }
    } catch (err) {
      const res = err.response;
      res && toast.error(res.data.message);
    }
  };

  const getCategoryDetail = async (isModalOpen) => {
    try {
      if ( categoryList && categoryList.length == 0) {
        const response = await CategoryService.getOption();
        setCategoryList(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStoreList = async (isModalOpen) => {
    if ( storeList && storeList.length == 0) {
      await StoreService.list((callback) => {
         setStoreList(callback)
    });
    }
  };

  const handleParamsChange = async (updatedParams) => {

    if (!updatedParams.location) {
      setStore("");
    }
    if (!updatedParams.category) {
      setCategory("");
    }
    if (!updatedParams.tag) {
      setTag("");
    }
    if (!updatedParams.brand) {
      setBrand("");
    }
    const productparam = {
      ...updatedParams,
      brand: updatedParams.brand || "",
      category: updatedParams.category || "",
      tag: updatedParams.tag || "",
      pageSize: updatedParams.pageSize || pageSizeValue || "",
      search: updatedParams.search || searchTerm || "",
      location: updatedParams.location || "",
      sort: updatedParams.sort || sortByValue,
      sortDirection: updatedParams.sortDirection || sortDirection,
      
    };

    setProductParams(productparam);

    Url.UpdateUrl(productparam, props);
    await getFilterData(productparam);
    await getDetails(productparam);
  };

  const handleSearchTermChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    setSearch(searchValue);
    setPage(1);
  
    handleParamsChange({
      sort: sortBy,
      sortDirection: sortDirection,
      sortValues: values,
      brand:Url.GetParam("brand"),
      category:Url.GetParam("category"),
      tag:Url.GetParam("tag"),
      location:Url.GetParam("location"),
      search:Url.GetParam("search"),
      search: searchValue
    });
  };  

  const getSelectedSortLabel = () => {
    const selectedSortOption = sortByOption.find(
      (option) => option.value === selectedSortValue
    );

    return selectedSortOption?.label
      ? selectedSortOption?.label
      : sortByOption[0].label;
  };

  // Handle category change
  const handleSortChange = async (values) => {
    const sortAndSortDir = values.split(":");
    setSelectedSort(values);

    const sortBy = sortAndSortDir[0];
    const sortDirection = sortAndSortDir[1];

    setSortValue(sortBy);
    setSortBy(sortBy);

    setSortDirection(sortDirection);
    handleParamsChange({
      sort: sortBy,
      sortDirection: sortDirection,
      sortValues: values,
      brand:Url.GetParam("brand"),
      category:Url.GetParam("category"),
      tag:Url.GetParam("tag"),
      location:Url.GetParam("location"),
      search:Url.GetParam("search"),

    });
    setPage(1);
  };

  const initialValues = {
    brand: brandList.find((data) => data?.id == Url.GetParam("brand")),
    category: categoryList.find((data) => data?.id == Url.GetParam("category")),
    tag: tagOption.find((data) => data?.id == Url.GetParam("tag")),
    location: storeList.find((data) => data?.value == Url.GetParam("location"))
  };

  const onSearchKeyUp = (event) => {
    if (event.charCode === 13) {
      getFilterData(productParams);
    }
  };

  // Handle Pagesize
  const handlePageSize = async (value) => {
    setPageSize(value);
    setPageSizeValue(value);

    handleParamsChange({
      pageSize: value,
      brand: Url.GetParam("brand"),
      category: Url.GetParam("category"),
      tag: Url.GetParam("tag"),
      location: Url.GetParam("location"),
      search: Url.GetParam("search"),
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
    });

    setCurrentParams(productParams);
    setPage(1);
  };

  const refreshButtonOnClick = async () => {
    setSpin(true);
    await getDetails(productParams);
    setSpin(false);
  };
  
    const handleDeleteFilter = (removedFilter) => {
      const updatedParams = { ...productParams };
  
      Object.keys(removedFilter).forEach((params) => {
        updatedParams[params] = "";
      });
  
      handleParamsChange(updatedParams);
  };

  const handleFilter = (values) => {
    setCategory(values?.category ? values?.category?.value : "");
    setTag(values?.tag ? values?.tag?.value : "");
    setStore(values?.location ? values?.location?.value : "");
    setBrand(values?.brand ? values?.brand?.value : "")
    handleParamsChange({
      location: values?.location ? values?.location?.value : "",
      tag: values?.tag ? values?.tag?.value : "",
      category: values?.category ? values?.category?.value : "",
      brand: values?.brand ? values?.brand?.value : "",
    });
  }

  return (
    <Filter
      showHeader
      newTableHeading
      pageSearchOnChange={(e) => {
        handleSearchTermChange(e);
      }}
      pageSearchValue={searchItem}
      onKeyPress={onSearchKeyUp}
      searchPlaceholder="Search Product"
      getPageSizeByOptions={(e) => handlePageSize(e)}
      selectedPageSize={pageSize && pageSize}
      getSelectedSortLabel={getSelectedSortLabel()}
      sortByOptions={sortByOption}
      handleSortByChange={handleSortChange}
      refreshButtonOnClick={refreshButtonOnClick}
      refreshValue={spinValue}
      showBrandFilter
      showPageSize
      showCategoryFilter
      showTagFilter
      tagParams={{
        type: TagTypeName.PRODUCT
      }}
      showStoreFilter
      initialValues={initialValues}
      handleDeleteFilter={handleDeleteFilter}
      openModel={openModel}
      modelOpen={isModalOpen}
      handleFilter={handleFilter}
    />
  );
};
export default StockReportFilter;
