import React from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Form from "../../components/Form";
import CancelButton from "../../components/CancelButton";
import Submit from "../../components/Submit";
import Quantity from "../../components/Quantity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const QuantityModal = (props) => {

  const { toggle, onModalClose, handleSubmit, modalTitle, confirmLabel, quantity,initialValues } = props;

  const formInitialValues = initialValues || {
    quantity: { label: quantity , value: quantity},
  };
  return (
    <>
      <Modal isOpen={toggle} centered={true} >
      <ModalHeader
          toggle={onModalClose}
          close={
            <FontAwesomeIcon
              icon={faXmark}
              className="cursor-pointer"
              style={{ marginLeft: "5px" }}
              onClick={() => onModalClose()}
            />
          }
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <span className="w-100 text-center">{modalTitle}</span>
        </ModalHeader>
        <Form initialValues={formInitialValues} onSubmit={handleSubmit}>
          <ModalBody>
            <Quantity label="Quantity" required={true} />
          </ModalBody>
          <ModalFooter>
            <Submit label={confirmLabel} />
            <CancelButton onClick={onModalClose} />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default QuantityModal;