import React, { useEffect, useState } from "react";
import Select from "./Select";
import AttendanceTypeService from "../services/AttendanceTypeService";

const AttendanceTypeSelect = (props) => {
  let {
    name,
    label,
    handleChange,
    required,
    accountTypeOption,
    placeholder,
    width,
    onChange,
    customOption,
    isDisabled,
    isMulti,
  } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try{
    const response = await AttendanceTypeService.list();
    setOptions(response);
    accountTypeOption && accountTypeOption(response);
    }catch(err){
      console.log(err);
    }
  };

  return (
    <>
      <Select
        name={name ? name : "type"}
        label={label?label:"Type"}
        options={customOption ? customOption : options}
        placeholder={placeholder ? placeholder : "Select Type"}
        handleChange={handleChange}
        required={required}
        width={width}
        onInputChange={onChange}
        isDisabled={isDisabled}
        isMulti={isMulti}
      />
    </>
  );
};

export default AttendanceTypeSelect;
