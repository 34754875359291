import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { convertToRaw } from "draft-js";
import { useState } from "react";

// Components
import Drawer from "../../../components/Drawer";
import AccountForm from "./accountForm";
import SaveButton from "../../../components/SaveButton";
import Toast from "../../../components/Toast";

// Lib
import String from "../../../lib/String";
import Url from "../../../lib/Url";

// Services
import AccountService from "../../../services/AccountService";

// Actions
import { fetchList } from "../../../actions/table";

// API
import { endpoints } from "../../../api/endPoints";

// Helpers
import Vendor, { vendorStatusOptions } from "../../../helpers/Vendor";
import Account from "../../../helpers/Account";

const AddAccountModal = ({
  toggle,
  onModalClose,
  rows,
  name,
  editorState,
  setEditorState,
  pathName,
  activeTab,
}) => {
  const [status, setStatus] = useState("");
  const [type, setType] = useState(rows?.type || "");
  const [accountCategory, setAccountCategory] = useState();
  const [paymentAccountName, setpaymentAccountName] = useState([]);
  const [paymentAccountId, setpaymentAccountId] = useState();
  const [vendorValue, setVendorValue] = useState("");
  const [accountTypeOption, setAccountTypeOption] = useState([]);
  const [url, setUrl] = useState();
  const [cashDiscount, setCashDiscount] = useState();
  const [returnTerms, setReturnTerms] = useState();
  const [paymentTerms, setPaymentTerms] = useState();
  const [isSubmit, setIsSubmit] = useState();
  const [billingName, setBillingName] = useState("");
  const [gstNumber, setGstNumber] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();

  const dispatch = useDispatch();

  // Handle Project vendor form submit
  const handleSubmit = async (values) => {
    setIsSubmit(false);
    let rawComment;
    if (editorState) {
      rawComment = convertToRaw(editorState.getCurrentContent());
    }
    values.gst_number = values.gst_number ? values.gst_number : "";
    values.phoneNumber = values.phoneNumber ? values.phoneNumber : "",
    values.payment_account = values?.payment_account?.value
      ? values?.payment_account?.value
      : "";
    values.billing_name = values?.billing_name?.value
      ? values?.billing_name?.value
      : "";
    const newValues = Object.assign({}, values);
    newValues.status = status
      ? status
      : typeof newValues.status === "object"
      ? newValues.status.value
      : null;
    newValues.type = values && values?.type ? values?.type.value : "";
    newValues.accountCategory =
      pathName == "/customers"
        ? Vendor.TYPE_CUSTOMER_VALUE
        : pathName == "/vendor"
        ? Vendor.TYPE_VENDOR_VALUE
        : values && values?.type
        ? values?.type.value
        : "";
    newValues.notes = JSON.stringify(rawComment)
      ? JSON.stringify(rawComment)
      : "";
    newValues.tab = activeTab;

    let param = {
      status: Url.GetParam("status")
        ? Url.GetParam("status")
        : Vendor.STATUS_ACTIVE_VALUE,
      pagination: true,
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      search: Url.GetParam("search"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      accountType: Url.GetParam("accountType"),
    };

    if (pathName == "/customers") {
      param.accountCategory = Account.CATEGORY_CUSTOMER;
    }

    if (pathName == "/vendor") {
      param.accountCategory = Account.CATEGORY_VENDOR;
    }

    if (rows && rows?.id) {
      try {
        const response = await AccountService.updateVendor(rows?.id, newValues);
        dispatch(
          fetchList(
            "allVendor",
            pathName == "/accounts"
              ? `${endpoints().accountAPI}/search`
              : pathName == "/customers"
              ? `${endpoints().accountAPI}/search`
              : `${endpoints().accountAPI}/vendorSearch`,
            param.page ? param.page : 1,
            param.pageSize ? param.pageSize : 25,
            param
          )
        );
        Toast.success(response.message);
        onModalClose();
        setIsSubmit(true);
        setGstNumber(null);
      } catch (err) {
        const res = err.response;
        res && Toast.error(res.data.message);
        setIsSubmit(true);
      }
    } else {
      try {
        const response = await AccountService.createVendor(
          newValues,
          activeTab
        );
        dispatch(
          fetchList(
            "allVendor",
            pathName == "/accounts"
              ? `${endpoints().accountAPI}/search`
              : pathName == "/customers"
              ? `${endpoints().accountAPI}/search`
              : `${endpoints().accountAPI}/vendorSearch`,
            param.page ? param.page : 1,
            param.pageSize ? param.pageSize : 25,
            param
          )
        );
        Toast.success(response.message);
        onModalClose();
        setIsSubmit(true);
        setGstNumber(null);
      } catch (err) {
        const res = err.response;
        res && Toast.error(res.data.message);
        setIsSubmit(true);
      }
    }
  };

  useEffect(() => {
    setType("");
    setpaymentAccountName([]);
    setpaymentAccountId(null);
    setVendorValue("");
    setUrl("");
    setCashDiscount(null);
    setReturnTerms(null);
    setPaymentTerms(null);
    setAccountCategory("");
    setStatus("");
    setPhoneNumber("");
  }, [toggle]);

  const handleStatusChange = (selectStatus) => {
    if (selectStatus && selectStatus.value) {
      setStatus(selectStatus.value);
    }
  };

  const handleTypeChange = (e) => {
    setType(e?.value ? e.value : "");
    setAccountCategory(e?.category ? e.category : "");
  };

  const handlePaymentAccountChange = (e) => {
    setpaymentAccountId(e);
  };

  const vendorChange = (e) => {
    let data = e?.target?.value;
    setVendorValue(data);
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleChange = (e) => {
    let data = e?.target?.value;
    setUrl(data);
  };

  const handleBillingNameChange = (e) => {
    let value = e && e.value ? e.value : "";
    setBillingName(value);
  };

  const handleCashDiscount = (e) => {
    setCashDiscount(e?.values?.cash_discount);
  };

  const handleReturnTerms = (e) => {
    setReturnTerms(e?.target?.value);
  };

  const handlePaymentTerms = (e) => {
    setPaymentTerms(e?.target?.value);
  };

  const handleGstNumber = (e) => {
    setGstNumber(e?.target?.value ? e?.target?.value : "");
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e)
  }

  const addVendorForm = (
    <AccountForm
      vendorChange={vendorChange}
      handlePaymentAccountChange={handlePaymentAccountChange}
      handleStatusChange={handleStatusChange}
      handleTypeChange={handleTypeChange}
      type={accountCategory ? accountCategory : rows?.accountCategory}
      row={rows}
      setpaymentAccountName={setpaymentAccountName}
      editorState={editorState}
      handleEditorChange={handleEditorChange}
      vendorValue={vendorValue}
      handleChange={handleChange}
      handleCashDiscount={handleCashDiscount}
      handleReturnTerms={handleReturnTerms}
      handlePaymentTerms={handlePaymentTerms}
      handleBillingNameChange={handleBillingNameChange}
      name={name}
      accountTypeOption={setAccountTypeOption}
      handleGstNumber={handleGstNumber}
      handlePhoneNumberChange={handlePhoneNumberChange}
    />
  );

  const initialValue = {
    id: rows && rows?.vendorId,
    vendor_name: vendorValue
      ? String.Get(vendorValue)
      : rows && String.Get(rows?.vendorName),
    vendor_url: url ? url : rows && String.Get(rows.vendorUrl),
    status: status
      ? vendorStatusOptions.find((data) => data.value == status)
      : vendorStatusOptions.find((data) => data.label == rows?.status),
    gst_number: gstNumber ? gstNumber : rows && rows?.gst_number,
    type: type
      ? accountTypeOption.find((data) => data.value == type)
      : accountTypeOption.find((data) => data.value == rows?.typeId),
    cash_discount: cashDiscount ? cashDiscount : rows && rows?.cash_discount,
    return_terms: returnTerms
      ? returnTerms
      : rows && rows?.return_terms
      ? rows?.return_terms
      : "",
    payment_terms: paymentTerms
      ? paymentTerms
      : rows && rows?.payment_terms
      ? rows?.payment_terms
      : "",
    payment_account: paymentAccountId
      ? {
          label: paymentAccountId?.label,
          value: paymentAccountId?.value,
        }
      : paymentAccountName.find((items) =>
          items?.value == rows?.payment_account ? rows?.payment_account : ""
        ),
    billing_name:
      rows && rows.billingOption && rows.billingOption.length > 0 && billingName
        ? rows && rows.billingOption.find((data) => data.value == billingName)
        : rows &&
          rows.billingOption &&
          rows &&
          rows.billingOption.find((data) => data.value == rows?.billing_name),
    notes: rows?.notesText ? rows?.notesText : "",
    mobile: phoneNumber ? phoneNumber : rows?.mobile_number ?  rows?.mobile_number :""
  };

  const vendorFooter = (
    <SaveButton
      type="submit"
      loading={isSubmit == false}
      label={rows?.id ? "Save" : "Add"}
    />
  );

  return (
    <>
      <Drawer
        modelTitle={
          rows?.id
            ? `Edit ${
                pathName == "/accounts"
                  ? "Account"
                  : pathName == "/customers"
                  ? "Customer"
                  : "Vendor"
              }`
            : `Add ${
                pathName == "/accounts"
                  ? "Account"
                  : pathName == "/customers"
                  ? "Customer"
                  : "Vendor"
              }`
        }
        DrawerBody={addVendorForm}
        DrawerFooter={vendorFooter}
        onSubmit={handleSubmit}
        initialValues={initialValue}
        handleOpenModal={onModalClose}
        handleCloseModal={onModalClose}
        handleDrawerClose={onModalClose}
        isModalOpen={toggle}
        enableReinitialize
      />
    </>
  );
};

export default AddAccountModal;
