import React from "react";
import "./style.scss";

// Assets
import NoImage from "../../assets/img/noImage.png";

const index = ({ src, alt, size, className, minWidth, maxWidth, maxHeight, minHeight }) => {
  return (
    <div className="d-flex justify-content-center">
      <img
        src={src ? src : NoImage}
        alt={alt ? alt : "No Image"}
        className={`${size} ${className}`}
        style={{
          minWidth: minWidth,
          maxWidth: maxWidth,
          minHeight: minHeight,
          maxHeight: maxHeight
        }} />
    </div>
  )
}

export default index