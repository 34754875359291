import React from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

class CancelButton extends React.Component {
  render() {
    const { onClick, className, name, width, style, fontSize } = this.props;

    return (
      <Button
        id={name || "Cancel"}
        outline
        onClick={onClick}
        type="button"
        className={`m-1 border border-dark ${className}`}
        style={style ? style :
          {
            borderRadius: "7px",
            width: width ? width : "90px",
            fontSize: ".875rem",
          }}
      >
        <span className={fontSize}>{name || "Cancel"}</span>
      </Button>
    );
  }
}

CancelButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default CancelButton;
