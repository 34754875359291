import React, { useEffect, useState } from "react";

// Components
import Form from "../../../components/Form";
import SingleCheckbox from "../../../components/SingleCheckbox";
import Toast from "../../../components/Toast";

// lib
import { isBadRequest } from "../../../lib/Http";
import ArrayList from "../../../lib/ArrayList";
import { getKeyValueByObject } from "../../../lib/Helper";
import Number from "../../../lib/Number";

// Helpers
import ObjectName from "../../../helpers/ObjectName";
import { Setting } from "../../../helpers/Setting";

// Services
import { getSetings, saveSetting } from "../../../services/SettingService";

const SettingsTab = (props) => {
  const { accountId } = props;
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {

    //get company id from cookie
    let params = {
      object_id: Number.Get(accountId),
      object_name: ObjectName.ACCOUNT,
    };

    //get company setting
    const settingData = await getSetings(params);

    //set setting in state
    setSettings(settingData);
  };

  const handleUpdate = async (data) => {
    try {
      data.append("objectId", accountId);
      data.append("objectName", ObjectName.ACCOUNT);

      // Save settings
      saveSetting(data, null, () => {
        getSettings();
      });
    } catch (error) {
      console.log(error);
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(errorMessage);
      }
    }
  };

  const handleDuplicateInvoiceUpdate = async (values) => {
    try {
      const data = new FormData();

      data.append(
        Setting.ALLOW_DUPLICATE_INVOICE_NUMBER,
        values.allow_duplicate_invoice_number
      );
      handleUpdate(data)
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvoiceLineAmount = async (values) => {

    try {
      const data = new FormData();

      data.append(
        Setting.INVOICE_LINE_ITEM_AMOUNT_INCLUDES_TAX,
        values.invoice_line_item_amount_includes_tax
      );

      handleUpdate(data)

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form
      enableReinitialize={true}
      initialValues={{
        invoice_line_item_amount_includes_tax:
          settings &&
            ArrayList.isNotEmpty(settings) &&
            getKeyValueByObject(
              settings,
              Setting.INVOICE_LINE_ITEM_AMOUNT_INCLUDES_TAX
            ) == "true"
            ? true
            : false,
        [Setting.ALLOW_DUPLICATE_INVOICE_NUMBER]:
          settings &&
            ArrayList.isNotEmpty(settings) &&
            getKeyValueByObject(
              settings,
              Setting.ALLOW_DUPLICATE_INVOICE_NUMBER
            ) == "true"
            ? true
            : false,
      }}
    >
      <div className="card bg-white mt-3">
        <div className="card-body">
          <div>
            <SingleCheckbox
              name={Setting.INVOICE_LINE_ITEM_AMOUNT_INCLUDES_TAX}
              label="Invoice Line Item Amount Includes Tax"
              className="accepted-terms"
              handleOnChangeSubmit={(value, name) => handleInvoiceLineAmount(value, name)}
            />
          </div>
          <div className="mt-2">
            <SingleCheckbox
              name={Setting.ALLOW_DUPLICATE_INVOICE_NUMBER}
              label="Allow Duplicate Invoice Number"
              className="accepted-terms"
              handleOnChangeSubmit={(value, name) => handleDuplicateInvoiceUpdate(value, name)}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default SettingsTab;
