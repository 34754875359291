import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";

// Components
import AddButton from "../../components/AddButton";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import StatusSelect from "../../components/SelectStatus";
import StatusText from "../../components/StatusText";
import Text from "../../components/Text";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";

// Lib
import Url from "../../lib/Url";

// Services
import AppService from "../../services/AppService";

// Helpers
import App from "../../helpers/App";

const AppsSetting = (props) => {
    let { history } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [row, setRow] = useState(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    let dispatch = useDispatch();

    const _toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleAddButtonClick = () => {
        _toggle();
        setRow("");
    };

    const openToggle = () => {
        setIsOpen(true)
    }

    const closeToggle = () => {
        setIsOpen(false)
        setRow(null)
    }

    const closeDeleteToggle = () => {
        setIsDeleteOpen(false)
        setRow(null)
    }

    let customStatusOption = [
        {
            label: App.STATUS_ACTIVE_TEXT,
            value: App.STATUS_ACTIVE
        },
        {
            label: App.STATUS_INACTIVE_TEXT,
            value: App.STATUS_INACTIVE
        }
    ]

    let modelBody = (
        <>
            <Text
                name="name"
                label="Name"
                required
            />
            <Text
                name="nameSpace"
                label="Name Space"
                required
            />
            <StatusSelect
                name="status"
                label="Status"
                customStatusOption={customStatusOption}
                required
            />
        </>
    )

    let modelFooter = (
        <>
            <SaveButton label={row ? "Save" : "Add"} />
        </>
    )

    let initialValues = {
        name: row?.name ? row?.name : "",
        nameSpace: row?.nameSpace ? row?.nameSpace : "",
        status: customStatusOption.find((data) => data?.value == row?.statusId)
    }

    let handleSubmit = async (values) => {
        let data = new FormData()
        data.append("name", values && values?.name ? values?.name : "")
        data.append("nameSpace", values && values?.nameSpace ? values?.nameSpace : "")
        data.append("status", values && values?.status ? values?.status?.value : "");
        if (row?.id) {
            data.append("id", row?.id);
            dispatch(await AppService.update(data, (res) => {
                dispatch(
                    fetchList(
                        "apps",
                        `${endpoints().appAPI}/search`,
                        Url.GetParam("page") ? Url.GetParam("page") : 1,
                        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                        {
                            ...Url.GetAllParams()
                        }
                    )
                );
                closeToggle()
            }))
        } else {
            dispatch(await AppService.create(data, (res) => {
                dispatch(
                    fetchList(
                        "apps",
                        `${endpoints().appAPI}/search`,
                        Url.GetParam("page") ? Url.GetParam("page") : 1,
                        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                        {
                            ...Url.GetAllParams()
                        }
                    )
                );
                closeToggle()
            }))
        }
    }

    const handleDelete = async (value) => {
        dispatch(await AppService.delete({ data: { id: row?.id } }, (res) => {
            dispatch(
                fetchList(
                    "apps",
                    `${endpoints().appAPI}/search`,
                    Url.GetParam("page") ? Url.GetParam("page") : 1,
                    Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                    {
                        ...Url.GetAllParams()
                    }
                )
            );
            closeToggle()
        }))
    }

    const sortByOption = [
        {
            value: "id:DESC",
            label: "Most Recent",
        },
        {
            value: "name:ASC",
            label: "Name",
        },
    ];


    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <PageTitle label="Apps" />
                <AddButton
                    className="ms-2 me-1"
                    label="Add"
                    onClick={handleAddButtonClick}
                />
            </div>
            <DeleteModal
                isOpen={isDeleteOpen}
                toggle={closeDeleteToggle}
                title="Delete Image"
                label={row?.name}
                deleteFunction={handleDelete}
            />
            <Drawer
                isModalOpen={isOpen}
                handleOpenModal={openToggle}
                handleCloseModal={closeToggle}
                handleDrawerClose={closeToggle}
                modelTitle={row ? "Edit Apps" : "Add Apps"}
                DrawerBody={modelBody}
                DrawerFooter={modelFooter}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
                hideDefaultButtons
            />
            <ReduxTable
                id="apps"
                searchPlaceholder="Search"
                apiURL={`${endpoints().appAPI}/search`}
                newTableHeading
                paramsToUrl={true}
                history={props.history}
                sortByOptions={sortByOption}
                params={{
                    status: App.STATUS_ACTIVE_TEXT
                }}
                showStatusParam={false}
            >
                <ReduxColumn
                    field="name"
                    sortBy="name"
                    renderField={(row) => (
                        <Link
                            to={`/admin/settings/app/${row.id}`}
                            className="link-opacity-75 text-decoration-none"
                        >
                            {row.name}
                        </Link>
                    )}
                >
                    Name
                </ReduxColumn>
                <ReduxColumn
                    field="nameSpace"
                    sortBy="name_space"
                    className="text-center"
                >
                    Name Space
                </ReduxColumn>
                <ReduxColumn
                    field="status"
                    sortBy="status"
                    className="column-status"
                    renderField={(row) => (
                        <StatusText
                            backgroundColor={row.statusColor}
                            status={row.status}
                        />
                    )}
                >
                    Status
                </ReduxColumn>
                <ReduxColumn
                    field="Action"
                    disableOnClick
                    width="120px"
                    renderField={(row) => (
                        <div className="text-center action-group-dropdown">
                            <MoreDropdown>
                                <DropdownItem
                                    onClick={() => {
                                        setRow(row)
                                        openToggle()
                                    }}
                                >
                                    Quick View
                                </DropdownItem>
                                <DropdownItem
                                    className="text-danger"
                                    onClick={() => {
                                        setRow(row)
                                        setIsDeleteOpen(true)
                                    }}
                                >
                                    Delete
                                </DropdownItem>
                            </MoreDropdown>
                        </div>
                    )}
                >
                    Action
                </ReduxColumn>
            </ReduxTable>
        </>
    );
};

export default AppsSetting;
