import classnames from "classnames";
import React, { } from "react";
import {
  NavItem,
  NavLink,
} from "reactstrap";

const Tab = (props) => {
  const { name, active, toggle, handleChange, navigation } = props;

  return (
    <a
      href={navigation}
      className="text-decoration-none"
      style={{ color: "inherit" }}
      target="_blank"
      onClick={(e) => e.preventDefault()}
    >
      <NavItem>
        <NavLink
          className={classnames({
            active: active === name,
          })}
          onClick={() => {
            toggle(name);
            handleChange && handleChange(name);
          }}
        >
          {name}
       
        </NavLink>
      </NavItem>
    </a>
  );
};

export default Tab;
