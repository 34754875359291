import React, { useEffect } from "react";
import Select from "../Select";
import Color from "../../helpers/Color";


const ColorSelect = (props) => {
  const { label, name } = props


  return (
    <Select
      label={label}
      name={name ? name : "color"}
      options={Color.options()}
    />
  );
};

export default ColorSelect;
