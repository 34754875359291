import React from "react";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import ProductCard from "../../views/product/components/productCard";
import Button from "../../components/Button";

// Helpers
import { Status } from "../../helpers/Product";
import Modal from "../../components/Modal";

const ProductSelect = ({
  modalOpen,
  toggle,
  BulkSelect,
  toggleModalClose,
  initialValues,
  handleSubmit,
  history,
  id,
  footerLabel,
  bulkSelect = true,
  showFooter = true,
  data,
  selectedCheckBox
}) => {
 
  const modalBody = (
    <ReduxTable
      id={id ? id : "product"}
      showHeader
      dataList={data}
      apiURL={[]}
      onBulkSelect={BulkSelect}
      bulkSelect={bulkSelect}
      history={history}
      showFooter={showFooter}
      DropdownWithCheckbox
      selectedCheckBox={selectedCheckBox}
    >
      <ReduxColumn
        type="link"
        isClickable="true"
        sortBy="product_name"
        renderField={(row) => {
          return (
            <div>
              <ProductCard
                id={row.id}
                productImageIcon
                productName={row.name ? row.name : row.productName}
                brandName={row.brand ? row.brand : row.brand_name}
                size={row.size}
                unit={row.unit}
                salePrice={row.sale_price}
                mrp={row.mrp}
                url={row.image}
                status={
                  row.status == Status.INACTIVE_VALUE ? Status.INACTIVE : ""
                }
                packSize={row.pack_size}
                brand_id={row.brand_id}
                disableLink
              />
            </div>
          );
        }}
      >
        Product
      </ReduxColumn>
    </ReduxTable>
  );

  const productFooter = (
    <Button
      label={footerLabel ? footerLabel : "Merge"}
      onClick={(values) => {
        handleSubmit(values);
      }}
      showButtonLabelName
    />
  );

  return (
    <>
      <Modal
        className="expert-modal maxWidth-100"
        isOpen={modalOpen}
        toggle={toggle}
        toggleModalClose={toggleModalClose}
        modalTitle="Select Product"
        initialValues={initialValues ? initialValues : { product: "" }}
        modalBody={modalBody}
        modalFooter={showFooter ? productFooter : ""}
        FooterClassName="mt-5 pt-2"
        hideDefaultButtons
      />
    </>
  );
};

export default ProductSelect;
