import React, { useState, useEffect } from "react";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import PageTitle from "../../components/PageTitle";
import DeleteModal from "../../components/DeleteModal";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";

//Config
import { endpoints } from "../../api/endPoints";

//AP Client
import MoreDropdown from "../../components/authentication/moreDropdown";
import { DropdownItem } from "reactstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AddCategoryModal from "./component/AddModal";
import LoyaltyCategoryService from "../../services/loyaltyCategoryService";
import { fetchList } from "../../actions/table";
import Drawer from "../../components/Drawer";
import Text from "../../components/Text";
import SaveButton from "../../components/SaveButton";
import Url from "../../lib/Url";

const Category = (props) => {
  const [countryModalOpen, setCountryModalOpen] = useState();

  const [data, setData] = useState();

  const [deleteModal, setDeleteModal] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [rowValue, setRowValue] = useState(null);
  const dispatch = useDispatch();

  let tab = props.match.params.tab;
  const buttonLabel = true;

    let hasLoyaltyCategoryDeletePermission = hasPermission(Permission.LOYALTY_CATEGORY_DELETE);

  const sortByOption = [
    {
      value: "name",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const DrawerBody = (
    <>
      <Text
        label="Category Name"
        name="name"
        placeholder="Category Name"
        required
      />
    </>
  );

  let params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
  };

  const initialValues = {
    name: rowValue?.name ? rowValue?.name : "",
  };

  const DrawerFooter = (
    <>
      <SaveButton type="submit" label={rowValue ? "Edit" : "Create"} />
    </>
  );

  const toggle = () => {
    setModalOpen(!isModalOpen);
  };

  const _toggle = () => {
    setModalOpen(!isModalOpen);
    setRowValue("");
  };

  const handleSubmit = async (values) => {
    const data = new FormData();
    data.append("category_name", values?.name);
    try {
      if (rowValue && rowValue?.id) {
        // Update existing record
        LoyaltyCategoryService.update(rowValue?.id, data, (res) => {
          if (res) {
            setRowValue("");
            dispatch(
              fetchList(
                "loyaltyCategory",
                `${endpoints().loyaltyCategory}/search`,
                params?.page || 1,
                params?.pageSize || 25,
                { ...params }
              )
            );
            _toggle();
          }
        });
      } else {
        // Add new record
        await LoyaltyCategoryService.add(data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "loyaltyCategory",
                `${endpoints().loyaltyCategory}/search`,
                params?.page || 1,
                params?.pageSize || 25,
                { ...params }
              )
            );
            toggle();
          }
        });
      }
    } catch (err) {
      console.error("Error in handleSubmit", err);
    }
  };

  const handledelete = (id) => {
    try {
      dispatch(
        LoyaltyCategoryService.delete(id, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "loyaltyCategory",
                `${endpoints().loyaltyCategory}/search`,
                params?.page ? params?.page : 1,
                params?.pageSize ? params?.pageSize : 25,
                { ...params }
              )
            );
          }
        })
      );
    } catch (err) {}
  };

  return (
    <>
      <AddCategoryModal
        toggle={toggle}
        isOpen={countryModalOpen}
        onModalClose={toggle}
        hideDefaultButtons
      />

      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Category"
        id={data?.id}
        label={data?.name}
        deleteFunction={handledelete}
      />

      {/* /.page-heading */}
      <PageTitle label="Loyalty" buttonHandler={toggle} buttonLabel="Add New" />

      <Drawer
        modelTitle={rowValue ? "Edit Loyalty" : "Add Loyalty"}
        DrawerBody={DrawerBody}
        DrawerFooter={DrawerFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        enableReinitialize
        handleOpenModal={toggle}
        handleCloseModal={_toggle}
        handleDrawerClose={_toggle}
        isModalOpen={isModalOpen}
        buttonLabel={buttonLabel}
      />

      {/* Country list Table */}
      <ReduxTable
        id="loyaltyCategory"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().loyaltyCategory}/search`}
        sortByOptions={sortByOption}
        history={props.history}
        paramsToUrl={true}
        sortByDropdown
        showPageSize
      >
        <ReduxColumn
          field="name"
          sortBy="name"
          renderField={(row) => (
            <Link
              to={`/admin/settings/loyalty/${row.id}`}
              className="link-opacity-75 text-decoration-none lh-lg"
            >
              {row.name}
            </Link>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="text-center action-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  className="cursor-pointer"
                  onClick={() => {
                    setRowValue(row);
                    toggle();
                  }}
                >
                  Quick View
                </DropdownItem>
                {hasLoyaltyCategoryDeletePermission && <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    setData(row);
                    setDeleteModal(true);
                  }}
                >
                  Delete
                </DropdownItem>
          }
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};
export default Category;
