import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from '../../components/Form';
import SaveButton from '../../components/SaveButton';
import Select from '../../components/Select';
import SingleCheckbox from "../../components/SingleCheckbox";
import { Setting } from "../../helpers/Setting";
import { getKeyValueByObject } from "../../lib/Helper";
import { getCompanySettings, saveSetting } from "../../services/SettingService";
import TagTypeService from '../../services/TagTypeService';
import Number from '../../lib/Number';

const SettingPage = (props) => {
  const [typeList, setTypeList] = useState([]);
  const [locationCashAmount, setLocationCashAmount] = useState();
  const [orderCashAmount, setOrderCashAmount] = useState("");
  const [orderUpiAmount, setOrderUpiAmount] = useState("");
  const [receivedCashAmount, setReceivedCashAmount] = useState("");
  const [receivedUpiAmount, setReceivedUpiAmount] = useState("");
  const [draftAmount, setDraftAmount] = useState("");

  useEffect(() => {
    getFineTypes();
    getSettings();
  }, []);

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    let cash = getKeyValueByObject(
      settingData,
      Setting.LOCATION_CASH_AMOUNT_MISSING_FINE_TYPE
    );
    let order = getKeyValueByObject(
      settingData,
      Setting.ORDER_CASH_AMOUNT_MISSING_FINE_TYPE
    );
    let upi = getKeyValueByObject(
      settingData,
      Setting.ORDER_UPI_AMOUNT_MISSING_FINE_TYPE
    );
    let receivedCash = getKeyValueByObject(
      settingData,
      Setting.RECEIVED_CASH_AMOUNT_MISSING_FINE_TYPE
    );
    let receivedUpi = getKeyValueByObject(
      settingData,
      Setting.RECEIVED_UPI_AMOUNT_MISSING_FINE_TYPE
    );
    let draftOrderType = getKeyValueByObject(
      settingData,
      Setting.DRAFT_ORDER_AMOUNT_MISSING_FINE_TYPE
    );

    setLocationCashAmount(cash);
    setOrderCashAmount(order);
    setOrderUpiAmount(upi);
    setReceivedCashAmount(receivedCash);
    setReceivedUpiAmount(receivedUpi);
    setDraftAmount(draftOrderType);
  };
  const getFineTypes = async () => {
    let response = await TagTypeService.TagList("FineType");
    let data = response && response?.data;
    if (data && data.length > 0) {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        const { id, name } = data[i];
        list.push({
          value: id,
          label: name,
        });
      }
      setTypeList(list);
    }
  };
  const handleUpdate = async (values) => {
    let data = new FormData();
    data.append(
      Setting.LOCATION_CASH_AMOUNT_MISSING_FINE_TYPE,
      values &&
        Number.Get(values?.location_cash_amount_missing_fine_type?.value)
    );
    data.append(
      Setting.ORDER_CASH_AMOUNT_MISSING_FINE_TYPE,
      values && Number.Get(values?.order_cash_amount_missing_fine_type?.value)
    );
    data.append(
      Setting.ORDER_UPI_AMOUNT_MISSING_FINE_TYPE,
      values && Number.Get(values?.order_upi_amount_missing_fine_type?.value)
    );
    data.append(
      Setting.RECEIVED_CASH_AMOUNT_MISSING_FINE_TYPE,
      values &&
        Number.Get(values?.received_cash_amount_missing_fine_type?.value)
    );
    data.append(
      Setting.RECEIVED_UPI_AMOUNT_MISSING_FINE_TYPE,
      values && Number.Get(values?.received_upi_amount_missing_fine_type?.value)
    );
    data.append(
      Setting.DRAFT_ORDER_AMOUNT_MISSING_FINE_TYPE,
      values && Number.Get(values?.draft_order_amount_missing_fine_type?.value)
    );

    saveSetting(data, null, (res) => {
      if (res) {
      }
    });
  };

  const handleLocationCashAmount = (e) => {
    let value = e?.values?.location_cash_amount_missing_fine_type?.value ?? "";
    setLocationCashAmount(value);
  };

  const handleOrderCashAmount = (e) => {
    let value = e?.values?.order_cash_amount_missing_fine_type?.value || "";
    setOrderCashAmount(value);
  };

  const handleOrderUpiAmount = (e) => {
    let value = e?.values?.order_upi_amount_missing_fine_type?.value || "";
    setOrderUpiAmount(value);
  };

  const handleReceivedCashAmount = (e) => {
    let value = e?.values?.received_cash_amount_missing_fine_type?.value || "";
    setReceivedCashAmount(value);
  };

  const handleReceivedUpiAmount = (e) => {
    let value = e?.values?.received_upi_amount_missing_fine_type?.value || "";
    setReceivedUpiAmount(value);
  };

  const handleDraftOrderAmount = (e) => {
    let value = e?.values?.draft_order_amount_missing_fine_type?.value || "";
    setDraftAmount(value);
  };

  let initialValues = {
    location_cash_amount_missing_fine_type:
      (typeList &&
        typeList.length > 0 &&
        typeList.find(
          (data) => data?.value == Number.Get(locationCashAmount)
        )) ||
      "",
    order_cash_amount_missing_fine_type:
      (typeList &&
        typeList.length > 0 &&
        typeList.find((data) => data?.value == orderCashAmount)) ||
      "",
    order_upi_amount_missing_fine_type:
      (typeList &&
        typeList.length > 0 &&
        typeList.find((data) => data?.value == orderUpiAmount)) ||
      "",
    received_cash_amount_missing_fine_type:
      (typeList &&
        typeList.length > 0 &&
        typeList.find((data) => data?.value == receivedCashAmount)) ||
      "",
    received_upi_amount_missing_fine_type:
      (typeList &&
        typeList.length > 0 &&
        typeList.find((data) => data?.value == receivedUpiAmount)) ||
      "",
    draft_order_amount_missing_fine_type:
      (typeList &&
        typeList.length > 0 &&
        typeList.find((data) => data?.value == draftAmount)) ||
      "",
  };

  return (
    <div>
      <Form
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          handleUpdate(values);
        }}
      >
        <div className="card bg-white mb-3">
          <div className="card-body">
            <Select
              name={Setting.LOCATION_CASH_AMOUNT_MISSING_FINE_TYPE}
              label="Location Cash Amount Missing Fine Type"
              options={typeList}
              onInputChange={handleLocationCashAmount}
            />
            <Select
              name={Setting.ORDER_CASH_AMOUNT_MISSING_FINE_TYPE}
              label="Order Cash Amount Missing Fine Type"
              options={typeList}
              onInputChange={handleOrderCashAmount}
            />
            <Select
              name={Setting.ORDER_UPI_AMOUNT_MISSING_FINE_TYPE}
              label="Order Upi Amount Missing Fine Type"
              options={typeList}
              onInputChange={handleOrderUpiAmount}
            />
            <Select
              name={Setting.RECEIVED_CASH_AMOUNT_MISSING_FINE_TYPE}
              label="Received Cash Amount Missing Fine Type"
              options={typeList}
              onInputChange={handleReceivedCashAmount}
            />
            <Select
              name={Setting.RECEIVED_UPI_AMOUNT_MISSING_FINE_TYPE}
              label="Received Upi Amount Missing Fine Type"
              options={typeList}
              onInputChange={handleReceivedUpiAmount}
            />
            <Select
              name={Setting.DRAFT_ORDER_AMOUNT_MISSING_FINE_TYPE}
              label="Draft Order Amount Missing Fine Type"
              options={typeList}
              onInputChange={handleDraftOrderAmount}
            />

            <div className="mb-4">
              <div>
                <SaveButton />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default SettingPage;