import React, { useEffect, useState } from "react";
import Currency from "../../lib/Currency";
import Number from "../../lib/Number";
import SalaryService from "../../services/SalaryService";
import FormList from "./components/form";

function SalaryDetail(props) {
  const [salaryDetail, setSalaryDetail] = useState();
  const [userList, setUserList] = useState([]);

  let salary_id = props.match.params.id;
  useEffect(() => {
    getDetails();
  }, []);




  const initialValues = {
    user: userList && userList.find((data) => data?.id == salaryDetail?.user_id),
    startDate: salaryDetail?.startDate,
    endDate: salaryDetail?.endDate,
    working_days: salaryDetail?.working_days,
    worked_days: salaryDetail?.worked_days,
    leave_days: salaryDetail?.leave,
    additional_days: salaryDetail?.additional_days,
    absent: salaryDetail?.absent,
    basic: salaryDetail?.basic,
    hra: salaryDetail?.hra,
    standard_allowance: salaryDetail?.standard_allowance,
    special_allowance: salaryDetail?.special_allowance,
    medical_insurance: salaryDetail?.medical_insurance,
    gratuity: salaryDetail?.gratuity,
    professional_tax: salaryDetail?.professional_tax,
    net_salary: salaryDetail?.net_salary,
    monthly_salary: salaryDetail?.monthly_salary,
    salaryPerDay: salaryDetail?.salaryPerDay,
    leave_salary: salaryDetail?.leave_salary,
    tds: salaryDetail?.tds,
    other_deductions: salaryDetail?.other_deductions,
    additional_day_allowance: salaryDetail?.additional_day_allowance,
    provident_fund: salaryDetail?.provident_fund,
    bonus: Currency.Get(salaryDetail?.bonus),
    fine: salaryDetail?.fine,
    other_allowance: salaryDetail?.other_allowance,
    worked_days_salary: salaryDetail?.worked_days_salary,
    additional_hours: salaryDetail?.additional_hours,
    additional_hours_salary: salaryDetail?.additional_hours_salary,
    notes: salaryDetail?.notes,
  };

  const getDetails = async () => {
    const data = await SalaryService.get(salary_id);
    setSalaryDetail(data);
  };

  const salaryUpdate = async (values) => {
    const data = new FormData();
    data.append("other_deductions", Number.Get(values.other_deductions));
    data.append("other_allowance", Number.Get(values.other_allowance));
    data.append("user", Number.Get(values?.user?.id));
    data.append("notes", values && values.notes ? values?.notes : "");

    await SalaryService.update(salary_id, data, (res) => {
      if (res) {
        getDetails();
      }
    });
  };

  const breadcrumbList = [
    { label: "Home", link: "/salary" },
    { label: "Salary", link: `/salary/list/${salaryDetail?.salary_id}` },
    { label: "Salary Detail", link: "" },
  ];

  const calculate = async () => {
    const params = {
      salary_id: Number.Get(salaryDetail.salary_id),
      user: Number.Get(salaryDetail.user_id),
      id: Number.Get(salaryDetail?.id),
    };

    await SalaryService.getfilterData(params, (res) => {
      if (res) {
        getDetails();
      }
    });
  };

  return (
    <>
      <FormList
        pageTitle={`${salaryDetail?.first_name + " " + salaryDetail?.last_name}`}
        initialValues={initialValues}
        handleSubmit={salaryUpdate}
        breadcrumbList={breadcrumbList}
        userList={userList}
        id={props?.match?.params?.id}
        calculate={calculate}
        showUserDetailsPageLink={salaryDetail?.user_id ? true : false}
        userId={salaryDetail?.user_id}
        detail={salaryDetail}
        getDetails={getDetails}
        history={props.history}
        setUserList={setUserList}
      />
    </>
  );
}

export default SalaryDetail;
