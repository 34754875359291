import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import FineForm from "../fineForm";
import DeleteModal from "../../../components/DeleteModal";
import Drawer from "../../../components/Drawer";
import SaveButton from "../../../components/SaveButton";
import StatusText from "../../../components/StatusText";
import UserCard from "../../../components/UserCard";
import MoreDropdown from "../../../components/authentication/moreDropdown";

// Helpers
import ObjectName from "../../../helpers/ObjectName";
import { Fine } from "../../../helpers/Fine";
import MediaUpload from "../../../helpers/MediaUpload";
import * as Constants from "../../../helpers/Fine";

// Api
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../api/endPoints";

// Lib
import DateTime from "../../../lib/DateTime";
import Currency from "../../../lib/Currency";
import Url from "../../../lib/Url";
import ArrayList from "../../../lib/ArrayList";
import String from "../../../lib/String";
import Numbers from "../../../lib/Number";

// Actions
import { fetchList } from "../../../actions/table";

// Services
import StatusService from "../../../services/StatusService";
import CompanyUserService from "../../../services/UserService";
import { hasPermission } from "../../../services/UserRolePermissionService";
import Permission from "../../../helpers/Permission";
import { ALLOW_TO_VIEW_ENABLED } from "../../../helpers/Status";
import Media from "../../../helpers/Media";
import Cookies, { setCookie } from "../../../lib/Helper";
import Cookie from "../../../helpers/Cookie";

// Tabs Constants
const FineList = (props) => {
  const {
    history,
    rowValue,
    setRowValue,
    Toggle,
    setIsOpen,
    isOpen,
    user_id,
    onBulkSelect,
    bulkSelect,
    selectedCheckBox,
    showUserFilter,
    param = {},
    apiURL,
    service,
    apiUrls,
    showActionColumn=true,
    objectName
  } = props;

  let pathName = props?.history?.location?.pathname;

  const [userData, setUserData] = useState([]);
  const [fineTypeData, setFineTypeData] = useState();
  const [status, setStatus] = useState();
  const [defaultAmount, setDefaultAmount] = useState(null);
  const [type, setType] = useState();
  const [dueDate, setDueDate] = useState();
  const [date, setDate] = useState();
  const [amount, setAmount] = useState(null);
  const [user, setUser] = useState(null);
  const [reviewer, setReviewer] = useState();
  const [selectedFile, setSelectedFile] = useState("");
  const [productTagList, setProductTagList] = useState();
  const [statusList, setStatusList] = useState([]);
  const [statusDetail, setStatusDetail] = useState("");
  const [notesChange, setNotesChange] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [nextStatusList, setNextStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageurl, setImageUrl] = useState([]);
  const [arrayList, setArrayList] = useState([]);
  const [arrays, setArray] = useState([]);

  let fineManageOthersPermission = hasPermission(Permission.FINE_MANAGE_OTHERS);
  const dispatch = useDispatch();

  const buttonLabel = true;

  useEffect(() => {
    getStatus();
    getSeletedTagDetails();
    getUserList();
    getStatusList();
    getNextStatusList();
  }, []);

  useEffect(() => {
    const checkedList = Cookies.get(Cookie.FINE);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);

  function getKeyByValue(object, value) {
    let isSelected = false;
    for (const key in object) {
      if (key == value) {
        isSelected = object[key] == true ? true : false;
      }
    }
    return isSelected;
  }

  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.FINE, arrayList);
    setArray(array);
    setArrayList(array);
  };

  const handleImageRemove = async (deletedvalue) => {
    if (deletedvalue) {
      const updatedImageUrlArray = selectedFile.filter(
        (item) => item.id !== deletedvalue.image_id
      );
      await setIsLoading(true);
      setSelectedFile(updatedImageUrlArray);
      setImageUrl(updatedImageUrlArray);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      getUrl();
    }
  }, [isLoading, selectedFile]);

  const getUrl = () => {
    let url = [];
    if (selectedFile && selectedFile.length > 0) {
      for (let i = 0; i < selectedFile.length; i++) {
        const file = selectedFile[i];
        const imageUrl = URL.createObjectURL(file && file[0]);
        url.push({ url: imageUrl, image_id: file.id });
      }
      setImageUrl && setImageUrl(url);
    }
  };

  let params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
    status: Url.GetParam("status"),
    type: Url.GetParam("type"),
    user: Url.GetParam("user"),
    startDate: param?.startDate ? param?.startDate : Url.GetParam("startDate"),
    endDate: param?.endDate ? param?.endDate : Url.GetParam("endDate"),
    ...(param?.isFineType
      ? { isFineType: true }
      : param?.isBonusType
      ? { isBonusType: true }
      : pathName == "/fine"
      ? { isFineType: true }
      : pathName == "/bonus"
      ? { isBonusType: true }
      : { isFineType: true }),
    date: Url.GetParam("date"),
  };

  const uploadMedia = async (id) => {
    if (ArrayList.isArray(selectedFile)) {
      let mediaParams;
      for (let i = 0; i < selectedFile.length; i++) {
        mediaParams = {
          selectedFile: selectedFile[i][0] && selectedFile[i][0],
          objectId: id,
          ObjectName: objectName,
        };
        await MediaUpload.uploadFile(mediaParams, () => {});
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      const data = new FormData();
      data.append("date", values.date);
      data.append("due_date", values && values.due_date);
      data.append("user", Numbers.Get(values?.user?.id));
      data.append("type", Numbers.Get(values?.type?.value));
      data.append("amount", values?.amount ? values?.amount : "");
      data.append("status", values && values?.status.value);
      data.append("due_date", values?.due_date ? values?.due_date : "");
      data.append("notes", String.Get(values?.notes ? values?.notes : ""));
      data.append("reviewer", Numbers.Get(values?.reviewer?.id));

      if (rowValue && rowValue?.id) {
        await service.update(rowValue?.id, data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "fines",
                apiUrls ? apiUrls : `${apiURL}/search`,
                Url.GetParam("page") ? Url.GetParam("page") : 1,
                Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                {
                  ...params,
                  showTotalAmount: true,
                }
              )
            );
            openToggle();
          }
        });
      } else {
        await dispatch(
          service.add(
            data,
            {
              ...params,
            },
            async (id) => {
              if (id) {
                dispatch(
                  fetchList(
                    "fines",
                    apiUrls ? apiUrls : `${apiURL}/search`,
                    Url.GetParam("page") ? Url.GetParam("page") : 1,
                    Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                    {
                      ...params,
                      showTotalAmount: true,
                    }
                  )
                );
                await uploadMedia(id);
              }
            }
          )
        );
      }
      closeToggle();
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const getStatusList = async () => {
    const getStatus = [];
    const status = await StatusService.search(
      param?.isFineType
        ? ObjectName.FINE
        : param?.isBonusType
        ? ObjectName.BONUS
        : pathName == "/fine"
        ? ObjectName.FINE
        : pathName == "/bonus"
        ? ObjectName.BONUS
        : ObjectName.FINE
    );
    for (let i = 0; i < status.length; i++) {
      getStatus.push({
        label: status[i].name,
        value: status[i].id,
      });
    }
    setStatusList(getStatus);
  };

  const getNextStatusList = async (currentStatusId, allowed_statuses) => {
    try {
      if (currentStatusId) {
        const data = await StatusService.nextStatusSearch(
          param?.isFineType
            ? ObjectName.FINE
            : param?.isBonusType
            ? ObjectName.BONUS
            : pathName == "/fine"
            ? ObjectName.FINE
            : pathName == "/bonus"
            ? ObjectName.BONUS
            : ObjectName.FINE,
          currentStatusId,
          "",
          allowed_statuses
        );

        if (data && data.length > 0) {
          setNextStatusList(data);
          return data;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSeletedTagDetails = async () => {
    try {
      const response = await apiClient.get(`${endpoints().tagApi}/list`);

      const tagDetails = response.data.data;

      const tagList = [];
      if (ArrayList.isNotEmpty(tagDetails)) {
        tagDetails &&
          tagDetails.forEach((tag) => {
            if (
              param?.isFineType
                ? tag.type == "FineType"
                : param?.isBonusType
                ? tag.type == "BonusType"
                : pathName == "/fine"
                ? tag.type == "FineType"
                : pathName == "/bonus"
                ? tag.type == "BonusType"
                : tag.type == "FineType"
            ) {
              tagList.push({
                label: tag.name,
                value: tag.id,
                id: tag.id,
                status: tag.status,
              });
            }
          });
      }
      setProductTagList(tagList);
    } catch (err) {}
  };

  const getStatus = async () => {
    const status = await StatusService.search(
      ObjectName.FINE,
      Fine.STATUS_DRAFT
    );
    for (let i = 0; i < status.length; i++) {
      setStatus(status[i]?.id);
    }
  };

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const getUserName = (media_url, firstName, lastName) => {
    return (
      <div className="d-flex">
        <UserCard
          id="avatar"
          firstName={firstName}
          lastName={lastName}
          url={media_url}
        />
      </div>
    );
  };

  const getUserList = async () => {
    const data = await CompanyUserService.list();

    const userList = [];
    data &&
      data.data.forEach((list) => {
        userList.push({
          value: list.id,
          id: list.id,
          label: getUserName(
            list?.media_url,
            list?.first_name,
            list?.last_name
          ),
        });
      });
    setUserData(userList);
  };

  const closeToggle = () => {
    Toggle && Toggle();
    setReviewer("");
    setDueDate("");
    setType("");
    setDate("");
    setDueDate("");
    setDefaultAmount(null);
    setIsOpen(!isOpen);
    setSelectedFile("");
    setStatusDetail("");
    setAmount(null);
    setUser(null);
    setNotesChange("");
    setSelectedFile([]);
    setImageUrl([]);
  };

  const openToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleTagChange = (values) => {
    let value = values;
    if (amount === null || amount === "") {
      setAmount(value?.amount ? value?.amount : "");
    }
    setDefaultAmount(value);
  };

  const handleStatusChange = (values) => {
    setStatusDetail(values?.values?.status ? values?.values?.status : "");
  };

  const handleNotesChange = (e) => {
    let value = e?.target?.value ? e?.target?.value : "";
    setNotesChange(value);
  };

  const handleDueDateChange = (values) => {
    setDueDate(values);
  };

  const handleDateChange = (values) => {
    setDate(values);
  };

  const handleAmountChange = (values) => {
    let value = values.target.value;
    setAmount(value);
  };

  const handleUserChange = (e) => {
    let value = e;
    setUser(value);
  };

  const handleReviewerChange = (e) => {
    let value = e;
    setReviewer(value);
  };

  const handleImageValue = (images) => {
    setIsLoading(true);
    setSelectedFile([...selectedFile, { ...images }]);
    setIsLoading(false);
  };

  const onDrop = (acceptedFiles) => {
    handleImageValue({
      ...acceptedFiles,
      id: selectedFile.length + 1,
    });
  };
  const fineForm = (
    <FineForm
      imageUrl={imageurl}
      onDrop={onDrop}
      selectedFile={selectedFile}
      handleDelete={handleImageRemove}
      rowValue={rowValue}
      handleTagChange={handleTagChange}
      handleDateChange={handleDateChange}
      statusList={statusList}
      handleStatusChange={handleStatusChange}
      handleNotesChange={handleNotesChange}
      handleDueDateChange={handleDueDateChange}
      handleAmountChange={handleAmountChange}
      handleUserChange={handleUserChange}
      handleReviewerChange={handleReviewerChange}
      showUserDetailsPageLink={rowValue?.id ? true : false}
      userId={rowValue?.id ? rowValue?.userId : null}
      reviewerId={rowValue?.id ? rowValue?.reviewerId : null}
      objectId={rowValue?.object_id ? rowValue?.object_id : null}
      objectName={rowValue?.object_name ? rowValue?.object_name : null}
      userList={setUserList}
      typeId={rowValue?.typeId}
      params={
        param?.isFineType
          ? { type: "FineType" }
          : param?.isBonusType
          ? { type: "BonusType" }
          : pathName == "/fine"
          ? { type: "FineType" }
          : pathName == "/bonus"
          ? { type: "BonusType" }
          : { type: "FineType" }
      }
      isLoading={isLoading}
    />
  );

  const fineFooter = (
    <div>
      <SaveButton type="submit" label={rowValue?.id ? "Save" : "Add"} />
    </div>
  );

  const handleFineDelete = async (id) => {
    dispatch(
      await service.delete(id, () => {
        dispatch(
          fetchList(
            "fines",
            apiUrls ? apiUrls : `${apiURL}/search`,
            Url.GetParam("page") ? Url.GetParam("page") : 1,
            Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
            {
              ...params,
              showTotalAmount: true,
            }
          )
        );
      })
    );
  };

  const onStatusChange = async (value, id) => {
    const data = {};
    data.status = value;
    dispatch(
      await service.updateStatus(id, data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "fines",
              apiUrls ? apiUrls : `${apiURL}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams(),
                showTotalAmount: true,
              }
            )
          );
        }
      })
    );
  };

  const FieldLabel = [
    {
      value: Constants.FieldLabel.DUE_DATE,
      label: Constants.FieldLabel.DUE_DATE,
    },
    {
      value: Constants.FieldLabel.REVIEWER,
      label: Constants.FieldLabel.REVIEWER,
    },
  ];

  const due_date =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.DUE_DATE)
      ? true
      : false;
  const reviewers =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.REVIEWER)
      ? true
      : false;

  return (
    <>
      <Drawer
        modelTitle={
          rowValue?.id
            ? param?.isBonusType
              ? "Edit Bonus"
              : "Edit Fine"
            : param?.isFineType
            ? "Add Fine"
            : param?.isBonusType
            ? "Add Bonus"
            : pathName === "/fine"
            ? "Add Fine"
            : pathName === "/bonus"
            ? "Add Bonus"
            : "Add Fine"
        }
        DrawerBody={fineForm}
        DrawerFooter={fineFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={{
          date: date
            ? DateTime.getTodayDateByUserTimeZone(date)
            : rowValue?.date
            ? rowValue?.date
            : DateTime.getTodayDateByUserTimeZone(new Date()),
          user: user
            ? user
            : rowValue?.userId
            ? userList && userList.find((data) => data.id == rowValue?.userId)
            : user_id
            ? userData && userData.find((data) => data.value == user_id)
            : (userData && userData.find((data) => data.value == user)) || "",
          type: defaultAmount
            ? {
                label: defaultAmount?.label,
                value: defaultAmount?.value,
              }
            : rowValue
            ? {
                label: rowValue?.type,
                value: rowValue?.typeId,
              }
            : (fineTypeData &&
                fineTypeData.find((data) => data.value == type)) ||
              "",
          notes: notesChange
            ? notesChange
            : rowValue?.notes
            ? rowValue?.notes
            : "",
          reviewer: reviewer
            ? reviewer
            : rowValue?.reviewerId
            ? userList &&
              userList.find((data) => data.id == rowValue?.reviewerId)
            : "",
          status: statusDetail
            ? statusDetail
            : rowValue
            ? {
                label: rowValue?.status,
                value: rowValue?.statusId,
              }
            : statusList
            ? {
                label: statusList[0]?.label,
                value: statusList[0]?.value,
              }
            : "",
          amount: amount
            ? amount
            : amount == ""
            ? ""
            : rowValue?.amount
            ? rowValue?.amount
            : "",
          due_date: dueDate
            ? DateTime.getTodayDateByUserTimeZone(dueDate)
            : rowValue?.due_date
            ? rowValue?.due_date
            : DateTime.getTodayDateByUserTimeZone(new Date()),
          objectName: rowValue?.object_name || "",
          objectId: rowValue?.object_id || "",
        }}
        enableReinitialize
        handleOpenModal={openToggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
        buttonLabel={buttonLabel}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false);
        }}
        title={
          param?.isFineType
            ? "Delete Fine"
            : param?.isBonusType
            ? "Delete Bonus"
            : pathName === "/fine"
            ? "Delete Fine"
            : pathName === "/bonus"
            ? "Delete Bonus"
            : "Delete Fine"
        }
        deleteFunction={() => {
          handleFineDelete(rowValue?.id);
        }}
        label={rowValue?.id}
        id={rowValue?.id}
      />
      <div>
        <ReduxTable
          id="fines"
          showHeader
          newTableHeading
          apiURL={apiUrls ? apiUrls : `${apiURL}/search`}
          searchPlaceholder="Search"
          params={{
            sort: Url.GetParam("sort"),
            sortDir: Url.GetParam("sortDir"),
            objectName:objectName,
            user: user_id ? user_id : null,
            ...param,
            showTotalAmount: true,
          }}
          sortByOptions={sortByOption}
          objectName={
            objectName
          }
          showTypeFilter
          customTypeOption={productTagList}
          showCustomDateFilter
          showUserFilter={showUserFilter === false ? false : true}
          showStatusFilter
          bulkSelect={bulkSelect === false ? false : true}
          onBulkSelect={onBulkSelect}
          selectedCheckBox={selectedCheckBox}
          paramsToUrl={true}
          history={props.history}
          icon={<FontAwesomeIcon icon={faCartShopping} />}
          message="You can start by clicking on Add Fine"
          statusParamAllowToView={
            !fineManageOthersPermission ? ALLOW_TO_VIEW_ENABLED : null
          }
          showBackgroundColor
          isMultiType
          DropdownWithCheckbox
          handleColumnChange={handleColumnChange}
          FieldLabel={FieldLabel}
          arrayList={arrayList}
          customStatusOption={statusList}
        >
          <ReduxColumn
            width="130px"
            minWidth="100px"
            maxWidth="90px"
            field="id"
            sortBy="createdAt"
            type="link"
            isClickable="true"
            className="text-center"
            renderField={(row) => (
              <Link
                to={`${
                  param?.isFineType
                    ? "/fine"
                    : param?.isBonusType
                    ? "/bonus"
                    : pathName
                }/${row.id}`}
                className="link-opacity-75"
              >
                {row.id}
              </Link>
            )}
          >
            {param?.isFineType
              ? "Fine#"
              : param?.isBonusType
              ? "Bonus#"
              : pathName === "/fine"
              ? "Fine#"
              : pathName === "/bonus"
              ? "Bonus#"
              : "Fine#"}
          </ReduxColumn>
          <ReduxColumn
            width="110px"
            className="text-center"
            minWidth="110px"
            maxWidth="110px"
            sortBy="date"
            renderField={(row) => <span>{DateTime.getDate(row.date)}</span>}
          >
            Date
          </ReduxColumn>
          <ReduxColumn
            field="user"
            className="ellipsis text-start"
            sortBy="user_id"
            minWidth="130px"
            renderField={(row) => (
              <>
                <UserCard
                  customSize={parseInt(50, 10)}
                  firstName={row.firstName}
                  url={row.media_url}
                  lastName={row.lastName}
                />
              </>
            )}
          >
            User
          </ReduxColumn>
          <ReduxColumn
            field="type"
            className="ellipsis text-center"
            minWidth="130px"
            sortBy="type"
          >
            Type
          </ReduxColumn>
          {reviewers && reviewers == true && (
            <ReduxColumn
              field="reviewer"
              className="ellipsis text-center"
              minWidth="130px"
              sortBy="reviewer"
              renderField={(row) => (
                <>
                  <UserCard
                    customSize={parseInt(50, 10)}
                    firstName={row.reviewerFirstName}
                    url={row.reviewerMediaUrl}
                    lastName={row.reviewerLastName}
                  />
                </>
              )}
            >
              Reviewer
            </ReduxColumn>
          )}
          {due_date && due_date == true && (
            <ReduxColumn
              field="due_date"
              className="ellipsis text-center"
              minWidth="130px"
              sortBy="dueDate"
              renderField={(row) => (
                <span>{DateTime.getDate(row.due_date)}</span>
              )}
            >
              DueDate
            </ReduxColumn>
          )}

          <ReduxColumn
            sortBy="amount"
            width="90px"
            minWidth="130px"
            className="text-end"
            renderField={(row) => <span>{Currency.Format(row.amount)}</span>}
          >
            Amount
          </ReduxColumn>
          <ReduxColumn
            field="createdAt"
            sortBy="createdAt"
            className="text-center"
            width="150px"
            minWidth="100px"
            maxWidth="150px"
            renderField={(row) => <span>{row.createdAt}</span>}
          >
            Created At
          </ReduxColumn>
          <ReduxColumn
            field="status"
            sortBy="status"
            width={"130px"}
            minWidth="130px"
            maxWidth="130px"
            className="column-status"
            renderField={(row) => (
              <StatusText
                backgroundColor={row.statusColor}
                status={row.status}
              />
            )}
          >
            Status
          </ReduxColumn>
         {showActionColumn && <ReduxColumn
            field="Action"
            disableOnClick
            width="70px"
            renderField={(row) => (
              <>
                <div className="text-center action-group-dropdown">
                  <MoreDropdown
                    onClick={() => {
                      setNextStatusList([]);
                      getNextStatusList(row.statusId, row.next_status_id);
                    }}
                  >
                    <DropdownItem
                      onClick={() => {
                        setRowValue(row);
                        openToggle();
                      }}
                    >
                      Quick View
                    </DropdownItem>
                    {nextStatusList &&
                      nextStatusList.length > 0 &&
                      nextStatusList.map((data) => {
                        return (
                          <DropdownItem
                            onClick={() => {
                              onStatusChange(data?.value, row?.id);
                            }}
                          >
                            {data?.label}
                          </DropdownItem>
                        );
                      })}

                    <DropdownItem
                      className={"text-danger"}
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setRowValue(row);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </MoreDropdown>
                </div>
              </>
            )}
          >
            Action
          </ReduxColumn>}
        </ReduxTable>
      </div>
    </>
  );
};

export default FineList;
