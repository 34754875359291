// Account Constant
const Account = {
  PRIMARY_ENABLED: 1,

  PRIMARY_DISABLED: 0,

  STATUS_ACTIVE: "Active",

  TYPE_CREDIT: "Credit",

  TYPE_DEBIT: "Debit",

  STATUS_ACTIVE: "Active",

  STATUS_INACTIVE: "InActive",

  ACTIVE: 1,
  
  INACTIVE: 2,

  CATEGORY_CUSTOMER: 1,

  CATEGORY_EMPLOYEE: 2,

  CATEGORY_VENDOR: 3,

  CATEGORY_USER: 4,

  TAB_CUSTOMER: "Customers",

  TAB_EMPLOYEE: "Employees",

  ADD_PRODUCTS_FROM_PURCHASE: "Add Products From Purchase",

  BULK_UPDATE: "Bulk Update",
};
export default Account;
