import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Components
import Action from "../../components/Action";
import ActivityList from "../../components/ActivityList";
import BreadCrumb from "../../components/Breadcrumb";
import CancelButton from "../../components/CancelButton";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Spinner from "../../components/Spinner";
import Status from "../../components/Status";
import CandidateForm from "./components/form";
import Comment from "../../components/comment";

// Services
import CandidateService from "../../services/CandidateService";
import JobService from "../../services/JobService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Helper
import { LanguageKnown } from "../../helpers/Candidate";
import { Jobs } from "../../helpers/Job";
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";

// Lib
import Number from "../../lib/Number";

export const Tab = {
  GENERAL: "General",
  PRODUCTS: "Products",
  HISTORY: "History",
  COMMENTS: "Comments"
};

const CandidateDetail = (props) => {
  const [details, setDetails] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [jobsDetail, setJobsDetail] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState();
  const [position, setPosition] = useState();
  const [expectedSalary, setExpectedSalary] = useState(null);
  const [gender, setGender] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const Param = new URLSearchParams(props.history.location.search);
  const tab = Param.get("tab");
  const [activeTab, setActiveTab] = useState(tab ? tab : Tab.GENERAL);
  const [editable, setEditable] = useState(true);
  const [qualificationValue, setQualificationValue] = useState(null);
  const [departmentValue, setDepartmentValue] = useState(null);
  const [statyingInValue, setStatyingInValue] = useState(null);
  const [yearOfPassingValue, setYearOfPassingValue] = useState(null);
  const [languageKnown, setLanguageKnown] = useState(null);
  const [messageValue, setMessageValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCityValue, setCurrentCityValue] = useState(null);
  const [currentStateValue, setCurrentStateValue] = useState(null);
  const [permanentCityValue, setPermanentCityValue] = useState(null);
  const [permanentStateValue, setPermanentStateValue] = useState(null);
  const [ageOnchangeValue, setAgeOnchangeValue] = useState(null);

  let candidateId =
    props && props?.match && props?.match?.params && props?.match?.params?.id;

  const dispatch = useDispatch();

  let showHistory = hasPermission(Permission.CANDIDATE_HISTORY_VIEW);

  let hasEditPermission = hasPermission(Permission.CANDIDATE_EDIT);

  let hasDeletePermission = hasPermission(Permission.CANDIDATE_DELETE);

  useEffect(() => {
    getCandidateDetail();
    getJobDetails();
  }, []);

  const genderOptions = [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];

  const maritialStatusOptions = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Married",
      label: "Married",
    },
  ];

  const returnMultiSelectValue = (lId) => {
    if (Number.isNotNull(lId)) {
      let splitLanguage = lId;
      const languages = LanguageKnown.filter((lang) =>
        splitLanguage.includes(lang?.value?.toString())
      );

      return languages;
    }
  };

  const initialValues = {
    firstName: firstName ? firstName : details?.first_name || "",
    lastName: lastName ? lastName : details?.last_name || "",
    email:
      email === ""
        ? email
        : email
          ? email
          : details?.email
            ? details?.email
            : "",
    gender: gender
      ? gender
      : details?.gender
        ? genderOptions.find((data) => details?.gender == data.value)
        : "",
    skills: details?.skills || "",
    interviewDate: details?.interview_date || null,
    phone:
      phoneNumber === ""
        ? phoneNumber
        : phoneNumber
          ? phoneNumber
          : details?.phone || "",
    maritalStatus: maritalStatus
      ? maritalStatus
      : details?.marital_status
        ? maritialStatusOptions.find(
          (data) => details?.marital_status == data.value
        )
        : "",
    age: ageOnchangeValue ? ageOnchangeValue : details?.age || null,
    position: position
      ? position
      : details?.position_id
        ? jobsDetail.find((data) => data?.value == details?.position_id)
        : "",
    qualification: qualificationValue
      ? qualificationValue
      : details?.qualification || "",
    currentAddress: details?.current_address || "",
    currentArea: details?.current_area || "",
    currentCountry: details?.current_country || "",
    currentCity: currentCityValue
      ? currentCityValue
      : details?.current_city || "",
    currentState: currentStateValue
      ? currentStateValue
      : details?.current_state || "",
    currentPincode:
      (details?.current_pincode && (details?.current_pincode).toString()) || "",
    permanentAddress: details?.permanent_address || "",
    permanentArea: details?.permanent_area || "",
    permanentCountry: details?.permanent_country || "",
    permanentCity: permanentCityValue
      ? permanentCityValue
      : details?.permanent_city || "",
    permanentState: permanentStateValue
      ? permanentStateValue
      : details?.permanent_state || "",
    permanentPincode:
      (details?.permanent_pincode && (details?.permanent_pincode).toString()) ||
      "",
    department: departmentValue ? departmentValue : details?.department || "",
    yearOfPassing: yearOfPassingValue
      ? yearOfPassingValue
      : details?.year_of_passing || "",
    over_all_experience: details?.overall_experience || "",
    projectTitle: details?.project_title || "",
    projectPeriod: details?.project_period || "",
    projectDescription: details?.project_description || "",
    courseName: details?.course_name || "",
    coursePeriod: details?.course_period || "",
    courseInstitution: details?.course_institution || "",
    currentSalary: details?.current_salary || null,
    expected_salary: expectedSalary
      ? expectedSalary
      : details?.expected_salary || null,
    message: messageValue ? messageValue : details?.message || "",
    percentage: details?.percentage || "",
    positionType: details?.position_type || "",
    dob: details?.dob || null,
    relevantExperience: details?.relevant_experience || null,
    expectedCostPerHour: details?.expected_cost_per_hour || null,
    jobReferenceType: details?.job_reference_type || "",
    jobReferenceName: details?.job_reference_name || "",
    willingToWorkInShift: details?.willing_to_work_in_shift || "",
    stayingIn: statyingInValue ? statyingInValue : details?.staying_in || "",
    tenthPercentage: details?.tenth_percentage || "",
    tenthYearOfPassing: details?.tenth_year_of_passing || null,
    twelvethPercentage: details?.twelveth_percentage || "",
    twelvethYearOfPassing: details?.twelveth_year_of_passing || null,
    degreeArrear: details?.degree_arrear || "",
    didCourse: details?.did_course || false,
    didProject: details?.did_project || false,
    linkedinProfileName: details?.linkedin_profile_name || "",
    facebookProfileName: details?.facebook_profile_name || "",
    jobTitle: details?.job_title || "",
    joinedMonth: details?.joined_month || null,
    joinedYear: details?.joined_year || null,
    companyName: details?.company_name || "",
    knownLanguages: details?.known_languages || "",
    employmentEligibility: details?.employment_eligibility || "",
    didVaccineStatus: details?.vaccine_status || "",
    languages_known: languageKnown
      ? languageKnown
      : languageKnown == ""
        ? languageKnown
        : returnMultiSelectValue(details?.known_languages),
  };

  const getCandidateDetail = async () => {
    const id = props.match.params.id;
    const response = await CandidateService.get(id);
    setDetails(response?.data?.data);
  };

  const getJobDetails = async () => {
    let response = await JobService.search({
      status: Jobs.STATUS_ACTIVE,
    });
    let data = response && response?.data && response?.data?.data;
    if (response && response?.data && response?.data?.data) {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        const { job_title, id, slug } = data[i];
        list.push({
          label: job_title,
          id: id,
          value: id,
          slug: slug,
        });
      }

      setJobsDetail(list);
    } else {
      setJobs([]);
    }
  };

  const breadcrumbList = [
    { label: "Home", link: "/job/dashboard" },
    {
      label: "Candidate",
      link: "/jobs/candidate",
    },
    {
      label: "Candidate Details",
      link: "",
    },
  ];

  const actionsMenuList = [];

  if (hasEditPermission && editable && activeTab === Tab.GENERAL) {
    actionsMenuList.unshift({
      label: "Edit",
      value: "Edit",
    });
  }

  if (hasDeletePermission && activeTab === Tab.GENERAL) {
    actionsMenuList.push({
      value: "Delete",
      label: "Delete",
    });
  }

  const handleDelete = async (id) => {
    dispatch(await CandidateService.delete(id));
    props.history.push("/jobs/candidate");
  };

  const handleSubmit = async (values) => {
    try {
      let splitLanguage =
        values && values?.languages_known
          ? values?.languages_known?.map((data) => data?.value)?.join(",")
          : "";
      values.maritalStatus = values?.maritalStatus?.value;
      values.gender = values?.gender?.value;
      values.position_id = values?.position?.value;
      values.position = values?.position?.label;
      values.knownLanguages = splitLanguage;
      const id = props.match.params.id;
      await CandidateService.update(id, values, (res) => {
        if (res) {
          setIsLoading(true);
          getCandidateDetail();
          setIsLoading(false);
        }
      });
      setIsLoading(true);
      getCandidateDetail();
      setIsLoading(false);
      setEditable(true);
    } catch (error) {
      console.log(error);
    }
  };

  const toggle = (tab) => {
    setIsOpen(!isOpen);
    setActiveTab(tab);
  };

  const _handleTabChange = (tab) => {
    props.history.push(`?tab=${tab}`);
  };

  const handleActionChange = (e) => {
    if (e === "Delete") {
      setDeleteModal(true);
    }
    if (e == "Edit") {
      setEditable(false);
    }
  };

  const onStatusChange = async (values) => {
    const data = { status: values };
    const id = props?.match?.params?.id;

    dispatch(
      CandidateService.updateStatus(id, data, (res) => {
        if (res) {
          setEditable(true);
          setIsLoading(true);
          getCandidateDetail();
          setIsLoading(false);
        }
        setEditable(true);
      })
    );
  };

  const handleMaritalStatusChange = (e) => {
    let value = e?.values?.maritalStatus;
    setMaritalStatus(value);
  };

  const handlePositionChange = (e) => {
    let value = e?.values?.position;
    setPosition(value);
  };

  const handleExpectedSalaryChange = (e) => {
    let value = e?.values?.expected_salary;
    setExpectedSalary(value);
  };

  const handleGenderChange = (e) => {
    let value = e?.values?.gender;
    setGender(value);
  };

  const handleFirstNameChange = (e) => {
    let value = e?.target?.value;
    setFirstName(value);
  };

  const handleLastNameChange = (e) => {
    let value = e?.target?.value;
    setLastName(value);
  };

  const handlePhoneNumberChange = (e) => {
    let value = e;
    setPhoneNumber(value);
  };

  const handleEmailChange = (e) => {
    let value = e?.target?.value;
    setEmail(value);
  };

  const handleQualificationChange = (e) => {
    let value = e?.target?.value;
    setQualificationValue(value);
  };

  const handleDepartmentChange = (e) => {
    let value = e?.target?.value;
    setDepartmentValue(value);
  };

  const handleStatyingWithChange = (e) => {
    let value = e?.target?.value;
    setStatyingInValue(value);
  };

  const handleYearOfPassingChange = (e) => {
    let value = e?.target?.value;
    setYearOfPassingValue(value);
  };

  const handleLanguagesKnownChange = (e) => {
    let values =
      e && e?.values && e?.values?.languages_known
        ? e?.values?.languages_known
        : "";
    setLanguageKnown(values);
  };

  const handleMessageChange = (e) => {
    let value = e?.target?.value;
    setMessageValue(value);
  };

  const handleCurrentCityChange = (e) => {
    let value = e?.target?.value;
    setCurrentCityValue(value);
  };

  const handleCurrentStateChange = (e) => {
    let value = e?.target?.value;
    setCurrentStateValue(value);
  };

  const handlePermanentCityChange = (e) => {
    let value = e?.target?.value;
    setPermanentCityValue(value);
  };

  const handlePermanentStateChange = (e) => {
    let value = e?.target?.value;
    setPermanentStateValue(value);
  };

  const handleAgeChange = (e) => {
    let value = e?.target?.value;
    setAgeOnchangeValue(value);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mb-4 pb-4 mb-sm-0 pb-sm-0">
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label={"Candidate Details"} />
        <div className="d-flex">
          <Status
            objectName={ObjectName.CANDIDATE}
            handleChange={(values) => onStatusChange(values)}
            buttonLabel={details?.status?.name}
            currentStatusId={details?.status?.id}
          />
          {actionsMenuList && actionsMenuList?.length > 0 && (
            <div className="mx-2">
              <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        isOpen={deleteModal}
        title="Delete Candidate"
        toggle={() => {
          setDeleteModal(false);
        }}
        label={`${details?.first_name} ${details?.last_name}`}
        deleteFunction={() => handleDelete(details.id)}
      />
      <Nav tabs className="admin-tabs mb-1">
        {/* GENERAL Tab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.GENERAL,
            })}
            onClick={() => {
              toggle(Tab.GENERAL);
              _handleTabChange(Tab.GENERAL);
            }}
          >
            General
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.COMMENTS,
            })}
            onClick={() => {
              toggle(Tab.COMMENTS);
              _handleTabChange(Tab.COMMENTS);
            }}
          >
            Comments
          </NavLink>
        </NavItem>

        {/* HISTORY tab */}
        {showHistory && (
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => {
                toggle(Tab.HISTORY);
                _handleTabChange(Tab.HISTORY);
              }}
            >
              History
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        {activeTab == Tab.GENERAL && (
          <TabPane tabId={Tab.GENERAL}>
            <div className="card p-3">
              <Form
                enableReinitialize
                initialValues={initialValues}
                // onSubmit={(values)=>onSubmit(values)}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                <CandidateForm
                  handlePositionChange={handlePositionChange}
                  handleExpectedSalaryChange={handleExpectedSalaryChange}
                  handleGenderChange={handleGenderChange}
                  handleMaritalStatusChange={handleMaritalStatusChange}
                  handleFirstNameChange={handleFirstNameChange}
                  handleLastNameChange={handleLastNameChange}
                  handlePhoneNumberChange={handlePhoneNumberChange}
                  handleEmailChange={handleEmailChange}
                  details={details}
                  editable={editable}
                  handleQualificationChange={handleQualificationChange}
                  handleDepartmentChange={handleDepartmentChange}
                  handleYearOfPassingChange={handleYearOfPassingChange}
                  handleStatyingWithChange={handleStatyingWithChange}
                  handleLanguagesKnownChange={handleLanguagesKnownChange}
                  handleMessageChange={handleMessageChange}
                  handleCurrentCityChange={handleCurrentCityChange}
                  handleCurrentStateChange={handleCurrentStateChange}
                  handlePermanentCityChange={handlePermanentCityChange}
                  handlePermanentStateChange={handlePermanentStateChange}
                  handleAgeChange={handleAgeChange}
                />
                <>
                  {!editable && (
                    <>
                      <SaveButton />
                      <CancelButton
                        onClick={() => props.history.push("/jobs/candidate")}
                      />
                    </>
                  )}
                </>
              </Form>
            </div>
          </TabPane>
        )}
        {activeTab == Tab.COMMENTS &&
          <TabPane tabId={Tab.COMMENTS} className="w-100">
            <Comment
              objectId={candidateId}
              objectName={ObjectName.CANDIDATE}
              maxHeight="100vh"
              owner_id={details?.owner_id ? details?.owner_id : null}
            />
          </TabPane>
        }
        {showHistory && activeTab == Tab.HISTORY && (
          <TabPane tabId={Tab.HISTORY} className="w-100 ">
            <ActivityList
              id={candidateId}
              objectId={candidateId}
              object_name={ObjectName.CANDIDATE}
            />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

export default CandidateDetail;
