import React, { useEffect, useState } from "react";
import {
    BarElement,
    CategoryScale,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import Chart from "chart.js/auto";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Currency from "../../../lib/Currency";
import { getRandomColor } from "../../../lib/Helper";
import Spinner from "../../../components/Spinner";
import Number from "../../../lib/Number";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LocationPerformanceGraph = (props) => {
    let { graphArrayData = [], key1, key2, totalAmount = 0, title, isLoading, isQuantityWise=false } = props;
    const [colorList, setColorList] = useState([]);


    useEffect(() => {
        getRandomColors();
    }, [isLoading]);



    const getRandomColors = () => {
        let color = getRandomColor(graphArrayData.length);
        setColorList(color);
    };

    const graphBarData = graphArrayData && graphArrayData.length > 0 && graphArrayData.map((value, index) => [value[key1], isQuantityWise ? Number.Get(value[key2]):  Currency.Get(value[key2])]);
console.log('graphBarData>>>------------------------> ', graphBarData);
    if (isLoading) {
        return <Spinner />
    }

    return (
        <>
            <div className="App">
                <h3 className="text-center">
                    {`${title}`}
                </h3>
            </div>

            <div className="overflow-x-auto" style={{ width: '100%' }} id="scroll-view-v1">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                        chart: {
                            type: 'column',
                            scrollablePlotArea: {
                                minWidth: 700,
                            },
                            ...(graphBarData && graphBarData.length > 30 ? { width: 10000 } : {}),
                        },
                        credits: {
                            enabled: false,
                        },
                        title: {
                            text: " ",
                        },
                        xAxis: {
                            type: 'category',
                            labels: {
                                autoRotation: [-45, -90],
                                style: {
                                    fontSize: '13px',
                                    fontFamily: 'Verdana, sans-serif',
                                },
                            },
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: ' ',
                            },
                            labels: {
                                formatter: function () {
                                    if(isQuantityWise){
                                        return Number.Get(this.value)
                                    }else{
                                        return Currency.GetFormatted(this.value);
                                    }
                                },
                            },
                        },
                        legend: {
                            enabled: false,
                        },
                        tooltip: {
                            pointFormat: isQuantityWise ? '<b>{point.y}</b>' :  '<b>₹{point.y}</b>',
                        },
                        series: [
                            {
                                name: 'Population',
                                colors: colorList,
                                colorByPoint: true,
                                groupPadding: 0,
                                data: graphBarData,
                                dataLabels: {
                                    enabled: true,
                                    rotation: -60,
                                    color: 'black',
                                    align: 'center',
                                    format: isQuantityWise ? '<b>{point.y}</b>' :  '<b>₹{point.y}</b>',
                                    style: {
                                        fontSize: '13px',
                                        fontFamily: 'Verdana, sans-serif',
                                    },
                                },
                            },
                        ],
                    }}
                />
            </div>
        </>
    );
};
export default LocationPerformanceGraph;
