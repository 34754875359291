import React from "react";
import { format } from "date-fns";

const UserMonthWiseCard = ({ data, manageOthers }) => {
    const { first_name, last_name, media_url, order_month, shift_name } = data;

    const formattedMonth = order_month ? format(new Date(order_month), "MMM-yyyy") : "";

    const initials = `${first_name?.charAt(0) || ""}${last_name?.charAt(0) || ""}`;

    const cardStyle = {
        display: "flex",
    };

    const avatarStyle = {
        width: "48px",
        height: "48px",
        borderRadius: "50%",
        objectFit: "cover",
        border: "2px solid rgb(251, 252, 253)"
    };

    const initialsStyle = {
        width: "48px",
        height: "48px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        backgroundColor: initials ? "#9ca3af" : "",
        color: "white",
        fontWeight: "bold",
        fontSize: "18px",
        border: initials ? "2px solid #6b7280" : ""
    };

    const detailsStyle = {
        marginLeft: "12px"
    };

    const nameStyle = {
        fontSize: "16px",
        fontWeight: "600",
        color: "#1f2937",
        marginBottom: "2px"
    };

    const infoStyle = {
        fontSize: "14px",
    };
    
    return (
        <div style={cardStyle}>
            {media_url ? (
                manageOthers && <img src={media_url} style={avatarStyle} />
            ) : (
                manageOthers && <div style={initialsStyle}>
                    {initials?.toUpperCase()}
                </div>
            )}

            <div style={detailsStyle}>
                {manageOthers && <h2 style={nameStyle}>{first_name} {last_name}</h2>}
                <h2 style={infoStyle}>
                    {formattedMonth} {shift_name && <span>({shift_name})</span>}
                </h2>
            </div>
        </div>
    );
};

export default UserMonthWiseCard;
