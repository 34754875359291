import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { DropdownItem } from "reactstrap";

// Components
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import AddButton from "../../components/AddButton";
import SaveButton from "../../components/SaveButton";
import Drawer from "../../components/Drawer";
import CustomerForm from "./components/CustomersForm";
import MoreDropdown from "../../components/authentication/moreDropdown";
import DeleteModal from "../../components/DeleteModal";

//Config
import { endpoints } from "../../api/endPoints";

// Services
import customerService from "../../services/CustomerService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Actions
import { fetchList } from "../../actions/table";

// Lib
import Url from "../../lib/Url";
import Currency from "../../lib/Currency";
import String from "../../lib/String";

// Helpers
import Permission from "../../helpers/Permission";

const Customer = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  const [isSubmit, setIsSubmit] = useState(true);
  const [customerDeleteModal, setCustomerDeleteModal] = useState(false);
  const [rowValue, setRowValue] = useState("");

  //Sort By Option Values
  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "first_name:ASC",
      label: "Name",
    },
  ];

  const { history } = props;

  const dispatch = useDispatch();

  let hasDeletePermission = hasPermission(Permission.CUSTOMER_DELETE);
  let hasAddPermission = hasPermission(Permission.CUSTOMER_ADD);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const closeDeleteModal = () => {
    setCustomerDeleteModal(!customerDeleteModal);
    setRowValue(null);
  };

  const addCustomerForm = <CustomerForm />;

  const customerFormFooter = (
    <SaveButton
      type="submit"
      loading={isSubmit == false}
      label={data?.id ? "Save" : "Add"}
    />
  );

  let params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    search: Url.GetParam("search"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    date: Url.GetParam("date"),
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate"),
  };

  const handleCreate = async (values) => {
    setIsSubmit(false);
    try {
      const datas = new FormData();
      datas.append("first_name", values?.first_name);
      datas.append("last_name", values.last_name);
      datas.append("mobile_number", values.mobile_number);

      if (data?.id) {
        dispatch(
          await customerService.update(data?.id, datas, (res) => {
            if (res) {
              dispatch(
                fetchList(
                  "customers",
                  `${endpoints().customerAPI}/search`,
                  params.page ? params.page : 1,
                  params.pageSize ? params.pageSize : 25,
                  {
                    ...params,
                  }
                )
              );
              toggle();
              setIsSubmit(true);
            }
          })
        );
      } else {
        dispatch(
          await customerService.create(datas, (res) => {
            if (res) {
              dispatch(
                fetchList(
                  "customers",
                  `${endpoints().customerAPI}/search`,
                  params.page ? params.page : 1,
                  params.pageSize ? params.pageSize : 25,
                  {
                    ...params,
                  }
                )
              );
              toggle();
              setIsSubmit(true);
            }
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCustomer = async () => {
    try {
      await customerService.delete(rowValue.id, {});
      closeDeleteModal();
      dispatch({
        type: "REMOVE_CUSTOMER",
        payload: rowValue.id,
      });

      dispatch(
        fetchList(
          "customers",
          `${endpoints().customerAPI}/search`,
          Url.GetParam("page") ? Url.GetParam("page") : 1,
          Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
          {
            ...params,
          }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    first_name: data?.first_name ? data?.first_name : "",
    last_name: data?.last_name ? data?.last_name : "",
    mobile_number: data?.mobile_number ? data?.mobile_number : "",
  };

  return (
    <>
      <DeleteModal
        isOpen={customerDeleteModal}
        title="Delete Customer"
        id={rowValue?.id}
        label={
          rowValue?.first_name
            ? String.concatName(rowValue?.first_name, rowValue?.last_name)
            : rowValue?.id
        }
        toggle={closeDeleteModal}
        deleteFunction={deleteCustomer}
      />
      <Drawer
        modelTitle={data?.id ? "Edit Customer" : "Add Customer"}
        DrawerBody={addCustomerForm}
        DrawerFooter={customerFormFooter}
        onSubmit={(values) => {
          handleCreate(values);
        }}
        initialValues={initialValues}
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize
      />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Customers" />
        {hasAddPermission && (
          <div className="d-flex">
            <AddButton
              className="ms-2 me-1"
              label="Add New"
              onClick={(e) => {
                setData("");
                toggle();
              }}
            />
          </div>
        )}
      </div>
      <ReduxTable
        id="customers"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().customerAPI}/search`}
        history={history}
        params={{}}
        sortByOptions={sortByOption}
        paramsToUrl={true}
        showCustomDateFilter
      >
        <ReduxColumn
          field="first_name"
          sortBy="first_name"
          width="130px"
          maxWidth="170px"
          minWidth="170px"
          renderField={(row) => (
            <Link
              to={`/customers/details/${row.id}`}
              className="link-opacity-75"
            >
              {row.first_name}
            </Link>
          )}
        >
          First Name
        </ReduxColumn>
        <ReduxColumn
          field="last_name"
          sortBy="last_name"
          width="130px"
          maxWidth="130px"
          minWidth="130px"
        >
          Last Name
        </ReduxColumn>
        <ReduxColumn
          field="mobile_number"
          sortBy="mobile_number"
          className="text-center"
          width="150px"
          maxWidth="150px"
          minWidth="150px"
        >
          Mobile Number
        </ReduxColumn>
        <ReduxColumn
          field="order_count"
          sortBy="order_count"
          className="text-center"
          width="150px"
          maxWidth="150px"
          minWidth="150px"
        >
          Order Count
        </ReduxColumn>
        <ReduxColumn
          field="order_amount"
          sortBy="order_amount"
          width="130px"
          maxWidth="170px"
          minWidth="170px"
          className="text-end"
          renderField={(row) => <>{Currency.GetFormatted(row?.order_amount)}</>}
        >
          Order Amount
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setData(row);
                      toggle();
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  {hasDeletePermission && (
                    <DropdownItem
                      className="text-danger"
                      onClick={() => {
                        setCustomerDeleteModal(true);
                        setRowValue(row);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default Customer;
