import React, { useEffect, useState } from "react";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import BreadCrumb from "../../components/Breadcrumb";
import UserCard from "../../components/UserCard";

// API
import { endpoints } from "../../api/endPoints";

// Lib
import Currency from "../../lib/Currency";
import Cookies, { setCookie } from "../../lib/Helper";
import ArrayList from "../../lib/ArrayList";

// Helpers
import { Salary } from "../../helpers/Salary";
import Cookie from "../../helpers/Cookie";

const SalaryProjectionReport = (props) => {
  const { history } = props;
  const [arrays, setArray] = useState([]);
  const [arrayList, setArrayList] = useState([]);
  const sortOptions = [
    {
      value: "name:ASC",
      label: "Name",
    },
  ];
  useEffect(() => {
    const checkedList = Cookies.get(Cookie.SALARY_PROJECTION_REPORT);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);
  // Breadcrumb list
  const breadcrumbList = [
    { label: "Home", link: "/accountDashboard" },
    { label: "Report", link: "/accountReports" },
    { label: "Salary Projection Report", link: "" },
  ];
  const FieldLabel = [
    {
      value: Salary.LEAVE,
      label: Salary.LEAVE,
    },
    {
      value: Salary.ABSENT,
      label: Salary.ABSENT,
    },
    {
      value: Salary.ADDITIONAL_DAYS,
      label: Salary.ADDITIONAL_DAYS,
    },
    {
      value: Salary.ADDITIONAL_DAY_SALARY,
      label: Salary.ADDITIONAL_DAY_SALARY,
    },
    {
      value: Salary.ADDITIONAL_HOURS,
      label: Salary.ADDITIONAL_HOURS,
    },
    {
      value: Salary.WORKED_DAYS,
      label: Salary.WORKED_DAYS,
    },
    {
      value: Salary.WORKING_DAYS,
      label: Salary.WORKING_DAYS,
    },
    {
      value: Salary.ADDITIONAL_HOURS_SALARY,
      label: Salary.ADDITIONAL_HOURS_SALARY,
    },
    {
      value: Salary.FINE,
      label: Salary.FINE,
    },
    {
      value: Salary.LOP,
      label: Salary.LOP,
    },
  ];

  // Handle Column Sort
  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.SALARY_PROJECTION_REPORT, arrayList);
    setArray(array);
    setArrayList(array);
  };

  const enableLeave =
    arrays && ArrayList.getKeyByValue(arrays, Salary.LEAVE) ? true : false;

  const enableAbsent =
    arrays && ArrayList.getKeyByValue(arrays, Salary.ABSENT) ? true : false;

  const enableAdditionalDays =
    arrays && ArrayList.getKeyByValue(arrays, Salary.ADDITIONAL_DAYS)
      ? true
      : false;

  const enableAdditionalDaySalary =
    arrays && ArrayList.getKeyByValue(arrays, Salary.ADDITIONAL_DAY_SALARY)
      ? true
      : false;

  const enableAdditionalHours =
    arrays && ArrayList.getKeyByValue(arrays, Salary.ADDITIONAL_HOURS)
      ? true
      : false;

  const enableWorkedDays =
    arrays && ArrayList.getKeyByValue(arrays, Salary.WORKED_DAYS)
      ? true
      : false;

  const enableWorkingDays =
    arrays && ArrayList.getKeyByValue(arrays, Salary.WORKING_DAYS)
      ? true
      : false;

  const enableAdditionalHourSalary =
    arrays && ArrayList.getKeyByValue(arrays, Salary.ADDITIONAL_HOURS_SALARY)
      ? true
      : false;

  const enableFine =
    arrays && ArrayList.getKeyByValue(arrays, Salary.FINE) ? true : false;

  const enableLop =
    arrays && ArrayList.getKeyByValue(arrays, Salary.LOP) ? true : false;

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />

      <ReduxTable
        id="accountEntryReport"
        sortByOptions={sortOptions}
        showHeader
        newTableHeading
        showCustomDateFilter
        apiURL={`${endpoints().salaryUserAPI}/projectionReport`}
        message="You can start by clicking Add New"
        paramsToUrl={true}
        history={history}
        searchPlaceholder="Search"
        showUserFilter
        FieldLabel={FieldLabel}
        handleColumnChange={handleColumnChange}
        arrayList={arrayList}
        DropdownWithCheckbox
      >
        <ReduxColumn
          field="user"
          sortBy="user"
          minWidth="250px"
          maxWidth="250px"
          className="border border-left"
          renderField={(row) => (
            <>
              <UserCard
                customSize={parseInt(50, 10)}
                firstName={row.firstName}
                url={row.avatarUrl}
                lastName={row.LastName}
              />
            </>
          )}
        >
          User
        </ReduxColumn>
        <ReduxColumn
          field="startDate"
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          className="border border-right"
          sortBy="start_date"
        >
          Start Date
        </ReduxColumn>
        <ReduxColumn
          field="endDate"
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          className="border border-right"
          sortBy="end_date "
        >
          End Date
        </ReduxColumn>
        <ReduxColumn
          field="monthlySalary"
          sortBy="monthlySalary"
          className="border border-right text-end"
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          renderField={(row) => (
            <span>{Currency.Format(row.monthlySalary)}</span>
          )}
        >
          Monthly Salary
        </ReduxColumn>
        {enableWorkingDays && (
          <ReduxColumn
            field="totalWorkingDays"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="border border-right"
            sortBy="totalWorkingDays"
          >
            Working Days
          </ReduxColumn>
        )}
        {enableWorkedDays && (
          <ReduxColumn
            field="worked"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="border border-right"
            sortBy="worked"
          >
            Worked Days
          </ReduxColumn>
        )}
        {enableLeave && (
          <ReduxColumn
            field="leave"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="border border-right"
            sortBy="leave"
          >
            Leave
          </ReduxColumn>
        )}
        {enableAbsent && (
          <ReduxColumn
            field="absent"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="border border-right"
            sortBy="absent"
          >
            Absent
          </ReduxColumn>
        )}
        {enableAdditionalDays && (
          <ReduxColumn
            field="additional"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="border border-right"
            sortBy="additional"
          >
            Additional Days
          </ReduxColumn>
        )}
        {enableAdditionalDaySalary && (
          <ReduxColumn
            field="additional_day_allowance"
            sortBy="additional_day_allowance"
            className="border border-right text-end"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            renderField={(row) => (
              <span>{Currency.Format(row.additional_day_allowance)}</span>
            )}
          >
            Additional Day Salary
          </ReduxColumn>
        )}
        {enableAdditionalHours && (
          <ReduxColumn
            field="additional_hours"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            className="border border-right"
            sortBy="additional_hours"
          >
            Additional Hours
          </ReduxColumn>
        )}
        {enableAdditionalHourSalary && (
          <ReduxColumn
            field="additionalHourAmount"
            sortBy="additionalHourAmount"
            className="border border-right text-end"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            renderField={(row) => (
              <span>{Currency.Format(row.additionalHourAmount)}</span>
            )}
          >
            Additional Hours Salary
          </ReduxColumn>
        )}
        {enableFine && (
          <ReduxColumn
            field="fine"
            className="border border-right text-end"
            sortBy="fine"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            renderField={(row) => <span>{Currency.Format(row.fine)}</span>}
          >
            Fine
          </ReduxColumn>
        )}
        {enableLop && (
          <ReduxColumn
            field="leave_salary"
            className="border border-right text-end"
            sortBy="fine"
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            renderField={(row) => <span>{Currency.Format(row.leave_salary)}</span>}
          >
            Leave Salary
          </ReduxColumn>
        )}
        <ReduxColumn
          className="text-end border border-right"
          field="net_salary"
          width="150px"
          minWidth="150px"
          maxWidth="150px"
          sortBy="net_salary"
          renderField={(row) => <span>{Currency.Format(row.net_salary)}</span>}
        >
          Net Salary
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

export default SalaryProjectionReport;
