import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";

// Components
import CancelButton from "../../components/CancelButton";
import Form from "../../components/Form";
import SaveButton from "../../components/SaveButton";
import FineForm from "./fineForm";
import ActivityList from "../../components/ActivityList";
import MediaCarousel from "../../components/MediaCarousel";
import Action from "../../components/Action";
import Comment from "../../components/comment";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import PageTitle from "../../components/PageTitle";
import Spinner from "../../components/Spinner";
import StatusComponent from "../../components/Status";
import UserCard from "../../components/UserCard";

// Lib
import Numbers from "../../lib/Number";
import DateTime from "../../lib/DateTime";
import Url from "../../lib/Url";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import Urls from "../../helpers/Url";
import Permission from "../../helpers/Permission";

// Services
import MediaService from "../../services/MediaService";
import CompanyUserService from "../../services/UserService";
import TagService from "../../services/TagService";
import { hasPermission } from "../../services/UserRolePermissionService";
import StatusService from "../../services/StatusService";
import fineService from "../../services/FineService";

export const Tab = {
  GENERAL: "General",
  HISTORY: "History",
  COMMENTS: "Comments",
};

const FineDetail = (props) => {
  const { history } = props;
  const [userData, setUserData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [fineData, setFineData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setMediaDelete] = useState(false);
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") || Tab.GENERAL
  );
  const [tagList, setTagList] = useState();
  const [defaultAmount, setDefaultAmount] = useState();
  const [amount, setAmount] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [date, setDate] = useState();
  const [notesChange, setNotesChange] = useState(null);
  const [dueDate, setDueDate] = useState();
  const [user, setUser] = useState();
  const [reviewer, setReviewer] = useState();
  const [statusList, setStatusList] = useState([]);
  const [statusDetail, setStatusDetail] = useState("");
  const [editable, setEditable] = useState(true);

  const dispatch = useDispatch();

  let showHistory = hasPermission(Permission.FINE_HISTORY_VIEW);

  let id = props?.match?.params?.id;

  let pathName = props?.match?.path;



  let hasEditPermission = hasPermission(Permission.FINE_EDIT);

  let hasDeletePermission = hasPermission(Permission.FINE_DELETE);

  let isFineStatusEditable = hasPermission(Permission.FINE_STATUS_UPDATE);

  const getUser = (media_url, firstName, LastName) => {
    return (
      <>
        <UserCard lastName={LastName} firstName={firstName} url={media_url} />
      </>
    );
  };

  useEffect(() => {
    getUserList();
    getFineDetails();
    getStatusList();
  }, []);

  useEffect(() => {
    if (fineData && fineData.type) {
      getTagList();
    }
  }, [fineData]);

  const getTagList = async () => {
    let tagList = await TagService.getOption();
    setTagList(tagList);
  };

  const handleStatusChange = (values) => {
    setStatusDetail(values?.values?.status ? values?.values?.status : "");
  };

  const getUserList = async () => {
    const data = await CompanyUserService.list();
    let user = data?.data;
    const userList = [];
    user &&
      user.forEach((list) => {
        userList.push({
          value: list.id,
          id: list.id,
          label: getUser(list?.media_url, list?.first_name, list?.last_name),
        });
      });
    setUserData(userList);
  };

  const getFineDetails = async () => {
    let Id = props?.match?.params?.id;
    const data = await fineService.get(Id);
    setFineData(data?.data?.data);
  };

  const handleSubmit = async (values) => {
    const data = new FormData();
    data.append("date", values?.date && DateTime.formatDate(values?.date));
    data.append("user", Numbers.Get(values?.user?.id));
    data.append("reviewer", Numbers.Get(values?.reviewer?.id));
    data.append("amount", Numbers.Get(values?.amount));
    data.append("status", values && values?.status.value);
    data.append("type", Numbers.Get(values?.type?.value));
    data.append("notes", values?.notes ? values?.notes : "");
    data.append("due_date", values && DateTime.formatDate(values?.due_date));
    dispatch(
      await fineService.update(props?.match?.params?.id, data, (res) => {
        if (res) {
          setIsLoading(true);
          getFineDetails();
          setIsLoading(false);
          setEditable(true);
        }
      })
    );
  };

  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    {
      label: "Fines",
      link: Urls.FINE_LIST,
    },
    {
      label: "Fine Details",
      link: "",
    },
  ];

  const onStatusChange = async (value) => {
    const data = {};
    data.status = value;
    const id = props.match.params.id;
    dispatch(await fineService.updateStatus(id, data, (res) => {
      if (res) {
        setIsLoading(true);
        getFineDetails();
        setIsLoading(false);
      }
    }));
  };

  const deleteFine = async (id) => {
    try {
      await fineService.delete(id, (res) => {
        if (res) {
          history.push("/fine");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusList = async () => {
    const status = await StatusService.getOption(ObjectName.FINE);
    setStatusList(status);
  };

  const deleteMedia = async (mediaId) => {
    if (mediaId) {
      MediaService.delete(mediaId, () => {
        getFineDetails();
      });
    }
  };

  const toggle = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  const actionsMenuList = [];

  if (hasDeletePermission) {
    actionsMenuList.push(
      {
        value: "delete",
        label: "Delete",
      },
    )
  }

  if (hasEditPermission && editable && activeTab !== Tab.HISTORY) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleTagChange = (values) => {
    let value = values;
    setDefaultAmount(value);
    setAmount(value?.default_value ? value?.default_value : "");
  };

  const handleDateChange = (values) => {
    setDate(values);
  };

  const handleDueDateChange = (values) => {
    setDueDate(values);
  };

  const handleUserChange = (e) => {
    let value = e;
    setUser(value);
  };

  const handleReviewerChange = (e) => {
    let value = e;
    setReviewer(value);
  };

  const handleNotesChange = (e) => {
    let value = e?.target?.value ? e?.target?.value : "";
    setNotesChange(value);
  };

  const handleAmountChange = (values) => {
    let value = values.target.value;
    setAmount(value);
  };

  const __toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <DeleteModal
        id={fineData?.id}
        label={fineData?.id}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title={"Delete Fine"}
        deleteFunction={() => deleteFine(fineData?.id, {})}
      />
      <DeleteModal
        id={fineData?.id}
        label={fineData?.mediaName}
        isOpen={openDeleteModal}
        toggle={() => {
          setMediaDelete(false);
        }}
        title="Delete Media"
        deleteFunction={() => deleteMedia(fineData?.mediaId)}
      />
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label={"Fine Details"} />
        <div className="d-flex">
          {isFineStatusEditable == true && (
            <div className="ms-2">
            <StatusComponent
              objectName={ObjectName.FINE}
              handleChange={(values) => onStatusChange(values)}
              buttonLabel={fineData?.statusName}
              currentStatusId={fineData?.statusValue}
            />
          </div>)}
          {actionsMenuList && actionsMenuList.length > 0 && (
            <div className="ms-2">
              <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            </div>
          )}
        </div>
      </div>
      <Nav tabs className="admin-tabs">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.GENERAL,
            })}
            onClick={() => {
              toggle(Tab.GENERAL);
            }}
          >
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.COMMENTS,
            })}
            onClick={() => {
              toggle(Tab.COMMENTS);
            }}
          >
            {Tab.COMMENTS}
          </NavLink>
        </NavItem>
        {showHistory && (
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => {
                toggle(Tab.HISTORY);
              }}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        {activeTab == Tab.GENERAL && (
          <TabPane tabId={Tab.GENERAL} className="w-100">
            <div className="card-body card">
              <Form
                initialValues={{
                  date: date ? date : fineData?.date,
                  user: user
                    ? user
                    : userData &&
                    userData.find((fine) => fine.value === fineData?.user),
                  type: defaultAmount
                    ? {
                      label: defaultAmount?.label,
                      value: defaultAmount?.value,
                    }
                    : tagList &&
                    tagList.find((fine) => fine.value === fineData?.type),
                  amount:
                    defaultAmount &&
                      defaultAmount.amount === null &&
                      amount === ""
                      ? ""
                      : amount
                        ? amount
                        : defaultAmount && defaultAmount.amount
                          ? Numbers.Get(defaultAmount.amount)
                          : fineData?.amount,
                  notes:
                    notesChange === ""
                      ? notesChange
                      : notesChange
                        ? notesChange
                        : fineData?.notes
                          ? fineData?.notes
                          : "",
                  due_date: dueDate ? dueDate : fineData?.due_date,
                  reviewer: reviewer
                    ? reviewer
                    : userData &&
                    userData.find(
                      (fine) => fine.value === fineData?.reviewer
                    ),
                  status: statusDetail
                    ? statusDetail
                    : fineData
                      ? {
                        label: fineData.statusName,
                        value: fineData.statusValue,
                      }
                      : "",
                  objectName: fineData?.object_name || "",
                  objectId: fineData?.object_id || "",
                }}
                enableReinitialize
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                <div className="row">
                  <div className="col-sm-7">
                    <FineForm
                      TagList={setTagList}
                      finedata={fineData?.user}
                      editable={editable}
                      statusList={statusList}
                      handleTagChange={handleTagChange}
                      handleAmountChange={handleAmountChange}
                      handleStatusChange={handleStatusChange}
                      handleDateChange={handleDateChange}
                      handleDueDateChange={handleDueDateChange}
                      handleUserChange={handleUserChange}
                      handleNotesChange={handleNotesChange}
                      handleReviewerChange={handleReviewerChange}
                      showUserDetailsPageLink={fineData?.user ? true : false}
                      showReviewerDetailsPageLink={
                        fineData?.reviewer ? true : false
                      }
                      userId={fineData?.user}
                      reviewerId={fineData?.reviewer}
                      objectId={fineData?.object_id}
                      objectName={fineData?.object_name}
                      typeId={fineData?.type}
                      params={{ type: "FineType" }}
                      pathName={pathName}
                    />
                    {!editable && (
                      <div className="mt-2">
                        <SaveButton type="submit" label="Save" />
                        <CancelButton
                          onClick={() => {
                            history.push(Urls.FINE_LIST);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-sm-5">
                    <MediaCarousel
                      showCarasoul
                      title="Bill"
                      objectName={ObjectName.FINE}
                      objectId={id}
                      history={history}
                      billView={true}
                      attachmentsList={true}
                      modalOpen={isModelOpen}
                      toggle={__toggle}
                      setIsModelOpen={setIsModelOpen}
                      imageCount={setImageCount}
                      numberOfImage={imageCount}
                      Attachments={"Attachments"}
                      editable={editable}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </TabPane>
        )}
        {showHistory && activeTab == Tab.HISTORY && (
          <TabPane tabId={Tab.HISTORY} className="w-100">
            <ActivityList
              id={id}
              objectId={id}
              object_name={ObjectName.FINE}
              history={history}
            />
          </TabPane>
        )}
        <TabPane tabId={Tab.COMMENTS} className="w-100">
          <Comment
            objectId={id}
            objectName={ObjectName.FINE}
            maxHeight="100vh"
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default FineDetail;
