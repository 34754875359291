import React from "react";
import Filter from "../../components/Filter";
import { CUSTOM_VALUE, DateOptions } from "../../helpers/Date";
import DateTime from "../../lib/DateTime";
import Url from "../../lib/Url";

const GstReportFilter = (props) => {
  const { getDetails, setParams, params } = props;


  const handleChange = async (updatedParams) => {
    const productparam = {
      date: updatedParams.date
        ? updatedParams.date
        : "",
    };

    if (updatedParams?.date == CUSTOM_VALUE) {
      productparam.startDate = updatedParams.startDate
        ? updatedParams.startDate
        : ""
      productparam.endDate = updatedParams.endDate
        ? updatedParams.endDate
        : ""
    }
    setParams(productparam)
    Url.UpdateUrl(productparam, props);
    await getDetails(productparam);
  };

  const handleDeleteFilter = (values) => {
    handleChange({ ...params, ...values });
  };

  const initialValues = {
    startDate: DateTime.getDate(Url.GetParam("startDate")),
    endDate: DateTime.getDate(Url.GetParam("endDate")),
    date: DateOptions.find((value) => value?.value == Url.GetParam("date")),
  };

  const handleFilter = (values) => {
    let data = {
      date: values?.date ? values?.date?.value : ""
    };

    if (values?.date?.value == CUSTOM_VALUE || Url.GetParam("date") == CUSTOM_VALUE) {
      data.endDate = values?.endDate ? DateTime.toISOStringDate(values?.endDate) : ""
      data.startDate = values?.startDate
        ? DateTime.toISOStringDate(values?.startDate)
        : ""
    }

    Url.UpdateUrl(
      data,
      props
    );
    handleChange(data);
  };

  return (
    <Filter
      initialValues={initialValues}
      showHeader
      newTableHeading
      showCustomDateFilter
      sortByDropdown
      showSearch
      refreshButton
      handleFilter={handleFilter}
      handleDeleteFilter={handleDeleteFilter}
    />
  );
};
export default GstReportFilter;
