import React, { useState } from "react";

// Components
import ColorSelect from "../../components/colorSelect/colorSelect";
import "../../scss/_custom.scss";
import Select from "../../components/Select";
import { statusBarOptions } from "../../helpers/StatusBar";

const Header = (props) => {
  const { settings, setHeaderColor, setHeaderTextColor } = props;
  
  let { portal_header_color, portal_header_text_color} = settings;

  const [isExpanded, setIsExpanded] = useState(true);

  const headerColor = isExpanded ? "#ECF5FF" : "#FFFFFF";
 

  return (
    <>
      <div className="accordion mt-3" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingHeader">
            <button
              className={`accordion-button ${isExpanded ? "" : "collapsed"}`}
              style={{ backgroundColor: headerColor }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseHeader"
              aria-expanded={isExpanded ? "true" : "false"}
              aria-controls="collapseHeader"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <h5 className="text-primary">Header</h5>
            </button>
          </h2>
          <div
            id="collapseHeader"
            className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
            aria-labelledby="headingHeader"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-3">
              {/* Header color */}
              <h5>Color</h5>
              <div className="field-wrapper">
                <ColorSelect
                  name="portal_header_color"
                  defaultValue={portal_header_color}
                  setColor={setHeaderColor}
                />
              </div>

              {/* Header Text color */}
              <h5>Text Color</h5>
              <div className="field-wrapper">
                <ColorSelect
                  name="portal_header_text_color"
                  defaultValue={portal_header_text_color}
                  setColor={setHeaderTextColor}
                />
              </div>
              <h5>Status Bar</h5>
              <div className="field-wrapper">
              <Select
                name="app_status_bar_content_style"
                placeholder="Select Status Bar"
                options={statusBarOptions}
               />
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
