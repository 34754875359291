import React, { useState } from "react";
import { DropdownItem } from "reactstrap";
import { useDispatch } from "react-redux";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import ProductCard from "../product/components/productCard";
import MoreDropdown from "../../components/authentication/moreDropdown";
import DeleteModal from "../../components/DeleteModal";

// API
import { endpoints } from "../../api/endPoints";

// Lib
import Url from "../../lib/Url";

// Services

// Helpers
import { Status } from "../../helpers/Product";
import PromotionProductService from "../../services/PromotionProductService";
import { fetchList } from "../../actions/table";

const PromotionProductList = (props) => {
  const { history, promotionId } = props;
  const [rowValue, setRowValue] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const deleteProduct = async () => {
    let data = new FormData();
    data.append("id", rowValue?.id);
    dispatch(
      await PromotionProductService.delete(data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "promotionProduct",
              `${endpoints().promotionProductAPI}`,
              1,
              25,
              {
                promotionId: promotionId,
              }
            )
          );
        }
      })
    );
    closeDeleteModal();
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        label={rowValue?.name}
        toggle={closeDeleteModal}
        title="Delete Promotion Product"
        deleteFunction={deleteProduct}
      />

      <ReduxTable
        id="promotionProduct"
        showHeader
        newTableHeading
        apiURL={`${endpoints().promotionProductAPI}`}
        params={{
          promotionId: promotionId,
          sort: Url.GetParam("sort"),
          sortDir: Url.GetParam("sortDir"),
        }}
        searchPlaceholder="Search..."
        sortByOptions={sortByOption}
        paramsToUrl={true}
        history={history}
      >
        <ReduxColumn
          field="name"
          type="link"
          sortBy="name"
          isClickable="true"
          renderField={(row) => (
            <>
              <ProductCard
                productImageIcon
                square
                productName={row.name}
                packSize={row.pack_size}
                url={row.image}
                brandName={row.brand_name}
                salePrice={row.sale_price}
                size={row.size}
                unit={row.unit}
                mrp={row.mrp}
                id={row.product_id}
                brand_id={row.brand_id}
                status={
                  row.status == Status.INACTIVE_VALUE ? Status.INACTIVE : ""
                }
              />
            </>
          )}
        >
          Product
        </ReduxColumn>
        <ReduxColumn field="promotion_name" sortBy="promotion_name">
          Promotion Name
        </ReduxColumn>

        <ReduxColumn
          width="70px"
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="col-4 text-center landing-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  className="text-danger cursor-pointer"
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setRowValue(row);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default PromotionProductList;
