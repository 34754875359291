import React from "react";

// Components
import UserSelect from "../../../components/UserSelect";
import Select from "../../../components/Select";
import Text from "../../../components/Text";
import StoreSelector from "../../location/components/storeSelector";
import ShiftSelect from "../../../components/ShiftSelect";
import TimeSelector from "../../../components/TimeSelector";
import SingleCheckbox from "../../../components/SingleCheckbox";
import WeekdaysCheckbox from "../../../components/WeekdaysCheckbox";

// Helpers
import { RecurringOption } from "../../../helpers/recurringTask";

// Lib
import String from "../../../lib/String";

const RecurringActivityForm = (props) => {
    let {
        editable,
        row,
        handleUserChange,
        handleDateChange,
        activityTypeList,
        handleActivityTypeChange,
        handleDescriptionChange,
        handleRoleChange,
        userRole,
        setUserList,
        setStoreList,
        onLocationChange,
        handleShiftChange,
        setShiftList,
        handleTimeChange,
        typeOptions,
        handleTypeChange,
        handleAddOnCheckinChange,
        addForCheckedinUsers,
        selectedDays,
        handleCheckboxChange,
        dateOptions,
        monthOption,
        handleMonthChange,
        details,
        type,
        shiftMultiSelect
    } = props;

    return (
        <div>
            <Select
                name="activityType"
                label="Activity Type"
                placeholder="Select Activity Type"
                options={activityTypeList}
                handleChange={handleActivityTypeChange}
                isDisabled={editable}
                required
            />
            <Text
                name="description"
                label="Description"
                placeholder="Description"
                onChange={handleDescriptionChange}
                disabled={editable}
            />
            <Select
                name="role_id"
                label="Role"
                placeholder="Select Role"
                options={userRole}
                handleChange={handleRoleChange}
                isMulti
                isDisabled={editable}
                required
            />
            <UserSelect
                label="Owner"
                name="owner"
                placeholder={"Select Owner"}
                handleUserChange={handleUserChange}
                showUserDetailsPageLink={
                    details?.assignee_id ? true : false
                }
                userId={details?.assignee_id}
                userList={setUserList}
                isDisabled={editable}
            />
            <StoreSelector
                setStoreList={setStoreList}
                onInputChange={onLocationChange}
                disabled={editable}
            />
            {shiftMultiSelect && (
                <ShiftSelect
                    handleShiftChange={handleShiftChange}
                    label="Shift"
                    defaultValue={row?.shift_id}
                    shiftOption={setShiftList}
                    isMulti
                />
            )}
            {!shiftMultiSelect && (<ShiftSelect
                handleShiftChange={handleShiftChange}
                name="shift_id"
                label="Shift"
                defaultValue={details?.shift_id}
                shiftOption={setShiftList}
                isDisabled={editable}
            />
            )}
            <TimeSelector
                label="Time"
                name="time"
                isClearable={editable ? false : true}
                onChange={handleTimeChange}
                disabled={editable}
            />
            <Select
                name="taskType"
                label="Recurring Type"
                placeholder="Select Task"
                options={typeOptions}
                handleChange={handleTypeChange}
                isDisabled={editable}
                required
            />
            <SingleCheckbox
                handleFeatureChange={handleAddOnCheckinChange}
                label={String.createLabel(RecurringOption.ADD_ON_CHECK_IN)}
                name={RecurringOption.ADD_ON_CHECK_IN}
                enabled={editable}
            />
            <SingleCheckbox
                handleFeatureChange={addForCheckedinUsers}
                label={String.createLabel(RecurringOption.ADD_FOR_CHECKEDIN_USERS)}
                name={RecurringOption.ADD_FOR_CHECKEDIN_USERS}
                enabled={editable}
            />
            {type == "Weekly" || (details?.type == "Weekly" && !type) ? (
                <div>
                    <WeekdaysCheckbox
                        selectedDays={selectedDays}
                        handleCheckboxChange={handleCheckboxChange}
                        disabled={editable}
                    />
                </div>
            ) : (
                ""
            )}
            {type == "Annually" ||
                (details?.type == "Annually" && !type) ? (
                <>
                    <Select
                        name="month"
                        label="Month"
                        options={monthOption}
                        handleChange={handleMonthChange}
                        isDisabled={editable}
                    />
                </>
            ) : (
                ""
            )}
            {type == "Monthly" ||
                type == "Annually" ||
                (details?.type == "Monthly" && !type) ||
                (details?.type == "Annually" && !type) ? (
                <>
                    <Select
                        name="date"
                        label="Date"
                        options={dateOptions}
                        handleChange={handleDateChange}
                        isDisabled={editable}
                    />
                </>
            ) : (
                ""
            )}
        </div>
    );
};

export default RecurringActivityForm;
