import React, { useEffect, useRef, useState } from "react";

// Components
import ReportTable from "./components/ReportTable";
import Spinner from "../../components/Spinner";
import ReportFilter from "./components/reportFilter";
import UserCard from "../../components/UserCard";
import PageTitle from "../../components/PageTitle";

// End Points
import { endpoints } from "../../api/endPoints";

// API client
import { apiClient } from "../../apiClient";

// Lib
import Url from "../../lib/Url";
import ArrayList from "../../lib/ArrayList";

// helpers
import BreadCrumb from "../../components/Breadcrumb";
import { setCookie } from "../../lib/Helper";
import Cookie from "../../helpers/Cookie";
import Cookies from "../../lib/Helper";


const AttendanceSummaryReport = (props) => {
  const [detail, setDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(Url.GetParam("pageSize"));
  const [totalCount, setTotalCount] = useState();
  const [params, setParams] = useState({});
  const [param, setParam] = useState({});

  const [arrayList, setArrayList] = useState([]);
  const [arrays, setArray] = useState([]);

  const divRefs = {
    csvDiv: useRef(null),
    pdfDiv: useRef(null),
    pngDiv: useRef(null),
  };


  useEffect(() => {
    getDetails(params);
  }, [page]);

  useEffect(() => {
    const checkedList = Cookies.get(Cookie.ATTENDANCE_REPORT);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);


  const getDetails = async (values) => {
    setIsLoading(true)
    let params = {};
    params.user_id = values?.user_id ? values?.user_id : Url.GetParam("user_id");
    params.startDate = values?.startDate ? values?.startDate : Url.GetParam("startDate");
    params.endDate = values?.endDate ? values?.endDate : Url.GetParam("endDate");
    params.page = page ? page : ""
    params.pageSize = Url.GetParam("pageSize")
    params.sort = values?.sort ? values?.sort : Url.GetParam("sort")
    params.sortDir = values?.sortDir ? values?.sortDir : Url.GetParam("sortDir")
    params.type = values?.type ? values?.type : Url.GetParam("type")
    params.comparison_type = values?.comparison_type ? values?.comparison_type : Url.GetParam("comparison_type")
    params.comparison_value = values?.comparison_value ? values?.comparison_value : Url.GetParam("comparison_value")
    params.pagination = true
    try {
      const queryString = [];
      let response;
      if (params) {
        Object.keys(params).forEach((param) => {
          queryString.push(`${param}=${params[param]}`);
        });
      }
      if (queryString && queryString.length > 0) {
        response = await apiClient.get(
          `${endpoints().attendanceAPI}/search?${queryString.join("&")}`
        );
      }

      let paramdata = response.data;
      setPageSize(paramdata?.pageSize)
      setTotalCount(paramdata?.totalCount);
      let data = response.data.data;

      // setParams(response.data)
      let attendance = [];
      if (ArrayList.isNotEmpty(data)) {
        data.forEach((list) => {
          const userNames = (
            <UserCard
              first_name={list.userName}
              lastName={list.LastName}
              firstName={list.firstName}
              media_url={list.media_url}
            />
          );

          attendance.push({
            userName: userNames,
            id: list.user,
            total: list.total,
            absent: list.absent,
            leave: list.leave,
            additional: list.additional,
            status: list.status,
            worked: list.worked,
            name: list.userName,
            late_hours: list.late_hours,
            additional_hours: list.additional_hours,
            startDate: list.startDate,
            endDate: list.endDate,
            columns: list?.columns,
            ...list
          });
        });
      }

      setDetail(attendance);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    {
      label: "Reports",
      link: "/Reports",
    },
    {
      label: "Attendance Summary Report",
      link: "",
    },
  ];


  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.ATTENDANCE_REPORT, arrayList);
    setArray(array);
    setArrayList(array);
  };

  const handleActionChange = (id) => {
    if (divRefs[id]?.current) {
      divRefs[id].current.click();
    }
  }

  const dropdownLinks = [
    {
      value: "pngDiv",
      label: "Export As PNG",
    },
    {
      value: "pdfDiv",
      label: "Export As PDF",
    },
    {
      value: "csvDiv",
      label: "Export As CSV",
    },
  ]

  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <PageTitle label="Attendance Summary Report" dropdownLinks={dropdownLinks} actionLabel={"Export"} handleChange={handleActionChange} />
      <ReportFilter
        history={props.history}
        initialParam={param}
        handleChange={getDetails}
        setPage={setPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        detail={detail}
        props={props}
        handleColumnChange={(e) => {
          handleColumnChange(e);
        }}
        arrayList={arrayList}
        divRefs={divRefs}
      />
      <ReportTable
        arrays={arrays}
        detail={detail}
        setPage={setPage}
        page={page}
        params={params}
        pageSize={pageSize}
        totalCount={totalCount}
        history={props.history}
        getDetails={getDetails}
        divRefs={divRefs}
      />
    </>
  );
};

export default AttendanceSummaryReport;
