import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/Button";
import DeleteModal from "../../../components/DeleteModal";
import Drawer from "../../../components/Drawer";
import ContactService from "../../../services/ContactService";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { DropdownItem } from "reactstrap";
import { fetchList } from "../../../actions/table";
import { endpoints } from "../../../api/endPoints";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import Email from "../../../components/Email";
import Phone from "../../../components/Phone";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import Text from "../../../components/Text";
import Url from "../../../lib/Url";

const ContactList = (props) => {
  let { objectId, isModalOpen, objectName, addContactToggle } = props;

  const [rowValue, setRowValue] = useState(null);
  const [isContactDeleteModel, setDeleteContactModal] = useState(false);

  const dispatch = useDispatch();

  const addContactForm = (
    <div>
      <Text
        label="First Name"
        name="first_name"
        className="fw-bold-text"
        placeholder="Enter First Name"
        required
        error=""
      />
      <Text
        label="Last Name"
        name="last_name"
        className="fw-bold-text"
        placeholder="Enter Last Name"
        error=""
      />
      <Email
        label="Email Address"
        name="email"
        placeholder="Enter Email Address"
        notify="error"
      />
      <Phone
        label="Mobile Number"
        name="mobile"
        placeholder="Enter Mobile Number"
        error=""
      />
      <Phone
        label="Work Phone"
        name="work_phone"
        placeholder="Enter Work Number"
        error=""
      />
      <Text
        label="Designation"
        name="designation"
        placeholder="Enter Designation"
      />
    </div>
  );

  const addContactFooter = (
    <Button
      type="submit"
      label={rowValue?.id ? "Edit" : "Add"}
      showButtonLabelName
    ></Button>
  );

  const closeDeleteModal = () => {
    setDeleteContactModal(false);
    setRowValue(null);
  };

  const closeContactToggle = () => {
    props.setIsModalOpen && props.setIsModalOpen(false);
    setRowValue(null);
  };

  const handleContactDelete = async () => {
    let params = {
      ...(objectId ? { object_id: objectId } : {}),
      ...(objectName ? { object_name: objectName } : {}),
      ...Url.GetAllParams(),
    };
    dispatch(
      await ContactService.del(rowValue?.id, () => {
        dispatch(
          fetchList(
            "contact",
            `${endpoints().contactAPI}/search`,
            1,
            25,
            params
          )
        );
        closeDeleteModal();
      })
    );
  };

  const handleContact = async (values) => {
    const createData = new FormData();
    let id = rowValue?.id;
    createData.append("first_name", values.first_name ? values.first_name : "");
    createData.append("last_name", values.last_name ? values.last_name : "");
    createData.append("email", values.email ? values.email : "");
    createData.append("mobile", values.mobile ? values.mobile : "");
    createData.append(
      "designation",
      values.designation ? values.designation : ""
    );

    createData.append("work_phone", values.work_phone ? values.work_phone : "");

    if (objectName) {
      createData.append("object_name", objectName);
    }

    if (objectId) {
      createData.append("object_id", objectId);
    }

    let params = {
      ...(objectId ? { object_id: objectId } : {}),
      ...(objectName ? { object_name: objectName } : {}),
      ...Url.GetAllParams(),
    };

    if (rowValue?.id) {
      createData.append("id", rowValue?.id);

      dispatch(
        await ContactService.update(id, createData, (res) => {
          dispatch(
            fetchList(
              "contact",
              `${endpoints().contactAPI}/search`,
              1,
              25,
              params
            )
          );
          closeContactToggle();
        })
      );
    } else {
      dispatch(
        await ContactService.create(createData, (response) => {
          dispatch(
            fetchList(
              "contact",
              `${endpoints().contactAPI}/search`,
              1,
              25,
              params
            )
          );
          closeContactToggle();
        })
      );
    }
  };

  const initialValue = {
    first_name: rowValue?.first_name ? rowValue?.first_name : "",
    last_name: rowValue?.last_name ? rowValue?.last_name : "",
    email: rowValue?.email ? rowValue?.email : "",
    mobile: rowValue?.mobile ? rowValue?.mobile : "",
    work_phone: rowValue?.work_phone ? rowValue?.work_phone : "",
    designation: rowValue?.designation ? rowValue?.designation : "",
  };

  return (
    <>
      <DeleteModal
        isOpen={isContactDeleteModel}
        title="Delete Contact"
        label={rowValue?.name}
        toggle={closeDeleteModal}
        deleteFunction={handleContactDelete}
      />
      <Drawer
        modelTitle={rowValue?.id ? "Edit Contact" : "Add Contact"}
        DrawerBody={addContactForm}
        DrawerFooter={addContactFooter}
        onSubmit={(values) => handleContact(values)}
        initialValues={initialValue}
        handleOpenModal={addContactToggle}
        handleCloseModal={closeContactToggle}
        handleDrawerClose={closeContactToggle}
        isModalOpen={isModalOpen}
        enableReinitialize={true}
      />

      <ReduxTable
        id="contact"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().contactAPI}/search`}
        history={props?.history}
        paramsToUrl={true}
        sortByDropdown
        className="lh-lg"
        params={{
          ...(objectId ? { object_id: objectId } : {}),
          ...(objectName ? { object_name: objectName } : {}),
        }}
        showPageSize
      >
        <ReduxColumn
          field="name"
          sortBy="name"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          renderField={(row) => (
            <Link to={`/contact/${row.id}`} className="link-opacity-75 text-decoration-none">{row?.name}</Link>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="email"
          sortBy="email"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          className="text-center"
          renderField={(row) => (
            <a href={`mailto:${row.email}`} className="link-opacity-75 text-decoration-none">{row?.email}</a>
          )}
        >
          Email
        </ReduxColumn>
        <ReduxColumn
          field="mobile"
          sortBy="mobile"
          width="180px"
          minWidth="180px"
          maxWidth="180px"
          className="text-center"
        >
          Mobile
        </ReduxColumn>
        <ReduxColumn
          field="designation"
          sortBy="designation"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          className="text-center"
        >
          Designation
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="text-center action-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    setRowValue(row);
                    props.setIsModalOpen && props.setIsModalOpen(true);
                  }}
                >
                  Quick View
                </DropdownItem>
                <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    setRowValue(row);
                    setDeleteContactModal(true);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default ContactList;
