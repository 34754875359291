import React from "react";
import Avatar from "./Avatar";
import DateTime from "../lib/DateTime";
import Number from "../lib/Number";

const MessageCard = (props) => {
  let {
    first_name,
    last_name,
    url,
    last_message_time,
    recent_last_message,
    read_at,
    unRead,
    isChannel,
    currentLocationName,
    currentShiftName,
    isPermissionExist,
  } = props;

  // Format first_name with location & shift info if permission exists
  let locationPart = currentLocationName ? `${currentLocationName}` : "";
  let shiftPart = currentShiftName ? `${currentShiftName}` : "";
  let locationShiftPart =
    locationPart && shiftPart
      ? `(${locationPart}, ${shiftPart})`
      : locationPart
      ? `(${locationPart})`
      : shiftPart
      ? `(${shiftPart})`
      : "";

  return (
    <div className="d-flex align-items-start p-2">
      {!isChannel ? (
        <div className="me-2">
          <Avatar id="avatar" url={url} firstName={first_name} lastName={last_name} />
        </div>
      ) : (
        <div className="me-2">
          <Avatar id="avatar" firstName={first_name} lastName={last_name} />
        </div>
      )}
      <div>
        {/* First Row: Name + Count Badge or Name + LocationShift */}
        <div className="d-flex align-items-center flex-wrap">
          <div className="fw-bold">
            {first_name || ""} {last_name || ""}
          </div>
          {Number.isNotNull(read_at) > 0 && !isChannel ? (
            <div className=" d-flex  justify-content-center">
              {isPermissionExist &&
                locationShiftPart &&
                !!locationShiftPart && (
                  <span
                    className="ms-1 text-secondary"
                    style={{ fontSize: "12px" }}
                  >
                    {locationShiftPart}
                  </span>
                )}
              <div
                className="rounded-circle text-center "
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "red",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                {read_at}
              </div>
            </div>
          ) : (
            isPermissionExist &&
            locationShiftPart && (
              <span
                className="ms-1 text-secondary"
                style={{ fontSize: "12px" }}
              >
                {locationShiftPart}
              </span>
            )
          )}
        </div>
        <div
          className="text-muted "
          style={{ fontSize: "10px", marginTop: "2px" }}
        >
          {last_message_time &&
            DateTime.getDateTimeByUserProfileTimezone(last_message_time)}
        </div>
        <div
          className="recent-message text-muted"
          style={{
            filter: unRead ? "blur(4px)" : "none",
            transition: "filter 0.3s ease-in-out",
          }}
        >
          {recent_last_message}
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
