import React from "react";

// Components
import DateSelector from "../../../components/Date";
import Select from "../../../components/Select";
import SelectStore from "../../../components/SelectStore";
import HorizontalSpace from "../../../components/HorizontalSpace";
import SaveButton from "../../../components/SaveButton";
import CancelButton from "../../../components/CancelButton";
import Currency from "../../../components/Currency";
import UserSelect from "../../../components/UserSelect";
import AccountSelect from "../../../components/AccountSelect";
import ShiftSelect from "../../../components/ShiftSelect";

// Helpers
import Url from "../../../helpers/Url";
import { Order } from "../../../helpers/Order";

const GeneralTab = (props) => {
  const {
    paymentType,
    selectedDate,
    orderDateFieldOnchange,
    storeFieldOnchange,
    handleShiftChange,
    userList,
    onChangePaymentValue,
    history,
    orderId,
    customerList,
    pathName,
    onChangeSalesCustomer,
    editable,
    showUserDetailsPageLink,
    showCustomerDetailsPageLink,
    customerId,
    shiftId,
    setShiftOption,
    locationId,
    setLocationList,
    showSaveButton,
    onChangeOwner,
    ownerId,
    showCustomerSelection,
    orderTypes,
    onChangeTypeValue,
    deliveryDateFieldOnchange,
    selectedDeliveryDate,
    paymentValue,
    OrderDetail,
    showDeliveryDate,
    setUsersList
  } = props;

  return (
    <div className="card card-body mb-3">
      {/* 1st Row: Date, Time, Payment Type */}
      <div className="row">
        <div className="col-sm-4 pe-1">
          <DateSelector
            name="date"
            maxDate={new Date()}
            label="Date"
            selected={selectedDate}
            onChange={orderDateFieldOnchange}
            position="unset"
            disabled={editable}
          />
        </div>
        <div className="col-sm-4 pe-1">
          <DateSelector
            name="time"
            label="Time"
            showTimeSelectOnly
            selected={selectedDate}
            showTimeSelect={true}
            onChange={orderDateFieldOnchange}
            format="h:mm aa"
            disabled={editable}
          />
        </div>
        <div className="col-sm-4">
          <Select
            name="payment_type"
            placeholder="Select Payment"
            label="Payment Type"
            options={paymentType}
            onInputChange={(e) => onChangePaymentValue(e)}
            isDisabled={editable}
          />
        </div>
      </div>

      {/* 2nd Row: Location, Shift, Cash Amount, UPI Amount */}
      <div className="row">
        <div className="col-sm-3 pe-1">
          <SelectStore
            label="Location"
            required
            handleStoreChange={storeFieldOnchange}
            isDisabled={editable}
            defaultValue={locationId}
            StoreList={setLocationList}
          />
        </div>
        <div className="col-sm-3 pe-1">
          <ShiftSelect
            placeholder="Select shift"
            name="shift"
            label="Shift"
            handleShiftChange={(e) => handleShiftChange(e)}
            isDisabled={editable}
            defaultValue={shiftId}
            shiftOption={setShiftOption}
          />
        </div>
        {(paymentValue === Order.PAYMENT_CASH_VALUE ||
          paymentValue === Order.PAYMENT_MIXED_VALUE ||
          (!paymentValue &&
            OrderDetail?.payment_type === Order.PAYMENT_CASH_VALUE) ||
          (!paymentValue &&
            OrderDetail?.payment_type === Order.PAYMENT_MIXED_VALUE)) && (
          <div className="col-sm-3 pe-1">
            <Currency
              name="cash_amount"
              label="Cash Amount"
              onInputChange={props.handleCashAmount}
              disabled={editable}
            />
          </div>
        )}
        {(paymentValue === Order.PAYMENT_UPI_VALUE ||
          paymentValue === Order.PAYMENT_MIXED_VALUE ||
          (!paymentValue &&
            OrderDetail?.payment_type === Order.PAYMENT_UPI_VALUE) ||
          (!paymentValue &&
            OrderDetail?.payment_type === Order.PAYMENT_MIXED_VALUE)) && (
          <div className="col-sm-3">
            <Currency
              name="upi_amount"
              label="UPI Amount"
              onInputChange={props.handleUpiAmount}
              disabled={editable}
            />
          </div>
        )}
      </div>

      {/* 3rd Row: Order Type, Owner */}
      <div className="row">
        <div className="col-sm-6">
          <Select
            name="type"
            placeholder="Select Type"
            label="Type"
            options={orderTypes}
            onInputChange={(e) => onChangeTypeValue(e)}
            isDisabled={editable}
          />
        </div>
        <div className="col-sm-6">
          <UserSelect
            name="owner"
            label="Owner"
            placeholder="Owner"
            required
            isDisabled={editable}
            handleUserChange={(e) => onChangeOwner(e)}
            showUserDetailsPageLink={showUserDetailsPageLink}
            userId={ownerId}
            userList={setUsersList}
          />
        </div>
      </div>

      {/* Remaining fields in the last row */}
      {showCustomerSelection && (
        <div className="row">
          <div className="col">
            <AccountSelect
              name="customer_account"
              label="Customer"
              placeholder="Select Customer"
              customOption={customerList}
              isDisabled={editable}
              handleVendorChange={(e) => onChangeSalesCustomer(e)}
              showAccountDetailsPageLink={showCustomerDetailsPageLink}
              accountId={customerId}
            />
          </div>
        </div>
      )}

      {showDeliveryDate && (
        <div className="row">
          <div className="col-sm-6 pe-1">
            <DateSelector
              name="delivery_date"
              maxDate={new Date()}
              label="Delivery Date"
              selected={selectedDeliveryDate}
              onChange={deliveryDateFieldOnchange}
              position="unset"
              disabled={editable}
            />
          </div>
          <div className="col-sm-6">
            <DateSelector
              name="delivery_time"
              label="Delivery Time"
              showTimeSelectOnly
              selected={selectedDeliveryDate}
              showTimeSelect={true}
              onChange={deliveryDateFieldOnchange}
              format="h:mm aa"
              disabled={editable}
            />
          </div>
        </div>
      )}

      {showSaveButton && (
        <HorizontalSpace>
          <SaveButton label="Save" />
          <CancelButton
            onClick={() => {
              if (pathName === "/location/deliveryOrders/details") {
                history.push(Url.DELIVERY_ORDER_LIST);
              } else if (pathName === "/deliveryOrder") {
                history.push(Url.DELIVERY_ORDER_LIST);
              } else {
                history.push(Url.ORDER_LIST);
              }
            }}
          />
        </HorizontalSpace>
      )}
    </div>
  );
};

export default GeneralTab;
