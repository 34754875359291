import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";

// Components
import Button from "../../components/Button";
import TicketList from "../../components/TicketList";
import TicketSummaryList from "./components/TicketSummaryList";
import RefreshButton from "../../components/refreshButton";
import PaymentList from "../payment/components/paymentList";
import DeleteModal from "../../components/DeleteModal";
import Bill from "../bill";

// lib
import DateTime from "../../lib/DateTime";
import Url from "../../lib/Url";
import Cookies from "../../lib/Helper";

// Services
import AttendanceService from "../../services/AttendanceService";
import CompanyUserService from "../../services/CompanyUserService";
import { hasPermission } from "../../services/UserRolePermissionService";
import PaymentService from "../../services/PaymentService";

// Helpers
import Permission from "../../helpers/Permission";
import ObjectName from "../../helpers/ObjectName";
import { User } from "../../helpers/User";
import Cookie from "../../helpers/Cookie";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";
import Purchase from "../purchase";
import Fines from "../fine";

export const Tab = {
  TICKET_SUMMARY: "Ticket Summary",
  TODAY_TICKETS: "Pending Tickets",
  PENDING_BILLS: "Pending Bills",
  PENDING_PURCHASES: "Pending Purchases",
  PENDING_PAYMENTS: "Pending Payments",
  PENDING_FINES: "Pending Fines",
};

const MyDahboard = (props) => {
  const [attendance, setAttendance] = useState("");
  const [userDetail, setUserDetail] = useState("");
  const [checkinPermission, setCheckinPermission] = useState("");
  const [userName, setUserName] = useState("");
  const [rowValue, setRowValue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(Tab.TICKET_SUMMARY);

  const dispatch = useDispatch();

  useEffect(() => {
    getAttendanceList(userDetail?.id);
    getUserDetail();
  }, []);

  let showPendingBill = hasPermission(Permission.BILL_VIEW);
  let showPendingPurchase = hasPermission(Permission.PURCHASE_VIEW);
  let showPendingPayment = hasPermission(Permission.PAYMENT_VIEW);
  let showPendingFine = hasPermission(Permission.FINE_VIEW);
  let showPendingTicket = hasPermission(Permission.TICKET_VIEW);

  const getAttendanceList = async (userId) => {
    const permission = hasPermission(Permission.USER_WEB_CHECKIN);
    setCheckinPermission(permission);
    if (userId !== undefined) {
      let attendance = {
        startDate: DateTime.formatDate(new Date()),
        endDate: DateTime.toISOStrings(new Date()),
        userId: userId,
      };
      let data = await AttendanceService.getAttendenceByUserId(attendance);
      if (data) {
        setAttendance(data.data[0]);
      }
    }
  };

  const toggle = (tab) => {
    setActiveTab(tab);
    props.history.push("/mydashboard");
  };

  const getUserDetail = async () => {
    let userDetail = await CompanyUserService.getLoggedInUser();
    if (userDetail) {
      let userName = userDetail.name
        ? userDetail.name +
        " " +
        (userDetail.lastName ? userDetail.lastName : "")
        : "";
      setUserName(userName);
    }
    setUserDetail(userDetail);
    getAttendanceList(userDetail?.id);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsSubmitting(true);
    setRowValue("");
  };

  const toggles = () => {
    setIsOpen(!isOpen);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const paymentsDelete = async () => {
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      search: Url.GetParam("search"),
      dashboardDelete: true,
    };
    dispatch(await PaymentService.delete(rowValue?.id, null, params));
    closeDeleteModal();
  };

  const attendanceHandler = async () => {
    if (attendance && attendance?.login) {
      let response = await AttendanceService.CheckOutValidation(attendance?.id);
      if (response && response.data) {
        if (attendance?.id) {
          await AttendanceService.Checkout({ attendanceId: attendance?.id });
        }
      }
      getAttendanceList(userDetail?.id);
    } else {
      const params = {
        shift_id: userDetail?.primary_shift_id,
        store: userDetail?.primary_location_id,
        is_working_day: true,
      };
      await AttendanceService.Checkin(params);

      getAttendanceList(userDetail?.id);
    }
  };

  const handleRefreshButton = () => {
    let param = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
    };
    {
      activeTab === Tab.TICKET_SUMMARY &&
        dispatch(
          fetchList(
            "summary",
            `${endpoints().ticketAPI}/summery`,
            param?.page ? param?.page : 1,
            param?.pageSize ? param?.pageSize : 25,
            {
              status: User.STATUS_ACTIVE_VALUE,
              ...param,
            }
          )
        );
    }
    {
      activeTab === Tab.TODAY_TICKETS &&
        dispatch(
          fetchList(
            "ticket",
            `${endpoints().ticketAPI}/pending`,
            param?.page ? param?.page : 1,
            param?.pageSize ? param?.pageSize : 25,
            param
          )
        );
    }
    {
      activeTab === Tab.PENDING_PAYMENTS &&
        dispatch(
          fetchList(
            "payment",
            `${endpoints().paymentAPI}/pending`,
            param?.page ? param?.page : 1,
            param?.pageSize ? param?.pageSize : 25,
            param
          )
        );
    }
    {
      activeTab === Tab.PENDING_BILLS &&
        dispatch(
          fetchList(
            "bill",
            `${endpoints().billAPI}/pending`,
            param?.page ? param?.page : 1,
            param?.pageSize ? param?.pageSize : 25,
            param
          )
        );
    }
    {
      activeTab === Tab.PENDING_PURCHASES &&
        dispatch(
          fetchList(
            "pendingPurchases",
            `${endpoints().purchaseAPI}/pending`,
            param?.page ? param?.page : 1,
            param?.pageSize ? param?.pageSize : 25,
            param
          )
        );
    }
    {
      activeTab === Tab.PENDING_FINES &&
        dispatch(
          fetchList(
            "fine",
            `${endpoints().fineApi}/pending`,
            param?.page ? param?.page : 1,
            param?.pageSize ? param?.pageSize : 25,
            param
          )
        );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="fw-normal">Hello {userName}!</h3>
        {checkinPermission && (
          <div>
            {!(attendance?.login && attendance?.logout) && (
              <Button
                className="btn btn-secondary fw-bold"
                label={
                  attendance?.login && !attendance?.logout
                    ? "Check out"
                    : "Check In"
                }
                onClick={() => attendanceHandler()}
                showButtonLabelName
              />
            )}

            {userDetail?.id === attendance?.userId && (
              <div
                className={`fw-bold ms-5 mt-2 ${attendance?.logout ? "pull-right" : "pull-left"
                  }`}
              >
                <span>
                  {attendance?.login && (
                    <span>
                      CheckIn: {DateTime.LocalTime(attendance?.login)}
                    </span>
                  )}
                  {attendance?.logout && (
                    <>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Checkout:{" "}
                      {DateTime.LocalTime(attendance?.logout)}
                    </>
                  )}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      <hr />
      <div className="float-end mb-0 mt-0">
        <RefreshButton onClick={handleRefreshButton} />
      </div>
      <div className="mt-3 pt-3">
        <Nav tabs className="admin-tabs">
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.TICKET_SUMMARY,
              })}
              onClick={() => {
                toggle(Tab.TICKET_SUMMARY);
              }}
            >
              Ticket Summary
            </NavLink>
          </NavItem>
          {showPendingTicket && (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tab.TODAY_TICKETS,
                })}
                onClick={() => {
                  toggle(Tab.TODAY_TICKETS);
                }}
              >
                {Tab.TODAY_TICKETS}
              </NavLink>
            </NavItem>
          )}
          {showPendingPayment && (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tab.PENDING_PAYMENTS,
                })}
                onClick={() => {
                  toggle(Tab.PENDING_PAYMENTS);
                }}
              >
                {Tab.PENDING_PAYMENTS}
              </NavLink>
            </NavItem>
          )}
          {showPendingBill && (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tab.PENDING_BILLS,
                })}
                onClick={() => {
                  toggle(Tab.PENDING_BILLS);
                }}
              >
                {Tab.PENDING_BILLS}
              </NavLink>
            </NavItem>
          )}
          {showPendingPurchase && (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tab.PENDING_PURCHASES,
                })}
                onClick={() => {
                  toggle(Tab.PENDING_PURCHASES);
                }}
              >
                {Tab.PENDING_PURCHASES}
              </NavLink>
            </NavItem>
          )}
          {showPendingFine && (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tab.PENDING_FINES,
                })}
                onClick={() => {
                  toggle(Tab.PENDING_FINES);
                }}
              >
                {Tab.PENDING_FINES}
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </div>
      <TabContent activeTab={activeTab}>
        {activeTab === Tab.TICKET_SUMMARY && (
          <TabPane tabId={Tab.TICKET_SUMMARY} className="w-100">
            <TicketSummaryList {...props} />
          </TabPane>
        )}
        {activeTab === Tab.TODAY_TICKETS && (
          <TabPane tabId={Tab.TODAY_TICKETS} className="w-100">
            <TicketList
              props={props}
              history={props.history}
              showSearch
              apiUrl={`${endpoints().ticketAPI}/pending`}
              projectId={
                Url.GetParam("projectId") !== undefined
                  ? Url.GetParam("projectId")
                  : Cookies.get(Cookie.PROJECT_ID)
              }
              showQuickView={false}
            />
          </TabPane>
        )}
        <DeleteModal
          isOpen={openDeleteModal}
          label={rowValue?.id}
          toggle={closeDeleteModal}
          title="Delete Payment"
          deleteFunction={paymentsDelete}
        />
        {activeTab === Tab.PENDING_PAYMENTS && (
          <TabPane tabId={Tab.PENDING_PAYMENTS} className="w-100">
            <PaymentList
              props={props}
              history={props.history}
              apiUrl={`${endpoints().paymentAPI}/pending`}
              setIsSubmitting={setIsSubmitting}
              isSubmitting={isSubmitting}
              params={{
                sort: Url.GetParam("sort"),
                sortDir: Url.GetParam("sortDir"),
                page: Url.GetParam("page"),
                pageSize: Url.GetParam("pageSize"),
                search: Url.GetParam("search"),
                objectName: ObjectName.PAYMENT,
                showTotal: true,
              }}
              toggles={toggles}
              isOpen={isOpen}
              handleCloseModal={handleCloseModal}
              setRowValue={setRowValue}
              rowValue={rowValue}
              setOpenDeleteModal={setOpenDeleteModal}
              pendingPayment={Tab.PENDING_PAYMENTS}
              showAccountFilter
              showPaymentAccountFilter
            />
          </TabPane>
        )}
        {activeTab === Tab.PENDING_BILLS && (
          <TabPane tabId={Tab.PENDING_BILLS} className="w-100">
            <Bill
              showBillList
              history={props.history}
              apiUrl={`${endpoints().billAPI}/pending`}
            />
          </TabPane>
        )}
        {activeTab === Tab.PENDING_PURCHASES && (
          <TabPane tabId={Tab.PENDING_PURCHASES} className="w-100">
            <Purchase
              showPurchaseList
              id={"pendingPurchases"}
              history={props.history}
              apiUrl={`${endpoints().purchaseAPI}/pending`}
            />
          </TabPane>
        )}
        {activeTab === Tab.PENDING_FINES && (
          <TabPane tabId={Tab.PENDING_FINES} className="w-100">
            <Fines
              showFineList
              history={props.history}
              apiUrls={`${endpoints().fineApi}/pending`}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default MyDahboard;
