import { faFile, faTableCellsLarge } from "@fortawesome/free-solid-svg-icons";
import { hasPermission } from "../services/UserRolePermissionService";
import Permission from "../helpers/Permission";

export function getPagesNavList() {

  let arrayList = [];

  const showPage = hasPermission(Permission.PAGE_VIEW);

    arrayList = arrayList.concat({
      name: "Dashboard",
      url: "/pages/dashboard",
      icon: faTableCellsLarge,
  
    });

  if (showPage) {
    arrayList = arrayList.concat({
      name: "Pages",
      url: "/pages/pagelist",
      detailsPageurl: "/pages/pagelist/detail",
      addPageurl: "/pages/pagelist/detail/:id/:section",
      icon: faFile,
    });
  }

  return arrayList;
}
