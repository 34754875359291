import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// API
import { endpoints } from "../../api/endPoints";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import PageTitle from "../../components/PageTitle";
import AddButton from "../../components/AddButton";
import YearSelect from "../../components/YearSelect";
import MonthSelect from "../../components/MonthSelect";
import SaveButton from "../../components/SaveButton";
import Drawer from "../../components/Drawer";

// Services
import { hasPermission } from "../../services/UserRolePermissionService";
import SalaryService from "../../services/SalaryService";

// Helpers
import Permission from "../../helpers/Permission";

// Lib
import Currency from "../../lib/Currency";
import DateSelector from "../../components/Date";

const SalaryIndex = (props) => {
  let { history } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  let showAddButton = hasPermission(Permission.SALARY_ADD);

  const _toggle = () => {
    setIsOpen(!isOpen);
  };
  
  const [month, setMonth] = useState();
  const [year, setYear] = useState();

  const handleSubmit = async () => {
    try {
      const data = new FormData();
      data.append("month", month);
      data.append("year", year);
      dispatch(await SalaryService.create(data, _toggle));
    } catch (err) {
      console.log(err);
    }
  };

  const handleMonthChange = async (values) => {
    const month =
      values && values.values && values.values.month
        ? values.values.month.value
        : "";
    setMonth(month);
  };

  // Handle End date
  const handleYearChange = async (values) => {
    const year =
      values && values.values && values.values.year
        ? values.values.year.value
        : "";
    setYear(year);
  };

  const addSalary = (
    <>
      <div className="col">
        <YearSelect name="year" onChange={handleYearChange} />
      </div>
      <div className="col">
        <MonthSelect name="month" onChange={handleMonthChange} />
      </div>
    </>
  );

  const addFooter = <SaveButton label="Add" />;
  return (
    <div>
      <div className="d-flex justify-content-between">
        <PageTitle label="Salary" />
        {showAddButton && (
          <div className="me-2 fw-bold mb-3">
            <AddButton
              label="Add"
              onClick={() => {
                _toggle();
              }}
            />
          </div>
        )}
      </div>
      <Drawer
        modelTitle={"Add Salary"}
        DrawerBody={addSalary}
        DrawerFooter={addFooter}
        onSubmit={handleSubmit}
        initialValues={{
          month: "",
          year: "",
        }}
        enableReinitialize
        handleOpenModal={isOpen}
        handleCloseModal={_toggle}
        handleDrawerClose={_toggle}
        isModalOpen={isOpen}
      />
      <ReduxTable
        id="salaryIndex"
        showHeader
        newTableHeading
        apiURL={`${endpoints().salaryAPI}`}
        searchPlaceholder="Search"
        paramsToUrl={true}
        history={history}
        sortByDropdown
        params={{}}
        showMonthFilter
        showSearch
        showYearFilter
      >
        <ReduxColumn field="id" sortBy="id" className="text-center"
        renderField={(row) => (
          <Link to={`/salary/list/${row?.id}`} className="link-opacity-75 text-decoration-none">
            {row.id}
          </Link>
        )}>
          Id
        </ReduxColumn>
        <ReduxColumn
          field="start_date"
          sortBy="start_date"
          className="text-center"
        >
          Start Date
        </ReduxColumn>
        <ReduxColumn field="end_date" sortBy="end_date" className="text-center">
          End Date
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

export default SalaryIndex;
