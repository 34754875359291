import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import Text from "../../components/Text";
import PageTitle from "../../components/PageTitle";
import Link from "../../components/Link";
import MoreDropdown from "../../components/authentication/moreDropdown";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";

//Config
import { endpoints } from "../../api/endPoints";

// Action
import * as API from "../../actions/userSetting";

// Helpers
import { User } from "../../helpers/User";
import { Tab } from "../../helpers/ProductTag";

// Lib
import Url from "../../lib/Url";

// Font Awesome Icons
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/Button";

const UserRole = (props) => {
  const { history } = props;
  const [rowValue, setRowValue] = useState(null);
  const [isRoleListDeleteModel, setDeleteRoleListModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "role_name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const toggle = () => {
    setIsOpen(!isOpen);
    setRowValue("")
  };

  /**
   * Create Portal
   *
   * @param data
   */
  const userRoleCreate = (Values) => {
    const data = new FormData();
    data.append("role_name", Values.role_name.trim() || "");
    data.append("status", Values.status);
    let params = {
      search: Url.GetParam("search"),
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      status: Url.GetParam("status")
    };
    if (rowValue?.id) {
      dispatch(API.updateUserRole(rowValue?.id, data, params, toggle));
    } else {
      dispatch(API.createUserRole(data, params, toggle));
    }
  };

  const initialValues = {
    role_name: rowValue?.role_name ? rowValue?.role_name : "",
    status: "Active",
  };

  const statusOptions = [
    {
      value: Tab.STATUS_ACTIVE_TEXT,
      label: Tab.STATUS_ACTIVE_TEXT,
    },
    {
      value: Tab.STATUS_INACTIVE_TEXT,
      label: Tab.STATUS_INACTIVE_TEXT,
    },
    {
      value: "",
      label: "All",
    },
  ];

  const addRoleForm = (
    <div className="mt-2 mb-3">
      <div>
        <Text
          name="role_name"
          label="Role Name"
          placeholder="Enter Role Name..."
          error=""
          fontBolded
          required={true}
        />
      </div>
    </div>
  );

  const roleFooter = (
    <Button
      type="submit"
      label={rowValue && rowValue?.id ? "Save" : "Create"}
      showButtonLabelName
    />
  );

  const closeDeleteModal = () => {
    setDeleteRoleListModal(false);
    setRowValue(null);
  };

  let params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
    status: Url.GetParam("status"),
  };

  const roleDelete = async (id, params) => {
    let roleId = rowValue.id;
    let param = {
      ...Url.GetAllParams(),
    };
    dispatch(API.deleteUserRole(roleId, param));
  };

  return (
    <>
      <DeleteModal
        isOpen={isRoleListDeleteModel}
        title="Delete Role"
        label={rowValue?.role_name}
        toggle={closeDeleteModal}
        deleteFunction={roleDelete}
      />

      <Drawer
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        initialValues={initialValues}
        enableReinitialize
        DrawerBody={addRoleForm}
        DrawerFooter={roleFooter}
        modelTitle={rowValue?.id ? "Update Role" : "Create a New Role"}
        onSubmit={(values) => {
          userRoleCreate(values);
        }}
      />

      {/* /.page-heading */}
      <PageTitle
        label="Roles"
        buttonHandler={(e) => {
          toggle();
        }}
        buttonLabel="Add New"
      />
      <div className="mt-4">
        <ReduxTable
          id="userRole"
          showHeader
          searchPlaceholder="Search"
          message="You can start by clicking on Add New"
          icon={<FontAwesomeIcon icon={faBuilding} />}
          newTableHeading
          apiURL={`${endpoints().userRoleAPI}/search`}
          history={history}
          paramsToUrl={true}
          showStatusFilter
          params={{
            status: Tab.STATUS_ACTIVE_TEXT,
          }}
          sortByOptions={sortByOption}
          customStatusOption={statusOptions}
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            field="role_name"
            sortBy="role_name"
            renderField={(row) => (
              <Link
                text={row.role_name}
                url={`roles/${row.id}`}
                className="link-opacity-75"
              />
            )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            className="column-status"
            field="status"
            sortBy="status"
            width="130px"
            minWidth="130px"
            maxWidth="130px"
            renderField={(row) => (
              <div
                className={`status-input text-center rounded text-white text-uppercase ms-3 mx-auto ${row.status && row.status === User.STATUS_ACTIVE_TEXT
                  ? "bg-success"
                  : row.status === User.STATUS_INACTIVE_TEXT
                    ? "bg-dark bg-opacity-50"
                    : ""
                  }`}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            width="100px"
            disableOnClick
            renderField={(row) => (
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  {row.status == User.STATUS_ACTIVE_TEXT ? (
                    <DropdownItem
                      onClick={() => {
                        let data = {
                          status: User.STATUS_INACTIVE_TEXT
                        }
                        dispatch(
                          API.updateUserRole(
                            row.id,
                            data,
                            params
                          )
                        );
                      }}
                    >
                      Make as InActive
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      onClick={() => {
                        let data = {
                          status: User.STATUS_ACTIVE_TEXT,
                        }
                        dispatch(
                          API.updateUserRole(
                            row.id,
                            data,
                            params
                          )
                        );
                      }}
                    >
                      Make as Active
                    </DropdownItem>
                  )}
                  <DropdownItem
                    onClick={() => {
                      toggle();
                      setRowValue(row);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className="text-danger"
                    onClick={() => {
                      setRowValue(row);
                      setDeleteRoleListModal(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

export default UserRole;
