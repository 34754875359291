import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

//components
import CancelButton from "../../components/CancelButton";
import Form from "../../components/Form";
import HorizontalSpace from "../../components/HorizontalSpace";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import Text from "../../components/Text";
import TimeSelector from "../../components/TimeSelector";
import Action from "../../components/Action";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import UserList from "../../components/UserList";
import ActivityList from "../../components/ActivityList";
import Toast from "../../components/Toast";
import PageTitle from "../../components/PageTitle";

//actions
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Helpers
import Customer from "../../helpers/Customer";
import ObjectName from "../../helpers/ObjectName";
import { User } from "../../helpers/User";
import classNames from "classnames";

// Lib
import Url from "../../lib/Url";
import { isBadRequest } from "../../lib/Http";
import DateTime from "../../lib/DateTime";

// Actions
import { fetchList } from "../../actions/table";

// Services
import ShiftService from "../../services/ShiftService";

const shiftDetail = (props) => {
  const { match, history } = props;
  const shiftId = props.match.params.id;
  const dispatch = useDispatch();

  //Tab constants
  const TAB_SUMMARY = "Summary";
  const TAB_USERS = "Users";
  const TAB_HISTORY = "History";

  const [shiftDetails, setShiftDetails] = useState();
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [checkinAllowedFrom, setCheckinAllowedFrom] = useState(null);
  const [checkinAllowedTill, setCheckinAllowedTill] = useState(null);
  const [activeTabs, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : TAB_SUMMARY
  );

  const [deleteModal, setDeleteModal] = useState(false);

  //status options
  const shiftStatusOptions = [
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "InActive",
      label: "InActive",
    },
  ];

  useEffect(() => {
    getShiftDetails(match.params.id);
  }, [props]);

  //get the shift details
  const getShiftDetails = () => {
    let id = props.match.params.id;

    try {
      return apiClient
        .get(`${endpoints().shiftAPI}/${id}`)
        .then((response) => {
          const data = response.data;
          setShiftDetails(data);
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (error) {
      console.log(err);
    }
  };

  // Initial values
  const initialValues = {
    name: shiftDetails?.name,
    status:
      shiftStatusOptions &&
      shiftStatusOptions.find((option) => option.label == shiftDetails?.status),
    start_time: startTime ? startTime : shiftDetails?.start_time,
    end_time: endTime ? endTime : shiftDetails?.end_time,
    checkinAllowedFrom: checkinAllowedFrom
      ? checkinAllowedFrom
      : shiftDetails?.checkin_allowed_from,
    checkinAllowedTill: checkinAllowedTill
      ? checkinAllowedTill
      : shiftDetails?.checkin_allowed_till,
    grace_period: DateTime.getSchedulerTime().find(
      (data) => data?.value == shiftDetails?.grace_period
    ),
    checkout_cutoff_time: DateTime.getSchedulerTime().find(
      (data) => data?.value == shiftDetails?.checkout_cutoff_time
    ),
    checkin_cutoff_time: DateTime.getSchedulerTime().find(
      (data) => data?.value == shiftDetails?.checkin_cutoff_time
    ),
  };

  //Handle Update userRole Details
  const handleUpdate = (id, values) => {
    const data = new FormData();
    data.append("name", values && values.name ? values.name : "");
    data.append(
      "status",
      values && values.status ? values.status.value : "Active"
    );
    if (startTime || startTime === "") {
      data.append("start_time", values.start_time ? values.start_time : "");
    }
    if (endTime || endTime === "") {
      data.append("end_time", values.end_time ? values.end_time : "");
    }
    if (checkinAllowedFrom || checkinAllowedFrom === "") {
      data.append(
        "checkin_allowed_from",
        values.checkinAllowedFrom ? values.checkinAllowedFrom : ""
      );
    }
    if (checkinAllowedTill || checkinAllowedTill === "") {
      data.append(
        "checkin_allowed_till",
        values.checkinAllowedTill ? values.checkinAllowedTill : ""
      );
    }

    if (shiftDetails?.grace_period !== values?.grace_period?.value) {
      data.append(
        "grace_period",
        values?.grace_period ? values?.grace_period?.value : ""
      );
    }

    data.append(
      "checkout_cutoff_time",
      values && values?.checkout_cutoff_time
        ? values?.checkout_cutoff_time?.value
        : ""
    );
    data.append(
      "checkin_cutoff_time",
      values && values?.checkin_cutoff_time
        ? values?.checkin_cutoff_time?.value
        : ""
    );

    ShiftService.update(id, data, (res) => {
      if (res) {
        setStartTime(null);
        setEndTime(null);
        setCheckinAllowedFrom(null);
        setCheckinAllowedTill(null);
        getShiftDetails();
      }
    });
  };

  const DateFieldOnchange = (e) => {
    setStartDate(e);
    let date = {
      start_time: e,
    };
  };

  const handleShiftDelete = (id, params) => {
    try {
      ShiftService.delete(id);
      props.history.push("/setting/Shifts");
    } catch (err) {
      console.log(err);
    }
  };

  const breadcrumbList = [
    { label: "Settings", link: "/setting/Shifts" },
    {
      label: "Shifts",
      link: "/setting/Shifts",
    },
    {
      label: Customer.GetDisplayName(shiftDetails?.name),
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  const handleStartTime = (values) => {
    setStartTime(values ? values : "");
  };

  const handleEndTime = (values) => {
    setEndTime(values ? values : "");
  };

  const handleCheckinAllowedFrom = (values) => {
    setCheckinAllowedFrom(values ? values : "");
  };

  const handleCheckinAllowedTill = (values) => {
    setCheckinAllowedTill(values ? values : "");
  };

  const handleTabs = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);

    const updatedParams = { tab };

    if (tab === TAB_USERS) {
      updatedParams.Shifts = match.params.id;
    }

    Url.UpdateUrl(updatedParams);
  };

  let values = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    search: Url.GetParam("search"),
    current_shift: Url.GetParam("current_shift"),
    allow_leave: Url.GetParam("allow_leave"),
    status: Url.GetParam("status"),
    role: Url.GetParam("role")
  };

  const updateUser = async (value) => {
    let userId = value?.data?.userId;
    let data = value?.data;
    try {
      const response = await apiClient.put(
        `${endpoints().userAPI}/${userId}`,
        data
      );
      if (response && response.data) {
        let successMessage = response.data.message;
        Toast.success(successMessage);
        const updatedValues = {
          ...values,
          current_shift: props.match.params.id,
          status: User.STATUS_ACTIVE_VALUE,
        };
        dispatch(
          fetchList(
            "shiftUserList",
            `${endpoints().userAPI}/search`,
            updatedValues.page ? updatedValues.page : 1,
            updatedValues.pageSize ? updatedValues.pageSize : 25,
            updatedValues
          )
        );
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  return (
    <>
      <DeleteModal
        id={shiftId}
        label={shiftDetails?.name}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Product "
        deleteFunction={handleShiftDelete}
      />
      <div>
        <BreadCrumb list={breadcrumbList} />
        <div className="d-flex justify-content-between mb-3">
          <PageTitle label={shiftDetails?.name} />

          <div className="ps-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>

        <Nav tabs className="admin-tabs mt-2">

          <NavItem>
            <NavLink
              className={classNames({
                active: activeTabs === TAB_SUMMARY,
              })}
              onClick={() => {
                handleTabs(TAB_SUMMARY);
              }}
            >
              {TAB_SUMMARY}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({
                active: activeTabs === TAB_USERS,
              })}
              onClick={() => {
                handleTabs(TAB_USERS);
              }}
            >
              {TAB_USERS}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({
                active: activeTabs === TAB_HISTORY,
              })}
              onClick={() => {
                handleTabs(TAB_HISTORY);
              }}
            >
              {TAB_HISTORY}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTabs}>
          <TabPane tabId={TAB_SUMMARY}>
            <Form
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values) => {
                let id = props.match.params.id;
                handleUpdate(id, values);
              }}
            >
              <div className="card bg-white">
                <div className="card-body">
                  <Text
                    name="name"
                    label="Shift Name"
                    placeholder="Enter Shift Name..."
                    required
                  />
                  <Select
                    fullWidth={true}
                    label="Status"
                    name="status"
                    isClearable
                    options={shiftStatusOptions}
                    required
                  />
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <TimeSelector
                        label="Start Time"
                        name="start_time"
                        placeholder="Start Date"
                        isClearable
                        onChange={handleStartTime}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <TimeSelector
                        label="End Time"
                        name="end_time"
                        placeholder="End Date"
                        isClearable
                        onChange={handleEndTime}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <TimeSelector
                        label="Check-In Allowed From"
                        name="checkinAllowedFrom"
                        isClearable
                        onChange={handleCheckinAllowedFrom}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <TimeSelector
                        label="Check-In Allowed Till"
                        name="checkinAllowedTill"
                        isClearable
                        onChange={handleCheckinAllowedTill}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <Select
                      name="grace_period"
                      placeholder="Select"
                      label="Grace Period"
                      options={DateTime.getSchedulerTime(1)}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <Select
                        name="checkout_cutoff_time"
                        placeholder="Select Check Out Cut Off Time"
                        label="Check Out Cut Off Time"
                        options={DateTime.getSchedulerTime(12)}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <Select
                        name="checkin_cutoff_time"
                        placeholder="Select Check In Cut Off Time"
                        label="Check In Cut Off Time"
                        options={DateTime.getSchedulerTime(12)}
                      />
                    </div>
                  </div>
                  <HorizontalSpace bottom="3">
                    <SaveButton label="Save" />
                    <CancelButton
                      onClick={() => {
                        history.push({ pathname: "/setting/Shifts" });
                      }}
                    />
                  </HorizontalSpace>
                </div>
              </div>
            </Form>
          </TabPane>

          <TabPane tabId={TAB_USERS}>
            <UserList
              tableId="shiftUserList"
              params={{
                current_shift: props?.match?.params?.id,
                status: User.STATUS_ACTIVE_VALUE,
              }}
              props={props}
              history={history}
              bulkSelect={false}
              DropdownWithCheckbox={false}
              updateUser={(e) => updateUser(e)}
            />
          </TabPane>
          {activeTabs == TAB_HISTORY && (
            <TabPane tabId={TAB_HISTORY}>
              <ActivityList
                id={props?.match?.params?.id}
                objectId={props?.match?.params?.id}
                object_name={ObjectName.SHIFT}
              />
            </TabPane>
          )}
        </TabContent>
      </div>
    </>
  );
};

export default shiftDetail;
