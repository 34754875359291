import classnames from "classnames";
import { convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { endpoints } from "../../../api/endPoints";
import { apiClient } from "../../../apiClient";
import ActivityList from "../../../components/ActivityList";
import AddButton from "../../../components/AddButton";
import DateSelector from "../../../components/Date";
import DraftEditor from "../../../components/Draft";
import DropdownField from "../../../components/DropdownField";
import EditableField from "../../../components/EditableField";
import Form from "../../../components/Form";
import OutsideAlerter from "../../../components/OutSideClickAlerter";
import Toast from "../../../components/Toast";
import ObjectName from "../../../helpers/ObjectName";
import DateTime from "../../../lib/DateTime";
import { isBadRequest } from "../../../lib/Http";
import ProjectService from "../../../services/ProjectService";
import ProjectTicketTypeService from "../../../services/ProjectTicketTypeService";
import SprintService from "../../../services/SprintService";
import StatusService from "../../../services/StatusService";
import MediaCarousel from "../../../components/MediaCarousel";
import { Local } from "../../../helpers/LocalStorage";
import Permission from "../../../helpers/Permission";
import TagService from "../../../services/TagService";
import { hasPermission } from "../../../services/UserRolePermissionService";
import Comment from "../../../components/comment";
import TicketTestTable from "./TicketTestTable";
import StoryPointSelect from "../../../components/StoryPointSelect";
import AddModal from "../../../components/Modal";
import Button from "../../../components/Button";
import TicketService from "../../../services/TicketService";
import { useDispatch } from "react-redux";
import UserSelect from "../../../components/UserSelect";
import UserCard from "../../../components/UserCard";
import TextArea from "../../../components/TextArea";
import ProjectComponentService from "../../../services/ProjectComponentService";
import TicketList from "../../../components/TicketList";
import Url from "../../../lib/Url";
import Cookies from "../../../lib/Helper";
import Cookie from "../../../helpers/Cookie";
import CreateTicketModel from "../../../components/createTicketModel";
import TestCaseTab from "./TestCaseTab";
import CompanyUserService from "../../../services/UserService";
import {
  TICKET_FIELD_ACCEPTANCE_CRITERIA,
  TICKET_FIELD_SUB_TASKS,
  TICKET_FIELD_TEST_CASES,
  TICKET_FIELD_HISTORY,
  TICKET_FIELD_TEST,
  TICKET_FIELD_COMMENT,
  TICKET_FIELD_ACTUAL_RESULTS,
  TICKET_FIELD_ASSIGNEE,
  TICKET_FIELD_ATTACHMENT_IMAGE,
  TICKET_FIELD_COMPLETED_AT,
  TICKET_FIELD_COMPONENT,
  TICKET_FIELD_CREATED_AT,
  TICKET_FIELD_DATE,
  TICKET_FIELD_DELIVERY_DATE,
  TICKET_FIELD_DESCRIPTION,
  TICKET_FIELD_DEVELOPER,
  TICKET_FIELD_ENVIRONMENTS,
  TICKET_FIELD_ESTIMATED_HOURS,
  TICKET_FIELD_DUE_DATE,
  TICKET_FIELD_EXPECTED_RESULTS,
  TICKET_FIELD_PRIORITY,
  TICKET_FIELD_PROJECT,
  TICKET_FIELD_REPORTER,
  TICKET_FIELD_REVIEWER,
  TICKET_FIELD_SEVERITY,
  TICKET_FIELD_SPRINT,
  TICKET_FIELD_STORY_POINTS,
  TICKET_FIELD_SUMMARY,
  TICKET_FIELD_TESTER,
  TICKET_FIELD_TEST_STEPS,
  TICKET_FIELD_TYPE,
  TICKET_FIELD_UPDATED_AT,
  TICKET_FIELD_INITIAL_DUE_DATE,
  TICKET_FIELD_FROM_LOCATION,
  TICKET_FIELD_TO_LOCATION,
} from "../../../helpers/ProjectTicketType";
import Status from "../../../helpers/ProjectComponent";
import ArrayList from "../../../lib/ArrayList";
import NoRecordsFound from "../../../components/NoRecordsFound";

// Constants
const Tab = {
  ATTACHMENTS: "Attachments",
  COMMENTS: "Comments",
  HISTORY: "History",
  TEST_CASE: "Tests",
  TEST_CASES: "Test Cases",
  SUB_TASKE: "Sub Tasks",
};

const TicketDetailsForm = (props) => {
  const { details, edit, ticketId, editorStates, setEditorState, setLoading } =
    props;
  const [projectList, setProjectList] = useState();
  const [activeTab, setActiveTab] = useState(Tab.COMMENTS);
  const [statusOption, setStatusOption] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [status, setImageStatus] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [file, setFile] = useState();
  const [sprintList, setSprintlist] = useState();
  const [ticketType, setTicketType] = useState([]);
  const [projectComponentDetail, setProjectComponentDetail] = useState([]);
  const [severityList, setSeverityList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [rowValue, setRowValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [isSubmit, setIsSubmit] = useState(true);
  const [isDueDateModelOpen, setIsDueDateModelOpen] = useState(false);
  const [subTaskModalOpen, setSubTaskModalOpen] = useState(false);
  const [subTaskRowValue, setSubTaskRowValue] = useState(null);
  const [userData, setUserData] = useState([]);
  const [isTestCasesOpen, setIsTestCasesOpen] = useState(false);
  let showHistory = hasPermission(Permission.TICKET_HISTORY_VIEW);



  useEffect(() => {
    getStatus();
    getSprintlist();
    getTicketType();
    getUserList();
    getComponent();
    getSeverityList();
    getPriorityList();
    getProjectdetails();
  }, [details]);

  const Toggle = () => {
    setIsOpen(!isOpen);
    setRowValue("");
    setSelectedImage();
    setIsSubmit(true);
  };

  let dispatch = useDispatch();

  const getUserName = (media_url, firstName, lastName) => {
    return (
      <div className="d-flex">
        <UserCard
          id="avatar"
          firstName={firstName}
          lastName={lastName}
          url={media_url}
        />
      </div>
    );
  };

  const getStatus = async () => {
    const statusList = await StatusService.nextStatusSearch(
      ObjectName.TICKET,
      details.status
    );
    setStatusOption(statusList);
  };

  const getSprintlist = async () => {
    const response = await SprintService.search();
    let data = response && response?.data && response?.data?.data;
    let list = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].status === "Active") {
        list.push({
          label: data[i].name,
          value: data[i].id,
        });
      }
    }
    setSprintlist(list);
    const arrayAsString = JSON.stringify(list);
    localStorage.setItem(Local.SPRINT_LIST, arrayAsString);
  };

  const getTicketType = async () => {
    let param = {
      projectId: details && details?.project,
      defaultValue: details?.type_id
    };
    let response = await ProjectTicketTypeService.list(param);
    setTicketType(response);
    const arrayAsString = JSON.stringify(response);
    localStorage.setItem(Local.TICKET_TYPE_LIST, arrayAsString);
  };

  const getUserList = async () => {
    const data = await CompanyUserService.list();
    let user = data?.data;
    const userList = [];
    user &&
      user.forEach((list) => {
        userList.push({
          value: list.id,
          label: getUserName(
            list?.media_url,
            list?.first_name,
            list?.last_name
          ),
        });
      });
    setUserData(userList);
  };

  const getComponent = async () => {
    let param = {
      projectId: details && details?.project,
      status: Status.Active
    };
    let response = await ProjectComponentService.search(param);
    let data = response && response?.data && response?.data?.data;
    let list = [];
    for (let i = 0; i < data.length; i++) {
      const { id, name } = data[i];
      list.push({
        label: name,
        value: id,
      });
    }
    setProjectComponentDetail(list);
    const arrayAsString = JSON.stringify(list);
    localStorage.setItem(Local.TICKET_TYPE_LIST, arrayAsString);
  };

  const getSeverityList = async () => {
    let param = {
      type: "Severity",
    };
    const response = await TagService.search(param);
    let data = response && response?.data && response?.data?.data;
    let list = [];
    for (let i = 0; i < data.length; i++) {
      const { id, name } = data[i];
      list.push({
        label: name,
        value: id,
      });
    }
    setSeverityList(list);
    const arrayAsString = JSON.stringify(list);
    localStorage.setItem(Local.SEVERITY_LIST, arrayAsString);
  };

  const getPriorityList = async () => {
    let param = {
      type: "Priority",
    };
    const response = await TagService.search(param);
    let data = response && response?.data && response?.data?.data;
    let list = [];
    for (let i = 0; i < data.length; i++) {
      const { id, name } = data[i];
      list.push({
        label: name,
        value: id,
      });
    }
    setPriorityList(list);
    const arrayAsString = JSON.stringify(list);
    localStorage.setItem(Local.PRIORITY_LIST, arrayAsString);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // Update Ticket
  const updateTicket = async (id, data) => {
    await apiClient
      .put(`${endpoints().ticketAPI}/${id}`, data)
      .then((response) => {
        if (response) {
          props?.props?.history.push(
            `/ticket/${response?.data?.slug}/${props?.props?.match?.params?.id}`
          );
          setLoading(true);
          Toast.success(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleDescriptionUpdate = () => {
    let rawComment;
    if (editorStates) {
      rawComment = convertToRaw(editorStates.getCurrentContent());
    }
    let data = {
      id: ticketId,
    };
    data.description = JSON.stringify(rawComment);
    updateTicket(ticketId, data);
  };

  const handleAssigeeChange = async (values, fieldName) => {
    let data = {
      id: ticketId,
      assignee: values && values.id ? values.id : "",
    };
    updateTicket(ticketId, data);
  };

  const handleSprintChange = async (values, fieldName) => {
    let data = {
      id: ticketId,
      sprint: values && values.label ? values.label : "",
    };
    updateTicket(ticketId, data);
  };

  const handleDueDateField = (values) => {
    let data = {};
    if (values) {
      data.due_date = values;
    } else {
      const [date, time] = details?.due_date.split("T");
      data.due_date = date;
    }
    (data.id = ticketId), updateTicket(ticketId, data);
  };

  const handleInitialDueDateField = (values) => {
    let data = {};
    if (values) {
      data.initial_eta = values;
    } else {
      const [date, time] = details?.initial_eta.split("T");
      data.initial_eta = date;
    }
    (data.id = ticketId), updateTicket(ticketId, data);
  };

  const handleTicektDateField = (values) => {
    let data = {};
    if (values) {
      data.ticket_date = values;
    } else {
      const [date, time] = details?.ticket_date.split("T");
      data.ticket_date = date;
    }
    (data.id = ticketId), updateTicket(ticketId, data);
  };


  const handleDelivery_dateField = (values) => {
    let data = {};
    if (values) {
      data.delivery_date = values;
    } else {
      const [date, time] = details?.delivery_date.split("T");
      data.delivery_date = date;
    }

    (data.id = ticketId), updateTicket(ticketId, data);
  };

  const handleProjectChange = async (values, fieldName) => {
    let data = {
      id: ticketId,
      project: values && values.value,
    };
    updateTicket(ticketId, data);
  };

  // Handle Save for Text Input Fields
  const handleSummaryChange = async (values) => {
    let data = {
      summary: values.name,
      id: ticketId,
    };
    updateTicket(ticketId, data);
  };

  const getProjectdetails = async () => {
    const response = await ProjectService.getProjectList();
    const arrayAsString = JSON.stringify(response);
    localStorage.setItem(Local.PROJECT_LIST, arrayAsString);
    setProjectList(response);
  };

  const handleAcceptanceCriteriaChange = (value) => {
    let data = {
      id: ticketId,
      acceptance_criteria: value && value?.name,
    };
    updateTicket(ticketId, data);
  };

  const handleFromLocationChange = (value) => {
    let data = {
      id: ticketId,
      fromLocation: value && value?.name,
    };
    updateTicket(ticketId, data);
  };

  const handleToLocationChange = (value) => {
    let data = {
      id: ticketId,
      toLocation: value && value?.name,
    };
    updateTicket(ticketId, data);
  };

  const handleEnvironmentChange = (value) => {
    let data = {
      id: ticketId,
      environment: value && value?.name,
    };
    updateTicket(ticketId, data);
  };

  const handleTestStepChange = (value) => {
    let data = {
      id: ticketId,
      test_step: value && value?.name,
    };
    updateTicket(ticketId, data);
  };

  const handleActualResultChange = (value) => {
    let data = {
      id: ticketId,
      actual_results: value && value?.name,
    };
    updateTicket(ticketId, data);
  };

  const handleExpectedResultChange = (value) => {
    let data = {
      id: ticketId,
      expected_results: value && value?.name,
    };
    updateTicket(ticketId, data);
  };

  const handleTypeChange = (values) => {
    let value = values && values?.values?.type_id?.value;
    let data = {
      id: ticketId,
      type_id: value ? value : null,
    };
    updateTicket(ticketId, data);
  };

  const handleComponentChange = (values) => {
    let data = {
      id: ticketId,
      component_id: values?.value ? values?.value : null,
    };
    updateTicket(ticketId, data);
  };

  const handleSeverityChange = (values) => {
    let value = values && values?.values?.severity_id?.value;
    let data = {
      id: ticketId,
      severity_id: value ? value : null,
    };
    updateTicket(ticketId, data);
  };

  const handlePriorityChange = (values) => {
    let value = values && values?.values?.priority?.value;
    let data = {
      id: ticketId,
      priority: value ? value : null,
    };
    updateTicket(ticketId, data);
  };

  const handleStoryPointChange = (values) => {
    let value =
      values && values?.values?.story_points?.value
        ? values?.values?.story_points?.value
        : "";
    let data = {
      id: ticketId,
      story_points: value,
    };
    updateTicket(ticketId, data);
  };

  const handleEstimatedHoursChange = (values) => {
    let value =
      values && values?.values?.estimated_hours?.value
        ? values?.values?.estimated_hours?.value
        : "";
    let data = {
      id: ticketId,
      estimated_hours: value,
    };
    updateTicket(ticketId, data);
  };

  const handleReviewerChange = (values) => {
    let value = values && values?.id;
    let data = {
      id: ticketId,
      reviewer: value ? value : "",
    };
    updateTicket(ticketId, data);
  };

  const handleDeveloperChange = (values) => {
    let value = values && values?.id;
    let data = {
      id: ticketId,
      developer: value ? value : "",
    };
    updateTicket(ticketId, data);
  };

  const handleTesterChange = (values) => {
    let value = values && values?.id;
    let data = {
      id: ticketId,
      tester: value ? value : "",
    };
    updateTicket(ticketId, data);
  };

  const imagetoggle = (id) => {
    if (id) {
      setModalOpen(!modalOpen);
    } else {
      setModalOpen(!modalOpen);
      setCurrentData("");
      setImageStatus("");
      setSelectedFile("");
      setErrorMessage("");
      setSelectedFile("");
      setFile("");
    }
  };

  const openDueDateToggle = () => {
    setIsDueDateModelOpen(!isDueDateDateModelOpen);
  };

  let etaBody = (
    <>
      <UserSelect
        name="reviewer"
        label="Reviewer"
        selectedUserId={details?.reviewer_id ? details?.reviewer_id : null}
        required
      />
      <TextArea
        name="reason"
        label="Reason"
        placeholder="Enter Reason"
        required
      />
    </>
  );

  let etaFooter = <Button type="submit" label={"Submit"} />;

  const handleDueDateDateRequest = async (values) => {
    let data = new FormData();
    data.append("id", ticketId);
    data.append("objectName", ObjectName.TICKET);
    data.append("message", values && values?.reason);
    data.append(
      "reviewer",
      values && values?.reviewer ? values?.reviewer?.id : ""
    );
    dispatch(
      await TicketService.sendDueDate(data, (res) => {
        if (res) {
          openDueDateToggle();
        }
      })
    );
  };

  let assigneeDetail = userData?.find(
    (data) => data.value == details?.assignee_id
  );

  let reviewerDetail = userData?.find(
    (data) => data.value == details?.reviewer
  );

  let developerDetail = userData?.find(
    (data) => data.value == details?.developer
  );

  let testerDetail = userData?.find((data) => data.value == details?.tester);

  let ticketValue = ticketType.find((data) => data?.value == details?.type_id);
  let severityValue = severityList.find(
    (data) => data?.value == details?.severity_id
  );
  let priorityValue = priorityList.find(
    (data) => data?.value == details?.priority
  );

  const initialValues = {
    summary: details?.summary ? details?.summary : "",
    sprint: details?.sprint ? details?.sprint : "",
    status:
      statusOption &&
      details &&
      statusOption.find((data) => data.value == details.status),
    due_date: DateTime.getDateTimeByUserProfileTimezone(details?.due_date),
    initial_eta: DateTime.getDateTimeByUserProfileTimezone(details?.initial_eta),
    etaTime: DateTime.getDateTimeByUserProfileTimezone(details?.etaTime),
    delivery_date: DateTime.getDateTimeByUserProfileTimezone(
      details?.delivery_date
    ),
    ticket_date: DateTime.getDateTimeByUserProfileTimezone(
      details?.ticket_date
    ),
    delivery_dateTime: DateTime.getDateTimeByUserProfileTimezone(
      details?.delivery_date
    ),
    description: editorStates ? editorStates : "",
    story_points: details?.story_points
      ? {
        label: details?.story_points,
        value: details?.story_points,
      }
      : "",
    estimated_hours: details?.estimated_hours
      ? {
        label: details?.estimated_hours,
        value: details?.estimated_hours,
      }
      : "",
    project: details?.projectName
      ? {
        label: details?.projectName,
        value: details?.project,
      }
      : "",
    component_id: details?.component_id
      ? {
        label: details?.component,
        value: details?.component_id,
      }
      : "",
    assignee: assigneeDetail
      ? {
        label: assigneeDetail?.label,
        value: assigneeDetail?.value,
      }
      : "",

    reviewer: reviewerDetail
      ? {
        label: reviewerDetail?.label,
        value: reviewerDetail?.value,
      }
      : "",
    developer: developerDetail
      ? {
        label: developerDetail?.label,
        value: developerDetail?.value,
      }
      : "",
    tester: testerDetail
      ? {
        label: testerDetail?.label,
        value: testerDetail?.value,
      }
      : "",
    type_id: ticketValue ? ticketValue : "",
    severity_id: severityValue ? severityValue : "",
    priority: priorityValue ? priorityValue : "",
    sprint: details?.sprint
      ? {
        label: details?.sprint,
        value: details?.sprint,
      }
      : "",
  };

  let component = projectComponentDetail.find(
    (data) => data?.value == details?.component_id
  );

  const handleCloseModal = () => {
    setSubTaskModalOpen(!subTaskModalOpen);
  };

  const handleOpenModal = () => {
    setSubTaskModalOpen(!subTaskModalOpen);
  };

  const testCasesOpenToggle = () => {
    setIsTestCasesOpen(!isTestCasesOpen);
  };

  const testCasesCloseToggle = () => {
    setIsTestCasesOpen(false);
  };

  let fieldValue = details && details?.field?.split(',')


  return (
    <div className="mb-4 pb-4 mb-sm-2 pb-sm-2">
      <AddModal
        toggle={openDueDateToggle}
        toggleModalClose={openDueDateToggle}
        isOpen={isDueDateModelOpen}
        modalTitle="Due Date Change Request"
        modalBody={etaBody}
        modalFooter={etaFooter}
        hideDefaultButtons
        onSubmit={handleDueDateDateRequest}
        initialValues={{
          reason: "",
          reviewer: details?.reviewer_id
            ? {
              label: getUserName(
                details?.reviewer_url,
                details?.reviewer,
                null
              ),
              id: details?.reviewer_id,
            }
            : "",
        }}
        enableReinitialize
      />
      {Array.isArray(fieldValue) && (
        <Form
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => {
            let id = props.details.assignee_id;
            updateTicket(id, values);
          }}
        >
          <div className="field-wrapper mt-3">
            <div className="form-wrapper">
              {fieldValue &&
                fieldValue?.includes(TICKET_FIELD_SUMMARY.toString()) && (
                  <OutsideAlerter>
                    <EditableField
                      name={details?.summary}
                      EditHandler={(e) => {
                        handleSummaryChange(e, details?.summary, "summary");
                      }}
                      isSave
                    />
                  </OutsideAlerter>
                )}

              <div className="row">
                <div className="col-sm-8 mb-5 pb-2">
                  <hr />
                  {fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_PROJECT.toString()) && (
                      <OutsideAlerter>
                        <DropdownField
                          isDropdown
                          singleSelect
                          titleClassName="text-primary"
                          fieldTitle="Project:"
                          field="project"
                          options={projectList}
                          fieldName={
                            details?.projectName
                              ? details?.projectName
                              : "Select"
                          }
                          onInputChange={(e) => {
                            handleProjectChange(
                              e && e.values && e.values.project,
                              details?.project,
                              "project"
                            );
                          }}
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_TYPE.toString()) && (
                      <OutsideAlerter>
                        <DropdownField
                          titleClassName="text-primary"
                          isDropdown
                          singleSelect
                          showEditButton
                          fieldTitle="Type:"
                          field="type_id"
                          options={ticketType}
                          fieldName={ticketValue?.label}
                          onInputChange={(e) => handleTypeChange(e)}
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_COMPONENT.toString()) && (
                      <OutsideAlerter>
                        <DropdownField
                          titleClassName="text-primary"
                          isDropdown
                          singleSelect
                          showEditButton
                          fieldTitle="Component:"
                          field="component_id"
                          options={projectComponentDetail}
                          fieldName={details?.component}
                          onInputChange={(e) => {
                            handleComponentChange(
                              e && e.values && e.values.component_id,
                              details?.component_id,
                              "component_id"
                            );
                          }}
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_SEVERITY.toString()) && (
                      <OutsideAlerter>
                        <DropdownField
                          isDropdown
                          singleSelect
                          showEditButton
                          titleClassName="text-primary"
                          fieldTitle="Severity:"
                          field="severity_id"
                          options={severityList}
                          fieldName={severityValue?.label}
                          onInputChange={(e) => handleSeverityChange(e)}
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_PRIORITY.toString()) && (
                      <OutsideAlerter>
                        <DropdownField
                          isDropdown
                          showEditButton
                          singleSelect
                          titleClassName="text-primary"
                          fieldTitle="Priority:"
                          field="priority"
                          options={priorityList}
                          fieldName={priorityValue?.label}
                          onInputChange={(e) => handlePriorityChange(e)}
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_SPRINT.toString()) && (
                      <OutsideAlerter>
                        <DropdownField
                          isDropdown
                          showEditButton
                          singleSelect
                          fieldTitle="Sprint:"
                          field="sprint"
                          titleClassName="text-primary"
                          options={sprintList}
                          fieldName={details?.sprint}
                          onInputChange={(e) => {
                            handleSprintChange(
                              e && e.values && e.values.sprint,
                              details?.sprint,
                              "sprint"
                            );
                          }}
                        />
                      </OutsideAlerter>
                    )}
                  <div className="row">
                    <div className="col-lg-12">
                      {fieldValue &&
                        fieldValue?.includes(
                          TICKET_FIELD_DESCRIPTION.toString()
                        ) && (
                          <OutsideAlerter>
                            <DraftEditor
                              showEditButton
                              label={"Description:"}
                              name="description"
                              editorState={editorStates ? editorStates : ""}
                              onChange={handleEditorChange}
                              onBlur={() => handleDescriptionUpdate()}
                              height={"30vh"}
                              overflowY={"auto"}
                            />
                          </OutsideAlerter>
                        )}
                    </div>
                    <div className="col-lg-12">
                      {fieldValue &&
                        fieldValue?.includes(
                          TICKET_FIELD_FROM_LOCATION.toString()
                        ) && (
                          <OutsideAlerter>
                        <DropdownField
                          isTextField
                          fieldTitle="From Location:"
                          textFieldName="from_location"
                          titleClassName="text-primary"
                          className="null"
                          editableFieldName={details?.from_location}
                          EditHandler={handleFromLocationChange}
                        />
                      </OutsideAlerter>
                        )}
                    </div>
                    <div className="col-lg-12">
                    {fieldValue &&
                        fieldValue?.includes(
                          TICKET_FIELD_TO_LOCATION.toString()
                        ) && (
                          <OutsideAlerter>
                        <DropdownField
                          isTextField
                          fieldTitle="To Location:"
                          textFieldName="to_location"
                          titleClassName="text-primary"
                          className="null"
                          editableFieldName={details?.to_location}
                          EditHandler={handleToLocationChange}
                        />
                      </OutsideAlerter>
                        )}
                    </div>
                  </div>
                  <div className="p-0 col-12 col-sm-7 col-lg-6 col-xl-7">
                    {fieldValue &&
                      fieldValue?.includes(
                        TICKET_FIELD_ATTACHMENT_IMAGE.toString()
                      ) && (
                        <MediaCarousel
                          showRemoveTop
                          Attachments={"Attachments"}
                          objectId={props.ticketId}
                          objectName={ObjectName.TICKET}
                          attachmentsList
                          showFeature={false}
                          errorMessage={errorMessage}
                          setErrorMessage={setErrorMessage}
                          selectedFile={selectedFile}
                          setSelectedFile={selectedFile}
                          file={file}
                          currentData={currentData}
                          setCurrentData={setCurrentData}
                          status={status}
                          setImageStatus={setImageStatus}
                          setFile={setFile}
                          modalOpen={modalOpen}
                          setModalOpen={setModalOpen}
                          toggle={imagetoggle}
                          showCarasoul
                        />
                      )}
                  </div>
                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_ACCEPTANCE_CRITERIA.toString()
                    ) && (
                      <OutsideAlerter>
                        <DropdownField
                          isTextField
                          fieldTitle="Acceptance Criteria:"
                          textFieldName="acceptance_criteria"
                          titleClassName="text-primary"
                          className="null"
                          editableFieldName={details?.acceptance_criteria}
                          EditHandler={handleAcceptanceCriteriaChange}
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_ENVIRONMENTS.toString()
                    ) && (
                      <OutsideAlerter>
                        <DropdownField
                          isTextField
                          fieldTitle="Environment:"
                          textFieldName="environment"
                          titleClassName="text-primary"
                          className="null"
                          editableFieldName={details?.environment}
                          EditHandler={handleEnvironmentChange}
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_TEST_STEPS.toString()
                    ) && (
                      <OutsideAlerter>
                        <DropdownField
                          isTextField
                          fieldTitle="Test Step:"
                          textFieldName="test_step"
                          titleClassName="text-primary"
                          className="null"
                          editableFieldName={details?.test_step}
                          EditHandler={handleTestStepChange}
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_ACTUAL_RESULTS.toString()
                    ) && (
                      <OutsideAlerter>
                        <DropdownField
                          isTextField
                          fieldTitle="Actual Result:"
                          textFieldName="actual_results"
                          titleClassName="text-primary"
                          className="null"
                          editableFieldName={details?.actual_results}
                          EditHandler={handleActualResultChange}
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_EXPECTED_RESULTS.toString()
                    ) && (
                      <OutsideAlerter>
                        <DropdownField
                          isTextField
                          fieldTitle="Expected Result:"
                          textFieldName="expected_results"
                          titleClassName="text-primary"
                          className="null"
                          editableFieldName={details?.expected_results}
                          EditHandler={handleExpectedResultChange}
                        />
                      </OutsideAlerter>
                    )}
                </div>
                <div className="col-sm-4">
                  {((fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_REPORTER.toString())) ||
                    (fieldValue &&
                      fieldValue?.includes(TICKET_FIELD_ASSIGNEE.toString())) ||
                    (fieldValue &&
                      fieldValue?.includes(TICKET_FIELD_REVIEWER.toString())) ||
                    (fieldValue &&
                      fieldValue?.includes(
                        TICKET_FIELD_DEVELOPER.toString()
                      )) ||
                    (fieldValue &&
                      fieldValue?.includes(
                        TICKET_FIELD_TESTER.toString()
                      ))) && (
                      <>
                        <hr />
                        <h6 className="text-primary">People:</h6>
                      </>
                    )}
                  <>
                    {fieldValue &&
                      fieldValue?.includes(
                        TICKET_FIELD_REPORTER.toString()
                      ) && (
                        <OutsideAlerter>
                          <DropdownField
                            isDropdown
                            userField
                            userSelect
                            disabled
                            fieldTitle="Reporter:"
                            field="reporter"
                            fieldName={details?.reporter}
                            avatarUrl={details?.reporter_url}
                          />
                        </OutsideAlerter>
                      )}
                  </>
                  <>
                    {fieldValue &&
                      fieldValue?.includes(
                        TICKET_FIELD_ASSIGNEE.toString()
                      ) && (
                        <OutsideAlerter>
                          <DropdownField
                            isDropdown
                            userField
                            userSelect
                            fieldTitle="Assignee:"
                            field="assignee"
                            showAssignToMeOption
                            disabled={
                              details?.allow_for_assignee_change_permission ===
                                false
                                ? true
                                : false
                            }
                            handleUserChange={(value) => {
                              handleAssigeeChange(
                                value,
                                details?.assignee,
                                "assigneeId"
                              );
                            }}
                            avatarUrl={details?.assignee_url}
                            fieldName={
                              details?.assignee ? details?.assignee : "None"
                            }
                            hideAvatar={details?.assignee ? false : true}
                          />
                        </OutsideAlerter>
                      )}
                    {fieldValue &&
                      fieldValue?.includes(
                        TICKET_FIELD_REVIEWER.toString()
                      ) && (
                        <OutsideAlerter>
                          <DropdownField
                            isDropdown
                            userField
                            userSelect
                            fieldTitle="Reviewer:"
                            field="reviewer"
                            showAssignToMeOption
                            handleUserChange={handleReviewerChange}
                            fieldName={
                              details?.reviewerName
                                ? details?.reviewerName
                                : "None"
                            }
                            avatarUrl={details?.reviewer_url}
                            hideAvatar={details?.reviewerName ? false : true}
                          />
                        </OutsideAlerter>
                      )}
                    {fieldValue &&
                      fieldValue?.includes(
                        TICKET_FIELD_DEVELOPER.toString()
                      ) && (
                        <OutsideAlerter>
                          <DropdownField
                            isDropdown
                            userField
                            userSelect
                            fieldTitle="Developer:"
                            field="developer"
                            showAssignToMeOption
                            handleUserChange={handleDeveloperChange}
                            fieldName={
                              details?.developerName
                                ? details?.developerName
                                : "None"
                            }
                            avatarUrl={details?.developer_url}
                            hideAvatar={details?.developerName ? false : true}
                          />
                        </OutsideAlerter>
                      )}
                    {fieldValue &&
                      fieldValue?.includes(TICKET_FIELD_TESTER.toString()) && (
                        <OutsideAlerter>
                          <DropdownField
                            isDropdown
                            userField
                            userSelect
                            fieldTitle="Tester:"
                            field="tester"
                            showAssignToMeOption
                            handleUserChange={handleTesterChange}
                            fieldName={
                              details?.testerName ? details?.testerName : "None"
                            }
                            avatarUrl={details?.tester_url}
                            hideAvatar={details?.testerName ? false : true}
                          />
                        </OutsideAlerter>
                      )}
                  </>
                  {fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_INITIAL_DUE_DATE.toString()) && (
                      <div className="row">
                        <hr />
                        <h6 className="col text-primary d-flex align-items-center">
                          Initial Due Date:
                        </h6>
                        <div className="col-6">
                          <DateSelector
                            name="initial_eta"
                            placeholder="Select Initial Due Date"
                            onChange={(e) => {
                              handleInitialDueDateField(
                                DateTime.getDateByUserProfileTimezone(e),
                                details?.initial_eta,
                                "initial_eta",
                                "currentInitialDueDate"
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_DUE_DATE.toString()) && (
                      <div className="row">
                        <hr />
                        <h6 className="col text-primary d-flex align-items-center">
                          Due Date:
                        </h6>
                        <div className="col-6">
                          <DateSelector
                            name="due_date"
                            placeholder="Select Due Date"
                            disabled={
                              details && details.dueDatePermission === false
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              handleDueDateField(
                                DateTime.getDateByUserProfileTimezone(e),
                                details?.due_date,
                                "due_date",
                                "currentDueDate"
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_DATE.toString()) && (
                      <div className="row">
                        <h6 className="col text-primary d-flex align-items-center">
                          Ticket Date:
                        </h6>
                        <div className="col-6">
                          <DateSelector
                            name="ticket_date"
                            placeholder="Select"
                            disabled={
                              details && details.dueDatePermission === false
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              handleTicektDateField(
                                DateTime.getDateByUserProfileTimezone(e),
                                details?.due_date,
                                "ticket_date"
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  {((fieldValue &&
                    fieldValue?.includes(TICKET_FIELD_CREATED_AT.toString())) ||
                    (fieldValue &&
                      fieldValue?.includes(
                        TICKET_FIELD_UPDATED_AT.toString()
                      )) ||
                    (fieldValue &&
                      fieldValue?.includes(
                        TICKET_FIELD_COMPLETED_AT.toString()
                      ))) && (
                      <>
                        <hr />
                        <h6 className="text-primary">Dates:</h6>
                      </>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_CREATED_AT.toString()
                    ) && (
                      <OutsideAlerter>
                        <DropdownField
                          isDateField
                          disabled
                          fieldName={(
                            details?.createdAt
                          )}
                          fieldTitle="Created:"
                          dateFieldName="created"
                        />
                      </OutsideAlerter>
                    )}

                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_UPDATED_AT.toString()
                    ) && (
                      <OutsideAlerter>
                        <DropdownField
                          isDateField
                          disabled
                          fieldName={(
                            details?.updatedAt
                          )}
                          fieldTitle="Updated:"
                          dateFieldName="updated"
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_COMPLETED_AT.toString()
                    ) && (
                      <OutsideAlerter>
                        <DropdownField
                          isDateField
                          disabled
                          fieldName={(
                            details?.completed_at
                          )}
                          fieldTitle="Completed:"
                          dateFieldName="completed"
                        />
                      </OutsideAlerter>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_STORY_POINTS.toString()
                    ) && (
                      <>
                        <hr />
                        <h6 className="text-primary">Story Points:</h6>
                        <OutsideAlerter>
                          <StoryPointSelect
                            name="story_points"
                            onChange={(e) => handleStoryPointChange(e)}
                            value={details?.story_points}
                          />
                        </OutsideAlerter>
                      </>
                    )}
                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_ESTIMATED_HOURS.toString()
                    ) && (
                      <>
                        <hr />
                        <h6 className="text-primary">Hours:</h6>
                        <div className="row">
                          <div className="col-5 mt-2">Estimated:</div>
                          <div className="col-7">
                            {fieldValue &&
                              fieldValue?.includes(
                                TICKET_FIELD_ESTIMATED_HOURS.toString()
                              ) && (
                                <OutsideAlerter>
                                  <StoryPointSelect
                                    name="estimated_hours"
                                    onChange={(e) =>
                                      handleEstimatedHoursChange(e)
                                    }
                                    value={details?.estimated_hours}
                                  />
                                </OutsideAlerter>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  <hr />
                  {fieldValue &&
                    fieldValue?.includes(
                      TICKET_FIELD_DELIVERY_DATE.toString()
                    ) && <h6 className="text-primary">Delivery Date:</h6>}
                  <div className="row">
                    <div className="col-6">
                      {fieldValue &&
                        fieldValue?.includes(
                          TICKET_FIELD_DELIVERY_DATE.toString()
                        ) && (
                          <DateSelector
                            name="delivery_date"
                            placeholder="Delivery Date"
                            disabled={
                              details && details.UserRolePermission === false
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              handleDelivery_dateField(
                                DateTime.getDateByUserProfileTimezone(e),
                                details?.delivery_date,
                                "delivery_date",
                                "currentDelivery_date"
                              );
                            }}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
      {ArrayList.isEmpty(fieldValue) && (
        <NoRecordsFound middleHeight={"70vh"} />
      )}
      {activeTab == Tab.TEST_CASE && (
        <AddButton
          className="mt-3 pull-right"
          label="Add Test"
          onClick={(e) => {
            Toggle();
          }}
        />
      )}
      {activeTab == Tab.TEST_CASES && (
        <AddButton
          className="mt-3 pull-right"
          label="Add Test Cases"
          onClick={(e) => {
            testCasesOpenToggle();
          }}
        />
      )}
      {activeTab == Tab.SUB_TASKE && (
        <AddButton
          className="mt-3 pull-right"
          label="Add Sub Task"
          onClick={(e) => {
            setSubTaskModalOpen(true);
          }}
        />
      )}
      <CreateTicketModel
        isModalOpen={subTaskModalOpen}
        setModalOpen={setSubTaskModalOpen}
        handleCloseModal={handleCloseModal}
        subTaskValue={details}
        rowValue={subTaskRowValue}
      />
      {((fieldValue && fieldValue?.includes(TICKET_FIELD_COMMENT.toString())) ||
        (fieldValue &&
          fieldValue?.includes(TICKET_FIELD_SUB_TASKS.toString())) ||
        (fieldValue && fieldValue?.includes(TICKET_FIELD_TEST.toString())) ||
        (fieldValue && fieldValue?.includes(TICKET_FIELD_TEST.toString())) ||
        (fieldValue &&
          fieldValue?.includes(TICKET_FIELD_HISTORY.toString()))) && (
          <Nav tabs className="admin-tabs">
            {/* General Tab */}
            {fieldValue &&
              fieldValue?.includes(TICKET_FIELD_COMMENT.toString()) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === Tab.COMMENTS,
                    })}
                    onClick={() => {
                      toggle(Tab.COMMENTS);
                    }}
                  >
                    Comments
                  </NavLink>
                </NavItem>
              )}
            {fieldValue &&
              fieldValue?.includes(TICKET_FIELD_SUB_TASKS.toString()) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === Tab.SUB_TASKE,
                    })}
                    onClick={() => {
                      toggle(Tab.SUB_TASKE);
                    }}
                  >
                    {Tab.SUB_TASKE}
                  </NavLink>
                </NavItem>
              )}
            {fieldValue && fieldValue?.includes(TICKET_FIELD_TEST.toString()) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === Tab.TEST_CASE,
                  })}
                  onClick={() => {
                    toggle(Tab.TEST_CASE);
                  }}
                >
                  Tests
                </NavLink>
              </NavItem>
            )}
            {fieldValue &&
              fieldValue?.includes(TICKET_FIELD_TEST_CASES.toString()) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === Tab.TEST_CASES,
                    })}
                    onClick={() => {
                      toggle(Tab.TEST_CASES);
                    }}
                  >
                    {Tab.TEST_CASES}
                  </NavLink>
                </NavItem>
              )}
            {fieldValue &&
              fieldValue?.includes(TICKET_FIELD_HISTORY.toString()) && (
                <NavItem>
                  {showHistory && (
                    <NavLink
                      className={classnames({
                        active: activeTab === Tab.HISTORY,
                      })}
                      onClick={() => {
                        toggle(Tab.HISTORY);
                      }}
                    >
                      History
                    </NavLink>
                  )}
                </NavItem>
              )}
          </Nav>
        )}

      <TabContent activeTab={activeTab}>
        {/* Detail Tab Start*/}
        {fieldValue &&
          fieldValue?.includes(TICKET_FIELD_COMMENT.toString()) &&
          activeTab == Tab.COMMENTS && (
            <TabPane tabId={Tab.COMMENTS} className="w-100">
              <Comment
                objectId={props.ticketId}
                objectName={ObjectName.TICKET}
                assignee_id={details?.assignee_id}
                reviewer_id={details?.reviewer_id}
                ticketDetails
              />
            </TabPane>
          )}
        {/* History Tab Start*/}
        {showHistory && activeTab == Tab.HISTORY && (
          <TabPane tabId={Tab.HISTORY} className="w-100">
            <ActivityList
              id={ticketId}
              objectId={ticketId}
              object_name={ObjectName.TICKET}
              history={props.history}
            />
          </TabPane>
        )}
        {activeTab == Tab.TEST_CASE && (
          <TabPane tabId={Tab.TEST_CASE} className="w-100">
            <TicketTestTable
              history={props.history}
              ticketId={props.ticketId}
              isOpen={isOpen}
              toggle={Toggle}
              setRowValue={setRowValue}
              rowValue={rowValue}
              setSelectedFile={setSelectedImage}
              selectedFile={selectedImage}
              setIsSubmit={setIsSubmit}
              isSubmit={isSubmit}
            />
          </TabPane>
        )}
        {activeTab == Tab.SUB_TASKE && (
          <TabPane tabId={Tab.SUB_TASKE} className="w-100">
            <TicketList
              history={props.history}
              newTableHeading
              parent_ticket_id={ticketId}
              projectId={
                Cookies.get(Cookie.PROJECT_ID)
                  ? Cookies.get(Cookie.PROJECT_ID)
                  : Url.GetParam("projectId") !== undefined
                    ? Url.GetParam("projectId")
                    : ""
              }
              handleOpenModal={handleOpenModal}
              setRowValue={setSubTaskRowValue}
            />
          </TabPane>
        )}
        {activeTab == Tab.TEST_CASES && (
          <TabPane tabId={Tab.TEST_CASES} className="w-100">
            <TestCaseTab
              history={props.history}
              ticketId={ticketId}
              projectId={
                Cookies.get(Cookie.PROJECT_ID)
                  ? Cookies.get(Cookie.PROJECT_ID)
                  : Url.GetParam("projectId") !== undefined
                    ? Url.GetParam("projectId")
                    : ""
              }
              isTestCasesOpen={isTestCasesOpen}
              testCasesOpenToggle={testCasesOpenToggle}
              testCasesCloseToggle={testCasesCloseToggle}
            />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

export default TicketDetailsForm;
