

export const Type ={
    LEAVE_TEXT: "Leave",
    WORKING_DAY_TEXT: "Working Day",
    WORKING_DAY: 1,
    LEAVE: 2,
   

}
export const Status ={
   STATUS_ACTIVE_TEXT:"Active",
   STATUS_INACTIVE_TEXT:"InActive",
   
   STATUS_ACTIVE:1,
   STATUS_INACTIVE:2,
}



export const statusOptions =[
   {
       label: Status.STATUS_ACTIVE_TEXT,
       value: Status.STATUS_ACTIVE
   },
   {
       label: Status.STATUS_INACTIVE_TEXT,
       value: Status.STATUS_INACTIVE
   }
]