// API
import { endpoints } from "../api/endPoints";

// Config
import { apiClient } from "../apiClient";

// Components
import UserCard from "../components/UserCard";

class UserService {
  static async search(id) {
    try {
      const response = await apiClient.get(`${endpoints().userAPI}/search`);
      const data = response.data.data;
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  static async delete(id) {
    try {
      const response = await apiClient.delete(`${endpoints().userAPI}/${id}`);
      const data = response.data.data;
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getUserName(media_url, firstName, lastName) {
    return (
      <div className="d-flex">
        <UserCard
          id="avatar"
          firstName={firstName}
          lastName={lastName}
          url={media_url}
        />
      </div>
    );
  }

  static async getSalesExecutive() {
    try {
      const response = await apiClient.get(
        `${endpoints().userAPI}/salesExecutive`
      );
      const data = response?.data?.data;
      
      let List = [];
      let inActiveList = [];
  
      if (data?.activeList && data.activeList.length > 0) {
        List = await Promise.all(
          data.activeList.map(async (list) => {
            return {
              value: list.first_name + " " + list.last_name,
              id: list.value,
              label: await this.getUserName(
                list?.media_url,
                list.first_name,
                list.last_name
              ),
              media_url: list?.media_url,
              first_name: list.first_name,
              last_name: list.last_name,
            };
          })
        );
      }
  
      if (data?.inActiveList && data.inActiveList.length > 0) {
        inActiveList = await Promise.all(
          data.inActiveList.map(async (list) => {
            return {
              value: list.first_name + " " + list.last_name,
              id: list.id,
              label: await this.getUserName(
                list?.media_url,
                list.first_name,
                list.last_name
              ),
              media_url: list?.media_url,
              first_name: list.first_name,
              last_name: list.last_name,
            };
          })
        );
      }
  
      return { activeList: List, inActiveList: inActiveList };
    } catch (err) {
      console.log(err);
      return { activeList: [], inActiveList: [] };
    }
  }
  
}

export default UserService;
