import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";

class GoogleService {
  static async connect() {
    const response = await apiClient.post(`${endpoints().googleAPI}/auth`);
    return response && response?.data;
  }

  static async status() {
    const response = await apiClient.get(`${endpoints().googleAPI}/status`);
    return response && response?.data?.data;
  }
}

export default GoogleService;
