import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Collapse, Row } from "reactstrap";

// Action
import * as API from "../../../actions/userSetting";

// Components
import DefaultContent from "../../../components/content/defaultContent";
import SingleCheckbox from "../../../components/SingleCheckbox";
import Spinner from "../../../components/Spinner";
import Form from "../../../components/Form";

// Services
import Permission from "../../../helpers/Permission";

// Assets
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserRolePermission = (props) => {
  const { match } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [permissionList, setPermissionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState([]);
  const [userPermissionList, setUserPermissionList] = useState([]);

  const dispatch = useDispatch();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Create Creation
   *
   * @param values
   */
  const userRolePermissionCreate = (data) => {
    dispatch(API.createRolePermission(data, match.params.id, {}, () => { }));

    toggle();
  };


  useEffect(() => {
    getRolePermissions(props.match.params.id);
    setUserPermissionList(Permission.UserPermissionLists()); // Fetch user permissions
  }, []);

  //Get Role Permission
  const getRolePermissions = async (roleId) => {
    setIsLoading(true);
    const lists = await API.search(roleId);
    setPermissionList(lists);
    setIsLoading(false);
  };

  // handle check box value
  const handleCheckBoxValue = (value, name) => {
    // convert object to array
    let array = Object.entries(value);
    let checkBoxData = {};
    // forEach the selected permission
    array.forEach((result) => {
      // if selected name is equal to field name
      if (result[0] === name) {
        // push permission name
        checkBoxData.label = result[0];
        // push permission value
        checkBoxData.value = result[1];
      }
    });
    // permission post Api
    userRolePermissionCreate(checkBoxData);
  };

  useEffect(() => {
    const allIndices = Permission.permissionList.map((_, index) => index);
    setActiveAccordion(allIndices);
  }, []);

  // Spinner loading
  if (isLoading) {
    return <Spinner />;
  }

  // Accordion toggle
  const toggleAccordion = (index) => {
    setActiveAccordion((prevAccordion) => {
      if (prevAccordion.includes(index)) {
        return prevAccordion.filter((item) => item !== index);
      } else {
        return [...prevAccordion, index];
      }
    });
  };

  // Generate initial values from the permission list and user permission list
  const generateInitialValues = (permissionList, userPermissionList) => {
    return permissionList.reduce((acc, permission) => {
      const permissionExists = userPermissionList.some(
        (userPermission) => userPermission.value === permission.value
      );
      acc[permission.value] = permissionExists;
      return acc;
    }, {});
  };

  // Generate initial values for form checkboxes
  const initialValues = generateInitialValues(permissionList, userPermissionList);

  return (
    <>
      <Form initialValues={initialValues}>
        <DefaultContent>
          {Permission.permissionList.map((role, index) => {
            const isActive = activeAccordion.includes(index);

            return (
              <div className="card" key={index}>
                <div className="card-header" onClick={() => toggleAccordion(index)}>
                  <div>
                    <p className="pull-right cursor-pointer">
                      {!isActive ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                    </p>
                  </div>
                  <h5>{role.title}</h5>
                </div>

                <Collapse isOpen={isActive}>
                  <div className="form-wrapper p-3">
                    {role.permission.map((permission) => (
                      <div className="field-wrapper" key={permission.name}>
                        <SingleCheckbox
                          name={permission.name}
                          label={permission.label}
                          className="accepted-terms mb-1 pb-0 me-3"
                          handleOnChangeSubmit={(value, name) =>
                            handleCheckBoxValue(value, name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </Collapse>
              </div>
            );
          })}
        </DefaultContent>
      </Form>
    </>
  );
};

export default UserRolePermission;
