import React, { useEffect, useState } from 'react'

// Components
import PageTitle from '../../components/PageTitle';
import BreadCrumb from '../../components/Breadcrumb';
import Form from '../../components/Form';
import LeadTypeForm from './components/LeadTypeForm';
import SaveButton from '../../components/SaveButton';
import CancelButton from '../../components/CancelButton';
import DeleteModal from '../../components/DeleteModal';
import Action from '../../components/Action';

// Services
import LeadSettingService from '../../services/LeadSettingService';
import { hasPermission } from '../../services/UserRolePermissionService';

// Helpers
import { statusOptions } from '../../helpers/Lead';
import Permission from '../../helpers/Permission';

const LeadTypeDetail = (props) => {
    const [detail, setDetail] = useState(null)
    const [isModalOpan, setIsModalOpan] = useState(false)

    let hasDeletePermission = hasPermission(Permission.LEAD_TYPE_DELETE);

    useEffect(() => {
        getDetail()
    }, [])

    const breadcrumbList = [
        { label: "Home", link: "/admin/companyProfile" },
        { label: "Lead Types", link: "/setting/leadType" },
        { label: "Lead Type Detail", link: "" },
    ];

    const getDetail = async () => {
        let data = await LeadSettingService.get(props?.match?.params?.id);
        setDetail(data)
    };

    let initialValues = {
        name: detail?.name ? detail?.name : "",
        status: statusOptions.find((data) => data?.value == detail?.status)
    };

    let handleFormSubmit = async (values) => {
        let data = new FormData();
        data.append("name", values?.name ? values?.name : "")
        data.append("status", values?.status ? values?.status?.value : "");

        data.append("id", detail?.id);
        await LeadSettingService["update"](data);
    };

    const actionsMenuList = [];

    if (hasDeletePermission) {
        actionsMenuList.push(
            {
                value: "Delete",
                label: "Delete",
            },
        )
    }

    const handleActionChange = (e) => {
        if (e === "Delete") {
            setIsModalOpan(true);
        }
    };

    const handleDelete = async () => {
        await LeadSettingService.delete(detail?.id, (res) => {
            props.history.push("/setting/leadType")
        })
    }

    return (
        <div>
            <BreadCrumb list={breadcrumbList} />
            <div className="d-flex justify-content-between mb-3">
                <PageTitle label="Lead Type Detail Page" />
                {actionsMenuList && actionsMenuList?.length > 0 && (
                    <div className="d-flex justify-content-end">
                        <Action
                            dropdownLinks={actionsMenuList}
                            handleChange={handleActionChange}
                        />
                    </div>
                )}
            </div>
            <DeleteModal
                isOpen={isModalOpan}
                toggle={() => {
                    setIsModalOpan(false);
                }}
                title="Delete Lead Type"
                label={detail?.name}
                deleteFunction={() => {
                    handleDelete()
                }}
            />

            <div className='card card-body'>
                <Form
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                >
                    <LeadTypeForm />
                    <div className="d-flex">
                        <SaveButton
                            className="btn-sm my-1"
                        />
                        <CancelButton
                            className="btn-sm my-1"
                            onClick={() => {
                                props?.history?.goBack()
                            }}
                        />
                    </div>
                </Form>
            </div>
        </div>
    )
};

export default LeadTypeDetail;
