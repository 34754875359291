import React, { useEffect, useState } from "react";
import DateTime from "../../../lib/DateTime";
import Url from "../../../lib/Url";
import Filter from "../../../components/Filter";
import { DateOptions } from "../../../helpers/Date";
import ArrayList from "../../../lib/ArrayList";
import CompanyUserService from "../../../services/UserService";
import AttendanceTypeService from "../../../services/AttendanceTypeService";

const AttendanceFilter = ({
  initialParam,
  handleChange,
  setPage,
  setPageSize,
  pageSize,
  props,
  detail,
  handleColumnChange,
  arrayList,
}) => {
  const [param, setParam] = useState({
    user_id: "",
    startDate: "",
    endDate: "",
    date: "",
    type:"",
    comparison_type:"",
    comparison_value:""
  });
    const [typeList, setTypeList] = useState([]);
  
  const [usersList, setUsersList] = useState([]);
  useEffect(() => {
    setPageSize(Url.GetParam("pageSize"));
    getUserList();
    attendanceTypeList()
  }, [initialParam]);

  const attendanceTypeList = async ()=>{
    const list = await AttendanceTypeService.list();
    setTypeList(list)
  }

  const FieldLabel = ArrayList.isArray(detail[0]?.columns)
    ? detail[0]?.columns.map(item => ({
      label: item?.attendance_type_name,
      value: item?.attendance_type_name
    }))
    : [];

  FieldLabel.push({
    label: "Additional Hours",
    value: "additional_hours"
  });

  const getUserList = async () => {
    const data = await CompanyUserService.getOption();
    setUsersList(data);
  };

  let customSelectOption = [
    {
      label: "Greater than",
      value: 1
    },
    {
      label: "Equal to",
      value: 2
    },
    {
      label: "Less than",
      value: 3
    },
  ];

  // Handle End date
  const handleFilter = async (values) => {
    let endDateValue = values?.endDate ? DateTime.toISOStringDate(values?.endDate) : "";
    let startDateValue = values?.startDate ? DateTime.toISOStringDate(values?.startDate) : "";
    let data = {
      user_id: values?.user?.id ? values?.user?.id : "",
      endDate: endDateValue ? endDateValue : "",
      startDate: startDateValue ? startDateValue : "",
      date: values?.date?.value ? values?.date?.value : "",
      type: values?.type?.value ? values?.type?.value : "",
      comparison_type: values?.comparison_type?.value ? values?.comparison_type?.value :"",
      comparison_value: values?.comparison_value ? values?.comparison_value :"",
    };
    Url.UpdateUrl(
      {
        user_id: values?.user?.id ? values?.user?.id : "",
        endDate: endDateValue ? endDateValue : "",
        startDate: startDateValue ? startDateValue : "",
        date: values?.date?.value ? values?.date?.value : "",
        type: values?.type?.value ? values?.type?.value : "",
        comparison_type: values?.comparison_type?.value ? values?.comparison_type?.value :"",
        comparison_value: values?.comparison_value ? values?.comparison_value :"",
        ...pageSize,
      },
      props
    );
    handleChange(data);
    setParam(data);
  };

  // Handle Pagesize
  const handlePageSize = async (pageSize) => {
    setPageSize(pageSize);


    let data = {
     ...param,
     pageSize: pageSize,
     user: param?.user_id
    };
    setParam(data);
    setPage(1);
    Url.UpdateUrl(data,
      props
    );
    handleChange(data);
  };

  const initialValues = {
    user: usersList
      ? usersList.find((data) => data.id == Url.GetParam("user_id"))
      : "",
    startDate: DateTime.getDate(Url.GetParam("startDate"))  || "",
    endDate: DateTime.getDate(Url.GetParam("endDate")) || "",
    date:
      DateOptions &&
      DateOptions.find((fine) => fine.value == Url.GetParam("date")),
      type: typeList.find((value)=> value.value == Url.GetParam("type")),
      comparison_type: customSelectOption.find((value)=> value.value == Url.GetParam("comparison_type")),
      comparison_value: Url.GetParam("comparison_value"),
  };


  const handleRefreshButtonOnClick = () => {
    handleChange();
  }
  const handleDeleteFilter = async (removedFilter) => {
    let params = {
      ...param,
      ...removedFilter,
    };

    if(Object.keys(removedFilter)[0] == "user"){
      params.user_id = ""
      params.user = ""
    }

    Url.UpdateUrl(
      {
        ...params,
        ...pageSize,
      },
      props
    );
    handleChange(params);
    setParam(params);
  };


  return (
    <>
      <Filter
        showPageSize
        showHeader
        newTableHeading
        sortByDropdown
        searchPlaceholder="Search"
        getPageSizeByOptions={(e) => handlePageSize(e)}
        selectedPageSize={pageSize && pageSize}
        refreshButtonOnClick={handleRefreshButtonOnClick}
        handleDeleteFilter={handleDeleteFilter}
        initialValues={initialValues}
        handleFilter={handleFilter}
        FieldLabel={FieldLabel}
        handleColumnChange={handleColumnChange}
        showCustomDateFilter
        showUserFilter
        arrayList={arrayList}
        DropdownWithCheckbox
        showAttendanceTypeFilter
        showCustomSelectFilter
        customSelectPlaceholder={"Select Comparison"}
        customSelectOption={customSelectOption}
        customSelectName={"comparison_type"}
        customNumberName="comparison_value"
        customNumberPlaceholder="Enter Value ..."
        showCustomNumberFilter
      />
    </>

  );
};
export default AttendanceFilter;
