import React, { useEffect, useState } from "react";
import Number from "../../../components/Number";
import Select from "../../../components/Select";
import Text from "../../../components/Text";
import { useDispatch } from "react-redux";
import MultiSelect from "../../../components/Multiselect";
import SingleCheckbox from "../../../components/SingleCheckbox";
import SubTitle from "../../../components/SubTitle";
import RoleService from "../../../services/RoleService";
import StatusService from "../../../services/StatusService";
import ObjectName from "../../../helpers/ObjectName";
import CompanyUserService from "../../../services/UserService";
import UserSelect from "../../../components/UserSelect";

const ActivityTypeForm = ({ rowValue }) => {
  const [activityTypeData, setActivityTypeData] = useState();
  const [roles, setRoles] = useState();
  const [list, setList] = useState();
  const [userList, setUserList] = useState();

  useEffect(() => {
    UserList();

    getRoleDetail();
    getStatusList();
  }, []);


  const dispatch = useDispatch();


  //get role deatails
  const getRoleDetail = async () => {
    const response = await RoleService.getRoleDetail();
    setRoles(response);
  };

  const getValue = (array, array1) => {
    const selectedRoles = [];
    const roleInitialValues = {};

    if (array && array.length > 0) {
      console.log();
      array.forEach((data) => {
        if (array1 && array1.length > 0)
          array1.forEach((tag) => {
            if (tag.value == data) selectedRoles.push(tag);
          });
      });
      roleInitialValues["allowed_roles"] = selectedRoles;
    }
    return roleInitialValues;
  };

  let selectedRoleIds = activityTypeData?.allowed_roles || "";

  let selectedRoleArray = selectedRoleIds.split(",");

  const selectedRole = getValue(selectedRoleArray, roles);

  const getStatusList = async () => {
    const response = await StatusService.getOption(ObjectName.ACTIVITY);
    const actvityList = []
    response.forEach((data) => {
      actvityList.push({
        value: data?.value,
        label: data?.label
      })

    })

    setList(actvityList);
  };

  const UserList = async () => {
    const response = await CompanyUserService.list();
    const data = response.data;
    const usersList = [];
    data
      .sort((a, b) => parseFloat(a.sort_order) - parseFloat(b.sort_order))
      .forEach((list) => {
        usersList.push({
          value: list.id,
          label: list.first_name,
        });
      });

    setUserList(usersList);
  };

  return (
    <div>
      <Text
        name="name"
        label="Activity Type Name"
        placeholder="Enter Name"
        required
      />
      <MultiSelect
        name={"allowed_roles"}
        label="Allowed Roles"
        options={roles}
      />
      <UserSelect
        label="Approver"
        name="approvers"
        placeholder={"Select Approver"}
        userList={setUserList}
      />
         <Number
        name="min_entries_per_day"
        label="Minimum Entry Per Day"
        placeholder="Enter Minimum Entry Per Day"
      />
      <Number
        name="max_entries_per_day"
        label="Maximum Entry Per Day"
        placeholder="Enter Maximum Entry Per Day"
      />
      <Select
        name="default_status"
        label="Default Status"
        placeholder="Default Status"
        options={list}
      />
      <SubTitle label={"Fields"} />
      <div className="form-wrapper">
        <div className="form-wrapper">
          {/* company view */}
          <div className="field-wrapper">
            <SingleCheckbox
              name={"show_attachments"}
              label={"Show Attachments"}
              className="accepted-terms pb-0 me-3"
            />
          </div>{" "}
          <div className="field-wrapper">
            <SingleCheckbox
              name={"show_notes"}
              label={"Show Notes"}
              className="accepted-terms pb-0 me-3"
            />
          </div>
          

          <SubTitle label="Settings" />
          <div className="field-wrapper">
            <SingleCheckbox
              name={"auto_add"}
              label={"Auto Add"}
              className="accepted-terms pb-0 me-3"
            />
          </div>
          <div className="field-wrapper">
          <SingleCheckbox
              name={"attachment_required"}
              label={"Attachment Required"}
              className="accepted-terms pb-0 me-3"
            />
          </div>
          <div className="field-wrapper">
          <SingleCheckbox
              name={"notify_owner"}
              label={"Notify Owner"}
              className="accepted-terms pb-0 me-3"
            />
          </div>
          <div className="field-wrapper">
          <SingleCheckbox
              name={"notification"}
              label={"Notification"}
              className="accepted-terms pb-0 me-3"
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default ActivityTypeForm;
