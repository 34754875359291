import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";
import Text from "../../components/Text";
import PageTitle from "../../components/PageTitle";
import { Link } from "react-router-dom";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";

//Config
import { endpoints } from "../../api/endPoints";

// Action
import DeleteModal from "../../components/DeleteModal";
import SaveButton from "../../components/SaveButton";
import ProjectService from "../../services/ProjectService";
import MoreDropdown from "../../components/authentication/moreDropdown";
import { Projects } from "../../helpers/Project";
import ObjectName from "../../helpers/ObjectName";
import StatusService from "../../services/StatusService";
import Url from "../../lib/Url";
import Drawer from "../../components/Drawer";
import Cookie from "../../helpers/Cookie";
import { setCookie } from "../../lib/Helper";
import Number from "../../components/Number";
import { fetchList } from "../../actions/table";

const buttonLabel = true;

const Project = (props) => {
  const { history, activeTab, match } = props;
  const TAB_DETAIL = "Detail";

  const TAB_GENERAL = "general";
  const [isOpen, setIsOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeTabs, setActiveTab] = useState(TAB_DETAIL);
  const [projectData, setProjectData] = useState("");
  const [projectDeleteModal, setProjectDeleteModal] = useState(false);
  const [projectStatus, setProjectStatus] = useState();
  const [rowValue, setRowValue] = useState("");

  const selectedId = match && match.params && match.params.id;

  useEffect(() => {
    getStatus();
  }, []);

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  // Toggling the tabs and modals in respective tab
  const handleTabs = (tab) => {
    setActiveTab(tab);
  };

  const dispatch = useDispatch();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Create Creation
   *
   * @param data
   */
  const projectCreate = (data) => {
    data.name = data?.name;
    data.slug = data?.name;
    data.code = data?.name;
    data.status = projectStatus;
    dispatch(
      ProjectService.addProject(data, {
        sort: Url.GetParam("sort"),
        sortDir: Url.GetParam("sortDir"),
        search: Url.GetParam("search"),
        page: Url.GetParam("page"),
        pageSize: Url.GetParam("pageSize"),
      })
    );

    toggle();
  };

  const projectUpdate = async (data) => {
    data.name = data?.name;
    data.slug = data?.slug;
    data.code = data?.code;
    data.sort = data?.sort;
    dispatch(
      await ProjectService.updateProject(rowValue.id, data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "project",
              `${endpoints().projectAPI}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams(),
              }
            )
          );
          setIsOpen(false)
          toggle();
        }
      })
    );
  };

  const getStatus = async () => {
    const status = await StatusService.search(
      ObjectName.PROJECT,
      Projects.STATUS_ACTIVE
    );
    for (let i = 0; i < status.length; i++) {
      setProjectStatus(status[i]?.id);
    }
  };

  const addProjectForm = (
    <>
      <Text
        name="name"
        label=" Name"
        placeholder=" Name"
        error=""
        fontBolded
        required={true}
      />
      {rowValue.id && (
        <Text name="code" label="Code" placeholder="Code" fontBolded />
      )}
      {rowValue.id && (
        <Text name="slug" label="Slug" placeholder="slug" fontBolded />
      )}
      {rowValue.id && <Number label="Sort" name="sort" fontBolded />}
    </>
  );

  const projectFooter = (
    <div className="container-fluid">
      <div className="col-sm-12 text-center">
        <SaveButton label={rowValue?.id ? "Save" : "Add"} />
      </div>
    </div>
  );

  const handleStatusChange = (id, status) => {
    const data = new FormData();
    data.append("status", status ? status : "");
    dispatch(ProjectService.updateProjectStatus(id, data));
  };

  const projectDelete = (id) => {
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
    };
    dispatch(ProjectService.deleteProject(id, params));
  };

  const handleRedirect = (rowValue) => {
    setCookie(Cookie.PROJECT_ID, rowValue?.id);
    history.push(`/project/${rowValue?.id}?projectId=${rowValue?.id}`);
  };

  const initialValues = {
    name: rowValue?.name ? rowValue?.name : "",
    code: rowValue?.code ? rowValue?.code : "",
    slug: rowValue?.slug ? rowValue?.slug : "",
    sort: rowValue?.sort ? rowValue?.sort : "",
  };

  return (
    <>
      <>
        <DeleteModal
          isOpen={projectDeleteModal}
          toggle={() => {
            setProjectDeleteModal(false);
          }}
          title="Delete Location"
          id={projectData.id}
          label={projectData.name}
          deleteFunction={projectDelete}
        />

        <Drawer
          modelTitle={`${rowValue?.id ? "Edit Project" : "Add New Project"}`}
          DrawerBody={addProjectForm}
          DrawerFooter={projectFooter}
          onSubmit={(values) => {
            if (rowValue?.id) {
              projectUpdate(values);
            } else {
              projectCreate(values);
            }
          }}
          initialValues={initialValues}
          handleOpenModal={toggle}
          handleCloseModal={toggle}
          handleDrawerClose={toggle}
          isModalOpen={isOpen}
          buttonLabel={buttonLabel}
          enableReinitialize
        />
      </>

      <PageTitle
        label="Project"
        buttonHandler={(e) => {
          toggle();
        }}
        buttonLabel="Add New"
        className={"pt-3"}
      />
      <div className="mt-4">
        <ReduxTable
          id="project"
          showHeader
          searchPlaceholder="Search"
          apiURL={`${endpoints().projectAPI}/search`}
          newTableHeading
          history={props.history}
          paramsToUrl={true}
          sortByOptions={sortByOption}
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            field="name"
            sortBy="name"
            width="140px"
            minWidth="140px"
            maxWidth="140px"
            renderField={(row) => (
              <Link to="#" onClick={(e) => handleRedirect(row)}>
                {row.name}
              </Link>
            )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            field="status"
            sortBy="status"
            width="120px"
            minWidth="120px"
            maxWidth="120px"
            renderField={(row) => (
              <div
                className={`status-input text-center rounded text-white text-uppercase  my-3 mx-auto ${
                  row.status && row.status == Projects.STATUS_ACTIVE
                    ? "bg-success"
                    : row.status == Projects.STATUS_INACTIVE
                    ? "bg-dark bg-opacity-50"
                    : ""
                }`}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            width="70px"
            maxWidth="70px"
            minWidth="70px"
            field="Action"
            disableOnClick
            renderField={(row) => (
              <div className=" text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      toggle();
                      setRowValue(row);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className="text-danger"
                    onClick={() => {
                      setProjectDeleteModal(true);
                      setProjectData(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

export default Project;
