import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { isBadRequest } from "../lib/Http";


class TestCaseService {

    static async create (data,callback){
       await apiClient
        .post(`${endpoints().TestCaseAPI}/create`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback(response);
          }
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    }

    static async update  (id,data,callback)  {
        try {
            apiClient
             .put(`${endpoints().TestCaseAPI}/update/${id}`, data)
             .then((response) => {
               let successMessage;
               if (response && response.data) {
                 successMessage = response.data.message;
                 Toast.success(successMessage);
                 return callback && callback(successMessage)
               }
             })
             .catch((error) => {
               if (isBadRequest(error)) {
                 let errorMessage;
                 const errorRequest = error.response.request;
                 if (errorRequest && errorRequest.response) {
                   errorMessage = JSON.parse(errorRequest.response).message;
                 }
                 Toast.error(errorMessage);
               }
             });
         }
         catch(err){
             console.log(err);
         }
       };

       static delete(id,callback){
        apiClient
        .delete(`${endpoints().TestCaseAPI}/delete/${id}`)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback && callback(successMessage)
          }
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    }
  
}

export default TestCaseService;