import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
// import GatePassForm from "../gatePass/gatePassForm";
import SaveButton from "../../components/SaveButton";
import Url from "../../lib/Url";
import { fetchList } from "../../actions/table";
import { endpoints } from "../../api/endPoints";
import ReimbursementService from "../../services/ReimbursementService";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import AddButton from "../../components/AddButton";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import UserCard from "../../components/UserCard";
import MoreDropdown from "../../components/authentication/moreDropdown";
import DateSelector from "../../components/Date";
import Currency from "../../components/Currency";
import UserSelect from "../../components/UserSelect";
import TextArea from "../../components/TextArea";
import MediaCarousel from "../../components/MediaCarousel";
import ObjectName from "../../helpers/ObjectName";
import StatusSelect from "../../components/SelectStatus";
import App from "../../helpers/App";
import ArrayList from "../../lib/ArrayList";
import MediaUpload from "../../helpers/MediaUpload";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";
import DateTime from "../../lib/DateTime";
import Select from "../../components/Select";
import StatusService from "../../services/StatusService";
import StatusText from "../../components/StatusText";

const reimbursementListPage = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rowValue, setRowValue] = useState("");
  const [isSubmit, setIsSubmit] = useState(true);
  const [owner, setOwner] = useState(null);
  const [approver, setApprover] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [imageurl, setImageUrl] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState();
  const [amount, setAmount] = useState(null);
  const [description, setDescription] = useState(null);
  const [userList, setUserList] = useState([]);
  const [statusValue, setStatusValue] = useState(null);
  const [statusList, setStatusList] = useState([]);

  const dispatch = useDispatch();

  let hasDeletePermission = hasPermission(Permission.REIMBURSEMENT_DELETE);
  let AddPermission = hasPermission(Permission.REIMBURSEMENT_ADD);
  const hasEditPermission = hasPermission(Permission.REIMBURSEMENT_EDIT);

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most recent",
    },
  ];

  const customStatusOption = [
    {
      label: App.STATUS_ACTIVE_TEXT,
      value: App.STATUS_ACTIVE,
    },
    {
      label: App.STATUS_INACTIVE_TEXT,
      value: App.STATUS_INACTIVE,
    },
  ];

  const toggle = () => {
    setIsOpen(!isOpen);
    setIsSubmit(true);
    setRowValue("");
    setOwner("");
    setSelectedFiles("");
    setDate("");
    setAmount("");
    setDescription("");
    setStatusValue("");
    setApprover("");
  };

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    if (selectedFiles) {
      getUrl();
    }
  }, [isLoading, selectedFiles]);

  const getStatus = async () => {
    const status = await StatusService.getOption(ObjectName.REIMBURSEMENT);
    setStatusList(status);
  };

  const onDropImage = (images) => {
    handleImageValue({
      ...images,
      id: selectedFiles.length + 1,
    });
  };

  const handleImageValue = (images) => {
    setSelectedFiles((prevFileList) => [...prevFileList, { ...images }]);
  };

  const getUrl = () => {
    let url = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const imageUrl = URL.createObjectURL(file && file[0]);
      url.push({ url: imageUrl, image_id: file.id });
    }
    setImageUrl(url);
  };

  const handleImageRemove = (deletedvalue) => {
    setIsLoading(true);
    const updatedImageUrlArray = selectedFiles.filter(
      (item) => item.id !== deletedvalue.image_id
    );
    setSelectedFiles(updatedImageUrlArray);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const uploadMedia = async (id) => {
    if (ArrayList.isArray(selectedFiles)) {
      let mediaParams;
      for (let i = 0; i < selectedFiles.length; i++) {
        mediaParams = {
          selectedFile: selectedFiles[i][0] && selectedFiles[i][0],
          objectId: id,
          ObjectName: ObjectName.REIMBURSEMENT,
        };
        await MediaUpload.uploadFile(mediaParams, () => { });
      }
    }
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    setSelectedFile("");
  };

  const handleOwnerChange = (e) => {
    let value = e.id;
    setOwner(value);
  };

  const handleApproverChange = (e) => {
    let value = e.id;
    setApprover(value);
  };

  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleAmountChange = (e) => {
    let value = e.target.value;
    setAmount(value);
  };

  const handleDescriptionChange = (e) => {
    let value = e.target.value;
    setDescription(value);
  };

  const handleStatusChange = (e) => {
    let value = e?.values?.status;
    setStatusValue(value);
  };

  const reimbursementForm = (
    <>
      <DateSelector
        name="date"
        label="Date"
        placeholder="Date"
        minWidth="160px"
        required
        onChange={handleDateChange}
      />

      <Currency
        name="amount"
        label="Amount"
        placeholder="Amount"
        required
        onChange={handleAmountChange}
      />

      <UserSelect
        name="owner"
        label="Owner"
        selectedUserId={rowValue?.ownerId ? rowValue?.ownerId : null}
        showUserDetailsPageLink={rowValue?.ownerId ? true : false}
        userId={rowValue?.ownerId}
        handleUserChange={handleOwnerChange}
        userList={setUserList}
        required
      />

      <UserSelect
        name="approver"
        label="Approver"
        selectedUserId={rowValue?.approverId ? rowValue?.approverId : null}
        showUserDetailsPageLink={rowValue?.approverId ? true : false}
        userId={rowValue?.approverId}
        handleUserChange={handleApproverChange}
        userList={setUserList}
        required
      />

      <Select
        name="status"
        label="Status"
        placeholder="Select Status"
        onInputChange={handleStatusChange}
        options={statusList}
        required
      />

      <TextArea
        name="description"
        label="Description"
        placeholder="Enter escription..."
        onChange={handleDescriptionChange}
      />

      {!rowValue?.id && (
        <MediaCarousel
          height="300px"
          showCarasoul
          Attachments
          onDropImage={onDropImage}
          imageUrl={imageurl}
          handleImageRemove={handleImageRemove}
          isLoading={isLoading}
          editable={!hasEditPermission}
        />
      )}
      {rowValue?.id && (
        <MediaCarousel
          showCarasoul
          objectName={ObjectName.REIMBURSEMENT}
          objectId={rowValue?.id}
          history={history}
          attachmentsList={true}
          Attachments={"Attachments"}
          editable={!hasEditPermission}
        />
      )}
    </>
  );

  const initialValues = {
    date: date ? date : rowValue ? rowValue?.date : "",
    amount: amount ? amount : rowValue ? rowValue?.amount : "",
    owner: owner
      ? userList && userList.find((data) => data.id == owner)
      : (userList && userList.find((data) => data.id == rowValue?.ownerId)) ||
      "",
    approver: approver
      ? userList && userList.find((data) => data.id == approver)
      : (userList &&
        userList.find((data) => data.id == rowValue?.approverId)) ||
      "",
    description: description
      ? description
      : rowValue
        ? rowValue?.description
        : "",
    status: statusValue
      ? {
        label: statusValue?.label,
        value: statusValue?.value,
      }
      : rowValue
        ? {
          label: rowValue?.status,
          value: rowValue?.statusId,
        }
        : "",
  };

  const ReimbursementFooter = (
    <SaveButton
      type="submit"
      loading={isSubmit == false}
      label={rowValue?.id ? "Save" : "Add"}
    />
  );

  const params = {
    search: Url.GetParam("search"),
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    date: Url.GetParam("date"),
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmit(true);
      let data = new FormData();
      data.append("date", values?.date);
      data.append("amount", values?.amount);
      data.append("owner", values?.owner?.id ? values?.owner?.id : "");
      data.append("approver", values?.approver?.id ? values?.approver?.id : "");
      data.append(
        "description",
        values?.description ? values?.description : ""
      );
      data.append("status", values && values?.status?.value);

      dispatch(
        ReimbursementService.add(data, async (res) => {
          if (res) {
            dispatch(
              fetchList(
                "reimbursement",
                `${endpoints().ReimbursementApi}/search`,
                params.page ? params.page : 1,
                params.pageSize ? params.pageSize : 25,
                params
              )
            );
            await uploadMedia(res?.id);
            toggle();
            setIsSubmit(false);
            setSelectedFiles("");
          }
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  const toggleModelClose = () => {
    setRowValue("");
    setSelectedFile("");
    toggle();
    setIsSubmit(true);
  };

  const handleUpdate = async (values) => {
    const data = new FormData();
    data.append("date", values?.date);
    data.append("amount", values?.amount);
    data.append("owner", values?.owner?.id ? values?.owner?.id : "");
    data.append("approver", values?.approver?.id ? values?.approver?.id : "");
    data.append("description", values?.description ? values?.description : "");
    data.append("status", values && values?.status?.value);

    dispatch(
      await ReimbursementService.update(rowValue?.id, data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "reimbursement",
              `${endpoints().ReimbursementApi}/search`,
              params.page ? params.page : 1,
              params.pageSize ? params.pageSize : 25,
              params
            )
          );
          toggle();
          setIsSubmit(false);
          setSelectedFile("");
        }
      })
    );
  };

  const deleteToggle = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const handleDeleteFunction = async () => {
    try {
      await ReimbursementService.delete(rowValue?.id, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "reimbursement",
              `${endpoints().ReimbursementApi}/search`,
              params.page || 1,
              params.pageSize || 25,
              params
            )
          );
          setRowValue("");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteOpen}
        toggle={() => {
          deleteToggle();
        }}
        deleteFunction={handleDeleteFunction}
        title="Delete Reimbursement"
        label={`${rowValue?.id} - ${rowValue?.owner}`}
      />
      <Drawer
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize
        initialValues={initialValues}
        DrawerBody={reimbursementForm}
        DrawerFooter={hasEditPermission && ReimbursementFooter}
        modelTitle={rowValue?.id ? "Edit Reimbursement" : "Add Reimbursement"}
        onSubmit={(values) => {
          if (rowValue?.id) {
            handleUpdate(values);
          } else {
            handleSubmit(values);
          }
        }}
      />
      <div className="d-flex justify-content-between pb-3">
        <PageTitle label="Reimbursement" />
        {AddPermission && (
          <div className="d-flex">
            <AddButton
              label="Add New"
              onClick={() => {
                toggle();
              }}
            />
          </div>
        )}
      </div>
      <ReduxTable
        id="reimbursement"
        showHeader
        searchPlaceholder="Search"
        newTableHeading
        icon={<FontAwesomeIcon icon={faCubes} />}
        message="You can start by clicking on Add New"
        apiURL={`${endpoints().ReimbursementApi}/search`}
        sortByOptions={sortByOption}
        paramsToUrl={true}
        history={props.history}
        showCustomDateFilter
      >
        <ReduxColumn
          sortBy="id"
          field="id"
          type="link"
          minWidth="70px"
          className="text-center no-underline"
          renderField={(row) => (
            <Link
              to={`/reimbursement/${row.id}`}
              className="link-opacity-75"
            >
              {row?.id}
            </Link>
          )}
        >
          Reimbursement Id#
        </ReduxColumn>
        <ReduxColumn
          sortBy="date"
          field="date"
          minWidth="70px"
          className="text-center"
          renderField={(row) => <>{DateTime.getDateAndTime(row?.date)}</>}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          sortBy="amount"
          field="amount"
          minWidth="70px"
          className="text-center"
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="owner"
          className="ellipsis text-start"
          sortBy="owner"
          renderField={(row) => (
            <>
              <UserCard
                customSize={parseInt(50, 10)}
                firstName={row.ownerFirstName}
                lastName={row.ownerLastName}
                url={row.ownerMediaUrl}
              />
            </>
          )}
        >
          Owner
        </ReduxColumn>

        <ReduxColumn
          field="approver"
          className="ellipsis text-start"
          sortBy="approver"
          renderField={(row) => (
            <>
              <UserCard
                customSize={parseInt(50, 10)}
                firstName={row.approverFirstName}
                lastName={row.approverLastName}
                url={row.approverMediaUrl}
              />
            </>
          )}
        >
          Approver
        </ReduxColumn>

        <ReduxColumn field="description" minWidth="170px" sortBy="description">
          Description
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy="status"
          className="column-status"
          width="150px"
          renderField={(row) => (
            <StatusText backgroundColor={row.statusColor} status={row.status} />
          )}
        >
          Status
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      toggle();
                      setRowValue(row);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  {hasDeletePermission && (
                    <DropdownItem
                      className="text-danger"
                      onClick={() => {
                        setRowValue(row);
                        setIsDeleteOpen(true);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default reimbursementListPage;
