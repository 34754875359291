import { endpoints } from "../api/endPoints";
import apiClient from "../apiClient";
import ArrayList from "../lib/ArrayList";
import Url from "../lib/Url";

class DailySummaryReportService {
  static async getReport(params) {
    try {
      let queryString = await ArrayList.toQueryString(params);

      let response = await Url.get(
        `${endpoints().DailySummaryReportAPI}`,
        queryString
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  }
}

export default DailySummaryReportService;
