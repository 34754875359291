import React from "react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { endpoints } from "../../../api/endPoints";
import AvatarCard from "../../../components/AvatarCard";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import { Link } from "react-router-dom/cjs/react-router-dom";

const LeaveList = (props) => {
  const { params } = props;

  return (
    <>
      <ReduxTable
        id={"leaveList"}
        showHeader
        apiURL={`${endpoints().LocationAllocationUserAPI}/leaveList`}
        noPagination
        icon={<FontAwesomeIcon icon={faStar} />}
        message="You can start by clicking add."
        sortByDropdown
        params={params}
        history={props.history}
      >
        <ReduxColumn
          field="userName"
          width="110px"
          sortBy="userName"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => (
            <div className="d-flex text-break">
              <Link
                to={`/user/${row?.id}`}
                className="link-opacity-75 text-decoration-none"
                target="_blank"
              >
                <AvatarCard
                  id="avatar"
                  firstName={row.first_name}
                  lastName={row.last_name}
                  url={row.media_url}
                />
              </Link>
            </div>
          )}
        >
          Leaves
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default LeaveList;
