import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { isBadRequest } from "../lib/Http";




class AppSettingService{

    static save(data,callback){
        apiClient
        .put(`${endpoints().appSettingAPI}`, data)
        .then((response) => {
            let successMessage;
            if (response && response.data) {
                successMessage = response.data.message;
                Toast.success(successMessage);
            }
            return callback(response && response.data);
        })
        .catch((error) => {
            if (isBadRequest(error)) {
                let errorMessage;
                const errorRequest = error.response.request;
                if (errorRequest && errorRequest.response) {
                    errorMessage = JSON.parse(errorRequest.response).message;
                }
                Toast.error(errorMessage);
                console.error(errorMessage);
            }
        });
    }
    static get(data, callback) {

        apiClient
            .get(`${endpoints().appSettingAPI}`, data)
            .then((response) => {
                return callback(response && response.data);
            })
    }
}
export default AppSettingService;