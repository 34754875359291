import React, { useEffect, useState } from "react";
import ArrayList from "../lib/ArrayList";
import Select from "./Select";
import accountTypeService from "../services/AccountTypeService";

const AccountTypeSelect = (props) => {
  let {
    name,
    label,
    handleChange,
    required,
    accountTypeOption,
    placeholder,
    width,
    onChange,
    customOption,
    isDisabled,
    isMulti,
    defaultValue=null
  } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try{
      let params={
        defaultValue: defaultValue
      }
    const response = await accountTypeService.list(params);
    setOptions(response);
    accountTypeOption && accountTypeOption(response);
    }catch(err){
      console.log(err);
    }
  };

  return (
    <>
      <Select
        name={name ? name : "accountType"}
        label={label}
        options={customOption ? customOption : options}
        placeholder={placeholder ? placeholder : "Select Type"}
        handleChange={handleChange}
        required={required}
        width={width}
        onInputChange={onChange}
        isDisabled={isDisabled}
        isMulti={isMulti}
      />
    </>
  );
};

export default AccountTypeSelect;
