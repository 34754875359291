import React, { useState } from "react";
import { DropdownItem } from "reactstrap";
import { useDispatch } from "react-redux";

// Components
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import ProductCard from "../../product/components/productCard";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import PercentageComponent from "../../../components/Percentage";
import Drawer from "../../../components/Drawer";
import DeleteModal from "../../../components/DeleteModal";
import SaveButton from "../../../components/SaveButton";

// API
import { endpoints } from "../../../api/endPoints";

// Lib
import Url from "../../../lib/Url";
import Percentage from "../../../lib/Percentage";

// Services
import AccountProductService from "../../../services/AccountProductService";
import { hasPermission } from "../../../services/UserRolePermissionService";

// Helpers
import { Status } from "../../../helpers/Product";
import Permission from "../../../helpers/Permission";

const AccountProductList = (props) => {
  const { history, accountId, handleBulkUpdate, selectedCheckBox } = props;
  const [rowValue, setRowValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const dispatch = useDispatch();

  let hasDeletePermission = hasPermission(Permission.ACCOUNT_PRODUCT_DELETE);

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const closeToggle = () => {
    setRowValue("");
    setIsOpen(!isOpen);
    setIsSubmit(true);
  };

  let params = {
    accountId: accountId ? accountId : "",
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const _toggle = (id) => {
    setIsOpen(!isOpen);
    setIsSubmit(true);
  };

  const deleteAccountProduct = async () => {
    dispatch(await AccountProductService.delete(rowValue?.id, params));
    closeDeleteModal();
  };

  const editModal = (
    <div className="mt-2 mb-3">
      <div>
        <PercentageComponent
          name="margin_percentage"
          label="Regular Margin %"
          placeholder="Enter Margin %"
          error=""
          fontBolded
        />
      </div>
    </div>
  );

  const footer = (
    <>
      <SaveButton type="submit" loading={isSubmit == false} label="Save" />
    </>
  );

  const handleUpdate = async (values) => {
    const data = new FormData();

    data.append(
      "margin_percentage",
      values && values.margin_percentage ? values.margin_percentage : ""
    );

    let params = {
      accountId: accountId ? accountId : "",
      id: rowValue?.id,
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      search: Url.GetParam("search"),
      brand: Url.GetParam("brand"),
      category: Url.GetParam("category"),
    };

    dispatch(
      await AccountProductService.update({ ...params }, data, (res) => {
        if (res) {
          closeToggle();
        }
      })
    );
  };

  const initialValues = {
    margin_percentage: rowValue?.margin_percentage
      ? rowValue?.margin_percentage
      : "",
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        label={rowValue?.name}
        toggle={closeDeleteModal}
        title="Delete Account Product"
        deleteFunction={deleteAccountProduct}
      />
      <Drawer
        modelTitle="Edit Product"
        DrawerBody={editModal}
        DrawerFooter={footer}
        onSubmit={(values) => {
          handleUpdate(values);
        }}
        initialValues={initialValues}
        enableReinitialize
        handleOpenModal={_toggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={_toggle}
        isModalOpen={isOpen}
      />
      <ReduxTable
        id="vendorProduct"
        bulkSelect
        showHeader
        newTableHeading
        onBulkSelect={handleBulkUpdate}
        selectedCheckBox={selectedCheckBox}
        apiURL={`${endpoints().accountProductAPI}/search`}
        params={{
          accountId: accountId,
          sort: Url.GetParam("sort"),
          sortDir: Url.GetParam("sortDir"),
        }}
        searchPlaceholder="Search..."
        sortByOptions={sortByOption}
        paramsToUrl={true}
        history={history}
        showCategoryFilter
        showBrandFilter
        AccountProductBrandList
        AccountProductCategoryList
      >
        <ReduxColumn
          field="name"
          type="link"
          sortBy="name"
          isClickable="true"
          renderField={(row) => (
            <>
              <ProductCard
                productImageIcon
                square
                productName={row.name}
                packSize={row.pack_size}
                url={row.image}
                brandName={row.brand_name}
                salePrice={row.sale_price}
                size={row.size}
                unit={row.unit}
                mrp={row.mrp}
                id={row.product_id}
                brand_id={row.brand_id}
                status={
                  row.status == Status.INACTIVE_VALUE ? Status.INACTIVE : ""
                }
              />
            </>
          )}
        >
          Product
        </ReduxColumn>
        <ReduxColumn
          field="margin_percentage"
          sortBy="margin_percentage"
          renderField={(row) => (
            <span className="float-end">
              {Percentage.Get(row.margin_percentage)}
            </span>
          )}
        >
          Margin %
        </ReduxColumn>

        <ReduxColumn
          width="70px"
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="col-4 text-center landing-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    setRowValue(row);
                    _toggle();
                  }}
                >
                  Quick View
                </DropdownItem>
                {hasDeletePermission && (
                  <DropdownItem
                    className="text-danger cursor-pointer"
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setRowValue(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                )}
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default AccountProductList;
