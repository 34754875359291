import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { useDispatch } from "react-redux";

// Assets
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// API
import { endpoints } from "../../../api/endPoints";

// Components
import MoreDropdown from "../../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import UserCard from "../../../components/UserCard";
import StatusText from "../../../components/StatusText";

// Lib
import Currency from "../../../lib/Currency";
import Url from "../../../lib/Url";
import Cookies, { setCookie } from "../../../lib/Helper";

// Helpers
import * as Constants from "../../../helpers/SaleSettlement";
import { PAGE } from "../../../helpers/Status";
import ObjectName from "../../../helpers/ObjectName";
import Cookie from "../../../lib/Helper";

// Services
import SaleSettlementService from "../../../services/SaleSettlementService";
import StatusService from "../../../services/StatusService";

const SaleSettlementList = (props) => {
  const [page, setPage] = useState(Url.GetParam("page"));
  const [statusList, setStatusList] = useState([]);
  const [arrays, setArray] = useState([]);
  const [arrayList, setArrayList] = useState([]);

  const dispatch = useDispatch();

  const {
    id,
    history,
    sortByOption,
    status,
    setIsDeleteModel,
    setCurrentData,
    setSaleSettlementId,
    setSaleSettlementData,
    toggleSidebar,
    showSidebar,
    store,
    shift,
    setCurrentPage,
    startDate,
    salesExecutive,
    endDate,
    draftCurrentPage,
    draftCurrentPageSize,
    allCurrentPage,
    allCurrentPageSize,
    reviewCurrentPageSize,
    reviewCurrentPage,
    objectName,
    handleBulkUpdate,
  } = props;

  function getKeyByValue(object, value) {
    let isSelected = false;
    for (const key in object) {
      if (key == value) {
        isSelected = object[key] == true ? true : false;
      }
    }
    return isSelected;
  }

  useEffect(() => {
    getStatusList();
    const checkedList = Cookies.get(Cookie.SALE_SETTLEMENT);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);

  const FieldLabel = [
    {
      value: Constants.FieldLabel.CALCULATED_AMOUNT_CASH,
      label: Constants.FieldLabel.CALCULATED_AMOUNT_CASH,
    },
    {
      value: Constants.FieldLabel.CALCULATED_AMOUNT_UPI,
      label: Constants.FieldLabel.CALCULATED_AMOUNT_UPI,
    },
    {
      value: Constants.FieldLabel.RECEIVED_AMOUNT_CASH,
      label: Constants.FieldLabel.RECEIVED_AMOUNT_CASH,
    },
    {
      value: Constants.FieldLabel.RECEIVED_AMOUNT_UPI,
      label: Constants.FieldLabel.RECEIVED_AMOUNT_UPI,
    },
    {
      value: Constants.FieldLabel.ORDER_AMOUNT_CASH,
      label: Constants.FieldLabel.ORDER_AMOUNT_CASH,
    },
    {
      value: Constants.FieldLabel.ORDER_AMOUNT_UPI,
      label: Constants.FieldLabel.ORDER_AMOUNT_UPI,
    },
    {
      value: Constants.FieldLabel.DISCREPANCY_AMOUNT_CASH,
      label: Constants.FieldLabel.DISCREPANCY_AMOUNT_CASH,
    },
    {
      value: Constants.FieldLabel.DISCREPANCY_AMOUNT_UPI,
      label: Constants.FieldLabel.DISCREPANCY_AMOUNT_UPI,
    },
    {
      value: Constants.FieldLabel.AMOUNT_CASH,
      label: Constants.FieldLabel.AMOUNT_CASH,
    },
    {
      value: Constants.FieldLabel.AMOUNT_UPI,
      label: Constants.FieldLabel.AMOUNT_UPI,
    },
    {
      value: Constants.FieldLabel.TOTAL_ORDER_AMOUNT,
      label: Constants.FieldLabel.TOTAL_ORDER_AMOUNT,
    },
    {
      value: Constants.FieldLabel.TOTAL_CALCULATED_AMOUNT,
      label: Constants.FieldLabel.TOTAL_CALCULATED_AMOUNT,
    },
    {
      value: Constants.FieldLabel.TOTAL_RECEIVED_AMOUNT,
      label: Constants.FieldLabel.TOTAL_RECEIVED_AMOUNT,
    },
    {
      value: Constants.FieldLabel.CASH_IN_LOCATION,
      label: Constants.FieldLabel.CASH_IN_LOCATION,
    },
    {
      value: Constants.FieldLabel.CASH_TO_OFFICE,
      label: Constants.FieldLabel.CASH_TO_OFFICE,
    },
  ];

  const enable_calculated_amount_cash =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CALCULATED_AMOUNT_CASH)
      ? true
      : false;
  const enable_calculated_amount_upi =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CALCULATED_AMOUNT_UPI)
      ? true
      : false;
  const enable_amount_cash =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.AMOUNT_CASH)
      ? true
      : false;
  const enable_amount_upi =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.AMOUNT_UPI)
      ? true
      : false;
  const enable_discrepancy_amount_cash =
    arrays &&
      getKeyByValue(arrays, Constants.FieldLabel.DISCREPANCY_AMOUNT_CASH)
      ? true
      : false;
  const enable_discrepancy_amount_upi =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.DISCREPANCY_AMOUNT_UPI)
      ? true
      : false;

  const enable_received_amount_cash =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.RECEIVED_AMOUNT_CASH)
      ? true
      : false;

  const enable_received_amount_upi =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.RECEIVED_AMOUNT_UPI)
      ? true
      : false;
  const enable_order_amount_cash =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.ORDER_AMOUNT_CASH)
      ? true
      : false;
  const enable_order_amount_upi =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.ORDER_AMOUNT_UPI)
      ? true
      : false;
  const enable_total_order_amount =
    arrays &&
      getKeyByValue(arrays, Constants.FieldLabel.TOTAL_ORDER_AMOUNT)
      ? true
      : false;
  const enable_total_calculated_amount =
    arrays &&
      getKeyByValue(arrays, Constants.FieldLabel.TOTAL_CALCULATED_AMOUNT)
      ? true
      : false;
  const enable_total_received_amount =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.TOTAL_RECEIVED_AMOUNT)
      ? true
      : false;
  const enable_cash_in_store =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CASH_IN_LOCATION)
      ? true
      : false;

  const enable_cash_to_office =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CASH_TO_OFFICE)
      ? true
      : false;

  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.SALE_SETTLEMENT, arrayList);
    setArray(array);
    setArrayList(array);
  };

  const params = {
    search: Url.GetParam("search"),
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate"),
    location: Url.GetParam("location"),
    status: Url.GetParam("status"),
    salesExecutive: Url.GetParam("salesExecutive"),
    shift: Url.GetParam("shift"),
    date: Url.GetParam("date"),
    showTotalAmount: true,
  };

  const getStatusList = async (currentStatusId, allowed_statuses) => {
    if (currentStatusId) {
      const data = await StatusService.nextStatusSearch(
        ObjectName.SALE_SETTLEMENT,
        currentStatusId,
        "",
        allowed_statuses
      );

      if (data && data.length > 0) {
        setStatusList(data);
      }
    }
  };

  return (
    <div className="mt-4">
      <ReduxTable
        id={"SaleSettlement"}
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().saleSettlementAPI}/list`}
        setPage={setPage}
        params={{
          page: page ? page : PAGE,
          objectName: objectName ? objectName : "",
          showTotalAmount: true
        }}
        sortByOptions={sortByOption}
        paramsToUrl={true}
        history={history}
        FieldLabel={FieldLabel}
        handleColumnChange={handleColumnChange}
        arrayList={arrayList}
        showCustomDateFilter
        showStoreFilter
        showSalesExecutiveFilter
        showShiftFilter
        onBulkSelect={handleBulkUpdate}
        showBackgroundColor
        bulkSelect
        showStatusFilter
        DropdownWithCheckbox
        icon={<FontAwesomeIcon icon={faHandHoldingDollar} />}
        message="You can start by clicking on Add New">
        <ReduxColumn
          className="text-center"
          field="saleSettlementNumber"
          sortBy="saleSettlementNumber"
          isClickable="true"
          type="link"
          width="190px"
          minWidth="190px"
          maxWidth="190px"
          renderField={(row) => (
            <Link to={`/SalesSettlement/${row.id}`} className="link-opacity-75">{row.saleSettlementNumber}</Link>
          )}>
          SalesSettlement#
        </ReduxColumn>
        <ReduxColumn
          field="date"
          sortBy="date"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-center">
          Date
        </ReduxColumn>
        <ReduxColumn
          field="locationName"
          sortBy="store_id"
          width="110px"
          minWidth="110px"
          maxWidth="110px">
          Location
        </ReduxColumn>

        <ReduxColumn
          field="shift"
          sortBy="shift"
          width="110px"
          minWidth="110px"
          maxWidth="110px">
          Shift
        </ReduxColumn>
        <ReduxColumn
          className="text-break"
          field="salesExecutive"
          sortBy="salesExecutive"
          width="350px"
          maxWidth="250px"
          minWidth="250px"
          renderField={(row) => (
            <>
              <UserCard
                customSize={parseInt(50, 10)}
                firstName={row.salesExecutiveFirstName}
                url={row.avatarUrl}
                lastName={row.salesExecutiveLastName}
              />
            </>
          )}
        >
          Sales Executive
        </ReduxColumn>
        {enable_amount_cash && enable_amount_cash == true && (
          <ReduxColumn
            field="amount_cash"
            sortBy="amount_cash"
            className="text-right"
            renderField={(row) => (
              <span>{Currency.Format(row.amount_cash)}</span>
            )}
            width="110px"
            minWidth="110px"
            maxWidth="110px">
            Amount (Cash)
          </ReduxColumn>
        )}
        {enable_amount_upi && enable_amount_upi == true && (
          <ReduxColumn
            field="amount_upi"
            sortBy="amount_upi"
            className="text-right"
            renderField={(row) => (
              <span>{Currency.Format(row.amount_upi)}</span>
            )}
            width="110px"
            minWidth="110px"
            maxWidth="110px">
            Amount (UPI)
          </ReduxColumn>
        )}
        <ReduxColumn
          field="total_amount"
          sortBy="total_amount"
          className="text-right"
          renderField={(row) => (
            <span>{Currency.roundOff(row.total_amount)}</span>
          )}
          width="110px"
          minWidth="110px"
          maxWidth="110px">
          Amount (TOTAL)
        </ReduxColumn>

        {enable_calculated_amount_cash &&
          enable_calculated_amount_cash == true && (
            <ReduxColumn
              field="calculated_amount_cash"
              sortBy="calculated_amount_cash"
              width="130px"
              minWidth="130px"
              maxWidth="130px"
              className="text-right"
              renderField={(row) => (
                <span>
                  {Currency.Format(row.calculated_amount_cash)}
                </span>
              )}>
              Calculated Amount(Cash)
            </ReduxColumn>
          )}

        {enable_calculated_amount_upi &&
          enable_calculated_amount_upi == true && (
            <ReduxColumn
              field="calculated_amount_upi"
              sortBy="calculated_amount_upi"
              width="130px"
              minWidth="130px"
              maxWidth="130px"
              className="text-right"
              renderField={(row) => (
                <span>
                  {Currency.Format(row.calculated_amount_upi)}
                </span>
              )}>
              Calculated Amount(Upi)
            </ReduxColumn>
          )}

        {enable_total_calculated_amount &&
          enable_total_calculated_amount == true && (
            <ReduxColumn
              field="total_calculated_amount"
              sortBy="total_calculated_amount"
              width="130px"
              minWidth="130px"
              maxWidth="130px"
              className="text-right"
              renderField={(row) => (
                <span>
                  {Currency.Format(row.total_calculated_amount)}
                </span>
              )}>
              Calculated Amount(Total)
            </ReduxColumn>
          )}
        {enable_order_amount_cash &&
          enable_order_amount_cash == true && (
            <ReduxColumn
              field="order_cash_amount"
              sortBy="order_cash_amount"
              width="130px"
              minWidth="130px"
              maxWidth="130px"
              className="text-right"
              renderField={(row) => (
                <span>{Currency.Format(row.order_cash_amount)}</span>
              )}>
              Order Amount(Cash)
            </ReduxColumn>
          )}
        {enable_order_amount_upi && enable_order_amount_upi == true && (
          <ReduxColumn
            field="order_upi_amount"
            sortBy="order_upi_amount"
            width="130px"
            minWidth="130px"
            maxWidth="130px"
            className="text-right"
            renderField={(row) => (
              <span>{Currency.Format(row.order_upi_amount)}</span>
            )}>
            Order Amount(Upi)
          </ReduxColumn>
        )}
        {enable_received_amount_cash &&
          enable_received_amount_cash == true && (
            <ReduxColumn
              field="received_amount_cash"
              sortBy="received_amount_cash"
              width="130px"
              minWidth="130px"
              maxWidth="130px"
              className="text-right"
              renderField={(row) => (
                <span>{Currency.Format(row.received_amount_cash)}</span>
              )}>
              Received Amount(Cash)
            </ReduxColumn>
          )}

        {enable_received_amount_upi && enable_received_amount_upi == true && (
          <ReduxColumn
            field="received_amount_upi"
            sortBy="received_amount_upi"
            width="130px"
            minWidth="130px"
            maxWidth="130px"
            className="text-right"
            renderField={(row) => (
              <span>{Currency.Format(row.received_amount_upi)}</span>
            )}>
            Received Amount(Upi)
          </ReduxColumn>
        )}

        {enable_total_order_amount &&
          enable_total_order_amount == true && (
            <ReduxColumn
              field="order_total_amount"
              sortBy="order_total_amount"
              width="130px"
              minWidth="130px"
              maxWidth="130px"
              className="text-right"
              renderField={(row) => (
                <span>
                  {Currency.Format(row.order_total_amount)}
                </span>
              )}>
              Order Amount(Total)
            </ReduxColumn>
          )}

        {enable_total_received_amount &&
          enable_total_received_amount == true && (
            <ReduxColumn
              field="total_received_amount"
              sortBy="total_received_amount"
              width="130px"
              minWidth="130px"
              maxWidth="130px"
              className="text-right"
              renderField={(row) => (
                <span>
                  {Currency.Format(row.total_received_amount)}
                </span>
              )}>
              Received Amount(Total)
            </ReduxColumn>
          )}

        {enable_cash_in_store && enable_cash_in_store == true && (
          <ReduxColumn
            field="cash_in_store"
            sortBy="cash_in_store"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            className="text-right"
            renderField={(row) => (
              <span>{Currency.Format(row.cash_in_store)}</span>
            )}>
            Cash In Location
          </ReduxColumn>
        )}

        {enable_cash_to_office && enable_cash_to_office == true && <ReduxColumn
          field="cash_to_office"
          sortBy="cash_to_office"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-right"
          renderField={(row) => (
            <span>{Currency.Format(row.cash_to_office)}</span>
          )}>
          Cash To Office
        </ReduxColumn>}

        {enable_discrepancy_amount_cash &&
          enable_discrepancy_amount_cash == true && (
            <ReduxColumn
              field="discrepancy_amount_cash"
              sortBy="discrepancy_amount_cash"
              className="text-right"
              width="130px"
              minWidth="130px"
              maxWidth="130px"
              renderField={(row) => (
                <span>
                  {Currency.Format(row.discrepancy_amount_cash)}
                </span>
              )}>
              Discrepancy Amount(Cash)
            </ReduxColumn>
          )}

        {enable_discrepancy_amount_upi &&
          enable_discrepancy_amount_upi == true && (
            <ReduxColumn
              field="discrepancy_amount_upi"
              sortBy="discrepancy_amount_upi"
              width="130px"
              minWidth="130px"
              maxWidth="130px"
              className="text-right"
              renderField={(row) => (
                <span>
                  {Currency.Format(row.discrepancy_amount_upi)}
                </span>
              )}>
              Discrepancy Amount(Upi)
            </ReduxColumn>
          )}

        <ReduxColumn
          field="total_discrepancy_amount"
          sortBy="total_discrepancy_amount"
          width="130px"
          minWidth="130px"
          maxWidth="130px"
          className="text-right"
          renderField={(row) => (
            <span>{Currency.Format(row.total_discrepancy_amount)}</span>
          )}>
          Discrepancy Amount(Total)
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy="status"
          width="130px"
          minWidth="130px"
          maxWidth="130px"
          renderField={(row) => (
            <StatusText backgroundColor={row.statusColor} status={row.status} />
          )}>
          Status
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="120px"
          minWidth="80px"
          maxWidth="80px"
          renderField={(row) => (
            <div className=" text-center action-group-dropdown">
              <MoreDropdown
                onClick={() => {
                  setStatusList([]);
                  getStatusList(row.statusId, row.allowed_statuses);
                }}
              >
                <DropdownItem
                  onClick={() => {
                    props.setRowValue(row);
                    props.toggle();
                  }}
                >
                  Quick View
                </DropdownItem>
                {statusList &&
                  statusList.length > 0 &&
                  statusList.map((data) => {
                    return (
                      <DropdownItem
                        onClick={() => {
                          dispatch(
                            SaleSettlementService.updateStatus(
                              row.id,
                              data.value,
                              params
                            )
                          );
                        }}
                      >
                        {data.label}
                      </DropdownItem>
                    );
                  })}
                <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    setCurrentPage(page);
                    setCurrentData(row);
                    setSaleSettlementId(row.id);
                    setSaleSettlementData(row.saleSettlementNumber);
                    setIsDeleteModel(true);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

export default SaleSettlementList;
