import React from "react";

// Components
import Text from "../../../components/Text";
import Select from "../../../components/Select";
import DraftEditor from "../../../components/Draft";
import Number from "../../../components/Number";
import SingleCheckbox from "../../../components/SingleCheckbox";
import JobCategorySelect from "../../../components/JobCategorySelect";

const JobForm = (props) => {
  let {
    StatusOptions,
    jobTypeOptions,
    editorDescriptionState,
    handleEditorDescriptionChange,
    editorResponsibilityState,
    handleEditorResponsibilityChange,
    handleEditorBenefitsChange,
    editorBenefitsState,
    editorState,
    handleEditorChange,
    handleTitleChange,
    slug,
    editable
  } = props;

  const checkboxFields = [
    { name: "show_current_salary", label: "Show Current Salary" },
    { name: "show_expected_salary", label: "Show Expected Salary" },
    { name: "show_skills", label: "Show Skills" },
    { name: "show_employment_eligibility", label: "Select Employment Eligibility" },
    { name: "show_current_address", label: "Select Current Address" },
    { name: "show_home_town_address", label: "Show Home Town Address" },
    { name: "show_employment", label: "Show Employment" },
  ];

  const postResumeFields = [
    { name: "age", label: "Age" },
    { name: "current_address", label: "Current Address" },
    { name: "email_address", label: "Email Address" },
    { name: "expected_salary", label: "Expected Salary" },
    { name: "first_name", label: "First Name" },
    { name: "gender", label: "Gender" },
    { name: "highest_education", label: "Highest Education" },
    { name: "last_name", label: "Last Name" },
    { name: "marital_status", label: "Marital Status" },
    { name: "mobile_number", label: "Mobile Number" },
    { name: "message", label: "Message" },
    { name: "permanent_address", label: "Permanent Address" },
    { name: "position", label: "Position" },
    { name: "profile_picture", label: "Profile Picture" },
    { name: "resume", label: "Resume" },
    { name: "staying_in", label: "Staying In" },
    { name: "current_address_area", label: "Current Address - Area" },
    { name: "current_address_city", label: "Current Address - City" },
    { name: "current_address_state", label: "Current Address - State" },
    { name: "current_address_pincode", label: "Current Address - Pincode" },
    { name: "permanent_address_area", label: "Permanent Address - Area" },
    { name: "permanent_address_city", label: "Permanent Address - City" },
    { name: "permanent_address_state", label: "Permanent Address - State" },
    { name: "permanent_address_pincode", label: "Permanent Address - Pincode" },
    { name: "educational_qualifiction_course", label: "Educational Qualification - Course" },
    { name: "educational_qualifiction_department", label: "Educational Qualification - Department" },
    { name: "educational_qualifiction_year", label: "Educational Qualification - Year" },
    { name: "languages_known", label: "Language Known" },
  ];


  const CheckboxGroup = ({ fields, sectionTitle, editable }) => (
    <>
      {sectionTitle && <h5 className="my-3">{sectionTitle}</h5>}
      {fields.map((field, index) => (
        <div key={index} className="form-wrapper mb-2">
          <div className="field-wrapper">
            <SingleCheckbox
              name={field.name}
              label={field.label}
              enabled={editable}
              className="accepted-terms mb-2 pb-0 me-3"
            />
          </div>
        </div>
      ))}
    </>
  );
  return (
    <div>
      <Text
        name="job_title"
        label="Title"
        placeholder="Job Title..."
        onChange={handleTitleChange}
        error=""
        value={slug}
        disabled={editable}
        required={true}
      />
      <Select
        name="status"
        label="Status"
        fontBolded
        options={StatusOptions}
        isDisabled={editable}
      />
      <Text
        name="slug"
        label="Slug"
        fontBolded
        placeholder="slug"
        error=""
        disabled={editable}
        required
      />
      <JobCategorySelect
        name="category"
        label="Category"
        fontBolded
        isDisabled={editable}
      />
      <Select
        name="job_type"
        label="Type"
        fontBolded
        options={jobTypeOptions}
        isDisabled={editable}
      />
      <Text
        name="location"
        label="Location"
        fontBolded
        placeholder="location"
        error=""
        disabled={editable}
      />
      <Text
        name="experience"
        label="Experience"
        fontBolded
        placeholder="Experience"
        disabled={editable}
        error=""
      />
      <Select
        name="sort"
        label="Sort"
        fontBolded
        isDisabled={editable}
      />
      <div className="mt-3 mb-2">
        <label className="fw-bold">Description</label>
        <DraftEditor
          name="job_description"
          placeholder="Enter Description"
          editorState={editorDescriptionState}
          onChange={handleEditorDescriptionChange}
          disabled={editable}
        />
      </div>
      <div className="mt-3 mb-2">
        <label className="fw-bold">Responsibilities</label>
        <DraftEditor
          name="responsibilities"
          placeholder="Enter Responsibilities"
          editorState={editorResponsibilityState}
          onChange={handleEditorResponsibilityChange}
          disabled={editable}
        />
      </div>
      <div className="mt-3 mb-2">
        <label className="fw-bold">Requirements</label>
        <DraftEditor
          name="requirements"
          placeholder="Enter Requirements"
          editorState={editorState}
          onChange={handleEditorChange}
          disabled={editable}
        />
      </div>
      <div className="mt-3 mb-2">
        <label className="fw-bold">Benefits</label>
        <DraftEditor
          name="benefits"
          placeholder="Enter Benefits"
          editorState={editorBenefitsState}
          onChange={handleEditorBenefitsChange}
          disabled={editable}
        />
      </div>
      <Text
        name="mandatory_skills"
        label="Mandatory Skill"
        fontBolded
        disabled={editable}
      />
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
          <Number
            name="minimum_experience"
            label="Minimum Experience"
            fontBolded
            placeholder="Minimum Experience"
            error=""
            disabled={editable}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
          <Number
            label="Maximum Experience"
            placeholder="Maximum Experience"
            fontBolded
            name="maximum_experience"
            error={""}
            disabled={editable}
          />
        </div>
      </div>
      <Number
        label="Maximum Salary"
        placeholder="Maximum Salary"
        fontBolded
        name="maximum_salary"
        error={""}
        disabled={editable}
      />
      <div className="mt-4">
        <CheckboxGroup
          fields={checkboxFields}
          editable={editable}
        />
        <CheckboxGroup
          fields={postResumeFields}
          sectionTitle="Post Resume Fields"
          editable={editable}
        />
      </div>
    </div>
  );
};

export default JobForm;
