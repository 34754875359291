import { useFormikContext } from "formik";
import React from "react";
import { CUSTOM_VALUE, DateOptions } from "../helpers/Date";
import ArrayList from "../lib/ArrayList";
import Number from "../lib/Number";
import DateSelector from "./Date";
import Select from "./Select";

const DateSelect = (props) => {
  let { name, label, placeholder, required, menuPortal, customDateOption = [] } = props;
  const { values } = useFormikContext();

  return (
    <div>
      <Select
        name={name ? name : "date"}
        label={label}
        placeholder={placeholder ? placeholder : "Select Date"}
        options={
          ArrayList.isArray(customDateOption) ? customDateOption : DateOptions
        }
        required={required}
        menuPortal={menuPortal}
      />
      {Number.isNotNull(values["date"]) &&
        (ArrayList.isArray(customDateOption)
          ? customDateOption
            .map((data) => data?.value)
            .includes(values["date"]?.value) ||
          customDateOption
            .map((data) => data?.value)
            .includes(values["date"][0]?.value)
          : values["date"]?.value == CUSTOM_VALUE ||
          values["date"][0]?.value == CUSTOM_VALUE) && (
          <div className="row">
            <div className="col">
              <DateSelector
                name="startDate"
                placeholder="Start Date"
                onChange={props.handleStartDateChange}
                isClearable
              />
            </div>
            <div className="col">
              <DateSelector
                name="endDate"
                placeholder="End Date"
                onChange={props.handleEndDateChange}
                isClearable
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default DateSelect;
