import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import { useDispatch } from "react-redux";
import Action from "../../components/Action";
import DeleteModal from "../../components/DeleteModal";
import LoyaltyCategoryService from "../../services/loyaltyCategoryService";
import GeneralTab from "./component/GeneralTab";

// Tabs Constants
export const Tab = { GENERAL: "General" };

// Country detail page
const CategoryDetail = (props) => {
  // useState
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();

  // useEffect
  useEffect(() => {
    getCategoryDetail();
  }, []);

  // Breadcrumb
  const breadcrumbList = [
    { label: "Settings", link: "/Setting/Loyalty" },
    {
      label: "Loyalty",
      link: "/Setting/Loyalty",
    },
    {
      label: "Loyalty Detail",
      link: "",
    },
  ];

  const handledelete = (id) => {
    dispatch(
      LoyaltyCategoryService.delete(id, (res) => {
        if (res) {
          props.history.push("/Setting/Loyalty")
        }
      })
    );
  };

  // Getting country details
  const getCategoryDetail = async () => {
    {
      let id = props.match.params.id;
      const response = await LoyaltyCategoryService.get(id);
      setCategoryDetail(response);
    }
  };


  const handleActionChange = (e) => {
    if (e == "Delete") {
      setDeleteModal(true)
    }
  };

  return (
    <>
      {/* Breadcrumb */}
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between mb-2">
        <div>
          <PageTitle
            label="Category Detail"
          />
        </div>
        <div className="d-flex">
          <div className="ps-2">
            <Action
              dropdownLinks={[
                {
                  label: "Delete",
                  value: "Delete",
                }
              ]}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>

      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Category"
        id={categoryDetail?.id}
        label={categoryDetail?.name}
        deleteFunction={handledelete}
      />

      <GeneralTab history={props.history} categoryDetail={categoryDetail} />

    </>
  );
};

export default CategoryDetail;
