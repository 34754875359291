import { io } from "socket.io-client";
import { API_URL } from "../configs";

let socket = null;

export const connectSocket = (userId) => {
  if (!userId) return;

  if (socket) {
    socket.disconnect(); // Disconnect existing socket before creating a new one
  }
  if ((!socket|| !socket.connected) && userId) {
    socket = io(API_URL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 2000
    });

    socket.emit("join", userId);
  }

  return socket; // Return the existing socket instance
};

export const getSocketInstance = () => {
  return socket; // Returns existing socket without requiring userId
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};
