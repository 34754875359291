import React, { useRef, useState } from "react";
import * as XLSX from "xlsx";
import { gstB2bColumns } from "../../helpers/CsvSheet";
import PageTitle from "../../components/PageTitle";
import { useDispatch } from "react-redux";
import { fetchList } from "../../actions/table";
import GstB2bService from "../../services/GstB2bService";
import { endpoints } from "../../api/endPoints";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import DateTime from "../../lib/DateTime";
import Currency from "../../lib/Currency";
import Url from "../../lib/Url";

const Gst = (props) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = "B2B";
      const worksheet = workbook.Sheets[sheetName];

      if (worksheet) {
        const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Get the third and fourth rows as potential headers
        const thirdRow = rawData[4];
        const fourthRow = rawData[5];

        // Merge headers: use fourth row if available, otherwise use third row
        const maxColumns = Math.max(thirdRow.length, fourthRow.length);
        const headers = Array.from(
          { length: maxColumns },
          (_, index) => fourthRow[index] || thirdRow[index]
        );

        const startRow = 5; // Data starts from the 6th row (index 5)
        const structuredData = rawData
          .slice(startRow)
          .map((row) => {
            const rowData = {};
            headers.forEach((header, index) => {
              rowData[header] = row[index] || null; // Use null for missing values
            });
            return rowData;
          })
          .filter((row) => row[headers[0]]);
        const mappedData = structuredData.map((item) => {
          const newItem = {};
          gstB2bColumns.forEach((column) => {
            newItem[column.value] = item[column.label];
          });
          return newItem;
        });

        if (mappedData && mappedData.length > 0) {
          dispatch(
            await GstB2bService.create(mappedData, (res) => {
              if (res) {
                dispatch(
                  fetchList("gstB2b", `${endpoints().gstB2bAPI}`, 1, 25)
                );
              }
            })
          );
        }
      } else {
        console.error("Sheet B2B not found");
      }
    };

    reader.readAsBinaryString(file);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger file input click
    }
  };
  const sortByOption = [
    {
      value: "id:ASC",
      label: "Most Recent",
    },
  ];

  return (
    <div>
      <PageTitle
        label="GST"
        buttonHandler={() => {
          handleUploadClick();
        }}
        buttonLabel="Import"
        className="mb-3"
      />
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }} // Hide the input
      />
      <div className="mt-4">
        <ReduxTable
          id="gstB2b"
          searchPlaceholder="Search"
          apiURL={`${endpoints().gstB2bAPI}`}
          newTableHeading
          paramsToUrl={true}
          history={props.history}
          params={Url.GetAllParams()}
          sortByOptions={sortByOption}
        >
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="gst_number"
            sortBy="gst_number"
            className="text-center"
          >
            Gst Number
          </ReduxColumn>
          <ReduxColumn
            width="400px"
            minWidth="400px"
            maxWidth="400px"
            field="supplier_name"
            sortBy="supplier_name"
            className="text-center"
          >
            Supplier Name
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="invoice_number"
            className="text-center"
            sortBy="invoice_number"
          >
            Invoice Number
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="invoice_type"
            className="text-center"
            sortBy="invoice_type"
          >
            Invoice Type
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="invoice_date"
            className="text-center"
            sortBy="invoice_date"
            renderField={(row) => (
              <span>{DateTime.getDate(row.invoice_date)}</span>
            )}
          >
            Invoice date
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="invoice_value"
            className="text-end"
            sortBy="invoice_value"
            renderField={(row) => (
              <span>{Currency.Format(row.invoice_value)}</span>
            )}
          >
            Invoice Value
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="place_of_supply"
            className="text-center"
            sortBy="place_of_supply"
          >
            Place Of Supply
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="supply_attract_reverse_charge"
            className="text-center"
            sortBy="supply_attract_reverse_charge"
          >
            Supply Attract Reverse Charge
          </ReduxColumn>
          <ReduxColumn
            width="100px"
            minWidth="100px"
            maxWidth="100px"
            field="rate"
            className="text-end"
            sortBy="rate"
          >
            Rate
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="taxable_value"
            className="text-end"
            sortBy="taxable_value"
            renderField={(row) => (
              <span>{Currency.Format(row.taxable_value)}</span>
            )}
          >
            Taxable Value
          </ReduxColumn>

          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="integrated_tax"
            className="text-end"
            sortBy="integrated_tax"
            renderField={(row) => (
              <span>{Currency.Format(row.integrated_tax)}</span>
            )}
          >
            Integrated Tax
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="central_tax"
            className="text-end"
            sortBy="central_tax"
            renderField={(row) => (
              <span>{Currency.Format(row.central_tax)}</span>
            )}
          >
            Central Tax
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="state_ut_tax_amount"
            className="text-end"
            sortBy="state_ut_tax_amount"
            renderField={(row) => (
              <span>{Currency.Format(row.state_ut_tax_amount)}</span>
            )}
          >
            State Ut Tax Amount
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="cess"
            className="text-end"
            sortBy="cess"
            renderField={(row) => <span>{Currency.Format(row.cess)}</span>}
          >
            Cess
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="gstr1_filing_period"
            className="text-center"
            sortBy="gstr1_filing_period"
          >
            Gstr1 Filing Period
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="gstr1_filing_date"
            className="text-center"
            sortBy="gstr1_filing_date"
            renderField={(row) => (
              <span>{DateTime.getDate(row.gstr1_filing_date)}</span>
            )}
          >
            Gstr1 Filing Date
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="itc_availability"
            className="text-center"
            sortBy="itc_availability"
          >
            Itc Availability
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="reason"
            className="text-center"
            sortBy="reason"
          >
            Reason
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="applicable_tax_rate_percentage"
            className="text-center"
            sortBy="applicable_tax_rate_percentage"
          >
            Applicable Tax Rate Percentage
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="source"
            className="text-center"
            sortBy="source"
          >
            Source
          </ReduxColumn>

          <ReduxColumn field="irn" className="text-center" sortBy="irn">
            Irn
          </ReduxColumn>
          <ReduxColumn
            width="170px"
            minWidth="170px"
            maxWidth="170px"
            field="irn_date"
            className="text-center"
            sortBy="irn_date"
            renderField={(row) => <span>{DateTime.getDate(row.irn_date)}</span>}
          >
            Irn Date
          </ReduxColumn>
        </ReduxTable>
      </div>
    </div>
  );
};

export default Gst;
