import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";


class LeadTypeService {
    static async list() {
        try {
            const response = await apiClient.get(`${endpoints().LeadTypeApi}/list`);
            if(response){
                return response && response?.data?.data;
            }

        } catch (err) {
            console.log(err);
        }
    }
}
export default LeadTypeService
