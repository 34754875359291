import React, { useState } from "react";
import Filter from "../../components/Filter";
import { CUSTOM_VALUE, DateOptions } from "../../helpers/Date";
import DateTime from "../../lib/DateTime";
import Url from "../../lib/Url";

const GstReportFilter = (props) => {
  const { params, getDetails , setParams} = props;
  const [spinValue, setSpin] = useState(false);

  const handleChange = async (updatedParams) => {
    const productparam = {
        date: updatedParams.date
        ? updatedParams.date
        : "",
    };

    if(updatedParams?.date == CUSTOM_VALUE){
      productparam.startDate= updatedParams.startDate
        ? updatedParams.startDate
        : ""
        productparam.endDate= updatedParams.endDate
        ? updatedParams.endDate
        : ""
    }
    setParams(productparam)
    Url.UpdateUrl(productparam, props);
    await getDetails(productparam);
  };

  const initialValues = {
    startDate: DateTime.getDate(Url.GetParam("startDate")),
    endDate: DateTime.getDate(Url.GetParam("endDate")),
    date: DateOptions.find((value) => value?.value == Url.GetParam("date")),
  };

  const refreshButtonOnClick = async () => {
    setSpin(true);
    await getDetails(params);
    setSpin(false);
  };

  const handleDeleteFilter = (values) => {
    handleChange({...params,...values});
  };

  const handleFilter = (values) => {
    let data = {
         date: values?.date ? values?.date?.value : ""
    };

    if(values?.date?.value == CUSTOM_VALUE || Url.GetParam("date") == CUSTOM_VALUE){
      data.endDate= values?.endDate ? DateTime.toISOStringDate(values?.endDate) : ""
      data.startDate= values?.startDate
        ? DateTime.toISOStringDate(values?.startDate)
        : ""
    }

    Url.UpdateUrl(
      data,
      props
    );
    handleChange(data);
  };

  return (
    <Filter
      newTableHeading
      showHeader
      showSearch
      showPageSize={false}
      showCustomDateFilter
      sortByDropdown
      initialValues={initialValues}
      refreshButtonOnClick={refreshButtonOnClick}
      handleDeleteFilter={handleDeleteFilter}
      refreshValue={spinValue}
      handleFilter={handleFilter}
    />
  );
};
export default GstReportFilter;
