import React, { useEffect, useState } from "react";
import ArrayList from "../lib/ArrayList";
import Select from "./Select";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";
import MessagesService from "../services/MessagesService";

const MessageUserSelect = (props) => {
  let {
    name,
    handleUserChange,
    label,
    required,
    userList,
    params,
    customUserOption,
    placeholder,
    isDisabled,
    isMulti,
    showUserDetailsPageLink,
    userId: defaultValue = null,
  } = props;

  const [userOption, setUserOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    {
      setIsLoading(true);
      getUsers({ ...params});
      setIsLoading(false);
    }
  }, []);

  const getUsers = async (params) => {
    setIsLoading(true);
    const users = await MessagesService.list(
      params,
    );
    setUserOption(users);
    userList && userList(users);
    setIsLoading(false);
  };

  const handleUserChanges = (e) => {
    setSelectedUser(e);
    handleUserChange && handleUserChange(e);
  };



  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <>
        <div className="d-flex w-100">
          <Select
            name={name ? name : "user"}
            placeholder={placeholder ? placeholder : "Select User"}
            options={customUserOption ? customUserOption : userOption}
            handleChange={handleUserChanges}
            required={required}
            label={label}
            width={props?.width}
            isClearable={true}
            isLoading={isLoading}
            defaultValue={selectedUser}
            isDisabled={isDisabled}
            isMulti={isMulti}
          />
          {showUserDetailsPageLink && (
            <div className="flex-shrink-1 bd-highlight mt-4 pt-1">
              <Link target="_blank" to={`/user/${defaultValue}`}>
                <FontAwesomeIcon
                  className="text-body-secondary p-1"
                  icon={faSquareArrowUpRight}
                  style={{
                    fontSize: "40px",
                  }}
                />
              </Link>
            </div>
          )}
        </div>
      </>
    </>
  );
};
export default MessageUserSelect;
