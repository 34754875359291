import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import ArrayList from "../lib/ArrayList";
import { SUCCESS_RESPONSE, isBadRequest } from "../lib/Http";
import Url from "../lib/Url";






class PageBlockFieldsService {

    static create(data,callback) {
        apiClient.post(`${endpoints().PageBlockFieldsAPI}/create`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback(response);
          }
        })
        .catch((error) => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(error.response.data.message);
              console.error(errorMessage);
            }
          });
    }
   
      static update = async (id,data,callback) => {
        apiClient.put(`${endpoints().PageBlockFieldsAPI}/update/${id}`, data)
          .then((res) => {
            if (res.status == SUCCESS_RESPONSE) {
              Toast.success(res?.data?.message);
              return callback && callback(res?.data?.message) 
            }
          })
          .catch((err) => {
            if (isBadRequest(err)) {
              let errorMessage;
              const errorRequest = err.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
            }
          });
      }

      static delete = async (id,callback) => {
        apiClient.delete(`${endpoints().PageBlockFieldsAPI}/delete/${id}`)
          .then(async (res) => {
            if (res.status == SUCCESS_RESPONSE) {
              Toast.success(res?.data?.message);
              return callback && callback(res?.data?.message)
            }
           
          })
          .catch((err) => {
            if (isBadRequest(err)) {
              let errorMessage;
              const errorRequest = err.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
            }
          });
      };

}

export default PageBlockFieldsService;