import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from '../../components/Form';
import SaveButton from '../../components/SaveButton';
import Select from '../../components/Select';
import TagTypeService from '../../services/TagTypeService';
import { getCompanySettings, saveSetting } from "../../services/SettingService";
import { getKeyValueByObject } from "../../lib/Helper";
import { Setting } from "../../helpers/Setting";
import MultiSelect from '../../components/Multiselect';
import CompanyService from '../../services/CompanyService';
import { getUserRole } from "../../services/UserSettingService";

const SettingPage = (props) => {
    const [companyList, setCompanyList] = useState([]);
    const [company, setCompany] = useState([])
    const [userRoleList, setUserRoleList] = useState([]);
    const [userRole,setUserRole] = useState([])

    let dispatch = useDispatch();

    useEffect(() => {
        getSettings()
        getCompanyList();
        getUserRoleValue();
    }, [props]);

    const getSettings = async () => {
        const settingData = await getCompanySettings();
        let company = getKeyValueByObject(settingData, Setting.ONLINE_SALE_COMPANY);
        setCompany(company)
        let role = getKeyValueByObject(settingData, Setting.ONLINE_SALE_USER_ROLE)
        setUserRole(role)
    };
    const getUserRoleValue = async () => {
        const roleData = await getUserRole();
        setUserRoleList(roleData);
      };

    const getCompanyList = async () => {
    const data = await CompanyService.search();
    let list = [];
    data && data.length > 0 && data.forEach((companyList) => {
      list.push({
        value: companyList.id,
        label: companyList.company_name,
        id: companyList.id,
      });
    });
    setCompanyList(list)
  };


    const handleUpdate = async (values) => {
        let data = new FormData();
     
        data.append(
            Setting.ONLINE_SALE_COMPANY,
            values && values?.online_sale_company?.value
        );
        data.append(
            Setting.ONLINE_SALE_USER_ROLE,
            values && values?.online_sale_user_role?.value
        );

        saveSetting(data, null, (res) => {
            if (res) {
            }
        });
    };
    
    return (
        <div>
            <Form
                enableReinitialize={true}
                initialValues={{
                    online_sale_company: companyList && companyList.length > 0 && companyList.find((data) => data?.value == company),
                    online_sale_user_role : userRoleList && userRoleList.length > 0 && userRoleList.find((data)=>data?.value == userRole)
                }}
                onSubmit={(values) => {
                    handleUpdate(values);
                }}
            >
                <div className='card bg-white mb-3'>
                    <div className='card-body'>
                        <Select
                            name={Setting.ONLINE_SALE_COMPANY}
                            label='Company'
                            options={companyList}
                        />
                         <Select
                            name={Setting.ONLINE_SALE_USER_ROLE}
                            label='Role'
                            options={userRoleList}
                        />
                        <div className='mb-4'>
                            <div>
                                <SaveButton />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default SettingPage;