import React, { useEffect, useState } from "react";
import Form from "../../components/Form";
import SaveButton from "../../components/SaveButton";
import { Setting } from "../../helpers/Setting";
import { getKeyValueByObject } from "../../lib/Helper";
import { getCompanySettings, saveSetting } from "../../services/SettingService";
import TagTypeService from "../../services/TagTypeService";
import TagSelect from "../../components/TagSelect";
import { TagTypeName } from "../../helpers/Tag";

const SettingPage = (props) => {
  const [fineTypeList, setFineTypeList] = useState([]);
  const [bonusTypeList, setBonusTypeList] = useState([]);
  const [missingFine, setMissingFine] = useState([]);
  const [extraBonus, setExtraBonus] = useState([]);

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    let cash = getKeyValueByObject(
      settingData,
      Setting.STOCK_ENTRY_MISSING_FINE_TYPE
    );
    let extraBonusValue = getKeyValueByObject(
      settingData,
      Setting.STOCK_ENTRY_EXTRA_BONUS_TYPE
    );
    setMissingFine(cash);
    setExtraBonus(extraBonusValue)
  };


  const handleUpdate = async (values) => {
    let data = new FormData();
    data.append(
      Setting.STOCK_ENTRY_MISSING_FINE_TYPE,
      values && values?.stock_entry_missing_fine_type?.value
    );

    data.append(
      Setting.STOCK_ENTRY_EXTRA_BONUS_TYPE,
      values && values?.stock_entry_extra_bonus_type?.value
    );

    saveSetting(data, null, (res) => {
      if (res) {
      }
    });
  };

  return (
    <div>
      <Form
        enableReinitialize={true}
        initialValues={{
          stock_entry_missing_fine_type:
            fineTypeList &&
            fineTypeList.length > 0 &&
            fineTypeList.find((data) => data?.value == missingFine),
            [Setting.STOCK_ENTRY_EXTRA_BONUS_TYPE]:
            bonusTypeList &&
            bonusTypeList.length > 0 &&
            bonusTypeList.find((data) => data?.value == extraBonus),
        }}
        onSubmit={(values) => {
          handleUpdate(values);
        }}
      >
        <div className="card bg-white mb-3">
          <div className="card-body">
            <TagSelect
              name={Setting.STOCK_ENTRY_MISSING_FINE_TYPE}
              label="Stock Entry Missing Fine Type"
              params={{ type: TagTypeName.FINE_TYPE }}
              defaultValue={missingFine}
              TagList={setFineTypeList}
            />
              <TagSelect
              name={Setting.STOCK_ENTRY_EXTRA_BONUS_TYPE}
              label="Stock Entry Extra Bonus Type"
              params={{ type: TagTypeName.BONUS_TYPE }}
              defaultValue={extraBonus}
              TagList={setBonusTypeList}
            />
            <div className="mb-4">
              <div>
                <SaveButton />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default SettingPage;
