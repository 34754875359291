// Layout Constants
export const Layout = { BLOCK_FIXED_LAYOUT : "fixed-width", BLOCK_FLUID_LAYOUT : "fluid-width" };

// Block Constants
export const Block = { BLOCK_HEADER : "header", BLOCK_FOOTER : "footer" };

export const Page={
    HOME_TEXT:"Home",
    HOME:1
};


