import Toast from "../components/Toast";
import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import { HttpStatus } from "../helpers/HttpStatus";
import { isBadRequest, SUCCESS_RESPONSE } from "../lib/Http";
class CallRegisterService {
  // create a new callRegister
  static create = (data, callback) => {
    apiClient
      .post(endpoints().callRegisterAPI, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback && callback(successMessage)
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status >= HttpStatus.BAD_REQUEST
        ) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          // toast responce
          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  // update Exists callRegister
  static update = (id, data, callback) => {
    apiClient
      .put(`${endpoints().callRegisterAPI}/${id}`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback && callback(successMessage)

        }
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          // Toast.error
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // delete Exists callRegister
  static del = async (callRegisterId, callback) => {

    apiClient
      // delete callRegister
      .delete(`${endpoints().callRegisterAPI}/delete/${callRegisterId}`)
      .then((res) => {
        if (res.status == SUCCESS_RESPONSE) {
          Toast.success(res?.data?.message);
          return callback && callback(res?.data?.message)
        }
      })
      .catch((err) => {
        if (isBadRequest(err)) {
          let errorMessage;
          const errorRequest = err.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
        }
      });
  };
  static search = async (page, object_id, search, sort, sortDir) => {
    try {
      //create new array for store list

      //get store list
      let response = await apiClient.get(
        `${endpoints().callRegisterAPI}/search?page=${page}&pagination=true&object_id=${object_id}&search=${search}&sort=${sort}&sortDir=${sortDir}`
      );

      //validate response xiist or not
      if (response && response.data && response.data.data) {
        //get store list
        let callRegister = response.data;
        return callRegister
      }
    }
    catch (err) { }


  };

  static get = async (id) => {
    const { data } = await apiClient.get(`${endpoints().callRegisterAPI}/${id}`);
    return data;
  };
}
// export service function
export default CallRegisterService;
