/**
 * Service dependencies
 */
import { apiClient } from "../apiClient";
import { endpoints } from "../api/endPoints";
import Toast from "../components/Toast";
import ArrayList from "../lib/ArrayList";
import Url from "../lib/Url";
import { isBadRequest } from "../lib/Http";
import { fetchList } from "../actions/table";
import { HttpStatus } from "../helpers/HttpStatus";

class ProductService {
  static async get(productId) {
    try {
      const { data } = await apiClient.get(
        `${endpoints().purchaseProductAPI}/updateFromProduct/${productId}`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  static reIndex(productId, callback) {
    let data = {
      selectedIds: productId
    };
    apiClient
      .put(`${endpoints().productAPI}/reindex`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
        }

        if (callback) {
          return callback(null, response);
        }
      })
      .catch((err) => {
        if (isBadRequest(err)) {
          let errorMessage;
          const errorRequest = err.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
        }
      });
  }

  static async search(params) {
    try {
      let queryString = await ArrayList.toQueryString(params);
      let { data } = await Url.get(
        `${endpoints().productAPI}/search`,
        queryString
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  static async updateStatus(id, data, callBack) {
    try {
      const response = await apiClient.put(
        `${endpoints().productAPI}/status/${id}`,
        data
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        Toast.success(successMessage);
        return callBack(response);
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async clone(id, data, callBack) {
    try {
      let response = await apiClient.put(
        `${endpoints().productAPI}/clone/${id}`,
        data
      );

      return callBack(response);
    } catch (err) {
      console.log(er);
    }
  }

  static async getOption(param) {
    let params = {
      brand: Url.GetParam("brand") ? Url.GetParam("brand") : Url.GetParam("brand_id") ? Url.GetParam("brand_id") : param?.brand ? param?.brand : "",
      category: Url.GetParam("category") ? Url.GetParam("category") : param?.category ? param?.category : ""
    };

    let response =
      params &&
      (await this.list({
        ...params
      }));

    return response;
  }

  static async list(params) {
    try {
      let queryString = await ArrayList.toQueryString(params);
      let { data } = await Url.get(
        `${endpoints().productAPI}/list`,
        queryString
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  static async merge(id, data, callBack) {
    try {
      const response = await apiClient.put(
        `${endpoints().productAPI}/merge/${id}`,
        data
      );
      if (response && response.data) {
        let successMessage = response.data.message;
        Toast.success(successMessage);
        return callBack(response);
      }
    } catch (error) {
      console.log(error);
    }
  }
  static async bulkMerge(data, callBack) {
    try{
    return  (dispatch) => {

       apiClient.put(
        `${endpoints().productAPI}/bulkMerge`,
        data
      )
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callBack(successMessage)
          }
        })
        .then(() => {
          dispatch(
            fetchList(
              "duplicateProductReport",
              `${endpoints().productAPI}/duplicateReport`,
              1,
              25,
              Url.GetAllParams(),
            )
          );
        })
        .catch((error) => {

          if (error.response && error.response.status >= HttpStatus.BAD_REQUEST) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }

            Toast.error(errorMessage);
          }
        });
    };
  }catch(err){
    console.log(err);
  }
  }
}
export default ProductService;
