import React, { useState, useEffect } from "react";
import Text from "../../components/Text";
import SectionTitle from "../../components/SectionTitle";
import Url from "../../components/Url";
import { Setting } from "../../helpers/Setting";
import "../../scss/_custom.scss";
import ColorSelect from "../../components/colorSelect/colorSelect";

const Footer = (props) => {
  const { footToggle, footToggles, settingsFooter } = props;
  let {
    portal_footer_color,
    portal_footer_heading_color,
    portal_footer_text_color,
  } = settingsFooter;
  const [footersColors, setFootersColors] = useState(portal_footer_color);
  const [footersTextColor, setFootersTextColor] = useState(
    portal_footer_text_color
  );
  const [isExpanded, setIsExpanded] = useState(true);

  const footColor = isExpanded ? "#ECF5FF" : "#FFFFFF";

  useEffect(() => {
    setFootersColors(settingsFooter.portal_footer_color);
    setFootersTextColor(settingsFooter.portal_footer_text_color);
  }, [
    settingsFooter.portal_footer_color,
    settingsFooter.portal_footer_heading_color,
    settingsFooter.portal_footer_text_color,
  ]);

  return (
    <>
      <div className="accordion mt-3" id="accordionFooter">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFooter">
            <button
              className={`accordion-button ${isExpanded ? "" : "collapsed"}`}
              style={{ backgroundColor: footColor }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFooter"
              aria-expanded={isExpanded ? "true" : "false"}
              aria-controls="collapseFooter"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <h5 className="text-primary">Footer</h5>
            </button>
          </h2>
          <div
            id="collapseFooter"
            className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
            aria-labelledby="headingFooter"
            data-bs-parent="#accordionFooter"
          >
            <div className="accordion-body p-3">
              {/* Footer Color */}
              <SectionTitle label="Color" className="font-weight-normal" />
              <div className="field-wrapper">
                <ColorSelect
                  name="portal_footer_color"
                  defaultValue={portal_footer_color}
                  setColor={props?.footerColor}
                />
              </div>

              {/* Footer Text Color */}
              <SectionTitle label="Text Color" className="d-flex" />
              <div className="field-wrapper">
                <ColorSelect
                  name="portal_footer_text_color"
                  defaultValue={portal_footer_text_color}
                  setColor={props?.footerTextColor}
                />
              </div>

              {/* Footer Copyright Text */}
              <h5>Copyright Text</h5>
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <Text
                    className="border rounded"
                    name={Setting.SETTINGS_PORTAL_FOOTER_COPY_RIGHTS_TEXT}
                    placeholder="Enter Footer Copyright Text"
                  />
                </div>
              </div>

              {/* URL for Terms of Service */}
              <h5>URL for Terms of Service</h5>
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <Url
                    name={Setting.SETTINGS_TERMS_OF_SERVICE_URL}
                    label="Set the path to your terms of service."
                    placeholder="Enter the URL..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
