import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import { bindActionCreators } from "redux";

//Action
import { fetchList, setTablePage } from "../../actions/table";

//Components
import Pagination from "../Pagination";
import Spinner from "../Spinner";
import Filter from "../Filter";
import ProductCard from "../../views/product/components/productCard";
import NoRecordsFound from "../NoRecordsFound";
import "./styles.scss";

// API
import { endpoints } from "../../api/endPoints";

// Config
import { apiClient } from "../../apiClient";

// helpers
import ObjectName from "../../helpers/ObjectName";
import { Order } from "../../helpers/Order";
import * as tabConstant from "../../helpers/Product";
import Cookie from "../../helpers/Cookie";
import { groupOption } from "../../helpers/Status";

// Lib
import ArrayList from "../../lib/ArrayList";
import Currency from "../../lib/Currency";
import Cookies from "../../lib/Helper";
import DateTime from "../../lib/DateTime";
import Number from "../../lib/Number";
import Url from "../../lib/Url";

// Services
import PaymentAccountService from "../../services/PaymentAccountService";
import TranferTypeReasonService from "../../services/TranferTypeReasonService";
import BrandService from "../../services/BrandService";
import UserService from "../../services/UserService";
import usersService from "../../services/userServices";
import { DEFAULT_PAGE } from "../../views/product";
import SprintService from "../../services/SprintService";
import CategoryService from "../../services/CategoryService";
import ProductService from "../../services/ProductService";
import AccountService from "../../services/AccountService";
import StatusService from "../../services/StatusService";
import ShiftService from "../../services/ShiftService";
import transferTypeService from "../../services/TransferTypeService";
import StoreService from "../../services/StoreService";
import ProjectTicketTypeService from "../../services/ProjectTicketTypeService";
import ProjectComponentService from "../../services/ProjectComponentService";
import accountTypeService from "../../services/AccountTypeService";
import ProjectService from "../../services/ProjectService";

// Assets
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MonthOption } from "../../helpers/Salary";
import { CUSTOM_VALUE, DateOptions } from "../../helpers/Date";
import { leaveOption } from "../../helpers/User";
import UserRoleService from "../../services/UserRoleService";

export const ReduxColumn = () => { };

class ReduxTable extends React.Component {
  state = {
    page: 1,
    pageSize: 25,
    id: "",
    apiURL: "",
    selectedAll: false,
    selectedIds: [],
    searchTerm:
      this.props.params && this.props.params.searchItem
        ? this.props.params.searchItem
        : "" || "",
    isSearch: false,
    isActive: "",
    selectedStatusOption: "Active",
    selectedSortOption: "Alphabetical A-Z",
    sortByOptions: [
      {
        value: "a-z",
        label: "Alphabetical A-Z",
      },
    ],
    value: Url.GetParam("search"),
    pageSizeValue: Url.GetParam("pageSize"),
    categoryList: "",
    brandList: "",
    spinning: false,
    autoRefresh: "",
    tab: Url.GetParam("tab"),
    storeData: [],
    vendorData: [],
    refreshValue: "",
    ownerData: [],
    statusList: [],
    gstStatusList: [],
    groupList: [],
    transferTypeList: [],
    userList: [],
    shiftList: [],
    brandOption: [],
    categoryOption: [],
    salesExecutiveOption: [],
    ticketTypeOption: [],
    ticketComponentOption: [],
    shiftData: [],
    bankOption: [],
    userOption: [],
    tagOption: [],
    sprintOption: [],
    reasonOption: [],
    productOption: [],
    account: Url.GetParam("account"),
    brand: Url.GetParam("brand"),
    category: Url.GetParam("category"),
    location: Url.GetParam("location"),
    stockEntryProductType: Url.GetParam("stockEntryProductType"),
    startDate: Url.GetParam("startDate"),
    createdStartDate: Url.GetParam("createdStartDate"),
    createdEndDate: Url.GetParam("createdEndDate"),
    date: Url.GetParam("date"),
    endDate: Url.GetParam("endDate"),
    status: Url.GetParam("status"),
    selectedSalesExecutive: Url.GetParam("salesExecutive"),
    shift: Url.GetParam("shift"),
    paymentType: Url.GetParam("paymentType"),
    type: Url.GetParam("type"),
    selectedFromLocation: Url.GetParam("fromLocation"),
    selectedToLocation: Url.GetParam("toLocation"),
    paymentAccount: Url.GetParam("paymentAccount"),
    user: Url.GetParam("user"),
    sender: Url.GetParam("sender"),
    receiver: Url.GetParam("receiver"),
    objectName: Url.GetParam("objectName"),
    visitorType: Url.GetParam("visitorType"),
    tag: Url.GetParam("tag"),
    selectedManufacture: Url.GetParam("manufacture"),
    sprint: Url.GetParam("sprint"),
    selectedSort: Url.GetParam("sort"),
    selectedSortDir: Url.GetParam("sortDir"),
    selectedReason: Url.GetParam("reason"),
    product: Url.GetParam("product"),
    stockType: Url.GetParam("stockType"),
    gstStatus: Url.GetParam("gstStatus"),
    reporter: Url.GetParam("reporter"),
    reviewer: Url.GetParam("reviewer"),
    statusGroup: Url.GetParam("group"),
    selectedProjectId: Url.GetParam("project_id"),
    role: Url.GetParam("role"),
    projectId: Cookies.get(Cookie.PROJECT_ID),
    projectList: [],
    roleList: [],
    show_duplicate: Url.GetParam("show_duplicate"),
    startTime: Url.GetParam("startTime"),
    endTime: Url.GetParam("endTime"),
    stockEntryStartDate: Url.GetParam("stockEntryStartDate"),
    stockEntryEndDate: Url.GetParam("stockEntryEndDate"),
    orderStartDate: Url.GetParam("orderStartDate"),
    orderEndDate: Url.GetParam("orderEndDate"),
    transferStartDate: Url.GetParam("transferStartDate"),
    transferEndDate: Url.GetParam("transferEndDate"),
    ticketType: Url.GetParam("ticketType"),
    ticketComponent: Url.GetParam("ticketComponent"),
    accountType: Url.GetParam("accountType"),
    accountTypeList: [],
    orderType: Url.GetParam("orderType"),
    month: Url.GetParam("month"),
    year: Url.GetParam("year"),
    yearOption: [],
    allow_leave: Url.GetParam("allow_leave"),
    show_currently_checked_in_user: Url.GetParam("show_currently_checked_in_user"),

  };

  componentDidMount() {
    const { apiURL, id, table, sortByOptions, statusOptions, showDropdown, autoRefresh } =
      this.props;

    if (showDropdown) {
      this.getCategoryDetail();
      this.getBrandDetail();
    }

    if (
      Url.GetParam("location") ||
      Url.GetParam("fromLocation") ||
      Url.GetParam("toLocation")
    ) {
      this.storeList();
    }

    if (this.props.showSalesExecutiveFilter) {
      this.getSalesExecutiveList();
    }

    if (this.props.showTicketTypeFilter) {
      this.getTicketType();
    }

    if (this.props.showTicketComponentFilter) {
      this.getTicketComponent();
    }

    if (this.props.showProjectFilter) {
      this.getProject();
    }

    if (this.props.showPaymentAccountFilter) {
      this.getBankList();
    }

    if (this.props.showYearFilter) {
      this.getYears();
    }

    if (Url.GetParam("brand")) {
      this.getBrandList();
    }

    if (Url.GetParam("category")) {
      this.getCategoryList();
    }

    if (Url.GetParam("role")) {
      this.getRoleList();
    }
    if (Url.GetParam("product")) {
      this.getProducts();
    }

    if (Url.GetParam("accountType")) {
      this.getAccountTypeList();
    }

    if (Url.GetParam("account")) {
      this.getAccounts();
    }

    if (
      Url.GetParam("status") ||
      Url.GetParam("gstStatus") ||
      this.props.showGstStatusFilter
    ) {
      this.getStatus();
    }

    if (Url.GetParam("projectId")) {
      this.getGroupStatus(
        this.props.objectName,
        Url.GetParam("projectId") && Url.GetParam("projectId")
      );
    }

    if (Url.GetParam("shift")) {
      this.getShiftList();
    }

    if (Url.GetParam("type")) {
      this.getTransferType();
    }

    if (
      Url.GetParam("user") ||
      Url.GetParam("reporter") ||
      Url.GetParam("reviewer") ||
      Url.GetParam("sender") ||
      Url.GetParam("receiver") ||
      Url.GetParam("owner")
    ) {
      this.getUserList();
    }

    if (
      this.props.showTagFilter ||
      this.props.showManufactureFilter ||
      this.props.showVisitorTypeFilter
    ) {
      this.getTagList();
    }

    if (this.props.showReasonFilter) {
      this.getTransferTypeReason();
    }

    if (this.props.showSprintFilter) {
      this.getSprintList();
    }

    if (autoRefresh) {
      this.startAutoRefresh();
    }

    this.setState(
      {
        id,
        apiURL,
        page:
          table[id] && table[id].currentPage
            ? table[id].currentPage
            : Url.GetParam("page") || 1,
        pageSize: table[id] && table[id].pageSize ? table[id].pageSize : 25,
        selectedStatusOption:
          statusOptions && statusOptions.length
            ? statusOptions[0].value
            : this.state.selectedStatusOption,
        selectedSortOption: this.props.defaultSortOption
          ? this.props.defaultSortOption[0].value
          : sortByOptions && sortByOptions.length
            ? sortByOptions[0].value
            : this.state.selectedSortOption,
      },
      () => {
        const selectedSortOption = this.getSelectedSortLabel(
          this.state.selectedSortOption
        );
        this.handleSortByChange(selectedSortOption);
      }
    );
    const selectedStatusOption = this.getSelectedStatusLabel(
      this.state.selectedStatusOption
    );
    this.handleStatusByChange(selectedStatusOption);
  }

  componentDidUpdate(prevProps) {
    const { sortByOptions, statusOptions, autoRefresh } = this.props;

    if (autoRefresh !== prevProps.autoRefresh) {
      if (autoRefresh) {
        this.startAutoRefresh();
      } else {
        this.stopAutoRefresh();
      }
    }
    if (this.props.apiURL !== prevProps.apiURL) {
      this.setState(
        {
          apiURL: this.props.apiURL,
          selectedStatusOption:
            statusOptions && statusOptions.length
              ? statusOptions[0].value
              : this.state.selectedStatusOption,
          selectedSortOption:
            sortByOptions && sortByOptions.length
              ? sortByOptions[0].value
              : this.state.selectedSortOption,
        },
        () => {
          const selectedSortOption = this.getSelectedSortLabel(
            this.state.selectedSortOption
          );
          this.handleSortByChange(selectedSortOption);
        },
        () => {
          const selectedStatusOption = this.getSelectedStatusLabel(
            this.state.selectedStatusOption
          );
          this.handleStatusByChange(selectedStatusOption);
        }
      );
    }
  }

  componentWillUnmount() {
    this.stopAutoRefresh();
  }

  startAutoRefresh() {
    this.interval = setInterval(() => {
      this.fetchData(
        ...this.getFetchDataParams({
          search: this.state.value ? this.state.value : "",
        })
      );
    }, 60000);
  }

  stopAutoRefresh() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  getProductDetails = (
    productName,
    productImage,
    brandName,
    size,
    unit,
    salePrice,
    mrp
  ) => {
    return (
      <ProductCard
        productImageIcon
        square
        disableLink
        disableLinks
        productName={productName}
        url={productImage}
        brandName={brandName}
        size={size}
        unit={unit}
        salePrice={salePrice}
        mrp={mrp}
      />
    );
  };

  getBrandList = async () => {
    const data = await BrandService.getBrandOption();
    this.setState({ brandOption: data });
  };

  getCategoryList = async () => {
    const data = await CategoryService.getOption();
    this.setState({ categoryOption: data });
  };

  getRoleList = async () => {
    const response = await UserRoleService.list();
    this.setState({ roleList: response });
  };
  getProducts = async () => {
    let response = await ProductService.getOption();
    let productList = new Array();

    if (response && response.data) {
      let products = response.data;
      if (products && products.length > 0) {
        for (let i = 0; i < products.length; i++) {
          let productDetails = products[i];
          productList.push({
            label: this.getProductDetails(
              productDetails.name,
              products[i].image,
              productDetails.brand,
              productDetails.size,
              productDetails.unit,
              productDetails.sale_price,
              productDetails.mrp,
              productDetails.id
            ),
            value: productDetails.product_display_name + products[i].id,
            id: products[i].id,
          });
        }
      }
    }
    this.setState({ productOption: productList });
  };

  getAccounts = async () => {
    let response = await AccountService.getOption();
    this.setState({ vendorData: response });
  };

  getStatus = async () => {
    let response = await StatusService.getOption(this.props.objectName);
    this.setState({ statusList: response, gstStatusList: response });
  };

  getGroupStatus = async (objectName, projectId) => {
    let response = await StatusService.getGroupOption(objectName, projectId);
    this.setState({ groupList: response ? response : [] });
  };

  getShiftList = async () => {
    const response = await ShiftService.getShiftList();
    this.setState({ shiftData: response });
  };

  getAccountTypeList = async () => {
    const response = await accountTypeService.list();
    this.setState({ accountTypeList: response });
  };

  getTransferType = async () => {
    const response = await transferTypeService.getOption();
    this.setState({ transferTypeList: response });
  };

  getUserList = async () => {
    const users = await UserService.getOption();
    if (users && users.length > 0) {
      this.setState({ userOption: users });
    }
  };



  /**
   * Fetch data using API call
   *
   * @param {*} force
   */

  fetchData(
    force,
    sort,
    sortDir,
    status,
    account,
    brand,
    category,
    location,
    startDate,
    endDate,
    createdStartDate,
    createdEndDate,
    startTime,
    endTime,
    statusValue,
    salesExecutive,
    shift,
    paymentType,
    type,
    fromLocation,
    toLocation,
    paymentAccount,
    user,
    objectName,
    tag,
    visitorType,
    pageValue,
    reason,
    product,
    stockType,
    search,
    manufacture,
    gstStatus,
    reporter,
    reviewer,
    sprint,
    statusGroup,
    projectId,
    stockEntryProductType,
    project_id,
    role,
    date,
    show_duplicate,
    stockEntryStartDate,
    stockEntryEndDate,
    orderStartDate,
    orderEndDate,
    transferStartDate,
    transferEndDate,
    ticketType,
    ticketComponent,
    accountType,
    orderType,
    month,
    year,
    owner,
    allow_leave,
    show_currently_checked_in_user,
    sender,
    receiver
  ) {
    const {
      id,
      apiURL,
      page,
      pageSize,
      searchTerm,
      selectedSortOption,
      value,
      pageSizeValue,
      tab,
      selectedSort,
      selectedSortDir,
      accountTypeList,
    } = this.state;
    const {
      table,
      actions: { fetchList, setTablePage },
      sortByOptions,
      statusOptions,
    } = this.props;
    const sortAndSortDir = selectedSortOption.split(":");

    const sortBy = sort
      ? sort
      : selectedSort
        ? selectedSort
        : sortAndSortDir[0];
    const sortDirBy = sortDir
      ? sortDir
      : selectedSortDir
        ? selectedSortDir
        : sortAndSortDir[1];
    const selectedstatus = status;

    const listDetails = table[id] || {};

    const params = this.props.params || {};
    params.search = search ? search : value || "";

    if (this.props.showStatusOptions && !params.status) {
      params.status =
        status ||
        (statusOptions && selectedstatus) ||
        this.state.selectedStatusOption ||
        "";
    }
    params.sort = sort ? sort : (sortByOptions && !searchTerm && sortBy) || "";
    params.sortDir =
      sortDir || (sortByOptions && !searchTerm && sortDirBy) || "";
    params.pageSize = pageSize || pageSizeValue || Url.GetParam("pageSize");
    if (pageValue || Url.GetParam("page")) {
      params.page = pageValue ? pageValue : Url.GetParam("page");
    } else {
      params.page = DEFAULT_PAGE;
    }
    params.tab = this?.props?.params?.tab ? this?.props?.params?.tab : tab;
    params.account = account
      ? account
      : account === ""
        ? ""
        : this.props?.params?.account
          ? this.props?.params?.account
          : Url.GetParam("account");
    params.brand = brand ? brand : brand === "" ? "" : Url.GetParam("brand");
    params.category = category
      ? category
      : category === ""
        ? ""
        : Url.GetParam("category");
    params.location = location
      ? location
      : location === ""
        ? ""
        : Url.GetParam("location");
    params.stockEntryProductType = stockEntryProductType
      ? stockEntryProductType
      : stockEntryProductType === ""
        ? ""
        : Url.GetParam("stockEntryProductType");
    if ((this.props.showDateFilter) || (this.props.showCustomDateFilter)) {
      params.startDate = startDate
        ? startDate
        : startDate === ""
          ? ""
          : Url.GetParam("startDate");
      params.endDate = endDate
        ? endDate
        : endDate === ""
          ? ""
          : Url.GetParam("endDate");
    }
    if (this.props.showCreatedAtFilter) {
      params.createdStartDate = createdStartDate
        ? createdStartDate
        : createdStartDate === ""
          ? ""
          : Url.GetParam("createdStartDate");
      params.createdEndDate = createdEndDate
        ? createdEndDate
        : createdEndDate === ""
          ? ""
          : Url.GetParam("createdEndDate");
    }
    if (this.props.showTimeFilter) {
      params.startTime = startTime
        ? startTime
        : startTime === ""
          ? ""
          : Url.GetParam("startTime");
      params.endTime = endTime
        ? endTime
        : endTime === ""
          ? ""
          : Url.GetParam("endTime");
    }
    if (this.props.showStockEntryDateFilter) {
      params.stockEntryStartDate = stockEntryStartDate
        ? stockEntryStartDate
        : stockEntryStartDate === ""
          ? ""
          : Url.GetParam("stockEntryStartDate");
      params.stockEntryEndDate = stockEntryEndDate
        ? stockEntryEndDate
        : stockEntryEndDate === ""
          ? ""
          : Url.GetParam("stockEntryEndDate");
    }
    if (this.props.showOrderDateFilter) {
      params.orderStartDate = orderStartDate
        ? orderStartDate
        : orderStartDate === ""
          ? ""
          : Url.GetParam("orderStartDate");
      params.orderEndDate = orderEndDate
        ? orderEndDate
        : orderEndDate === ""
          ? ""
          : Url.GetParam("orderEndDate");
    }
    if (this.props.showTransferDateFilter) {
      params.transferStartDate = transferStartDate
        ? transferStartDate
        : transferStartDate === ""
          ? ""
          : Url.GetParam("transferStartDate");
      params.transferEndDate = transferEndDate
        ? transferEndDate
        : transferEndDate === ""
          ? ""
          : Url.GetParam("transferEndDate");
    }
    if (this.props.showTicketTypeFilter) {
      params.ticketType = ticketType
        ? ticketType
        : ticketType === ""
          ? ""
          : Url.GetParam("ticketType");
    }
    if (this.props.showTicketComponentFilter) {
      params.ticketComponent = ticketComponent
        ? ticketComponent
        : ticketComponent === ""
          ? ""
          : Url.GetParam("ticketComponent");
    }
    if (this.props.showAccountTypeFilter) {
      params.accountType = accountType
        ? accountType
        : accountType === ""
          ? ""
          : this.state.accountType
            ? this.state.accountType
            : Url.GetParam("accountType")
              ? Url.GetParam("accountType")
              : "";
    }

    if (this.props.showCustomDateFilter) {
      params.date = date ? date : date === "" ? "" : Url.GetParam("date");
    }
    params.status = statusValue
      ? statusValue
      : statusValue === ""
        ? ""
        : this.state.status
          ? this.state.status
          : this?.props?.params?.status
            ? this?.props?.params?.status :
            this?.props?.params?.status == 0 ? this?.props?.params?.status
              : Url.GetParam("status");
    params.salesExecutive = salesExecutive
      ? salesExecutive
      : salesExecutive === ""
        ? ""
        : Url.GetParam("salesExecutive");
    params.shift = shift ? shift : shift === "" ? "" : Url.GetParam("shift");
    params.paymentType = paymentType
      ? paymentType
      : paymentType === ""
        ? ""
        : Url.GetParam("paymentType");
    params.orderType = orderType
      ? orderType
      : orderType === ""
        ? ""
        : Url.GetParam("orderType");
    params.month = month
      ? month
      : month === ""
        ? ""
        : Url.GetParam("month");
    params.year = year
      ? year
      : year === ""
        ? ""
        : Url.GetParam("year");

    params.type = type
      ? type
      : type === ""
        ? ""
        : this.state.type
          ? this.state.type
          : this?.props?.params?.type
            ? this?.props?.params?.type
            : Url.GetParam("type");
    params.fromLocation = this.props.params?.fromLocation?this.props.params?.fromLocation:fromLocation
      ? fromLocation
      : fromLocation === ""
        ? ""
        : Url.GetParam("fromLocation");
    params.toLocation = this.props.params?.toLocation?this.props.params?.toLocation:toLocation
      ? toLocation
      : toLocation === ""
        ? ""
        : Url.GetParam("toLocation");
    params.paymentAccount = paymentAccount
      ? paymentAccount
      : paymentAccount === ""
        ? ""
        : Url.GetParam("paymentAccount");
    params.user = user
      ? user
      : user === ""
        ? ""
        : this?.props?.params?.user
          ? this?.props?.params?.user
          : Url.GetParam("user");

          params.sender = sender
          ? sender
          : sender === ""
            ? ""
            : this?.props?.params?.sender
              ? this?.props?.params?.sender
              : Url.GetParam("sender");

              params.receiver = receiver
              ? receiver
              : receiver === ""
                ? ""
                : this?.props?.params?.receiver
                  ? this?.props?.params?.receiver
                  : Url.GetParam("receiver");
    params.owner = owner
      ? owner
      : owner === ""
        ? ""
        : this?.props?.params?.owner
          ? this?.props?.params?.owner
          : Url.GetParam("owner");
    if (
      this?.props?.params?.objectName ||
      objectName ||
      objectName === "" ||
      Url.GetParam("objectName")
    ) {
      params.objectName = this?.props?.params?.objectName
        ? this?.props?.params?.objectName
        : objectName
          ? objectName
          : objectName === ""
            ? ""
            : Url.GetParam("objectName");
    }
    params.sprint = sprint
      ? sprint
      : sprint === ""
        ? ""
        : Url.GetParam("sprint");
    params.tag = tag ? tag : tag === "" ? "" : Url.GetParam("tag");
    params.visitorType = visitorType
      ? visitorType
      : visitorType === ""
        ? ""
        : Url.GetParam("visitorType");
    params.manufacture = manufacture
      ? manufacture
      : manufacture === ""
        ? ""
        : Url.GetParam("manufacture");
    if (this.props.customStatus && this.props.customStatus.status) {
      params.status = this.props.customStatus.status;
    }
    params.gstStatus = gstStatus
      ? gstStatus
      : gstStatus === ""
        ? ""
        : Url.GetParam("gstStatus");
    params.reporter = reporter
      ? reporter
      : reporter === ""
        ? ""
        : Url.GetParam("reporter");
    params.reviewer = reviewer
      ? reviewer
      : reviewer === ""
        ? ""
        : Url.GetParam("reviewer");
    params.reason = reason
      ? reason
      : reason === ""
        ? ""
        : Url.GetParam("reason");
    params.product = product
      ? product
      : product === ""
        ? ""
        : Url.GetParam("product");

    params.stockType = stockType
      ? stockType
      : stockType === ""
        ? ""
        : Url.GetParam("stockType");
    params.role = role
      ? role
      : role === ""
        ? ""
        : this.state.role || Url.GetParam("role")
          ? Url.GetParam("role")
          : "";
    params.group = statusGroup
      ? statusGroup
      : statusGroup === ""
        ? ""
        : this?.props?.params?.group
          ? this?.props?.params?.group
          : this.props?.params?.group == ""
            ? ""
            : Url.GetParam("group");

    if (!this.props.projectId) {
      params.project_id = project_id
        ? project_id
        : project_id === ""
          ? ""
          : Url.GetParam("project_id");
    }

    if (Cookies.get(Cookie.PROJECT_ID) !== "null" && this.props.projectId) {
      params.projectId =
        Cookies.get(Cookie.PROJECT_ID) == "null"
          ? ""
          : Cookies.get(Cookie.PROJECT_ID)
            ? Cookies.get(Cookie.PROJECT_ID)
            : this.props.projectId
              ? this.props.projectId
              : this.props.projectId === ""
                ? ""
                : "";
    }

    params.show_duplicate = show_duplicate
      ? show_duplicate
      : show_duplicate === ""
        ? ""
        : Url.GetParam("show_duplicate");

    if (this?.props?.showUserAllowLeaveFilter) {
      params.allow_leave = allow_leave === "" ? "" : allow_leave !== undefined ? allow_leave : Url.GetParam("allow_leave");
    }
    if (this?.props?.showCheckedInUser) {
      params.show_currently_checked_in_user = show_currently_checked_in_user
        ? show_currently_checked_in_user
        : show_currently_checked_in_user === ""
          ? ""
          : Url.GetParam("show_currently_checked_in_user");
    }

    if (this.props.paramsToUrl) {
      const currentPage = window.location.pathname;
      let queryString = "";

      const queryStringArray = Object.entries(params);
      const urlParams = [];
      if (queryStringArray.length > 0) {
        queryString = "?";
        queryStringArray.forEach(([key, value]) => {
          if (this.props.showParamsInUrl) {
            let filteredKey = Object.keys(this.props.showParamsInUrl).find(
              (values) => values === key
            );
            if (filteredKey) {
              urlParams.push(`${key}=${value}`);
            }
          } else {
            if (value !== null && value !== "") {
              urlParams.push(`${key}=${value}`);
            }
          }
        });
      }
      const filteredParams = urlParams.join("&");
      if (this.props.history && this.props.history.push) {
        this.props.history.push(
          `${currentPage}${filteredParams ? `?${filteredParams}` : ""}`,
          {
            data: params,
          }
        );
      }
    }

    if (!listDetails.isFetching && apiURL) {
      if (!listDetails) {
        return;
      }
      if (
        !listDetails[page] ||
        (listDetails.sortList[page] !== params.sort &&
          listDetails.sortDirList[page] !== params.sortDir) ||
        force
      ) {
        fetchList(
          id,
          apiURL,
          params.page ? params.page : page,
          pageSize,
          params
        );
      } else {
        setTablePage(id, page);
      }
    }
    this.setState({ pageSize: "" });
  }

  /**
   * Change page
   *
   * @param {*} page
   */
  onPageChange(page) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.props.setPage && this.props.setPage(page);
    const pageSize = Url.GetParam("pageSize");
    this.setState({ pageSize });
    this.fetchData(...this.getFetchDataParams({ page: page ? page : "" }));
  }

  /**
   * Change page size
   *
   * @param {*} e
   */
  onPageSizeChange(e) {
    this.setState({ page: 1, pageSize: e.target.value, isSearch: false }, () =>
      this.fetchData(true)
    );
  }

  /**
   * Select all checkbox
   *
   * @param {*} data
   * @param {*} e
   */
  toggleSelectAll(data, e) {
    const selectedIds = this.state.selectedIds;
    let rowIds = [];
    let checkedIds = [];
    data.forEach((row) => {
      if (e.target.checked) {
        if (
          selectedIds.indexOf(
            this.props.customCheckBoxId
              ? row[this.props.customCheckBoxId]
              : row.id
          ) < 0
        ) {
          selectedIds.push(
            this.props.customCheckBoxId
              ? row[this.props.customCheckBoxId]
              : row.id
          );
          rowIds.push(
            this.props.customCheckBoxId
              ? row[this.props.customCheckBoxId]
              : row.id
          );
        }
      } else {
        if (
          selectedIds.indexOf(
            this.props.customCheckBoxId
              ? row[this.props.customCheckBoxId]
              : row.id
          ) >= 0
        ) {
          selectedIds.splice(
            selectedIds.indexOf(
              this.props.customCheckBoxId
                ? row[this.props.customCheckBoxId]
                : row.id
            ),
            1
          );
        }
      }
    });
    for (let i = 0; i < rowIds.length; i++) {
      const validIds = rowIds.find((number) => number == selectedIds[i]);
      if (validIds !== undefined && validIds !== "") {
        checkedIds.push(validIds);
      }
    }
    this.setState({
      selectedAll: e.target.checked,
      selectedIds: checkedIds,
    });
    this.props.onBulkSelect(checkedIds);

    if (this.props.selectedCheckBox == false) {
      this.setState({
        selectedAll: false,
        selectedIds: "",
      });
      this.props.onBulkSelect(checkedIds);
    }
  }

  /**
   * Single checkbox select
   *
   * @param {*} data
   * @param {*} e
   */
  toggleSelect(data, e) {
    const rowIds = [];
    let checkedIds = [];

    data.forEach((row) => {
      rowIds.push(
        parseInt(
          this.props.customCheckBoxId
            ? row[this.props.customCheckBoxId]
            : row.id
        )
      );
    });
    const rowId = parseInt(e.target.value);
    const selectedIds = this.state.selectedIds;
    if (e.target.checked) {
      if (selectedIds.indexOf(rowId) < 0) {
        selectedIds.push(rowId);
      }
    } else {
      if (selectedIds.indexOf(rowId) >= 0) {
        selectedIds.splice(selectedIds.indexOf(rowId), 1);
      }
    }

    let selectedLength = 0;
    rowIds.forEach((rowId) => {
      if (selectedIds.indexOf(rowId) >= 0) {
        selectedLength++;
      }
    });

    for (let i = 0; i < rowIds.length; i++) {
      const validIds = rowIds.find((number) => number == selectedIds[i]);
      if (validIds !== undefined) {
        checkedIds.push(validIds);
      }
    }

    if (
      this.props.checkBoxLimit &&
      checkedIds.length < this.props.checkBoxLimit + 1
    ) {
      this.setState(
        {
          selectedAll: rowIds.length === selectedLength,
          selectedIds: selectedIds,
        },
        () => {
          this.props.onBulkSelect(checkedIds);
        }
      );
    }

    if (!this.props.checkBoxLimit) {
      this.setState(
        {
          selectedAll: rowIds.length === selectedLength,
          selectedIds: selectedIds,
        },
        () => {
          this.props.onBulkSelect(checkedIds);
        }
      );
    }
  }

  componentWillReceiveProps(props) {
    const { table, id, dataList } = props;
    const listDetails = table[id];
    let data = [];

    if (listDetails) {
      data = listDetails[listDetails.currentPage] || [];
    }
    if (dataList && dataList.length > 0) {
      data = dataList || [];
    }

    if (this.props.selectedCheckBox == false) {
      this.setState({ selectedIds: [], selectedAll: false });
    }
    const selectedIds = this.state.selectedIds;
    let selectedLength = 0;
    data.forEach((row) => {
      if (selectedIds.indexOf(row.id) >= 0) {
        selectedLength++;
      }
    });

    this.setState({
      selectedAll: selectedLength > 0 && selectedLength === data.length,
    });
  }

  /**
   * Change search term
   *
   * @param {*} event
   */
  onChange(event) {
    this.setState({ value: event.target.value, page: 1 });
    event.persist();
    this.setState({ searchTerm: event.target.value });
    const status = Url.GetParam("status");
    this.setState({ selectedStatusOption: status });
    if (this.props.islandingTable)
      this.props.saveSearchTerm(event.target.value);
  }

  onSearchKeyPress(event) {
    if (event.charCode === 13) {
      this.fetchData(
        ...this.getFetchDataParams({
          search: event?.target?.value ? event?.target?.value : "",
          page: 1,
        })
      );
    }
  }

  onSearchKeyDown(event) {
    if (event.key === "Enter") {
      this.fetchData(
        ...this.getFetchDataParams({
          search: event?.target?.value ? event?.target?.value : "",
          page: 1,
        })
      );
    }
  }

  onSearchClick(event) {
    this.fetchData(
      ...this.getFetchDataParams({
        search: this.state.value ? this.state.value : "",
        page: 1,
      })
    );
  }

  doSearch = _.debounce((event) => {
    this.setState(
      {
        isSearch: true,
        searchTerm: encodeURIComponent(event.target.value),
        page: 1,
        pageSize: Url.GetParam("pageSize"),
      },
      () => {
        this.fetchData(
          true,
          Url.GetParam("sort"),
          Url.GetParam("sortDir"),
          "",
          Url.GetParam("account"),
          Url.GetParam("brand"),
          Url.GetParam("category"),
          Url.GetParam("location"),
          Url.GetParam("startDate"),
          Url.GetParam("endDate"),
          Url.GetParam("createdStartDate"),
          Url.GetParam("createdEndDate"),
          Url.GetParam("startTime"),
          Url.GetParam("endTime"),
          Url.GetParam("status"),
          Url.GetParam("salesExecutive"),
          Url.GetParam("shift"),
          Url.GetParam("paymentType"),
          Url.GetParam("type"),
          Url.GetParam("fromLocation"),
          Url.GetParam("toLocation"),
          Url.GetParam("paymentAccount"),
          Url.GetParam("user"),
          Url.GetParam("objectName"),
          Url.GetParam("tag"),
          Url.GetParam("manufacture"),
          Url.GetParam("stockEntryProductType"),
          Url.GetParam("date"),
          DEFAULT_PAGE
        );
        this.setState({
          selectedAll: false,
          selectedIds: [],
        });
      }
    );
  }, 500);

  columnSortBy(sortBy) {
    this.setState({
      isActive: !this.state.isActive,
      sortBy: sortBy,
      pageSize: this.state.pageSize,
    });
    let sortDir = "";
    if (this.state.isActive) {
      sortDir = "DESC";
    } else {
      sortDir = "ASC";
    }
    this.fetchData(true, sortBy, sortDir);
  }

  handleSortByChange = (value) => {
    this.setState({ selectedSortOption: this.getSortValueFromLabel(value) });
    this.getSortByOptions(value);
  };

  getFetchDataParams = (values) => {
    return [
      true,
      Url.GetParam("sort"),
      Url.GetParam("sortDir"),
      "",
      (values && values?.account) || values?.account === ""
        ? values?.account
        : (values && values?.vendor) || values?.vendor === ""
          ? values?.vendor
          : Url.GetParam("account"),
      (values && values?.brand) || values?.brand === ""
        ? values?.brand
        : Url.GetParam("brand"),
      (values && values?.category) || values?.category === ""
        ? values?.category
        : Url.GetParam("category"),
      (values && values?.location) || values?.location === ""
        ? values?.location
        : Url.GetParam("location"),
      (values && values?.startDate) || values?.startDate === ""
        ? values?.startDate
        : Url.GetParam("startDate"),
      (values && values?.endDate) || values?.endDate === ""
        ? values?.endDate
        : Url.GetParam("endDate"),
      (values && values?.createdStartDate) || values?.createdStartDate === ""
        ? values?.createdStartDate
        : Url.GetParam("createdStartDate"),
      (values && values?.createdEndDate) || values?.createdEndDate === ""
        ? values?.createdEndDate
        : Url.GetParam("createdEndDate"),
      (values && values?.startTime) || values?.startTime === ""
        ? values?.startTime
        : Url.GetParam("startTime"),
      (values && values?.endTime) || values?.endTime === ""
        ? values?.endTime
        : Url.GetParam("endTime"),
      (values && values?.status) || values?.status === ""
        ? values?.status
        : Url.GetParam("status"),
      (values && values?.salesExecutive) || values?.salesExecutive === ""
        ? values?.salesExecutive
        : Url.GetParam("salesExecutive"),
      (values && values?.shift) || values?.shift === ""
        ? values?.shift
        : Url.GetParam("shift"),
      (values && values?.paymentType) || values?.paymentType === ""
        ? values?.paymentType
        : Url.GetParam("paymentType"),
      (values && values?.type) || values?.type === ""
        ? values?.type
        : Url.GetParam("type"),
      (values && values?.fromLocation) || values?.fromLocation === ""
        ? values?.fromLocation
        : Url.GetParam("fromLocation")|| this?.props?.params?.fromLocation,
      (values && values?.toLocation) || values?.toLocation === ""
        ? values?.toLocation
        : Url.GetParam("toLocation") || this?.props?.params?.toLocation,
      (values && values?.paymentAccount) || values?.paymentAccount === ""
        ? values?.paymentAccount
        : Url.GetParam("paymentAccount"),
      (values && values?.user) || values?.user === ""
        ? values?.user
        : this.props && this.props?.params && this.props?.params?.user
          ? this.props?.params?.user
          : Url.GetParam("user"),
      (values && values?.objectName) || values?.objectName === ""
        ? values?.objectName
        : Url.GetParam("objectName"),
      (values && values?.tag) || values?.tag === ""
        ? values?.tag
        : Url.GetParam("tag"),
      (values && values?.visitorType) || values?.visitorType === ""
        ? values?.visitorType
        : Url.GetParam("visitorType"),
      values && values?.page ? values?.page : Url.GetParam("page"),
      (values && values?.reason) || values?.reason === ""
        ? values?.reason
        : Url.GetParam("reason"),
      (values && values?.product) || values?.product === ""
        ? values?.product
        : Url.GetParam("product"),
      (values && values?.stockType) || values?.stockType === ""
        ? values?.stockType
        : Url.GetParam("stockType"),
      (values && values?.search) || values?.search === ""
        ? values?.search
        : Url.GetParam("search"),
      (values && values?.manufacture) || values?.manufacture === ""
        ? values?.manufacture
        : Url.GetParam("manufacture"),
      (values && values?.gstStatus) || values?.gstStatus === ""
        ? values?.gstStatus
        : Url.GetParam("gstStatus"),
      (values && values?.reporter) || values?.reporter === ""
        ? values?.reporter
        : Url.GetParam("reporter"),
      (values && values?.reviewer) || values?.reviewer === ""
        ? values?.reviewer
        : Url.GetParam("reviewer"),
      (values && values?.sprint) || values?.sprint === ""
        ? values?.sprint
        : Url.GetParam("sprint"),
      (values && values?.statusGroup) || values?.statusGroup === ""
        ? values?.statusGroup
        : (values && values?.status_group) || values?.status_group === ""
          ? values?.status_group
          : Url.GetParam("group"),
      (this.props && this.props?.projectId) || this.props?.projectId === ""
        ? this.props?.projectId
        : Cookies.get(Cookie.PROJECT_ID),
      (values && values?.stockEntryProductType) ||
        values?.stockEntryProductType === ""
        ? values?.stockEntryProductType
        : Url.GetParam("stockEntryProductType"),
      (values && values?.project_id) || values?.project_id === ""
        ? values?.project_id
        : (values && values?.projectName) || values?.projectName === ""
          ? values?.projectName
          : Url.GetParam("project_id"),
      (values && values?.role) || values?.role === ""
        ? values?.role
        : Url.GetParam("role") || this?.props?.params?.role,
      (values && values?.date) || values?.date === ""
        ? values?.date
        : Url.GetParam("date"),
      (values && values?.show_duplicate) || values?.show_duplicate === ""
        ? values?.show_duplicate
        : Url.GetParam("show_duplicate"),
      (values && values?.stockEntryStartDate) ||
        values?.stockEntryStartDate === ""
        ? values?.stockEntryStartDate
        : Url.GetParam("stockEntryStartDate"),
      (values && values?.stockEntryEndDate) || values?.stockEntryEndDate === ""
        ? values?.stockEntryEndDate
        : Url.GetParam("stockEntryEndDate"),
      (values && values?.orderStartDate) || values?.orderStartDate === ""
        ? values?.orderStartDate
        : Url.GetParam("orderStartDate"),
      (values && values?.orderEndDate) || values?.orderEndDate === ""
        ? values?.orderEndDate
        : Url.GetParam("orderEndDate"),
      (values && values?.transferStartDate) || values?.transferStartDate === ""
        ? values?.transferStartDate
        : Url.GetParam("transferStartDate"),
      (values && values?.transferEndDate) || values?.transferEndDate === ""
        ? values?.transferEndDate
        : Url.GetParam("transferEndDate"),
      (values && values?.ticketType) || values?.ticketType === ""
        ? values?.ticketType
        : Url.GetParam("ticketType"),
      (values && values?.ticketComponent) || values?.ticketComponent === ""
        ? values?.ticketComponent
        : Url.GetParam("ticketComponent"),
      (values && values?.accountType) || values?.accountType === ""
        ? values?.accountType
        : "",
      (values && values?.orderType) || values?.orderType === ""
        ? values?.orderType
        : Url.GetParam("orderType"),
      (values && values?.month) || values?.month === ""
        ? values?.month
        : Url.GetParam("month"),
      (values && values?.year) || values?.year === ""
        ? values?.year
        : Url.GetParam("year"),
      (values && values?.owner) || values?.owner === ""
        ? values?.owner
        : this.props && this.props?.params && this.props?.params?.owner
          ? this.props?.params?.owner
          : Url.GetParam("owner"),
      (values && (values?.allow_leave || !values?.allow_leave)) || values?.allow_leave === ""
        ? values?.allow_leave
        : Url.GetParam("allow_leave"),
      (values && values?.show_currently_checked_in_user) || values?.show_currently_checked_in_user === ""
        ? values?.show_currently_checked_in_user
        : Url.GetParam("show_currently_checked_in_user"),
        (values && values?.sender) || values?.sender === ""
        ? values?.sender
        : this.props && this.props?.params && this.props?.params?.sender
          ? this.props?.params?.sender
          : Url.GetParam("sender"),
          (values && values?.receiver) || values?.receiver === ""
          ? values?.receiver
          : this.props && this.props?.params && this.props?.params?.receiver
            ? this.props?.params?.receiver
            : Url.GetParam("receiver"),
    ];
  };

  retrunSingleOrMultiValue = (values, key) => {
    let returnValue;
    if (Array.isArray(values[key])) {
      let arrayValue = [];
      for (let i = 0; i < values[key].length; i++) {
        if (values[key][i] !== undefined) {
          const { value } = values[key][i];
          arrayValue.push(value);
        }
      }
      returnValue = arrayValue;
    } else {
      returnValue = values[key]?.value ? values[key]?.value : "";
    }
    return returnValue;
  };


  getFilterValues = (values) => {
    if (values?.projectName) {
      this.getGroupStatus(
        this.props.objectName,
        values?.projectName ? values?.projectName?.value : ""
      );
    }

    return {
      account: values?.vendor ? values?.vendor?.value : "",
      brand: values?.brand ? values?.brand?.value : "",
      role: values?.role ? values?.role?.value : "",
      category: values?.category ? values?.category?.value : "",
      location: values?.location ? values?.location?.value : "",
      stockEntryProductType: values?.stockEntryProductType
        ? values?.stockEntryProductType?.value
        : "",
      startDate: values?.startDate
        ? DateTime.toISOStringDate(values?.startDate)
        : "",
      endDate: values?.endDate ? DateTime.toISOStringDate(values?.endDate) : "",
      createdStartDate: values?.createdStartDate
        ? DateTime.toISOStringDate(values?.createdStartDate)
        : "",
      createdEndDate: values?.createdEndDate ? DateTime.toISOStringDate(values?.createdEndDate) : "",
      date: values?.date ? values?.date?.value : "",
      startTime: values?.startTime ? values?.startTime : "",
      endTime: values?.endTime ? values?.endTime : "",
      status: (!values?.status && this?.props?.params?.status) ? this?.props?.params?.status : this.retrunSingleOrMultiValue(values, "status"),
      gstStatus: values?.gstStatus ? values?.gstStatus?.value : "",
      salesExecutive: values?.salesExecutive ? values?.salesExecutive?.id : "",
      shift: values?.shift ? values?.shift?.value : "",
      paymentType: values?.paymentType ? values?.paymentType?.value : "",
      type: this.retrunSingleOrMultiValue(values, "type") || Url.GetParam("type"),
      fromLocation: values?.fromLocation ? values?.fromLocation?.value : "",
      toLocation: values?.toLocation ? values?.toLocation?.value : "",
      paymentAccount: values?.paymentAccount
        ? values?.paymentAccount?.value
        : "",
      user: values?.user
        ? values?.user?.id
        : this.props && this.props?.params && this.props?.params?.user
          ? this.props?.params?.user
          : "",
          sender: values?.sender
          ? values?.sender?.id
          : this.props && this.props?.params && this.props?.params?.sender
            ? this.props?.params?.sender
            : "",
            receiver: values?.receiver
            ? values?.receiver?.id
            : this.props && this.props?.params && this.props?.params?.receiver
              ? this.props?.params?.receiver
              : "",
      reporter: values?.reporter ? values?.reporter?.id : "",
      reviewer: values?.reviewer ? values?.reviewer?.id : "",
      objectName: values?.objectName ? values?.objectName?.value : "",
      sprint: values?.sprint ? values?.sprint?.value : "",
      tag: values?.tag ? values?.tag?.value : "",
      visitorType: values?.visitorType ? values?.visitorType?.value : "",
      manufacture: values?.manufacture ? values?.manufacture?.value : "",
      reason: values?.reason ? values?.reason?.value : "",
      stockType: values?.stockType ? values?.stockType?.value : "",
      product: values?.product ? values?.product?.id : "",
      statusGroup: values?.status_group ? values?.status_group?.value : "",
      show_duplicate:
        values?.show_duplicate && values?.show_duplicate !== undefined
          ? values?.show_duplicate
          : "",
      project_id: values?.projectName ? values?.projectName?.value : "",
      stockEntryStartDate: values?.stockEntryStartDate
        ? values?.stockEntryStartDate
        : "",
      stockEntryEndDate: values?.stockEntryEndDate
        ? values?.stockEntryEndDate
        : "",
      orderStartDate: values?.orderStartDate ? values?.orderStartDate : "",
      orderEndDate: values?.orderEndDate ? values?.orderEndDate : "",
      transferStartDate: values?.transferStartDate
        ? values?.transferStartDate
        : "",
      transferEndDate: values?.transferEndDate ? values?.transferEndDate : "",
      ticketType: values?.ticketType ? values?.ticketType?.value : "",
      ticketComponent: values?.ticketComponent
        ? values?.ticketComponent.value
        : "",
      accountType: values?.type ? values?.type.value : "",
      orderType: values?.orderType ? values?.orderType?.value : "",
      month: values?.month ? values?.month?.value : "",
      year: values?.year ? values?.year?.value : "",
      owner: values?.owner
        ? values?.owner?.id
        : this.props && this.props?.params && this.props?.params?.owner
          ? this.props?.params?.owner
          : "",
      allow_leave:
        values?.allow_leave && values?.allow_leave !== undefined
          ? values?.allow_leave.value
          : "",
      show_currently_checked_in_user:
        values?.show_currently_checked_in_user && values?.show_currently_checked_in_user !== undefined
          ? values?.show_currently_checked_in_user
          : "",
      page: 1
    };
  };

  setStateFilterValue = (values) => {
    let statusValue = values?.status ? values?.status?.value : "";
    let ticketTypeValue = values?.ticketType?.value
      ? values?.ticketType?.value
      : Url.GetParam("ticketType");
    let ticketComponentValue = values?.ticketComponent?.value
      ? values?.ticketComponent?.value
      : Url.GetParam("ticketComponent");

    Url.UpdateUrl({ page: 1 },
      Url.GetParam("sort"),
      Url.GetParam("sortDir"),
      Url.GetParam("search"),
      Url.GetParam("pageSize"),
    );

    this.setState({
      account: values?.vendor ? values?.vendor?.value : Url.GetParam("account"),
    });
    this.setState({
      brand: values?.brand ? values?.brand?.value : Url.GetParam("brand"),
    });
    this.setState({
      selectedRole: values?.role ? values?.role?.value : Url.GetParam("role"),
    });
    this.setState({
      category: values?.category
        ? values?.category?.value
        : Url.GetParam("category"),
    });
    this.setState({
      location: values?.location
        ? values?.location?.value
        : Url.GetParam("location"),
    });
    this.setState({
      stockEntryProductType: values?.stockEntryProductType
        ? values?.stockEntryProductType?.value
        : Url.GetParam("stockEntryProductType"),
    });
    this.setState({
      startDate: values?.startDate
        ? DateTime.toISOStringDate(values?.startDate)
        : Url.GetParam("startDate"),
    });
    this.setState({
      endDate: values?.endDate
        ? DateTime.toISOStringDate(values?.endDate)
        : Url.GetParam("endDate"),
    });
    this.setState({
      createdStartDate: values?.createdStartDate
        ? DateTime.toISOStringDate(values?.createdStartDate)
        : Url.GetParam("createdStartDate"),
    });
    this.setState({
      createdEndDate: values?.createdEndDate
        ? DateTime.toISOStringDate(values?.createdEndDate)
        : Url.GetParam("createdEndDate"),
    });
    this.setState({
      date: values?.date ? values?.date : values?.date
        ? values?.date?.value
        : Url.GetParam("date"),
    });
    this.setState({
      startTime: values?.startTime
        ? values?.startTime
        : Url.GetParam("startTime"),
    });
    this.setState({
      endTime: values?.endTime ? values?.endTime : Url.GetParam("endTime"),
    });
    this.setState({
      status: statusValue ? statusValue : "",
    });
    this.setState({
      gstStatus: values?.gstStatus
        ? values?.gstStatus?.value
        : Url.GetParam("gstStatus"),
    });
    this.setState({
      selectedSalesExecutive: values?.salesExecutive
        ? values?.salesExecutive?.id
        : Url.GetParam("salesExecutive"),
    });
    this.setState({
      shift: values?.shift ? values?.shift?.value : Url.GetParam("shift"),
    });
    this.setState({
      paymentType: values?.paymentType
        ? values?.paymentType?.value
        : Url.GetParam("paymentType"),
    });
    this.setState({
      type: values?.type ? values?.type?.value : Url.GetParam("type"),
    });
    this.setState({
      selectedFromLocation: this.props.params?.fromLocation? this.props.params?.fromLocation:values?.fromLocation
        ? values?.fromLocation?.value
        : Url.GetParam("fromLocation"),
    });
    this.setState({
      selectedToLocation:  this.props.params?.toLocation? this.props.params?.toLocation:values?.toLocation
        ? values?.toLocation?.value
        : Url.GetParam("toLocation"),
    });
    this.setState({
      paymentAccount: values?.paymentAccount
        ? values?.paymentAccount?.value
        : Url.GetParam("shift"),
    });
    this.setState({
      user: values?.user ? values?.user?.id : Url.GetParam("user"),
    });
    this.setState({
      sender: values?.sender ? values?.sender?.id : Url.GetParam("sender"),
    });
    this.setState({
      receiver: values?.receiver ? values?.receiver?.id : Url.GetParam("receiver"),
    });
    this.setState({
      owner: values?.owner ? values?.owner?.id : Url.GetParam("owner"),
    });
    this.setState({
      reporter: values?.reporter
        ? values?.reporter?.id
        : Url.GetParam("reporter"),
    });
    this.setState({
      reviewer: values?.reviewer
        ? values?.reviewer?.id
        : Url.GetParam("reviewer"),
    });
    this.setState({
      objectName: values?.objectName
        ? values?.objectName?.value
        : Url.GetParam("objectName"),
    });
    this.setState({
      sprint: values?.sprint ? values?.sprint?.value : Url.GetParam("sprint"),
    });
    this.setState({
      tag: values?.tag ? values?.tag?.value : Url.GetParam("tag"),
    });
    this.setState({
      visitorType: values?.visitorType
        ? values?.visitorType?.value
        : Url.GetParam("visitorType"),
    });
    this.setState({
      selectedManufacture: values?.manufacture
        ? values?.manufacture?.value
        : Url.GetParam("manufacture"),
    });
    this.setState({
      selectedReason: values?.reason
        ? values?.reason?.value
        : Url.GetParam("reason"),
    });
    this.setState({
      selectedReason: values?.stockType
        ? values?.stockType?.value
        : Url.GetParam("stockType"),
    });
    this.setState({
      product: values?.product ? values?.product?.id : Url.GetParam("product"),
    });
    this.setState({
      status_group: values?.status_group
        ? values?.status_group?.value
        : Url.GetParam("group"),
    });
    this.setState({
      show_duplicate:
        values?.show_duplicate && values?.show_duplicate !== undefined
          ? values?.show_duplicate
          : Url.GetParam("show_duplicate"),
    });
    this.setState({
      selectedProjectId: values?.projectName
        ? values?.projectName?.value
        : Url.GetParam("project_id"),
    });
    this.setState({
      stockEntryStartDate: values?.stockEntryStartDate
        ? values?.stockEntryStartDate
        : Url.GetParam("stockEntryStartDate"),
    });
    this.setState({
      stockEntryEndDate: values?.stockEntryEndDate
        ? values?.stockEntryEndDate
        : Url.GetParam("stockEntryEndDate"),
    });
    this.setState({
      orderStartDate: values?.orderStartDate
        ? values?.orderStartDate
        : Url.GetParam("orderStartDate"),
    });
    this.setState({
      orderEndDate: values?.orderEndDate
        ? values?.orderEndDate
        : Url.GetParam("orderEndDate"),
    });
    this.setState({
      transferStartDate: values?.transferStartDate
        ? values?.transferStartDate
        : Url.GetParam("transferStartDate"),
    });
    this.setState({
      transferEndDate: values?.transferEndDate
        ? values?.transferEndDate
        : Url.GetParam("transferEndDate"),
    });
    this.setState({
      ticketType: ticketTypeValue
        ? ticketTypeValue
        : Url.GetParam("ticketType"),
    });
    this.setState({
      ticketComponent: ticketComponentValue
        ? ticketComponentValue
        : Url.GetParam("ticketComponent"),
    });
    this.setState({
      orderType: values?.orderType
        ? values?.orderType?.value
        : Url.GetParam("orderType"),
    });
    this.setState({
      month: values?.month
        ? values?.month?.value
        : Url.GetParam("month"),
    });
    this.setState({
      year: values?.year
        ? values?.year?.value
        : Url.GetParam("year"),
    });
    this.setState({
      allow_leave:
        values?.allow_leave && values?.allow_leave !== undefined
          ? values?.allow_leave
          : Url.GetParam("allow_leave"),
    });
    this.setState({
      show_currently_checked_in_user:
        values?.show_currently_checked_in_user && values?.show_currently_checked_in_user !== undefined
          ? values?.show_currently_checked_in_user
          : Url.GetParam("show_currently_checked_in_user"),
    });
  };

  handleDeleteFilter = (removedFilter) => {
    this.setStateFilterValue(removedFilter);
    this.setState(removedFilter);
    this.fetchData(...this.getFetchDataParams(removedFilter));
  };

  handleFilter = (values) => {
    let concatObjValue = {
      ...values,
      startDate: this?.props?.showDateFilter
        ? values?.startDate
        : ArrayList.isArray(values?.date) &&
          (ArrayList.isArray(this?.props?.customDateOption) && this?.props?.customDateOption
            .map((data) => data?.value)
            .includes(values?.date[0]?.value) ||
            values?.date[0]?.value == CUSTOM_VALUE)
          ? values?.startDate
          : ArrayList.isArray(this?.props?.customDateOption) && this?.props?.customDateOption
            .map((data) => data?.value)
            .includes(values?.date?.value) ||
            values?.date?.value == CUSTOM_VALUE
            ? values?.startDate
            : this?.props?.params?.startDate ? this?.props?.params?.startDate : "",
      endDate: this?.props?.showDateFilter
        ? values?.endDate
        : ArrayList.isArray(values?.date) &&
          (ArrayList.isArray(this?.props?.customDateOption) && this?.props?.customDateOption
            .map((data) => data?.value)
            .includes(values?.date[0]?.value) ||
            values?.date[0]?.value == CUSTOM_VALUE)
          ? values?.endDate
          : ArrayList.isArray(this?.props?.customDateOption) && this?.props?.customDateOption
            .map((data) => data?.value)
            .includes(values?.date?.value) ||
            values?.date?.value == CUSTOM_VALUE
            ? values?.endDate
            : this?.props?.params?.endDate ? this?.props?.params?.endDate : "",
      createdStartDate: this?.props?.showCreatedAtFilter
            ? values?.createdStartDate : "",
      createdEndDate: this?.props?.showCreatedAtFilter
            ? values?.createdEndDate : "",
    };
    let filterValue = this.getFilterValues(concatObjValue);
    this.setStateFilterValue(concatObjValue);
    this.fetchData(...this.getFetchDataParams(filterValue));
  };

  refreshButtonOnClick = (e) => {
    let value = e;
    this.setState({ refreshValue: value });
    this.setState({ spinning: true });
    this.fetchData(
      true,
      Url.GetParam("sort") ? Url.GetParam("sort") : this?.props?.params?.sort,
      Url.GetParam("sortDir")
        ? Url.GetParam("sortDir")
        : this?.props?.params?.sortDir,
      "",
      Url.GetParam("account"),
      Url.GetParam("brand"),
      Url.GetParam("category"),
      Url.GetParam("location"),
      Url.GetParam("startDate"),
      Url.GetParam("endDate"),
      Url.GetParam("createdStartDate"),
      Url.GetParam("createdEndDate"),
      Url.GetParam("startTime"),
      Url.GetParam("endTime"),
      Url.GetParam("status") || this?.props?.params?.status
        ? this?.props?.params?.status
        : "",
      Url.GetParam("salesExecutive"),
      Url.GetParam("shift"),
      Url.GetParam("paymentType"),
      Url.GetParam("type"),
      Url.GetParam("fromLocation"),
      Url.GetParam("toLocation"),
      Url.GetParam("paymentAccount"),
      Url.GetParam("user"),
      Url.GetParam("objectName"),
      Url.GetParam("tag"),
      Url.GetParam("visitorType"),
      Url.GetParam("page") ? Url.GetParam("page") : DEFAULT_PAGE,
      Url.GetParam("reason"),
      Url.GetParam("product"),
      Url.GetParam("stockType"),
      Url.GetParam("search"),
      Url.GetParam("manufacture"),
      Url.GetParam("gstStatus"),
      Url.GetParam("reporter"),
      Url.GetParam("reviewer"),
      Url.GetParam("sprint"),
      Url.GetParam("group"),
      Url.GetParam("projectId"),
      Url.GetParam("stockEntryProductType"),
      Url.GetParam("project_id"),
      Url.GetParam("role") || this?.props?.params?.role
        ? this?.props?.params?.role
        : "",
      Url.GetParam("date"),
      Url.GetParam("show_duplicate"),
      Url.GetParam("stockEntryStartDate"),
      Url.GetParam("stockEntryEndDate"),
      Url.GetParam("orderStartDate"),
      Url.GetParam("orderEndDate"),
      Url.GetParam("transferStartDate"),
      Url.GetParam("transferEndDate"),
      Url.GetParam("ticketType"),
      Url.GetParam("ticketComponent"),
      Url.GetParam("accountType"),
      Url.GetParam("allow_leave"),
      Url.GetParam("show_currently_checked_in_user"),
      Url.GetParam("sender"),
      Url.GetParam("receiver"),
    );
    this.setState({ refreshValue: "" });
    this.setState({ spinning: false });
  };

  // Sort by option
  getSortByOptions(value) {
    const valueArray = this.getSortValueFromLabel(value).split(":");
    const sortBy = valueArray[0];
    const sortDir = valueArray[1];
    const status = Url.GetParam("status");
    this.fetchData(true, sortBy, sortDir, status);
  }

  getSelectedSortLabel() {
    const sortByOptions = this.props.sortByOptions
      ? this.props.sortByOptions
      : this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      (option) => option.value.split(":")[0] === Url.GetParam("sort")
    );

    return selectedSortOption?.label
      ? selectedSortOption?.label
      : sortByOptions[0].label;

    return "";
  }

  getSortValueFromLabel(label) {
    const sortByOptions = this.props.sortByOptions
      ? this.props.sortByOptions
      : this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      (option) => option.value === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  // handle status
  handleStatusByChange = (value) => {
    const status1 = this.getStatusValueFromLabel(value);
    this.setState({
      selectedStatusOption: status1,
    });
    this.getStatusByOptions(value);
  };

  // get seleted status
  getSelectedStatusLabel() {
    const statusOptions = this.props.statusOptions
      ? this.props.statusOptions
      : this.state.sortByOptions;

    let selectedStatusOption;
    if (this.props.selectedStatus && !this.state.status) {
      selectedStatusOption = statusOptions.find(
        (option) => option.value === this.props.selectedStatus
      );
    } else {
      selectedStatusOption = statusOptions.find(
        (option) => option.value === this.state.selectedStatusOption
      );
    }
    if (selectedStatusOption) {
      return selectedStatusOption.label;
    }

    return "";
  }
  // get status value from label
  getStatusValueFromLabel(label) {
    const statusOptions = this.props.statusOptions
      ? this.props.statusOptions
      : this.state.statusOptions;

    const selectedStatusOption =
      statusOptions && statusOptions.find((option) => option.label === label);

    if (selectedStatusOption) {
      return selectedStatusOption.value;
    }

    return "";
  }

  // status by option
  getStatusByOptions(value) {
    const valueArray = this.getStatusValueFromLabel(value).split(":");
    const status = valueArray;
    this.fetchData(...this.getFetchDataParams({ status: value }));
  }

  // Page size by option
  getPageSizeByOptions(value) {
    const sort = Url.GetParam("sort") || "";
    const sortDir = Url.GetParam("sortDir") || "";
    const status = Url.GetParam("status") || "";
    const searchTerm = Url.GetParam("search") || "";
    this.setState(
      {
        page: 1,
        pageSize: value,
        pageSizeValue: value,
      },
      () => {
        this.fetchData(true, sort, sortDir, status);
      }
    );
  }

  // Getting the Brand type options
  getBrandTypeOptions = () => {
    // To list the brands in multi select dropdown
    let brandListOptions = [];
    const { brandList } = this.state;
    if (!brandList) return brandListOptions;

    brandList
      .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
      .forEach((type) => {
        brandListOptions.push({ value: type.id, label: type.name });
      });
    return brandListOptions;
  };

  // Getting the active categories details in the multi select dropdowns.
  getCategoryDetail = async () => {
    try {
      const response = await apiClient.get(`${endpoints().categoryAPI}/search`);
      const data = response && response.data && response.data.data;
      if (data && data.length > 0) {
        const categories = [];

        data.forEach((category) => {
          if (category.status !== tabConstant.CategoryStatus.INACTIVE)
            categories.push({
              id: category.id,
              name: category.name,
            });
        });
        this.setState({ categoryList: categories });
      }
    } catch (error) { }
  };

  // Getting the active brands details in the multi select dropdowns.
  getBrandDetail = async () => {
    try {
      const response = await apiClient.get(`${endpoints().brandAPI}/search`);
      const data = response && response.data && response.data.data;

      if (data && data.length > 0) {
        const brands = [];
        data.forEach((brand) => {
          if (brand.status !== tabConstant.BrandStatus.INACTIVE)
            brands.push({
              id: brand.id,
              name: brand.name,
            });
        });
        this.setState({ brandList: brands });
      }
    } catch (error) { }
  };

  // Getting the category type options
  getCategoryTypeOptions = () => {
    // To list the categories in multi select dropdown
    let categoryListOptions = [];
    let { categoryList } = this.state;

    if (!categoryList) return categoryListOptions;

    categoryList
      .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
      .forEach((type) => {
        categoryListOptions.push({ value: type.id, label: type.name });
      });
    return categoryListOptions;
  };

  storeList = async () => {
    StoreService.list((list) => {
      this.setState({ storeData: list });
    });
  };

  getSalesExecutiveList = async () => {
    let data = await usersService.getSalesExecutive();
    this.setState({ salesExecutiveOption: data.activeList });
  };

  paymentType = [
    {
      label: Order.PAYMENT_CASH_TEXT,
      value: Order.PAYMENT_CASH_VALUE,
    },
    {
      label: Order.PAYMENT_UPI_TEXT,
      value: Order.PAYMENT_UPI_VALUE,
    },
    {
      label: Order.PAYMENT_MIXED_TEXT,
      value: Order.PAYMENT_MIXED_VALUE,
    },
  ];

  getSprintList = async () => {
    const response = await SprintService.getSprintList();
    this.setState({ sprintOption: response });
  };

  getBankList = async () => {
    await PaymentAccountService.getList((response, err) => {
      const bankDetails = response?.data?.data;
      let bankList = [];
      bankDetails.forEach((bank) => {
        bankList.push({
          label: bank.payment_account_name,
          value: bank.id,
        });
      });
      this.setState({ bankOption: bankList });
    });
  };

  getYears = async () => {
    let yearOption = DateTime.getYears()

    this.setState({ yearOption: yearOption });

  };

  getTagList = async () => {
    let tagList = [];
    const response = await apiClient.get(
      `${endpoints().tagApi}/search?status=${tabConstant.Status.ACTIVE
      }&pagination=false`
    );
    const tag = response.data.data;
    if (ArrayList.isNotEmpty(tag)) {
      tag.forEach((tag) => {
        tagList.push({
          id: tag.id,
          value: tag.id,
          label: tag.name,
        });
      });
    }
    this.setState({ tagOption: tagList });
  };

  getTransferTypeReason = async () => {
    const data = await TranferTypeReasonService.search();
    let list = [];
    if (data && data.length > 0) {
      data.forEach((values) => {
        list.push({
          value: values && values.id,
          label: values && values.name,
        });
      });
    }
    this.setState({ reasonOption: list });
  };

  getTicketType = async () => {
    const project_id = Url.GetParam("projectId");
    let response = await ProjectTicketTypeService.search({
      projectId: project_id,
    });
    let data = response && response?.data && response?.data?.data;
    if (response && response?.data && response?.data?.data) {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        const { name, id, userId, userName, default_story_point } = data[i];
        list.push({
          label: name,
          id: id,
          value: id,
          assigneeId: userId,
          assigneeName: userName,
          default_story_point: default_story_point,
        });
      }

      this.setState({ ticketTypeOption: list });
    } else {
      this.setState({ ticketTypeOption: [] });
    }
  };

  getProject = async () => {
    const data = await ProjectService.getProjectList();
    this.setState({ projectList: data });
  }

  getTicketComponent = async () => {
    const project_id = Url.GetParam("projectId");
    let response = await ProjectComponentService.search({
      projectId: project_id,
    });
    let data = response && response?.data && response?.data?.data;
    if (response && response?.data && response?.data?.data) {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        const { name, id, userId, userName, default_story_point } = data[i];
        list.push({
          label: name,
          id: id,
          value: id,
          assigneeId: userId,
          assigneeName: userName,
          default_story_point: default_story_point,
        });
      }

      this.setState({ ticketComponentOption: list });
    } else {
      this.setState({ ticketComponentOption: [] });
    }
  };

  render() {
    const {
      table,
      id,
      children: columns,
      showHeader = true,
      onRowClick,
      bulkSelect,
      showTableHeader,
      headerButton,
      searchPlaceholder,
      showSearch,
      sortByDropdown,
      DropdownWithCheckbox,
      handleColumnChange,
      arrayList,
      FieldLabel,
      refreshButton,
      newTableHeading,
      transformData,
      disableColumnSort,
      sortByOptions,
      icon,
      message,
      subtextMessage,
      noPagination,
      searchBarWidth,
      hideMarginBottom,
      showNoRecord = true,
      noRecordFoundHeight,
      noRecordFoundComponent,
      dropdownLabel,
      showStatusOptions,
      statusOptions,
      showPageSize = true,
      buttonClassName,
      buttonOnClick,
      buttonLabel,
      showRoleFilter,
      setPage,
      showScroll,
      disableHeader,
      showSchedulerJobList,
      showAccountFilter,
      ActionMenu,
      dataList,
      vendorPlaceholder,
    } = this.props;

    const listDetails = table[id];
    const isLoading = !listDetails || listDetails.isFetching;
    const {
      selectedAll,
      selectedIds,
      vendorData,
      statusList,
      gstStatusList,
      ticketTypeOption,
      yearOption
    } = this.state;

    let data = [];
    let totalCount = 0;
    let currentPage = "";
    let pageSize = "";
    let startPage = "";
    let endPage = "";
    let totalAmount = 0;
    let totalHours = 0;
    let totalCash = 0;
    let totalUpi = 0;
    let totalDiscrepancyCash = 0;
    let totalDiscrepancyUpi = 0;
    let totalDiscrepancyAmount = 0;
    let cashInStore = 0;
    let totalReceivedAmount = 0;
    let totalCalculatedAmount = 0;
    let receivedUpi = 0;
    let receivedCash = 0;
    let calculatedUpi = 0;
    let calculatedCash = 0;
    let totalNetAmount = 0;
    let totalProfitAmount = 0;
    let totalMarginAmount = 0;
    let totalDiscountAmount = 0;
    let totalTaxAmount = 0;
    let totalTaxableAmount = 0;
    let totalRejectedProductAmount = 0;
    let totalNotReceivedProductAmount = 0;

    if (listDetails) {
      setPage && setPage(listDetails.currentPage);
      currentPage = listDetails.currentPage;
      totalCount = listDetails.totalCount;
      pageSize = listDetails.pageSize;
      data = listDetails[currentPage] || [];
      totalAmount = listDetails.totalAmount;
      totalHours = listDetails.totalHours;
      totalCash = listDetails.totalCash;
      totalUpi = listDetails.totalUpi;
      cashInStore = listDetails.cashInStore;
      totalReceivedAmount = listDetails.totalReceivedAmount;
      totalCalculatedAmount = listDetails.totalCalculatedAmount;
      receivedUpi = listDetails.receivedUpi;
      receivedCash = listDetails.receivedCash;
      calculatedUpi = listDetails.calculatedUpi;
      calculatedCash = listDetails.calculatedCash;
      (totalDiscrepancyCash = listDetails.totalDiscrepancyCash),
        (totalDiscrepancyUpi = listDetails.totalDiscrepancyUpi);
      totalDiscrepancyAmount = listDetails.totalDiscrepancyAmount;
      totalTaxAmount = listDetails.totalTaxAmount;
      totalTaxableAmount = listDetails.totalTaxableAmount;
      totalNetAmount = listDetails.totalNetAmount;
      totalProfitAmount = listDetails.totalProfitAmount;
      totalDiscountAmount = listDetails.totalDiscountAmount;
      totalMarginAmount = listDetails.totalMarginAmount;
      totalRejectedProductAmount = listDetails.totalRejectedProductAmount;
      totalNotReceivedProductAmount = listDetails.totalNotReceivedProductAmount;

      startPage = (currentPage - 1) * pageSize + 1;
      startPage = startPage > totalCount ? totalCount : startPage;

      endPage = currentPage * pageSize;
      endPage = endPage > totalCount ? totalCount : endPage;
    }

    const columnLength = columns?.length + (bulkSelect ? 1 : 0);
    if (dataList) {
      data = dataList;
    }
    if (transformData) {
      data = transformData(data);
    }

    if (showSchedulerJobList) {
      if (
        this.props.schedulerJobList &&
        this.props.schedulerJobList.length > 0
      ) {
        data = this.props.schedulerJobList;
      }
    }

    if (this.props.customTagList) {
      if (this.props.customTagList && this.props.customTagList.length > 0) {
        data = this.props.customTagList;
      }
    }

    if (dataList) {
      data = dataList;
    }

    let getInitialValue = (key, customOption, generalOption) => {
      let arrayValue = Url.GetParam(key) && Url.GetParam(key)?.split(",");
      let retrunValue = "";
      if (ArrayList.isArray(arrayValue)) {
        retrunValue = customOption
          ? ArrayList.isArray(customOption) &&
          customOption.filter((item) =>
            arrayValue.map((val) => parseInt(val) || val).includes(item?.value)
          )
          : ArrayList.isArray(generalOption) &&
          generalOption.filter((item) =>
            arrayValue.map((val) => parseInt(val)).includes(item?.value)
          );
      } else {
        retrunValue = customOption
          ? customOption.find((data) => data?.value == Url.GetParam(key))
          : ArrayList.isArray(generalOption)
            ? generalOption.find((data) => data?.value == Url.GetParam(key))
            : "";
      }
      return retrunValue;
    };


    let initialValues = {
      account:
        (vendorData &&
          vendorData.length > 0 &&
          vendorData.find((data) => data.value == Url.GetParam("account"))) ||
        "",
      brand:
        this.state && this.state.brandOption.length > 0
          ? this.state.brandOption.find(
            (data) => data.value == Url.GetParam("brand")
          )
          : "",
      category:
        this.props && this.props.customCategoryOption
          ? this.props.customCategoryOption.find(
            (data) => data.value == Url.GetParam("category")
          )
          : this.state && this.state.categoryOption.length > 0
            ? this.state.categoryOption.find(
              (data) => data.value == Url.GetParam("category")
            )
            : "",
      location:
        this.state && this.state.storeData.length > 0
          ? this.state.storeData.find(
            (data) => data.value == Url.GetParam("location")
          )
          : "",
      startDate:
        DateTime.getDateTimeByUserProfileTimezone(
          Url.GetParam("startDate"),
          "DD-MMM-YYYY"
        ) || "",
      endDate:
        DateTime.getDateTimeByUserProfileTimezone(
          Url.GetParam("endDate"),
          "DD-MMM-YYYY"
        ) || "",
      createdStartDate:
        DateTime.getDateTimeByUserProfileTimezone(
          Url.GetParam("createdStartDate"),
          "DD-MMM-YYYY"
        ) || "",
      createdEndDate:
        DateTime.getDateTimeByUserProfileTimezone(
          Url.GetParam("createdEndDate"),
          "DD-MMM-YYYY"
        ) || "",
      startTime: Url.GetParam("startTime") || "",
      endTime: Url.GetParam("endTime") || "",
      date: getInitialValue("date", this?.props?.customDateOption, DateOptions),
      status: getInitialValue("status", this?.props?.customStatusOption, statusList),
      gstStatus:
        gstStatusList && gstStatusList.length > 0
          ? gstStatusList.find(
            (data) => data.value == Url.GetParam("gstStatus")
          )
          : "",
      salesExecutive:
        this.state &&
          Array.isArray(this.state.salesExecutiveOption) &&
          this.state.salesExecutiveOption.length > 0
          ? this.state.salesExecutiveOption.find(
            (data) => data.id == Url.GetParam("salesExecutive")
          )
          : "",
      shift:
        this.state && this.state.shiftData.length > 0
          ? this.state.shiftData.find(
            (data) => data.value == Url.GetParam("shift")
          )
          : "",
      fromLocation:
        this.state && this.state.storeData.length > 0
          ? this.state.storeData.find(
            (data) => data.value == Url.GetParam("fromLocation")
          )
          : "",
      toLocation:
        this.state && this.state.storeData.length > 0
          ? this.state.storeData.find(
            (data) => data.value == Url.GetParam("toLocation")
          )
          : "",
      type: getInitialValue("type", this?.props?.customTypeOption, this.state.transferTypeList),

      stockEntryProductType:
        this.props && this.props.stockProductTypeOption
          ? this.props.stockProductTypeOption.find(
            (data) => data.value == Url.GetParam("stockEntryProductType")
          )
          : null,

      user:
        this.state && this.state.userOption.length > 0
          ? this.state.userOption.find(
            (data) => data?.id == Url.GetParam("user")
          )
          : "",
          sender:
          this.state && this.state.userOption.length > 0
            ? this.state.userOption.find(
              (data) => data?.id == Url.GetParam("sender")
            )
            : "",
            receiver:
            this.state && this.state.userOption.length > 0
              ? this.state.userOption.find(
                (data) => data?.id == Url.GetParam("receiver")
              )
              : "",
      owner:
        this.state && this.state.userOption.length > 0
          ? this.state.userOption.find(
            (data) => data?.id == Url.GetParam("owner")
          )
          : "",
      reporter:
        this.state && this.state.userOption.length > 0
          ? this.state.userOption.find(
            (data) => data?.id == Url.GetParam("reporter")
          )
          : "",
      reviewer:
        this.state && this.state.userOption.length > 0
          ? this.state.userOption.find(
            (data) => data?.id == Url.GetParam("reviewer")
          )
          : "",
      sprint:
        this.state && this.state.sprintOption.length > 0
          ? this.state.sprintOption.find(
            (data) => data?.value == Url.GetParam("sprint")
          )
          : "",
      objectName:
        ObjectName &&
        ObjectName.Options.find(
          (data) => data.value === Url.GetParam("objectName")
        ),
      tag:
        this.props && this.props.customTagOption
          ? this.props.customTagOption.find(
            (data) => data.value == Url.GetParam("tag")
          )
          : this.state && this.state.tagOption.length > 0
            ? this.state.tagOption.find(
              (data) => data.value == Url.GetParam("tag")
            )
            : "",
      visitorType:
        this.props && this.props.customTagOption
          ? this.props.customTagOption.find(
            (data) => data.value == Url.GetParam("visitorType")
          )
          : this.state && this.state.tagOption.length > 0
            ? this.state.tagOption.find(
              (data) => data.value == Url.GetParam("visitorType")
            )
            : "",
      manufacture:
        this.state && this.state.tagOption.length > 0
          ? this.state.tagOption.find(
            (data) => data.value == Url.GetParam("manufacture")
          )
          : "",
      paymentAccount:
        this.state && this.state.bankOption.length > 0
          ? this.state.bankOption.find(
            (data) => data.value == Url.GetParam("paymentAccount")
          )
          : "",
      reason:
        this.state && this.state.reasonOption.length > 0
          ? this.state.reasonOption.find(
            (data) => data.value == Url.GetParam("reason")
          )
          : "",
      product:
        this.state &&
          this.state.productOption &&
          this.state.productOption.length > 0
          ? this.state.productOption.find(
            (data) => data.id == Url.GetParam("product")
          )
          : "",
      stockType:
        ActionMenu &&
        ActionMenu.find((data) => data.value == Url.GetParam("stockType")),
      status_group:
        groupOption &&
        groupOption.find((data) => data.value == Url.GetParam("group")),
      paymentType: this.paymentType.find(
        (data) => data.value == Url.GetParam("paymentType")
      ),
      projectName:
        this.state.projectList &&
          this.state.projectList.find(
            (data) => data?.value == Url.GetParam("project_id")
          )
          ? this.state.projectList &&
          this.state.projectList.find(
            (data) => data?.value == Url.GetParam("project_id")
          )
          : this.state.projectList &&
          this.state.projectList.find(
            (data) => data?.value == Url.GetParam("projectId")
          ),
      role:
        this.state.roleList &&
        this.state.roleList.find((data) => data?.value == Url.GetParam("role")),
      show_duplicate: Url.GetParam("show_duplicate"),
      stockEntryStartDate: Url.GetParam("stockEntryStartDate") || "",
      stockEntryEndDate: Url.GetParam("stockEntryEndDate") || "",
      orderStartDate: Url.GetParam("orderStartDate") || "",
      orderEndDate: Url.GetParam("orderEndDate") || "",
      transferStartDate: Url.GetParam("transferStartDate") || "",
      transferEndDate: Url.GetParam("transferEndDate") || "",
      ticketType:
        this.state && this.state.ticketTypeOption.length > 0
          ? this.state.ticketTypeOption.find(
            (data) => data.value == Url.GetParam("ticketType")
          )
          : "",
      ticketComponent:
        this.state && this.state.ticketComponentOption.length > 0
          ? this.state.ticketComponentOption.find(
            (data) => data.value == Url.GetParam("ticketComponent")
          )
          : "",
      orderType:
        this?.props &&
          this?.props?.orderTypeOption &&
          this?.props?.orderTypeOption.length > 0
          ? this?.props?.orderTypeOption.find(
            (data) => data.value == Url.GetParam("orderType")
          )
          : "",
      month:
        MonthOption.find(
          (data) => data.value == Url.GetParam("month")
        ),
      year:
        this?.state &&
          this?.state?.yearOption &&
          this?.state?.yearOption.length > 0
          ? this?.state?.yearOption.find(
            (data) => (data.value == Url.GetParam("year"))
          )
          : "",
      allow_leave: leaveOption.find((data) => (data?.value == Url.GetParam("allow_leave"))),
      show_currently_checked_in_user: Url.GetParam("show_currently_checked_in_user"),

    };

    if (this.props && this.props.showAccountTypeFilter) {
      initialValues.type =
        this.state && this.state.accountTypeList.length > 0
          ? this.state.accountTypeList.find(
            (data) => data.value == Url.GetParam("accountType")
          )
          : ""
    }

    return (
      <div className={`redux-table mb-3 pb-4 mb-sm-0 pb-sm-0 ${this.props.className}`}>
        <div>
          {/* SearchBar and Filter Component */}
          <Filter
            handleFilter={this.handleFilter}
            handleDeleteFilter={this.handleDeleteFilter}
            showHeader={showHeader}
            disableHeader={disableHeader}
            newTableHeading={newTableHeading}
            showSearch={showSearch}
            //pageSize props
            showPageSize={showPageSize}
            getPageSizeByOptions={(e) => this.getPageSizeByOptions(e)}
            //page search props
            searchBarWidth={searchBarWidth}
            pageSearchValue={this.state.value}
            searchPlaceholder={searchPlaceholder}
            pageSearchOnChange={this.onChange.bind(this)}
            onKeyPress={(e) => this.onSearchKeyPress(e)}
            onKeyDown={(e) => this.onSearchKeyDown(e)}
            onSearchClick={this.onSearchClick.bind(this)}
            sortByDropdown={sortByDropdown}
            DropdownWithCheckbox={DropdownWithCheckbox}
            FieldLabel={FieldLabel}
            handleColumnChange={handleColumnChange}
            arrayList={arrayList}
            dropdownLabel={dropdownLabel}
            getSelectedSortLabel={this.getSelectedSortLabel()}
            sortByOptions={sortByOptions}
            handleSortByChange={this.handleSortByChange}
            //refresh button
            refreshButton={refreshButton}
            refreshButtonOnClick={this.refreshButtonOnClick}
            refreshValue={this.state.refreshValue}
            spinning={this.state.spinning}
            showRoleFilter={showRoleFilter}
            roleLists={this.props.roleList}
            buttonLabel={buttonLabel}
            buttonOnClick={buttonOnClick}
            buttonClassName={buttonClassName}
            // Filters
            initialValues={initialValues}
            // Date Filter
            showDateFilter={this.props.showDateFilter}
            showCreatedAtFilter={this.props.showCreatedAtFilter}
            showTimeFilter={this.props.showTimeFilter}
            showSingleDateFilter={this.props.showSingleDateFilter}
            //Brand Filter
            showBrandFilter={this.props.showBrandFilter}
            brandOption={(x) => this.setState({ brandOption: x })}
            //Category Filter
            showCategoryFilter={this.props.showCategoryFilter}
            categoryList={(x) => this.setState({ categoryOption: x })}
            //Product Filter
            showProductFilter={this.props.showProductFilter}
            productOption={(x) => this.setState({ productOption: x })}
            brandValue={this.state.brand}
            categoryValue={this.state.category}
            // Vendor Filter props
            vendorPlaceholder={vendorPlaceholder}
            showAccountFilter={showAccountFilter}
            accountCustomOption={this.props.accountCustomOption}
            vendorList={(x) => this.setState({ vendorData: x })}
            //Location Filter Props
            showStoreFilter={this.props.showStoreFilter}
            showStockEntryProductTypeFilter={
              this.props.showStockEntryProductTypeFilter
            }
            AccountProductBrandList={this.props.AccountProductBrandList}
            AccountProductCategoryList={this.props.AccountProductCategoryList}
            stockProductTypeOption={this.props.stockProductTypeOption}
            StoreList={(e) => this.setState({ storeData: e })}
            //Status Filter props
            showStatusFilter={this.props.showStatusFilter}
            customStatusOption={this.props.customStatusOption}
            statusOption={(x) => this.setState({ statusList: x })}
            objectName={this?.props?.params?.objectName}
            orderTypeGroup={this?.props?.params?.orderType ? this?.props?.params?.orderType : Url.GetParam("type") ? Url.GetParam("type") : this?.props?.params?.type}
            statusParamAllowToView={this?.props?.statusParamAllowToView}
            isMultiSelect={this.props.isMultiSelect}
            showGstStatusFilter={this.props.showGstStatusFilter}
            gstStatusOption={(x) => this.setState({ gstStatusList: x })}
            showSalesExecutiveFilter={this.props.showSalesExecutiveFilter}
            salesExecutiveOption={this.state.salesExecutiveOption}
            // Shift Filter
            showShiftFilter={this.props.showShiftFilter}
            shiftOption={(x) => this.setState({ shiftData: x })}
            //Payment Filter
            showProjectFilter={this.props.showProjectFilter}
            setProjectList={(e) => this.setState({ projectList: e })}
            showPaymentTypeFilter={this.props.showPaymentTypeFilter}
            setRoleList={(e) => this.setState({ roleList: e })}
            paymentType={this.paymentType}
            //Order Type Filter
            showOrderTypeFilter={this.props.showOrderTypeFilter}
            orderTypeOption={this.props.orderTypeOption}
            //Type Filter
            showTypeFilter={this.props.showTypeFilter}
            typeOption={(x) => this.setState({ transferTypeList: x })}
            customTypeOption={this.props.customTypeOption}
            typeIsclearable={this.props.typeIsclearable}
            // FromToStore Props
            showFromToLocationFilter={this.props.showFromToLocationFilter}
            storeData={this.state.storeData}
            // Payment Account props
            showPaymentAccountFilter={this.props.showPaymentAccountFilter}
            bankOption={this.state.bankOption}
            // User Filter Props
            showUserFilter={this.props.showUserFilter}
            showSenderFilter={this.props.showSenderFilter}
            showReceiverFilter={this.props.showReceiverFilter}
            userList={(x) => this.setState({ userOption: x })}
            assigneePlaceholder={this.props.assigneePlaceholder}
            startDatePlaceholder={this.props.startDatePlaceholder}
            endDatePlaceholder={this.props.endDatePlaceholder}
            showReporterFilter={this.props.showReporterFilter}
            showReviewerFilter={this.props.showReviewerFilter}
            reviewerList={(x) => this.setState({ userOption: x })}
            reporterList={(x) => this.setState({ userOption: x })}
            typeList={(x) => this.setState({ ticketTypeOption: x })}
            componentList={(x) => this.setState({ ticketComponentOption: x })}
            //Owner Filter
            showOwnerFilter={this.props.showOwnerFilter}
            // ObjectName Filter
            ShowObjectNameFilter={this.props.ShowObjectNameFilter}
            objectNameOptions={ObjectName.Options}
            showSprintFilter={this.props.showSprintFilter}
            sprintOptions={this.state.sprintOption}
            //ProductTag Filter
            showTagFilter={this.props.showTagFilter}
            showVisitorTypeFilter={this.props.showVisitorTypeFilter}
            customTagOption={this.props.customTagOption}
            tagParams={this.props.tagFilterType}
            tagPlaceholder={this.props.tagPlaceholder}
            showManufactureFilter={this.props.showManufactureFilter}
            showTicketTypeFilter={this.props.showTicketTypeFilter}
            showTicketComponentFilter={this.props.showTicketComponentFilter}
            //Status Filter
            showStatusOptions={showStatusOptions}
            getSelectedStatusLabel={this.getSelectedStatusLabel()}
            statusOptions={statusOptions}
            groupOption={this.state.groupList}
            handleStatusByChange={this.handleStatusByChange}
            // Reason Filter
            showReasonFilter={this.props.showReasonFilter}
            reasonOption={this.state.reasonOption}
            // Stock Filter
            showStockFilter={this.props.showStockFilter}
            ActionMenu={ActionMenu}
            //Status Group Select
            showStatusGroupFilter={this.props.showStatusGroupFilter}
            showCheckBox={this.props.showCheckBox}
            showStockEntryDateFilter={this.props.showStockEntryDateFilter}
            showOrderDateFilter={this.props.showOrderDateFilter}
            showTransferDateFilter={this.props.showTransferDateFilter}
            showObjectNameParam={this.props.showObjectNameParam}
            showStatusParam={this.props.showStatusParam}
            showAccountTypeFilter={this.props.showAccountTypeFilter}
            accountTypeOption={(x) => this.setState({ accountTypeList: x })}
            hideQueryParams={this.props.hideQueryParams}
            showMonthFilter={this.props.showMonthFilter}
            showYearFilter={this.props.showYearFilter}
            isMultiStatus={this.props.isMultiStatus}
            isMultiType={this.props.isMultiType}
            showCustomDateFilter={this.props.showCustomDateFilter}
            showUserAllowLeaveFilter={this.props.showUserAllowLeaveFilter}
            customDateOption={this.props.customDateOption}
            accountSelectParams={this.props.accountSelectParams}
            showCheckedInUser={this.props.showCheckedInUser}
          />

          <div className={showScroll ? "scroll" : ""}>
            <Table
              hover
              responsive
              className={`table-outline,   ${hideMarginBottom ? "mb-0" : ""}`}
            >
              <thead className="thead-light">
                <tr className={showTableHeader ? "sticky-top" : ""}>
                  {bulkSelect && (
                    <th
                      style={{ width: 12, paddingLeft: 8 }}
                      className="align-middle"
                    >
                      <input
                        type="checkbox"
                        checked={selectedAll}
                        onChange={this.toggleSelectAll.bind(this, data)}
                      />
                    </th>
                  )}
                  {React.Children.map(columns, (x) => {
                    // TODO: Santhosh, let"s have the "selected" class applied when you click on the TH for the filterings.
                    return (
                      x &&
                      (x?.props?.showHeader == true ? true : x?.props?.showHeader == false ? false : showHeader) && (
                        <th
                          style={{
                            minWidth: x.props.minWidth ? x.props.minWidth : "",
                            maxWidth: x.props.maxWidth ? x.props.maxWidth : "",
                          }}
                          className={`${x.props.sortBy !== ""
                            ? "cursor-pointer text-center"
                            : ""
                            } ${x.props.className}`}
                          onClick={() => {
                            return !disableColumnSort && !x.props.disableOnClick
                              ? this.columnSortBy(x.props.sortBy)
                              : false;
                          }}
                          colSpan={x.props.colspan}
                        >
                          {x.props.children}
                          {!disableColumnSort && !x.props.disableOnClick ? (
                            x.props.sortBy === this.state.sortBy ? (
                              this.state.isActive ? (
                                <FontAwesomeIcon
                                  style={{
                                    width: "10px",
                                  }}
                                  icon={faChevronUp}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  style={{
                                    width: "10px",
                                  }}
                                  icon={faChevronDown}
                                />
                              )
                            ) : (
                              <FontAwesomeIcon
                                style={{
                                  width: "10px",
                                }}
                                icon={faChevronDown}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                      )
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((list, rowIndex) => {
                  const isLastRow = rowIndex === data.length - 1;
                  return (
                    <tr
                      key={rowIndex}
                      className={this.props.showBackgroundColor && isLastRow ? "table-secondary" : ""}
                    >
                      {this.props.showBackgroundColor && isLastRow && bulkSelect ? (
                        <td className="align-middle">
                          &nbsp;
                        </td>) : (
                        bulkSelect && (
                          <td
                            style={{ paddingRight: 0 }}
                            className="align-middle"
                          >
                            <input
                              type="checkbox"
                              value={
                                this.props.customCheckBoxId
                                  ? list[this.props.customCheckBoxId]
                                  : list.id
                              }
                              onChange={this.toggleSelect.bind(this, data)}
                              checked={
                                selectedIds.indexOf(
                                  this.props.customCheckBoxId
                                    ? list[this.props.customCheckBoxId]
                                    : list.id
                                ) >= 0
                              }
                            />
                          </td>
                        ))}
                      {React.Children.map(columns, (x) => {
                        if (x && this.props.showBackgroundColor && isLastRow && x.props.field === "Action") {
                          return (
                            <td
                              className="align-middle"
                              style={{ width: "90px" }}
                            >
                              &nbsp;
                            </td>
                          );
                        }
                        return (
                          x && (
                            <td
                              className={`align-middle ${x.props.className} ${onRowClick && !x.props.disableOnClick
                                ? "cursor-pointer"
                                : ""
                                } ${x.props.type && x.props.type === "link"
                                  ? "text-primary text-underline cursor-pointer link-opacity-75"
                                  : x.props.isClickable ? "link-opacity-75" : ""
                                }`}
                              onClick={() =>
                                x.props.isClickable === "true" &&
                                  onRowClick &&
                                  !x.props.disableOnClick
                                  ? x.props.onLinkClick
                                    ? x.props.onLinkClick(list)
                                    : onRowClick(list)
                                  : null
                              }
                              style={{
                                maxWidth: x.props.width ? x.props.width : "",
                                backgroundColor:
                                  Number.Get(list["minimum_cash_in_store"]) !==
                                    Number.Get(list["cash_in_location"])
                                    ? this.props.rowBackgroundColor
                                    : "",
                                ...(x.props.field &&
                                  x.props.field.toLowerCase() === "action" && {
                                  width: "90px",
                                }),
                              }}
                            >
                              {x.props.field !== "action"
                                ? x.props.renderField
                                  ? x.props.renderField(list)
                                  : list[x.props.field]
                                : x.props.element}
                            </td>
                          )
                        );
                      })}
                    </tr>
                  );
                })}
                {isLoading ? (
                  <Spinner />
                ) : !noRecordFoundComponent ? (
                  data.length === 0 && !icon && showNoRecord ? (
                    <tr>
                      <td
                        className="text-center"
                        colSpan={columnLength >= 2 ? columnLength : 2}
                      >
                        <NoRecordsFound
                          middleHeight={noRecordFoundHeight}
                          showMessage={true}
                          hideCard={true}
                          message="No Records Found"
                        />
                      </td>
                    </tr>
                  ) : data.length === 0 && icon ? (
                    <tr>
                      <td
                        className="align-middle"
                        colSpan={columnLength}
                        height="400px"
                      >
                        <div className="d-flex flex-column align-items-center">
                          {icon}
                          <strong>No records found</strong>
                          <span> {message ? message : ""} </span>
                          <span> {subtextMessage ? subtextMessage : ""} </span>
                        </div>
                      </td>
                    </tr>
                  ) : null
                ) : (
                  data.length === 0 && (
                    <tr>
                      <td className="text-center" colSpan={columnLength}>
                        {noRecordFoundComponent}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>

          <div className="bg-white ">
            {this.props.totalProductCount && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Total Product Count: {totalCount ? totalCount : 0}
                </div>
              </div>
            )}

            {this.props.orderCashAmount && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Cash Amount:{" "}
                  {Currency.Format(
                    this.props.orderCashAmount ? this.props.orderCashAmount : 0
                  )}
                </div>
              </div>
            )}
            {this.props.orderUpiAmount && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Upi Amount:{" "}
                  {Currency.Format(
                    this.props.orderUpiAmount ? this.props.orderUpiAmount : 0
                  )}
                </div>
              </div>
            )}
           
            {this.props.totalTaxableAmount && totalCount > 0 && (
              <div className="col d-flex m-2 fw-bold justify-content-right">
                Total Taxable Amount:{" "}
                {Currency.Format(totalTaxableAmount ? totalTaxableAmount : 0)}
              </div>
            )}
            {this.props.totalTaxAmount && totalCount > 0 && (
              <div className="col d-flex m-2 fw-bold justify-content-right text-red">
                Total Tax Amount:{" "}
                {Currency.Format(totalTaxAmount ? totalTaxAmount : 0)}
              </div>
            )}
            {this.props.purchaseProducttotalAmount && totalCount > 0 && (
              <div className="col d-flex m-2 fw-bold justify-content-right text-primary">
                Total Amount: {Currency.Format(totalAmount ? totalAmount : 0)}
              </div>
            )}
            {this.props.totalAmount && (
              <div className="col d-flex m-2 fw-bold justify-content-end">
                Total Amount: {Currency.Format(totalAmount ? totalAmount : 0)}
              </div>
            )}
            {this.props.totalHours && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Total Hours: {totalHours ? totalHours : 0}
                </div>
              </div>
            )}

            {this.props.cashInStore && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Total Cash In Location: {Currency.Format(cashInStore)}
                </div>
              </div>
            )}
            {this.props.totalCash && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Total Cash Amount:{" "}
                  {Currency.Format(totalCash ? totalCash : 0)}
                </div>
              </div>
            )}
            {this.props.totalUpi && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Total Upi Amount: {Currency.Format(totalUpi ? totalUpi : 0)}
                </div>
              </div>
            )}
            {this.props.totalCalculatedAmount && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Total Calculated Amount:{" "}
                  {Currency.Format(totalCalculatedAmount)}
                </div>
              </div>
            )}
            {this.props.calculatedCash && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Calculated Cash: {Currency.Format(calculatedCash)}
                </div>
              </div>
            )}
            {this.props.calculatedUpi && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Calculated Upi: {Currency.Format(calculatedUpi)}
                </div>
              </div>
            )}
            {this.props.totalDiscrepancyAmount && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Total Discrepancy: {Currency.Format(totalDiscrepancyAmount)}
                </div>
              </div>
            )}
            {this.props.totalDiscrepancyCash && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Discrepancy Cash: {Currency.Format(totalDiscrepancyCash)}
                </div>
              </div>
            )}
            {this.props.totalDiscrepancyUpi && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Discrepancy Upi: {Currency.Format(totalDiscrepancyUpi)}
                </div>
              </div>
            )}
            {this.props.totalReceivedAmount && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Total Received Amount: {Currency.Format(totalReceivedAmount)}
                </div>
              </div>
            )}
            {this.props.receivedCash && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Received Cash: {Currency.Format(receivedCash)}
                </div>
              </div>
            )}
            {this.props.receivedUpi && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Received Upi: {Currency.Format(receivedUpi)}
                </div>
              </div>
            )}
            {this.props.totalNetAmount && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Total Net Amount:{" "}
                  {Currency.Format(totalNetAmount ? totalNetAmount : 0)}
                </div>
              </div>
            )}
            {this.props.totalProfitAmount && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-end">
                  Total Profit Amount:{" "}
                  {Currency.Format(totalProfitAmount ? totalProfitAmount : 0)}
                </div>
              </div>
            )}
            {this.props.orderProductTotalAmount && (
              <div className="col d-flex m-2 fw-bold ">
                Total Amount: {Currency.Format(totalAmount ? totalAmount : 0)}
              </div>
            )}
            {this.props.orderProductTotalProfitAmount && (
              <div>
                <div className="col d-flex m-2 fw-bold">
                  Total Profit Amount:{" "}
                  {Currency.Format(totalProfitAmount ? totalProfitAmount : 0)}
                </div>
              </div>
            )}
            {this.props.totalRejectedProductAmount && totalRejectedProductAmount > 0 && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-right text-red">
                  Total Rejected Product Amount:{" "}
                  {Currency.Format(totalRejectedProductAmount ? totalRejectedProductAmount : 0)}
                </div>
              </div>
            )}
            {this.props.totalNotReceivedProductAmount && totalNotReceivedProductAmount > 0 && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-right text-red">
                  Total Not Received Product Amount:{" "}
                  {Currency.Format(totalNotReceivedProductAmount ? totalNotReceivedProductAmount : 0)}
                </div>
              </div>
            )}
            {this.props.totalMarginAmount && totalCount > 0 && (
              <div>
                <div className="col d-flex m-2 fw-bold justify-content-right text-success">
                  Total Margin Amount:{" "}
                  {Currency.Format(totalMarginAmount ? totalMarginAmount : 0)}
                </div>
              </div>
            )}
            {this.props.totalDiscountAmount && (
              <div className="col-4 d-flex m-2 fw-bold">
                Total Discount Amount:{" "}
                {Currency.Format(totalDiscountAmount ? totalDiscountAmount : 0)}
              </div>
            )}
          </div>
          {totalCount > 0 && !noPagination && (
            <Row>
              <Col>
                Showing {startPage} to {endPage} of {totalCount} entries
              </Col>

              <Col>
                <Pagination
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={pageSize}
                  onPageChange={this.onPageChange.bind(this)}
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    table: state.table,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList, setTablePage }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReduxTable);
