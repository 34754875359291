import React from "react";

class LeftSideSection extends React.Component {
  render() {
    let { sidebarImage } = this.props;

    sidebarImage = sidebarImage ? sidebarImage : "";

    return (
      <div>

      </div>
    );
  }
}

export default LeftSideSection;
