import React from 'react';
import { endpoints } from "../../../api/endPoints";
import { TagsIcon } from "../../../assets/icons";
import SaveButton from "../../../components/SaveButton";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";

const LocationsTab=(props)=> {

  const handleDirectionClick = (userLatitude, userLongitude, locationLatitude, locationLongitude) => {
    const originLatitude = userLatitude;
    const originLongitude = userLongitude;
    const destinationLatitude = locationLatitude;
    const destinationLongitude = locationLongitude;
    const travelMode = 'DRIVING'; 
  
    const url = `https://www.google.com/maps/dir/?api=1&origin=${originLatitude},${originLongitude}&destination=${destinationLatitude},${destinationLongitude}&travelMode=${travelMode}`;
  
    window.open(url, '_blank');
  };
  


  return (
    <div>
      <ReduxTable
        id="locations"
        apiURL={`${endpoints().userAPI}/location`}
        showHeader
        newTableHeading
        icon={<TagsIcon />}
        message="You can start by clicking Add New"
        paramsToUrl={true}
        history={props?.history}
        searchPlaceholder="Search"
        params={{
            user: props?.user_id
        }}
        sortByDropdown
      >

        <ReduxColumn
          field="name"
          sortBy="name"
          minWidth="110px"
          className="text-center"
        >
          Location Name
        </ReduxColumn>
        <ReduxColumn
          field="distance"
          sortBy="distance"
          minWidth="130px"
          className="text-center"
        >
         Distance
        </ReduxColumn>
        <ReduxColumn
          field="direction"
          sortBy="direction"
          className="text-center"
          renderField={(row) => (
            <span>
             {row?.isDirection && <SaveButton label="Get Direction" onClick={()=>handleDirectionClick(row?.userLatitude,row?.userLongitude,row?.locationLatitude,row?.locationLongitude)}/>}
            </span>
          )}
        >
         Direction
        </ReduxColumn>
      </ReduxTable>
    </div>
  )
}

export default LocationsTab;
