/* Ticket Type Details Sub Tabs */
export const TICKET_COMMENTS_TAB = "COMMENTS";
export const TICKET_ATTACHMENTS_TAB = "ATTACHMENTS";
export const TICKET_TASKS_TAB = "TASKS";
export const TICKET_TESTS_TAB = "TESTS";
export const TICKET_HISTORY_TAB = "HISTORY";
export const TICKET_ACTIVITY_TAB = "ACTIVITY";
export const FieldLabel = {
  REPORTER: "Reporter",
  PROJECT: "Project",
  SPRINT: "Sprint",
  CREATEDAT: "CreatedAt",
  REVIEWER: "Reviewer",
  LOCATION: "Location",
};
