import React from "react";

// Components
import ReduxTable, { ReduxColumn } from "../components/reduxTable";
import ProductCard from "../views/product/components/productCard";
import Modal from "./Modal";
import Button from "./Button";

// API
import { endpoints } from "../api/endPoints";

// Helpers
import { Status } from "../helpers/Product";

const ProductSelectModal = ({
  modalOpen,
  toggle,
  params,
  onProductClick,
  apiURL,
  BulkSelect,
  toggleModalClose,
  initialValues,
  handleSubmit,
  showBrandFilter,
  showCategoryFilter,
  history,
  id,
  footerLabel,
  bulkSelect = true,
  showFooter = true,
  checkBoxLimit,
}) => {
  const modalBody = (
    <ReduxTable
      id={id ? id : "product"}
      showHeader
      newTableHeading
      sortByDropdown
      showStatusOptions={false}
      searchPlaceholder="Search"
      params={params}
      apiURL={apiURL ? apiURL : `${endpoints().productAPI}/search`}
      onBulkSelect={BulkSelect}
      bulkSelect={bulkSelect}
      history={history}
      paramsToUrl={true}
      showScroll
      showBrandFilter={showBrandFilter}
      showCategoryFilter={showCategoryFilter}
      showFooter={showFooter}
      checkBoxLimit={checkBoxLimit}
    >
      <ReduxColumn
        type="link"
        isClickable="true"
        sortBy="product_name"
        renderField={(row) => {
          return (
            <div onClick={() => onProductClick(row)}>
              <ProductCard
                id={row.id}
                productImageIcon
                productName={row.name ? row.name : row.productName}
                brandName={row.brand ? row.brand : row.brand_name}
                size={row.size}
                unit={row.unit}
                salePrice={row.sale_price}
                mrp={row.mrp}
                url={row.image}
                status={
                  row.status == Status.INACTIVE_VALUE ? Status.INACTIVE : ""
                }
                packSize={row.pack_size}
                brand_id={row.brand_id}
                disableLink
              />
            </div>
          );
        }}
      >
        Product
      </ReduxColumn>
    </ReduxTable>
  );

  const productFooter = (
    <Button
      label={footerLabel ? footerLabel : "Add Products"}
      onClick={(values) => {
        handleSubmit(values);
      }}
      showButtonLabelName
    />
  );

  return (
    <>
      <Modal
        className="expert-modal maxWidth-100"
        isOpen={modalOpen}
        toggle={toggle}
        toggleModalClose={toggleModalClose}
        modalTitle="Select Product"
        initialValues={initialValues ? initialValues : { product: "" }}
        modalBody={modalBody}
        modalFooter={showFooter ? productFooter : ""}
        FooterClassName="mt-5 pt-2"
        hideDefaultButtons
      />
    </>
  );
};

export default ProductSelectModal;
