import React, { useRef } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = (props) => {
  let {
    url,
    width,
    height,
    volume,
    controls,
    playInlineVideo,
    setImageModal,
  } = props;

  const playerRef = useRef(null);

  const handlePlay = () => {
    try {
      if (playerRef.current && !playInlineVideo) {
        playerRef.current.seekTo(0);
        playerRef.current.getInternalPlayer().pause();
      }
      setImageModal(true);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <ReactPlayer
        ref={playerRef}
        className="react-player"
        url={url}
        controls={controls}
        width={width ? width : "100%"}
        height={height ? height : "100%"}
        style={{ backgroundImage: url }}
        volume={volume ? volume : 100}
        onPlay={handlePlay}
      />
    </div>
  );
};
export default VideoPlayer;
