import React, { useState } from "react";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import PageTitle from "../../components/PageTitle";
//Config
import { endpoints } from "../../api/endPoints";
import BreadCrumb from "../../components/Breadcrumb";
import UserShiftDate from "./UserShiftDate";

import Currency from "../../lib/Currency";
import NumberDisplay from "../../components/NumberDisplay";
import { SaleSettlement } from "../../helpers/SaleSettlement";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const salesSettlementDiscrepancyReport = (props) => {
  const { history } = props;

  //Sort By Option Values
  const sortByOption = [
    {
      value: "discrepancy_cash:Desc",
      label: "Discrepancy Cash",
    },
    {
      value: "discrepancy_upi:Desc",
      label: "Discrepancy Upi",
    },
    {
      value: "location:Asc",
      label: "location",
    },
    {
      value: "shift:Asc",
      label: "Shift",
    },
    
  ];

  //Sort By Option Values
  const saleSettlementTypeOption = [
    {
      label: SaleSettlement.TYPE_ALL_TEXT,
      value: SaleSettlement.TYPE_ALL,
    },
    {
      label: SaleSettlement.TYPE_POSITIVE_TEXT,
      value: SaleSettlement.TYPE_POSITIVE,
    },
    {
      label: SaleSettlement.TYPE_NEGATIVE_TEXT,
      value: SaleSettlement.TYPE_NEGATIVE,
    },
  ];

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report",
    },
    {
      label: "Sales Settlement Discrepancy Report",
      link: "",
    },
  ];

  return (
    <>
      <BreadCrumb list={breadcrumbList} />

      <div className="row mx-1 justify-content-between mb-2">
        <PageTitle label="Sales Settlement Discrepancy Report" />
      </div>
      <ReduxTable
        id="salesSettlementDiscrepancyReport"
        searchPlaceholder="Search"
        newTableHeading
        showHeader
        apiURL={`${endpoints().orderSalesSettlementAPI}/search`}
        sortByOptions={sortByOption}
        paramsToUrl={true}
        history={history}
        params={{}}
        showStoreFilter
        showUserFilter
        showCustomDateFilter
        showShiftFilter
        showTypeFilter
        customTypeOption={saleSettlementTypeOption}
      >
        <ReduxColumn
          className="text-center"
          field="id"
          sortBy="saleSettlementNumber"
          isClickable="true"
          type="link"
          width="190px"
          minWidth="190px"
          maxWidth="190px"
          renderField={(row) => (
            <Link to={`/SalesSettlement/${row.id}`}>{row.saleSettlementNumber}</Link>
        )}>         
         SalesSettlement#
        </ReduxColumn>
        <ReduxColumn
          field="location"
          sortBy="location"
          disableOnClick
          renderField={(row) => <UserShiftDate data={row} />}
          className="text-center"
        >
          Location
        </ReduxColumn>

        <ReduxColumn
          field="totalOrderCash"
          disableOnClick
          className="text-end"
          renderField={(row) => (
            <span>{Currency.Format(row.totalOrderCash)}</span>
          )}
        >
           Order Amount(Cash)
        </ReduxColumn>

        <ReduxColumn
          field="totalSaleCash"
          className="text-end"
          disableOnClick
          renderField={(row) => (
            <span>{Currency.Format(row.totalSaleCash)}</span>
          )}
        >
           SalesSettlement Amount (Cash)
        </ReduxColumn>

        <ReduxColumn
          field="discrepancy_cash"
          sortBy="discrepancy_cash"
          className="text-end"
          renderField={(row) => (
            <NumberDisplay number={row.discrepancy_cash} />
          )}
        >
           Discrepancy Cash
        </ReduxColumn>
        <ReduxColumn
          field="totalOrderUpi"
          className="text-end"
          disableOnClick
          renderField={(row) => (
            <span>{Currency.Format(row.totalOrderUpi)}</span>
          )}
        >
           Order Amount(Upi)
        </ReduxColumn>

        <ReduxColumn
          field="totalSaleUpi"
          className="text-end"
          disableOnClick
          renderField={(row) => (
            <span>{Currency.Format(row.totalSaleUpi)}</span>
          )}
        >
           SalesSettlement Amount (Upi)
        </ReduxColumn>
        <ReduxColumn
          field="discrepancy_upi"
          sortBy="discrepancy_upi"
          className="text-end"
          renderField={(row) => (
            <NumberDisplay number={row.discrepancy_upi} />
          )}
        >
           Discrepancy Upi
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default salesSettlementDiscrepancyReport;
