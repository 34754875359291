const CustomForm = {
    TYPE_FILE_UPLOAD : "FileUpload",
    TYPE_DATE: 'Date',
    TYPE_CURRENCY: 'Currency',
    TYPE_TEXT_AREA : "TextArea",
    TYPE_TEXT: "Text",
    TYPE_STORE_SELECT : "StoreSelect"

}
module.exports = CustomForm
