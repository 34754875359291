import React, { useEffect, useState } from "react";
import StoreService from "../services/StoreService";
import Select from "./Select";

const SelectStore = (props) => {

    let { name, label, required, handleStoreChange, isDisabled, options, StoreList, placeholder, defaultValue=null } = props;
    
    const [storeList, setStoreList] = useState([]);

    useEffect(() => {
        if(StoreList){
            getStores()
        }
    }, [defaultValue])
    
    const getStores = async () => {
        let params={
            ...(defaultValue ? {defaultValue: defaultValue}:{})
         }
            await StoreService.list((callback) => {
                setStoreList && setStoreList(callback)
                StoreList && StoreList(callback)
            },params);
    };

    return (
        <>
            <Select
                name={name ? name : "location"}
                placeholder={placeholder ? placeholder : "Select Location" }
                options={storeList}
                handleChange={handleStoreChange}
                label={label}
                required={required}
                isDisabled={isDisabled}
                autoFocus={getStores}
                menuPortal = {props.menuPortal}
            />
        </>
    )
}

export default SelectStore;