import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { endpoints } from "../../api/endPoints";
import BreadCrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import UserCard from "../../components/UserCard";

const replenishmentReport = (props) => {
    const breadcrumbList = [
        { label: "Home", link: "/locationDashboard" },
        {
            label: "Reports",
            link: "/report",
        },
        {
            label: "Replenishment Report",
            link: "",
        },
    ];

    return (
        <>
            <BreadCrumb list={breadcrumbList} />
            <PageTitle label="Replenishment Report" className="mb-3" />
            <ReduxTable
                id="replenishmentReport"
                showHeader
                searchPlaceholder="Search"
                apiURL={`${endpoints().replenishmentReport}`}
                newTableHeading
                icon={<FontAwesomeIcon icon={faUser} />}
                message="You can start by clicking add."
                history={props.history}
                paramsToUrl={true}
                showUserFilter
                showCustomDateFilter
                sortByDropdown
            >
                <ReduxColumn
                    field="user"
                    sortBy="name"
                    renderField={(row) => (
                        <>
                            <UserCard
                                customSize={parseInt(50, 10)}
                                firstName={row.first_name}
                                url={row.media_url}
                                lastName={row.last_name}
                            />
                        </>
                    )}
                >
                    User
                </ReduxColumn>
                <ReduxColumn
                    className="text-center"
                    field="product_count"
                    sortBy="product_count"
                >
                    Product Count
                </ReduxColumn>
            </ReduxTable>
        </>
    );
};

export default replenishmentReport;
