import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import PageTitle from "../../components/PageTitle";
import DateSelector from "../../components/Date";
import UserSelect from "../../components/UserSelect";
import StatusText from "../../components/StatusText";
import UserCard from "../../components/UserCard";
import SaveButton from "../../components/SaveButton";
import AddButton from "../../components/AddButton";
import Drawer from "../../components/Drawer";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";

// Services
import TimeSheetService from "../../services/TimeSheetService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Lib
import DateTime from "../../lib/DateTime";

// Helpers
import Permission from "../../helpers/Permission";

const TimeSheetListPage = (props) => {
  let { history } = props;
  const [isSubmit, setIsSubmit] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [logedInUser, setLogedInUser] = useState();
  const [user, setUser] = useState(null);
  const [DateChange, setonDateChange] = useState();
  const [userList, setUserList] = useState([]);

  let dispatch = useDispatch();

  const hasAddPermission = hasPermission(Permission.TIMESHEET_ADD);

  const toggle = () => {
    setIsOpen(!isOpen);
    setIsSubmit(true);
    setUser("");
    setonDateChange("");
  };

  const handleUserChange = (values) => {
    let value = values;
    setUser(value && value?.id ? value?.id : "");
  };

  const onDateChange = (e) => {
    setonDateChange(e ? e : "");
  };

  const addTimesheetFrom = (
    <>
      <DateSelector label="Date" name="date" onChange={onDateChange} />
      <UserSelect
        name="user_id"
        label="User"
        showLoggedInUser
        setLogedInUser={setLogedInUser}
        handleUserChange={handleUserChange}
        selectedUserId={user}
        userList={setUserList}
      />
    </>
  )

  const addTimesheetFooter = (
    <SaveButton loading={isSubmit == false} type="submit" label="Add" />
  );

  let initialValues = {
    user_id: user ? userList && userList.find((data) => data.id == user) : logedInUser ? logedInUser : "",
    date: DateChange
      ? DateChange
      : DateTime.getTodayDateByUserTimeZone(new Date()) || "",
  };

  const handleOnSubmit = async (values) => {
    try {
      let data = new FormData();
      data.append(
        "user_id",
        values && values?.user_id?.id
          ? values?.user_id?.id
          : values?.user
            ? values?.user
            : ""
      );
      data.append("date", values && values?.date ? values?.date : "");
      dispatch(
        await TimeSheetService.create(data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "timeSheet",
                `${endpoints().TimeSheetAPI}/search`,
                1,
                25,
                {
                  sort: "createdAt",
                  sortDir: "DESC",
                }
              )
            );
            toggle();
          }
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  const sortByOption = [
    {
      label: "Most Recent",
      value: "id:DESC",
    },
  ];

  return (
    <>
      <div className="d-flex justify-between mb-3">
        <PageTitle
          label="Timesheet"
        />
        {hasAddPermission && (
          <AddButton
            label="Add"
            onClick={(e) => {
              toggle();
            }}
          />
        )}

        <Drawer
          modelTitle={"Add Timesheet"}
          DrawerBody={addTimesheetFrom}
          DrawerFooter={addTimesheetFooter}
          onSubmit={(values) => {
            handleOnSubmit(values);
          }}
          initialValues={initialValues}
          handleOpenModal={toggle}
          handleCloseModal={toggle}
          handleDrawerClose={toggle}
          isModalOpen={isOpen}
          enableReinitialize
        />
      </div>
      <ReduxTable
        id="timeSheet"
        showHeader
        newTableHeading
        apiURL={`${endpoints().TimeSheetAPI}/search`}
        sortByOptions={sortByOption}
        searchPlaceholder="Search"
        paramsToUrl={true}
        history={history}
        params={{}}
      >
        <ReduxColumn
          field="timesheet_number"
          width="120px"
          minWidth="120px"
          className="text-center"
          maxWidth="220px"
          type="link"
          sortBy="timesheet_number"
          isClickable="true"
          renderField={(row) => (
            <Link to={`/timesheet/${row.timesheet_number}`} className="link-opacity-75">
              {row.timesheet_number}
            </Link>
          )}
        >
          Timesheet#
        </ReduxColumn>
        <ReduxColumn
          field="date"
          sortBy="date"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-center"
          renderField={(row) => (
            <>{DateTime.getDateByUserProfileTimeZoneFrontEndFormat(row.date)}</>
          )}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="user_name"
          sortBy="user_name"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          className="text-center"
          renderField={(row) => (
            <UserCard
              customSize={parseInt(50, 10)}
              firstName={row.firstName}
              lastName={row.lastName}
              url={row.media_url}
            />
          )}
        >
          User
        </ReduxColumn>
        <ReduxColumn
          field="total_hours"
          width="90px"
          minWidth="90px"
          maxWidth="90px"
          className="text-center">
          Total Hours
        </ReduxColumn>
        <ReduxColumn
          field="createdAt"
          sortBy="createdAt"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-center"
          renderField={(row) => <span>{row.createdAt}</span>}
        >
          CreatedAt
        </ReduxColumn>
        <ReduxColumn
          field="status"
          sortBy="status"
          width="130px"
          minWidth="130px"
          maxWidth="130px"
          className="column-status"
          renderField={(row) => (
            <StatusText
              backgroundColor={row?.color_code}
              status={row?.status}
            />
          )}
        >
          Status
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default TimeSheetListPage;
