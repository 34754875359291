import React, { useState } from "react";
import Pagination from "../../components/Pagination";
import DateTime from "../../lib/DateTime";
import UserCard from "../../components/UserCard";
import { Attendance } from "../../helpers/Attendance";
import { Link } from "react-router-dom";

function ReportTable(props) {
  const { detail, detailValue, setPage, page, pageSize } = props;
  const currentPage = page;
  const totalCounts = detailValue.totalCount;
  const pageSizes = pageSize ? pageSize : detailValue.pageSize;
  const startPage = (currentPage - 1) * pageSizes;
  const endPage = currentPage * pageSizes;

  const [nameToggles, setNameToggles] = useState({});

  const toggleName = (dataListId) => {
    setNameToggles((prevToggles) => ({
      ...prevToggles,
      [dataListId]: !prevToggles[dataListId],
    }));
  };

  return (
    <div className="overflow-x-auto">
      <div className="card">
        {detail &&
          detail?.location?.length > 0 &&
          detail?.location?.slice(startPage, endPage).map((dataList) => (
            <div key={dataList.id}>
              <div
                className="border bg-light cursor-pointer"
                style={{
                  padding: "10px",
                }}
                onClick={() => toggleName(dataList.id)}
              >
                {detail &&
                detail?.attendance.some(
                  (list) => list?.location_id == dataList?.locationId
                ) == true ? (
                  <h5 className="d-flex align-middle fw-bold text-primary pt-3 pb-0">
                    {" "}
                    {dataList.locationName}
                  </h5>
                ) : (
                  <h5 className="d-flex align-middle fw-bold text-danger pt-3 pb-0">
                    {" "}
                    {dataList.locationName}
                  </h5>
                )}
              </div>
              <div className="border border-bottom-0 border-top-0">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    {detail &&
                      detail?.attendance?.length > 0 &&
                      detail?.attendance.map((value, index) => {
                        const isMatched =
                          value?.location_id == dataList?.locationId;

                        return isMatched ? (
                          <>
                                {value?.attendanceTypeDetail?.name && (
                                  <div
                                    className={
                                      value?.attendanceTypeDetail?.category !== 2
                                        ? "text-primary"
                                        : ""
                                    }
                                  >
                            <div className="d-flex mx-2 mt-2" key={index}>
                              <Link to={`/attendance/${value.attendanceId}`}>
                                <UserCard
                                  customSize={parseInt(40, 10)}
                                  firstName={value?.name}
                                  url={
                                    value?.media_url
                                      ? value?.media_url
                                      : value?.image
                                  }
                                  lastName={value?.last_name}
                                />
                              </Link>
                              <div className="my-2 py-1 mt-sm-1 pt-sm-1">
                                {":"}{" "}
                                {DateTime.getUserTimeZoneTime(value?.loginTime)}
                                {value.logoutTime && (
                                  <>
                                    <span className="mx-1">-</span>
                                    <span className="mx-1">
                                      {DateTime.getUserTimeZoneTime(
                                        value?.logoutTime
                                      )}
                                    </span>
                                  </>
                                )}
                                {" ("}
                                {value.shiftName}
                                {value.type ===
                                  Attendance.TYPE_ADDITIONAL_DAY && (
                                  <>
                                    {" "}
                                    -{" "}
                                    <span className="text-danger">
                                      {value.type}
                                    </span>
                                  </>
                                )}
                                {")"}
                                {value?.attendanceTypeDetail?.name && (
                                  <span
                                    className={
                                      value?.attendanceTypeDetail?.category == 2
                                        ? "text-dark"
                                        : ""
                                    }
                                  >
                                    {" "}
                                    - {value?.attendanceTypeDetail?.name}
                                  </span>
                                )}
                              </div>
                            </div>
                            </div>
                          )}
                            {!value.bothLocationMatched &&
                              value.primaryLocationName && (
                                <div className="d-flex mx-3 px-5 mt-1 pt-1 text-danger">
                                  <br />
                                  <span className="fw-bold">
                                    Primary{":"}{" "}
                                  </span>{" "}
                                  {"("}
                                  {value.primaryLocationName}
                                  {value.primaryShiftName && " - "}
                                  {value.primaryShiftName}
                                  {")"}
                                </div>
                              )}
                          </>
                        ) : null;
                      })}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {totalCounts > 0 && (
        <div className="d-flex justify-content-between mb-4 pb-4 mb-sm-0 pb-sm-0 mt-2">
          <div>
            Showing {startPage + 1} to {Math.min(endPage, totalCounts)} of{" "}
            {totalCounts} entries
          </div>
          <Pagination
            currentPage={page}
            totalCount={totalCounts}
            pageSize={pageSizes}
            onPageChange={(page) => {
              setPage(page);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ReportTable;
