import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabPane } from "reactstrap";
import classNames from "classnames";

// Components
import Form from "../../components/Form";
import SaveButton from "../../components/SaveButton";
import PageTitle from "../../components/PageTitle";
import DeleteModal from "../../components/DeleteModal";
import BreadCrumb from "../../components/Breadcrumb";
import Action from "../../components/Action";
import ActivityList from "../../components/ActivityList";
import HorizontalSpace from "../../components/HorizontalSpace";
import CancelButton from "../../components/CancelButton";
import DateSelector from "../../components/Date";
import Currency from "../../components/Currency";
import UserSelect from "../../components/UserSelect";
import TextArea from "../../components/TextArea";
import MediaCarousel from "../../components/MediaCarousel";
import Select from "../../components/Select";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";

// Services
import ReimbursementService from "../../services/ReimbursementService";
import { hasPermission } from "../../services/UserRolePermissionService";
import StatusService from "../../services/StatusService";
import Url from "../../lib/Url";

export const ReimbursementTab = {
  SUMMARY: "Summary",
  HISTORY: "History",
};

const reimbursementDetailPage = (props) => {
  let { history } = props;
  const [detail, setDetail] = useState({});
  const [isReimbursementDeleteModel, setDeleteReimbursementModal] =
    useState(false);
  const [activeTab, setActiveTab] = useState(Url.GetParam("tab") ? Url.GetParam("tab") : ReimbursementTab.SUMMARY);
  const [isEdit, setIsEdit] = useState(false);
  const [date, setDate] = useState();
  const [userList, setUserList] = useState([]);
  const [statusValue, setStatusValue] = useState(null);
  const [description, setDescription] = useState(null);
  const [amount, setAmount] = useState(null);
  const [owner, setOwner] = useState(null);
  const [approver, setApprover] = useState(null);
  const [statusList, setStatusList] = useState([]);

  let dispatch = useDispatch();

  const id = props.match.params.id;

  let showHistory = hasPermission(Permission.REIMBURSEMENT_HISTORY_VIEW);

  let hasDeletePermission = hasPermission(Permission.REIMBURSEMENT_DELETE);

  let hasEditPermission = hasPermission(Permission.REIMBURSEMENT_EDIT);

  useEffect(() => {
    getReimbursementDetail();
    getStatus();
  }, []);

  const actionsMenuList = [];

  if (hasDeletePermission) {
    actionsMenuList.push({
      value: "Delete",
      label: "Delete",
    });
  }

  if (hasEditPermission) {
    actionsMenuList.unshift({
      value: "Edit",
      label: "Edit",
    });
  }

  let getReimbursementDetail = async () => {
    let data = await ReimbursementService.get(props?.match?.params?.id);
    setDetail(data);
  };

  const getStatus = async () => {
    const status = await StatusService.getOption(ObjectName.REIMBURSEMENT);
    setStatusList(status);
  };

  const handleActionChange = (e) => {
    if (e === "Delete") {
      setDeleteReimbursementModal(true);
    }

    if (e === "Edit") {
      setIsEdit(true);
    }
  };

  const initialValues = {
    date: date ? date : detail ? detail?.date : "",
    amount: amount ? amount : detail ? detail?.amount : "",
    owner: owner
      ? userList && userList.find((data) => data.id == owner)
      : (userList && userList.find((data) => data.id == detail?.ownerId)) || "",
    approver: approver
      ? userList && userList.find((data) => data.id == approver)
      : (userList && userList.find((data) => data.id == detail?.approverId)) ||
      "",
    description: description ? description : detail ? detail?.description : "",
    status: statusValue
      ? {
        label: statusValue?.label,
        value: statusValue?.value,
      }
      : detail
        ? {
          label: detail?.statusName,
          value: detail?.statusValue,
        }
        : "",
  };

  const handleReimbursement = async (values) => {
    const updateData = new FormData();
    updateData.append("date", values?.date);
    updateData.append("amount", values?.amount);
    updateData.append("owner", values?.owner?.id ? values?.owner?.id : "");
    updateData.append(
      "approver",
      values?.approver?.id ? values?.approver?.id : ""
    );
    updateData.append(
      "description",
      values?.description ? values?.description : ""
    );
    updateData.append("status", values && values?.status?.value);

    dispatch(
      await ReimbursementService.update(
        props?.match?.params?.id,
        updateData,
        (res) => {
          getReimbursementDetail();
          setIsEdit(false);
        }
      )
    );
  };

  const closeDeleteModal = () => {
    setDeleteReimbursementModal(false);
  };

  const handleReimbursementDelete = async () => {
    dispatch(
      await ReimbursementService.delete(detail.id, () => {
        history.goBack();
      })
    );
  };

  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    { label: "Reimbursement", link: "/reimbursement" },
    { label: "Reimbursement Detail", link: "" },
  ];

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  const _handleTabChange = (tab) => {
    props.history.push(`?tab=${tab}`);
  };

  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleDescriptionChange = (e) => {
    let value = e?.target?.value;
    setDescription(value);
  };

  const handleStatusChange = (e) => {
    let value = e?.values?.status;
    setStatusValue(value);
  };

  const handleAmountChange = (e) => {
    let value = e?.target?.value;
    setAmount(value);
  };

  const handleOwnerChange = (e) => {
    let value = e?.id;
    setOwner(value);
  };

  const handleApproverChange = (e) => {
    let value = e?.id;
    setApprover(value);
  };

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <DeleteModal
        isOpen={isReimbursementDeleteModel}
        title="Delete Reimbursement"
        label={`${detail?.id}`}
        toggle={closeDeleteModal}
        deleteFunction={handleReimbursementDelete}
      />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Reimbursement Detail Page" />
        {actionsMenuList && actionsMenuList.length > 0 && (
          <div className="d-flex">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        )}
      </div>

      <Nav tabs className="admin-tabs mt-2">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === ReimbursementTab.SUMMARY,
            })}
            onClick={() => {
              toggle(ReimbursementTab.SUMMARY);
              _handleTabChange(ReimbursementTab.SUMMARY);
            }}
          >
            {ReimbursementTab.SUMMARY}
          </NavLink>
        </NavItem>
        {showHistory && (
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === ReimbursementTab.HISTORY,
              })}
              onClick={() => {
                toggle(ReimbursementTab.HISTORY);
                _handleTabChange(ReimbursementTab.HISTORY);
              }}
            >
              {ReimbursementTab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      {activeTab === ReimbursementTab.SUMMARY && (
        <TabPane>
          <div className="card card-body">
            <Form
              initialValues={initialValues}
              enableReinitialize
              onSubmit={handleReimbursement}
            >
              <div className="row">
                <div className="col-lg-7">
                  <DateSelector
                    name="date"
                    label="Date"
                    placeholder="Date"
                    minWidth="160px"
                    required
                    onChange={handleDateChange}
                    disabled={!isEdit}
                  />

                  <Currency
                    name="amount"
                    label="Amount"
                    placeholder="Amount"
                    required
                    onChange={handleAmountChange}
                    disabled={!isEdit}
                  />

                  <UserSelect
                    name="owner"
                    label="Owner"
                    selectedUserId={detail?.ownerId ? detail?.ownerId : null}
                    isDisabled={!isEdit}
                    showUserDetailsPageLink={detail?.ownerId ? true : false}
                    userId={detail?.ownerId}
                    handleUserChange={handleOwnerChange}
                    userList={setUserList}
                    required
                  />

                  <UserSelect
                    name="approver"
                    label="Approver"
                    selectedUserId={
                      detail?.approverId ? detail?.approverId : null
                    }
                    isDisabled={!isEdit}
                    showUserDetailsPageLink={detail?.approverId ? true : false}
                    userId={detail?.approverId}
                    handleUserChange={handleApproverChange}
                    userList={setUserList}
                    required
                  />

                  <Select
                    name="status"
                    label="Status"
                    placeholder="Select Status"
                    onInputChange={handleStatusChange}
                    options={statusList}
                    isDisabled={!isEdit}
                    required
                  />

                  <TextArea
                    name="description"
                    label="Description"
                    placeholder="Enter escription..."
                    onChange={handleDescriptionChange}
                    disabled={!isEdit}
                  />
                  <div>
                    <HorizontalSpace bottom="2">
                      {isEdit && (
                        <>
                          <SaveButton />
                          <CancelButton
                            onClick={() => {
                              history.push("/reimbursement");
                            }}
                          />
                        </>
                      )}
                    </HorizontalSpace>
                  </div>
                </div>
                <div className="col-lg-5">
                  <MediaCarousel
                    showCarasoul
                    objectName={ObjectName.REIMBURSEMENT}
                    objectId={id}
                    history={history}
                    attachmentsList={true}
                    Attachments={"Attachments"}
                    editable={!isEdit}
                  />
                </div>
              </div>
            </Form>
          </div>
        </TabPane>
      )}
      {activeTab === ReimbursementTab.HISTORY && (
        <ActivityList
          id={props?.match?.params?.id}
          objectId={props?.match?.params?.id}
          object_name={ObjectName.REIMBURSEMENT}
          history={history}
        />
      )}
    </div>
  );
};

export default reimbursementDetailPage;
