/**
 * Service dependencies
 */
import { apiClient } from "../apiClient";
import { endpoints } from "../api/endPoints";
import ArrayList from "../lib/ArrayList";
import Url from "../lib/Url";
import { isBadRequest, SUCCESS_RESPONSE } from "../lib/Http";
import Toast from "../components/Toast";




class StoreService {

  static get = async (storeId) => {
    const response = await apiClient.get(`${endpoints().locationAPI}/${storeId}`);
    return response;
  }


  static search = async (callback, params) => {
   
    let queryString= await ArrayList.toQueryString(params);
    let response =await Url.get(`${endpoints().locationAPI}/search`,queryString);
    let storeData = [];
    let values = response.data.data;
    for (let i in values) {
      storeData.push({
        id: values[i].id,
        label: values[i].name,
        value: values[i].id,
        ...values[i]
      })
    }
    return callback({
      storeData,
      totalCount: response?.data?.totalCount,
      pageSize: response?.data?.pageSize
  });

  }

  static list = async (callback, params) => {
   
    let queryString= await ArrayList.toQueryString(params);
    let response = await Url.get(`${endpoints().locationAPI}/list`,queryString);
    let storeData = [];
    let values = response.data.data
    for (let i in values) {
      storeData.push({
        id: values[i].id,
        label: values[i].name,
        value: values[i].id,
        allowedShift: values[i]?.allowedShift
      })
    }
    return callback(storeData);

  }

  static delete = async (storeId) => {
    const { data } = await apiClient.post(`${endpoints().store}/${storeId}`);
    return data;
  }

  static updateOrder = async (data) => {
    apiClient.put(`${endpoints().locationAPI}/order`, data)
      .then((res) => {
        if (res.status == SUCCESS_RESPONSE) {
          Toast.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (isBadRequest(err)) {
          let errorMessage;
          const errorRequest = err.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
        }
      });
  }
}


export default StoreService;
