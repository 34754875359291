import React from "react";
import DateTime from "../../../lib/DateTime";
import Currency from "../../../lib/Currency";
import "../style.scss";

const OrderStatementDownload = (props) => {
    const data = props?.orderInvoiceDetails ? props?.orderInvoiceDetails : "";

    const calculateProductAmount = (item) => {
        let Amount = item?.sale_price * item?.quantity;
        return Amount;
    };
    const overallTotalAmount = data.reduce((acc, item) => {
        return acc + item.productDetails.reduce((acc, product) => {
            return acc + calculateProductAmount(product);
        }, 0);
    }, 0);


    const styles = {
        my_table: {
            width: "110mm",
            fontFamily: "Helvetica",
            borderCollapse: "collapse",
            fontSize: "8px",
        },
        tableSize: {
            fontSize: "9px",
        },
        tableBody: { minHeight: "100px" },
        table: {
            width: "100%",
            border: "1px solid #ccc",
        },
        tableHeading: {
            background: "#f2f2f2",
            fontWeight: "bold",
            textAlign: "center",
        },
        tableRow: {
            border: "1px solid #ddd",
        },
        tableDataId: {
            textAlign: "center",
            width: "10%",
        },
        tableData: {
            textAlign: "center",
        },
        totalAmount: {
            fontWeight: "bold",
            textAlign: 'left',
            marginLeft: "30px",
            fontSize: "10px",
        },
        productName: {
            minWidth: "200px",
            maxWidth: "300px",
            textAlign: "left",
            fontWeight: "bold",
            wordWrap: "break-word",
        },
        brandName: {
            fontWeight: "bold",
        },
        productDetails: {
            display: "flex",
            alignItems: "center",
        },
        productInfo: {
            marginLeft: "10px",
        },
        dateCell: {
            whiteSpace: "nowrap",
        },
        pageBreakAfter: {
            pageBreakAfter: "always",
        },
    };
    
    // Create a map to group product details by order_id
    const productMap = {};
    if (data && data.length > 0) {
        data.forEach(item => {
            if (item && item.orderDetails) {
                const orderId = item.orderDetails.id;
                const orderDate = item.orderDetails.date;
                if (!productMap[orderId]) {
                    productMap[orderId] = [];
                }
                if (item.productDetails) {
                    item.productDetails.forEach(product => {
                        productMap[orderId].push({ ...product, orderDate });
                    });
                }
            }
        });
    }
    Object.keys(productMap).forEach(orderId => {
        productMap[orderId].sort((a, b) => new Date(a.orderDate) - new Date(b.orderDate));
    });
    const rowsPerPage = 25;
    const totalRows = data?.length ?? 0;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const lastPageRows = totalRows % rowsPerPage || rowsPerPage;
    const itemsPerPage = totalPages === 1 ? totalRows : rowsPerPage;
    return (
        <div style={styles.my_table}>
            <div className="row m-1 p-0">

                <div className="col-6">
                    <img src={props.companyLogo}/>
                    <h4 className="text-dark mb-1">{props.companyName}</h4>
                </div>
            </div>
            {Array.from(Array(totalPages).keys()).map((page) => (

                    <div key={page} className="table-responsive-sm mt-0 pe-1 ps-4" style={styles.tableResponsive}>
                        <table className="small table table-bordered mt-0" style={styles.table}>
                            <thead>
                                <tr className="text-center">
                                    <th className="m-0 p-2" style={styles.tableHeading}><b>Order#</b></th>
                                    <th className="m-0 p-2" style={styles.tableHeading}><b>Order Date</b></th>
                                    <th className="m-0 p-2" style={styles.tableHeading}><b>Product</b></th>
                                    <th className="m-0 p-2" style={styles.tableHeading}><b>Price(INR)</b></th>
                                    <th className="m-0 p-2" style={styles.tableHeading}><b>Quantity</b></th>
                                    <th className="m-0 p-2" style={styles.tableHeading}><b>Amount(INR)</b></th>
                                </tr>
                            </thead>
                            <tbody className="p-0 m-0" style={{ ...styles.tableBody, margin: 0, padding: 0 }}>
                            {Object.keys(productMap).slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((orderId, index) => (
                                    <React.Fragment key={index}>
                                        {productMap[orderId].map((product, productIndex) => (
                                            <tr key={`${orderId}-${productIndex}`} style={styles.tableRow}  className="p-0 m-0">
                                                {productIndex === 0 ? (
                                                    <td  className="p-0 m-0" style={styles.tableDataId} rowSpan={productMap[orderId].length}>{orderId}</td>
                                                ) : null}
                                                <td  className="p-1 m-1" style={{ ...styles.tableData, ...styles.dateCell }}>
                                                    {DateTime.getDateTimeByUserProfileTimezone(product.orderDate)}
                                                </td>
                                                <td  className="p-0 m-0">
                                                    <div style={styles.productInfo}>
                                                        {product.productBrandName && (
                                                            <b style={styles.brandName}>{`${product.productBrandName}`}</b>
                                                        )}
                                                        {product.product_name && (
                                                            <p style={styles.productName}>
                                                                {product.product_name}
                                                                {`${product.size ? "," + " " + product.size : ""}`}
                                                                {`${product.unit ? " " + product.unit : ""}`}
                                                            </p>
                                                        )}
                                                    </div>
                                                </td>
                                                <td  className="p-0 m-0" style={styles.tableData}>
                                                    {parseFloat(product.sale_price).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                                                </td>
                                                <td  className="p-0 m-0" style={styles.tableData}>
                                                    {product.quantity || ""}
                                                </td>
                                                <td  className="p-0 m-0" style={styles.tableData}>
                                                    {parseFloat(calculateProductAmount(product)).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>

                        </table>
                    </div>
            ))}
            <div style={{ ...styles.totalAmount }}>Total Amount(INR): {parseFloat(overallTotalAmount).toLocaleString('en-US', { minimumFractionDigits: 2 })}
            </div>
        </div>

    );
};
export default OrderStatementDownload