import React, { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Cookie from "../lib/Helper";
import { COOKIE_SESSION_TOKEN } from "../lib/Cookie";
import ArrayList from "../lib/ArrayList";
import { isVideoURL } from "./static/imageCarousel";

const CustomAttachment = ({ mediaArray = [], isOpen = false, setIsOpen }) => {
    const [mediaSlides, setMediaSlides] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => setIsOpen(false);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    useEffect(() => {
        const fetchMedia = async () => {
            if (!ArrayList.isArray(mediaArray) || mediaArray.length === 0) {
                console.warn("Invalid media array");
                setMediaSlides([]);
                return;
            }

            setIsLoading(true);
            const slides = [];

            try {
                for (const media of mediaArray) {
                    const { media_url, file_name,file_path } = media;

                    const response = await fetch(media_url, {
                        headers: {
                            Authorization: Cookie.get(COOKIE_SESSION_TOKEN),
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`Failed to fetch media from ${media_url}`);
                    }

                    const blob = await response.blob();
                    if (isVideoURL(file_name || file_path)) {
                        slides.push({
                            type: "video",
                            sources: [{ src: URL.createObjectURL(blob) }],
                            mainUrl: media_url,
                            width: 1920,
                            height: 1080,
                        });
                    } else {
                        slides.push({
                            type: "image",
                            src: URL.createObjectURL(blob),
                            mainUrl: media_url,
                            width: 3840,
                            height: 5760,
                        });
                    }
                }

                setMediaSlides(slides);
            } catch (error) {
                console.error("Error fetching media:", error);
                setMediaSlides([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMedia();

        return () => {
            mediaSlides.forEach((item) => {
                if (item?.type !== "video") {
                    URL.revokeObjectURL(item.src);
                }
            });
        };
    }, [mediaArray]);

    return (
        <>
            {isLoading && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                    }}
                >
                    <div
                        className="spinner"
                        style={{
                            width: "50px",
                            height: "50px",
                            border: "5px solid rgba(255, 255, 255, 0.3)",
                            borderTop: "5px solid white",
                            borderRadius: "50%",
                            animation: "spin 1s linear infinite",
                        }}
                    ></div>
                </div>
            )}
            {!isLoading && (
                <Lightbox
                    open={isOpen}
                    close={handleClose}
                    slides={mediaSlides}
                    plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
                    zoom={{
                        maxZoomPixelRatio: 10,
                        scrollToZoom: true,
                        zoomInMultiplier: 1.5,
                    }}
                    render={{
                        slide: (props) => {
                            if (props?.slide?.type === "image") {
                                return (
                                    <div className="react-lightbox-slide">
                                        <a href={props?.slide?.mainUrl} target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={props?.slide?.src}
                                                alt={props?.slide?.caption || ""}
                                                style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        </a>
                                    </div>
                                );
                            }

                            if (props?.slide?.type === "video") {
                                return (
                                    <div className="react-lightbox-slide">
                                        <a href={props?.slide?.mainUrl} target="_blank" rel="noopener noreferrer">
                                            <video
                                                controls
                                                src={props?.slide?.sources[0]?.src}
                                                width="100%"
                                                height="auto"
                                                style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                }}
                                            />
                                        </a>
                                    </div>
                                );
                            }

                            return <div />;
                        },
                    }}
                />
            )}
            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}
            </style>
        </>
    );
};

export default CustomAttachment;
