import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";

// Components
import MoreDropdown from "../../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import ProductCard from "../../product/components/productCard";
import DeleteModal from "../../../components/DeleteModal";

// Actions
import { updateProduct } from "../../../actions/storeProduct";

// API
import { endpoints } from "../../../api/endPoints";

const CategoryProductForm = (props) => {
  const {
    categoryId,
    categoryName,
    categoryStatus,
    handleBulkSelect,
    selectedCheckBox,
  } = props;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  const dispatch = useDispatch();

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const DeleteCategoryProduct = () => {
    try {
      if (selectedProduct) {
        let params = {
          categoryId: categoryId,
        };

        dispatch(
          updateProduct(selectedProduct.id, "", params, props.getProductList)
        );
        closeDeleteModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div>
        <DeleteModal
          isOpen={openDeleteModal}
          label={selectedProduct.product_name}
          toggle={closeDeleteModal}
          title="Delete Product"
          deleteFunction={DeleteCategoryProduct}
        />
        {/* Stock Product Entry List */}
        <div className="mt-4">
          <ReduxTable
            id="categoryProduct"
            bulkSelect
            showHeader
            onBulkSelect={handleBulkSelect}
            selectedCheckBox={selectedCheckBox}
            searchPlaceholder="Search"
            paramsToUrl={true}
            history={props.history}
            apiURL={`${endpoints().categoryAPI}/productList/search`}
            newTableHeading
            sortByDropdown
            params={{
              categoryId: categoryId,
              categoryName: categoryName,
              categoryStatus: categoryStatus,
            }}
          >
            <ReduxColumn
              field="product_name"
              sortBy="name"
              width="400px"
              minWidth="310px"
              maxWidth="310px"
              className="ellipsis text-wrap"
              type="link"
              isClickable="false"
              renderField={(row) => (
                <>
                  <ProductCard
                    productImageIcon
                    square
                    productName={row.product_name}
                    brandName={row.brand_name}
                    size={row.size != "null" ? row.size : ""}
                    unit={row.unit != "null" ? row.unit : ""}
                    salePrice={row.sale_price != "null" ? row.sale_price : ""}
                    mrp={row.mrp != "null" ? row.mrp : ""}
                    url={row.featured_media_url}
                    id={row.product_id}
                    brand_id={row.brand_id}
                  />
                </>
              )}
            >
              {" "}
              Product
            </ReduxColumn>
            <ReduxColumn
              field="status"
              width={"130px"}
              minWidth="130px"
              maxWidth="130px"
              sortBy="status"
              renderField={(row) => (
                <div
                  className={`status-input text-center rounded text-white text-uppercase mx-auto ${row.status && row.status === "Active"
                    ? "bg-success"
                    : row.status === "Draft"
                      ? "bg-dark bg-opacity-50"
                      : row.status === "Archived"
                        ? "bg-primary"
                        : ""
                    }`}
                >
                  <p>{row.status}</p>
                </div>
              )}
            >
              Status
            </ReduxColumn>
            <ReduxColumn
              field="Action"
              disableOnClick
              width="70px"
              renderField={(row) => (
                <>
                  <div className="text-center action-group-dropdown">
                    <MoreDropdown>
                      <DropdownItem
                        className="text-danger cursor-pointer"
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setSelectedProduct(row);
                        }}
                      >
                        Delete
                      </DropdownItem>
                    </MoreDropdown>
                  </div>
                </>
              )}
            >
              Action
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    </>
  );
};

export default CategoryProductForm;
