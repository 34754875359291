import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import PageTitle from "../../components/PageTitle";
import Form from "../../components/Form";
import SaveButton from "../../components/SaveButton";
import CancelButton from "../../components/CancelButton";
import DeleteModal from "../../components/DeleteModal";
import BreadCrumb from "../../components/Breadcrumb";
import AccountForm from "./components/paymentAccountForm";
import Action from "../../components/Action";

// Lib
import Url from "../../lib/Url";

// Helpers
import { paymentAccounts } from "../../helpers/AccountEntry";
import Permission from "../../helpers/Permission";

// Services
import PaymentAccountService from "../../services/PaymentAccountService";
import { hasPermission } from "../../services/UserRolePermissionService";
import { Nav, NavItem, NavLink } from "reactstrap";
import ActivityList from "../../components/ActivityList";
import ObjectName from "../../helpers/ObjectName";
import classNames from "classnames";

const Detail = (props) => {
  let hasEditPermission = hasPermission(Permission.PAYMENT_ACCOUNT_EDIT);

  let hasDeletePermission = hasPermission(Permission.PAYMENT_ACCOUNT_DELETE);

  let hasHistoryViewPermission = hasPermission(
    Permission.PAYMENT_ACCOUNT_HISTORY_VIEW
  );
  const Tab = {
    SUMMARY: "Summary",
    HISTORY: "History",
  };

  const dispatch = useDispatch();
  const { history } = props;
  const [primary, setPrimary] = useState();
  const [detail, setDetail] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editable, setEditable] = useState(true);
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : Tab.SUMMARY
  );
  const [paymentAccountNumberValue, setPaymentAccountNumberValue] =
    useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [paymentAccountValue, setPaymentAccountValue] = useState(null);
  const [ifscValue, setIfscValue] = useState("");
  const [bankNameValue, setBankNameValue] = useState("");
  const [paymentAccountNameValue, setPaymentAccountNameValue] = useState(null);

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/accountDashboard" },
    { label: "PaymentAccount", link: "/paymentAccount" },
    { label: "Payment Account Detail", link: "" },
  ];

  // sales entry id
  const id = props?.match?.params?.id;

  // Get sales Details
  const getDetails = async () => {
    const response = await PaymentAccountService.get(id);
    if (response && response.data) {
      setDetail(() => response.data);
      setPrimary(() => response.data.primary);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  const deleteAccount = async () => {
    const id = props?.match?.params?.id;
    dispatch(await PaymentAccountService.delete(id, history, {}));
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  const handlePaymentAccountNumberChange = (e) => {
    let value = e?.target?.value;
    setPaymentAccountNumberValue(value);
  };

  const handelDescriptionChange = (e) => {
    let value = e?.target?.value;
    setDescriptionValue(value);
  };

  const handelpaymentAccountsChange = (e) => {
    let value = e;
    setPaymentAccountValue(value);
  };

  const handleIfscChange = (e) => {
    let value = e?.target?.value;
    setIfscValue(value);
  };

  const handleBankNameChange = (e) => {
    let value = e?.target?.value;
    setBankNameValue(value);
  };

  const handlePaymentAccountNameChange = (e) => {
    let value = e?.target?.value;
    setPaymentAccountNameValue(value);
  };

  // Handle form Submit
  const handleSubmit = async (values) => {
    try {
      const data = new FormData();
      data.append("payment_account_type", values?.payment_account_type.value);

      // date
      data.append("payment_account_name", values?.payment_account_name);

      // Shift
      data.append("payment_account_number", values?.payment_account_number);

      // Type
      data.append("bank_name", values?.bank_name);
      data.append("ifsc", values?.ifsc);
      data.append("description", values.description);
      // Amount
      data.append("primary", primary);

      await PaymentAccountService.update(id, data);
      setEditable(true);
    } catch (err) {
      console.log(err);
    }
  };

  // Form initial values
  const initialValues = {
    payment_account_type: paymentAccountValue
      ? paymentAccounts.find(
          (account) => account.value === paymentAccountValue?.value
        )
      : detail
      ? paymentAccounts.find(
          (account) => account.value === detail?.payment_account_type
        )
      : "",
    payment_account_name: paymentAccountNameValue
      ? paymentAccountNameValue
      : detail?.payment_account_name,
    payment_account_number: paymentAccountNumberValue
      ? paymentAccountNumberValue
      : detail?.payment_account_number,
    bank_name: bankNameValue
      ? bankNameValue
      : detail?.bank_name
      ? detail?.bank_name
      : "",
    ifsc: ifscValue ? ifscValue : detail?.ifsc ? detail?.ifsc : "",
    description: descriptionValue ? descriptionValue : detail?.description,
    primary: detail?.primary,
  };

  //Handle Actions Change
  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "Delete") {
      setDeleteModal(true);
    }
  };

  //Actions Menu List
  const actionsMenuList = [];

  if (hasDeletePermission)
    actionsMenuList.push({
      value: "Delete",
      label: "Delete",
    });

  if (hasEditPermission && editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  return (
    <>
      <DeleteModal
        id={detail?.id}
        label={`${detail?.payment_account_name}${
          detail?.bank_name ? ` - ${detail.bank_name}` : ""
        }`}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Account"
        deleteFunction={deleteAccount}
      />

      {/* Breadd Crumb Section */}
      <BreadCrumb list={breadcrumbList} />

      {/* Page title and Action Button */}
      <div className="d-flex mx-1 justify-content-between">
        <PageTitle label="Payment Account Detail" />
        {actionsMenuList && actionsMenuList?.length > 0 && (
          <div className="d-flex">
            <Action
              buttonLabel="Actions"
              hideCaret
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        )}
      </div>

      <Nav tabs className="admin-tabs">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tab.SUMMARY,
            })}
            onClick={() => handleTabChange(Tab.SUMMARY)}
          >
            {Tab.SUMMARY}
          </NavLink>
        </NavItem>
        {hasHistoryViewPermission && (
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => handleTabChange(Tab.HISTORY)}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>

      {/* Form */}
      {activeTab === Tab.SUMMARY && (
        <div className="card mt-3 mb-2">
          <div className="card-body ">
            <Form
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <AccountForm
                primary={primary}
                setPrimary={setPrimary}
                editable={editable}
                handlePaymentAccountNumberChange={
                  handlePaymentAccountNumberChange
                }
                handelDescriptionChange={handelDescriptionChange}
                handelpaymentAccountsChange={handelpaymentAccountsChange}
                handleIfscChange={handleIfscChange}
                handleBankNameChange={handleBankNameChange}
                handlePaymentAccountNameChange={handlePaymentAccountNameChange}
              />
              {!editable && (
                <div className="mb-4 pb-3 mb-sm-0 pb-sm-0">
                  <SaveButton label="Save" />
                  <CancelButton
                    onClick={() => props.history.push("/paymentAccount")}
                  />
                </div>
              )}
            </Form>
          </div>
        </div>
      )}
      {activeTab === Tab.HISTORY && (
        <ActivityList
          id={id}
          objectId={id}
          object_name={ObjectName.PAYMENT_ACCOUNT}
          history={history}
        />
      )}
    </>
  );
};

export default Detail;
