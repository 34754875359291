import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { isBadRequest } from "../lib/Http";

export const generateOauthToken = (callback) => {
  let apiUrl = "";
  apiUrl = `${endpoints().oauthAPI}/generateOauthToken`;

  // Save settings
  return apiClient
    .post(apiUrl)
    .then((response) => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        Toast.success(successMessage);
      }

      if (callback) {
        return callback(null, response);
      }
    })
    .catch((error) => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(error.response.data.message);
        console.error(errorMessage);
      }
      if (callback) {
        return callback(error, null);
      }
    });
};
