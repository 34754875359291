import PropTypes from "prop-types";
import React from "react";
import { EditIconAlt } from "../assets/icons";
import defaultAvatar from "../assets/img/defaultAvatar.png";
import defaultProductAvatar from "../assets/img/productIcon.png";

class Avatar extends React.Component {
  handleClick = (event) => {
    if (this.props.handleClick) {
      this.props.handleClick(event);
    }
  };

  render() {
    const {
      id,
      url,
      firstName,
      lastName,
      companyName,
      defaultUrl,
      size,
      bgColor,
      square,
      customSize,
      imageSize,
      fontSize,
      className,
      customStyle,
      allowEdit,
      productImageIcon,
      headerTextColor,
    } = this.props;

    const sizes = {
      xs: 32,
      sm: 45,
      md: 60,
      lg: 100,
      xl: 120,
      xxl: 150,
      customSize: parseInt(imageSize, 10),
    };

    const fontSizes = {
      xs: 12,
      sm: 14,
      md: 18,
      lg: 24,
      xl: 30,
      xxl: 36,
    };

    let dimension;
    if (customSize) {
      dimension = customSize;
    }

    if (!dimension && size) {
      dimension = sizes[size];
    }

    if (!dimension) {
      dimension = sizes["sm"];
    }

    const defaultStyle = {
      width: dimension,
      height: dimension,
      display: "block",
      borderRadius: square ? "0" : "50%",
      cursor: "pointer",
    };

    if (url) {
      let backgroundImage = `url(${url})`;
      if (defaultUrl) {
        backgroundImage = `${backgroundImage}, url(${defaultUrl})`;
      }

      const style = Object.assign({}, defaultStyle, {
        backgroundSize: "cover",
        backgroundRepeat: "initial",
        backgroundImage,
        backgroundPosition: "center",
      });

      return (
        <>
          <div
            id={id}
            style={style}
            className={["avatar-picture cursor-pointer"].join(" ")}
            onClick={this.handleClick}
          />
          <div>
            {allowEdit && (
              <span
                style={{
                  right: "0",
                  bottom: "0",
                  padding: "10px",
                  borderRadius: "8px",
                  color: "var(--avatar-color)",
                }}
                className="pt-5 cursor-pointer position-absolute"
              >
                <EditIconAlt style={{ verticalAlign: "bottom" }} />
              </span>
            )}
          </div>
        </>
      );
    }

    if (firstName || lastName || companyName) {
      const initial = [];

      const getValidChars = (str) =>
        str
          ?.split("")
          .filter((char) => /[a-zA-Z0-9]/.test(char))
          .slice(0, 1);

      if (firstName) {
        initial.push(...getValidChars(firstName));
      }

      // Extract initials from lastName
      if (lastName) {
        initial.push(...getValidChars(lastName));
      }

      if (initial.length === 0 && companyName) {
        initial.push(...getValidChars(companyName));
      }

      if (initial.length < 2) {
        if (firstName?.length > 1) {
          initial.push(...getValidChars(firstName.slice(1)));
        } else if (lastName?.length > 1) {
          initial.push(...getValidChars(lastName.slice(1)));
        } else if (companyName?.length > 1) {
          initial.push(...getValidChars(companyName.slice(1)));
        }
      }

      initial.length = Math.min(initial.length, 2);

      if (initial.length > 0) {
        const style = Object.assign({}, defaultStyle, {
          lineHeight: `${dimension}px`,
          backgroundColor: bgColor || "var(--avatar-bg-color)",
          color: headerTextColor || "var(--avatar-header-text-color)",
          textTransform: "uppercase",
          textAlign: "center",
          fontSize: fontSize || fontSizes[size] || 15,
          letterSpacing: 1,
        });

        return (
          <>
            <div
              id={id}
              className={customStyle}
              style={style}
              onClick={this.handleClick}
            >
              {initial.join("")}
            </div>
            <div>
              {allowEdit && (
                <span
                  style={{
                    right: "0",
                    bottom: "0",
                    padding: "10px",
                    borderRadius: "8px",
                    color: headerTextColor || "var(--avatar-header-text-color)",
                  }}
                  className="pt-5 cursor-pointer position-absolute"
                >
                  <EditIconAlt style={{ verticalAlign: "bottom" }} />
                </span>
              )}
            </div>
          </>
        );
      }
    }

    if (defaultUrl) {
      const style = Object.assign({}, defaultStyle, {
        backgroundSize: "cover",
        backgroundImage: `url(${defaultUrl})`,
        margin: "auto",
      });

      return (
        <div
          id={id}
          style={style}
          onClick={this.handleClick}
        />
      );
    }

    const style = Object.assign({}, defaultStyle, {
      backgroundSize: "cover",
      backgroundImage: `url(${productImageIcon ? defaultProductAvatar : defaultAvatar})`,
    });

    return (
      <div
        id={id}
        className={className}
        style={style}
        onClick={this.handleClick}
      />
    );
  }
}

Avatar.propTypes = {
  url: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  defaultUrl: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  customSize: PropTypes.number,
  imageSize: PropTypes.string,
  fontSize: PropTypes.number,
  bgColor: PropTypes.string,
  square: PropTypes.bool,
  customStyle: PropTypes.string,
  allowEdit: PropTypes.bool,
  productImageIcon: PropTypes.bool,
  headerTextColor: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Avatar;
