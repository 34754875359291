import React from "react";
import ChannelMessage from "./ChannelMessage";
import DirectMessage from "./DirectMessage";

const MessageBox = (props) => {
  let {
    params
  } = props;
  


  return (
    <div className= {'bg-white w-100 vh-100 max-h-fit'}>
      {/* Group chat */}
      {params && params?.path == "group" && (
        <ChannelMessage {...props}/>
      )}

      {/* Direct Chat */}
      {params && params?.path == "user" && <DirectMessage {...props} />}
    </div>
  );
};

export default MessageBox;
