import React, { useEffect, useState } from "react";

// Components
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import SingleCheckbox from "../../components/SingleCheckbox";
import TimeZoneSelect from "../../components/TimeZoneSelect";
import DefaultContent from "../../components/content/defaultContent";
import UserSelect from "../../components/UserSelect";

// Lib
import ArrayList from "../../lib/ArrayList";
import DateTime from "../../lib/DateTime";
import { getKeyValueByObject } from "../../lib/Helper";

// Services
import { getCompanySettings, saveSetting } from "../../services/SettingService";

// Helpers
import { Setting } from "../../helpers/Setting";

const General = () => {
  const [settings, setSettings] = useState({});
  const [timeZones, setTimeZone] = useState([]);
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState();
  const [defaultTimeZone, setDefaultTimeZone] = useState();

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    setSettings(settingData);
  };

  useEffect(() => {
    getSettings();
    getTimeZones();
  }, []);

  const initialValues = {
    show_store_selection_on_login:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.SHOW_STORE_SELECTION_ON_LOGIN) ==
        "true"
        ? true
        : false,
    device_approval_required:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(settings, Setting.DEVICE_APPROVAL_REQUIRED) == "true"
        ? true
        : false,
    user_default_time_zone: defaultTimeZone
      ? defaultTimeZone
      : defaultTimeZone === null
      ? ""
      : settings &&
        ArrayList.isNotEmpty(settings) &&
        timeZones.find(
          (data) =>
            data.value ==
            getKeyValueByObject(settings, Setting.USER_DEFAULT_TIME_ZONE)
        ),

    system_user: user
      ? user
      : user === null
      ? ""
      : settings && ArrayList.isNotEmpty(settings)
      ? userList.find(
          (data) =>
            data.id == getKeyValueByObject(settings, Setting.SYSTEM_USER)
        )
      : null,
  };

  const handleCheckBoxValue = (values) => {
    const data = new FormData();

    if (values && values.show_store_selection_on_login !== undefined) {
      data.append(
        Setting.SHOW_STORE_SELECTION_ON_LOGIN,
        values.show_store_selection_on_login
      );
    }

    // Save settings
    saveSetting(data, null, () => {
      getSettings();
    });
  };

  const handleMobileCheckBoxValue = (values) => {
    const data = new FormData();

    if (values && values.device_approval_required !== undefined) {
      data.append(
        Setting.DEVICE_APPROVAL_REQUIRED,
        values.device_approval_required
      );
    }

    // Save settings
    saveSetting(data, null, () => {
      getSettings();
    });
  };

  const handleSubmit = (values) => {
    const data = new FormData();

    if (values && values.show_store_selection_on_login !== undefined) {
      data.append(
        Setting.SHOW_STORE_SELECTION_ON_LOGIN,
        values.show_store_selection_on_login
      );
    }

    if (values && values.user_default_time_zone !== undefined) {
      data.append(
        Setting.USER_DEFAULT_TIME_ZONE,
        values && values.user_default_time_zone
          ? values.user_default_time_zone.value
          : ""
      );
    }

    if (values && values.system_user !== undefined) {
      data.append(
        Setting.SYSTEM_USER,
        values && values.system_user ? values.system_user.id : ""
      );
    }

    if (values && values.device_approval_required !== undefined) {
      data.append(
        Setting.DEVICE_APPROVAL_REQUIRED,
        values.device_approval_required
      );
    }
    // Save settings
    saveSetting(data, null, () => {
      getSettings();
    });
  };

  const getTimeZones = () => {
    let timeZones = DateTime.getTimeZones();

    let timeZoneList = new Array();

    for (let i = 0; i < timeZones.length; i++) {
      timeZoneList.push({
        label: timeZones[i],
        value: timeZones[i],
      });
    }

    setTimeZone(timeZoneList);
  };

  const handleUserChange = (e) => {
    let value = e;
    setUser(value);
  };

  const handleTimeZoneChange = (values) => {
    let value = values;
    setDefaultTimeZone(value);
  };


  let systemUser = initialValues.system_user;

  return (
    <>
      <PageTitle label="General" />
      <DefaultContent>
        <Form
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <div className="row field-wrapper">
            <div className="col-lg-6 col-sm-6">
              <TimeZoneSelect
                name="user_default_time_zone"
                label="Default Time Zone"
                handleChange={handleTimeZoneChange}
              />
            </div>
          </div>
          <div className="row field-wrapper">
            <div className="col-lg-6 col-sm-6">
              <UserSelect
                name="system_user"
                label="System User"
                showUserDetailsPageLink={systemUser?.id ? true : false}
                userId={systemUser?.id}
                handleUserChange={handleUserChange}
                userList={setUserList}
              />
            </div>
          </div>
          <div className="row field-wrapper mt-3">
            <div className="col-lg-12 col-sm-12">
              <SingleCheckbox
                name={Setting.SHOW_STORE_SELECTION_ON_LOGIN}
                label={"After Login Show Location Selection"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
          </div>
          <div className="row field-wrapper mt-3">
            <div className="col-lg-12 col-sm-12">
              <SingleCheckbox
                name={Setting.DEVICE_APPROVAL_REQUIRED}
                label={"Device Approval Required"}
                handleOnChangeSubmit={(value, name) =>
                  handleMobileCheckBoxValue(value, name)
                }
              />
            </div>
          </div>
          <SaveButton label={"Save"} />
        </Form>
      </DefaultContent>
    </>
  );
};

export default General;
