import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Components
import Action from "../../components/Action";
import ActivityList from "../../components/ActivityList";
import BreadCrumb from "../../components/Breadcrumb";
import CancelButton from "../../components/CancelButton";
import Comment from "../../components/comment";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import StatusComponent from "../../components/Status";
import UserCard from "../../components/UserCard";
import UpdateForm from "./components/UpdateForm";
import Spinner from "../../components/Spinner";

// Services
import ActivityService from "../../services/ActivityService";
import ActivityTypeService from "../../services/ActivityTypeService";
import StatusService from "../../services/StatusService";
import { hasPermission } from "../../services/UserRolePermissionService";
import UserService from "../../services/UserService";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";
import Urls from "../../helpers/Url";

// Lib
import ArrayList from "../../lib/ArrayList";

const ActivityDetail = (props) => {
  const Tab = {
    SUMMARY: "Summary",
    COMMENTS: "Comments",
    HISTORY: "History",
  };

  // Use States
  const [deleteModal, setDeleteModal] = useState(false);
  const [activityDetail, setActivityDetail] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [editable, setEditable] = useState(true);
  const [locationList, setLocationList] = useState([]);
  const [activeTab, setActiveTab] = useState(Tab.SUMMARY);
  const [isLoading, setIsLoading] = useState(false);

  const { history } = props;

  const hasDeletePermission = hasPermission(Permission.ACTIVITY_DELETE);

  const hasEditPermission = hasPermission(Permission.ACTIVITY_EDIT);

  const hasHistoryViewPermission = hasPermission(Permission.ACTIVITY_HISTORY_VIEW);

  // Use Dispatch
  const dispatch = useDispatch();

  // UseEffect
  useEffect(() => {
    getActivityDetail();
    getUsersList();
    getStatusList();
    getActivityList();
  }, []);

  //   Get Status List

  const getStatusList = async () => {
    const status = await StatusService.list({
      objectName: ObjectName.ACTIVITY,
    });

    const statusList = ArrayList.isArray(status?.data?.data)
      ? status.data.data.map(({ label, value, group, id }) => ({
        label,
        value,
        group,
        id,
      }))
      : [];
    setStatusList(statusList);
  };

  //Get Activity Details
  const getActivityList = async () => {
    let response = await ActivityTypeService.search();
    let data = response && response.data && response.data.data;
    // Split the activity options
    if (data && data.length > 0) {
      const activityList = [];
      data
        .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
        .forEach((activityData) => {
          activityList.push({
            value: activityData.id,
            label: activityData.name,
          });
        });
      // Set Activity Options List in state
      setActivityList(activityList);
    }

    return data;
  };

  const getUserName = (media_url, firstName, lastName) => {
    return (
      <div className="d-flex">
        <UserCard
          id="avatar"
          firstName={firstName}
          lastName={lastName}
          url={media_url}
        />
      </div>
    );
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const tabToggle = (tab) => {
    setActiveTab(tab);
  };

  // Getting Users list for user dropdown
  const getUsersList = async () => {
    const response = await UserService.list();
    const userList = response && response.data;
    const data = [];
    userList &&
      userList.length > 0 &&
      userList.forEach((list) => {
        data.push({
          label: getUserName(list.media_url, list.first_name, list.last_name),
          value: list.first_name,
          id: list.id,
        });
      });
    setUsersList(data);
  };

  // Breadcrumb list
  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    { label: "Activity", link: Urls.ACTIVITY_LIST },
    { label: "Activity Detail", link: "" },
  ];

  const deleteActivity = async (id) => {
    dispatch(await ActivityService.delete(id, setDeleteModal, {}));
    props.history.push("/activity");
  };

  const getActivityDetail = async () => {
    let id = props.match.params.id;
    let response = await ActivityService.get(id);
    if (response && response.data) {
      setActivityDetail(response.data);
    }
  };

  const onStatusChange = (value) => {
    let id = props.match.params.id;
    let data = {};
    data.status = value;
    dispatch(
      ActivityService.updateStatus(id, data, {}, (res) => {
        if (res) {
          setIsLoading(true);
          getActivityDetail();
          setIsLoading(false);
        }
      })
    );
  };

  // Handle on update
  const handleUpdate = async (id, values) => {
    let data = new FormData();

    data.append(
      "activity_type_id",
      values.activity_type && values.activity_type.value
    );

    data.append("description", values.description ? values.description : "");

    if (values.date) {
      data.append("date", values.date ? values.date : "");
    }

    if (values?.end_date) {
      data.append("end_date", values?.end_date ? values?.end_date : "");
    }

    if (values.start_date) {
      data.append("start_date", values.start_date ? values.start_date : "");
    }

    data.append("notes", values.notes ? values.notes : "");
    data.append("status", values.status ? values.status.value : "");
    data.append("owner", values.owner ? values.owner.id : "");
    data.append("location_id", values.location_id ? values.location_id.id : "");
    dispatch(await ActivityService.update(id, data, {}));
  };

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  if (hasEditPermission && editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  // Initial Values
  const initialValues = {
    date: activityDetail && activityDetail.date,
    owner:
      activityDetail &&
      usersList &&
      usersList.find((data) => data.id == activityDetail.user_id),
    activity_type: activityList.find(
      (data) => data.value == activityDetail.activity_type_id
    ),
    status:
      activityDetail &&
      statusList &&
      statusList.find((data) => data.value == activityDetail.status),
    started_at:
      activityDetail && activityDetail.started_at
        ? activityDetail.started_at
        : "",
    completed_at: activityDetail && activityDetail.completed_at,
    notes: activityDetail && activityDetail.notes,
    location_id:
      ArrayList.isArray(locationList) &&
      locationList.find((data) => data?.id == activityDetail?.location_id),
    description: activityDetail && activityDetail?.description,
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex mx-1 justify-content-between mb-3">
        <PageTitle
          label={`Activity Detail #${activityDetail && activityDetail?.activityId
            }`}
        />
        <div className="d-flex">
          <div className="mx-2">
            <StatusComponent
              objectName={ObjectName.ACTIVITY}
              handleChange={onStatusChange}
              buttonLabel={activityDetail?.statusName}
              currentStatusId={activityDetail?.status}
              color={activityDetail?.colorCode}
            />
          </div>
          {hasDeletePermission && (
            <div className="d-flex">
              <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Activity"
        id={props.match.params.id}
        label={`Activity# ${activityDetail.activityId} ${activityDetail.description ? `- ${activityDetail.description}` : ""
          }`}
        deleteFunction={deleteActivity}
      />

      <div>
        <Nav tabs className="admin-tabs">
          {/* Detail Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.SUMMARY,
              })}
              onClick={() => {
                tabToggle(Tab.SUMMARY);
                handleTabChange(Tab.SUMMARY);
              }}
            >
              {Tab.SUMMARY}
            </NavLink>
          </NavItem>

          {/* Comments Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.COMMENTS,
              })}
              onClick={() => {
                tabToggle(Tab.COMMENTS);
                handleTabChange(Tab.COMMENTS);
              }}
            >
              {Tab.COMMENTS}
            </NavLink>
          </NavItem>

          {/* History Tab */}

          {hasHistoryViewPermission && (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tab.HISTORY,
                })}
                onClick={() => {
                  tabToggle(Tab.HISTORY);
                  handleTabChange(Tab.HISTORY);
                }}
              >
                {Tab.HISTORY}
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </div>

      <TabContent activeTab={activeTab}>
        {activeTab == Tab.SUMMARY && (
          <TabPane tabId={Tab.SUMMARY}>
            <div className="card card-body mb-4 pb-4">
              <Form
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => {
                  handleUpdate(props.match.params.id, values);
                  setEditable(true);
                }}
              >
                <UpdateForm
                  activityDetail={activityDetail}
                  id={props.match.params.id}
                  history={props.history}
                  usersList={usersList}
                  activityList={activityList}
                  statusList={statusList}
                  showUserDetailsPageLink={
                    activityDetail?.user_id ? true : false
                  }
                  userId={activityDetail?.user_id}
                  editable={editable}
                  setLocationList={setLocationList}
                />
                {!editable && (
                  <div className="mt-2">
                    <SaveButton label="Save" />

                    <CancelButton
                      onClick={() => {
                        history.push(Urls.ACTIVITY_LIST);
                      }}
                    />
                  </div>
                )}
              </Form>
            </div>
          </TabPane>
        )}

        {activeTab == Tab.COMMENTS && (
          <TabPane tabId={Tab.COMMENTS} className="w-100">
            <Comment
              objectId={props.match.params.id}
              objectName={ObjectName.ACTIVITY}
              maxHeight="100vh"
            />
          </TabPane>
        )}
        <TabPane tabId={Tab.HISTORY} className="w-100">
          {activeTab == Tab.HISTORY && (
            <ActivityList
              id={props.match.params.id}
              objectId={props.match.params.id}
              object_name={ObjectName.ACTIVITY}
              history={history}
            />
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};
export default ActivityDetail;
