import React, { useEffect, useState } from "react";
import Form from "../../components/Form";
import SingleCheckbox from "../../components/SingleCheckbox";
import { Setting } from "../../helpers/Setting";
import ArrayList from "../../lib/ArrayList";
import { getKeyValueByObject } from "../../lib/Helper";
import { getCompanySettings, saveSetting } from "../../services/SettingService";

const SettingTab = () => {
  const [settings, setSettings] = useState({});

  const getSettings = async () => {
    const settingData = await getCompanySettings();
    setSettings(settingData);
  };

  useEffect(() => {
    getSettings();
  }, []);

  const initialValues = {
    require_verification_on_location_change:
      settings &&
      ArrayList.isNotEmpty(settings) &&
      getKeyValueByObject(
        settings,
        Setting.REQUIRE_VERIFICATION_ON_LOCATION_CHANGE
      ) == "true"
        ? true
        : false,
  };

  const handleCheckBoxValue = (values) => {
    const data = new FormData();

    if (
      values &&
      values.require_verification_on_location_change !== undefined
    ) {
      data.append(
        Setting.REQUIRE_VERIFICATION_ON_LOCATION_CHANGE,
        values.require_verification_on_location_change
      );
    }

    // Save settings
    saveSetting(data, null, () => {
      getSettings();
    });
  };

  return (
    <>
      <div className="card card-body">
        <Form
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={{}}
        >
          <div className="row field-wrapper mt-3">
            <div className="col-lg-12 col-sm-12">
              <SingleCheckbox
                name={Setting.REQUIRE_VERIFICATION_ON_LOCATION_CHANGE}
                label={"Require Verification On Location Change"}
                handleOnChangeSubmit={(value, name) =>
                  handleCheckBoxValue(value, name)
                }
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SettingTab;
