import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { isBadRequest } from "../lib/Http";



class LeadSettingService {

    static create(data, callback) {
        apiClient
            .post(`${endpoints().LeadTypeApi}`, data)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                    return callback && callback(response)
                }
            })
            .catch((err) => {
                if (isBadRequest(err)) {
                    let errorMessage;
                    const errorRequest = err.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                }
            });

    }

    static update(data, cb) {
        apiClient
            .put(`${endpoints().LeadTypeApi}`, data)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                    return cb(successMessage)
                }
            })
            .catch((err) => {
                if (isBadRequest(err)) {
                    let errorMessage;
                    const errorRequest = err.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                }
            });
    }

    static async get(id) {
        try {
            if (id) {
                const response = await apiClient.get(`${endpoints().LeadTypeApi}`,{params:{id: id}});
                const data = response.data.data;
                return data;
            }

        } catch (err) {
            console.log(err);
        }
    }

    static delete = (id, cb) => {
        apiClient
            .delete(`${endpoints().LeadTypeApi}`, { params: { id: id } })
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                    return cb(successMessage)
                }
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                }
            });
    }
}
export default LeadSettingService;