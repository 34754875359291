import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import Permission from "../../helpers/Permission";
import Url from "../../helpers/Url";
import { hasPermission } from "../../services/UserRolePermissionService";

const PeoplesReportList = () => {
  const [nameToggle, setNameToggle] = useState(true);
  const showAttendanceSummaryReport = hasPermission(
    Permission.ATTENDANCE_SUMMARY_REPORT_VIEW
  );
  const showAttendanceReport = hasPermission(Permission.ATTENDANCE_REPORT_VIEW);
  const showFineReport = hasPermission(Permission.FINE_REPORT_VIEW);
  const hasAttendanceLateCheckInAnalysisReportView = hasPermission(
    Permission.ATTENDANCE_LATE_CHECKIN_ANALYSIS_REPORT_VIEW
  );

  // store report data
  const reports = [];
  if (showAttendanceSummaryReport) {
    reports.push({
      title: "Attendance Summary Report",
      link: Url.ATTENDENCE_SUMMARY_REPORT,
    });
  }
  if (showAttendanceReport) {
    reports.push({
      title: "Attendance Report",
      link: Url.ATTENDENCE_REPORT,
    });
  }
  reports.push({
    title: "Attendance Late CheckIn Analysis Report",
    link: Url.ATTENDANCE_LATE_CHECKIN_ANALYSIS_REPORT,
  });

  if (showFineReport) {
    reports.push({
      title: "Fine Report",
      link: Url.FINE_REPORT,
    });
  }

  return (
    <>
      <PageTitle label="Reports" />
      {/* Attendance Report */}
      {hasAttendanceLateCheckInAnalysisReportView && (
        <div className="accordion mt-3" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={`accordion-button bg-body-tertiary ${
                  nameToggle ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded={nameToggle ? "true" : "false"}
                aria-controls="collapseOne"
                onClick={() => setNameToggle(!nameToggle)}
              >
                <h5 className="fw-normal">Attendance</h5>
              </button>
            </h2>
            <div
              id="collapseOne"
              className={`accordion-collapse collapse ${
                nameToggle ? "show" : ""
              }`}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    {reports.map((dataList, index) => (
                      <div key={index}>
                        <Link
                          to={dataList.link}
                          className="my-2 text-dark text-decoration-none"
                        >
                          {dataList.title}
                        </Link>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PeoplesReportList;
