// Type options
export const typeOptions = [
  { value: "Absent", label: "Absent" },
  { value: "Additional Day", label: "Additional Day" },
  { value: "Additional Leave", label: "Additional Leave" },
  { value: "Compensation Day", label: "Compensation Day" },
  { value: "Leave", label: "Leave" },
  { value: "Non-Working Day", label: "Non-Working Day" },
  { value: "Working Day", label: "Working Day" },
];

// Status options
export const statusOptions = [
  { label: "Approved", value: "Approved" },
  { label: "Pending", value: "Pending" },
];

// Late hours status options
export const lateHoursStatusOptions = [
  { label: "Approved", value: "Approved" },
  { label: "Approved(LOP)", value: "Approved(LOP)" },
  { label: "Pending", value: "Pending" },
];

// Attendance status options
export const attendanceStatusOptions = [
  { label: "Approved", value: "Approved" },
  { label: "Hold", value: "Hold" },
  { label: "Approved(LOP)", value: "Approved(LOP)" },
  { label: "Pending", value: "Pending" },
];

export const FieldLabel = {
  LATE_HOURS: "Late Hours",
  IP_ADDRESS: "Ip Address",
  TYPE: "Type",
  DAYS_COUNT: "Days Count",
  CHECK_IN: "Check-In",
  CHECK_OUT: "Check Out",
  ADDITIONAL_HOURS: "Additional Hours",
};

export const Attendance = {
  TYPE_LEAVE: "Leave",
  TYPE_ABSENT: "Absent",
  TYPE_WORKING_DAY: "Working Day",
  TYPE_ADDITIONAL_DAY: "Additional Day",
  TYPE_ADDITIONAL_LEAVE: "Additional Leave",
  STATUS_ACTIVE_TEXT: "Active",
  STATUS_INACTIVE_TEXT: "InActive",
  STATUS_ACTIVE: 1,
  STATUS_INACTIVE: 2,
};

export const AttendanceCategory = {
  CATEGORY_LEAVE: 2,
  CATEGORY_WORKING_DAY: 1,
}