export const statusOptions = [
    {
        value: "Active",

        label: "Active",
    },

    {
        value: "InActive",

        label: "InActive",
    },
];

export const sprint = {

    STATUS_ACTIVE_TEXT: "Active",
    STATUS_INACTIVE_TEXT: "InActive",
}