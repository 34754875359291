import React, { useRef } from "react";
import { useSortBy, useTable } from "react-table";
import { Table } from "reactstrap";
import Pagination from "../../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Url from "../../../lib/Url";
import { useState } from "react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
import { toHoursAndMinutes } from "../../../lib/HoursAndMinutes";
import DateTime from "../../../lib/DateTime";

export function Tables(props) {
    const {
        columns = [],
        Values = [],
        setPage,
        page,
        getDetails,
        pageSize = 10,
        totalCount = 0,
        divRefs
    } = props;

    const data = Array.isArray(Values) ? Values : [];
    const [sortValue, setSortValue] = useState(false);
    const tableRef = useRef();

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data }, useSortBy);

    const handleSort = (columnId) => {
        setSortValue(!sortValue);

        Url.UpdateUrl(
            {
                ...Url.GetAllParams(),
                sort: columnId,
                sortDir: sortValue ? "DESC" : "ASC",
            },
            props
        );
        getDetails && getDetails();
    };

    const currentPage = page;
    const firstPage = (currentPage - 1) * pageSize + 1;
    const lastPage = Math.min(currentPage * pageSize, totalCount);
    // CSV Download
    const downloadCSV = () => {
        // Flatten the columns by extracting only child columns with accessors
        const flattenColumns = (columns) => {
            return columns.reduce((acc, col) => {
                if (col.columns) {
                    return [...acc, ...flattenColumns(col.columns)]; // Recursive call for nested columns
                } else if (col.accessor) {
                    return [...acc, col]; // Include columns that have an accessor
                }
                return acc;
            }, []);
        };

        const flatColumns = flattenColumns(columns);

        const csvContent =
            "data:text/csv;charset=utf-8," +
            [
                flatColumns.map((col) => col.Header).join(","),
                ...data.map((row) =>
                    flatColumns.map((col) => (row[col.accessor] || "")).join(",")
                ),
            ].join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `Attendance Summary Report-${DateTime.DateAndTime(new Date())}.csv`);
        document.body.appendChild(link);
        link.click();
    };


    const downloadPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape", // Use landscape mode for more width
            unit: "mm",
            format: "a4",
        });

        const pageWidth = doc.internal.pageSize.getWidth() - 20; // Account for margins

        // Flatten the columns by extracting only child columns with accessors
        const flattenColumns = (columns) => {
            return columns.reduce((acc, col) => {
                if (col.columns) {
                    return [...acc, ...flattenColumns(col.columns)];
                } else if (col.accessor) {
                    return [...acc, col];
                }
                return acc;
            }, []);
        };

        const flatColumns = flattenColumns(columns);
        const headers = flatColumns.map((col) => col.Header);

        const body = data.map((row) =>
            flatColumns.map((col) => {
                if (col?.accessor === "additional_hours") {
                    return row[col?.accessor] ? `${toHoursAndMinutes(row[col?.accessor])}` : "";
                }
                return row[col?.accessor] || "";
            })
        );

        let columnWidths = headers.map((header) => doc.getTextWidth(header) + 10);
        let totalWidth = columnWidths.reduce((sum, width) => sum + width, 0);

        columnWidths[0] = 40;
        totalWidth = columnWidths.reduce((sum, width) => sum + width, 0);

        if (totalWidth > pageWidth) {
            const remainingWidth = pageWidth - columnWidths[0]; // Remaining space after first column
            const scaleFactor = remainingWidth / (totalWidth - columnWidths[0]);
            columnWidths = columnWidths.map((width, index) => index === 0 ? width : width * scaleFactor);
        }

        const columnStyles = columnWidths.reduce((acc, width, index) => {
            acc[index] = { cellWidth: width };
            return acc;
        }, {});

        autoTable(doc, {
            head: [headers],
            body: body,
            styles: { fontSize: 10, cellPadding: 3 },
            columnStyles: columnStyles,
            startY: 20,
            theme: "grid",
            headStyles: {
                fillColor: [41, 128, 186],
                textColor: 255,
                fontStyle: "bold",
            },
            margin: { left: 10, right: 10 },
        });

        doc.save(`Attendance Summary Report-${DateTime.DateAndTime(new Date())}.pdf`);
    };



    const downloadPNG = () => {
        if (!tableRef.current) return; // Prevent errors if tableRef is null
        html2canvas(tableRef.current, { scrollX: -window.scrollX, scrollY: -window.scrollY })
            .then((canvas) => {
                const link = document.createElement("a");
                link.href = canvas.toDataURL("image/png");
                link.download = `Attendance Summary Report-${DateTime.DateAndTime(new Date())}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    };


    return (
        <div className="redux-table mb-4 pb-4 mb-sm-0 pb-sm-0">

            <div ref={divRefs.csvDiv} id="csvDiv" onClick={() => downloadCSV()}>
            </div>
            <div ref={divRefs.pdfDiv} id="pdfDiv" onClick={() => downloadPDF()}>
            </div>
            <div ref={divRefs.pngDiv} id="pngDiv" onClick={() => downloadPNG()}>
            </div>

            <div ref={tableRef}>
                <Table
                    hover
                    responsive
                    className="table-outline"
                    {...getTableProps()}
                >
                    <thead className="thead-light text-center">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        key={column.id}
                                        onClick={() => handleSort(column.id)}
                                        className="sortable-header"
                                        style={{ cursor: "pointer" }}
                                        {...column.getHeaderProps()}
                                    >
                                        {column?.render("Header")}
                                        {column?.sortType ? (Url.GetParam("sort") === column?.id) ? (
                                            (Url.GetParam("sortDir") === "DESC") ? <FontAwesomeIcon
                                                style={{
                                                    width: "10px",
                                                }}
                                                icon={faChevronDown}
                                            /> : <FontAwesomeIcon
                                                style={{
                                                    width: "10px",
                                                }}
                                                icon={faChevronUp}
                                            />
                                        ) : <FontAwesomeIcon
                                            style={{
                                                width: "10px",
                                            }}
                                            icon={faChevronDown}
                                        /> : ""}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={row.id}>
                                    {row.cells.map((cell) => (
                                        <td key={cell.column.id} className="text-center" {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>

            <div className="d-flex justify-content-between">
                <div>
                    Showing {firstPage} to {lastPage} of {totalCount} entries
                </div>

                <Pagination
                    currentPage={page}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    onPageChange={setPage}
                />
            </div>
        </div>
    );
}
