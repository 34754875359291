import React, { useState } from "react";
import Text from "../../../components/Text";
import RoleSelect from "../../../components/RoleSelect";
import Select from "../../../components/Select";
import { UserFilterTypeOptions } from "../../../helpers/Message";
import SaveButton from "../../../components/SaveButton";
import MessageChannelService from "../../../services/MessageChannelService";
import { useDispatch } from "react-redux";
import { fetchList } from "../../../actions/table";
import { endpoints } from "../../../api/endPoints";
import ArrayList from "../../../lib/ArrayList";
import Drawer from "../../../components/Drawer";
import { GroupStatus } from "../../../helpers/Group";
import Number from "../../../lib/Number";
import Toast from "../../../components/Toast";
import StoreSelector from "../../location/components/storeSelector";

const GroupForm = (props) => {
  let { detail, className } = props;
  let dispatch = useDispatch();
  const [roleList, setRoleList] = useState([]);
  const [locationList, setLocationList] = useState([]);
 
  let roleData = [];

  if (ArrayList.isArray(detail?.user_role)) {
    if (ArrayList.isArray(roleList)) {
      for (let i = 0; i < detail?.user_role.length; i++) {
        let role = roleList.find((data) => data?.value == detail?.user_role[i]);
        roleData.push(role);
      }
    }
  }

  let groupBody = (
    <>
      <Text name="channel_name" label="Group Name" required />

      <>
        <RoleSelect
          fullWidth={true}
          label="Role"
          name="user_role"
          isMulti
          list={setRoleList}
        />
        <Select
          fullWidth={true}
          label="User Filter Type"
          name="user_filter_type"
          isClearable
          options={UserFilterTypeOptions}
        />
        <StoreSelector
          name="location"
          label="Location"
          isSingleSelect
          setStoreList={setLocationList}
        />
      </>
      <Select name="status" label="Status" required options={GroupStatus} />
     
    </>
  );

  let groupFooter = (
    <>
      <SaveButton type="submit" label={detail?.channel_id ? "Save" : "Add"} />
    </>
  );

  const handleAdd = async (values) => {
    let data = new FormData();
    data.append("channel_name", values && values?.channel_name);
    data.append("status", values && Number.Get(values?.status?.value));
    if (values && ArrayList.isArray(values?.user_role)) {
      let roleIds = values?.user_role.map((value) => value.value);
      data.append("user_role", roleIds);
    }
    if (Number.isNotNull(values?.location?.id)) {
      data.append("location", values && Number.Get(values?.location?.id));
    }
    data.append(
      "user_filter_type",
      values && Number.Get(values?.user_filter_type?.value)
    );

    if (detail?.channel_id) {
      dispatch(
        await MessageChannelService.update(detail?.channel_id, data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "groupList",
                `${endpoints().MessageChannelApi}/search`,
                1,
                10,
                {}
              )
            );
            props?.toggle();
            props?.getChannelList && props?.getChannelList();
            props?.getDetail && props?.getDetail();
          }
        })
      );
    } else {
      dispatch(
        await MessageChannelService.create(data, (res) => {
          if (res) {
            Toast.success(res?.data?.message);
            dispatch(
              fetchList(
                "groupList",
                `${endpoints().MessageChannelApi}/search`,
                1,
                10,
                {}
              )
            );
            props?.toggle();
            props?.getChannelList && props?.getChannelList();
            props?.getDetail &&  props?.getDetail()
            if (res?.data?.channelId) {
              props?.history.push(`/message/group/${res?.data?.channelId}`);
            }
          }
        })
      );
    }
  };
  let initialValues = {
    channel_name: detail?.channel_name || "",
    user_role: roleData || "",
    user_filter_type: UserFilterTypeOptions?.find(
      (data) => data.value == ((detail && detail?.user_filter_type) || "")
    ),
    status: GroupStatus.find(
      (data) => data.value == ((detail && detail?.status) || "")
    ),
    location: locationList.find(
      (data) => data.id == ((detail && detail?.location) || "")
    ),
  };
  return (
    <>
      <Drawer
        modelTitle={detail?.channel_id ? "Edit Group" : "Add Group"}
        DrawerBody={groupBody}
        DrawerFooter={groupFooter}
        onSubmit={handleAdd}
        initialValues={initialValues}
        handleOpenModal={props?.toggle}
        handleCloseModal={props?.toggle}
        handleDrawerClose={props?.toggle}
        isModalOpen={props?.isOpen}
        enableReinitialize
        className={className}
      />
    </>
  );
};
export default GroupForm;
