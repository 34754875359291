export const CHECKED_IN_USERS_ONLY = {
  TEXT: "Checked In User Only",
  VALUE: 1,
};
export const UserFilterTypeOptions = [
  {
    label: CHECKED_IN_USERS_ONLY.TEXT,
    value: CHECKED_IN_USERS_ONLY.VALUE,
  },
];
