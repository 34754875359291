import React from "react";
import { Link } from "react-router-dom";
import { endpoints } from "../../../api/endPoints";
import UserCard from "../../../components/UserCard";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import ObjectName from "../../../helpers/ObjectName";
import { Group } from "../../../helpers/Status";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TicketSummaryList = (props) => {
  let { history } = props;

  let pendingCountGroup = [
    Group.DRAFT,
    Group.NEW,
    Group.REVIEW,
    Group.PENDING,
    Group.APPROVED,
    Group.CANCELLED,
    Group.INPROGRESS,
    Group.REOPEN,
    Group.HOLD,
  ];

  return (
    <>
      <ReduxTable
        id="summary"
        newTableHeading
        disableHeader
        apiURL={`${endpoints().ticketAPI}/summery`}
        paramsToUrl={true}
        history={history}
        message="You can start by clicking on Add New"
        icon={<FontAwesomeIcon icon={faTasks} />}
      >
        <ReduxColumn
          field="assignee_name"
          sortBy="assignee_name"
          width="310px"
          minWidth="310px"
          maxWidth="310px"
          renderField={(row) => (
            <>
              <UserCard
                firstName={row.first_name}
                url={row.media_url}
                lastName={row.last_name}
              />
            </>
          )}
        >
          Assignee
        </ReduxColumn>
        <ReduxColumn
          field="ticket_count"
          sortBy="count"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          className="text-center"
          renderField={(row) => (
            <Link
              to={`/ticketSearch?objectName=${ObjectName.TICKET}&user=${row?.assignee_id}&group=${pendingCountGroup.join(",")}`}
              className="opacity-75 no-underline text-blue-600 hover:underline"
            >
              {row?.ticket_count && `${row?.ticket_count}(${row?.total_story_points ? row?.total_story_points : 0})`}
            </Link>
          )}
        >
          Count (Story Points)
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};
export default TicketSummaryList;
