import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import Drawer from "../../components/Drawer";
import DateSelector from "../../components/Date";
import CurrencyComponent from "../../components/Currency.js";
import SaveButton from "../../components/SaveButton";
import PageTitle from "../../components/PageTitle";
import MoreDropdown from "../../components/authentication/moreDropdown.js";
import DeleteModal from "../../components/DeleteModal.js";

// Lib
import DateTime from "../../lib/DateTime";
import Currency from "../../lib/Currency";

// Services
import BankSettlementsService from "../../services/BankSettlementsService.js";
import { hasPermission } from "../../services/UserRolePermissionService.js";

// API
import { endpoints } from "../../api/endPoints.js";

// Actions
import { fetchList } from "../../actions/table.js";

// Helpers
import Permission from "../../helpers/Permission.js";
import Url from "../../lib/Url";

const BankSettlementsListPage = (props) => {
  const [rowValue, setRowValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  let showAdd = hasPermission(Permission.BANK_SETTLEMENT_ADD);
  let showDelete = hasPermission(Permission.BANK_SETTLEMENT_DELETE);
  let showQuickView = hasPermission(Permission.BANK_SETTLEMENT_EDIT);

  let dispatch = useDispatch();

  const modelBody = (
    <>
      <DateSelector
        name="date"
        label={"Date"}
        placeholder="Select Date"
        isClearable
        required
      />

      <CurrencyComponent label="Amount" name="amount" required />
    </>
  );

  const modelFooter = (
    <>
      <SaveButton label={rowValue ? "Edit" : "Add"} />
    </>
  );

  const openToggle = () => {
    setIsOpen(!isOpen);
  };

  const closeToggle = () => {
    setRowValue(null);
    setIsOpen(!isOpen);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
    setRowValue("");
  };

  const handleSubmit = async (values) => {
    let data = new FormData();
    data.append("amount", values && values?.amount ? values?.amount : "");
    data.append("date", values && values?.date ? values?.date : "");
    if (rowValue) {
      dispatch(
        await BankSettlementsService.update(rowValue?.id, data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "bankSettlement",
                `${endpoints().BankSettlementAPI}/search`,
                1,
                25,
                {
                  ...Url.GetAllParams()
                }
              )
            );
            closeToggle();
          }
        })
      );
    } else {
      dispatch(
        await BankSettlementsService.create(data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "bankSettlement",
                `${endpoints().BankSettlementAPI}/search`,
                1,
                25,
                {
                  ...Url.GetAllParams()
                }
              )
            );
            closeToggle();
          }
        })
      );
    }
  };

  const handleDelete = async () => {
    dispatch(
      await BankSettlementsService.delete(rowValue?.id, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "bankSettlement",
              `${endpoints().BankSettlementAPI}/search`,
              1,
              25,
              {
                ...Url.GetAllParams()
              }
            )
          );
          setOpenDeleteModal(false);
          setRowValue(null);
        }
      })
    );
  };

  return (
    <div>
      <div className="mb-2">
        <PageTitle
          label="Bank Settlements"
          buttonHandler={
            showAdd
              ? () => {
                openToggle();
              }
              : ""
          }
          buttonLabel="Add"
        />
      </div>
      <Drawer
        modelTitle={rowValue ? "Edit" : "Add"}
        DrawerBody={modelBody}
        DrawerFooter={modelFooter}
        onSubmit={handleSubmit}
        initialValues={{
          amount: rowValue?.amount ? rowValue?.amount : "",
          date: rowValue?.date ? rowValue?.date : "",
        }}
        enableReinitialize
        handleOpenModal={openToggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={closeDeleteModal}
        title="Delete BankSettlement"
        deleteFunction={() => {
          handleDelete();
        }}
        label={rowValue?.id}
      />
      <ReduxTable
        id={"bankSettlement"}
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().BankSettlementAPI}/search`}
        newTableHeading
        params={{ showTotal: true }}
        sortByDropdown
        icon={<FontAwesomeIcon icon={faUser} />}
        message="You can start by clicking add."
        history={props?.history}
        paramsToUrl={true}
        showBackgroundColor
      >
        <ReduxColumn
          className="text-center text-decoration-none"
          field="id"
          sortBy="id"
          isClickable="true"
          type="link"
          renderField={(row) => (
            <Link to={`/bankSettlement/${row.id}`} className="text-decoration-none">{row.id}</Link>
          )}
        >
          Id#
        </ReduxColumn>
        <ReduxColumn
          field="date"
          sortBy="date"
          className="text-center"
          renderField={(row) => (
            <span>{DateTime.getDateTimeByUserProfileTimezone(row?.date)}</span>
          )}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          sortBy="amount"
          className="text-end"
          renderField={(row) => (
            <>
              <span>{Currency.Format(row?.amount)}</span>
            </>
          )}
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="text-center action-group-dropdown">
              <MoreDropdown>
                {showQuickView && (
                  <DropdownItem
                    onClick={() => {
                      openToggle();
                      setRowValue(row);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                )}
                {showDelete && (
                  <DropdownItem
                    className="text-danger"
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setRowValue(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                )}
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

export default BankSettlementsListPage;
