import React from "react";
import { useDispatch } from "react-redux";
import Currency from "../../components/Currency";
import Email from "../../components/Email";
import Form from "../../components/Form";
import FormFileUpload from "../../components/FormFileUpload";
import ImageUpload from "../../components/FormImageUplaod";
import Number from "../../components/Number";
import Phone from "../../components/Phone";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import Url from "../../lib/Url";
import PublicCandidateService from "../../services/PublicCandidateService";

const PostResumePage = (props) => {

  let dispatch = useDispatch();


  function formatPosition(position) {
    return position
        .split('-') 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '); 
}


  let positionOption = [
    { label: "Accountant - Fresher", value: "Accountant - Fresher" },
    { label: "Admin Executive - Fresher", value: "Admin Executive - Fresher" },
    {
      label: "Graphics Designer - Fresher",
      value: "Graphics Designer - Fresher",
    },
    { label: "HR Recruiter - Fresher", value: "HR Recruiter - Fresher" },
    {
      label: "Node JS Developer - Fresher",
      value: "Node JS Developer - Fresher",
    },
    {
      label: "Manual QA Engineer - Fresher",
      value: "Manual QA Engineer - Fresher",
    },
    {
      label: "Mobile Developer - Fresher",
      value: "Mobile Developer - Fresher",
    },
    {
      label: "React JS Developer - Fresher",
      value: "React JS Developer - Fresher",
    },
    {
      label: "React Native Developer - Fresher",
      value: "React Native Developer - Fresher",
    },
    { label: "UI/UX Designer - Fresher", value: "UI/UX Designer - Fresher" },
    { label: "Web Developer - Fresher", value: "Web Developer - Fresher" },
    {
      label: "Wordpress Developer - Fresher",
      value: "Wordpress Developer - Fresher",
    },
  ];

  let genderOption = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  let maritalStatusOption = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
    { label: "Divorced", value: "Divorced" },
    { label: "Separated", value: "Separated" },
    { label: "Widowed", value: "Widowed" },
  ];

  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    position: "",
    ["expected-salary"]: "",
    age: "",
    gender: "",
    ["marital-status"]: "",
    ["current-city"]: "",
    ["current-state"]: "",
    ["permanent-city"]: "",
    ["permanent-state"]: "",
    ["qualification-degree"]: "",
    ["qualification-department"]: "",
    ["qualification-year"]: "",
    message: "",
    profileImage: "",
    file: "",
    ["staying-with"]: "",
  };

  const handleSubmit = async (values, resetForm) => {
    let keys = Object.keys(initialValues);
    let data = new FormData();
    keys.forEach((keys) => {
      if (keys == "gender" || keys == "marital-status" || keys == "position") {
        data.append(keys, values[keys]?.value);
      } else {
        data.append(keys, values[keys]);
      }
    });
    dispatch(
      await PublicCandidateService.create(data, async (response) => {
        resetForm();
      })
    );
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">
        {`Apply for ${formatPosition(Url.GetParam("position"))} position`}
      </h2>
      <div className="card card-body">
        <Form
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          <ImageUpload name="profileImage" required />
          <div className="row">
            <div className="col">
          <Text name="firstName" label="First Name" placeholder="First Name" required={true} />
            </div>
            <div className="col">
          <Text name="lastName" label="Last Name" placeholder="Last Name"  required={true}/>
            </div>
          </div>
          <Email
            label="Email"
            name="email"
            placeholder="Enter Email"
            notify="error"
            required={true}
          />
          <Phone
            name="mobile"
            label="Mobile Number"
            placeholder="Enter Mobile Number"
            error=""
            required={true}
          />
          <Select
            name="position"
            label="Position"
            placeholder="Position"
            error=""
            options={positionOption}
            required={true}
          />
          <Currency name="expected-salary" label="Expected Salary"   required={true} />
          <Number
            name="age"
            label="Age"
            placeholder="Enter Age..."
            error=""
            required={true}
          />

          <Select
            name="gender"
            label="Gender"
            placeholder="Gender"
            error=""
            options={genderOption}
            required={true}
          />

          <Select
            name="marital-status"
            label="Marital Status"
            placeholder="Marital Status"
            error=""
            options={maritalStatusOption}
            required={true}
          />
          <Text
            name="staying-with"
            label="Staying With"
            placeholder="Staying With"
            required={true}
          />
          <h4>Current Address</h4>

          <Text
            name="current-city"
            label="Current City"
            placeholder="Current City"
            required={true}
          />
          <Text
            name="current-state"
            label="Current State"
            placeholder="Current State"
            required={true}
          />
          <Text
            name="permanent-city"
            label="Permanent City"
            placeholder="Permanent City"
            required={true}
          />
          <Text
            name="permanent-state"
            label="Permanent State"
            placeholder="Permanent State"
            required={true}
          />
          <h4>Educational Qualification</h4>
          <Text
            name="qualification-degree"
            label="Qualification Degree"
            placeholder="Qualification Degree"
            required={true}
          />
          <Text
            name="qualification-department"
            label="Department"
            placeholder="Department"
            required={true}
          />
          <Text
            name="qualification-year"
            label="Year Of Passing"
            placeholder="Year Of Passing"
            required={true}
          />
          <TextArea name="message" label="Message" placeholder="Message" required={true} />
          <FormFileUpload
            name="file"
            label="Upload Resume"
            required={true}
            acceptedFileTypes={["image/jpeg", "image/png", "application/pdf"]}
            showPreview={false}
          />
          <div className="d-flex justify-content-center">
            <SaveButton label="Apply Now" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PostResumePage;
