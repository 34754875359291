// Jobs Status Constants
export const Jobs = {
  STATUS_ACTIVE: 1,
  STATUS_INACTIVE: 2,
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const JOB_CANDIDATE_PHOTO = 1
export const JOb_FIST_NAME = 2;
export const JOB_LAST_NAME = 3;
export const JOB_EMAIL = 4;
export const JOB_MOBILE_NUMBER = 5;
export const JOB_POSITION = 6;
export const JOB_EXPECTED_SALARY = 7;
export const JOB_AGE = 8;
export const JOB_GENDER = 9;
export const JOB_MARITAL_STATUS = 10;
export const JOB_STAYING_IN = 11;
export const JOB_CURRENT_ADDRESS = 12;
export const JOB_PERMANENT_ADDRESS = 13;
export const JOB_EDUCATIONAL_QUALIFICATION = 14;
export const JOB_MESSAGE = 15;
export const JOB_RESUME = 16;
