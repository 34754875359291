import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabPane } from "reactstrap";
import classNames from "classnames";

// Components
import Form from "../../components/Form";
import Text from "../../components/Text";
import Phone from "../../components/Phone";
import SaveButton from "../../components/SaveButton";
import PageTitle from "../../components/PageTitle";
import DeleteModal from "../../components/DeleteModal";
import BreadCrumb from "../../components/Breadcrumb";
import Action from "../../components/Action";
import TextArea from "../../components/TextArea";
import TagSelect from "../../components/TagSelect";
import ActivityList from "../../components/ActivityList";
import HorizontalSpace from "../../components/HorizontalSpace";
import CancelButton from "../../components/CancelButton";

// Services
import CallRegisterService from "../../services/CallRegisterService";
import TagService from "../../services/TagService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Lib
import Number from "../../lib/Number";

// Helpers
import Permission from "../../helpers/Permission";
import ObjectName from "../../helpers/ObjectName";
import Url from "../../lib/Url";


export const CallRegisterTab = {
  SUMMARY: "Summary",
  HISTORY: "History",
};

const callRegisterDetailPage = (props) => {
  let { history } = props;
  const [detail, setDetail] = useState({});
  const [isCallRegisterDeleteModel, setDeleteCallRegisterModal] = useState(false);
  const [purposeChange, setPurposeChange] = useState(null);
  const [categoryChange, setCategoryChange] = useState(null);
  const [name, setName] = useState();
  const [phoneValue, setPhoneValue] = useState(null);
  const [notes, setNotes] = useState();
  const [purposeTagList, setPurposeTagList] = useState();
  const [activeTab, setActiveTab] = useState(Url.GetParam("tab") ? Url.GetParam("tab") : CallRegisterTab.SUMMARY);
  let dispatch = useDispatch();
  let showHistory = hasPermission(Permission.CALL_REGISTER_HISTORY_VIEW);

  let hasDeletePermission = hasPermission(Permission.CALL_REGISTER_DELETE);

  let hasEditPermission = hasPermission(Permission.CALL_REGISTER_EDIT);

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getCallRegisterDetail();
    getTagList();
  }, []);

  const actionsMenuList = [];

  if (hasDeletePermission) {
    actionsMenuList.push(
      {
        value: "Delete",
        label: "Delete",
      },
    )
  }

  if (hasEditPermission) {
    actionsMenuList.unshift(
      {
        value: "Edit",
        label: "Edit",
      },
    )
  }

  let getCallRegisterDetail = async () => {
    let { data } = await CallRegisterService.get(props?.match?.params?.id);
    setDetail(data);
  };

  const getTagList = async () => {
    let purposeTagList = await TagService.getOption();
    setPurposeTagList(purposeTagList);
  };

  const handleActionChange = (e) => {
    if (e === "Delete") {
      setDeleteCallRegisterModal(true);
    }

    if (e === "Edit") {
      setIsEdit(true);
    }
  };

  const initialValue = {
    name: name ? name : detail?.name ? detail?.name : "",
    phone: phoneValue ? phoneValue : detail?.phone ? detail?.phone : "",
    notes: notes ? notes : detail?.notes ? detail?.notes : "",
    purpose: purposeChange
      ? {
        label: purposeChange?.label,
        value: purposeChange?.value,
      }
      : purposeTagList &&
      purposeTagList.find((fine) => fine.value === detail?.purpose),
      category: categoryChange
      ? {
        label: categoryChange?.label,
        value: categoryChange?.value,
      }
      : purposeTagList &&
      purposeTagList.find((fine) => fine.value === detail?.category),
  };

  const handleCallRegister = async (values) => {
    const updateData = new FormData();
    updateData.append("name", values.name ? values.name : "");
    updateData.append("phone", values.phone ? values.phone : "");
    updateData.append("notes", values.notes ? values.notes : "");
    updateData.append("purpose", Number.Get(values?.purpose?.value));
    updateData.append("category", Number.Get(values?.category?.value));

    dispatch(
      await CallRegisterService.update(
        props?.match?.params?.id,
        updateData,
        (res) => {
          getCallRegisterDetail();
          setIsEdit(false);
        }
      )
    );
  };

  const closeDeleteModal = () => {
    setDeleteCallRegisterModal(false);
  };

  const handleCallRegisterDelete = async () => {
    dispatch(
      await CallRegisterService.del(detail.id, () => {
        history.goBack();
      })
    );
  };

  const handleTagChange = (values) => {
    let value = values;
    setPurposeChange(value);
  };

  const handleCategoryChange = (values) => {
    let value = values;
    setCategoryChange(value);
  };

  const handleNotesChange = (values) => {
    let value = values?.target.value;
    setNotes(value);
  }

  const handleNameChange = (values) => {
    let value = values?.target?.value;
    setName(value);
  };

  const handlePhoneChange = (value) => {
    setPhoneValue(value);
  };

  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    { label: "Call Register", link: "/callRegister" },
    { label: "Call Register Detail", link: "" },
  ];

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  const _handleTabChange = (tab) => {
    props.history.push(`?tab=${tab}`);
  };

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <DeleteModal
        isOpen={isCallRegisterDeleteModel}
        title="Delete Call Register"
        label={`${detail?.name}`}
        toggle={closeDeleteModal}
        deleteFunction={handleCallRegisterDelete}
      />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Call Register Detail Page" />
        {actionsMenuList && actionsMenuList.length > 0 && (
          <div className="d-flex">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        )}
      </div>

      <Nav tabs className="admin-tabs mt-2">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === CallRegisterTab.SUMMARY,
            })}
            onClick={() => {
              toggle(CallRegisterTab.SUMMARY);
              _handleTabChange(CallRegisterTab.SUMMARY);
            }}
          >
            {CallRegisterTab.SUMMARY}
          </NavLink>
        </NavItem>
        {showHistory && (
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === CallRegisterTab.HISTORY,
              })}
              onClick={() => {
                toggle(CallRegisterTab.HISTORY);
                _handleTabChange(CallRegisterTab.HISTORY);
              }}
            >
              {CallRegisterTab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      {activeTab === CallRegisterTab.SUMMARY && (
        <TabPane>
          <div className="card card-body">
            <Form
              initialValues={initialValue}
              enableReinitialize
              onSubmit={handleCallRegister}
            >
              <Text
                label="Name"
                name="name"
                className="fw-bold-text"
                placeholder="Enter Name"
                required
                error=""
                disabled={!isEdit}
                onChange={handleNameChange}
              />
              <Phone
                label="Phone Number"
                name="phone"
                placeholder="Enter Phone Number"
                error=""
                disabled={!isEdit}
                onChange={handlePhoneChange}
              />

              <TagSelect
                name="purpose"
                label="Purpose"
                placeholder="Select Purpose"
                params={{ type: "Call Register Purpose" }}
                handleTagChange={handleTagChange}
                isDisabled={!isEdit}
              />
               <TagSelect
                name="category"
                label="Category"
                placeholder="Select Category"
                params={{ type: "Call Register Category" }}
                handleTagChange={handleCategoryChange}
                isDisabled={!isEdit}
              />

              <TextArea
                label="Notes"
                name="notes"
                placeholder="Enter Notes"
                disabled={!isEdit}
                onChange={handleNotesChange}
              />
              <div>
                <HorizontalSpace bottom="2">
                  {isEdit &&
                    <>
                      <SaveButton />
                      <CancelButton
                        onClick={() => {
                          history.push("/callRegister");
                        }}
                      />
                    </>
                  }
                </HorizontalSpace>
              </div>
            </Form>
          </div>
        </TabPane>
      )}
      {activeTab === CallRegisterTab.HISTORY && (
        <ActivityList
          id={props?.match?.params?.id}
          objectId={props?.match?.params?.id}
          object_name={ObjectName.CALL_REGISTER}
          history={history}
        />
      )}
    </div>
  );
};

export default callRegisterDetailPage;
