import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Components
import ActivityList from "../../components/ActivityList";
import BreadCrumb from "../../components/Breadcrumb";
import CancelButton from "../../components/CancelButton";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import VisitorForm from "./visitorForm";
import Action from "../../components/Action";
import MediaCarousel from "../../components/MediaCarousel";
import Spinner from "../../components/Spinner";

// Helpers
import Media from "../../helpers/Media";
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";
import MediaUpload from "../../helpers/MediaUpload";
import { TODAY_VALUE } from "../../helpers/Date";

// Lib
import Url from "../../lib/Url";
import Numbers from "../../lib/Number";

// Services
import MediaService from "../../services/MediaService";
import { hasPermission } from "../../services/UserRolePermissionService";
import visitorService from "../../services/VisitorService";

export const VisitorDetailDetailTab = {
  GENERAL: "General",
  HISTORY: "History",
};

const VisitorDetail = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [visitorDetail, setvisitorDetail] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [openDeleteModal, setMediaDelete] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [editable, setEditable] = useState(true);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [jobList, setJobList] = useState([]);

  let id = props.match.params.id;
  const param = new URLSearchParams(props.history.location.search);
  const role = param.get("section");
  let showHistory = hasPermission(Permission.VISITOR_HISTORY_VIEW);
  let hasEditPermission = hasPermission(Permission.VISITOR_EDIT);
  let hasDeletePermission = hasPermission(Permission.VISITOR_DELETE);
  const [activeTab, setActiveTab] = useState(Url.GetParam("tab") ? Url.GetParam("tab") :
    role ? role : VisitorDetailDetailTab.GENERAL
  );
  const [userList, setUserList] = useState([]);
  const [storeList, setStoreList] = useState([]);

  const dispatch = useDispatch();
  const breadcrumbList = [
    { label: "Home", link: "/people/dashboard" },
    { label: "Visitor", link: `/visitor?date=${TODAY_VALUE}` },
    { label: "Visitor Detail", link: "" },
  ];

  const toggle = (tab) => {
    setIsOpen(!isOpen);
    setActiveTab(tab || role);
    props.history.push(`?tab=${tab}`);
  };

  useEffect(() => {
    if (activeTab === VisitorDetailDetailTab.GENERAL) {
      getVisitordetail();
    }
  }, [activeTab]);

  const handleImageUpload = async (values) => {
    if (id) {
      let mediaParams = {
        selectedFile: selectedFile && selectedFile,
        objectId: id,
        ObjectName: ObjectName.VISITOR,
        values: values,
      }
      await MediaUpload.uploadFile(mediaParams);
    }
  };

  useEffect(() => {
    getVisitordetail();
  }, []);

  const _handleTabChange = (tab) => {
    props.history.push(`?tab=${tab}`);
  };

  const delteVisitor = async (id, params) => {
    dispatch(
      await visitorService.delete(
        id,
        params,
        {
          pagination: true,
          sort: "id",
          sortDir: "DESC",
          page: Url.GetParam("page") || "",
          pageSize: Url.GetParam("pageSize") || "",
        },
        props.history
      )
    );
  };

  const getVisitordetail = async () => {
    setIsLoading(true);
    let id = props.match.params.id;
    let response = await visitorService.get(id);
    setvisitorDetail(response);
    setIsLoading(false);
  };

  const initialValues = {
    visitor: visitorDetail?.name ? visitorDetail?.name : "",
    mobileNumber: visitorDetail?.phone ? visitorDetail?.phone : "",
    purpose: visitorDetail?.purpose ? visitorDetail?.purpose : "",
    title: visitorDetail?.title ? visitorDetail?.title : "",
    person_to_meet: visitorDetail
      ? userList &&
      userList.find((data) => data.id == visitorDetail?.person_to_meet)
      : "",
    notes: visitorDetail?.notes ? visitorDetail?.notes : "",
    type:
      tagList && tagList.length > 0
        ? tagList.find((tag) => tag.id === visitorDetail?.type)
        : {
          label: visitorDetail?.typeName,
          value: visitorDetail?.type,
        },
    location: visitorDetail?.location_id ? storeList.find((data)=> data?.id == visitorDetail?.location_id) : "",
    position: visitorDetail?.position ? jobList.find((data) => data?.id == visitorDetail?.position) : "",
  };

  const handleUpdate = async (id, values) => {
    const data = new FormData();
    setEditable(true);
    data.append("name", values?.visitor);
    data.append("phone", values?.mobileNumber);
    data.append("purpose", values?.purpose);
    data.append("title", values?.title);
    data.append("notes", values?.notes);
    data.append("position", values?.position?.id)
    data.append("person_to_meet", values?.person_to_meet?.id);
    data.append("location_id", values?.location?.id);
    data.append("type", Numbers.Get(values?.type?.value));
    dispatch(await visitorService.update(id, data, uploadFile));
    setIsLoading(true);
    await getVisitordetail();
    setIsLoading(false);
    setEditable(false);
  };

  const deleteMedia = async (mediaId) => {
    if (mediaId) {
      MediaService.delete(mediaId, () => {
        getVisitordetail();
      });
    }
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const uploadFile = async (objectId) => {
    try {
      if (selectedFile && objectId) {
        const mediaFile = selectedFile ? selectedFile : "";

        const media = selectedFile?.name;

        const data = new FormData();

        if (mediaFile) {
          data.append([Media.MEDIA_FILE], mediaFile ? mediaFile : "");
        }
        if (media !== undefined) {
          data.append([Media.MEDIA_NAME], media ? media : "");
        }
        data.append("object", ObjectName.VISITOR);

        data.append("object_id", objectId);

        data.append([Media.MEDIA_VISIBILITY], Media.VISIBILITY_PUBLIC);

        const response = await MediaService.saveImage(data);
        let media_file = {
          media_id: response.id,
        };
        dispatch(
          await visitorService.updateMedia(
            visitorDetail?.id,
            media_file,
            getVisitordetail
          )
        );
        setSelectedFile("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const actionsMenuList = [];

  if (hasDeletePermission) {
    actionsMenuList.push(
      {
        value: "delete",
        label: "Delete",
      },
    )
  }

  if (hasEditPermission && editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  if (isLoading) {
    <Spinner />;
  }

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        <PageTitle label={visitorDetail?.name} />

        {actionsMenuList && actionsMenuList.length > 0 && (
          <div className="ps-2 d-flex">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        )}
      </div>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Visitor"
        id={props.match.params.id}
        label={visitorDetail?.name}
        deleteFunction={delteVisitor}
      />
      <DeleteModal
        id={visitorDetail?.id}
        label={`${visitorDetail?.name} image`}
        isOpen={openDeleteModal}
        toggle={() => {
          setMediaDelete(false);
        }}
        title="Delete Media"
        deleteFunction={() => deleteMedia(visitorDetail?.media_id)}
      />
      <Nav tabs className="admin-tabs mt-2">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === VisitorDetailDetailTab.GENERAL,
            })}
            onClick={() => {
              toggle(VisitorDetailDetailTab.GENERAL);
              _handleTabChange(VisitorDetailDetailTab.GENERAL);
            }}
          >
            {VisitorDetailDetailTab.GENERAL}
          </NavLink>
        </NavItem>
        {showHistory && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === VisitorDetailDetailTab.HISTORY,
              })}
              onClick={() => {
                toggle(VisitorDetailDetailTab.HISTORY);
                _handleTabChange(VisitorDetailDetailTab.HISTORY);
              }}
            >
              {VisitorDetailDetailTab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent>
        {activeTab === VisitorDetailDetailTab.GENERAL && (
          <TabPane>
            <div className="row card-body px-2 me-0 pe-0 pb-5 mb-4">
              <div className="col-lg-8 col-sm-8 col-md-8 card card-body">
                <Form
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    let id = props.match.params.id;
                    handleUpdate(id, values);
                  }}
                >
                  <VisitorForm
                    handleTypeChange={handleTypeChange}
                    TagList={setTagList}
                    name={visitorDetail?.name}
                    selectedType={selectedType}
                    editable={editable}
                    visitorDetail={visitorDetail}
                    setUserList={setUserList}
                    showUserDetailsPageLink={
                      visitorDetail?.person_to_meet ? true : false
                    }
                    userId={visitorDetail?.person_to_meet}
                    setStoreList={setStoreList}
                    setJobList={setJobList}
                  />
                  {!editable && (
                    <div className="mt-2">
                      <SaveButton label="Save" />
                      <CancelButton
                        onClick={() => {
                          props.history.push(`/visitor?date=${TODAY_VALUE}`);
                        }}
                      />
                    </div>
                  )}
                </Form>
              </div>
              <div className="col-12 col-sm-4 mt-3">
                <MediaCarousel
                  showCarasoul
                  modalTitle="Upload File"
                  title="leads"
                  objectName={ObjectName.VISITOR}
                  objectId={id}
                  history={history}
                  billView={true}
                  attachmentsList={true}
                  modalOpen={isModelOpen}
                  toggle={toggle}
                  setIsModelOpen={setIsModelOpen}
                  Attachments={"Attachments"}
                  handleImageUpload={handleImageUpload}
                  selectedFileValue={setSelectedFile}
                  editable={editable}
                />
              </div>
            </div>
          </TabPane>
        )}
        {showHistory && activeTab === VisitorDetailDetailTab.HISTORY && (
          <TabPane>
            <ActivityList
              id={id}
              objectId={id}
              object_name={ObjectName.VISITOR}
            />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

export default VisitorDetail;
