import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import Url from "../../helpers/Url";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";

const AccountEntryList = () => {
  const [accountEntryToggle, setAccountEntryToggle] = useState(true);
  const [salaryToggle, setSalaryToggle] = useState(true);

  const showSaleGstReport = hasPermission(Permission.SALES_GST_REPORT_VIEW);
  const showSalaryProjectionReport = hasPermission(Permission.SALARY_PROJECTION_REPORT_VIEW);
  const showPurchaseGstReport = hasPermission(Permission.PURCHASE_GST_REPORT_VIEW);
  const showAccountEntryReport = hasPermission(Permission.ACCOUNT_ENTRY_REPORT_VIEW);

  const accountEntryReport = [];
  if(showAccountEntryReport){
    const attendenceReport = {
      title: "AccountEntry Report",
      link: Url.ACCOUNT_ENTRY_REPORT,
    };
     accountEntryReport.push(attendenceReport);
  }

  if (showSaleGstReport) {
    const salesGstReport = {
      title: "Sales GST Report",
      link: Url.SALES_GST_REPORT,
    };
    accountEntryReport.push(salesGstReport);
  }

  if (showPurchaseGstReport) {
    const purchaseGstReport = {
      title: "Purchase GST Report",
      link: Url.PURCHASE_GST_REPORT,
    };
    accountEntryReport.push(purchaseGstReport);
  }

  const salaryReport = [];
  if (showSalaryProjectionReport) {
    const salaryProjectionReport = {
      title: "Salary Projection Report",
      link: Url.SALARY_PROJECTION_REPORT,
    };
    salaryReport.push(salaryProjectionReport);
  }

  return (
    <>
      <PageTitle label="Reports" />
      <div className="accordion mt-3" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingAccountEntry">
            <button
              className={`accordion-button bg-body-tertiary ${accountEntryToggle ? '' : 'collapsed'}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseAccountEntry"
              aria-expanded={accountEntryToggle ? "true" : "false"}
              aria-controls="collapseAccountEntry"
              onClick={() => setAccountEntryToggle(!accountEntryToggle)}
            >
              <h5 className="fw-normal">AccountEntry</h5>
            </button>
          </h2>
          <div
            id="collapseAccountEntry"
            className={`accordion-collapse collapse ${accountEntryToggle ? 'show' : ''}`}
            aria-labelledby="headingAccountEntry"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {accountEntryReport.map((dataList, index) => (
                <div key={index}>
                  <Link to={dataList.link} className="my-2 text-dark text-decoration-none">
                    {dataList.title}
                  </Link>
                  <br />
                </div>
              ))}
            </div>
          </div>
        </div>

        {salaryReport.length > 0 && (
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSalary">
              <button
                className={`accordion-button bg-body-tertiary ${salaryToggle ? '' : 'collapsed'}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSalary"
                aria-expanded={salaryToggle ? "true" : "false"}
                aria-controls="collapseSalary"
                onClick={() => setSalaryToggle(!salaryToggle)}
              >
                <h5 className="fw-normal">Salary</h5>
              </button>
            </h2>
            <div
              id="collapseSalary"
              className={`accordion-collapse collapse ${salaryToggle ? 'show' : ''}`}
              aria-labelledby="headingSalary"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                {salaryReport.map((dataList, index) => (
                  <div key={index}>
                    <Link to={dataList.link} className="my-2 text-dark text-decoration-none">
                      {dataList.title}
                    </Link>
                    <br />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountEntryList;
