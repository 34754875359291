import { faAddressCard, faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";
import { faTableCellsLarge } from "@fortawesome/free-solid-svg-icons";
import { hasPermission } from "../services/UserRolePermissionService";
import Permission from "../helpers/Permission";

export function getJobsNavList() {

  const showCandidate = hasPermission(Permission.CANDIDATE_VIEW);

  const showJobs = hasPermission(Permission.JOB_VIEW);

  let arrayList = [];

  arrayList = arrayList.concat({
    name: "Dashboard",
    url: "/job/dashboard",
    icon: faTableCellsLarge,

  });

  if (showJobs) {
    arrayList = arrayList.concat({
      name: "Jobs",
      url: "/jobs",
      detailsPageurl: "/job/detail",
      icon: faAddressCard,
    });
  }

  if (showCandidate) {
    arrayList = arrayList.concat({
      name: "Candidates",
      url: "/jobs/candidate",
      detailsPageurl: "/jobs/candidate/",
      icon: faUsersViewfinder,
    });
  }

  return arrayList;
}
