import React, { useEffect, useState } from "react";
import Link from "../../../components/Link";
import Spinner from "../../../components/Spinner";
import UserCard from "../../../components/UserCard";
import ArrayList from "../../../lib/ArrayList";
import { toHoursAndMinutes } from "../../../lib/HoursAndMinutes";
import { Tables } from "./Table";

function ReportTable(props) {
    const { detail, setPage, page, history, arrays, pageSize, totalCount, getDetails, divRefs } = props;
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (detail && detail.length > 0 && ArrayList.isArray(detail[0]?.columns)) {
            setIsLoading(false);
        } else if (!ArrayList.isArray(detail[0]?.columns)) {
            setIsLoading(false);
        }
    }, [detail]);

    let isColumnExiste = (columnName) => {
        const isExiste = arrays && ArrayList.getKeyByValue(arrays, columnName) ? true : false;
        return isExiste
    }

    const mappedDetail = ArrayList.isArray(detail[0]?.columns)
        ? detail[0]?.columns.map(item => ({
            attendance_type_name: item?.attendance_type_name
        }))
        : [];


    const generateAttendanceColumns = (attendanceTypes) => {
        return ArrayList.isArray(attendanceTypes)
            ? attendanceTypes
                .filter(detail => isColumnExiste(detail?.attendance_type_name))
                .map((detail, index) => ({
                    Header: detail?.attendance_type_name,
                    accessor: `${detail?.attendance_type_name?.toLowerCase()?.replace(/\s+/g, "_")}_column`,
                    sortType: "basic",
                    Cell: ({ row }) => (
                        <div>
                            <Link
                                text={row.original[`${detail?.attendance_type_name?.toLowerCase()?.replace(/\s+/g, "_")}_column`]}
                                url={`/attendance?&startDate=${row?.original?.startDate}&endDate=${row?.original?.endDate}&user=${row?.original?.id}&type=${row?.original[`${detail?.attendance_type_name?.toLowerCase()?.replace(/\s+/g, "_")}_column`]}`}
                                className="link-dark link-underline link-underline-opacity-0"
                            />
                        </div>
                    )
                }))
            : [];
    };

    const attendanceColumns = generateAttendanceColumns(mappedDetail);

    const columns = React.useMemo(() => [
        {
            Header: " ",
            columns: [
                {
                    Header: "Name",
                    accessor: "userName",
                    sortType: "basic",
                    Cell: ({ cell: { row: { original: { firstName, LastName, media_url } } } }) => (

                        <div className="text-start">
                            <UserCard
                                firstName={firstName}
                                lastName={LastName}
                                url={media_url}
                            />
                        </div>
                    )
                }
            ]
        },
        {
            Header: "Attendance ( In days )",
            columns: attendanceColumns
        },
        {
            Header: " ",
            columns:
                isColumnExiste("additional_hours") ? [
                    {
                        Header: "Additional Hours",
                        accessor: "additional_hours",
                        sortType: "basic",
                        Cell: row => <div>{toHoursAndMinutes(row?.value)}</div>
                    }
                ] : []
        }
    ], [attendanceColumns]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <Tables
            columns={columns}
            Values={detail}
            setPage={setPage}
            page={page}
            pageSize={pageSize}
            totalCount={totalCount}
            history={history}
            getDetails={getDetails}
            divRefs={divRefs}
        />
    );
}

export default ReportTable;
