// API Client
import { apiClient } from "../apiClient";
import { endpoints } from "../api/endPoints";
import { CompanyUserCreateError, receiveCreateCompanyUser, requestCreateCompanyUser } from "../actions/companyUser";
import Toast from "../components/Toast";
import { fetchList } from "../actions/table";
import { User } from "../helpers/User";
import { isBadRequest } from "../lib/Http";
import Url from "../lib/Url";

class CompanyUserService {

  static async get(id) {
    try {
      if (id) {
        const response = await apiClient.get(`${endpoints().userAPI}/${id}`);
        const data = response && response.data;
        if (data) return data;
      };
    } catch (err) {
      console.log(null, err);
    }
  }

  static async getLoggedInUser() {
    try {
      const response = await apiClient.get(`${endpoints().userAPI}/`);
      
      return response && response.data;

    } catch (err) {
      console.log(null, err);
    }
  }

  // Search results
  static async search() {
    try {
      let response = await apiClient.get(`${endpoints().userAPI}/search`);
      const data = response && response.data && response.data.data
      return data;
    } catch (err) {
      console.log(null, err);
    }
  }

  static slackUpdate = (id, data) => {
    apiClient
      .put(`${endpoints().userAPI}/slack/update/${id}`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
        }
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  static async create(data, callback) {

         await apiClient
          .post(`${endpoints().userAPI}`, data).then((response)=>{
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              Toast.success(successMessage);
              return callback && callback(successMessage)
            } 
          }).catch((error)=>{
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
              console.error(errorMessage);
            }
            
          })
     
  }

}
export default CompanyUserService;
