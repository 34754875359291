import React, { useState } from "react";
import DeviceList from "../mobileApp/deviceList";
import PageTitle from "../../components/PageTitle";
import DeleteModal from "../../components/DeleteModal";
import DeviceInfoService from "../../services/DeviceInfoService";
import { useDispatch } from "react-redux";
import { fetchList } from "../../actions/table";
import { endpoints } from "../../api/endPoints";
import Url from "../../lib/Url";
import Action from "../../components/Action";
import { toast } from "react-toastify";

const Devices = (props) => {
  let { history } = props;

  const [selectedIds, setSelectedIds] = useState([]);
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);

  let dispatch = useDispatch();

  const actionsMenuList = [
    {
      value: "Bulk Delete",
      label: "Bulk Delete",
    },
  ];

  const onBulkSelect = (ids) => {
    setSelectedIds(ids);
  };

  const handleActionChange = (e) => {
    if (e == "Bulk Delete") {
      if (selectedIds && selectedIds.length > 0) {
        setIsDeleteModel(true);
      } else {
        toast.error("Select Atleast One Item");
      }
    }
  };

  const deviceBulkDelete = async (selectedIds) => {
    let ids = { selectedId: selectedIds };

    if (selectedIds && selectedIds.length > 0) {
      await DeviceInfoService.bulkDelete(ids, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "deviceInfo",
              `${endpoints().userDeviceInfo}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams(),
              }
            )
          );
          setIsDeleteModel(false);
          setSelectedIds([]);
          setSelectedCheckBox(false);
          setSelectedCheckBox(true);
        }
      });
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title="Device"
        id={selectedIds?.id}
        label={"Bulk Delete"}
        deleteFunction={() => {
          deviceBulkDelete(selectedIds);
        }}
      />
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle label="Devices" />
        <div className="mb-3">
          <Action
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}
          />
        </div>
      </div>
      <DeviceList
        history={history}
        onBulkSelect={onBulkSelect}
        selectedCheckBox={selectedCheckBox}
      />
    </>
  );
};

export default Devices;
