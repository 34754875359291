export const CsvSheetOptions = [
  {
    label: "B2B",
    value: "B2B",
  },
];

export const gstB2bColumns = [
  {
    label: "GSTIN of supplier",
    value: "gst_number",
  },
  {
    label: "Trade/Legal name",
    value: "supplier_name",
  },
  {
    label: "Invoice number",
    value: "invoice_number",
  },
  {
    label: "Invoice type",
    value: "invoice_type",
  },
  {
    label: "Invoice Date",
    value: "invoice_date",
  },
  {
    label: "Invoice Value(₹)",
    value: "invoice_value",
  },
  {
    label: "Place of supply",
    value: "place_of_supply",
  },
  {
    label: "Supply Attract Reverse Charge",
    value: "supply_attract_reverse_charge",
  },
  {
    label: "Rate(%)",
    value: "rate",
  },
  {
    label: "Taxable Value (₹)",
    value: "taxable_value",
  },
  {
    label: "Integrated Tax(₹)",
    value: "integrated_tax",
  },
  {
    label: "Central Tax(₹)",
    value: "central_tax",
  },
  {
    label: "State/UT Tax(₹)",
    value: "state_ut_tax_amount",
  },
  {
    label: "Cess(₹)",
    value: "cess",
  },
  {
    label: "GSTR-1/IFF/GSTR-5 Period",
    value: "gstr1_filing_period",
  },
  {
    label: "GSTR-1/IFF/GSTR-5 Filing Date",
    value: "gstr1_filing_date",
  },
  {
    label: "ITC Availability",
    value: "itc_availability",
  },
  {
    label: "Reason",
    value: "reason",
  },
  {
    label: "Applicable % of Tax Rate",
    value: "applicable_tax_rate_percentage",
  },
  {
    label: "Source",
    value: "source",
  },
  {
    label: "IRN",
    value: "irn",
  },
  {
    label: "IRN Date",
    value: "irn_date",
  },
];
