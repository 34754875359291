import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import ArrayList from "../lib/ArrayList";
import { isBadRequest } from "../lib/Http";
import Url from "../lib/Url";


class LocationAllocationUserService {

    static create(data,callback){

        try {
            apiClient
              .put(`${endpoints().LocationAllocationUserAPI}`, data)
              .then((response) => {
                let successMessage;
                if (response && response.data) {
                  successMessage = response.data.message;
                  Toast.success(successMessage);
                  return callback && callback(successMessage)
                }
              })
              .catch((error) => {
                if (isBadRequest(error)) {
                  let errorMessage;
                  const errorRequest = error.response.request;
                  if (errorRequest && errorRequest.response) {
                    errorMessage = JSON.parse(errorRequest.response).message;
                  }
                  Toast.error(errorMessage);
                }
              });
          }catch(err){
              console.log(err);
          }

    }
    static update(data,callback){

      try {
          apiClient
            .put(`${endpoints().LocationAllocationUserAPI}/update`, data)
            .then((response) => {
              let successMessage;
              if (response && response.data) {
                successMessage = response.data.message;
                Toast.success(successMessage);
                return callback && callback(successMessage)
              }
            })
            .catch((error) => {
              if (isBadRequest(error)) {
                let errorMessage;
                const errorRequest = error.response.request;
                if (errorRequest && errorRequest.response) {
                  errorMessage = JSON.parse(errorRequest.response).message;
                }
                Toast.error(errorMessage);
              }
            });
        }catch(err){
            console.log(err);
        }

  }

    static async search(params) {
        try {
          let queryString = await ArrayList.toQueryString(params);
          const { data } = await Url.get(
            `${endpoints().LocationAllocationUserAPI}/search`,
            queryString
          );
    
          return data;
        } catch (err) {
          console.log(err);
        }
      }

      static updateStatus(data,callback){

        try {
            apiClient
              .put(`${endpoints().LocationAllocationUserAPI}/statusUpdate`, data)
              .then((response) => {
                let successMessage;
                if (response && response.data) {
                  successMessage = response.data.message;
                  Toast.success(successMessage);
                  return callback && callback(successMessage)
                }
              })
              .catch((error) => {
                if (isBadRequest(error)) {
                  let errorMessage;
                  const errorRequest = error.response.request;
                  if (errorRequest && errorRequest.response) {
                    errorMessage = JSON.parse(errorRequest.response).message;
                  }
                  Toast.error(errorMessage);
                }
              });
          }catch(err){
              console.log(err);
          }

    }

    static resetToDefault(id,callback){

      try {
          apiClient
            .put(`${endpoints().LocationAllocationUserAPI}/resetToDefault/${id}`)
            .then((response) => {
              let successMessage;
              if (response && response.data) {
                successMessage = response.data.message;
                Toast.success(successMessage);
                return callback && callback(successMessage)
              }
            })
            .catch((error) => {
              if (isBadRequest(error)) {
                let errorMessage;
                const errorRequest = error.response.request;
                if (errorRequest && errorRequest.response) {
                  errorMessage = JSON.parse(errorRequest.response).message;
                }
                Toast.error(errorMessage);
              }
            });
        }catch(err){
            console.log(err);
        }

  }
    
    
}

export default LocationAllocationUserService;