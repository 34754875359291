import React, { useEffect, useState } from "react";

// Components
import PageTitle from "../../components/PageTitle";
import BillForm from "./components/billForm";
import BillList from "./components/billList";

// Helpers
import Drawer from "../../components/Drawer";
import { hasPermission } from "../../services/UserRolePermissionService";
import Permission from "../../helpers/Permission";

const Bill = (props) => {
  const {
    history,
    showBillList,
    handleBillModal,
    vendorDetails,
    handleBillCloseModal,
    billModal,
    purchase_id,
    apiUrl,
    showAccountFilter=true
  } = props;
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const [rowValue, setRowValue] = useState(null);
  const [isSubmit, setIsSubmit] = useState(true);

  let showAddButton = hasPermission(Permission.BILL_ADD);
  useEffect(() => {
    if (handleBillModal) {
      setStoreModalOpen(true);
    }
  }, [handleBillModal]);

  const StoreSelectModal = () => {
    setStoreModalOpen(!storeModalOpen);
    setIsSubmit(true);
    if (storeModalOpen) {
      setRowValue("");
    }
    if (handleBillCloseModal) {
      handleBillCloseModal();
    }
  };

  const addBillForm = (
    <BillForm
      history={history}
      billData={rowValue}
      billId={rowValue && rowValue.id}
      StoreSelectModal={StoreSelectModal}
      showAccountDetailsPageLink={
        rowValue?.id && rowValue?.account_id ? true : false
      }
      accountId={rowValue?.id ? rowValue?.account_id : null}
      vendorId={vendorDetails && vendorDetails?.vendorId}
      vendorDetails={vendorDetails}
      handleCancel={StoreSelectModal}
      form={rowValue && rowValue?.id ? "Edit" : "Add"}
      setIsSubmit={setIsSubmit}
      setStoreModalOpen={setStoreModalOpen}
      handleBillCloseModal={handleBillCloseModal}
      purchaseId={purchase_id}
      apiUrl={apiUrl}
    />
  );

  return (
    <>
      <Drawer
        modelTitle={rowValue?.id ? "Edit Bill" : "Add Bill"}
        DrawerBody={addBillForm}
        enableReinitialize
        handleOpenModal={StoreSelectModal}
        handleCloseModal={StoreSelectModal}
        handleDrawerClose={StoreSelectModal}
        isModalOpen={storeModalOpen || billModal}
        // buttonLabel={buttonLabel}
      />
      {!showBillList && showAddButton && (
        <PageTitle
          label="Bills"
          buttonHandler={(_e) => {
            StoreSelectModal();
            setRowValue("");
          }}
          buttonLabel="Add New"
        />
      )}
      {/* {!showBillList && ( */}
      <BillList
        history={history}
        StoreSelectModal={StoreSelectModal}
        setRowValue={setRowValue}
        rowValue={rowValue}
        vendorId={vendorDetails && vendorDetails?.vendorId}
        purchase_id={purchase_id}
        apiUrl={apiUrl}
        showAccountFilter={showAccountFilter}
      />
      {/* )} */}
    </>
  );
};
export default Bill;
