import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Components
import AddButton from "../../components/AddButton";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import UserCard from "../../components/UserCard";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import VisitorForm from "./visitorForm";

// Services
import visitorService from "../../services/VisitorService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Lib
import Numbers from "../../lib/Number";
import ArrayList from "../../lib/ArrayList";
import Url from "../../lib/Url";

// Helpers
import { TagTypeName } from "../../helpers/Tag";
import MediaUpload from "../../helpers/MediaUpload";
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";

// API
import { endpoints } from "../../api/endPoints";

const Visitor = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rowValue, setRowValue] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [isSubmit, setIsSubmit] = useState(true);
  const [userList, setUserList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [position, setPosition] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [imageurl, setImageUrl] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [jobList, setJobList] = useState([]);

  const dispatch = useDispatch();

  let hasDeletePermission = hasPermission(Permission.VISITOR_DELETE);

  let hasEditPermission = hasPermission(Permission.VISITOR_EDIT);

  let hasAddPermission = hasPermission(Permission.VISITOR_ADD);

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most recent",
    },
  ];

  const toggle = () => {
    setIsOpen(!isOpen);
    setIsSubmit(true);
  };

  const onDrop = (acceptedFiles) => {
    handleImageValue({
      ...acceptedFiles,
      id: selectedFile.length + 1,
    });
  };

  const handleImageValue = (images) => {
    setIsLoading(true);
    setSelectedFile([...selectedFile, { ...images }]);
    setIsLoading(false);
  };

  const handleImageRemove = async (deletedvalue) => {
    if (deletedvalue) {
      const updatedImageUrlArray = selectedFile.filter(
        (item) => item.id !== deletedvalue.image_id
      );
      await setIsLoading(true);
      setSelectedFile(updatedImageUrlArray);
      setImageUrl(updatedImageUrlArray);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      getUrl();
    }
  }, [isLoading, selectedFile]);

  const getUrl = () => {
    let url = [];
    if (selectedFile && selectedFile.length > 0) {
      for (let i = 0; i < selectedFile.length; i++) {
        const file = selectedFile[i];
        const imageUrl = URL.createObjectURL(file && file[0]);
        url.push({ url: imageUrl, image_id: file.id });
      }
      setImageUrl && setImageUrl(url);
    }
  };


  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const visitorForm = (
    <VisitorForm
      setUserList={setUserList}
      handleTypeChange={handleTypeChange}
      selectedType={selectedType}
      rowValue={rowValue}
      showUserDetailsPageLink={
        rowValue?.id && rowValue?.person_to_meet ? true : false
      }
      userId={rowValue?.id ? rowValue?.person_to_meet : null}

      imageUrl={imageurl}
      onDrop={onDrop}
      selectedFile={selectedFile}
      handleDelete={handleImageRemove}
      isAddPage={rowValue ? false : true}
      setStoreList={setStoreList}
      setJobList={setJobList}
    />
  );

  const initialValues = {
    visitor: rowValue ? rowValue?.name : "",
    mobileNumber: rowValue ? rowValue?.phone : "",
    purpose: rowValue ? rowValue?.purpose : "",
    title: rowValue ? rowValue?.title : "",
    notes: rowValue ? rowValue?.notes : "",
    type: rowValue
      ? {
        value: rowValue?.type,
        label: rowValue?.typeName,
      }
      : "",
    person_to_meet: rowValue
      ? userList && userList.find((data) => data.id == rowValue?.person_to_meet)
      : "",
    date: rowValue?.created_at ? rowValue?.created_at : "",
    location: rowValue?.location_id ? storeList.find((data) => data?.id == rowValue?.location_id) : "",
    position: rowValue ? jobList.find((data) => data?.id == rowValue?.position) : "",
  };

  const visitorFooter = (
    <SaveButton
      type="submit"
      loading={isSubmit == false}
      label={rowValue?.id ? "Save" : "Add"}
    />
  );

  const params = {
    search: Url.GetParam("search"),
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    visitorType: Url.GetParam("visitorType"),
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate"),
    date: Url.GetParam("date"),
  };

  const uploadMedia = async (id) => {
    if (ArrayList.isArray(selectedFile)) {
      let mediaParams;
      for (let i = 0; i < selectedFile.length; i++) {
        mediaParams = {
          selectedFile: selectedFile[i][0] && selectedFile[i][0],
          objectId: id,
          ObjectName: ObjectName.VISITOR,
        };
        await MediaUpload.uploadFile(mediaParams, () => { });
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmit(true);
      const data = new FormData();
      data.append("name", values?.visitor);
      data.append("mobileNumber", values?.mobileNumber);
      data.append("purpose", values?.purpose);
      data.append("title", values?.title);
      data.append("notes", values?.notes);
      data.append("type", Numbers.Get(values?.type?.value));
      data.append("person_to_meet", values?.person_to_meet?.id);
      data.append("location", values?.location?.id);
      data.append("position", values?.position?.id);
      data.append("media_file", selectedFile ? selectedFile : "");
      dispatch(
        await visitorService.add(data, params, async (res) => {
          await uploadMedia(res?.id)
          toggle();
          setIsSubmit(false);
          setSelectedFile("");
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  const toggleModelClose = () => {
    setRowValue("");
    setSelectedFile("");
    setSelectedType("");
    toggle();
    setIsSubmit(true);
  };

  const handleUpdate = async (values) => {
    const data = new FormData();
    data.append("name", values?.visitor ? values?.visitor : "");
    data.append("phone", values?.mobileNumber ? values?.mobileNumber : "");
    data.append("purpose", values?.purpose ? values?.purpose : "");
    data.append(
      "person_to_meet",
      values?.person_to_meet ? values?.person_to_meet?.id : ""
    );
    data.append("location_id", values?.location?.id);
    data.append("position", values?.position?.id ? values?.position?.id : "");

    data.append("title", values?.title ? values?.title : "");
    data.append("notes", values?.notes ? values?.notes : "");
    data.append(
      "type",
      Numbers.Get(values?.type?.value ? values?.type?.value : "")
    );
    dispatch(
      await visitorService.update(
        rowValue?.id,
        data,
        params,
        {},
        {},
        toggle,
        dispatch
      )
    );
  };

  const deleteFunction = async () => {
    dispatch(
      await visitorService.delete(rowValue?.id, null, {
        ...params,
      })
    );
    setRowValue("");
    setIsDeleteOpen(false);
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteOpen}
        toggle={() => {
          setIsDeleteOpen(false);
        }}
        title="Delete Visitor"
        id={rowValue?.id}
        label={rowValue?.name}
        deleteFunction={deleteFunction}
      />
      <Drawer
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggleModelClose}
        isModalOpen={isOpen}
        enableReinitialize
        initialValues={initialValues}
        DrawerBody={visitorForm}
        DrawerFooter={visitorFooter}
        modelTitle={`${rowValue?.id ? "Edit" : "Add"} Visitor`}
        onSubmit={(values) => {
          if (rowValue?.id) {
            handleUpdate(values);
          } else {
            handleSubmit(values);
          }
        }}
      />
      <div className="d-flex justify-content-between pb-3">
        <PageTitle label="Visitors" />
        {hasAddPermission && (
          <div className="d-flex">
            <AddButton
              label="Add New"
              onClick={() => {
                toggle();
                setRowValue("");
                setSelectedType("");
              }}
            />
          </div>
        )}
      </div>

      <ReduxTable
        id="visitors"
        showHeader
        searchPlaceholder="Search"
        newTableHeading
        icon={<FontAwesomeIcon icon={faCubes} />}
        message="You can start by clicking on Add New"
        apiURL={`${endpoints().visitor}/search`}
        sortByOptions={sortByOption}
        showVisitorTypeFilter
        tagPlaceholder={"Visitor Type"}
        tagFilterType={{ type: TagTypeName.VISITOR_TYPE }}
        paramsToUrl={true}
        history={props.history}
        showCustomDateFilter
      >
        <ReduxColumn
          className="text-start"
          width="210px"
          sortBy="name"
          minWidth="210px"
          maxWidth="210px"
          renderField={(row) => (
            <Link
              to={`/visitor/${row.id}`}
              className="link-opacity-75 no-underline text-blue-600 hover:underline"
            >
              <UserCard
                customSize={parseInt(50, 10)}
                firstName={row.name}
                url={row.media_url}
                mobileNumber={row.phone}
              />
            </Link>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="typeName"
          sortBy="typeName"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Type
        </ReduxColumn>
        <ReduxColumn
          field="purpose"
          width="170px"
          minWidth="170px"
          maxWidth="170px"
          sortBy="purpose"
        >
          Purpose
        </ReduxColumn>
        <ReduxColumn
          field="locationName"
          width="170px"
          minWidth="170px"
          maxWidth="170px"
          sortBy="location"
          className="text-center"
        >
          Location
        </ReduxColumn>
        <ReduxColumn
          field="created_at"
          sortBy="created_at"
          className="text-center"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          renderField={(row) => <span>{row.created_at}</span>}
        >
          Created At
        </ReduxColumn>
        {(hasEditPermission || hasDeletePermission) && (
          <ReduxColumn
            field="Action"
            disableOnClick
            width="70px"
            renderField={(row) => (
              <>
                <div className="text-center action-group-dropdown">
                  <MoreDropdown>
                    {hasEditPermission && (
                      <DropdownItem
                        onClick={() => {
                          toggle();
                          setRowValue(row);
                        }}
                      >
                        Quick View
                      </DropdownItem>
                    )}
                    {hasDeletePermission && (
                      <DropdownItem
                        className="text-danger"
                        onClick={() => {
                          setRowValue(row);
                          setIsDeleteOpen(true);
                        }}
                      >
                        Delete
                      </DropdownItem>
                    )}
                  </MoreDropdown>
                </div>
              </>
            )}
          >
            Action
          </ReduxColumn>
        )}
      </ReduxTable>
    </>
  );
};

export default Visitor;
