import React, { useEffect, useRef, useState } from "react";

// Components
import Spinner from "../../components/Spinner";
import PageTitle from "../../components/PageTitle";

// End Points
import { endpoints } from "../../api/endPoints";

// API client
import { apiClient } from "../../apiClient";

import ReportTable from "./ReportTable";
import BreadCrumb from "../../components/Breadcrumb";
import Url from "../../lib/Url";
import StockReportFilter from "./StockReportFilter";
import PdfDownload from "./Pdf";
import Action from "../../components/Action";
import ProductWiseStockReport from "./ProductWiseStockReport";
import { Product, Status } from "../../helpers/Product";
import ArrayList from "../../lib/ArrayList";
import DateTime from "../../lib/DateTime";
import Pagination from "../../components/Pagination";
import NoRecordsFound from "../../components/NoRecordsFound";

const stockReport = (props) => {
  const [detail, setDetail] = useState([]);
  const [detailValue, setDetailValue] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(Url.GetParam("pageSize"||25));
  const [storeList, setStoreList] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Over All");
  const [detailData, setDetailData] = useState([]);

  const [productParams, setProductParams] = useState({});

  const [storeData, setStoreData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModel = () => {
    setIsModalOpen(!isModalOpen);
  };
  useEffect(() => {
    getDetails(productParams);
  }, [page]);

  useEffect(() => {
    getFilterData(productParams);
  }, [detailData, storeData]);

  // Actions menu list
  const actionOptions = [
    {
      label: "Over All",
      value: "Over All"
    },
    {
      label: "Product Wise",
      value: "Product Wise"
    }
   
  ];

  const handleChange = (e) => {
    setSelectedOption(e);
  };
  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report"
    },
    {
      label: "Stock Report",
      link: ""
    }
  ];

  const getDetails = async (productParams) => {
    const queryString = [];

    let response;
    if (productParams) {
      Object.keys(productParams).forEach((param) => {
        queryString.push(`${param}=${productParams[param]}`);
      });
    }
    if (queryString && queryString.length > 0) {
      if (
        productParams.brand !== "" ||
        productParams.category !== "" ||
        productParams.tag !== "" ||
        productParams.search !== "" ||
        productParams.location !== ""
      ) {
        response = await apiClient.get(
          `${endpoints().stockReportAPI}/search?${queryString.join("&")}`
        );
      } else {
        response = "";
      }
    }

    setDetailData(
      response && response.data && response.data.data && response.data.data.data
    );

    let stores =
      response &&
      response.data &&
      response.data.data &&
      response.data.data.storeData;

    let locationName = [];
    setStoreData(stores);
    if (stores && stores.length > 0) {
      for (let i = 0; i < stores.length; i++) {
        let store = stores[i];
        locationName.push(store.name);
      }
      setStoreList(locationName);
    }
  };

  const getFilterData = async (productParams) => {
    try {
      let productList = [];
  
      const queryString = [];
      productParams.page = page;
      productParams.pageSize = pageSize      
      productParams.pagination = false;
      if (productParams.sort !== "") {
        productParams.sort = "";
      }
  
      if (productParams.sortDirection !== "") {
        productParams.sortDirection = "";
      }
      let response;
  
      if (productParams) {
        Object.keys(productParams).forEach((param) => {
          queryString.push(`${param}=${productParams[param]}`);
        });
      }
      if (queryString && queryString.length > 0) {
        if (
          productParams.brand != "" ||
          productParams.category != "" ||
          productParams.tag != "" ||
          productParams.search != "" ||
          productParams.location !=""
        ) {
          response = await apiClient.get(
            `${endpoints().productAPI}/search?${queryString.join("&")}`
          );
        } else {
          response = "";
        }
      }
      setDetailValue(response?.data);
      const MainProductIndexData = response?.data?.data;
  
      if (MainProductIndexData?.length) {
        const detailDataMap = new Map();
        if (detailData?.length) {
          detailData.forEach((item) => {
            if (!detailDataMap.has(item.product_id)) {
              detailDataMap.set(item.product_id, []);
            }
            detailDataMap.get(item.product_id).push(item);
          });
        }
  
        const storeDataMap = new Map();
        storeData.forEach((store) => storeDataMap.set(store.id, store));
  
        MainProductIndexData.forEach((productIndex) => {
          const allStoreProductList = detailDataMap.get(productIndex.id) || [];
  
          let maxQuantity = 0;
          let quantity = 0;
          let total_order_quantity = 0;
  
          allStoreProductList.forEach((storeValues) => {
            maxQuantity += storeValues.max_quantity;
            quantity += storeValues.quantity;
            total_order_quantity += Number(storeValues.order_quantity);
          });
  
          const required_quantity = maxQuantity - quantity;
  
          const storeProductArray = storeData.map((store) => {
            const storeProductData = allStoreProductList.find(
              (data) => data.store_id === store.id
            );
  
            return {
              quantity: storeProductData?.quantity || "",
              min_quantity: storeProductData?.min_quantity || "",
              max_quantity: storeProductData?.max_quantity || "",
              last_order_date: storeProductData?.last_order_date
                ? DateTime.getDate(storeProductData.last_order_date)
                : "",
              last_stock_entry_date: storeProductData?.last_stock_entry_date
                ? DateTime.getDate(storeProductData.last_stock_entry_date)
                : "",
              order_quantity: storeProductData?.order_quantity || "",
            };
          });
  
          productList.push({
            product_id: productIndex.id,
            product_name: productIndex.name || "",
            brand: productIndex.brand || "",
            brand_id: productIndex.brand_id || "",
            image: productIndex.image || "",
            amount: productIndex.price || "",
            mrp: productIndex.mrp || "",
            size: productIndex.size || "",
            unit: productIndex.unit || "",
            unit_price: productIndex.sale_price || "",
            price: productIndex.sale_price * productIndex.quantity,
            packSize: productIndex.pack_size || "",
            sale_price: productIndex.sale_price || "",
            required_quantity: required_quantity || 0,
            available_quantity: quantity || 0,
            storeProduct: storeProductArray,
            total_order_quantity: total_order_quantity,
            status: productIndex.status,
          });
        });
      }
  
      let data = [];
  
      if (productParams.sort !== "product_name") {
        data = ArrayList.sort(productList, productParams.sort);
      } else {
        data = ArrayList.sort(
          productList,
          productParams.sort,
          productParams.sortDirection
        );
      }
      let pageSizeValue = pageSize ? pageSize : 25;
  
      const offset = (page - 1) * pageSizeValue;

      let offsetValue = parseInt(offset) + parseInt(pageSizeValue);

      const productData = data.slice(offset, offsetValue);
  
      setDetail(productData);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  
  const currentPage = page;
  const totalCounts = detailValue?.totalCount;
  const pageSizes = detailValue?.pageSize;
  const startPage = (currentPage - 1) * detailValue?.pageSize + 1;
  const firstPage =
    startPage > detailValue?.totalCount ? detailValue?.totalCount : startPage;
  const endPage = currentPage * detailValue?.pageSize;
  const lastPage =
    endPage > detailValue?.totalCount ? detailValue?.totalCount : endPage;
  return (
    <>
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex mx-1 justify-content-between mb-3">
        <PageTitle label="Stock Report" />
        <Action dropdownLinks={actionOptions} handleChange={handleChange} />
      </div>

      <div id="download-as-pdf">
        <PdfDownload detail={detail} storeList={storeList} />
      </div>

      <div className="card card-body">
        <StockReportFilter
          getDetails={getDetails}
          setPage={setPage}
          setPageSize={setPageSize}
          getFilterData={getFilterData}
          pageSize={pageSize}
          productParams={productParams}
          setProductParams={setProductParams}
          history={props.history}
          openModel={openModel}
          isModalOpen={isModalOpen}
        />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          {selectedOption == "Over All" && (Url.GetParam("location") || Url.GetParam("tag") || Url.GetParam("brand") || Url.GetParam("category")) ? (
            <ReportTable
              detail={detail}
              detailValue={detailValue}
              storeList={storeList}
              setPage={setPage}
              page={page}
            />
          ) : selectedOption !== "Over All" && (Url.GetParam("location") || Url.GetParam("tag") || Url.GetParam("brand") || Url.GetParam("category")) ? (
            <ProductWiseStockReport
              detail={detail}
              detailValue={detailValue}
              storeList={storeList}
            />
          ) :  <div className="mt-5 pt-5">
          <NoRecordsFound
            showMessage
            boldMessage="No Records Found"
            description="You can start by apply filter"
          />
        </div>}
        </div>
      )}
       {totalCounts > 0  && (Url.GetParam("location") || Url.GetParam("tag") || Url.GetParam("brand") || Url.GetParam("category")) && (
        <div className="d-flex justify-content-between">
          <div>
            Showing {firstPage} to {lastPage} of {totalCounts} entries
          </div>

          <Pagination
            currentPage={page}
            totalCount={totalCounts}
            pageSize={pageSizes}
            onPageChange={(page) => {
              setPage(page);
            }}
          />
        </div>
      )}
    </>
  );
};

export default stockReport;
