import React, { useEffect, useState } from 'react';
import PageTitle from "../../../components/PageTitle";
import Drawer from "../../../components/Drawer";
import Text from "../../../components/Text";
import SaveButton from "../../../components/SaveButton";
import { useDispatch } from "react-redux";
import PageBlockService from "../../../services/PageBlockService";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import { DropdownItem } from "reactstrap";
import Spinner from "../../../components/Spinner";
import BreadCrumb from "../../../components/Breadcrumb";
import DeleteModal from "../../../components/DeleteModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import NoRecordsFound from "../../../components/NoRecordsFound";

const PageBlockListPage = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [row, setRow]=useState(null);
  const [deleteModal, setDeleteModal]=useState(false)
  let dispatch = useDispatch();

  useEffect(() => {
    getDetails()
  }, [row])

  const getDetails = async () => {
    setIsLoading(true);
    let data = await PageBlockService.search({});
    setRows(data)
    setIsLoading(false);
  };

  let modelBody = (
    <>
      <Text name="title" label="Title" required />
    </>
  );

  let modelFooter = (
    <>
      <div className="d-flex align-items-center">
        <SaveButton type="submit" label={row?.id ? "Edit": "Add"} />
      </div>
    </>
  )


  let initialValues = {
    title: row && row?.title  ? row?.title :""
  }


  const openToggle = () => {
    setIsOpen(!isOpen)
  }

  const closeToggle = () => {
    setIsOpen(!isOpen)
    setRow("")
  }

  const closeDeleteToggle = () => {
    setDeleteModal(false)
    setRow(null)
}

  const handleSubmit = async (values) => {
    let data = new FormData();
    data.append("title", values && values?.title ? values?.title : "");
    if(row && row?.id){
      dispatch(await PageBlockService.update(row?.id,data, (res) => {
        getDetails();
        closeToggle()
      }))
    }else{
      dispatch(await PageBlockService.create(data, (res) => {
        getDetails();
        closeToggle()
      }))
    }
  }

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const draggedRow = rows[result.source.index];
    const newRows = [...rows];
    newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, draggedRow);

    newRows.forEach((item, index) => {
      item.index = index;
    });
    setRows(newRows);
    await PageBlockService.updateOrder(newRows);
  };

  const deletePageBlock = async (id) => {
    dispatch(await PageBlockService.delete(id,(res)=>{
      getDetails()
      setDeleteModal(false);
     }))
  };

  const breadCrumbList = [
    { label: "Settings", link: "/setting/Home" },
    {
      label: "Home",
      link: "/setting/Home",
    },
    {
      label: "Page Block",
      link: "",
    },
  ];



  if (isLoading) {
    return <Spinner />
  }


  return (
    <div>
      <BreadCrumb list={breadCrumbList} />
      <DeleteModal
        label={row?.title}
        isOpen={deleteModal}
        toggle={closeDeleteToggle}
        title="Delete Page Block"
        deleteFunction={() => deletePageBlock(row?.id)}
      />
      <PageTitle
        label="Page Blocks"
        buttonLabel="Add"
        buttonHandler={() => {
          openToggle();
        }}
      />

      <Drawer
        DrawerBody={modelBody}
        initialValues={initialValues}
        handleOpenModal={openToggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
        enableReinitialize
        modelTitle={row?.id ? "Edit": "Add"}
        DrawerFooter={modelFooter}
        onSubmit={handleSubmit}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="table-responsive">
          <table className="w-100 table table-hover">
            <thead>
              <tr className="table-dark">
                <th className="mx-3 p-3">Title</th>
                <th className="mx-3 p-3">Action</th>
              </tr>
            </thead>
            {rows && rows.length > 0 ? (
              <Droppable droppableId="table-rows">
                {(provided) => (
                  <tbody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="bg-white"
                  >
                    {rows &&
                      rows.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={`table-row-${item.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >

                              <td className="m-2 p-4  text-center fw-normal">
                              <Link className="text-underline" to={`/setting/Statues/Home/pageBlock/${props?.match?.params?.id}/${item.id}`}>
                              {item?.title}
                              </Link>
                              </td>
                              <td className="m-2 p-4 text-center fw-normal">
                                <MoreDropdown>
                                  <DropdownItem
                                    onClick={() => {
                                      setRow(item);
                                      openToggle();
                                    }}
                                  >
                                    Quick View
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setRow(item);
                                      setDeleteModal(true)
                                    }}
                                    className="text-danger"
                                  >
                                    Delete
                                  </DropdownItem>
                                </MoreDropdown>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            ) : (
              <tr>
                <td className="text-center" colspan="6">
                  <NoRecordsFound middleHeight={"70vh"} />
                </td>
              </tr>
            )}
          </table>
        </div>
      </DragDropContext>
    </div>
  );
};

export default PageBlockListPage;
