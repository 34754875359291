import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// API
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Components
import Action from "../../components/Action";
import ActivityList from "../../components/ActivityList";
import AddButton from "../../components/AddButton";
import Banner from "../../components/Banner.js";
import BreadCrumb from "../../components/Breadcrumb";
import CancelButton from "../../components/CancelButton";
import Comment from "../../components/comment";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import HorizontalSpace from "../../components/HorizontalSpace";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Spinner from "../../components/Spinner";
import StatusComponent from "../../components/Status";
import Toast from "../../components/Toast";
import PaymentList from "../payment/components/paymentList.js";
import ProductCard from "../product/components/productCard";
import ProductForm from "./components/productForm";
import PurchaseForm from "./components/purchaseForm";
import Bill from "../bill/index.js";

// Action
import { deletePurchase, updatePurchase } from "../../actions/purchase";
import { fetchList } from "../../actions/table";

// Lib
import Currency from "../../lib/Currency";
import { isBadRequest, SUCCESS_RESPONSE } from "../../lib/Http";
import Number from "../../lib/Number";
import String from "../../lib/String";
import DateTime from "../../lib/DateTime";
import Cookies, { setCookie } from "../../lib/Helper.js";
import Urls from "../../lib/Url";
import ArrayList from "../../lib/ArrayList.js";

// Helpers
import Cookie from "../../helpers/Cookie.js";
import { HttpStatus } from "../../helpers/HttpStatus";
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";
import * as Constants from "../../helpers/Purchase.js";
import Url from "../../helpers/Url";

// Services
import AccountProductService from "../../services/AccountProductService.js";
import BillService from "../../services/BillService";
import PaymentService from "../../services/PaymentService.js";
import PurchaseProductService from "../../services/PurchaseProductService";
import StoreService from "../../services/StoreService.js";
import { hasPermission } from "../../services/UserRolePermissionService";

// Constants//
const Tab = {
  SUMMARY: "Summary",
  PRODUCT: "Products",
  HISTORY: "History",
  PAYMENT: "Payments",
  BILL: "Bills",
  COMMENTS: "Comments",
};

let showEditButton = hasPermission(Permission.PURCHASE_EDIT);

const GeneralDetailsTab = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(
    Urls.GetParam("tab") || Tab.SUMMARY
  );
  const [locationList, setLocationList] = useState([]);
  const [status, setStatus] = useState();
  const [vendorData, setVendorData] = useState();
  const [PermissionList, setPermissionList] = useState();
  const [purchaseData, setPurchaseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [vendorName, setVendorName] = useState();
  const [vendorInvoiceNumber, setVendorInvoiceNumber] = useState();
  const [location, setStore] = useState(null);
  const [descriptionChange, setDescriptionChange] = useState();
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [notes, setNotes] = useState();
  const [vendorInvoiceDate, setVensorInvoiceDate] = useState();
  const [dueDate, setDueDate] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [returnedAmount, setReturnedItemsAmount] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [productIds, setProductIds] = useState([]);
  const [arrayList, setArrayList] = useState([]);
  const [arrays, setArray] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [rowValue, setRowValue] = useState(null);
  const [detail, setDetail] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [reviewerValue, setReviewerValue] = useState(null);
  const [ownerValue, setOwnerValue] = useState(null);

  let storeId = purchaseData && purchaseData?.store_id;

  let purchaseId = purchaseData && purchaseData?.id;

  useEffect(() => {
    getBillDetail();
    getRolePermissions();
  }, [props, storeId]);

  useEffect(() => {
    getBillDetail();
  }, []);

  useEffect(() => {
    const checkedList = Cookies.get(Cookie.PURCHASE_PRODUCT_LIST_COLUMNS);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);

  const id = props.match.params.tab;


  //Get Role Permission
  const getRolePermissions = async () => {
    const statusPermission = hasPermission(Permission.PURCHASE_STATUS_UPDATE);
    setPermissionList(statusPermission);
  };

  // Bread Crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    { label: "Purchases", link: Url.PURCHASE_LIST },
    { label: activeTab },
  ];

  //Get Bill Detail
  const getBillDetail = async () => {
    let id = props?.match?.params?.tab;
    try {
      const response = await apiClient.get(
        `${endpoints().purchaseAPI}/detail/${id}`
      );
      const data = response.data ? response.data : "";
      setVendorData(data);
      setPurchaseData(data.data);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(errorMessage);
        console.error(errorMessage);
      }
    }
  };

  const handleUpdateFromProduct = async () => {
    let data = new FormData();
    data.append("purchase_id", id);
    data.append("productIds", productIds.productIds);

    dispatch(
      await PurchaseProductService.bulkUpdate(data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "purchaseProduct",
              `${endpoints().purchaseProductAPI}/list`,
              Urls.GetParam("page") ? Urls.GetParam("page") : 1,
              Urls.GetParam("pageSize") ? Urls.GetParam("pageSize") : 25,
              {
                purchaseId: id,
                storeId: storeId,
                vendorId: purchaseData && purchaseData?.vendor_id,
                sort: Urls.GetParam("sort"),
                sortDir: Urls.GetParam("sortDir"),
                search: Urls.GetParam("search"),
              }
            )
          );
          getBillDetail()
          setProductIds("");
          setSelectedCheckBox(false);
          setSelectedCheckBox(true);
        }
      })
    );
    setProductIds("");
    setSelectedCheckBox();
  };

  const handleSyncAccountProduct = async () => {
    await dispatch(
      PurchaseProductService.syncToAccountProduct(
        id,
        productIds?.productIds ? productIds?.productIds : [],
        {
          pagination: true,
          sort: Urls.GetParam("sort"),
          sortDir: Urls.GetParam("sortDir"),
          search: Urls.GetParam("search") || "",
          page: Urls.GetParam("page"),
          pageSize: Urls.GetParam("pageSize"),
          storeId,
          purchaseId,
          vendorId: purchaseData?.vendor_id,
        }, () => {
          getBillDetail()
        }
      )
    );
    setProductIds("");
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  const handleSyncToProduct = async () => {
    await dispatch(
      PurchaseProductService.syncToProduct(
        id,
        productIds?.productIds ? productIds?.productIds : [],
        {
          pagination: true,
          sort: Urls.GetParam("sort"),
          sortDir: Urls.GetParam("sortDir"),
          search: Urls.GetParam("search") || "",
          page: Urls.GetParam("page"),
          pageSize: Urls.GetParam("pageSize"),
          storeId,
          purchaseId,
          vendorId: purchaseData?.vendor_id,
        },
        () => {
          getBillDetail()
        }
      )
    );
    setProductIds("");
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
    setRowValue("");
  };

  const toggle = (tab) => {
    setActiveTab(tab);

    props.history.push(`?tab=${tab}`);
  };

  const handleChangeStatus = (selectStatus) => {
    if (selectStatus) {
      setStatus(selectStatus);
    }
    updateBill(id, status, {});
  };

  const paymentType = [
    {
      value: "purchase Payment",
      label: "Purchase Payment",
    },
    {
      value: "Vendor Advance",
      label: "Vendor Advance",
    },
  ];

  const handleChange = (e) => {
    if (e == "edit") {
      setIsEditable(false);
    }
    if (e == "Delete") {
      setDeleteModal(true);
    }

    if (e == "Update From Product") {
      handleUpdateFromProduct();
    }

    if (e == "Update To Product") {
      handleSyncToProduct();
    }

    if (e == "Update To Account Product") {
      handleSyncAccountProduct();
    }
  };

  const paymentTerm = [
    {
      value: "Net 15",
      label: "Net 15",
    },
    {
      value: "Net 30",
      label: "Net 30",
    },
    {
      value: "Net 45",
      label: "Net 45",
    },
    {
      value: "Net 60",
      label: "Net 60",
    },
    {
      value: "Due end of the month",
      label: "Due end of the month",
    },
    {
      value: "Due end of next month",
      label: "Due end of next month",
    },
    {
      value: "Due on receipt",
      label: "Due on receipt",
    },
  ];

  // Handle Column Sort
  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.PURCHASE_PRODUCT_LIST_COLUMNS, arrayList);
    setArray(array);
    setArrayList(array);
  };

  // Status Options
  const FieldLabel = [
    {
      value: Constants.FieldLabel.DISCOUNT_AMOUNT,
      label: Constants.FieldLabel.DISCOUNT_AMOUNT,
    },
    {
      value: Constants.FieldLabel.MANUFACTURED_DATE,
      label: Constants.FieldLabel.MANUFACTURED_DATE,
    },
    {
      value: Constants.FieldLabel.CGST,
      label: Constants.FieldLabel.CGST,
    },
    {
      value: Constants.FieldLabel.SGST,
      label: Constants.FieldLabel.SGST,
    },
    {
      value: Constants.FieldLabel.IGST,
      label: Constants.FieldLabel.IGST,
    },
    {
      value: Constants.FieldLabel.CESS,
      label: Constants.FieldLabel.CESS,
    },
    {
      value: Constants.FieldLabel.CGST_AMOUNT,
      label: Constants.FieldLabel.CGST_AMOUNT,
    },
    {
      value: Constants.FieldLabel.SGST_AMOUNT,
      label: Constants.FieldLabel.SGST_AMOUNT,
    },
    {
      value: Constants.FieldLabel.IGST_AMOUNT,
      label: Constants.FieldLabel.IGST_AMOUNT,
    },
    {
      value: Constants.FieldLabel.CESS_AMOUNT,
      label: Constants.FieldLabel.CESS_AMOUNT,
    },
  ];

  /**
   * Delete Bill
   *
   * @param data
   */
  const purchaseDelete = () => {
    let id = props.match.params.tab;
    dispatch(deletePurchase(id, {}, history));
  };

  const paymentsDelete = async () => {
    let params = {
      tabId: Tab.PAYMENT,
      purchaseId: purchaseId,
    };
    dispatch(await PaymentService.delete(rowValue?.id, null, params));
    closeDeleteModal();
  };

  let actionOptions = activeTab == Tab.PRODUCT
    ? [
      {
        label: "Update From Product",
        value: "Update From Product",
      },
      {
        label: "Update To Product",
        value: "Update To Product",
      },
      {
        label: "Update To Account Product",
        value: "Update To Account Product",
      },
    ]
    : [];

  if (activeTab === Tab.SUMMARY && isEditable && showEditButton) {
    actionOptions.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  // Add "Delete" action at the end
  actionOptions.push({
    value: "Delete",
    label: "Delete",
  });


  const getProductDetails = (
    productName,
    productImage,
    brandName,
    size,
    unit,
    salePrice,
    mrp
  ) => {
    return (
      <ProductCard
        productImageIcon
        square
        productName={productName}
        url={productImage}
        brandName={brandName}
        size={size != "null" ? size : ""}
        unit={unit != "null" ? unit : ""}
        salePrice={salePrice != "null" ? salePrice : ""}
        mrp={mrp != "null" ? mrp : ""}
        disableLink
      />
    );
  };

 

  let showHistory = hasPermission(Permission.PURCHASE_HISTORY_VIEW);

  //Handle Update purchase Details
  const handleUpdate = (id, values) => {
    const data = new FormData();
    if (values && values.purchase_number) {
      data.append(
        "purchaseNumber",
        values && Number.Get(values?.purchase_number)
      );
    }
    if (values && values.date) {
      data.append("date", values && DateTime.toISOStringDate(values.date));
    }
    if (values && values.vendor_invoice_number) {
      data.append(
        "vendor_invoice_number",
        values && String.Get(values.vendor_invoice_number)
      );
    }
    if (values && values.order_number) {
      data.append("order_number", values && Number.Get(values.order_number));
    }

    data.append("description", values && String.Get(values.description));
    data.append("location", values && Number.Get(values.location.value));
    data.append("vendor_id", values && String.Get(values.vendor_name.value));
    data.append("owner", values?.owner?.id ? values?.owner?.id : "");
    data.append("notes", values?.notes ? values?.notes : "");
    if (values && values.vendor_invoice_date) {
      data.append(
        "vendor_invoice_date",
        values.vendor_invoice_date
          ? DateTime.toISOStringDate(values.vendor_invoice_date)
          : ""
      );
    }
    if (values && values?.due_date) {
      data.append("due_date", values?.due_date ? values?.due_date : "");
    }
    data.append(
      "invoice_amount",
      values && values?.invoice_amount ? parseFloat(values.invoice_amount) : ""
    );
    data.append(
      "returnedItemAmount",
      values && values?.returnedItemAmount ? values?.returnedItemAmount : ""
    );
    data.append("reviewer", values?.reviewer?.id ? values?.reviewer?.id : "");

    dispatch(
      updatePurchase(id, data, {}, (response) => {
        if (response.status == HttpStatus.OK) {
          setIsLoading(true);
          getBillDetail();
          setIsEditable(true);
          setIsLoading(false);
        }
      })
    );
  };

  const _toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseModal = () => {
    setIsModelOpen(false);
    setIsSubmitting(true);
    setRowValue("");
  };

  const toggles = () => {
    setIsModelOpen(!isOpen);
    setDueDate("");
  };

  const handleInvoiceAmount = (e) => {
    const value = parseFloat(e.target.value);
    setInvoiceAmount(value);
  };

  const handleReturnedAmount = (e) => {
    const newValue = parseFloat(e.target.value) || 0;
    setReturnedItemsAmount(newValue);
  };

  const handleOwnerChange = (owner) => {
    setOwnerValue(owner);
  };

  useEffect(() => {
    const invoiceAmountUse = invoiceAmount
      ? invoiceAmount
      : purchaseData.invoice_amount;
    const returnsAmountuse =
      returnedAmount === 0
        ? 0
        : returnedAmount
          ? returnedAmount
          : purchaseData?.returned_items_amount;
    const calculatedpaymentAmount = invoiceAmountUse - returnsAmountuse;
    setPaymentAmount(calculatedpaymentAmount);
  }, [
    invoiceAmount,
    purchaseData?.returned_items_amount,
    returnedAmount,
    purchaseData?.invoice_amount,
  ]);

  const onVendorChange = (vendor_name) => {
    const value = vendor_name ? vendor_name : "";
    setVendorName(value);
  };

  const onPurchaseNumberChange = (e) => {
    const value = e.target.value;
    setVendorInvoiceNumber(value);
  };

  const onStoreChange = (e) => {
    const value = e;
    setStore(value);
  };

  const onDescriptionChange = (e) => {
    const value = e.target.value;
    setDescriptionChange(value);
  };

  if (isLoading) {
    return <Spinner />;
  }

  const initialValue = {
    purchase_number: purchaseData.purchase_number,
    description:
      descriptionChange == 0
        ? ""
        : descriptionChange
          ? descriptionChange
          : purchaseData.description,
    date: purchaseDate
      ? purchaseDate
      : purchaseData.purchase_date
        ? purchaseData.purchase_date
        : "",
    vendor_invoice_date: vendorInvoiceDate
      ? vendorInvoiceDate
      : purchaseData?.vendor_invoice_date
        ?
        purchaseData?.vendor_invoice_date

        : "",
    due_date: dueDate ? dueDate : purchaseData?.due_date,
    vendor_name: {
      label: vendorName?.label ? vendorName?.label : vendorData?.vendor_name,
      value: vendorName?.value ? vendorName?.value : purchaseData?.vendor_id,
    },
    order_number: purchaseData.order_number,
    payment_type: paymentType.find((option) =>
      option.value == purchaseData.payment_type ? purchaseData.payment_type : ""
    ),
    payment_term: paymentTerm.find((option) =>
      option.value == purchaseData.payment_term ? purchaseData.payment_term : ""
    ),
    location: location
      ? {
        label: location?.label,
        value: location?.value,
      }
      : locationList.find((data) => data.value === storeId),
    vendor_invoice_number:
      vendorInvoiceNumber == ""
        ? 0
        : vendorInvoiceNumber
          ? vendorInvoiceNumber
          : purchaseData.vendor_invoice_number,
    net_amount: Currency.Get(purchaseData?.net_amount) || "",
    owner: ownerValue == ""
      ? ""
      : ownerValue
        ? ArrayList.isArray(userList) &&
        userList.find((data) => data?.id == ownerValue)
        : purchaseData?.owner_id
          ? ArrayList.isArray(userList) &&
          userList.find((data) => data?.id == purchaseData?.owner_id)
          : "", notes:
      notes === ""
        ? ""
        : notes
          ? notes
          : purchaseData?.notes
            ? purchaseData?.notes
            : "",
    invoice_amount:
      invoiceAmount === 0
        ? ""
        : invoiceAmount
          ? invoiceAmount
          : purchaseData?.invoice_amount || "",
    returnedItemAmount:
      returnedAmount === 0
        ? ""
        : returnedAmount
          ? returnedAmount
          : purchaseData?.returned_items_amount || "",
    reviewer:
      reviewerValue == ""
        ? ""
        : reviewerValue
          ? ArrayList.isArray(userList) &&
          userList.find((data) => data?.id == reviewerValue)
          : purchaseData?.reviewer_id
            ? ArrayList.isArray(userList) &&
            userList.find((data) => data?.id == purchaseData?.reviewer_id)
            : "",
  };

  const onStatusChange = (value) => {
    if (value) {
      handleStatusChange(value);
    }
  };

  const handleStatusChange = (status) => {
    const data = new FormData();
    data.append("status", status ? status : "");
    try {
      if (
        Currency.roundOff(purchaseData?.net_amount) ==
        Currency.roundOff(vendorData?.totalAmount)
      ) {
        apiClient
          .put(`${endpoints().purchaseAPI}/status/${id}`, data)
          .then((res) => {
            if (res.status == SUCCESS_RESPONSE) {
              Toast.success(res?.data?.message);
            }
            getBillDetail(id);
            setStatus("");
          })
          .catch((err) => {
            if (isBadRequest(err)) {
              let errorMessage;
              const errorRequest = err.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              Toast.error(errorMessage);
            }
          });
      } else {
        Toast.error("Total did not match");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNotesChange = (e) => {
    const value = e.target.value;
    setNotes(value);
  };

  const onVendorInvoiceDateChange = (x) => {
    const value = x ? x : "";
    setVensorInvoiceDate(value);
  };

  const handlePurchaseChange = (date) => {
    const value = date ? date : "";
    setPurchaseDate(value);
  };

  const onDueDateChange = (e) => {
    setDueDate(e);
  };

  const onClick = async (values) => {
    const data = new FormData();
    data.append("purchaseId", purchaseData && purchaseData?.id);
    data.append("account_id", purchaseData && purchaseData?.vendor_id);
    data.append("date", purchaseData?.vendor_invoice_date);
    data.append(
      "invoice_number",
      purchaseData && purchaseData.vendor_invoice_number
    );
    data.append("vendor_name", vendorData?.vendor_name);
    data.append("owner_id", purchaseData && purchaseData?.owner_id);
    data.append("billing_name", vendorData && vendorData?.billing_name);
    data.append(
      "rejectedProductAmount",
      purchaseData?.returned_items_amount
        ? purchaseData?.returned_items_amount
        : ""
    );
    data.append(
      "invoice_amount",
      purchaseData?.invoice_amount ? purchaseData?.invoice_amount : ""
    );

    data.append(
      "cash_discount_percentage",
      vendorData?.cash_discount_percentage
        ? vendorData?.cash_discount_percentage
        : ""
    );

    let cash_discount_amount =
      Number.Float(purchaseData?.invoice_amount) -
      Number.Float(purchaseData.returned_items_amount);

    let amount = paymentAmount * (vendorData?.cash_discount_percentage / 100);

    data.append("cash_discount_amount", amount ? amount : "");

    let netAmount = Number.Float(cash_discount_amount) - Number.Float(amount);
    data.append("net_amount", netAmount ? netAmount : "");
    if (activeTab === Tab.BILL) {
      dispatch(
        await BillService.create(data, { purchase_id: id }, "", () => {
          dispatch(
            fetchList(
              "billId",
              `${endpoints().billAPI}/search`,
              Urls.GetParam("page") ? Urls.GetParam("page") : 1,
              Urls.GetParam("pageSize") ? Urls.GetParam("pageSize") : 25,
              {
                ...Urls.GetAllParams(),
              }
            )
          );
        })
      );
      setIsLoading(true);
      getBillDetail();
      setIsLoading(false);
    }
    if (activeTab === Tab.PAYMENT) {
      dispatch(
        await PaymentService.create(data, { purchase_id: id }, "", () => { })
      );
      setIsLoading(true);
      getBillDetail();
      setIsLoading(false);
    }
  };

  const onBulkSelect = (ids) => {
    setProductIds({ productIds: ids });
  };

  let params = { objectName: ObjectName.PAYMENT, showTotal: true };

  if (id) {
    params.purchaseId = id;
  }

  const handleReviewerChange = (value) => {
    setReviewerValue(value?.id ? value?.id : "");
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        label={rowValue?.id}
        toggle={closeDeleteModal}
        title="Delete Payment"
        deleteFunction={paymentsDelete}
      />

      <DeleteModal
        id={purchaseData?.data?.id}
        label={purchaseData?.purchase_number}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Product "
        deleteFunction={purchaseDelete}
      />

      {/* Bread Crumb Section */}
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between" target="_blank">
        <PageTitle label={`Purchase #${purchaseData.purchase_number}`} />

        <div className="d-flex justify-content-end mb-3">
          {activeTab === Tab.BILL && (
            <React.Fragment>
              <AddButton
                label="Create Bill"
                className="mx-2 mt-sm-1 me-2"
                onClick={onClick}
                toUrl="#"
              />
            </React.Fragment>
          )}
          {activeTab === Tab.PRODUCT && (
            <AddButton
              label="Add Product"
              onClick={() => {
                _toggle();
              }}
              className="mx-2 mt-sm-1 me-2"
            />
          )}
          <>
          {activeTab === Tab.SUMMARY && 

            <div className="d-flex justify-content-around">
              <div className="me-2">
                <StatusComponent
                  objectName={ObjectName.PURCHASE}
                  handleChange={onStatusChange}
                  buttonLabel={vendorData?.statusName}
                  currentStatusId={vendorData?.statusValue}
                />
              </div>
            </div>
}
          </>

          {/* Action Menu */}
          <Action dropdownLinks={actionOptions} handleChange={handleChange} />
        </div>
      </div>

      {/* showing the vendor Payment terms and Return terms */}
      {vendorData && (
        <div
          className="shadow mx-0 my-3 "
          style={{ borderRadius: "10px", backgroundColor: "yellow" }}
        >
          {vendorData?.payment_terms && (
            <pre className="py-2 px-2 text-red fs-6">
              {vendorData?.payment_terms ? vendorData?.payment_terms : null}
            </pre>
          )}
          {vendorData?.return_terms && (
            <pre className="py-2 px-2 text-red fs-6">
              {vendorData?.return_terms ? vendorData?.return_terms : null}
            </pre>
          )}
        </div>
      )}
      {vendorData && vendorData?.productCount == 0 && (
        <Banner className="bg-red" text="No Product(s) Added" />
      )}
      {vendorData && vendorData.marginMistachedText && (
        <Banner
          className="bg-red"
          text={vendorData && vendorData.marginMistachedText}
        />
      )}
      <div>
        {vendorData && vendorData.marginMatchedText && (
          <Banner text={vendorData && vendorData.marginMatchedText} />
        )}
      </div>

      <Nav tabs className="admin-tabs">
        {/* Detail Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.SUMMARY,
            })}
            onClick={() => {
              toggle(Tab.SUMMARY);
            }}
          >
            {Tab.SUMMARY}
          </NavLink>
        </NavItem>
        {/* History Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.PRODUCT,
            })}
            onClick={() => {
              toggle(Tab.PRODUCT);
            }}
          >
            {Tab.PRODUCT}
          </NavLink>
        </NavItem>

        {/* Payment Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.BILL,
            })}
            onClick={() => {
              toggle(Tab.BILL);
            }}
          >
            {Tab.BILL}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.PAYMENT,
            })}
            onClick={() => {
              toggle(Tab.PAYMENT);
            }}
          >
            {Tab.PAYMENT}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.COMMENTS,
            })}
            onClick={() => {
              toggle(Tab.COMMENTS);
            }}
          >
            {Tab.COMMENTS}
          </NavLink>
        </NavItem>

        {/* History Tab */}
        {showHistory && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => {
                toggle(Tab.HISTORY);
              }}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <div className="tabs-and-preview full-width d-flex admin-settings mb-4">
        <div className="card-body card mb-4 pb-4 mb-sm-0 pb-sm-0">
          <TabContent activeTab={activeTab}>
            {/* Detail Tab Start*/}
            {activeTab === Tab.SUMMARY && (
              <TabPane tabId={Tab.SUMMARY} className="w-100">
                <Form
                  enableReinitialize={true}
                  initialValues={initialValue}
                  onSubmit={(values) => {
                    let id = props.match.params.tab;
                    handleUpdate(id, values);
                  }}
                >
                  <div className="row">
                    <div className="col-12">
                      <PurchaseForm
                        className="col-lg-7"
                        storeList={locationList}
                        history={history}
                        edit={true}
                        status={true}
                        handleChange={(e) => handleChangeStatus(e)}
                        PermissionList={PermissionList}
                        onVendorChange={onVendorChange}
                        onPurchaseNumberChange={(e) =>
                          onPurchaseNumberChange(e)
                        }
                        onStoreChange={onStoreChange}
                        onDescriptionChange={onDescriptionChange}
                        editable={isEditable}
                        id={purchaseId}
                        showOwner={true}
                        getBillDetail={getBillDetail}
                        vendorData={vendorData}
                        handleNotesChange={handleNotesChange}
                        handleUserChange={handleOwnerChange}
                        onVendorInvoiceDateChange={onVendorInvoiceDateChange}
                        onDueDateChange={onDueDateChange}
                        showNotes
                        handleInvoiceAmount={handleInvoiceAmount}
                        handleReturnedAmount={handleReturnedAmount}
                        owner_id={
                          purchaseData?.owner_id ? purchaseData?.owner_id : null
                        }
                        reviewer_id={
                          purchaseData?.reviewer ? purchaseData?.reviewer : null
                        }
                        handlePurchaseChange={handlePurchaseChange}
                        showAccountDetailsPageLink={
                          vendorData?.vendor_id ? true : false
                        }
                        accountId={vendorData?.vendor_id}
                        showUserDetailsPageLink={
                          purchaseData?.owner_id ? true : false
                        }
                        userId={purchaseData?.owner_id}
                        userList={setUserList}
                        handleReviewerChange={handleReviewerChange}
                        isEditPage={true}
                        setLocationList={setLocationList}
                        locationId={storeId}
                        purchaseData={purchaseData}
                      />
                    </div>
                  </div>
                  {activeTab == Tab.SUMMARY && (
                    <HorizontalSpace>
                      {!isEditable && (
                        <>
                          <SaveButton label="Save" />
                          <CancelButton
                            onClick={() => {
                              props.history.push("/purchases");
                            }}
                          />
                        </>
                      )}
                    </HorizontalSpace>
                  )}
                </Form>
              </TabPane>
            )}

            {/* History Tab Start*/}
            {activeTab === Tab.PRODUCT && (
              <TabPane tabId={Tab.PRODUCT} className="w-100">
                <div>
                  <ProductForm
                    storeId={
                      purchaseData && purchaseData?.store_id
                        ? purchaseData.store_id
                        : props.match.params.subtab
                    }
                    purchaseId={
                      purchaseData && purchaseData.id
                        ? purchaseData?.id
                        : props.match.params.tab
                    }
                    vendorId={purchaseData && purchaseData?.vendor_id}
                    showActionButton
                    arrays={arrays}
                    history={history}
                    bulkSelect
                    onBulkSelect={onBulkSelect}
                    detail={vendorData}
                    FieldLabel={FieldLabel}
                    handleColumnChange={(e) => {
                      handleColumnChange(e);
                    }}
                    arrayList={arrayList}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    selectedCheckBox={selectedCheckBox}
                    getBillDetail={getBillDetail}
                    totalRejectedProductAmount
                    totalNotReceivedProductAmount
                  />
                </div>
              </TabPane>
            )}

            {/* Bill Tab */}
            {activeTab === Tab.BILL && (
              <TabPane tabId={Tab.BILL} className="w-100">
                <Bill
                  showBillList
                  purchase_id={id}
                  history={history}
                  showAccountFilter={false}
                />
              </TabPane>
            )}
            {activeTab == Tab.PAYMENT && (
              <TabPane tabId={Tab.PAYMENT} className="w-100">
                <PaymentList
                  history={history}
                  isOpen={isModelOpen}
                  toggles={toggles}
                  setIsSubmitting={setIsSubmitting}
                  isSubmitting={isSubmitting}
                  handleCloseModal={handleCloseModal}
                  assigneePlaceholder="Select Owner"
                  params={params}
                  setRowValue={setRowValue}
                  rowValue={rowValue}
                  setDueDate={setDueDate}
                  dueDate={dueDate}
                  setDetail={setDetail}
                  detail={detail}
                  notesValue={detail?.notes}
                  showLoggedInUser
                  purchaseData={purchaseData}
                  setOpenDeleteModal={setOpenDeleteModal}
                  purchaseAcountId={purchaseData && purchaseData?.vendor_id}
                  purchaseOwnerId={purchaseData && purchaseData?.owner_id}
                />
              </TabPane>
            )}
            {/* History Tab*/}
            {showHistory && activeTab === Tab.HISTORY && (
              <TabPane tabId={Tab.HISTORY} className="w-100">
                <ActivityList
                  id={id}
                  objectId={id}
                  object_name={ObjectName.PURCHASE}
                  history={history}
                />
              </TabPane>
            )}
            {activeTab == Tab.COMMENTS && (
              <TabPane tabId={Tab.COMMENTS} className="w-100">
                <Comment
                  objectId={id}
                  objectName={ObjectName.PURCHASE}
                  maxHeight="100vh"
                />
              </TabPane>
            )}
          </TabContent>
        </div>
      </div>
    </>
  );
};
export default GeneralDetailsTab;
[];
