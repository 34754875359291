import { Field, useField } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { FormFeedback, FormGroup } from "reactstrap";

const FileUpload = (property) => {
  let { label, required, acceptedFileTypes,showPreview, ...props } = property;
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [field, meta, helpers] = useField(props.name);
  const fileInputRef = useRef(null); // Ref for the file input

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

  const validateFile = (file) => {
    if (required && !file) {
      return "File is required.";
    }
    if (file && file.size > MAX_FILE_SIZE) {
      return "File size exceeds 10MB.";
    }
    if (acceptedFileTypes && !acceptedFileTypes.includes(file.type)) {
      return "Invalid file type. Please upload an image or PDF.";
    }
    return null;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    helpers.setError(null);

    const error = validateFile(file);
    if (error) {
      helpers.setError(error);
      helpers.setValue(null);
      setSelectedFile(null);
      setFileType(null);
      return;
    }

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setSelectedFile(fileUrl);
      setFileType(file.type);
      helpers.setValue(file);
    }
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    setFileType(null);
    helpers.setValue(null);
    helpers.setError(null);
  };

  const isError = meta.touched && meta.error;

  useEffect(() => {
    if (!field.value) {
      setSelectedFile(null);
      setFileType(null);
      helpers.setValue(null);
      helpers.setError(null);
    }
  }, [field.value]);

  return (
    <FormGroup>
      {label && (
        <label>
          {label} {required && "*"}
        </label>
      )}
      <div style={styles.container}>
        {(selectedFile && showPreview) && (
          <div
            style={{
              ...styles.previewContainer,
              border: isError ? "3px solid red" : "3px solid #f4f4f6",
            }}
          >
            {selectedFile ? (
              fileType === "application/pdf" ? (
                <object
                  data={selectedFile}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  <p>
                    PDF cannot be displayed.{" "}
                    <a
                      href={selectedFile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download the PDF
                    </a>
                  </p>
                </object>
              ) : (
                <img
                  src={selectedFile}
                  alt="Uploaded Preview"
                  style={styles.imagePreview}
                />
              )
            ) : (
              ""
            )}
          </div>
        )}
        <input
          type="file"
          accept={acceptedFileTypes.join(",")}
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: "none" }}
          onClick={(e) => {
            e.currentTarget.value = null;
          }}
        />

        {!selectedFile && (
          <a
            onClick={() => fileInputRef.current.click()}
            style={{
              ...styles.link,
              color: isError && !selectedFile ? "red" : "#007bff",
            }}
          >
            Browse for File
          </a>
        )}

        {selectedFile && (
          <a onClick={handleClearFile} style={styles.removeLink}>
            Remove File
          </a>
        )}

        <FormFeedback>{meta.error}</FormFeedback>
      </div>
    </FormGroup>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Align to left
    justifyContent: "center",
    padding: "20px",
    textAlign: "left", // Align text to left
  },
  previewContainer: {
    width: "180px",
    height: "180px",
    marginBottom: "15px",
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imagePreview: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f4f4f6",
    color: "#999",
  },
  uploadButton: {
    marginTop: "10px",
  },
  removeLink: {
    color: "#ff0000",
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "16px",
    marginTop: "10px",
  },
  link: {
    color: "#007bff",
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "16px",
    marginTop: "10px",
  },
};

FileUpload.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
};

const FormFileUpload = (props) => {
  const { required, acceptedFileTypes } = props;

  const validate = (value) => {
    let errorMessage;
    if (required && !value) {
      errorMessage = `File is required`;
    }
    return errorMessage;
  };

  return (
    <Field name={props.name} validate={validate}>
      {({ field, form }) => (
        <FileUpload
          {...field}
          {...props}
          acceptedFileTypes={acceptedFileTypes}
          error={form.touched[field.name] && form.errors[field.name]}
        />
      )}
    </Field>
  );
};

export default FormFileUpload;
