import React, { useState, useEffect } from "react";
import Select from "./Select";
import DateTime from "../lib/DateTime";

const YearSelect = ({
  label,
  required,
  onChange,
  name,
  isDisabled,
  defaultValue,
}) => {
  const [yearOption, setYearOption] = useState([]);

  useEffect(() => {
    getYears();
  }, []);

  const getYears = () => {
    let yearOption = DateTime.getYears();
    setYearOption(yearOption);
  };

  return (
    <>
      <Select
        fullWidth={true}
        width="100"
        label={label ? label : ""}
        name={name ? name : "Year"}
        placeholder={label ? label : "Select Year"}
        isClearable
        isSearchable
        required={required}
        options={yearOption}
        isSingleSelect={true}
        onInputChange={onChange}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        showVirtualizedMenu={true}
      />
    </>
  );
};

export default YearSelect;
