import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from '../../components/Form';
import SaveButton from '../../components/SaveButton';
import Select from '../../components/Select';
import TagTypeService from '../../services/TagTypeService';
import { getCompanySettings, saveSetting } from "../../services/SettingService";
import { getKeyValueByObject } from "../../lib/Helper";
import { Setting } from "../../helpers/Setting";
import MultiSelect from '../../components/Multiselect';

const SettingPage = (props) => {
    const [type, setType] = useState([]);
    const [visitorType, setVisitorType] = useState([])
    const [showVisitorType, setShowVisitorType] = useState([])
    let dispatch = useDispatch();

    useEffect(() => {
        getSettings()
        getTypes();
    }, [props]);

    const getSettings = async () => {
        const settingData = await getCompanySettings();
        let visitorType = getKeyValueByObject(settingData, Setting.VISITOR_TYPE);
        setVisitorType(visitorType)
        let showVisitorTitle = getKeyValueByObject(settingData , Setting.VISITOR_SHOW_TITLE)
        let visitorData = showVisitorTitle && showVisitorTitle.split(',');

        setShowVisitorType(visitorData)
    };

    let visitorValue = [];


    showVisitorType && showVisitorType.forEach((value) => {
          let data = type && type.find((data) => data?.value == value);
          visitorValue.push(data);

        });


    const getTypes = async () => {
        let response = await TagTypeService.TagList('Visitor Type');
        let data = response && response?.data;
        if (data && data.length > 0) {
            let list = [];
            for (let i = 0; i < data.length; i++) {
                const { id, name } = data[i];
                list.push({
                    value: id,
                    label: name,
                });
            }
            setType(list);
        }
    };

    const handleUpdate = async (values) => {
        let data = new FormData();
        let showVisitorTitle=[]
        for (let i = 0; i < values?.visitor_show_title.length; i++) {
          const data = values?.visitor_show_title[i];
          showVisitorTitle.push(data?.value)
        }
        data.append(
            Setting.VISITOR_TYPE,
            values && values?.visitor_type?.value
        );
        data.append(
            Setting.VISITOR_SHOW_TITLE,
            showVisitorTitle && showVisitorTitle.length > 0 ? showVisitorTitle.join(","):""
        );
        saveSetting(data, null, (res) => {
            if (res) {
            }
        });
    };
    
    return (
        <div>
            <Form
                enableReinitialize={true}
                initialValues={{
                    visitor_type: type && type.length > 0 && type.find((data) => data?.value == visitorType),
                    visitor_show_title : visitorValue
                }}
                onSubmit={(values) => {
                    handleUpdate(values);
                }}
            >
                <div className='card bg-white mb-3'>
                    <div className='card-body'>
                        <Select
                            name={Setting.VISITOR_TYPE}
                            label='Visitor Type'
                            options={type}
                        />
                     <MultiSelect
                            name={Setting.VISITOR_SHOW_TITLE}
                            label='Show Title'
                            options={type}
                        />
                        <div className='mb-4'>
                            <div>
                                <SaveButton />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default SettingPage;