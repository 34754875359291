import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { HttpStatus } from "../helpers/HttpStatus";

class GstB2bService {
  static create = (data, callback) => {
    apiClient
      .post(`${endpoints().gstB2bAPI}`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback(successMessage);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status >= HttpStatus.BAD_REQUEST) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}
export default GstB2bService;
