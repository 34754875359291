import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";

const Banner = (props) => {
  const { className, text, style, textColor } = props;
  const [isBannerVisible, setBannerVisible] = useState(true);

  const handleBannerClose = () => {
    setBannerVisible(false);
  };

  return (
    <>
      {isBannerVisible && (
        <div
          className={
            className ? `${className} top-banner` : "bg-success top-banner"
          }
          style={style}
        >
          <pre className={textColor ? `${textColor}  p-1 m-0 fs-6 fs-sm-5` : "text-white p-1 m-0 fs-6 fs-sm-5"}>
            {text}
          </pre>
          <span className="close-icon p-2" onClick={handleBannerClose}>
            <FaTimes />
          </span>
        </div>
      )}
    </>
  );
};

export default Banner;
